import axios from 'axios'
import Company from '../models/Company'
import User from '../models/User'
import UserAccessValidation from '../models/UserAccessValidation'
import { ContentConfig, SignUpConfig } from '../models/CompanyConfig'

export default class EnterService {

    getUserSignUpConfig = async (mainUrl: string, companyId: string, primaryPropertyValue: string): Promise<UserSignUpConfigRes> => {
        let url = `${mainUrl}/auth/userSignUpConfig/${companyId}/${primaryPropertyValue}`
        let result = await axios.get(url)
        return new UserSignUpConfigRes(result.data.data)
    }

    getCompanySignUpConfig = async (mainUrl: string, companyId: string): Promise<SignUpConfigRes> => {
        let url = `${mainUrl}/auth/companySignUpConfig/${companyId}`
        let result = await axios.get(url)
        return new SignUpConfigRes(result.data.data)
    }

    getUserExists = async (mainUrl: string, primaryProperty: string, value: string, companyId: string): Promise<boolean> => {
        let url = `${mainUrl}/auth/userExists`
        let result = await axios.post(url, { primaryProperty, value, companyId })
        return result.data.data.userExists
    }

    createUser = async (mainUrl: string, companyId: string, signUpData: any) => {
        let url = `${mainUrl}/auth/signup`
        let result = await axios.post(url, { companyId, signUpData })
        return new UserRes(result.data.data)
    }

    signInUser = async (mainUrl: string, companyId: string, signInData: any) => {
        let url = `${mainUrl}/auth/signin`
        let result = await axios.post(url, { companyId, signInData })
        return new UserRes(result.data.data)
    }

    getCompanies = async (mainUrl: string) => {
        let url = `${mainUrl}/auth/companies`
        let result = await axios.get(url)
        return result.data.data.companies.map((data: any) => { return new Company(data) })
    }

    updateUserPwd = async (mainUrl: string, token: string, newPwd: string) => {
        let url = `${mainUrl}/auth/updatePwd`
        await axios.post(url, { token, newPwd })
        return true
    }

    recoverUserPwd = async (mainUrl: string, companyId: string, doc: string | undefined, email: string | undefined) => {
        let url = `${mainUrl}/auth/recoverPwd`
        let result = await axios.post(url, { companyId, doc, email })
        return new RecoverPwdRes(result.data.data)
    }
}

export class UserRes {
    user: User
    tokenData: TokenData
    constructor(data: any) {
        this.user = new User(data.user)
        this.tokenData = new TokenData(data.tokenData)
    }
}

export class TokenData {
    token: string
    refreshToken: string
    constructor(data: any) {
        this.token = data.token
        this.refreshToken = data.refreshToken
    }
}

export class SignUpConfigRes {
    company: Company
    signUpConfig: SignUpConfig
    constructor(data: any) {
        this.company = new Company(data.company)
        this.signUpConfig = new SignUpConfig(data.signUpConfig)
    }
}

export class RecoverPwdRes {
    userEmail?: string
    userWhatsApp?: string

    constructor(data: any) {
        this.userEmail = data.userEmail
        this.userWhatsApp =  data.userWhatsApp
    }
}

export class UserSignUpConfigRes {
    company: Company
    signUpConfig: SignUpConfig
    userAccessValidation?: UserAccessValidation

    constructor(data: any) {
        this.company = new Company(data.company)
        this.signUpConfig = new SignUpConfig(data.signUpConfig)
        if (data.userAccessValidation) {
            this.userAccessValidation = new UserAccessValidation(data.userAccessValidation)
        }
    }
}