import * as XLSX from 'xlsx'
import _ from 'lodash'

import Module from '../../../../models/Module'
import { UserCompanyScore } from '../../../../models/Scores'
import { SubModule } from '../../../../models/SubModule'
import User from '../../../../models/User'
import { ReportConfig } from '../../../../models/CompanyConfig'
import UserAccessValidation from '../../../../models/UserAccessValidation'
import { ComposedData } from './UsageFilters'
import { UserModuleAttempts } from '../../../ranking/intefaces'

export const usersDidntPlayedModuleData = (primaryProperty: string, reportConfig: ReportConfig | undefined, users: Array<User>, selectedModule: Module | undefined) => {

    let wb = XLSX.utils.book_new()
    var ws_data: Array<any> = []
    var ws_data_index = 0

    // Explica o que é
    if (selectedModule) {
        ws_data[ws_data_index] = [`Usuários que ainda não jogaram ${selectedModule.title}`]; ws_data_index++
    } else {
        ws_data[ws_data_index] = ['Usuários que ainda não jogaram algum jogo']; ws_data_index++
    }

    let primaryPropertyTitle = primaryProperty === 'email' ? 'Email' : 'CPF'
    // Coloca nomes das colunas
    ws_data[ws_data_index] = ['Nome', primaryPropertyTitle];

    // Mostra data de criacao
    if (reportConfig && reportConfig.showCreatedDate) ws_data[ws_data_index].push('Data de Criação da Conta')

    // Auth Data
    if (reportConfig && reportConfig.authData) {
        let authDataKeys = Object.keys(reportConfig.authData)
        for (var i = 0; i < authDataKeys.length; i++) {
            let key = authDataKeys[i]
            let currentAuthData = reportConfig.authData[key]
            ws_data[ws_data_index].push(currentAuthData.title)
        }
    }

    // Auth Data Complement
    if (reportConfig && reportConfig.authDataComplementArray) {
        for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
            let authDataComplement = reportConfig.authDataComplementArray[n]
            ws_data[ws_data_index].push(authDataComplement.title)
        }
    }

    ws_data_index++

    users.sort((a, b) => {
        if (a.username.toLowerCase() > b.username.toLowerCase()) return 1
        else if (a.username.toLowerCase() < b.username.toLowerCase()) return -1
        else return 0
    }).forEach(user => {
        let primaryPropertyValue = primaryProperty === 'email' ? user.email : user.doc
        ws_data[ws_data_index] = [user.username, primaryPropertyValue]

        // Mostra data de criacao
        if (reportConfig && reportConfig.showCreatedDate) {
            let date = new Date(user.createdDateInMillis)
            let formattedDate = `${date.toLocaleDateString("pt-BR")} - ${date.toLocaleTimeString("pt-BR")}`
            ws_data[ws_data_index].push(formattedDate)
        }

        // Auth Data
        if (reportConfig && reportConfig.authData) {
            let authDataKeys = Object.keys(reportConfig.authData)
            for (var i = 0; i < authDataKeys.length; i++) {
                let key = authDataKeys[i]
                let currentAuthData = reportConfig.authData[key]
                let userAuthData = user.authData ? (user.authData[currentAuthData.id] || '-') : '-'
                ws_data[ws_data_index].push(userAuthData)
            }
        }

        // Auth Data Complement
        if (reportConfig && reportConfig.authDataComplementArray) {
            for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
                let authDataComplement = reportConfig.authDataComplementArray[n]
                if (user.authData && user.authData[authDataComplement.authDataId]) {
                    let userAuthDataValue = user.authData[authDataComplement.authDataId]
                    ws_data[ws_data_index].push(authDataComplement.authDataComplementValues[userAuthDataValue] || '')
                } else {
                    ws_data[ws_data_index].push('')
                }
            }
        }

        ws_data_index++
    })


    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, `Relatório`);
    let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: fileType });

}

export const usersPlayedModuleData = (primaryProperty: string, reportConfig: ReportConfig | undefined, users: Array<User>, scores: Array<UserCompanyScore>, modules: Array<Module>, subModules: Array<SubModule>, usersModuleAttempts: UserModuleAttempts[] | undefined = undefined) => {

    let wb = XLSX.utils.book_new()
    var ws_data: Array<any> = []
    var ws_data_index = 0

    let primaryPropertyTitle = primaryProperty === 'email' ? 'Email' : 'CPF'

    // Coloca nomes das colunas
    ws_data[ws_data_index] = ['Nome', primaryPropertyTitle]

    // Mostra data de criacao
    if (reportConfig && reportConfig.showCreatedDate) ws_data[ws_data_index].push('Data de Criação da Conta')

    // Auth Data
    if (reportConfig && reportConfig.authData) {
        let authDataKeys = Object.keys(reportConfig.authData)
        for (var i = 0; i < authDataKeys.length; i++) {
            let key = authDataKeys[i]
            let currentAuthData = reportConfig.authData[key]
            ws_data[ws_data_index].push(currentAuthData.title)
        }
    }

    // Auth Data Complement
    if (reportConfig && reportConfig.authDataComplementArray) {
        for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
            let authDataComplement = reportConfig.authDataComplementArray[n]
            ws_data[ws_data_index].push(authDataComplement.title)
        }
    }

    modules.forEach(module => {

        let maxScoreInModule = subModules.filter(subModule => { return subModule.moduleId === module.id }).reduce((acc, subModule) => { return acc + subModule.maxScore }, 0)

        // Coloca nomes das colunas
        let moduleColumnName = `${module.title} (${maxScoreInModule})`
        ws_data[ws_data_index].push(moduleColumnName)

        // Porcentabem
        if (reportConfig && reportConfig.showPercentage) {
            let modulePercentageColumnName = `${module.title} %`
            ws_data[ws_data_index].push(modulePercentageColumnName)
        }

        let moduleSubModules = subModules.filter(subModule => subModule.moduleId === module.id).sort((a, b) => a.pos - b.pos)
        moduleSubModules.forEach(subModule => {
            let subModuleColumnName = `${subModule.title} (${subModule.maxScore})`
            ws_data[ws_data_index].push(subModuleColumnName)

            // Porcentabem
            if (reportConfig && reportConfig.showPercentage) {
                let subModulePercentageColumnName = `${subModule.title} %`
                ws_data[ws_data_index].push(subModulePercentageColumnName)
            }
        })

        if(usersModuleAttempts) {
            ws_data[ws_data_index].push('Tentativas')
        }

        ws_data[ws_data_index].push('Status')
    })

    // Próxima linha
    ws_data_index++

    users.sort((a, b) => {
        if (a.username.toLowerCase() > b.username.toLowerCase()) return 1
        else if (a.username.toLowerCase() < b.username.toLowerCase()) return -1
        else return 0
    }).forEach(user => {
        let userScore = _.first(scores.filter(score => score.userId === user!.id))!
        let primaryPropertyValue = primaryProperty === 'email' ? user.email : user.doc
        ws_data[ws_data_index] = [user.username, primaryPropertyValue]

        // Mostra data de criacao
        if (reportConfig && reportConfig.showCreatedDate) {
            let date = new Date(user.createdDateInMillis)
            let formattedDate = `${date.toLocaleDateString("pt-BR")} - ${date.toLocaleTimeString("pt-BR")}`
            ws_data[ws_data_index].push(formattedDate)
        }

        // Auth Data
        if (reportConfig && reportConfig.authData) {
            let authDataKeys = Object.keys(reportConfig.authData)
            for (var i = 0; i < authDataKeys.length; i++) {
                let key = authDataKeys[i]
                let currentAuthData = reportConfig.authData[key]
                let userAuthData = user.authData ? (user.authData[currentAuthData.id] || '-') : '-'
                ws_data[ws_data_index].push(userAuthData)
            }
        }

        // Auth Data Complement
        if (reportConfig && reportConfig.authDataComplementArray) {
            for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
                let authDataComplement = reportConfig.authDataComplementArray[n]
                if (user.authData && user.authData[authDataComplement.authDataId]) {
                    let userAuthDataValue = user.authData[authDataComplement.authDataId]
                    ws_data[ws_data_index].push(authDataComplement.authDataComplementValues[userAuthDataValue] || '')
                } else {
                    ws_data[ws_data_index].push('')
                }
            }
        }

        modules.forEach(module => {
            let userShouldPlayModule = module.allowAccessAuthData ? Object.keys(module.allowAccessAuthData).reduce((acc, key) => {
                // se deu falso pra alguma propriedade ele ja nao precisa jogar
                if (acc === 'false') return acc
                else if (!user.authData) return 'false'
                else if (module.allowAccessAuthData![key].filter(val => val === user.authData![key]).length === 0) return 'false'
                else return acc
            }, 'true') === 'true' : true
            let moduleSubModules = subModules.filter(subModule => subModule.moduleId === module.id).sort((a, b) => a.pos - b.pos)
            let userScoreInModule = userShouldPlayModule ? moduleSubModules.reduce((acc: number, subModule) => acc + (userScore ? userScore.subModuleScores[subModule.id] || 0 : 0), 0) : '-'
            ws_data[ws_data_index].push(userScoreInModule)

            // Porcentagem
            if (reportConfig && reportConfig.showPercentage) {
                let maxScoreInModule = moduleSubModules.reduce((acc, prev) => { return acc += prev.maxScore }, 0)
                if (maxScoreInModule > 0) {
                    if (userScoreInModule === '-') ws_data[ws_data_index].push('-')
                    else ws_data[ws_data_index].push(((+userScoreInModule * 100) / maxScoreInModule).toFixed(2))
                } else {
                    ws_data[ws_data_index].push('-')
                }
            }

            var hasFinished = true
            moduleSubModules.forEach(subModule => {
                let subModuleScore = userShouldPlayModule ? (userScore ? userScore.subModuleScores[subModule.id] || 0 : 0) : '-'
                if (typeof subModuleScore === 'number' && subModuleScore < subModule.maxScore / 2) hasFinished = false
                ws_data[ws_data_index].push(subModuleScore.toString())

                // Porcentagem
                if (reportConfig && reportConfig.showPercentage) {
                    if (subModule.maxScore > 0) {
                        if (subModuleScore === '-') ws_data[ws_data_index].push('-')
                        else ws_data[ws_data_index].push(((+subModuleScore * 100) / subModule.maxScore).toFixed(2))
                    } else {
                        ws_data[ws_data_index].push('-')
                    }
                }
            })

            if(usersModuleAttempts) {
                const aModuleAttemptSearch = _.find(usersModuleAttempts.filter(attempt => attempt.userId === user.id))
                if(aModuleAttemptSearch) {
                    let attempts = aModuleAttemptSearch.subModuleAttempts.reduce((acc, curr) => { acc += curr.attemptsToMaxScore; return acc }, 0)
                    ws_data[ws_data_index].push(attempts)
                } else {
                    ws_data[ws_data_index].push(0)
                }
            }

            let status = userShouldPlayModule ? (hasFinished ? 'Concluído' : 'Não terminou') : 'Não precisa jogar'
            ws_data[ws_data_index].push(status)
        })
        // Próxima linha
        ws_data_index++
    })

    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, `Relatório`);
    let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: fileType });
}

export const usersCreatedAccountData = (primaryProperty: string, reportConfig: ReportConfig | undefined, users: Array<User>, scores: Array<UserCompanyScore>, modules: Array<Module>, subModules: Array<SubModule>) => {

    let wb = XLSX.utils.book_new()
    var ws_data: Array<any> = []
    var ws_data_index = 0

    let primaryPropertyTitle = primaryProperty === 'email' ? 'Email' : 'CPF'

    // Coloca nomes das colunas
    ws_data[ws_data_index] = ['Nome', primaryPropertyTitle]

    // Mostra data de criacao
    if (reportConfig && reportConfig.showCreatedDate) ws_data[ws_data_index].push('Data de Criação da Conta')

    // Auth Data
    if (reportConfig && reportConfig.authData) {
        let authDataKeys = Object.keys(reportConfig.authData)
        for (var i = 0; i < authDataKeys.length; i++) {
            let key = authDataKeys[i]
            let currentAuthData = reportConfig.authData[key]
            ws_data[ws_data_index].push(currentAuthData.title)
        }
    }

    // Auth Data Complement
    if (reportConfig && reportConfig.authDataComplementArray) {
        for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
            let authDataComplement = reportConfig.authDataComplementArray[n]
            ws_data[ws_data_index].push(authDataComplement.title)
        }
    }

    modules.forEach(module => {

        let maxScoreInModule = subModules.filter(subModule => { return subModule.moduleId === module.id }).reduce((acc, prev) => { return acc += prev.maxScore }, 0)

        // Coloca nomes das colunas
        let moduleColumnName = `${module.title} (${maxScoreInModule})`
        ws_data[ws_data_index].push(moduleColumnName)

        // Porcentabem
        if (reportConfig && reportConfig.showPercentage) {
            let modulePercentageColumnName = `${module.title} %`
            ws_data[ws_data_index].push(modulePercentageColumnName)
        }

        let moduleSubModules = subModules.filter(subModule => subModule.moduleId === module.id).sort((a, b) => a.pos - b.pos)
        moduleSubModules.forEach(subModule => {
            let maxScoreInSubModule = subModule.maxScore
            let subModuleColumnName = `${subModule.title} (${maxScoreInSubModule})`
            ws_data[ws_data_index].push(subModuleColumnName)

            // Porcentabem
            if (reportConfig && reportConfig.showPercentage) {
                let subModulePercentageColumnName = `${subModule.title} %`
                ws_data[ws_data_index].push(subModulePercentageColumnName)
            }
        })
        ws_data[ws_data_index].push('Status')
    })

    // Próxima linha
    ws_data_index++

    users.sort((a, b) => {
        if (a.username.toLowerCase() > b.username.toLowerCase()) return 1
        else if (a.username.toLowerCase() < b.username.toLowerCase()) return -1
        else return 0
    }).forEach(user => {
        let userScore = _.first(scores.filter(score => score.userId === user.id))
        let primaryPropertyValue = primaryProperty === 'email' ? user.email : user.doc
        ws_data[ws_data_index] = [user.username, primaryPropertyValue || '']

        // Mostra data de criacao
        if (reportConfig && reportConfig.showCreatedDate) {
            let date = new Date(user.createdDateInMillis)
            let formattedDate = `${date.toLocaleDateString("pt-BR")} - ${date.toLocaleTimeString("pt-BR")}`
            ws_data[ws_data_index].push(formattedDate)
        }

        // Auth Data
        if (reportConfig && reportConfig.authData) {
            let authDataKeys = Object.keys(reportConfig.authData)
            for (var i = 0; i < authDataKeys.length; i++) {
                let key = authDataKeys[i]
                let currentAuthData = reportConfig.authData[key]
                let userAuthData = user.authData ? (user.authData[currentAuthData.id] || '-') : '-'
                ws_data[ws_data_index].push(userAuthData)
            }
        }

        // Auth Data Complement
        if (reportConfig && reportConfig.authDataComplementArray) {
            for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
                let authDataComplement = reportConfig.authDataComplementArray[n]
                if (user.authData && user.authData[authDataComplement.authDataId]) {
                    let userAuthDataValue = user.authData[authDataComplement.authDataId]
                    ws_data[ws_data_index].push(authDataComplement.authDataComplementValues[userAuthDataValue] || '')
                } else {
                    ws_data[ws_data_index].push('')
                }
            }
        }

        modules.forEach(module => {
            let userShouldPlayModule = module.allowAccessAuthData ? Object.keys(module.allowAccessAuthData).reduce((acc, key) => {
                // se deu falso pra alguma propriedade ele ja nao precisa jogar
                if (acc === 'false') return acc
                else if (!user.authData) return 'false'
                else if (module.allowAccessAuthData![key].filter(val => val === user.authData![key]).length === 0) return 'false'
                else return acc
            }, 'true') === 'true' : true
            let moduleSubModules = subModules.filter(subModule => subModule.moduleId === module.id).sort((a, b) => a.pos - b.pos)
            let userScoreInModule = userShouldPlayModule ? moduleSubModules.reduce((acc: number, subModule) => acc + (userScore ? userScore.subModuleScores[subModule.id] || 0 : 0), 0) : '-'
            ws_data[ws_data_index].push(userScoreInModule)

            // Porcentagem
            if (reportConfig && reportConfig.showPercentage) {
                let maxScoreInModule = moduleSubModules.reduce((acc, prev) => { return acc += prev.maxScore }, 0)
                if (maxScoreInModule > 0) {
                    if (userScoreInModule === '-') ws_data[ws_data_index].push('-')
                    else ws_data[ws_data_index].push(((+userScoreInModule * 100) / maxScoreInModule).toFixed(2))
                } else {
                    ws_data[ws_data_index].push('-')
                }
            }

            var hasFinished = true
            moduleSubModules.forEach(subModule => {
                let subModuleScore = userShouldPlayModule ? (userScore ? userScore.subModuleScores[subModule.id] || 0 : 0) : '-'
                if (subModuleScore < subModule.maxScore / 2) hasFinished = false
                ws_data[ws_data_index].push(subModuleScore.toString())

                // Porcentagem
                if (reportConfig && reportConfig.showPercentage) {
                    if (subModule.maxScore > 0) {
                        if (subModuleScore === '-') ws_data[ws_data_index].push('-')
                        else ws_data[ws_data_index].push(((+subModuleScore * 100) / subModule.maxScore).toFixed(2))
                    } else {
                        ws_data[ws_data_index].push('-')
                    }
                }
            })
            let status = userShouldPlayModule ? (hasFinished ? 'Concluído' : 'Não terminou') : 'Não precisa jogar'
            ws_data[ws_data_index].push(status)
        })
        // Próxima linha
        ws_data_index++
    })

    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, `Relatório`);
    let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: fileType });
}

export const usersDidntCreatedAccountData = (primaryProperty: string, reportConfig: ReportConfig | undefined, userAccess: Array<UserAccessValidation>) => {

    let wb = XLSX.utils.book_new()
    var ws_data: Array<any> = []
    var ws_data_index = 0

    let primaryPropertyTitle = primaryProperty === 'email' ? 'Email' : 'CPF'

    // Coloca nomes das colunas
    ws_data[ws_data_index] = ['Nome', primaryPropertyTitle]

    // Auth Data
    if (reportConfig && reportConfig.authData) {
        let authDataKeys = Object.keys(reportConfig.authData)
        for (var i = 0; i < authDataKeys.length; i++) {
            let key = authDataKeys[i]
            let currentAuthData = reportConfig.authData[key]
            ws_data[ws_data_index].push(currentAuthData.title)
        }
    }

    // Auth Data Complement
    if (reportConfig && reportConfig.authDataComplementArray) {
        for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
            let authDataComplement = reportConfig.authDataComplementArray[n]
            ws_data[ws_data_index].push(authDataComplement.title)
        }
    }

    // Próxima linha
    ws_data_index++

    userAccess.forEach(accessValue => {

        ws_data[ws_data_index] = [accessValue.name || '', accessValue.primaryPropertyValue]

        // Auth Data
        if (reportConfig && reportConfig.authData) {
            let authDataKeys = Object.keys(reportConfig.authData)
            for (var i = 0; i < authDataKeys.length; i++) {
                let key = authDataKeys[i]
                let currentAuthData = reportConfig.authData[key]
                let userAuthData = accessValue.authData ? (accessValue.authData[currentAuthData.id] || '-') : '-'
                ws_data[ws_data_index].push(userAuthData)
            }
        }

        // Auth Data Complement
        if (reportConfig && reportConfig.authDataComplementArray) {
            for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
                let authDataComplement = reportConfig.authDataComplementArray[n]
                if (accessValue.authData && accessValue.authData[authDataComplement.authDataId]) {
                    let userAuthDataValue = accessValue.authData[authDataComplement.authDataId]
                    ws_data[ws_data_index].push(authDataComplement.authDataComplementValues[userAuthDataValue] || '')
                } else {
                    ws_data[ws_data_index].push('')
                }
            }
        }

        // Próxima linha
        ws_data_index++

    })

    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, `Relatório`);
    let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: fileType });
}

export const allUsersData = (primaryProperty: string, reportConfig: ReportConfig | undefined, composedData: Array<ComposedData>) => {

    let wb = XLSX.utils.book_new()
    var ws_data: Array<any> = []
    var ws_data_index = 0

    let primaryPropertyTitle = primaryProperty === 'email' ? 'Email' : 'CPF'

    // Coloca nomes das colunas
    ws_data[ws_data_index] = ['Nome', primaryPropertyTitle, 'Usuário acessou e definiu senha', 'Data de acesso e definição de senha']

    // Auth Data
    if (reportConfig && reportConfig.authData) {
        let authDataKeys = Object.keys(reportConfig.authData)
        for (var i = 0; i < authDataKeys.length; i++) {
            let key = authDataKeys[i]
            let currentAuthData = reportConfig.authData[key]
            ws_data[ws_data_index].push(currentAuthData.title)
        }
    }

    // Auth Data Complement
    if (reportConfig && reportConfig.authDataComplementArray) {
        for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
            let authDataComplement = reportConfig.authDataComplementArray[n]
            ws_data[ws_data_index].push(authDataComplement.title)
        }
    }

    // Próxima linha
    ws_data_index++

    composedData.forEach(data => {

        let name = data.user ? data.user.username : data.userAccess?.name ?? ''
        let accountCreated = data.user ? 'Sim' : 'Não'
        let createdDate = (data.user && data.user.createdDateInMillis) ? new Date(data.user.createdDateInMillis) : undefined
        let formattedCratedDate = createdDate ? `${createdDate.toLocaleDateString("pt-BR")} - ${createdDate.toLocaleTimeString("pt-BR")}` : ''

        ws_data[ws_data_index] = [name, data.primaryProperty, accountCreated, formattedCratedDate]

        // Auth Data
        if (reportConfig && reportConfig.authData) {
            let authDataKeys = Object.keys(reportConfig.authData)
            for (var i = 0; i < authDataKeys.length; i++) {
                let key = authDataKeys[i]
                let currentAuthData = reportConfig.authData[key]
                let userAuthData = data.user?.authData ?? data.userAccess?.authData ?? undefined
                ws_data[ws_data_index].push(userAuthData ? (userAuthData[currentAuthData.id] || '-') : '-')
            }
        }

        // Auth Data Complement
        if (reportConfig && reportConfig.authDataComplementArray) {
            for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
                let authDataComplement = reportConfig.authDataComplementArray[n]
                let userAuthData = data.user?.authData ?? data.userAccess?.authData ?? undefined
                if (userAuthData && userAuthData[authDataComplement.authDataId]) {
                    let userAuthDataValue = userAuthData[authDataComplement.authDataId]
                    ws_data[ws_data_index].push(authDataComplement.authDataComplementValues[userAuthDataValue] || '')
                } else {
                    ws_data[ws_data_index].push('')
                }
            }
        }

        // Próxima linha
        ws_data_index++
    })

    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, `Relatório`);
    let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: fileType });
}