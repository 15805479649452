export default class CardImage {
    id: string
    imageUrl: string
    tag: string
    companyId: string
    moduleId?: string
    subModuleId?: string

    constructor(data: any) {
        this.id = data.id
        this.imageUrl = data.imageUrl
        this.tag = data.tag
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
    }
}