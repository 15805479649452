import React, { Component, Fragment } from 'react';
import Module from '../../models/Module';
import { UserMaxScoreInSubModule } from '../../models/Scores';
import { SubModule, SubModuleTypes } from '../../models/SubModule';
import Company from '../../models/Company';
import { Row, Col } from 'reactstrap';
import { CompanyPropInterface } from '../shared/CompanyPropInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faGamepad } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'
import './style.css'
import StarSvg from '../../images/star.svg'
import ProgressBar from '../../components/ProgressBar'
import { lowerCaseAndRemovePunctuationFromString } from '../../utils/punctuation';
import { ContentConfig } from '../../models/CompanyConfig';
import { getModulesFromCategory, getSubModulesFinished, getUserScoreInModule } from '../../utils/moduleUtils';

export interface SelectModuleProps extends CompanyPropInterface {
    contentConfig?: ContentConfig
    categorySelected: string
    modules: Array<Module>
    companySubModules: Array<SubModule>
    userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>
    searchInput: string
    playModule: (companyId: string, moduleId: string) => void
    onCategorySelected: (id: string) => void
}

export default class SelectModuleComponent extends Component<SelectModuleProps> {

    renderProgressText(userScore: number, color: string) {
        return (<div className='d-flex align-items-center' style={{ marginTop: 10 }}>
            <img style={{ width: '0.95em', height: '0.95em', paddingBottom: 2, opacity: color === '#40b34f' ? 1 : 0.25 }} alt='ícone de troféu' src={StarSvg} />
            <div style={{ marginLeft: 2, color, height: '1em', lineHeight: '1em', fontSize: '1em' }}><b>{userScore}</b></div>
        </div>)
    }

    renderModules(company: Company, modules: Array<Module>, companySubModules: Array<SubModule>, userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>) {
        let searchInputWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(this.props.searchInput)
        let sortedModulesByPosition = modules.filter(module => {
            let moduleTitleWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(module.title)
            return moduleTitleWithoutPunctuation.includes(searchInputWithoutPunctuation)
        }).sort((a, b) => { return a.pos - b.pos })
        let modulesStatus = sortedModulesByPosition.map(module => {
            let userScoreInModule = getUserScoreInModule(module.id, userMaxScoreInSubModules)
            let moduleSubModules = companySubModules.filter(data => data.moduleId === module.id)
            return { userScoreInModule, moduleSubModules, module }
        })
        return modulesStatus.map(({ userScoreInModule, moduleSubModules, module }, index) => {
            let subModulesFinished = getSubModulesFinished(moduleSubModules, userMaxScoreInSubModules)
            let subModulesCanPlayLength = moduleSubModules.filter(subModule => subModule.type !== SubModuleTypes.LINK && subModule.type !== SubModuleTypes.LIVE).length
            let progressBarColor = subModulesFinished === 0 ? '#868686' : (subModulesFinished === subModulesCanPlayLength ? '#40b34f' : (subModulesFinished > (subModulesCanPlayLength * 0.5) ? '#f1b938' : '#f0652f'))
            let isOdd = index % 2
            return (<Col key={module.id} className="d-flex flex-column" lg={{ size: 5, offset: isOdd ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
                <div className="d-flex flex-row" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 20, marginTop: 20, minHeight: 120, borderRadius: 5, paddingLeft: 10 }} onClick={() => { this.props.playModule(module.companyId, module.id) }}>
                    <div className="d-flex flex-column justify-content-center mt-2" style={{ width: '100%' }}>
                        <div className="d-flex align-items-center" style={{ marginBottom: 2 }}>
                            <img className="moduleImage" alt='foto da missão' src={module.pic} />
                            <div style={{ marginLeft: 10, flex: 1, color: '#353f45' }}>
                                <div style={{ verticalAlign: 'middle' }}><b>MISSÃO</b></div>
                                <div style={{ verticalAlign: 'middle' }}>{module.title.toUpperCase()}</div>
                            </div>
                        </div>
                        {this.renderProgressText(userScoreInModule, progressBarColor)}
                        <div style={{ marginTop: 5, marginBottom: 10 }}><ProgressBar bgColor={progressBarColor} completed={subModulesFinished / subModulesCanPlayLength * 100} height={8} radius={2} /></div>
                        <div className="d-flex mb-2">
                            <div className="mr-auto" style={{ color: subModulesFinished === subModulesCanPlayLength ? '#40b34f' : '#868686', verticalAlign: 'middle' }}><FontAwesomeIcon style={{ marginRight: 4 }} icon={faGamepad} /><b>{subModulesFinished}</b> de <b>{subModulesCanPlayLength}</b> fases</div>
                        </div>
                    </div>
                    <div style={{ margin: 'auto', padding: 5 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                </div></Col>)
        })
    }

    renderCategories(categories: Array<{ id: string, title: string }>, modules: Array<Module>) {
        let modulesWithoutCategory = modules.filter(module => { return module.categories.length === 0 })
        return <Fragment>
            <Row>
                <Col className="mt-3" md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#353f45' }}>
                        <span style={{ fontSize: 'large' }}><b>SELECIONE UMA CATEGORIA</b></span>
                    </div>
                </Col>
            </Row>
            <Row style={{ overflow: 'auto', paddingBottom: 10, zIndex: 1 }}>
                {categories.map(({ id, title }, index) => {
                    let isOdd = index % 2
                    let categoryModules = getModulesFromCategory(modules, id)
                    return <Col key={id} className="d-flex flex-column" lg={{ size: 5, offset: isOdd ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
                        <div className="d-flex flex-row" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 20, marginTop: 20, minHeight: 120, borderRadius: 5, paddingLeft: 10 }} onClick={() => { this.props.onCategorySelected(id) }}>
                            <div className="d-flex flex-column justify-content-center" style={{ width: '100%' }}>
                                <div className='cursorPointer' style={{ verticalAlign: 'middle', fontSize: 'large' }}><b>{title}</b></div>
                                <div className="mr-auto" style={{ color: '#868686', verticalAlign: 'middle' }}><FontAwesomeIcon style={{ marginRight: 4 }} icon={faGamepad} />{categoryModules.length === 1 ? '1 Missão' : `${categoryModules.length} Missões`}</div>
                            </div>
                            <div style={{ margin: 'auto', padding: 10 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                        </div>
                    </Col>
                })}
                {modulesWithoutCategory.length > 0 && <Col className="d-flex flex-column" lg={{ size: 5, offset: (categories.length % 2) ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
                    <div className="d-flex flex-row" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 20, marginTop: 20, minHeight: 120, borderRadius: 5, paddingLeft: 10 }} onClick={() => { this.props.onCategorySelected('none') }}>
                        <div className="d-flex flex-column justify-content-center" style={{ width: '100%' }}>
                            <div className='cursorPointer' style={{ verticalAlign: 'middle', fontSize: 'large' }}><b>Outros</b></div>
                            <div className="mr-auto" style={{ color: '#868686', verticalAlign: 'middle' }}><FontAwesomeIcon style={{ marginRight: 4 }} icon={faGamepad} />{modulesWithoutCategory.length === 1 ? '1 Missão' : `${modulesWithoutCategory.length} Missões`}</div>
                        </div>
                        <div style={{ margin: 'auto', padding: 10 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                    </div>
                </Col>}
            </Row>
        </Fragment>
    }

    renderMainContent(company: Company, modules: Array<Module>, companySubModules: Array<SubModule>, userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>, contentConfig: ContentConfig | undefined, selectedCategory: string) {
        if (contentConfig && contentConfig.moduleCategories && contentConfig.moduleCategories.length > 0) {
            if (selectedCategory !== '') {
                if (selectedCategory === 'none') {
                    return <Row style={{ overflow: 'auto', paddingBottom: 10, zIndex: 1 }}>
                        {this.renderModules(company, modules.filter(module => {
                            return module.categories.length === 0
                        }), companySubModules, userMaxScoreInSubModules)}
                    </Row>
                } else {
                    return <Row style={{ overflow: 'auto', paddingBottom: 10, zIndex: 1 }}>
                        {this.renderModules(company, modules.filter(module => {
                            return module.categories.filter(id => { return id === selectedCategory }).length > 0
                        }), companySubModules, userMaxScoreInSubModules)}
                    </Row>
                }
            } else {
                let categoriesInModules = modules.reduce((acc, module) => {
                    for (var i = 0; i < module.categories.length; i++) {
                        let currentModuleCategory = module.categories[i]
                        if (acc.filter(category => { return category === currentModuleCategory }).length === 0) {
                            acc.push(currentModuleCategory)
                        }
                    }
                    return acc
                }, [] as Array<string>)
                if (categoriesInModules.length > 0) {
                    let filteredCategories = contentConfig.moduleCategories.filter(category => {
                        return categoriesInModules.filter(categoryInModule => { return category.id === categoryInModule }).length > 0
                    })
                    return this.renderCategories(filteredCategories, modules)
                }
            }
        }
        return (
            <Row style={{ overflow: 'auto', paddingBottom: 10, zIndex: 1 }}>
                {this.renderModules(company, modules, companySubModules, userMaxScoreInSubModules)}
            </Row>)
    }

    render() {
        let { company, modules, companySubModules, userMaxScoreInSubModules, contentConfig, categorySelected } = this.props
        return this.renderMainContent(company, modules, companySubModules, userMaxScoreInSubModules, contentConfig, categorySelected)
    }

}