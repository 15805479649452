export default class Module {
    id: string
    companyId: string
    title: string
    pic: string
    pos: number
    visible: boolean
    description?: string 
    open?: boolean
    allowAccessAuthData?: { [key: string]: [string] } 
    limiterQuiz: number;
    frequency: string;
    hasCertificate?: boolean
    hideRealtimeQuizButton?: boolean
    categories: Array<string>
    hasLimitedAccess: boolean
    feedback?: {
        title: string,
        description: string,
        minCharCount: number,
        // mostra o certificado so depois de responder o feedback
        blockCertificate: boolean
    }

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.title = data.title
        this.pic = data.pic
        this.pos = data.pos
        this.visible = data.visible
        this.description = data.description
        this.open = data.open
        this.visible = data.visible
        this.allowAccessAuthData = data.allowAccessAuthData
        this.limiterQuiz = data.limiterQuiz || 0;
        this.frequency = data.frequency || 'Day';
        this.hasCertificate = data.hasCertificate
        this.hideRealtimeQuizButton = data.hideRealtimeQuizButton
        this.categories = data.categories || []
        this.hasLimitedAccess = typeof data.hasLimitedAccess === 'undefined' ? false : data.hasLimitedAccess
        this.feedback = data.feedback
    }
}

export const Frequency = {
    Day: 'Day',
    Week: 'Week',
    Month: 'Month'
};

export const enum ModuleTemplateType {
    DEFAULT = 0,
    SHORT = 1,
    VIDEO = 2,
    URL_AI = 3
}