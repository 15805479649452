import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';
import UserAccessValidation from '../../../models/UserAccessValidation';

interface Props { userAccess: UserAccessValidation }

export default class UserAccessComponent extends Component<Props> {

    renderAuthDataProperty(val: string) {
        return <div style={{ fontSize: 12, marginBottom: 5, color: '#1d3256' }}>{val}</div>
    }

    render() {
        let userAccess = this.props.userAccess
        return <div key={userAccess.primaryPropertyValue} className="d-flex flex-column" style={{ paddingLeft: 5 }}>
            <div style={{ fontSize: 12, marginTop: 5, color: '#1d3256' }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faInfoCircle} />{userAccess.primaryPropertyValue}</div>
            <div style={{ fontSize: 18, color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', marginTop: 5 }}>{userAccess.name || ''}</div>
            {userAccess.authData && Object.keys(userAccess.authData).sort().map(key => this.renderAuthDataProperty(userAccess.authData![key]))}
            {userAccess.phone && <div style={{ fontSize: 12, marginTop: 5, color: '#1d3256' }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faPhone} />{userAccess.phone}</div>}
            <span style={{ marginTop: 5, fontSize: 'small', color: '#1d3256' }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faClock} /><b>Aguardando cadastro de senha</b></span>
        </div>
    }
}