import React, { Component } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap'

interface Props { image: string | undefined }

class LoadingScreen extends Component<Props> {

    public static defaultProps = {
        image: undefined
    };

    render() {
        return (<Container className="d-flex flex-column justify-content-center" fluid style={{ minHeight: '100vh', background: 'white' }}>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        {this.props.image && <img style={{ maxHeight: '20vh', marginBottom: 5, borderRadius: 5 }} src={this.props.image}/>}
                    </div>
                    <div className="d-flex justify-content-center">
                        <Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />{' '}
                    </div>
                </Col>
            </Row>
        </Container>)
    }
}

export default LoadingScreen;