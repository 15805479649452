export class UserCompanyScore {

    companyId: string
    userId: string
    subModuleScores: { [subModuleId: string]: number }
    totalScore: number

    constructor(data: any) {
        this.companyId = data.companyId
        this.userId = data.userId
        this.subModuleScores = data.subModuleScores
        this.totalScore = data.totalScore
    }

    updateSubModuleScore(subModuleId: string, newScore: number): boolean {
        let shouldUpdateScore = (this.subModuleScores[subModuleId] && this.subModuleScores[subModuleId] < newScore) || !this.subModuleScores[subModuleId]
        if (shouldUpdateScore) {
            this.subModuleScores[subModuleId] = newScore
            this.updateTotalScore()
        }
        return shouldUpdateScore
    }

    private updateTotalScore() {
        var updatedScore = 0
        for (let subModuleId in this.subModuleScores) {
            updatedScore += this.subModuleScores[subModuleId]
        }
        this.totalScore = updatedScore
    }

    getData(): any {
        return {
            companyId: this.companyId,
            userId: this.userId,
            subModuleScores: this.subModuleScores,
            totalScore: this.totalScore
        }
    }
}

export class UserModuleScore extends UserCompanyScore {
    moduleId: string

    constructor(data: any) {
        super(data)
        this.moduleId = data.moduleId
    }

    getData(): any {
        return {
            ...super.getData(),
            moduleId: this.moduleId
        }
    }
}

export class MonthUserCompanyScore extends UserCompanyScore {
    month: number
    year: number

    constructor(data: any) {
        super(data)
        this.month = data.month
        this.year = data.year
    }

    getData(): any {
        return {
            ...super.getData(),
            month: this.month,
            year: this.year
        }
    }
}

export class MonthUserModuleScore extends MonthUserCompanyScore {
    moduleId: string

    constructor(data: any) {
        super(data)
        this.moduleId = data.moduleId
    }

    getData(): any {
        return {
            ...super.getData(),
            moduleId: this.moduleId
        }
    }
}

export class WeekUserCompanyScore extends MonthUserCompanyScore {
    firstDayOfWeek: number
    constructor(data: any) {
        super(data)
        this.firstDayOfWeek = data.firstDayOfWeek
    }

    getData(): any {
        let data = super.getData()
        data.firstDayOfWeek = this.firstDayOfWeek
        return data
    }
}

export class WeekUserModuleScore extends WeekUserCompanyScore {
    moduleId: number
    constructor(data: any) {
        super(data)
        this.moduleId = data.moduleId
    }

    getData(): any {
        let data = super.getData()
        data.moduleId = this.moduleId
        return data
    }
}

export class UserMaxScoreInSubModule {

    userId: string
    score: number
    companyId: string
    moduleId: string
    subModuleId: string
    date_in_millis: number

    constructor(data: any) {
        this.userId = data.userId
        this.score = data.score
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
        this.date_in_millis = data.date_in_millis
    }

    getData(): any {
        return {
            userId: this.userId,
            score: this.score,
            companyId: this.companyId,
            moduleId: this.moduleId,
            subModuleId: this.subModuleId,
            date_in_millis: this.date_in_millis
        }
    }
}