import VideoFrameGrabber from "./VideoFrameGrabber"

export interface IRiff {
  RIFF: [
    {
      WEBP: string[],
    }
  ]
}

export interface IWebP {
  width: number
  height: number
  data: string
  riff: IRiff
}

export interface IWebPFrame extends IWebP {
  duration: number
}

export interface IFramesInfo {
  duration: number
  width: number
  height: number
}

export interface ISimpleBlockData {
  discardable: number
  frame: string
  invisible: number
  keyframe: number
  lacing: number
  trackNum: number
  timecode: number
}

export interface IEBML extends Array<IEBMLItem> { }

export interface IEBMLItem {
  id: number
  data: any
  size?: number
}

export class VideoElement {
  image: HTMLImageElement | undefined
  frameGrabber: VideoFrameGrabber | undefined
  scale: number
  animated: boolean
  alpha: number
  horizontalPosition: number
  verticalPosition: number

  constructor(image: HTMLImageElement | undefined, frameGrabber: VideoFrameGrabber | undefined, scale: number, animated: boolean, alpha: number, horizontalPosition: number, verticalPosition: number) {
    this.image = image
    this.frameGrabber = frameGrabber
    this.scale = scale
    this.animated = animated
    this.alpha = alpha
    this.horizontalPosition = horizontalPosition
    this.verticalPosition = verticalPosition
  }

  draw(ctx: CanvasRenderingContext2D, zoom: number, zoomWidth: number, zoomHeight: number, currentFrame: number, framesInAnimation: number, isPreview: boolean) {
    if (this.image) {
      //let yPos = this.verticalPosition * zoom
      var scaledImageHeight = this.image.height * this.scale * zoom;
      var scaledImageWidth = this.image.width * this.scale * zoom;
      let currentAnimationProgress = currentFrame > framesInAnimation ? 1 : currentFrame / framesInAnimation
      if (!isPreview) {
        if (currentAnimationProgress > this.alpha) currentAnimationProgress = this.alpha
      } else {
        currentAnimationProgress = this.alpha
      }

      ctx.globalAlpha = currentAnimationProgress;
      ctx.drawImage(this.image, (zoomWidth - scaledImageWidth) * this.horizontalPosition, (zoomHeight - scaledImageHeight) * this.verticalPosition, scaledImageWidth, scaledImageHeight);
      ctx.globalAlpha = 1;
    } else if (this.frameGrabber && this.frameGrabber.isVideoLoaded) {
      //let yPos = this.verticalPosition * zoom
      var scaledVideoHeight = this.frameGrabber.videoHeight * this.scale * zoom;
      var scaledVideoWidth = this.frameGrabber.videoWidth * this.scale * zoom;
      let currentAnimationProgress = currentFrame > framesInAnimation ? 1 : currentFrame / framesInAnimation
      if (!isPreview) {
        if (currentAnimationProgress > this.alpha) currentAnimationProgress = this.alpha
      } else {
        currentAnimationProgress = this.alpha
      }

      ctx.globalAlpha = currentAnimationProgress;
      ctx.drawImage(this.frameGrabber.video, (zoomWidth - scaledVideoWidth) * this.horizontalPosition, (zoomHeight - scaledVideoHeight) * this.verticalPosition, scaledVideoWidth, scaledVideoHeight);
      ctx.globalAlpha = 1;
    }
  }
}

export interface SelectedVideoFile {
  file: string,
  pos: number,
  thumbnail?: string,
}

export interface SavingFile {
  filename: string,
  duration: number,
  text: string,
  verticalStart: number,
  horizontalStart: number,
  font: string,
  fontSize: number,
  fontLineHeight: number,
  textColor: string,
  textBackgroundColor: string,
  textBackgroundType: "fixed_left" | "around_text" | "fixed_right",
  backgroundImage: HTMLImageElement | undefined,
  videoFrameGrabber: VideoFrameGrabber | undefined,
  backgroundVerticalPosition: number,
  audioFile: string,
  videoElements: VideoElement[],
  showStartTransition: boolean,
  showEndTransition: boolean,
  transitionColor: string,
  progress: number,
}