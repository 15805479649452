export const getImage = (imagePath: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const image = new Image();

        // Set the source of the Image object to the URL of your background image
        image.src = imagePath;
        image.crossOrigin = 'Anonymous';

        // Once the image has loaded, draw it on the canvas
        image.onload = function () {
            resolve(image)
        };

        image.onerror = function (err) {
            reject(err)
        }
    })
}

// só por causa da BOSTA do Firefox
export const roundRect = (ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, r: number | { tl: number, tr: number, br: number, bl: number }, fill: boolean, stroke: boolean) => {
    let radius: { tl: number, tr: number, br: number, bl: number } = { tl: 0, tr: 0, br: 0, bl: 0 };
    if (typeof r === 'number') {
        radius = { tl: r, tr: r, br: r, bl: r };
    } else {
        radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...r };
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
        ctx.fill();
    }
    if (stroke) {
        ctx.stroke();
    }
} 