import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { InputGroup, InputGroupText } from 'reactstrap';
import { Col, FormGroup, Input, Row } from 'reactstrap';

import { AuthData } from "../../../../models/CompanyConfig"


interface SearchInputViewProps {
    updateSearchInput: (input: string) => void
    updateAuthDataSelection: (selection: string | undefined, id: string) => void
    authData?: { [id: string]: AuthData }
    authDataSelectedValues: { [id: string]: string }
}

export const SearchInputView = (props: SearchInputViewProps) => {
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        const interval = setTimeout(() => {
            props.updateSearchInput(searchInput)
        }, 500)
        return () => clearInterval(interval)
    }, [searchInput])

    let renderAuthDataSelection = (authData: { [id: string]: AuthData }, authDataSelectedValues: { [id: string]: string }) => {
        let authDataKeys = Object.keys(authData)
        return (<div style={{ marginBottom: 5, marginTop: 10 }}>
            {authDataKeys.filter(key => !authData[key].show).map((id, index) => {
                let currentAuthData = authData[id]
                let selectedAuthDataValue = authDataSelectedValues[id] || 'Nenhum'

                if (Array.isArray(currentAuthData.values) && currentAuthData.values.length > 0) {

                    let options = currentAuthData.values.map(val => { return { label: val, value: val } })
                    return <FormGroup key={index} style={{ marginBottom: 10 }}>
                        <Select isClearable placeholder={`Filtrar por ${currentAuthData.title}`} options={options} onChange={opt => props.updateAuthDataSelection(opt ? opt.label : undefined, id)} />
                    </FormGroup>
                } else {
                    return (
                        <FormGroup key={index}>
                            <Input style={{ fontFamily: 'Montserrat' }} name={currentAuthData.title} id={id} value={selectedAuthDataValue} placeholder={`Filtrar por ${currentAuthData.title}`} onChange={(event: any) => props.updateAuthDataSelection(event.target.value as string, id)} />
                        </FormGroup>
                    )
                }
            })}
        </div>)
    }

    return (<Row style={{ marginTop: 5 }}>
        <Col md={{ size: 8, offset: 2 }}>
            <InputGroup>
                <InputGroupText><FontAwesomeIcon icon={faSearch} /></InputGroupText>
                <Input style={{ fontFamily: 'Montserrat' }} name="title" id="docTitle" placeholder='Procurar usuário' value={searchInput} onChange={(event: any) => {
                    setSearchInput(event.target.value)
                }} />
            </InputGroup>
        </Col>
        {props.authData && <Col md={{ size: 8, offset: 2 }}>
            {renderAuthDataSelection(props.authData, props.authDataSelectedValues)}
        </Col>}
    </Row>)

}