import React, { Component } from 'react';
import User from '../../../models/User';
import { Button, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamation, faStar, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { OponentUpdate } from './index'

interface State {
    oponentScore: number,
    oponentStatus: string
};

interface Props { oponent: User, onExit: () => void }

export default class OponentScoreComponent extends Component<Props, State> implements OponentUpdate {

    constructor(props: Props) {
        super(props);
        this.state = { oponentScore: 0, oponentStatus: 'connected' }
    }

    updateOponentScore = (oponentScore: number) => {
        this.setState({ oponentScore })
    }

    oponentStatusChanged = (oponentStatus: string) => {
        this.setState({ oponentStatus })
    }

    renderUserProfilePic(user: User) {
        if (!user.pic) return (<div><FontAwesomeIcon color='#1d3256' icon={faUserCircle} size='4x' /></div>)
        if (user.thumbnail) return <img style={{ width: '4em', height: '4em', borderRadius: '2em', objectFit: 'cover' }} alt='prof pic' src={user.thumbnail} />
        else return <img style={{ width: '4em', height: '4em', borderRadius: '2em', objectFit: 'cover' }} alt='prof pic' src={user.pic} />
    }

    renderStatus(oponentScore: number, oponentStatus: string) {
        if (oponentStatus === 'disconnected') {
            return <div style={{ color: '#F0220B', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}><FontAwesomeIcon icon={faExclamation} />A conexão do usuário caiu</div>
        } else {
            return <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}><FontAwesomeIcon icon={faStar} /> <b>{oponentScore}</b></div>
        }
    }

    render() {
        let user = this.props.oponent
        let { oponentScore, oponentStatus } = this.state
        return (<Container fluid className='d-flex flex-column' style={{ background: 'white' }}>
            <Row style={{ boxShadow: '2px 1px 2px 1px rgba(0,0,0,0.2)', background: 'white', paddingTop: 5, paddingBottom: 5 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex align-items-center" style={{ background: 'white', margin: 5, padding: 5 }}>
                        <div style={{ alignSelf: 'center' }}>
                            <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        </div>
                        {this.renderUserProfilePic(user)}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}>{user.username}</div>
                            {this.renderStatus(oponentScore, oponentStatus)}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>)
    }
}