import React, { useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface Props {
    moduleId: string,
    title: string,
    description: string,
    minCharCount: number,
    blockCertificate: boolean
    onClose: () => void
    onUpdate: (title: string, description: string, minCharCount: number, blockCertificate: boolean) => void
}

export const SetModuleFeedbackModal = ({ moduleId, title, description, minCharCount, blockCertificate, onClose, onUpdate }: Props) => {

    const [inputTitle, setInputTitle] = useState<string>(title)
    const [inputDescription, setInputDescription] = useState<string>(description)
    const [inputMinCharCount, setInputMinCharCount] = useState<number>(minCharCount)


    return <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }} toggle={() => onClose()}>
        <ModalHeader>
            <div>Editar Autoavaliação</div>
        </ModalHeader>
        <ModalBody>
            <FormGroup>
                <Label for="title">Título</Label>
                <Input style={{ opacity: 0.85 }} name="title" id="title" placeholder='Título da autoavaliação' value={inputTitle} onChange={(event) => setInputTitle(event.target.value)} />
            </FormGroup>
            <FormGroup>
                <Label for="description">Descrição</Label>
                <Input style={{ opacity: 0.85 }} name="title" id="title" placeholder='Descrição da autoavaliação' value={inputDescription} onChange={(event) => setInputDescription(event.target.value)} />
            </FormGroup>
            <FormGroup>
                <Label for="minChar">Quantidade mínima de caracteres</Label>
                <Input style={{ opacity: 0.85 }} name="chars" id="chars" placeholder='Caracteres mínimos da autoavaliação' value={inputMinCharCount} type='number' onChange={(event) => setInputMinCharCount(+event.target.value)} />
            </FormGroup>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center align-items-center' >
            <Button color="success" onClick={() => { onUpdate(inputTitle, inputDescription, inputMinCharCount, blockCertificate) }}>Salvar</Button>
            <Button color="secondary" onClick={() => onClose()}>Sair</Button>
        </ModalFooter>
    </Modal>
}