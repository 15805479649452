export default class FileHelper {

    getFileDataUrl(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = () => {
                let fileDataUrl = String(reader.result)
                resolve(fileDataUrl)
            }
            reader.onerror = () => { reject({ message: 'error fetching data url' }) }
            reader.readAsDataURL(file)
        })
    }

    loadImage(imageDataUrl: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            let image = new Image()
            image.onload = () => { resolve(image) }
            image.onerror = () => { reject({ message: 'error fetching image from data url' }) }
            image.src = imageDataUrl
        })
    }

    resizeImage(canvas: HTMLCanvasElement, image: HTMLImageElement, maxWidth: number, maxHeight: number): Promise<Blob> {

        var width = image.width;
        var height = image.height;

        if (width > height) {
            if (width > maxWidth) {
                height = Math.round(height * maxWidth / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round(width * maxHeight / height);
                height = maxHeight;
            }
        }

        canvas.width = width;
        canvas.height = height;

        let ctx = canvas.getContext("2d");
        ctx!.drawImage(image, 0, 0, width, height);

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (blob) resolve(blob)
                else reject()
            })
        })
    }
}