import React, { Component } from 'react';
import { useNavigate } from 'react-router';
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import * as ROUTES from '../../constants/routes'
import Cookies from 'universal-cookie';
import Company from '../../models/Company';
import { useUser } from '../../hooks/useUser';


const LandingScreen = () => {

    const navigate = useNavigate()
    const { user, company, noUser, noCompany } = useUser()

    React.useEffect(() => {
        if (company) {
            if (user) navigate(`/${company.id}${ROUTES.MAIN}`, { replace: true })
            else if (noUser) navigate(`/${ROUTES.APP_LANDING}${ROUTES.ENTER}/${company.id}`, { replace: true })
        }
    }, [noUser, noCompany, company, user])


    return (<Container fluid style={{ minHeight: '100vh' }}>
        <Row>
            <Col style={{ marginTop: '5em' }} md={{ size: 6, offset: 3 }}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <FontAwesomeIcon color='#1d3256' icon={faExclamationCircle} size='4x' />
                    <div style={{ fontSize: 16, color: 'black', fontFamily: 'Montserrat', textAlign: 'center', verticalAlign: 'middle', marginTop: 15, marginLeft: 10, marginRight: 10 }}><b>Ops, esse link não está certo...</b></div>
                    <div style={{ fontSize: 16, color: 'black', fontFamily: 'Montserrat', textAlign: 'center', verticalAlign: 'middle', marginTop: 15, marginLeft: 10, marginRight: 10 }}>Para acessar o aplicativo você precisa utilizar um link válido da sua empresa</div>
                </div>
            </Col>
        </Row>
    </Container>)

}

export default LandingScreen