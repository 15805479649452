import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Button, Input, InputGroup, Label } from "reactstrap";
import { SliderConfig } from "./interfaces";

interface ManageClipComponentProps {
    sliders: Array<SliderConfig>
    videoUrl: string
    onExit: () => void
    scale: number
    setScale?: (scale: number) => void
}

export default function ManageClipComponent(props: ManageClipComponentProps) {
    

    return <div className="bg-light p-2 m-2 rounded border">
        <Button color="light" size="sm" onClick={() => props.onExit()}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
        <div className="d-flex flex-column justify-content-center m-2">
            <video style={{ height: 180, marginBottom: 5, marginTop: 10, objectFit: 'contain' }} src={props.videoUrl} />
        </div>
        {props.sliders.map((sliderConfig, index) => {
            return <div key={`SliderConfig_${index}`}>
                <InputGroup>
                    <Label for={`ImageSlider_${index}`}>{sliderConfig.title} {sliderConfig.value}</Label>
                    <Input id={`ImageSlider_${index}`} value={sliderConfig.value} name={`ImageSlider_${index}`} type="range" min={sliderConfig.min} max={sliderConfig.max} step={sliderConfig.step} onChange={e => sliderConfig.setSliderValue(+e.target.value)} />
                </InputGroup>
            </div>
        })}
        {props.setScale && <InputGroup>
            <Label for="imageScale">Escala: {props.scale}</Label>
            <Input id="imageScale" value={props.scale} name="imageHorizontalPos" type="range" min={0.05} max={2} step={0.05} onChange={e => props.setScale!(+e.target.value)} />
        </InputGroup>}
    </div>
}