import React, { Component } from 'react';
import { ReportConfig } from '../../../../models/CompanyConfig';
import { ComposedData, UserFilter, getFilteredComposedData, getUserFilterText, getUsersWhoFinishedModule } from './UsageFilters';
import { UserCompanyScore } from '../../../../models/Scores';
import Module from '../../../../models/Module';
import { SubModule, SubModuleTypes } from '../../../../models/SubModule';
import { lowerCaseAndRemovePunctuationFromString } from '../../../../utils/punctuation';
import config from '../../../../config'
import { faInfoCircle, faCertificate, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, FormGroup, Spinner, Alert } from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';
import TokenHelper from '../../../../auth/TokenHelper';

export interface UserFiltersComponentActions {
    downloadReport: (primaryProperty: string, reportConfig: ReportConfig | undefined, userFilter: UserFilter, composedData: ComposedData[], scores: Array<UserCompanyScore>, modules: Array<Module>, subModules: Array<SubModule>, searchInput: string, authDataSelectedValues: { [id: string]: string }, selectedModule: Module | undefined) => void
    selectModule: (module: Module | undefined) => void
    changeFilter: (userFilter: UserFilter) => void
}

interface State {
    error: string | undefined;
    isLoading: boolean;
    isUserFilterDropdownOpen: boolean;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    isUserFilterDropdownOpen: false
};

interface Props {
    primaryProperty: string, 
    reportConfig: ReportConfig | undefined, 
    userFilter: UserFilter, 
    composedData: ComposedData[], 
    scores: Array<UserCompanyScore>, 
    modules: Array<Module>, 
    subModules: Array<SubModule>, 
    searchInput: string, 
    authDataSelectedValues: { [id: string]: string }, 
    selectedModule: Module | undefined,
    actions: UserFiltersComponentActions
}

export default class UserFiltersComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = { ...INITIAL_STATE }
    }

    downloadCertificates = async (moduleId: string, userIds: Array<string>) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let response = await fetch(`${config.endpoint}/admin/content/certificate/${moduleId}`, {
                method: "POST",
                headers: { 'Authorization': `Token ${token}`, "Content-Type": "application/json" },
                body: JSON.stringify({ userIds })
            })
            //let data = await response.json()
            //console.log("data", data)
            let buffer = await response.arrayBuffer()
            const url = window.URL.createObjectURL(new Blob([buffer])); const link = document.createElement("a");
            link.href = url; link.setAttribute("download", "certificados.zip");
            document.body.appendChild(link);
            link.click()
            window.URL.revokeObjectURL(url)
            this.setState({ isLoading: false, error: undefined })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) this.downloadCertificates(moduleId, userIds)
            else this.setState({ isLoading: false, error: error.toString() })
        }
    }

    toggleUserFilterDropdownOpen = () => {
        this.setState({ isUserFilterDropdownOpen: !this.state.isUserFilterDropdownOpen });
    }

    getUserFilterTooltip(userFilter: UserFilter) {
        if (userFilter === UserFilter.UsersCreatedAccount) return 'Relatório que contém dados de usuários que acessaram a plataforma ao menos uma vez e definiram sua senha de acesso.'
        else if (userFilter === UserFilter.UsersDidntCreatedAccount) return 'Relatório que contém dados de usuários ainda não acessaram a plataforma e, portanto ainda não definiram sua senha de acesso.'
        else if (userFilter === UserFilter.UsersPlayedModule) return 'Relatório que contém dados de usuários que acessaram a plataforma, definiram sua senha de acesso e jogaram ao menos uma fase.'
        else if (userFilter === UserFilter.UsersDidntPlayedModule) return 'Relatório que contém dados de usuários que acessaram a plataforma, definiram sua senha de acesso mas ainda não jogaram nenhuma fase.'
        else if (userFilter === UserFilter.AllUsers) return 'Relatório com dados de todos os usuários cadastrados na plataforma, tanto os que já acessaram e definiram senha quanto aqueles que não.'
        else if (userFilter === UserFilter.BlockedUsers) return 'Relatório dos usuários que estavam cadastrados e foram bloqueados, perdendo o acesso a plataforma.'
        else return ''
    }

    renderSelectedModuleDropdown(modules: Array<Module>) {
        let options = [{ label: 'Qualquer jogo', value: 'Qualquer jogo' }, ...modules.map(module => { return { label: module.title, value: module.id } })]
        return <FormGroup style={{ marginTop: 5, flex: 1 }}>
            <Select placeholder="Qualquer jogo" options={options} onChange={
                opt => {
                    if (opt!.label === 'Qualquer jogo') this.props.actions.selectModule(undefined)
                    else {
                        let module = _.first(modules.filter(module => module.id === opt!.value))
                        this.props.actions.selectModule(module)
                    }
                }
            } />
        </FormGroup>
    }

    render() {

        const { primaryProperty, reportConfig, userFilter, composedData, scores, modules, subModules, searchInput, authDataSelectedValues, selectedModule } = this.props

        let renderSelecModule = userFilter === UserFilter.UsersPlayedModule || userFilter === UserFilter.UsersDidntPlayedModule
        var showDownloadCertificates = false
        var usersWhoFinishedModuleIds: Array<string> = []
        if (userFilter === UserFilter.UsersPlayedModule && selectedModule && selectedModule.hasCertificate !== false) {
            let selectedModuleSubModules = subModules.filter(sub => sub.moduleId === selectedModule.id)
            if (selectedModuleSubModules.filter(subModule => subModule.type === SubModuleTypes.QUIZ).length > 0) {
                let searchInputWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(searchInput)
                let filteredComposedData = getFilteredComposedData(composedData, searchInputWithoutPunctuation, authDataSelectedValues)
                let usersWhoFinishedModule = getUsersWhoFinishedModule(filteredComposedData.filter(data => { return data.user !== undefined }).map(data => { return data.user! }), scores, selectedModuleSubModules)
                showDownloadCertificates = true
                usersWhoFinishedModuleIds = usersWhoFinishedModule.map(user => user.id)
            }
        }

        return (<Row>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <div className='d-flex'>
                    <Dropdown style={{ marginRight: 5, marginTop: 5 }} isOpen={this.state.isUserFilterDropdownOpen} toggle={this.toggleUserFilterDropdownOpen}>
                        <DropdownToggle color='info' caret>
                            {getUserFilterText(userFilter)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => this.props.actions.changeFilter(UserFilter.AllUsers)}>{getUserFilterText(UserFilter.AllUsers)}</DropdownItem>
                            <DropdownItem onClick={() => this.props.actions.changeFilter(UserFilter.UsersCreatedAccount)}>{getUserFilterText(UserFilter.UsersCreatedAccount)}</DropdownItem>
                            <DropdownItem onClick={() => this.props.actions.changeFilter(UserFilter.UsersDidntCreatedAccount)}>{getUserFilterText(UserFilter.UsersDidntCreatedAccount)}</DropdownItem>
                            <DropdownItem onClick={() => this.props.actions.changeFilter(UserFilter.UsersPlayedModule)}>{getUserFilterText(UserFilter.UsersPlayedModule)}</DropdownItem>
                            <DropdownItem onClick={() => this.props.actions.changeFilter(UserFilter.UsersDidntPlayedModule)}>{getUserFilterText(UserFilter.UsersDidntPlayedModule)}</DropdownItem>
                            {reportConfig && reportConfig.showExtraReports && reportConfig.showExtraReports.filter(id => { return id === 'blocked_users' }).length > 0 && <DropdownItem onClick={() => this.props.actions.changeFilter(UserFilter.BlockedUsers)}>{getUserFilterText(UserFilter.BlockedUsers)}</DropdownItem>}
                        </DropdownMenu>
                    </Dropdown>
                    {renderSelecModule && this.renderSelectedModuleDropdown(modules)}
                </div>
                <span style={{ color: 'black', fontSize: 'small', marginLeft: 5, marginTop: 5 }}><FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} />{this.getUserFilterTooltip(userFilter)}</span>
                <div className='mt-2'>
                    {showDownloadCertificates && <Button disabled={this.state.isLoading} style={{ marginRight: 5 }} color="primary" onClick={() => this.downloadCertificates(selectedModule!.id, usersWhoFinishedModuleIds)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCertificate} />Baixar {usersWhoFinishedModuleIds.length} certificados{this.state.isLoading && <Spinner style={{ marginLeft: 5, width: 15, height: 15 }} />}</Button>}
                    <Button color="success" onClick={() => this.props.actions.downloadReport(primaryProperty, reportConfig, userFilter, composedData, scores, modules, subModules, searchInput, authDataSelectedValues, selectedModule)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faDownload} />Baixar Relatório</Button>
                </div>
            </Col>
            {<Col md={{ size: 8, offset: 2 }}><Alert className='mt-2' isOpen={this.state.error !== undefined} toggle={() => this.setState({ error: undefined })} color="danger">{JSON.stringify(this.state.error)}</Alert></Col>}
        </Row>)

    }
}