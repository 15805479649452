import axios from 'axios'
import ForumComment from '../models/ForumComment'
import Module from '../models/Module'

export default class ForumService {
    async getModuleForumComments(token: string, mainUrl: string, companyId: string, moduleId: string): Promise<{ forumComments: Array<ForumComment>, module: Module }> {
        let url = `${mainUrl}/mobile/forum/module/${companyId}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let forumComments = result.data.data.forumComments.map((data: any) => new ForumComment(data))
        let module = new Module(result.data.data.module)
        return { forumComments, module }
    }

    async likeComment(token: string, mainUrl: string, commentId: string) {
        let url = `${mainUrl}/mobile/forum/likeComment`
        let result = await axios.post(url, { commentId }, { headers: { 'Authorization': `Token ${token}` } })
        return new ForumComment(result.data.data.likedComment)
    }

    async dislikeComment(token: string, mainUrl: string, commentId: string) {
        let url = `${mainUrl}/mobile/forum/dislikeComment`
        let result = await axios.post(url, { commentId }, { headers: { 'Authorization': `Token ${token}` } })
        return new ForumComment(result.data.data.dislikedComment)
    }

    async addComment(token: string, mainUrl: string, companyId: string, moduleId: string, username: string, comment: string, parentsChain: string[], layer: number, parentId?: string, userPic?: string) {
        let url = `${mainUrl}/mobile/forum/addComment`
        let result = await axios.post(url, { companyId, moduleId, username, userPic, comment, parentId, parentsChain, layer }, { headers: { 'Authorization': `Token ${token}` } })
        return new ForumComment(result.data.data.createdComment)
    }

    async deleteComment(token: string, mainUrl: string, commentId: string) {
        let url = `${mainUrl}/mobile/forum/deleteComment`
        let result = await axios.post(url, { commentId }, { headers: { 'Authorization': `Token ${token}` } })
        return new ForumComment(result.data.data.deletedComment)
    }
}