import React from 'react';
import { Container, Row, Col } from 'reactstrap'

const PrivacyPolicyScreen = () => {

    const renderHeader = () => {
        return (
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img alt='foto da empresa' style={{ height: '10vh', marginBottom: 5, marginTop: 15 }} src={require('../../images/LogoTexto.png')} />
                    </div>
                </Col>
            </Row>)
    }

    return (<Container className="d-flex flex-column justify-content-center" fluid>
        {renderHeader()}
        <Row style={{ marginTop: 20 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 28 }}>
                    Política de Privacidade
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Olá, bem-vindo(a) ao Lira Edu! Somos uma plataforma de aprendizado gamificado e simplificado.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    Esta Política de Privacidade compreende as formas, como e por que, coletamos seus dados pessoais, a forma como usamos seus dados pessoais e as opções que você tem sobre seus dados pessoais conforme a LEI Nº 13.709, DE 14 DE AGOSTO DE 2018 (LGPD).
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    Esta Política de Privacidade se aplica aos jogos, aplicativos, sites e serviços relacionados do Lira Edu, os quais chamamos coletivamente de Serviço. Podemos atualizar periodicamente esta Política de Privacidade, publicando uma nova versão no site do Lira Edu. Se realizarmos qualquer alteração material, nós o notificaremos por meio de um aviso no Serviço antes da alteração entrar em vigor. Seu uso continuado do Serviço após a data de vigência estará sujeito à nova Política de Privacidade.
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Entre em contato conosco</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    Caso tenha dúvidas sobre a proteção de dados ou se tiver algum pedido para resolver problemas com seus dados pessoais, recomendamos que entre em contato primeiramente por meio do jogo para que possamos lhe responder com mais rapidez.
                </div>
            </Col>
            <Col md={{ size: 6, offset: 3 }}><div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Nome do Controlador: Lira Edu</div></Col>
            <Col md={{ size: 6, offset: 3 }}><div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Endereço: Avenida Brigadeiro Faria Lima, 2369, Conj 1102, São Paulo/SP</div></Col>
            <Col md={{ size: 6, offset: 3 }}><div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Email: rafael@liraedu.com</div></Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 20 }}>
                    OS DADOS QUE COLETAMOS
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Dados que você nos fornece:</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    - Informações de contato (como nome e endereço de e-mail)<br />- Nome do jogador e senha<br />- Informações de perfil (como foto de perfil)<br />- Suas mensagens para o Serviço (como registros de bate-papo e tickets de suporte ao jogador)<br />- Outros dados que você opta por nos fornecer (como, por exemplo, dados para identificar uma conta perdida)
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Dados que coletamos automaticamente:</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    - Dados sobre sua conta e progresso do jogo<br />- Seu endereço de IP e identificadores de dispositivos móveis (como o ID do dispositivo, o código de publicidade, o endereço MAC, o IMEI)<br />- Dados sobre o seu dispositivo, como nome e sistema operacional do dispositivo, tipo de navegador e idioma<br />- Dados que coletamos com cookies e tecnologias semelhantes (veja mais abaixo)<br />- Dados gerais de localização<br />- Dados sobre seu uso do Serviço, como dados de jogabilidade e suas interações com outros jogadores dentro do Serviço
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Dados que coletamos dos parceiros:</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    - Dados que recebemos se você vincular uma ferramenta de terceiros ao Serviço (como Facebook ou Google)<br />- Dados demográficos (como para determinar a localização aproximada do seu endereço IP)<br />- Dados para combater fraudes (como abuso de reembolso nos jogos ou cliques fraudulentos em publicidade)<br />- Dados das plataformas nas quais os jogos são executados (por exemplo, para verificar o pagamento)<br />- Dados para fins de publicidade e análise, para que possamos fornecer um serviço melhor
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 20 }}>
                    POR QUE COLETAMOS SEUS DADOS
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Para que o serviço possa funcionar.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    Para executar o contrato, processamos os dados necessários para
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    - Criar contas e permitir que você jogue nossos jogos e use nosso serviço<br />- Operar o serviço<br />- Verificar e confirmar pagamentos<br />- Fornecer os produtos e serviços solicitados<br />- Enviar comunicações relacionadas ao Serviço
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Para fazer com que o Serviço seja mais adequado para nossos jogadores.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    Para oferecer um Serviço excelente aos nossos jogadores, temos o interesse legítimo para coletar e processar os dados necessários para
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    - Atualizar e desenvolver perfis de jogadores<br />- Desenvolver e melhorar o Serviço e a experiência do jogador<br />- Gerenciar nosso relacionamento com você<br />- Fornecer recursos sociais como parte do Serviço<br />- Personalizar sua experiência com o Serviço<br />- Responder aos seus comentários e perguntas e oferecer atendimento ao jogador<br />- Fornecer ofertas do Lira Edu no Serviço, bem como em outros sites e serviços, por e-mail, SMS e WhatsApp<br />- Enviar informações relacionadas, como atualizações, alertas de segurança e mensagens de suporte<br />- Permitir que você se comunique com outros jogadores<br />- Dar oportunidade aos usuários de receberem convites dos nosso parceiros para fazer parte da equipe
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Para manter o Serviço seguro e justo.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    Para oferecer um Serviço excelente aos nossos jogadores, temos o interesse legítimo para coletar e processar os dados necessários parainformações sobre nossa política de uso aceitável, consulte os <a href='https://www.gamific.app/app/use_terms' target='_blank' >Termos de Uso do Lira Edu</a>.
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>A fim de manter o Serviço e seus recursos sociais seguros e justos, para combater fraudes e, do contrário, garantir o uso aceitável, temos um interesse legítimo em processar os dados necessários para</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    - Analisar e monitorar o uso do Serviço e seus recursos sociais<br />- Moderar conversas automática ou manualmente<br />- Tomar medidas contra jogadores fraudulentos e/ou malcomportados
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Para analisar, perfilar e segmentar.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Em todos os casos e propósitos acima, podemos analisar, perfilar e segmentar todos os dados coletados.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Ranking.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>O Lira Edu se reserva no direito de disponibilizar no aplicativo (APP), de forma pública, a todos jogadores da empresa, o ranking de resultados das 10 melhores pontuações. Dessa forma, todos usuários terão acesso a sua posição no ranking ( caso ela seja uma das 10 maiores ) bem como a sua pontuação.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Sendo assim, você concorda que seu perfil pode aparecer, publicamente e para todos jogadores da empresa, no ranking, e ainda concorda em se abster, sob quaisquer circunstâncias, de reclamações a respeito de constrangimento e exposição.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>VOCÊ RECONHECE E CONCORDA QUE CASO SUA PONTUAÇÃO FIQUE ENTRE AS 10 MAIORES, SEU PERFIL APARECERÁ NO RANKING E DESSA FORMA, TODOS JOGADORES PODERÃO VER SUA POSIÇÃO E PONTUAÇÃO.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Com seu consentimento.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Com seu consentimento, podemos processar seus dados para finalidades adicionais, como usar sua localização de GPS para lhe mostrar eventos locais.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    QUEM PODE VER SEUS DADOS
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Além do Lira Edu, seus dados podem ser acessados ​​​​por outras pessoas nas seguintes situações:</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    OUTROS JOGADORES E USUÁRIOS
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Os recursos sociais são um componente essencial dos nossos jogos. Outros jogadores e usuários podem, por exemplo, ver seus dados de perfil, atividades no jogo, ler suas mensagens postadas, bem como, saber sua posição no Ranking.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    PARCEIROS QUE TRABALHAM PARA O LIRA EDU
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>O Lira Edu tem parceiros que realizam serviços para nós. Esses parceiros processam seus dados apenas de acordo com as instruções do Lira Edu para fornecer o Serviço, por exemplo hospedagem, suporte ao jogador, publicidade, análise e prevenção de fraudes.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Outras empresas e órgãos públicos.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Para combater fraudes e atividades ilegais, podemos trocar dados com outras empresas e organizações e fornecê-los a órgãos públicos em resposta a requisições legais.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Também podemos divulgar seus dados, com seu consentimento, a fim de cumprir com a lei ou para proteger nossos direitos, propriedade ou segurança, dos nossos jogadores ou outros.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    TRANSFERÊNCIAS DE DADOS INTERNACIONAIS
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Nosso serviço é global por natureza e seus dados podem ser transferidos para qualquer lugar do mundo. Uma vez que países diferentes podem ter leis de proteção de dados diferentes do seu, tomamos medidas para garantir que hajam proteções adequadas em vigor para proteger seus dados, conforme explicado nesta Política. As proteções adequadas que nossos parceiros podem usar incluem cláusulas contratuais padrão aprovadas pela Comissão da UE e a Certificação de Proteção da Privacidade no caso de transferências para os EUA.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    SEUS DIREITOS E OPÇÕES
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>E-mails de escolha de não recebimento de materiais de marketing e outros tipos de marketing direto.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Você pode optar por não receber nossas comunicações promocionais, como e-mails, SMS e WhatsApp de marketing, seguindo as instruções contidas em tais comunicações.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Excluir publicidade direcionada.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Você pode excluir a propaganda baseada em interesse nos aplicativos para celular verificando as configurações de privacidade do seu dispositivo Android ou iOS e selecionando "limitar o rastreamento de anúncios" (Apple iOS) ou "excluir anúncios baseados em interesses" (Android).</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Para excluir ofertas personalizadas no jogo, você pode usar as opções fornecidas nas configurações do jogo.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Acesse os dados pessoais que temos sobre você.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Se solicitado, forneceremos uma cópia dos seus dados pessoais em formato eletrônico.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Seus outros direitos.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Você também tem o direito de corrigir e excluir seus dados, contestar ou restringir a forma como usamos ou compartilhamos seus dados. Você pode sempre retirar seu consentimento, por exemplo, desativando o compartilhamento de localização por GPS nas configurações do seu celular. Responderemos a todas as suas solicitações dentro de um prazo razoável.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    COOKIES E TECNOLOGIAS SEMELHANTES
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Assim como para a maioria dos serviços on-line, nós e nossos parceiros usamos cookies e tecnologias similares para fornecer e personalizar o Serviço, analisar o uso, direcionar anúncios e evitar fraudes. Você pode desativar os cookies nas configurações do navegador, mas algumas partes do Serviço podem não funcionar corretamente.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    COMO PROTEGEMOS SEUS DADOS?
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Meios de segurança.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Para ajudar a garantir uma experiência segura para o jogador, estamos continuamente desenvolvendo e implementando medidas de segurança administrativas, técnicas e físicas para proteger seus dados contra o acesso não autorizado ou contra perda, uso indevido ou alteração.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    <b>Retenção de dados.</b>
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Retemos seus dados enquanto sua conta estiver ativa ou enquanto for necessário para lhe prestar o Serviço. Periodicamente, por exemplo, desidentificaremos contas de jogo não utilizadas e, regularmente, revisaremos e desidentificaremos dados desnecessários. Observe que, mesmo se nos pedir para remover seus dados pessoais, manteremos seus dados conforme necessário para nossos interesses comerciais legítimos, como para cumprir com nossas obrigações legais, resolver disputas e fazer cumprir nossos contratos.</div>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>
                    LIMITES DE IDADE
                </div>
            </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 15 }}>Não coletamos ou solicitamos intencionalmente dados pessoais, ou direcionamos propaganda baseada no interesse a menores de 16 anos de idade ou conscientemente permitimos que essas pessoas usem nossos Serviços. Se tiver menos de 16 anos, não nos envie nenhum dado sobre você, incluindo seu nome, endereço, número de telefone ou endereço de e-mail. Ninguém com menos de 16 anos de idade pode fornecer quaisquer dados pessoais. Se tomarmos conhecimento de que coletamos dados pessoais sobre uma criança menor de 16 anos, excluiremos esses dados o mais rápido possível. Se achar que possamos ter informações de um menor com idade inferior a 16 anos, entre em contato conosco.</div>
            </Col>
        </Row>
    </Container>)

}

export default PrivacyPolicyScreen;