import React, { Component, useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, FormText, Input, InputGroup, InputGroupText, Row, Spinner } from 'reactstrap';
import { faArrowLeft, faCheckCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AudioPlayButton from './AudioPlayButton';
import { deleteAudioFile, getAllAudioFiles, createTtsFile, getAudioFileUrl } from './services'

/** AUDIO VIEW */
export interface AudioViewProps {
    projectId: string
    onFileSelected: (filename: string, duration: number) => void
    onFileDeleted: (filename: string) => void
    file: string
}

const AudioView = (props: AudioViewProps) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState('');
    const [duration, setDuration] = useState<number>(-1)

    const onDeleteFileClick = async () => {
        setIsDeleting(true)
        try {
            await deleteAudioFile(props.projectId, props.file)
            setIsDeleting(false)
            props.onFileDeleted(file)
        } catch (error) {
            setIsDeleting(false)
            setError(error.toString())
        }
    }

    let file = props.file
    return (<div className="d-flex-column align-items-center mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
        {error && <div className="mt-1 mb-1"><Alert color="danger">{error}</Alert></div>}
        <div className="mb-2"><b>{file}</b></div>
        <div className="d-flex align-items-center" >
            <Button className="me-1" outline color="primary" onClick={() => props.onFileSelected(file, duration)}>Selecionar <FontAwesomeIcon icon={faCheckCircle} /></Button>
            <AudioPlayButton audioSrc={getAudioFileUrl(props.projectId, props.file)} start={() => console.log('play')} setDuration={setDuration} />
            <Button className='ms-1' size="sm" outline color="danger" disabled={isDeleting} onClick={() => { onDeleteFileClick() }}><FontAwesomeIcon icon={faTrashAlt} /> {isDeleting ? 'Deletando...' : 'Deletar'}</Button>
        </div>
    </div>)
}

/** SELECT AUDIO COMPONENT */
interface SelectAudioProps {
    projectId: string
    fileSelected: (filename: string, duration: number) => void
    goBack: () => void
}

interface SelectAudioComponentState {
    isLoading: boolean;
    error: string | undefined;
    files: Array<string>;
    textInput: string;
    fileNameInput: string;
}

const INITIAL_STATE: SelectAudioComponentState = {
    isLoading: true,
    error: undefined,
    files: [],
    textInput: '',
    fileNameInput: 'NewFile',
}

export default class SelecAudioComponent extends Component<SelectAudioProps, SelectAudioComponentState> {

    constructor(props: SelectAudioProps) {
        super(props)

        this.state = { ...INITIAL_STATE }
    }

    async componentDidMount() {
        try {
            let files = await getAllAudioFiles(this.props.projectId)
            this.setState({ files, isLoading: false })
        } catch (error) {
            this.setState({ error: error.toString(), isLoading: false })
        }
    }

    createNewTtsFile = async () => {
        try {
            let newFile = await createTtsFile(this.props.projectId, this.state.fileNameInput + '.wav', this.state.textInput)
            this.setState({ files: [...this.state.files, newFile], isLoading: false, textInput: '', fileNameInput: 'NewFile' })
        } catch (error) {
            this.setState({ error: error.toString(), isLoading: false })
        }
    }

    render() {
        let { isLoading, error } = this.state
        return <Row>
            <Col className="mb-2 mt-2 bg-light p-2 rounded border" md={{ size: 10, offset: 1 }}>
                <Button color="light" size="sm" onClick={this.props.goBack}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
                {error && <Alert color="danger">{error}</Alert>}
                <div className="d-flex flex-column justify-content-center mt-3" style={{ background: '#dbdbdbda', borderRadius: 5, padding: 10 }}>
                    <Form>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupText>Nome do Arquivo</InputGroupText>
                                <Input disabled={this.state.isLoading} placeholder="Nome do arquivo" value={this.state.fileNameInput} onChange={(event) => this.setState({ fileNameInput: event.target.value })} />
                                <InputGroupText>.wav</InputGroupText>
                            </InputGroup>
                            <InputGroup className="mt-2">
                                <Input disabled={this.state.isLoading} name="audioTextInput" id="audioTextInput" placeholder="Texto que será transformado em audio" value={this.state.textInput} onChange={(event) => this.setState({ textInput: event.target.value })} />
                                <Button disabled={this.state.isLoading || this.state.textInput.length < 3} outline color="primary" onClick={() => this.createNewTtsFile()}>Criar <FontAwesomeIcon icon={faCheckCircle} /></Button>
                            </InputGroup>
                            <FormText color="muted">Criar um arquivo de áudio com voz humana falando o texto.</FormText>
                        </FormGroup>
                    </Form>
                    {isLoading && <Spinner color="primary" />}
                </div>
                {this.state.files.map((file, index) => {
                    return <AudioView key={`AudioView_${index}`} file={file} onFileSelected={(filename, duration) => this.props.fileSelected(filename, duration)} onFileDeleted={(deletedFile) => this.setState({ files: [...this.state.files.filter(file => file !== deletedFile)] })} projectId={this.props.projectId} />
                })}
            </Col>
        </Row>
    }
}
