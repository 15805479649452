import axios from 'axios'
import UserAccessValidation from '../models/UserAccessValidation'
import { MonthUserCompanyScore, UserCompanyScore } from '../models/Scores'
import User from '../models/User'
import { SubModuleFinishedReport, DeckSubModuleFinishedReport, QuizQuestionFinishedReport } from '../models/SubModuleFinishedReport'
import Module from '../models/Module'
import { SubModule, SubModuleTypes } from '../models/SubModule'
import { ReportConfig, SignUpConfig } from '../models/CompanyConfig'
import UserModuleFeedback from '../models/UserModuleFeedback'

export default class AdminService {

    async getUsageControlData(token: string, mainUrl: string): Promise<UsageControlRes> {
        let url = `${mainUrl}/admin/reports/usageControl`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let usageControlRes = new UsageControlRes(result.data.data)
        return usageControlRes
    }

    getLastReports = async (token: string, mainUrl: string, companyId: string, userId: string): Promise<{
        lastReports: Array<SubModuleFinishedReport>,
        companyModules: Array<Module>,
        companySubModules: Array<SubModule>
        maxScoreInModules: { [moduleId: string]: { [subModuleId: string]: number } }
        user: User
    }> => {
        let url = `${mainUrl}/admin/reports/lastReports/${companyId}/${userId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let lastReports = result.data.data.lastReports.map((data: any) => {
            let subModuleType = data.type
            if (subModuleType === SubModuleTypes.DECK) {
                return new DeckSubModuleFinishedReport(data)
            } else if (subModuleType === SubModuleTypes.QUIZ) {
                return new QuizQuestionFinishedReport(data)
            } else {
                return new SubModuleFinishedReport(data)
            }
        })
        let user = new User(result.data.data.user)
        let maxScoreInModules: { [moduleId: string]: { [subModuleId: string]: number } } = result.data.data.maxScoreInModules
        let companyModules = result.data.data.companyModules.map((data: any) => new Module(data))
        let companySubModules = result.data.data.companySubModules.map((data: any) => new SubModule(data))
        return { lastReports, companyModules, companySubModules, maxScoreInModules, user }
    }

    getYearUsage = async (token: string, mainUrl: string, year: number) => {
        let url = `${mainUrl}/admin/reports/yearUsage/${year}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new YearUsageRes(result.data.data)
    }

    deleteUserScoreInModule = async (token: string, mainUrl: string, moduleId: string, userId: string): Promise<UserCompanyScore | undefined> => {
        let url = `${mainUrl}/admin/score/deleteUserScoreInModule/${moduleId}/${userId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        if (result.data.data.userCompanyScore) return new UserCompanyScore(result.data.data.userCompanyScore)
        else return undefined
    }

    async getFinishDateForModule(token: string, mainUrl: string, year: number, month: number, moduleId: string): Promise<FinishDateForModule> {
        let url = `${mainUrl}/admin/reports/finishDate/${year}/${month}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new FinishDateForModule(result.data.data)
    }

    async getUsersFeedbackForModule(token: string, mainUrl: string, moduleId: string): Promise<Array<UserModuleFeedback>> {
        let url = `${mainUrl}/admin/reports/usersFeedback/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return result.data.data.map((data: any) => new UserModuleFeedback(data))
    }

}

export class FinishDateForModule {

    module: Module;
    moduleSubModules: SubModule;
    dateUsersFinishedModuleInPeriod: {
        userId: string;
        finishedTime: number;
        subModulesFirstFinishedDate: {
            [subModuleId: string]: number;
        };
    }[]

    constructor(data: any) {
        this.module = new Module(data.module)
        this.moduleSubModules = new SubModule(data.moduleSubModules)
        this.dateUsersFinishedModuleInPeriod = data.dateUsersFinishedModuleInPeriod
    }

}

export class YearUsageRes {
    users: Array<User>;
    monthUsersCompanyScore: Array<MonthUserCompanyScore>;
    modules: Array<Module>;
    subModules: Array<SubModule>;
    reportConfig: ReportConfig | undefined;
    signUpConfig: SignUpConfig;
    constructor(data: any) {
        this.users = data.users.map((data: any) => new User(data))
        this.monthUsersCompanyScore = data.monthUsersCompanyScore.map((data: any) => new MonthUserCompanyScore(data))
        this.modules = data.modules.map((data: any) => new Module(data))
        this.subModules = data.subModules.map((data: any) => new SubModule(data))
        this.reportConfig = data.reportConfig ? new ReportConfig(data.reportConfig) : undefined
        this.signUpConfig = new SignUpConfig(data.signUpConfig)
    }
}

export class UsageControlRes {
    reportConfig?: ReportConfig;
    signUpConfig: SignUpConfig;
    scores: Array<UserCompanyScore>;
    users: Array<User>;
    lastTimeUsersPlayed: Array<any>;
    modules: Array<Module>;
    subModules: Array<SubModule>;
    accessValidation: Array<UserAccessValidation>;

    constructor(data: any) {
        if (data.reportConfig) this.reportConfig = new ReportConfig(data.reportConfig)
        this.signUpConfig = new SignUpConfig(data.signUpConfig)
        this.scores = data.scores.map((data: any) => new UserCompanyScore(data))
        this.users = data.users.map((data: any) => new User(data))
        this.lastTimeUsersPlayed = data.lastTimeUsersPlayed
        this.modules = data.modules.map((data: any) => new Module(data))
        this.subModules = data.subModules.map((data: any) => new SubModule(data))
        this.accessValidation = data.accessValidation.map((data: any) => new UserAccessValidation(data))
    }
}