import React, { Component, useEffect } from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, FormGroup, FormText, Input, Label, Row, Tooltip } from 'reactstrap'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import { SubModule, SubModuleTypes } from '../../../models/SubModule';
import Module from '../../../models/Module';
import TokenHelper from '../../../auth/TokenHelper';
import ContentService from '../../../services/contentService';
import config from '../../../config';
import AdminContentService from '../../../services/admin/adminContentService';
import * as ROUTES from '../../../constants/routes'

// SubModule Gifs
import DeckCardTemplateGif from "../../../images/SubModuleTemplates/DeckCardTemplate.gif";
import QuizTemplateGif from "../../../images/SubModuleTemplates/QuizTemplate.gif";
import ReactPlayer from 'react-player';
import FileHelper from '../../../files/FileHelper';
import { faArrowLeft, faEye, faEyeSlash, faFile, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingScreen from '../../loading';
import SelectFileComponent from '../../../components/SelectFileComponent';
import PDFPreview from '../../../components/pdf/PDFPreview';
import { useUser } from '../../../hooks/useUser';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';


interface State {
    error: any | undefined;
    isLoading: boolean;
    module: Module | undefined;
    title: string;
    pos: number;
    mistakesAllowed: boolean;
    type: SubModuleTypes;
    showPreview: boolean;
    positionTooltipOpen: boolean,
    formatTooltipOpen: boolean,
    continueTooltipOpen: boolean,
    selectedVideoFile?: File;
    selectedVideoUrl?: string;
    selectedFileName?: string;
    linkInput: string;
    videoTypeSelection: number;
    showFileSelectComponent: boolean;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    module: undefined,
    title: '',
    pos: 1,
    mistakesAllowed: false,
    type: SubModuleTypes.DECK,
    showPreview: true,
    positionTooltipOpen: false,
    formatTooltipOpen: false,
    continueTooltipOpen: false,
    linkInput: '',
    videoTypeSelection: 0,
    showFileSelectComponent: false,
};

const fileHelper = new FileHelper()

const CreateSubModuleScreen = () => {

    let navigate = useNavigate()
    const [state, setState] = React.useState<State>({ ...INITIAL_STATE })
    const { company } = useUser()
    const { height } = useWindowDimensions()
    const { moduleId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();

    const posFromUrl = searchParams.get("pos")

    useEffect(() => {
        if(posFromUrl) {
            setState({ ...state, pos: parseInt(posFromUrl) })
        }
    }, [posFromUrl])

    useEffect(() => {
        if (company && moduleId) {
            loadModule(company.id, moduleId)
        }
    }, [moduleId, company])


    const loadModule = async (companyId: string, moduleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let module = await contentService.getCompanyModule(token, config.endpoint, companyId, moduleId)
            setState({ ...state, module, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadModule(companyId, moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const changeType = (newTypeVal: string) => {
        if (newTypeVal === 'Trilha de Aprendizagem') {
            setState({ ...state, type: SubModuleTypes.DECK })
        } else if (newTypeVal === 'Avaliação de Conhecimento') {
            setState({ ...state, type: SubModuleTypes.QUIZ })
        } else if (newTypeVal === 'Video') {
            setState({ ...state, type: SubModuleTypes.VIDEO })
        } else if (newTypeVal === 'Link') {
            setState({ ...state, type: SubModuleTypes.LINK })
        } else if (newTypeVal === 'Live') {
            setState({ ...state, type: SubModuleTypes.LIVE })
        } else if (newTypeVal === 'Arquivo') {
            setState({ ...state, type: SubModuleTypes.FILE })
        }
    }

    const isInputValid = (title: string) => {
        return title.length > 3
    }

    const createSubModule = async (moduleId: string, title: string, pic: string, pos: number, time: number | undefined, mistakesAllowed: boolean, type: string, selectedVideoUrl: string | undefined) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let externalResourceUrl = (state.linkInput.length > 0 && state.linkInput) || selectedVideoUrl || state.selectedFileName
            let subModule = await adminContentService.createSubModule(token, config.endpoint, moduleId, title, pos, pic, time, type === SubModuleTypes.QUIZ ? mistakesAllowed : undefined, externalResourceUrl, type)
            if (type === SubModuleTypes.VIDEO) {
                if (state.selectedVideoFile) {
                    uploadVideo(subModule, state.selectedVideoFile)
                } else if (selectedVideoUrl) {
                    createOrUpdateSubModuleVideo(subModule, selectedVideoUrl)
                }
            } else {
                navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SUBMODULE_SELECT}/${moduleId}`)
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                createSubModule(moduleId, title, pic, pos, time, mistakesAllowed, type, selectedVideoUrl)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const setVideoFileDataUrl = async (selectedVideoFile: File) => {
        try {
            let URL = window.URL || window.webkitURL;
            let selectedVideoUrl = URL.createObjectURL(selectedVideoFile);
            setState({ ...state, selectedVideoFile, selectedVideoUrl })
        } catch (error) {
            setState({ ...state, error: error.toString() })
        }
    }
    
    const getSignedUrlForUpload = async (tokenHelper: TokenHelper, adminContentService: AdminContentService, subModule: SubModule, selectedFile: File) => {
        try {
            let token = tokenHelper.getToken()
            if(!token) return setState({...state, error: 'Usuário não possui token de acesso.' })
            let signedUrl = await adminContentService.getSignedUrlForUpload(token, config.endpoint, subModule.moduleId, subModule.id, selectedFile.type)
            await adminContentService.uploadVideoFromSignedUrl(signedUrl, selectedFile)
            saveSubModuleVideo(tokenHelper, adminContentService, subModule, selectedFile)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                getSignedUrlForUpload(tokenHelper, adminContentService, subModule, selectedFile)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const saveSubModuleVideo = async (tokenHelper: TokenHelper, adminContentService: AdminContentService, subModule: SubModule, selectedFile: File) => {
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({  ...state, error: 'Usuário não possui token de acesso.' })
            await adminContentService.saveSubModuleVideo(token, config.endpoint, subModule.moduleId, subModule.id, selectedFile)
            navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SUBMODULE_SELECT}/${subModule.moduleId}`)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                uploadVideo(subModule, selectedFile)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const uploadVideo = async (subModule: SubModule, selectedFile: File) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        let adminContentService = new AdminContentService()
        getSignedUrlForUpload(tokenHelper, adminContentService, subModule, selectedFile)
    }

    const createOrUpdateSubModuleVideo = async (subModule: SubModule, videoUrl: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            await adminContentService.createOrUpdateSubModuleVideo(token, config.endpoint, subModule.moduleId, subModule.id, videoUrl)
            navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SUBMODULE_SELECT}/${subModule.moduleId}`)
        }
        catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                createOrUpdateSubModuleVideo(subModule, videoUrl)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const renderHeader = (module: Module, showFileSelectComponent: boolean) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { showFileSelectComponent ? setState({ ...state, showFileSelectComponent: false }) : navigate(-1) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={module.pic} />
                    <div className="d-flex flex-column">
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 10 }}><b>{showFileSelectComponent ? 'Selecionar Arquivo' : 'Nova Fase'}</b></div>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 10 }}>{module.title}</div>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    const renderFileUploadOrPreview = (selectedFileName: string | undefined) => {
        return <div className="d-flex flex-column align-items-center justify-content-center">
            {selectedFileName && <PDFPreview width={250} height={250} filename={selectedFileName} />}
            <Button className="mt-2" onClick={() => setState({ ...state, showFileSelectComponent: true })}><FontAwesomeIcon icon={faFile} /> {selectedFileName ? 'Trocar Arquivo' : 'Selecionar Arquivo'}</Button>
        </div>

    }

    const renderVideoUploadOrPreview = (selectedVideoUrl: string | undefined) => {
        let videoTypeSelection = state.videoTypeSelection
        if (selectedVideoUrl && videoTypeSelection === 0) {
            var width = '100%', height = 'auto'
            return <ReactPlayer width={width} height={height} url={selectedVideoUrl} controls config={{
                file: {
                    attributes: {
                        controlsList: 'nodownload'
                    }
                }
            }} />
        } else {
            return <div>
                <ButtonGroup style={{ margin: 5 }}>
                    <Button color='secondary' outline active={videoTypeSelection === 0} onClick={() => setState({ ...state, videoTypeSelection: 0, selectedVideoUrl: undefined, selectedVideoFile: undefined })}>
                        Arquivo de Video
                    </Button>
                    <Button color='secondary' outline active={videoTypeSelection === 1} onClick={() => setState({ ...state, videoTypeSelection: 1, selectedVideoUrl: undefined, selectedVideoFile: undefined })}>
                        URL
                    </Button>
                </ButtonGroup>
                {videoTypeSelection === 0 && <Form style={{ margin: 5 }}>
                    <FormGroup>
                        <Label for="video">Upload de Video</Label>
                        <Input type="file" name="file" id="video" encType="multipart/form-data" accept="video/mp4,video/x-m4v,video/*" onChange={(event) => event.target.files && setVideoFileDataUrl(event.target.files[0])} />
                        <FormText color="muted">
                            Carregar uma video do seu dispositivo.
                        </FormText>
                    </FormGroup>
                </Form>}
                {videoTypeSelection === 1 && <Form style={{ margin: 5 }}>
                    <FormGroup>
                        <Input name="videoUrl" id="videoUrl" placeholder="URL do Video" onChange={(event) => setState({ ...state, selectedVideoUrl: event.target.value })} />
                    </FormGroup>
                </Form>}
            </div>
        }
    }

    const renderForm = (moduleId: string, title: string, pos: number, companyBackground: string, mistakesAllowed: boolean, type: SubModuleTypes, showPreview: boolean, selectedVideoUrl: string | undefined) => {
        var templateGif: string | undefined = DeckCardTemplateGif
        var templateText = "Trilha de Aprendizagem"
        var feedbackText = "Conteúdo gamificado que ensina através de informações, imagens, gifs e elementos interativos."
        if (type === SubModuleTypes.QUIZ) {
            templateGif = QuizTemplateGif
            templateText = "Avaliação de Conhecimento"
            feedbackText = "Permite avaliar o aprendizado do usuário através de um banco de questões que aparecem em ordem aleatória e com tempo para responder."
        } else if (type === SubModuleTypes.VIDEO) {
            templateGif = undefined
            feedbackText = "Video que o usuário assiste dentro da plataforma e ganha pontos ao assistir até o final. Recomendamos videos de até 3 minutos e com tamanho máximo de 32MB"
        } else if (type === SubModuleTypes.LINK) {
            templateGif = undefined
            feedbackText = "Link que leva para um site ou recurso externo. O usuário clica e ele abre em uma nova aba."
        } else if (type === SubModuleTypes.FILE) {
            templateGif = undefined
            feedbackText = 'Arquivo que o usuário faz upload dentro da plataforma. Recomendamos arquivos de até 32MB'
        } else if (type === SubModuleTypes.LIVE) {
            templateGif = undefined
            feedbackText = 'Live realizada online dentro da plataforma'
        }
        return (<Row style={{ overflow: 'auto', zIndex: 1, paddingBottom: 10 }}>
            <Col className="d-flex flex-column justify-content-center relative" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Form style={{ margin: 5 }}>
                    <FormGroup>
                        <Label for="moduleTitle">Título</Label>
                        <Input name="title" id="moduleTitle" placeholder="Nome da fase" onChange={(event: any) => setState({ ...state, title: event.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="position">
                            <span id="PositionText">Posição <FontAwesomeIcon icon={faQuestionCircle} /></span>
                            <Tooltip placement="top" isOpen={state.positionTooltipOpen} autohide={false} target="PositionText" toggle={() => setState({ ...state, positionTooltipOpen: !state.positionTooltipOpen })}>
                                As fases aparecem para o usuário em ordem da menor para a maior posição.
                            </Tooltip>
                        </Label>
                        <Input type="select" name="selectMulti" id="position" value={pos} onChange={(event: any) => setState({ ...state, pos: +event.target.value })}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="format">
                            <span id="FormatText">Formato da Fase <FontAwesomeIcon icon={faQuestionCircle} /></span>
                            <Tooltip placement="top" isOpen={state.formatTooltipOpen} autohide={false} target="FormatText" toggle={() => setState({ ...state, formatTooltipOpen: !state.formatTooltipOpen })}>
                                O formato da fase determina como o conteúdo vai ser apresentado para o usuário
                            </Tooltip>
                        </Label>
                        <Input type="select" name="selectMulti" id="position" onChange={(event: any) => changeType(event.target.value)}>
                            <option>Trilha de Aprendizagem</option>
                            <option>Avaliação de Conhecimento</option>
                            <option>Video</option>
                            <option>Link</option>
                            <option>Arquivo</option>
                            <option>Live</option>
                        </Input>
                        <FormText>
                            <span style={{ color: 'black', fontSize: '1em' }}>{feedbackText}</span>
                        </FormText>
                    </FormGroup>
                    {type === SubModuleTypes.QUIZ && renderMistakesAllowedInput(mistakesAllowed)}
                    {type === SubModuleTypes.LINK && renderLinkInput(state.linkInput)}
                    {type === SubModuleTypes.FILE && renderFileUploadOrPreview(state.selectedFileName)}
                </Form>
                {templateGif && <div className="d-flex flex-column align-items-center justify-content-center" style={{ margin: 5, background: 'white', borderRadius: '0.25rem', paddingTop: showPreview ? 10 : 5, paddingBottom: 5, borderColor: '#ced4da' }}>
                    <div className="cursorPointer" style={{ marginLeft: 5, marginRight: 5, borderStyle: 'none' }} color="secondary" onClick={() => setState({ ...state, showPreview: !showPreview })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={showPreview ? faEyeSlash : faEye} />{showPreview ? `Esconder ${templateText}` : `Mostrar ${templateText}`}</div>
                    {showPreview && <Zoom><img src={templateGif} alt='Gif do template' style={{ height: 300, width: 190, objectFit: 'contain' }} /></Zoom>}
                </div>}
                {type === SubModuleTypes.VIDEO && renderVideoUploadOrPreview(selectedVideoUrl)}
                <Button style={{ marginTop: 10 }} block disabled={!isInputValid(title)} color="primary" onClick={() => createSubModule(moduleId, title, companyBackground, pos, 4, mistakesAllowed, type, selectedVideoUrl)}>Salvar</Button>
            </Col>
        </Row>)
    }

    const renderMistakesAllowedInput = (mistakesAllowed: boolean) => {
        return <FormGroup check>
            <Input type="checkbox" checked={mistakesAllowed} onChange={e => setState({ ...state, mistakesAllowed: e.target.checked })} />
            <Label check>
                <span id="ContinueText">Continuar a avaliação depois de errar <FontAwesomeIcon icon={faQuestionCircle} /></span>
                <Tooltip placement="top" isOpen={state.continueTooltipOpen} autohide={false} target="ContinueText" toggle={() => setState({ ...state, continueTooltipOpen: !state.continueTooltipOpen })}>
                    A avaliação finaliza quando o usuário erra uma questão. Caso você queira permitir o usuário responder todas as questões mesmo se errar alguma, deixe essa opção ativada.
                </Tooltip>
            </Label>
        </FormGroup>
    }

    const renderLinkInput = (linkInput: string) => {
        return <FormGroup>
            <Label for="url">Link</Label>
            <Input name="url" id="url" value={linkInput} placeholder="Coloque aqui o link" onChange={(event: any) => setState({ ...state, linkInput: event.target.value })} />
        </FormGroup>
    }

    let { error, isLoading, module, title, pos, mistakesAllowed, type, showPreview, selectedVideoUrl, showFileSelectComponent } = state

        if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
        let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
        let backgroundPosition = company?.backgroundPosition || 'left top'

        return <Container className="d-flex flex-column relative" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
            {error && <Alert color="danger">{error}</Alert>}
            {module && renderHeader(module, showFileSelectComponent)}
            {showFileSelectComponent && <SelectFileComponent fileSelected={(filename: string) => setState({ ...state, showFileSelectComponent: false, selectedFileName: filename })} goBack={() => setState({ ...state, showFileSelectComponent: false })} />}
            {!showFileSelectComponent && module && company && renderForm(module!.id, title, pos, company.pic, mistakesAllowed, type, showPreview, selectedVideoUrl)}
        </Container>

}


export default CreateSubModuleScreen