import axios from 'axios'
import { IAttribution } from '../../models/DeckCards';

export interface IVideoFile {
    id: string,
    quality: string,
    file_type: string,
    width: number,
    height: number,
    fps: number,
    link: string
}

export interface IVideoPicture {
    id: number,
    picture: string,

}

export interface IPexelVideosData {
    user: {
        id: number,
        name: string,
        url: string,
    },
    // An array of different sized versions of the video.
    video_files: Array<IVideoFile>,
    // An array of preview pictures of the video.
    video_pictures: Array<IVideoPicture>,
}

export default class AiService {

    async getImagesFromPrompt(token: string, mainUrl: string, prompt: string, orientation: string, page: number = 1): Promise<Array<{
        image: string;
        attribution: IAttribution;
    }>> {
        let url = `${mainUrl}/admin/ai/images`
        let result = await axios.post(url, { prompt, orientation, page }, { headers: { 'Authorization': `Token ${token}` } })
        return result.data.data.images
    }
}