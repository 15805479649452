const getImageDimensions = (onImageDimensions: (width: number, height: number) => void, imageUrl: string) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
        onImageDimensions(img.width, img.height);
    };
    img.onerror = (err) => {
        console.log("img error");
        console.error(err);
    };
};

export default getImageDimensions