import React, { Component, useEffect, useState } from 'react';
import UserModuleFeedback from '../../../models/UserModuleFeedback';
import TokenHelper from '../../../auth/TokenHelper';
import AdminService from '../../../services/adminService';
import config from '../../../config';
import { Col } from 'reactstrap';
import User from '../../../models/User';


interface Props {
    moduleId: string
    users: Array<User>,
}

interface State {
    error: string | undefined;
    isLoading: boolean;
    usersModuleFeedback: Array<UserModuleFeedback>;
}

export const UserFeedbackComponent = ({ moduleId, users }: Props) => {

    const [state, setState] = useState<State>({
        error: undefined,
        isLoading: true,
        usersModuleFeedback: []
    })

    const { error, isLoading, usersModuleFeedback } = state

    const getData = async () => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let adminService = new AdminService()
            let userModuleFeedback = await adminService.getUsersFeedbackForModule(token, config.endpoint,  moduleId)
            setState({ error: undefined, isLoading: false, usersModuleFeedback: userModuleFeedback})

        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                getData()
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    useEffect(() => {
        getData()
    }, [moduleId])

    if (isLoading) {
        return <div>Carregando feedback dos usuários...</div>
    }

    return <Col className="d-flex flex-column mb-5 gap-2" md={{ size: 8, offset: 2 }}>
        <h3>Feedback dos usuários</h3>
        <ul>
            {usersModuleFeedback.map((feedback, index) => {
                const user = users.find(user => user.id === feedback.userId)
                return <li key={index}>
                    <div><b>{user?.username}</b></div>
                    <div>{feedback.feedback}</div>
                </li>
            })}
        </ul>
    </Col>

}