import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Alert, Form, FormGroup, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Company from '../../models/Company';
import { SignUpConfig, DocConfig, EmailConfig } from '../../models/CompanyConfig';
import Cookies from 'universal-cookie';
import EnterService from '../../services/enterService';
import config from '../../config'
import LoadingScreen from '../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import * as ROUTES from '../../constants/routes';
import { useUser } from '../../hooks/useUser';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';


interface State {
    isLoading: boolean
    error: any | undefined
    info: string | undefined
    signUpConfig: SignUpConfig | undefined,
    email: string | undefined;
    doc: string | undefined,
    password: string,
    height: number,
};

const INITIAL_STATE: State = {
    isLoading: true,
    error: null,
    info: undefined,
    signUpConfig: undefined,
    email: undefined,
    doc: undefined,
    password: '',
    height: 0,
};

type TParams = { companyId: string };

const LoginScreen = () => {

    const navigate = useNavigate()
    const { company, noCompany } = useUser()
    const { companyId } = useParams<TParams>()
    const [searchParams, setSearchParams] = useSearchParams()
    const primaryProperty = searchParams.get('primaryProperty')
    const [state, setState] = React.useState<State>(INITIAL_STATE)
    const { height } = useWindowDimensions()

    React.useEffect(() => {
        if (company && primaryProperty) {
            loadCompanySignUpConfig(company.id, primaryProperty)
            window.localStorage.removeItem('selectedCategory')
        } else if (noCompany) {
            navigate(`/${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
        }
    }, [noCompany, company, primaryProperty])

    const loadCompanySignUpConfig = async (companyId: string, primaryPropertyValue: string | undefined) => {
        try {
            setState({ ...state, isLoading: true, error: undefined })
            let enterService = new EnterService()
            let { company, signUpConfig } = await enterService.getCompanySignUpConfig(config.endpoint, companyId)
            let cookies = new Cookies()
            cookies.set('company', company.getData(), { path: '/', maxAge: 31536000, sameSite: 'none', secure: true });
            if (primaryPropertyValue) {
                if (signUpConfig.primaryProperty === 'doc') setState({ ...state, doc: primaryPropertyValue, isLoading: false, signUpConfig })
                else setState({ ...state, email: primaryPropertyValue, isLoading: false, signUpConfig })
            } else {
                setState({ ...state, isLoading: false, signUpConfig })
            }
        } catch (error) {
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const updateDoc = (docConfig: DocConfig, newValue: string) => {
        if (docConfig.mask) {
            if (docConfig.mask === 'cpf') {
                let cpfWithMask = applyCpfMask(newValue)
                return setState({ ...state, doc: cpfWithMask })
            }
        }

        setState({ ...state, doc: newValue })
    }

    const updateEmail = (newValue: string) => {
        setState({ ...state, email: newValue })
    }

    const updatePassword = (password: string) => {
        setState({ ...state, password })
    }

    const applyCpfMask = (value: string) => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    const isEmailValid = (email: string): boolean => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const testCPF = (strCPF: string) => {
        strCPF = strCPF.replace(/\D/g, '')
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (let n = 1; n <= 10; n++) Soma = Soma + parseInt(strCPF.substring(n - 1, n)) * (12 - n);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    const isInputInvalid = (signUpConfig: SignUpConfig) => {
        if (signUpConfig.doc && signUpConfig.doc.required && signUpConfig.primaryProperty === 'doc') {
            let docConfig = signUpConfig.doc
            if (docConfig.mask) {
                if (docConfig.mask === 'cpf') {
                    if (!testCPF(state.doc || '')) return true
                }
            }

            if (!state.doc || state.doc.length < 4) return true

        }

        if (signUpConfig.email && signUpConfig.primaryProperty === 'email') {
            if (signUpConfig.email.required) {
                if (!state.email || !isEmailValid(state.email)) return true
            } else {
                if (state.email && state.email.length > 0 && !isEmailValid(state.email)) return true
            }
        }

        if (signUpConfig.password) {
            if (!state.password || state.password.length < 8) return true
        }

        return false

    }

    const signIn = async (companyId: string, signUpConfig: SignUpConfig) => {
        try {
            let signInData: any = { companyId }
            if (signUpConfig.primaryProperty === 'doc') signInData.doc = state.doc
            else signInData.email = state.email
            if (signUpConfig.password) signInData.password = state.password
            setState({ ...state, isLoading: true })
            let enterService = new EnterService()
            let userRes = await enterService.signInUser(config.endpoint, companyId, signInData)
            let cookies = new Cookies()
            cookies.set('user', userRes.user.getData(), { path: '/', maxAge: 31536000, sameSite: 'none', secure: true })
            cookies.set('token', userRes.tokenData.token, { path: '/', maxAge: 31536000, sameSite: 'none', secure: true })
            cookies.set('refreshToken', userRes.tokenData.refreshToken, { path: '/', maxAge: 31536000, sameSite: 'none', secure: true })
            navigate(`/${companyId}${ROUTES.MAIN}`)
        } catch (error) {
            // Wrong_Pwd
            console.log(error)
            if (error.response && error.response.data && error.response.data.error) {
                let errorCode = error.response.data.error.code
                if (errorCode === 'Wrong_Pwd') {
                    return setState({ ...state, isLoading: false, error: 'Senha incorreta.' })
                }
            }
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const forgotPwdClick = async (signUpConfig: SignUpConfig) => {
        let { doc, email } = state
        let companyId = company ? company.id : undefined
        if (!companyId) return
        if (signUpConfig.doc && signUpConfig.doc.required) {
            let docConfig = signUpConfig.doc
            if (docConfig.mask) {
                if (docConfig.mask === 'cpf') {
                    if (!testCPF(doc || '')) return setState({ ...state, error: 'Por favor, digite um cpf valido' })
                }
            }

            if (!doc || doc.length < 4) return setState({ ...state, error: 'Por favor, digite um documento valido' })

        } else if (email) {
            if (!isEmailValid(email)) return setState({ ...state, error: 'Por favor, digite um e-mail válido para enviarmos o link de recuperação de senha.' })
        }

        setState({ ...state, isLoading: true })
        try {
            let enterService = new EnterService()
            let pwdRecoverRes = await enterService.recoverUserPwd(config.endpoint, companyId, doc, email)
            if (pwdRecoverRes.userEmail) {
                setState({ ...state, isLoading: false, info: `Email para recuperar senha enviado para ${pwdRecoverRes.userEmail}`, error: undefined })
            } else if (pwdRecoverRes.userWhatsApp) {
                setState({ ...state, isLoading: false, info: `WhatsApp para recuperar senha enviado para ${pwdRecoverRes.userWhatsApp}`, error: undefined })
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                let errorCode = error.response.data.error.code
                if (errorCode === 'Email_Not_Found') {
                    if (signUpConfig.email) return setState({ ...state, isLoading: false, error: 'Infelizmente você não possui um email cadastrado para receber o link de recuperação senha.' })
                    else if (signUpConfig.phone) return setState({ ...state, isLoading: false, error: 'Infelizmente você não possui um telefone cadastrado para receber o link de recuperação senha.' })
                }
            }
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const renderDocInput = (docConfig: DocConfig) => {
        return (<FormGroup>
            <Label for="docTitle">{docConfig.title}</Label>
            <Input style={{ opacity: 0.85 }} name="title" id="docTitle" placeholder={docConfig.placeholder} value={state.doc || ''} onChange={(event: any) => updateDoc(docConfig, event.target.value)} />
        </FormGroup>)
    }

    const renderInfoModal = (info: string) => {

        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => setState({ ...state, info: undefined })}>
                    <ModalBody>
                        {info}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setState({ ...state, info: undefined })}>OK</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    const renderError = (error: string) => {
        return (
            <Row>
                <Col style={{ marginTop: 5, marginBottom: 5 }} md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>

        );
    }

    const renderEmailInput = (emailConfig: EmailConfig) => {
        return (<FormGroup>
            <Label for="docTitle">{emailConfig.title}</Label>
            <Input style={{ opacity: 0.85 }} name="title" id="docTitle" placeholder={emailConfig.placeholder} value={state.email} onChange={(event: any) => updateEmail(event.target.value)} />
        </FormGroup>)
    }

    const renderPasswordInput = (config: SignUpConfig) => {
        return (<FormGroup>
            <Label for="passwordTitle">Senha</Label>
            <Input style={{ opacity: 0.85 }} type="password" name="title" id="passwordTitle" placeholder={'Por favor, digite sua senha'} value={state.password} onChange={(event: any) => updatePassword(event.target.value)} />
            <div className="cursorPointer" onClick={() => forgotPwdClick(config)} style={{ fontSize: 13, marginTop: 5, fontStyle: 'bold' }}><b>Esqueci minha senha</b></div>
        </FormGroup>)
    }

    const renderSignInForm = (config: SignUpConfig, company: Company) => {
        return (<Row>
            <Col className="d-flex flex-column justify-content-center relative mt-3" md={{ size: 6, offset: 3 }}>
                <Form>
                    {config.primaryProperty === 'doc' && renderDocInput(config.doc!)}
                    {config.primaryProperty === 'email' && renderEmailInput(config.email!)}
                    {config.password && renderPasswordInput(config)}
                    <Button style={{ background: company.mainColor || '#000', borderStyle: 'none' }} block disabled={isInputInvalid(config)} onClick={() => signIn(company.id, config)}>Entrar</Button>
                </Form>
            </Col>
        </Row>)
    }

    const renderHeader = (company: Company) => {
        return (<Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', backgroundColor: '#FFFFFFDA', color: '#0f3252' }}>
            <Col className="d-flex align-items-center justify-content-between" style={{ minHeight: '4em' }} md={{ size: 6, offset: 3 }}>
                <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon icon={faArrowLeft} size='2x' /></Button>
                <div style={{ verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Entrar</b></div>
                <img alt='company' style={{ height: 60, width: 60, margin: 5 }} src={company.pic} />
            </Col>
        </Row>)
    }

    const { signUpConfig, error, isLoading, info } = state;

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column relative" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {company && renderHeader(company)}
        {error && renderError(error)}
        {info && renderInfoModal(info)}
        {signUpConfig && company && renderSignInForm(signUpConfig, company)}
    </Container>)
}

export default LoginScreen