import React, { Component, useEffect } from 'react';
import { Container, Row, Col, Button, Alert } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCommentDots, faArrowLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'

import Company from '../../models/Company';
import { SMSTypes, ErrorSMS, SMS, SmsReceived } from '../../models/SMS';
import LoadingScreen from '../loading';
import TokenHelper from '../../auth/TokenHelper';
import AdminSmsService from '../../services/admin/adminSmsService'
import config from '../../config'
import { useUser } from '../../hooks/useUser';

interface State {
    error: string | undefined;
    isLoading: boolean;
    company: Company | undefined;
    smsSentArray: Array<SMS>;
    smsReceivedArray: Array<SmsReceived>;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    company: undefined,
    smsSentArray: [],
    smsReceivedArray: [],
};

interface PhoneResponseData {
    phone: string,
    smsSentArray: Array<SMS>;
    smsReceivedArray: Array<SmsReceived>;
    lastActionTime: number;
}

const SmsPanelScreen = () => {


    let navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { company } = useUser()

    useEffect(() => {
        if (company) {
            loadUserAccessAndLastMessageSent(company.id)
        } else {
            setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.' })
        }
    }, [company])

    const loadUserAccessAndLastMessageSent = async (companyId: string) => {
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            setState({ ...state, isLoading: true, error: undefined })
            let adminSmsService = new AdminSmsService()
            let { smsSentArray, smsReceivedArray } = await adminSmsService.getcompanySmsSentAndReceived(token, config.endpoint)
            setState({ ...state, isLoading: false, smsSentArray, smsReceivedArray })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadUserAccessAndLastMessageSent(companyId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const goBack = () => navigate(-1)

    const renderHeader = (company: Company) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { goBack() }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Resposta WhatsApp dos Usuários</b></div>
                    <img alt='foto da empresa' style={{ height: 60, marginBottom: 10, marginTop: 10 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderSmsData = (reducedDataArray: Array<PhoneResponseData>) => {

        let renderUserSmsData = (data: SMS | SmsReceived) => {
            let lastSmsSentDate = new Date(data.dateMillis)
            let formattedDate = `${lastSmsSentDate.toLocaleDateString("pt-BR")} - ${lastSmsSentDate.toLocaleTimeString("pt-BR")}`
            var message = ''
            if (data instanceof SMS) {
                if (data.type === SMSTypes.EnterLink) {
                    message = data.message || 'Envio de link para jogar'
                } else if (data.type === SMSTypes.Error) {
                    let errorSMS = new ErrorSMS(data)
                    message = `Erro ao enviar: ${errorSMS.error}`
                } else if (data.type === SMSTypes.RecoverPwd) {
                    message = 'Link para recuperar senha'
                } else if (data.type === SMSTypes.AutomaticResponse) {
                    message = 'Resposta Automática'
                }
                return <div>
                    <div className="d-flex" style={{ marginTop: 5, marginBottom: 5 }}>
                        <div style={{ fontSize: 'small', marginRight: 10 }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faPaperPlane} /><i>{formattedDate}:</i></div>
                        <div style={{ fontSize: 'small' }}>{message}</div>
                    </div>
                </div>
            } else {
                let smsReceived = data as SmsReceived
                let message = smsReceived.text
                return <div>
                    <div className="d-flex" style={{ marginTop: 5, marginBottom: 5 }}>
                        <div style={{ fontSize: 'small', marginRight: 10 }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faCommentDots} /><i>{formattedDate}:</i></div>
                        <div style={{ fontSize: 'small' }}>{message}</div>
                    </div>
                </div>
            }
        }

        return <Row style={{ marginTop: 10 }}>
            {reducedDataArray.sort((a, b) => {
                return b.lastActionTime - a.lastActionTime
            }).map(({ phone, smsSentArray, smsReceivedArray }) => {

                let timeline = [...smsSentArray, ...smsReceivedArray].sort((a, b) => { return a.dateMillis - b.dateMillis })

                return <Col className="d-flex flex-column mt-2 mb-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }} md={{ size: 6, offset: 3 }}>
                    <div style={{ fontSize: 12, marginLeft: 5, marginTop: 5, color: '#1d3256' }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faPhone} /><b>{phone}</b></div>
                    {timeline.map(smsData => renderUserSmsData(smsData))}
                </Col>
            })}
        </Row>
    }

    let { error, isLoading, smsSentArray, smsReceivedArray } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    let reducedDataArray: Array<PhoneResponseData> = []
    let allPhones = [...smsSentArray.map(data => data.toPhone), ...smsReceivedArray.map(data => data.phone)].reduce((allPhones: Array<string>, current: string) => {
        if (allPhones.filter(phone => phone === current).length === 0) {
            allPhones.push(current)
        }
        return allPhones
    }, [])

    for (var i = 0; i < allPhones.length; i++) {
        let currentPhone = allPhones[i]
        let phoneSmsSent = smsSentArray.filter(smsSent => smsSent.toPhone === currentPhone)
        let phoneSmsReceived = smsReceivedArray.filter(smsReceived => smsReceived.phone === currentPhone)
        let lastSmsReceivedTime = phoneSmsReceived.reduce((lastTime: number, current: SmsReceived) => {
            if (lastTime < current.dateMillis) return current.dateMillis
            else return lastTime
        }, 0)
        let reducedData: PhoneResponseData = {
            phone: currentPhone,
            smsSentArray: phoneSmsSent,
            smsReceivedArray: phoneSmsReceived,
            lastActionTime: lastSmsReceivedTime
        }
        reducedDataArray.push(reducedData)
    }

    return (<Container className="d-flex flex-column" fluid style={{ minHeight: '100vh', background: 'white' }}>
        {company && renderHeader(company)}
        {error && <Col className="d-flex flex-column" md={{ size: 6, offset: 3 }}><Alert color="danger">{error}</Alert></Col>}
        {renderSmsData(reducedDataArray)}
    </Container>)
}

export default SmsPanelScreen