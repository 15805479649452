import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText, Spinner, InputGroup, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLink, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'
import CardImage from '../../../../models/CardImage';
import Module from '../../../../models/Module';
import TokenHelper from '../../../../auth/TokenHelper';
import AiService from '../../../../services/admin/aiService';
import config from '../../../../config';
import { IAttribution } from '../../../../models/DeckCards';

interface SelectImageProps {
    uploadFile: (file: File | undefined) => void,
    getCardImages: (callback: (modules: Array<Module>, cardImages: Array<CardImage>) => void) => void,
    onImageSelected: (image: string) => void,
    onPromptImageSelected?: (image: string, attribution: IAttribution) => void,
    goBackToEdit: () => void
}

interface SelectImageComponentState {
    isLoading: boolean;
    modules: Array<Module>;
    cardImages: Array<CardImage>;
    selectedModule: Module | undefined
    linkInput: string;
    promptInput: string;
    promptImages: Array<{ image: string, attribution: IAttribution }>;
    error: string | undefined;
}

const SELECT_IMAGE_INITIAL_STATE: SelectImageComponentState = {
    isLoading: true,
    modules: [],
    cardImages: [],
    selectedModule: undefined,
    linkInput: '',
    promptInput: '',
    promptImages: [],
    error: undefined,
}

export default class SelectImageComponent extends Component<SelectImageProps, SelectImageComponentState> {

    constructor(props: SelectImageProps) {
        super(props)

        this.state = { ...SELECT_IMAGE_INITIAL_STATE }
    }

    componentDidMount() {
        this.props.getCardImages((modules, cardImages) => {
            this.setState({ modules, cardImages, isLoading: false })
        })
    }

    async searchForImagePrompt(prompt: string) {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = await tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Não foi possível obter o token de acesso. Por favor, tente novamente mais tarde.', isLoading: false })
            let aiService = new AiService()
            let images = await aiService.getImagesFromPrompt(token, config.endpoint, prompt, 'landscape')
            this.setState({ promptImages: images, isLoading: false })
        } catch (error) {
            this.setState({ error: error.toString(), isLoading: false })
        }
    }

    renderPromptImages(promptImages: Array<{ image: string, attribution: IAttribution }>) {

        return promptImages.map((promptImage, index) => {
            return (<Col key={index} xs="6" md="3" sm="4">
                <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                    <div className="d-flex flex-column justify-content-center">
                        <img className="m-3" style={{ objectFit: 'cover', maxHeight: 250, maxWidth: 250 }} src={promptImage.image} alt="Card cap" />
                        <div style={{ color: '#8f8f8f', fontSize: 'x-small', textAlign: 'center', marginBottom: 10, marginTop: 2 }}>Foto de <a style={{ color: '#8e8e8e' }} href={promptImage.attribution.authorLink} target='_blank' rel='noreferrer'><b>{promptImage.attribution.authorName}</b></a> em <a style={{ color: '#8e8e8e' }} href={promptImage.attribution.sourceLink} target='_blank' rel='noreferrer'><b>{promptImage.attribution.sourceName}</b></a></div>
                        <Button style={{ borderStyle: 'none' }} outline color="primary" onClick={() => this.props.onPromptImageSelected!(promptImage.image, promptImage.attribution)}>Selecionar <FontAwesomeIcon icon={faCheckCircle} /></Button>
                    </div>
                </div>
            </Col>)
        })
    }

    renderCardImages(modules: Array<Module>, selectedModule: Module | undefined, cardImages: Array<CardImage>) {
        let moduleImages = cardImages.filter(cardImage => {
            if (selectedModule) return cardImage.moduleId === selectedModule.id
            else return true
        })

        return moduleImages.map((moduleImage, index) => {
            let module = _.head(modules.filter(module => module.id === moduleImage.moduleId))
            return (<Col key={index} xs="6" md="3" sm="4">
                <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                    <div style={{ color: 'DarkGray', fontFamily: 'Montserrat', verticalAlign: 'middle' }}>{module ? module.title : ''}</div>
                    <div className="d-flex flex-column justify-content-center">
                        <img className="m-3" style={{ objectFit: 'cover', maxHeight: 250, maxWidth: 250 }} src={moduleImage.imageUrl} alt="Card cap" />
                        <Button style={{ borderStyle: 'none' }} outline color="primary" onClick={() => this.props.onImageSelected(moduleImage.imageUrl)}>Selecionar <FontAwesomeIcon icon={faCheckCircle} /></Button>
                    </div>
                </div>
            </Col>)
        })
    }

    renderModuleSelection(modules: Array<Module>, selectedModule: Module | undefined) {
        let options = modules.map(module => { return { label: module.title, value: module.id } }) //[{ label: 'Todas', value: 'Todas' }, ...modules.map(module => { return { label: module.title, value: module.id } })]
        return <Col className="d-flex flex-column justify-content-center relative mt-3" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
            <FormGroup >
                <Select placeholder="Selecionar imagens de uma Missão" isClearable={true} value={selectedModule ? { label: selectedModule.title, value: selectedModule.id } : undefined} options={options} onChange={
                    opt => {
                        if (!opt) this.setState({ selectedModule: undefined })
                        else {
                            let module = _.first(modules.filter(module => module.id === opt!.value))
                            this.setState({ selectedModule: module })
                        }
                    }
                } />
            </FormGroup></Col>
    }

    render() {

        let { isLoading, modules, selectedModule, cardImages, linkInput, promptInput, promptImages, error } = this.state

        if (isLoading) { return <Row><Col className="d-flex justify-content-center mt-4" md={{ size: 6, offset: 3 }}><Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />{' '}</Col></Row> }

        return (<Fragment>
            <Row>
                {error && <Col md={{ size: 6, offset: 3 }}><Alert color="danger">{error}</Alert></Col>}
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div className="d-flex flex-column justify-content-center mt-3" style={{ background: '#dbdbdbda', borderRadius: 5, padding: 10 }}>
                        <Form>
                            <FormGroup>
                                <Label for="image">Carregar Imagem</Label>
                                <Input type="file" name="file" id="image" accept="image/*" onChange={(event) => this.props.uploadFile(event.target.files ? event.target.files[0] : undefined)} />
                                <FormText color="muted">Carregar uma imagem do seu dispositivo.</FormText>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <Input valid={linkInput.length > 5} name="imageUrl" id="imageUrl" placeholder="URL da Imagem" value={linkInput} onChange={(event) => this.setState({ linkInput: event.target.value })} />
                                    <Button disabled={linkInput.length < 6} outline color="primary" onClick={() => this.props.onImageSelected(linkInput)}>Usar Link <FontAwesomeIcon icon={faLink} /></Button>
                                </InputGroup>
                                <FormText color="muted">Utilizar o link de uma imagem da web.</FormText>
                            </FormGroup>
                            {this.props.onPromptImageSelected && <FormGroup>
                                <InputGroup>
                                    <Input disabled={promptImages.length > 0} name="imagePrompt" id="imagePrompt" placeholder="Descrição da imagem" value={promptInput} onChange={(event) => this.setState({ promptInput: event.target.value })} />
                                    {promptImages.length === 0 && <Button disabled={promptInput.length === 0} outline color="primary" onClick={() => this.searchForImagePrompt(promptInput)}>Procurar <FontAwesomeIcon icon={faSearch} /></Button>}
                                    {promptImages.length > 0 && <Button color="danger" onClick={() => this.setState({ promptImages: [], promptInput: '' })}>Limpar <FontAwesomeIcon icon={faTimes} /></Button>}
                                </InputGroup>
                                <FormText color="muted">Buscar uma imagem do repositório web.</FormText>
                            </FormGroup>}
                        </Form>
                    </div>
                </Col>
                {modules.length > 0 && this.renderModuleSelection(modules, selectedModule)}
            </Row>
            <Row>{promptImages.length > 0 && this.renderPromptImages(promptImages)}</Row>
            <Row>{cardImages.length > 0 && this.renderCardImages(modules, selectedModule, cardImages)}</Row>
        </Fragment>)
    }

}