import React, { useRef, useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, Label, Spinner } from "reactstrap";
import { faArrowLeft, faCheckCircle, faPlusCircle, faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileHelper from "../../../files/FileHelper";
import { IAttribution } from "../../../models/DeckCards";
import { removeBackground as removeBackgroundService, getImagesFromPrompt } from '../services'
import { SliderConfig } from "./interfaces";

interface ManageImageComponentProps {
    onImageSelected: (image: string) => void
    sliders: Array<SliderConfig>
    fileDataUrl: string | undefined
    onExit: () => void
    scale?: number
    setScale?: (scale: number) => void
}

export default function ManageImageComponent(props: ManageImageComponentProps) {

    const uploadButtonRef = useRef<HTMLInputElement>(null)
    const [error, setError] = useState<string | undefined>(undefined)
    const [promptInput, setPromptInput] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [orientation, setOrientation] = useState<string>('landscape')
    const [orientationDropdownOpen, setOrientationDropdownOpen] = useState<boolean>(false)
    const [promptImages, setPromptImages] = useState<Array<{ image: string, attribution?: IAttribution }>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [searching, setSearching] = useState<boolean>(false)
    const fileHelper = new FileHelper()

    const processFile = async (file: File) => {
        try {
            let fileDataUrl = await fileHelper.getFileDataUrl(file)
            props.onImageSelected(fileDataUrl)
        } catch (error) {
            setError(error)
        }
    }

    const removeBackground = async (fileDataUrl: string) => {
        setLoading(true)
        setError(undefined)
        try {
            let url = await removeBackgroundService(fileDataUrl)
            props.onImageSelected(url)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(error.toString())
        }
    }

    const searchForImagePrompt = async (promptInput: string, orientation: string, page: number) => {
        setLoading(true)
        setError(undefined)
        try {
            let images = await getImagesFromPrompt(promptInput, orientation, page)
            if (page > 1) {
                setPromptImages([...images, ...promptImages])
            } else {
                setPromptImages(images)
            }
            setLoading(false)
            setPage(page)
        } catch (error) {
            setError(error.toString())
            setLoading(false)
        }
    }

    const renderPromptImages = (promptImages: Array<{ image: string, attribution?: IAttribution }>) => {

        return promptImages.map((promptImage, index) => {
            return <div key={index} className="d-flex flex-column mt-2 mb-2 p-1" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div className="d-flex flex-column align-items-center">
                    <img className="m-1" style={{ objectFit: 'cover', maxHeight: 250, maxWidth: 250 }} src={promptImage.image} alt="Card cap" />
                    {promptImage.attribution && <div style={{ color: '#8f8f8f', fontSize: 'x-small', textAlign: 'center', marginBottom: 10, marginTop: 2 }}>Foto de <a style={{ color: '#8e8e8e' }} href={promptImage.attribution.authorLink} target='_blank' rel='noreferrer'><b>{promptImage.attribution.authorName}</b></a> em <a style={{ color: '#8e8e8e' }} href={promptImage.attribution.sourceLink} target='_blank' rel='noreferrer'><b>{promptImage.attribution.sourceName}</b></a></div>}
                    <Button style={{ borderStyle: 'none' }} outline color="primary" onClick={() => { props.onImageSelected(promptImage.image); setPromptImages([]); setPromptInput('') }}>Selecionar <FontAwesomeIcon icon={faCheckCircle} /></Button>
                </div>
            </div>
        })
    }

    if (loading) { return <div className="d-flex justify-content-center align-items-center bg-light p-2 m-2 rounded border"><Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />{' '}</div> }

    if (searching) {
        return <div className="bg-light p-2 m-2 rounded border">
            <Button className="mb-2" color="light" size="sm" onClick={() => setSearching(false)}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
            <div className="d-flex flex-column justify-content-center">
                {error && <div>{error}</div>}
                <Form>
                    <FormGroup>
                        <InputGroup>
                            <Input name="imagePrompt" id="imagePrompt" placeholder="Descrição da imagem" value={promptInput} onChange={(event) => setPromptInput(event.target.value)} />
                            <Button onClick={() => searchForImagePrompt(promptInput, orientation, 1)}><FontAwesomeIcon icon={faSearch} /></Button>
                        </InputGroup>
                    </FormGroup>
                    <div className="d-flex mb-2">
                        <Dropdown className="me-1" isOpen={orientationDropdownOpen} toggle={() => setOrientationDropdownOpen(!orientationDropdownOpen)} >
                            <DropdownToggle caret>{orientation === 'landscape' ? 'Horizontal' : 'Vertical'}</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => setOrientation('landscape')}>Horizontal</DropdownItem>
                                <DropdownItem onClick={() => setOrientation('portrait')}>Vertical</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        {promptImages.length > 0 && <Button onClick={() => searchForImagePrompt(promptInput, orientation, page + 1)}><FontAwesomeIcon icon={faPlusCircle} /> Mais Imagens</Button>}
                    </div>
                </Form>
            </div>
            <div style={{ maxHeight: 500, overflowY: 'scroll' }}>
                {promptImages.length > 0 && renderPromptImages(promptImages)}
            </div>
        </div>
    }

    return <div className="bg-light p-2 m-2 rounded border">
        <Button color="light" size="sm" onClick={() => props.onExit()}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
        {error && <div>{error}</div>}
        <input id="myInput" type="file" ref={uploadButtonRef} style={{ display: 'none' }} onChange={(event) => { if (event.target.files) processFile(event.target.files[0]) }} />
        <div className="d-flex flex-column justify-content-center m-2">
            {props.fileDataUrl && <img className="rounded border bg-white" style={{ height: 180, marginBottom: 5, marginTop: 10, objectFit: 'contain' }} src={props.fileDataUrl} alt="background" />}
            {!props.fileDataUrl && <div className="rounded bg-white border" style={{ height: 180, marginBottom: 5, marginTop: 10 }} />}
            <Button outline onClick={() => uploadButtonRef && uploadButtonRef.current?.click()}><FontAwesomeIcon icon={faUpload} /> Fazer Upload</Button>
            <Button className="mt-1" outline onClick={() => setSearching(true)}><FontAwesomeIcon icon={faSearch} /> Buscar Imagem</Button>
            {props.fileDataUrl && <Button className="mt-1" onClick={() => removeBackground(props.fileDataUrl!)}>Remover Fundo</Button>}
        </div>
        {props.sliders.map((sliderConfig, index) => {
            return <div key={`SliderConfig_${index}`}>
                <InputGroup>
                    <Label for={`ImageSlider_${index}`}>{sliderConfig.title} {sliderConfig.value}</Label>
                    <Input id={`ImageSlider_${index}`} value={sliderConfig.value} name={`ImageSlider_${index}`} type="range" min={sliderConfig.min} max={sliderConfig.max} step={sliderConfig.step} onChange={e => sliderConfig.setSliderValue(+e.target.value)} />
                </InputGroup>
            </div>
        })}
        {props.setScale && <InputGroup>
            <Label for="imageScale">Escala: {props.scale}</Label>
            <Input id="imageScale" value={props.scale} name="imageHorizontalPos" type="range" min={0.05} max={2} step={0.05} onChange={e => props.setScale!(+e.target.value)} />
        </InputGroup>}
    </div>
}