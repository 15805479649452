import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

export default function ToolTipComponent(param: { target: string, tooltip: string, description: string }) {
  //const ref = useRef(null)
  return <Fragment>
    <span data-tip={param.tooltip} style={{ color: '#353f45', fontSize: 'small' }}><b>{param.description}</b> <FontAwesomeIcon icon={faQuestionCircle} /></span>
    <ReactTooltip multiline />
  </Fragment>
  
}