import axios from 'axios'
import UserAccessValidation from '../../models/UserAccessValidation'
import { LastSmsSent } from '../../models/SMS'
import Company from '../../models/Company'
import { SignUpConfig } from '../../models/CompanyConfig'

export default class AdminAccessValidationService {

    async addUserAccessValidation(token: string, mainUrl: string, primaryPropertyValue: string, name?: string, phone?: string, authData?: { [id: string]: string }): Promise<UserAccessValidation> {
        let url = `${mainUrl}/admin/userAccess/addAccess`
        let result = await axios.post(url, { primaryPropertyValue, name, phone, authData }, { headers: { 'Authorization': `Token ${token}` } })
        return new UserAccessValidation(result.data.data)
    }

    async updateUserAccessValidation(token: string, mainUrl: string, userAccessValidationArray: Array<any>, invalidate: boolean = true): Promise<Array<UserAccessValidation>> {
        let url = `${mainUrl}/admin/userAccess/upload`
        let result = await axios.post(url, { data: userAccessValidationArray, invalidate }, { headers: { 'Authorization': `Token ${token}` } })
        let userAccessValidationArrayResult = result.data.data.userAccessArray.map((data: any) => new UserAccessValidation(data))
        return userAccessValidationArrayResult
    }

    async invalidateUsers(token: string, mainUrl: string, primaryPropertyValueArray: Array<string>) {
        let url = `${mainUrl}/admin/userAccess/uploadForInvalidation`
        let result = await axios.post(url, { data: primaryPropertyValueArray }, { headers: { 'Authorization': `Token ${token}` } })
        return {
            primaryPropertyArray: result.data.data.primaryPropertyArray as Array<string>,
            signUpConfig: new SignUpConfig(result.data.data.companySignUpConfig)
        }
    }

    async deleteUserAccessValidation(token: string, mainUrl: string, primaryPropertyValue: string) {
        let url = `${mainUrl}/admin/userAccess/deleteAccess`
        await axios.post(url, { primaryPropertyValue }, { headers: { 'Authorization': `Token ${token}` } })
    }

    getAllAccessValidation = async (token: string, mainUrl: string): Promise<Array<UserAccessValidation>> => {
        let url = `${mainUrl}/admin/userAccess/allAccessValidation`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let userAccessValidationArray = result.data.data.accessValidation.map((data: any) => new UserAccessValidation(data))
        return userAccessValidationArray
    }

    getAllAccessValidationWithLastSms = async (token: string, mainUrl: string): Promise<UserAccessValidationWithLastSmsRes> => {
        let url = `${mainUrl}/admin/userAccess/allAccessValidationWithLastSms`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new UserAccessValidationWithLastSmsRes(result.data.data)
    }

    getCompanySignUpConfigForUserAccess = async (mainUrl: string, token: string): Promise<SignUpConfigForUserAccess> => {
        let url = `${mainUrl}/admin/userAccess/companySignUpConfigForUserAccess`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new SignUpConfigForUserAccess(result.data.data)
    }

    /*deleteAllAccessValidation = async (token: string, mainUrl: string): Promise<void> => {
        let url = `${mainUrl}/admin/userAccess/deleteAll`
        await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
    }*/

}

export class SignUpConfigForUserAccess {
    company: Company
    signUpConfig: SignUpConfig
    companyMessage?: string
    constructor(data: any) {
        this.company = new Company(data.company)
        this.signUpConfig = new SignUpConfig(data.signUpConfig)
        this.companyMessage = data.companyMessage
    }
}

export class UserAccessValidationWithLastSmsRes {
    accessValidation: Array<UserAccessValidation>;
    lastSmsSent: Array<LastSmsSent>;
    constructor(data: any) {
        this.accessValidation = data.accessValidation.map((data: any) => new UserAccessValidation(data))
        this.lastSmsSent = data.lastSmsSent.map((data: any) => new LastSmsSent(data))
    }
}