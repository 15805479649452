import React, { Component, useEffect } from 'react';
import { Container, Row, Col, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { saveAs } from 'file-saver';
import ReactList from 'react-list';

import * as ROUTES from '../../../../constants/routes'
import Company from '../../../../models/Company';
import config from '../../../../config'
import LoadingScreen from '../../../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClipboardList, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UserCompanyScore } from '../../../../models/Scores';
import User from '../../../../models/User';
import Module from '../../../../models/Module';
import { SubModule } from '../../../../models/SubModule';
import TokenHelper from '../../../../auth/TokenHelper';
import AdminService from '../../../../services/adminService';
import UserAccessValidation from '../../../../models/UserAccessValidation';
import UserDataComponent, { UserGameComponent } from '../UserDataComponent'
import UserAccessComponent from '../UserAccessComponent'
import { usersDidntPlayedModuleData, usersPlayedModuleData, usersCreatedAccountData, allUsersData, usersDidntCreatedAccountData } from './ReportsHelper'
import { ReportConfig, SignUpConfig } from '../../../../models/CompanyConfig';
import { lowerCaseAndRemovePunctuationFromString } from '../../../../utils/punctuation';
import { ComposedData, UserFilter, getComposedData, getFilteredComposedData, getUsersDidntPlayedModule, getUsersPlayedModule, getUserFilterText } from './UsageFilters';
import { SearchInputView } from './SearchInput';
import UserFiltersComponent, { UserFiltersComponentActions } from './UserFiltersComponent';
import { useUser } from '../../../../hooks/useUser';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

interface State {
    error: string | undefined;
    isLoading: boolean;
    scores: Array<UserCompanyScore>;
    lastTimeUsersPlayed: Array<any>;
    modules: Array<Module>;
    subModules: Array<SubModule>;
    composedData: ComposedData[];
    searchInput: string;
    userFilter: UserFilter;
    selectedModule?: Module;
    selectedUserToDeleteScoreInModule?: [Module, User]
    reportConfig?: ReportConfig
    signUpConfig?: SignUpConfig
    authDataSelectedValues: { [id: string]: string }
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    scores: [],
    modules: [],
    subModules: [],
    composedData: [],
    searchInput: '',
    userFilter: UserFilter.AllUsers,
    selectedModule: undefined,
    lastTimeUsersPlayed: [],
    selectedUserToDeleteScoreInModule: undefined,
    reportConfig: undefined,
    signUpConfig: undefined,
    authDataSelectedValues: {},
};


const UsageControlScreen = () => {

    const navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { company, noCompany } = useUser()
    const { height } = useWindowDimensions()

    useEffect(() => {
        if (company) loadUsageControl(company.id)
        if (noCompany) setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.' })
    }, [company, noCompany])

    const loadUsageControl = async (companyId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) token = await tokenHelper.getNewToken()
            let adminService = new AdminService()
            let { scores, users, lastTimeUsersPlayed, modules, subModules, accessValidation, reportConfig, signUpConfig } = await adminService.getUsageControlData(token, config.endpoint)
            let composedData = getComposedData(signUpConfig.primaryProperty, accessValidation, users)
            setState({ ...state, scores, composedData, lastTimeUsersPlayed, modules, subModules, reportConfig, signUpConfig, isLoading: false })
        } catch (error) {
            console.log(error)
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) loadUsageControl(companyId)
            else setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const updateAuthDataSelection = (selection: string | undefined, id: string) => {
        let authDataSelectedValues = state.authDataSelectedValues
        if (selection === undefined || selection.length === 0) {
            delete authDataSelectedValues[id]
            setState({ ...state, authDataSelectedValues })
        } else {
            authDataSelectedValues[id] = selection
            setState({ ...state, authDataSelectedValues })
        }
    }

    const selectModule = (module: Module | undefined) => {
        setState({ ...state, selectedModule: module })
    }

    const changeFilter = (userFilter: UserFilter) => {
        setState({ ...state, userFilter })
    }

    const downloadReport = (primaryProperty: string, reportConfig: ReportConfig | undefined, userFilter: UserFilter, composedData: ComposedData[], scores: Array<UserCompanyScore>, modules: Array<Module>, subModules: Array<SubModule>, searchInput: string, authDataSelectedValues: { [id: string]: string }, selectedModule: Module | undefined) => {

        let searchInputWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(searchInput)
        let filteredComposedData = getFilteredComposedData(composedData, searchInputWithoutPunctuation, authDataSelectedValues)

        let filterName = getUserFilterText(userFilter)
        let fileExtension = '.xlsx';

        if (userFilter === UserFilter.AllUsers) {

            let data = allUsersData(primaryProperty, reportConfig, filteredComposedData)
            saveAs(data, 'Relatório ' + filterName + fileExtension)

        } if (userFilter === UserFilter.UsersCreatedAccount) {

            let sortedModules = modules.sort((a, b) => { return a.pos - b.pos })

            let filteredUsers = filteredComposedData.reduce((acc, data) => {
                if (data.user) acc.push(data.user)
                return acc
            }, new Array<User>())
            let data = usersCreatedAccountData(primaryProperty, reportConfig, filteredUsers, scores, sortedModules, subModules)
            saveAs(data, 'Relatório ' + filterName + fileExtension);

        } else if (userFilter === UserFilter.UsersDidntCreatedAccount) {

            let filteredUserAccess = filteredComposedData.reduce((acc, data) => {
                if (!data.user && data.userAccess) acc.push(data.userAccess)
                return acc
            }, new Array<UserAccessValidation>())
            let data = usersDidntCreatedAccountData(primaryProperty, reportConfig, filteredUserAccess)
            saveAs(data, 'Relatório ' + filterName + fileExtension)

        } else if (userFilter === UserFilter.UsersPlayedModule) {

            // mostrar usuarios que jogaram o modulo escolhido, se tiver
            let sortedModules = modules.filter(module => {
                if (selectedModule) return module.id === selectedModule.id
                return true
            }).sort((a, b) => { return a.pos - b.pos })

            let filteredUsers = filteredComposedData.reduce((acc, data) => {
                if (data.user) acc.push(data.user)
                return acc
            }, new Array<User>())

            let usersPlayedModule = getUsersPlayedModule(filteredUsers, selectedModule, scores, subModules)

            let data = usersPlayedModuleData(primaryProperty, reportConfig, usersPlayedModule, scores, sortedModules, subModules)
            saveAs(data, 'Relatório ' + filterName + fileExtension);

        } else if (userFilter === UserFilter.UsersDidntPlayedModule) {

            let filteredUsers = filteredComposedData.reduce((acc, data) => {
                if (data.user) acc.push(data.user)
                return acc
            }, new Array<User>())

            let usersDidntPlayedModule = getUsersDidntPlayedModule(filteredUsers, selectedModule, scores, subModules)

            let data = usersDidntPlayedModuleData(primaryProperty, reportConfig, usersDidntPlayedModule, selectedModule)
            saveAs(data, 'Relatório ' + filterName + fileExtension);

        } else if (userFilter === UserFilter.BlockedUsers) {

            let sortedModules = modules.sort((a, b) => { return a.pos - b.pos })

            let filteredUsers = filteredComposedData.reduce((acc, data) => {
                if (data.user && data.user.active === false) acc.push(data.user)
                return acc
            }, new Array<User>())
            let data = usersCreatedAccountData(primaryProperty, reportConfig, filteredUsers, scores, sortedModules, subModules)
            saveAs(data, 'Relatório ' + filterName + fileExtension);

        }

    }

    const eraseUserScoreInModule = async (moduleId: string, userId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminService = new AdminService()
            let userCompanyScore = await adminService.deleteUserScoreInModule(token, config.endpoint, moduleId, userId)
            let scoresUpdated = state.scores.filter(score => score.userId !== userId)
            if (userCompanyScore) scoresUpdated.push(userCompanyScore)
            setState({ ...state, scores: scoresUpdated, isLoading: false, selectedUserToDeleteScoreInModule: undefined })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                eraseUserScoreInModule(moduleId, userId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const downloadCertificate = async (moduleId: string, userId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let response = await fetch(`${config.endpoint}/admin/content/certificate/${moduleId}/${userId}`, {
                method: "GET",
                headers: { 'Authorization': `Token ${token}` }
            })
            let buffer = await response.arrayBuffer()
            const url = window.URL.createObjectURL(new Blob([buffer])); const link = document.createElement("a");
            link.href = url; link.setAttribute("download", "certificado.png");
            document.body.appendChild(link); link.click();
            setState({ ...state, isLoading: false, error: undefined })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) downloadCertificate(moduleId, userId)
            else setState({ ...state, isLoading: false, error: error.toString() })
        }

    }

    const renderHeader = (company: Company) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Relatório de Usuários</b></div>
                    <img alt='foto da empresa' style={{ height: 60, marginBottom: 10, marginTop: 10 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderUserDataWithButton = (username: string, accessValue: string, userId: string, team: string | undefined = undefined) => {
        return (
            <div className="d-flex">
                <div className="d-flex flex-column" style={{ marginTop: 2, flex: 1 }}>
                    <div style={{ fontSize: 12, marginLeft: 5, marginTop: 5, color: '#1d3256' }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faUser} />{accessValue}</div>
                    <div style={{ fontSize: 18, color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', margin: 5 }}>{username}</div>
                    {team && <div style={{ fontSize: 12, marginLeft: 5, marginBottom: 5, color: '#1d3256' }}>{team}</div>}
                </div>
                <div className="d-flex align-items-center" style={{ marginTop: 2 }}>
                    <div onClick={() => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.ACTIVITIES}/${userId}`)} style={{ fontSize: 12, marginLeft: 5, marginTop: 5, color: '#1d3256' }}><FontAwesomeIcon size='2x' style={{ marginRight: 10 }} color='#1d3256' icon={faClipboardList} /></div>
                </div>
            </div>

        )
    }

    const renderDeleteUserScoreInModuleModal = (module: Module, user: User) => {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => setState({ ...state, selectedUserToDeleteScoreInModule: undefined })}>
                    <ModalHeader toggle={() => setState({ ...state, selectedUserToDeleteScoreInModule: undefined })}>Zerar Pontuação na Missão</ModalHeader>
                    <ModalBody>
                        <div style={{ color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', margin: 5 }}>Tem certeza que deseja deletar a pontuação de <b>{user.username}</b> na Missão <b>{module.title}</b>?</div>
                        <div style={{ color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', margin: 5 }}>Essa ação não poderá ser desfeita.</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => eraseUserScoreInModule(module.id, user.id)}>Zerar</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    const renderInfo = (info: string) => {
        return (<div className="d-flex align-items-center">
            <div style={{ fontSize: 14, color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 5 }}>{info}</div>
        </div>)
    }

    const renderList = (composedData: ComposedData[], lastTimeUsersPlayed: Array<any>, scores: Array<UserCompanyScore>, modules: Array<Module>, subModules: Array<SubModule>, searchInput: string, authDataSelectedValues: { [id: string]: string }, userFilter: UserFilter, selectedModule: Module | undefined) => {

        let searchInputWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(searchInput)
        let filteredComposedData = getFilteredComposedData(composedData, searchInputWithoutPunctuation, authDataSelectedValues)

        if (userFilter === UserFilter.AllUsers) {

            let sortedFilteredComposedData = filteredComposedData.sort((a, b) => {
                let nameA = a.user?.username ?? a.userAccess?.name ?? ''
                let nameB = b.user?.username ?? b.userAccess?.name ?? ''
                if (nameA.toLowerCase().trim() > nameB.toLowerCase().trim()) return 1
                else if (nameA.toLowerCase().trim() < nameB.toLowerCase().trim()) return -1
                else return 0
            })

            return renderAllUsers(sortedFilteredComposedData)


        } else if (userFilter === UserFilter.UsersCreatedAccount) {

            let sortedModules = modules.sort((a, b) => { return a.pos - b.pos })

            let filteredUsers = filteredComposedData.reduce((acc, data) => {
                if (data.user) acc.push(data.user)
                return acc
            }, new Array<User>()).sort((a, b) => {
                if (a.username.toLowerCase() > b.username.toLowerCase()) return 1
                else if (a.username.toLowerCase() < b.username.toLowerCase()) return -1
                else return 0
            })

            return renderUsersCreatedAccount(filteredUsers, lastTimeUsersPlayed, scores, sortedModules, subModules)

        } else if (userFilter === UserFilter.UsersDidntCreatedAccount) {

            let filteredUserAccess = filteredComposedData.reduce((acc, data) => {
                if (!data.user && data.userAccess) acc.push(data.userAccess)
                return acc
            }, new Array<UserAccessValidation>())

            return renderUsersDidntCreatedAccount(filteredUserAccess)

        } else if (userFilter === UserFilter.UsersPlayedModule) {

            let filteredUsers = filteredComposedData.reduce((acc, data) => {
                if (data.user) acc.push(data.user)
                return acc
            }, new Array<User>())

            let usersPlayedModule = getUsersPlayedModule(filteredUsers, selectedModule, scores, subModules)

            if (selectedModule) {
                return renderUsersCreatedAccount(usersPlayedModule, lastTimeUsersPlayed, scores, [selectedModule], subModules.filter(sub => sub.moduleId === selectedModule.id))
            } else {
                let sortedModules = modules.sort((a, b) => { return a.pos - b.pos })
                return renderUsersCreatedAccount(usersPlayedModule, lastTimeUsersPlayed, scores, sortedModules, subModules)
            }

        } else if (userFilter === UserFilter.UsersDidntPlayedModule) {

            let filteredUsers = filteredComposedData.reduce((acc, data) => {
                if (data.user) acc.push(data.user)
                return acc
            }, new Array<User>())

            let usersDidntPlayedModule = getUsersDidntPlayedModule(filteredUsers, selectedModule, scores, subModules).sort((a, b) => {
                if (a.username.toLowerCase() > b.username.toLowerCase()) return 1
                else if (a.username.toLowerCase() < b.username.toLowerCase()) return -1
                else return 0
            })

            return renderUsersCreatedAccount(usersDidntPlayedModule, lastTimeUsersPlayed, scores, modules, subModules)
        } else if (userFilter === UserFilter.BlockedUsers) {

            let sortedModules = modules.sort((a, b) => { return a.pos - b.pos })

            let filteredUsers = filteredComposedData.reduce((acc, data) => {
                if (data.user && data.user.active === false) acc.push(data.user)
                return acc
            }, new Array<User>()).sort((a, b) => {
                if (a.username.toLowerCase() > b.username.toLowerCase()) return 1
                else if (a.username.toLowerCase() < b.username.toLowerCase()) return -1
                else return 0
            })

            return renderUsersCreatedAccount(filteredUsers, lastTimeUsersPlayed, scores, sortedModules, subModules)

        }
    }

    const renderAllUsers = (composedData: ComposedData[]) => {

        let renderItem = (index: number, key: number | string) => {
            let data = composedData[index]

            if (data.user) {
                return (<div key={key} className="d-flex" style={{ marginTop: 10, marginBottom: 15, borderRadius: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                    <div className="d-flex flex-column" style={{ flex: 1, padding: 10 }}>
                        <UserDataComponent user={data.user} editUserProfile={(userId) => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.PROFILE}/${userId}`)} />
                    </div>
                </div>)
            } else if (data.userAccess) {
                return (<div key={key} className="d-flex" style={{ marginTop: 10, marginBottom: 15, borderRadius: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', padding: 10 }}>
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <UserAccessComponent userAccess={data.userAccess} />
                    </div>
                </div>)
            } else return <div />
        }

        return <Row style={{ overflow: 'auto' }}>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <div className="d-flex flex-column" style={{ flex: 1 }}>
                    <div style={{ fontSize: 16, color: 'black', verticalAlign: 'middle', margin: 5 }}>{`Total: ${composedData.length}`}</div>
                </div>
            </Col>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <ReactList itemRenderer={renderItem} length={composedData.length} type='variable' />
            </Col>
        </Row>
    }

    const renderUsersCreatedAccount = (usersAlphabetSort: User[], lastTimeUsersPlayed: Array<any>, scores: Array<UserCompanyScore>, modules: Array<Module>, subModules: Array<SubModule>) => {

        let renderItem = (index: number, key: number | string) => {
            let user = usersAlphabetSort[index]
            let userScore = _.first(scores.filter(score => score.userId === user.id))
            let lastTimeUserPlayed = _.first(lastTimeUsersPlayed.filter(data => data.userId === user.id))
            return (<div key={key} className="d-flex" style={{ marginTop: 10, marginBottom: 15, borderRadius: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                <div className="d-flex flex-column" style={{ flex: 1, padding: 10 }}>
                    <UserDataComponent user={user} editUserProfile={(userId) => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.PROFILE}/${userId}`)} />
                    {userScore && <UserGameComponent userScore={userScore} lastTimeUserPlayed={lastTimeUserPlayed} modules={modules} subModules={subModules} downloadCertificate={downloadCertificate} deleteUserScoreInModule={(module: Module) => setState({ ...state, selectedUserToDeleteScoreInModule: [module, user] })} />}
                </div>
            </div>)
        }

        return <Row style={{ overflow: 'auto' }}>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <div className="d-flex flex-column" style={{ flex: 1 }}>
                    <div style={{ fontSize: 16, color: 'black', verticalAlign: 'middle', margin: 5 }}>{`Total: ${usersAlphabetSort.length}`}</div>
                </div>
            </Col>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <ReactList itemRenderer={renderItem} length={usersAlphabetSort.length} type='simple' />
            </Col>
        </Row>
    }

    const renderUsersDidntCreatedAccount = (userAccessWithoutAccount: UserAccessValidation[]) => {

        let renderItem = (index: number, key: number | string) => {
            let userAccess = userAccessWithoutAccount[index]
            return (<div key={key} className="d-flex" style={{ marginTop: 10, marginBottom: 15, borderRadius: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', padding: 10 }}>
                <div className="d-flex flex-column" style={{ flex: 1 }}>
                    <UserAccessComponent userAccess={userAccess} />
                </div>
            </div>)
        }

        return <Row style={{ overflow: 'auto' }}>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <div className="d-flex flex-column" style={{ flex: 1 }}>
                    <div style={{ fontSize: 16, color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', margin: 5 }}>{`Total: ${userAccessWithoutAccount.length}`}</div>
                </div>
            </Col>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <ReactList itemRenderer={renderItem} length={userAccessWithoutAccount.length} type='simple' />
            </Col>
        </Row>
    }

    let { error, isLoading, scores, composedData, lastTimeUsersPlayed, modules, subModules, searchInput, authDataSelectedValues,
        userFilter, selectedModule, selectedUserToDeleteScoreInModule, reportConfig, signUpConfig } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', overflow: 'none', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && <Row><Col md={{ size: 6, offset: 3 }}><Alert color="danger">{error}</Alert></Col></Row>}
        {company && renderHeader(company)}
        {selectedUserToDeleteScoreInModule && renderDeleteUserScoreInModuleModal(selectedUserToDeleteScoreInModule[0], selectedUserToDeleteScoreInModule[1])}
        {signUpConfig && <SearchInputView authData={signUpConfig.authData} authDataSelectedValues={authDataSelectedValues} updateSearchInput={(searchInput: string) => setState({ ...state, searchInput })} updateAuthDataSelection={(selection: string | undefined, id: string) => updateAuthDataSelection(selection, id)} />}
        {signUpConfig && <UserFiltersComponent primaryProperty={signUpConfig.primaryProperty} reportConfig={reportConfig} userFilter={userFilter} composedData={composedData} scores={scores} modules={modules} subModules={subModules} searchInput={searchInput} authDataSelectedValues={authDataSelectedValues} selectedModule={selectedModule} actions={{ downloadReport, selectModule, changeFilter }} />}
        {signUpConfig && renderList(composedData, lastTimeUsersPlayed, scores, modules, subModules, searchInput, authDataSelectedValues, userFilter, selectedModule)}
    </Container>)

}

export default UsageControlScreen