import React, { Component, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button, Alert, Form, FormGroup, Input, Label } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import User from '../../../models/User';
import LoadingScreen from '../../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import AccountCircleSvg from '../../../images/account_circle-24px.svg';
import TokenHelper from '../../../auth/TokenHelper';
import AdminUsersService from '../../../services/admin/adminUsersService';
import config from '../../../config'
import FileHelper from '../../../files/FileHelper'
import { AuthData, EmailConfig, PhoneConfig, SignUpConfig } from '../../../models/CompanyConfig';
import EnterService from '../../../services/enterService';
import { useUser } from '../../../hooks/useUser';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

interface State {
    error: string | undefined;
    isLoading: boolean;
    user: User | undefined;
    signUpConfig: SignUpConfig | undefined;
    username: string;
    email: string;
    phone: string;
    active: boolean;
    authDataSelectedValues: { [id: string]: string };
    isAuthDataDropdownOpen: { [id: string]: boolean };
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    user: undefined,
    signUpConfig: undefined,
    username: '',
    email: '',
    phone: '',
    active: true,
    isAuthDataDropdownOpen: {},
    authDataSelectedValues: {},
};

const fileHelper = new FileHelper()

const ProfileScreen = () => {

    const uploadButton = useRef<HTMLInputElement>(null)
    const [state, setState] = useState(INITIAL_STATE)
    const navigate = useNavigate()
    const { company, noCompany } = useUser()
    const { height } = useWindowDimensions()
    const { userId } = useParams()

    useEffect(() => {
        if (company && userId) loadUser(company.id, userId)
        if (noCompany) setState({ ...state, error: 'Ops, link invalido. Você precisa fazer login primeiro.' })
    }, [company, noCompany, userId])

    useEffect(() => {
        if (state.user) loadCompanySignUpConfig(company!.id)
    }, [state.user])

    const loadUser = async (companyId: string, userId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminUsersService = new AdminUsersService()
            let user = await adminUsersService.getUser(token, config.endpoint, userId)
            let phone = user!.phone || ''
            let email = user!.email || ''
            let authDataSelectedValues = user!.authData || {}
            let active = user.active
            setState({ ...state, user, username: user.username, phone, email, active, authDataSelectedValues, error: undefined, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadUser(companyId, userId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const loadCompanySignUpConfig = async (companyId: string) => {
        try {
            setState({ ...state, isLoading: true, error: undefined })
            let enterService = new EnterService()
            let { signUpConfig } = await enterService.getCompanySignUpConfig(config.endpoint, companyId)
            let isAuthDataDropdownOpen: { [id: string]: boolean } = {}
            if (signUpConfig.authData) {
                for (let id in signUpConfig.authData) {
                    isAuthDataDropdownOpen[id] = false
                }
            }
            setState({ ...state, isLoading: false, signUpConfig, isAuthDataDropdownOpen })
        } catch (error) {
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const updateUser = async (userId: string, email: string, phone: string, username: string, active: boolean, authData: { [id: string]: string }) => {
        let updateData: any = {}
        if (username.length > 1) updateData.username = username
        if (email.length > 1) updateData.email = email
        if (Object.keys(authData).length > 0) updateData.authData = authData

        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminUsersService = new AdminUsersService()
            let updateEmail = isEmailValid(email) ? email : undefined
            let updatePhone = phone.length > 8 ? phone : undefined
            let updateUsername = username.length > 3 ? username : undefined
            let updateAuthData = Object.keys(authData).length > 0 ? authData : undefined
            await adminUsersService.updateUser(token, config.endpoint, userId, updateEmail, updateUsername, updatePhone, active, updateAuthData)
            navigate(-1)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                updateUser(userId, email, phone, username, active, authData)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const applyPhoneBrMask = (value: string) => {
        return value.replace(/\D/g, "")           //Remove tudo o que não é dígito
            .replace(/^(\d{2})(\d)/g, "($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
            .replace(/(\d)(\d{4})$/, "$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
    }

    const updateEmail = (newValue: string) => {
        setState({ ...state, email: newValue.toLowerCase() })
    }

    const updatePhone = (phoneConfig: PhoneConfig | undefined, newValue: string) => {

        if (phoneConfig) {
            if (phoneConfig.mask === 'br') {
                let phoneWithMask = applyPhoneBrMask(newValue)
                return setState({ ...state, phone: phoneWithMask })
            }
        }

        setState({ ...state, phone: newValue })
    }

    const isEmailValid = (email: string): boolean => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const testCPF = (strCPF: string) => {
        strCPF = strCPF.replace(/\D/g, '')
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (let n = 1; n <= 10; n++) Soma = Soma + parseInt(strCPF.substring(n - 1, n)) * (12 - n);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    const dropDownAuthDataSelection = (event: any, id: string) => {
        let authDataSelectedValues = state.authDataSelectedValues
        let selection = event as string
        if (selection === 'Nenhum') {
            delete authDataSelectedValues[id]
            setState({ ...state, authDataSelectedValues })
        } else {
            authDataSelectedValues[id] = selection
            setState({ ...state, authDataSelectedValues })
        }
    }

    const toggleAuthDataDropdownOpen = (id: string) => {
        let isAuthDataDropdownOpen = state.isAuthDataDropdownOpen
        isAuthDataDropdownOpen[id] = !isAuthDataDropdownOpen[id]
        setState({ ...state, isAuthDataDropdownOpen })
    }

    const renderUsernameInput = (username: string) => {
        return (<FormGroup>
            <Label for="usernameTitle">Nome</Label>
            <Input name="title" id="usernameTitle" placeholder={'Por favor, digite seu nome'} value={username} onChange={(event: any) => setState({ ...state, username: event.target.value })} />
            {username.length < 3 && <div style={{ fontFamily: 'Montserrat', fontSize: 'small', color: 'red', marginTop: 2 }}><i>O nome precisa de ao menos 3 caracteres</i></div>}
        </FormGroup>)
    }

    const renderEmailInput = (email: string, emailConfig: EmailConfig) => {
        // se o cara não digitou email nem precisa mostrar o erro
        let isEmailValidVar = email.length > 0 ? isEmailValid(email) : true
        return (<FormGroup>
            <Label style={{ fontFamily: 'Montserrat' }} for="docTitle">{emailConfig.title}</Label>
            <Input style={{ fontFamily: 'Montserrat' }} name="title" id="docTitle" value={email} onChange={(event: any) => updateEmail(event.target.value)} />
            {!isEmailValidVar && <div style={{ fontFamily: 'Montserrat', fontSize: 'small', color: 'red', marginTop: 2 }}><i>Por favor, digite um e-mail válido.</i></div>}
        </FormGroup>)
    }

    const renderPhoneInput = (phone: string, phoneConfig: PhoneConfig) => {
        // se o cara não digitou telefone nem precisa mostrar o erro
        let isPhoneValid = phone.length > 0 ? phone.length > 8 : true
        return (<FormGroup>
            <Label style={{ fontFamily: 'Montserrat' }} for="phoneTitle">{phoneConfig ? phoneConfig.title : 'Telefone'}</Label>
            <Input style={{ fontFamily: 'Montserrat' }} name="title" id="phoneTitle" placeholder={phoneConfig ? phoneConfig.placeholder : 'Digite o telefone (opcional)'} value={state.phone} onChange={(event: any) => updatePhone(phoneConfig, event.target.value)} />
            {!isPhoneValid && <div style={{ fontFamily: 'Montserrat', fontSize: 'small', color: 'red', marginTop: 2 }}><i>Por favor, digite um telefone válido com código de área e número completo.</i></div>}
        </FormGroup>)
    }

    const renderAuthDataSelection = (authData: { [id: string]: AuthData }, authDataSelectedValues: { [id: string]: string }) => {
        let authDataKeys = Object.keys(authData)
        return (<div style={{ marginBottom: 5 }}>
            {authDataKeys.filter(key => !authData[key].show).map((id, index) => {
                let currentAuthData = authData[id]
                let options = currentAuthData.values.map(val => { return { label: val, value: val } })
                if (Array.isArray(currentAuthData.values) && currentAuthData.values.length > 0) {
                    let currentValue = authDataSelectedValues[id] ? { label: authDataSelectedValues[id], value: authDataSelectedValues[id] } : undefined
                    return <FormGroup key={index} style={{ marginBottom: 10 }}>
                        <Label>{currentAuthData.title}</Label>
                        <Select isClearable placeholder={currentAuthData.placeholder} value={currentValue} options={options} onChange={opt => dropDownAuthDataSelection(opt ? opt!.label : undefined, id)} />
                    </FormGroup>
                } else {
                    let selectedAuthDataValue = authDataSelectedValues[id] || 'Nenhum'
                    return (
                        <FormGroup key={index}>
                            <Label>{currentAuthData.title}</Label>
                            <Input style={{ fontFamily: 'Montserrat' }} name={currentAuthData.title} id={id} value={selectedAuthDataValue} placeholder={currentAuthData.placeholder} onChange={(event: any) => dropDownAuthDataSelection(event.target.value as string, id)} />
                        </FormGroup>
                    )
                }
            })}
        </div>)
    }

    const isInputInvalid = (signUpConfig: SignUpConfig, email: string, phone: string, username: string) => {
        if (username.length < 3) return true

        if (signUpConfig.phone && signUpConfig.phone.required) {
            return phone.length <= 8
        } else if (phone.length > 0) {
            return phone.length <= 8
        }

        if (signUpConfig.email && signUpConfig.email.required) {
            return !isEmailValid(email)
        } else if (email.length > 0) {
            return !isEmailValid(email)
        }
    }

    const renderError = (error: string) => {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>
        );
    }

    const renderUserPic = (mainPicUrl: string | undefined) => {
        return (<div className="d-flex justify-content-center">
            <img alt='company pic' style={{ objectFit: 'cover', width: 150, height: 150, marginBottom: 5, marginTop: 10, borderRadius: '50%', borderStyle: 'solid' }} src={mainPicUrl || AccountCircleSvg} />
        </div>)
    }

    const renderEditForm = (signUpConfig: SignUpConfig, user: User, email: string, phone: string, username: string, active: boolean, authDataSelectedValues: { [id: string]: string }) => {
        return <Row style={{ overflow: 'auto' }}>
            <Col className="d-flex flex-column justify-content-center relative mb-2" md={{ size: 6, offset: 3 }}>
                {renderUserPic(user.pic)}
                <Form style={{ marginTop: 10 }}>
                    {renderUsernameInput(username)}
                    {signUpConfig.email && renderEmailInput(email, signUpConfig.email)}
                    {signUpConfig.phone && renderPhoneInput(phone, signUpConfig.phone)}
                    {signUpConfig.authData && renderAuthDataSelection(signUpConfig.authData, authDataSelectedValues)}
                    <FormGroup className='mb-2' switch>
                        <Input checked={active} type="switch" role="switch" onClick={() => setState({ ...state, active: !active })} />
                        <Label check>{active ? <span style={{ color: '#0d6efd' }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCheckCircle} /><b>Usuário Ativo</b></span> : <span style={{ color: '#979393' }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faBan} /><b>Usuário Bloqueado</b></span>}</Label>
                    </FormGroup>
                </Form>
                <Button color='primary' block disabled={isInputInvalid(signUpConfig, email, phone, username)} onClick={() => { updateUser(user.id, email, phone, username, active, authDataSelectedValues) }}>Salvar</Button>
            </Col>
        </Row>
    }

    const { user, error, isLoading, signUpConfig, username, phone, email, active, authDataSelectedValues } = state;

    if (isLoading || !company) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column justify-content-center" fluid style={{ overflow: 'hidden', height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        <Row style={{ background: '#FFFFFFDA', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 16, marginLeft: 10 }}><b>Perfil</b></div>
                </div>
            </Col>
        </Row>
        {error && renderError(error)}
        {user && signUpConfig && renderEditForm(signUpConfig, user, email, phone, username, active, authDataSelectedValues)}
    </Container>)

}

export default ProfileScreen
