import React, { Component } from 'react';
import TokenHelper from '../../../auth/TokenHelper';
import Company from '../../../models/Company';
import { SubModule, SubModuleQuiz, SubModuleTypes, SubModuleVideo } from '../../../models/SubModule';
import AdminContentService from '../../../services/admin/adminContentService';
import config from '../../../config';
import { Alert, Button, Col, Container, Form, FormGroup, FormText, Input, Label, Row, Spinner } from 'reactstrap';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTimes } from '@fortawesome/free-solid-svg-icons';
import PDFPreview from '../../../components/pdf/PDFPreview';
import SelectFileComponent from '../../../components/SelectFileComponent';

interface Props {
    company: Company
    subModule: SubModule
    exit: () => void
    onUpdate: (subModule: SubModule, subModuleVideo?: SubModuleVideo) => void
}

interface State {
    error: any | undefined;
    isLoading: boolean;
    title: string;
    mistakesAllowed: boolean;
    subModuleVideo?: SubModuleVideo
    selectedFile?: string
    selectedVideoFile?: File;
    selectedVideoFileUrl?: string;
    linkInput: string;
    pos: number,
    showFileSelectComponent: boolean,
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    title: '',
    mistakesAllowed: false,
    linkInput: '',
    pos: 1,
    showFileSelectComponent: false,
};

export default class EditSubModuleComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = { ...INITIAL_STATE }

        let subModule = props.subModule
        if (subModule.type === SubModuleTypes.QUIZ) {
            let subModuleQuiz = subModule as SubModuleQuiz
            this.state = { ...INITIAL_STATE, title: subModule.title, mistakesAllowed: subModuleQuiz.mistakesAllowed, pos: subModule.pos }
        } else this.state = { ...INITIAL_STATE, title: subModule.title, pos: subModule.pos, selectedFile: (subModule.type === SubModuleTypes.FILE && subModule.externalResourceUrl) || undefined, linkInput: (SubModuleTypes.LINK && subModule.externalResourceUrl) || '' }
    }

    componentDidMount() {

        if (this.props.subModule.type === SubModuleTypes.VIDEO) {
            this.loadSubModuleVideo()
        }

    }

    async loadSubModuleVideo() {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let subModuleVideo = await adminContentService.getSubModuleVideo(token, config.endpoint, this.props.subModule.id)
            this.setState({ isLoading: false, error: undefined, subModuleVideo })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadSubModuleVideo()
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    getSignedUrlForUpload = async (tokenHelper: TokenHelper, adminContentService: AdminContentService, subModule: SubModule, selectedFile: File) => {
        try {
            let token = tokenHelper.getToken()
            if(!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let signedUrl = await adminContentService.getSignedUrlForUpload(token, config.endpoint, subModule.moduleId, subModule.id, selectedFile.type)
            await adminContentService.uploadVideoFromSignedUrl(signedUrl, selectedFile)
            this.saveSubModuleVideo(tokenHelper, adminContentService, subModule, selectedFile)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.getSignedUrlForUpload(tokenHelper, adminContentService, subModule, selectedFile)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    saveSubModuleVideo = async (tokenHelper: TokenHelper, adminContentService: AdminContentService, subModule: SubModule, selectedFile: File) => {
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let subModuleVideo = await adminContentService.saveSubModuleVideo(token, config.endpoint, subModule.moduleId, subModule.id, selectedFile)
            this.props.onUpdate(subModule, subModuleVideo)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.uploadVideo(subModule, selectedFile)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    uploadVideo = async (subModule: SubModule, selectedFile: File) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        let adminContentService = new AdminContentService()
        this.getSignedUrlForUpload(tokenHelper, adminContentService, subModule, selectedFile)
    }

    async updateSubModule(subModuleId: string, title: string, pos: number, mistakesAllowed: boolean, linkInput: string, type: string) {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let externalResourceUrl : string | undefined = undefined
            if(type === SubModuleTypes.LINK) {
                externalResourceUrl = (this.state.linkInput.length > 0 && this.state.linkInput) || undefined
            } else if(type === SubModuleTypes.FILE) {
                externalResourceUrl = this.state.selectedFile
            }
            let adminContentService = new AdminContentService()
            await adminContentService.editSubModule(token, config.endpoint, subModuleId, title, pos, type === SubModuleTypes.QUIZ ? mistakesAllowed : undefined, externalResourceUrl)

            let subModule = Object.assign({}, this.props.subModule)
            if (subModule.type === SubModuleTypes.QUIZ) {
                let subModuleQuiz = subModule as SubModuleQuiz
                subModuleQuiz.title = title
                subModuleQuiz.pos = pos
                subModuleQuiz.mistakesAllowed = mistakesAllowed
                this.props.onUpdate(subModuleQuiz)
            } else {
                subModule.title = title
                subModule.pos = pos
                if (type === SubModuleTypes.VIDEO && this.state.selectedVideoFile) {
                    this.uploadVideo(subModule, this.state.selectedVideoFile)
                } else {
                    if (type === SubModuleTypes.LINK && this.state.linkInput.length > 3) {
                        subModule.externalResourceUrl = this.state.linkInput
                    } else if (type === SubModuleTypes.FILE && this.state.selectedFile) {
                        subModule.externalResourceUrl = this.state.selectedFile
                    }
                    this.props.onUpdate(subModule)
                }
            }

        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.updateSubModule(subModuleId, title, pos, mistakesAllowed, linkInput, type)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    async setFileDataUrl(selectedVideoFile: File) {
        try {
            let URL = window.URL || window.webkitURL;
            let selectedVideoFileUrl = URL.createObjectURL(selectedVideoFile);
            this.setState({ selectedVideoFile, selectedVideoFileUrl })
        } catch (error) {
            this.setState({ error: error.toString() })
        }
    }

    renderVideoUploadOrPreview() {
        let subModuleVideo = this.state.subModuleVideo
        let videoUrl = this.state.selectedVideoFileUrl || (subModuleVideo ? subModuleVideo.videoUrl : undefined)
        var width = '100%', height = 'auto'
        return <div className="d-flex flex-column">
            <FormGroup>
                <Label for="video">Upload de Video</Label>
                <Input type="file" name="file" id="video" accept="video/mp4,video/x-m4v,video/*" onChange={(event) => event.target.files && this.setFileDataUrl(event.target.files[0])} />
                <FormText color="muted">
                    Carregar um video do seu dispositivo.
                </FormText>
            </FormGroup>
            {videoUrl && <ReactPlayer width={width} height={height} url={videoUrl} controls config={{
                file: {
                    attributes: {
                        controlsList: 'nodownload'
                    }
                }
            }} />}
            <Form>
            </Form>
        </div>
    }

    renderMistakesAllowedInput(mistakesAllowed: boolean) {
        return <FormGroup check>
            <Input type="checkbox" checked={mistakesAllowed} onChange={e => this.setState({ mistakesAllowed: e.target.checked })} />
            <Label check>Continuar quiz depois de errar</Label>
            <FormText>
                O quiz finaliza quando o usuário erra uma questão. Caso você queira permitir o usuário responder todas as questões do quiz mesmo se errar alguma, deixe essa opção ativada.
            </FormText>
        </FormGroup>
    }

    renderLinkInput(linkInput: string) {
        return <FormGroup>
            <Label for="url">Link</Label>
            <Input name="url" id="url" value={linkInput} placeholder="Coloque aqui o link" onChange={(event: any) => this.setState({ linkInput: event.target.value })} />
        </FormGroup>
    }

    renderError(error: string) {
        return <Alert color="danger">{error}</Alert>
    }

    renderFileUploadOrPreview(selectedFileName: string | undefined) {
        return <div className="d-flex flex-column align-items-center justify-content-center">
            {selectedFileName && <PDFPreview width={250} height={250} filename={selectedFileName} />}
            <Button className="mt-2" onClick={() => this.setState({ showFileSelectComponent: true })}><FontAwesomeIcon icon={faFile} /> {selectedFileName ? 'Trocar Arquivo' : 'Selecionar Arquivo'}</Button>
        </div>

    }

    render() {
        let { error, isLoading, title, mistakesAllowed, linkInput, showFileSelectComponent, pos } = this.state

        if (isLoading) {
            return (<Container className="d-flex flex-column justify-content-center" fluid>
                <Row>
                    <Col md={{ size: 6, offset: 3 }}>
                        <div className="d-flex justify-content-center">
                            <Spinner style={{ width: '10rem', height: '10rem', color: 'black', margin: 20 }} />{' '}
                        </div>
                    </Col>
                </Row>
            </Container>)
        }

        if (showFileSelectComponent) {
            return <Container className="d-flex flex-column" fluid>
                <Row>
                    <Col>
                        <SelectFileComponent fileSelected={(filename: string) => {
                            this.setState({ showFileSelectComponent: false, selectedFile: filename })
                        } } goBack={() => {
                            this.setState({ showFileSelectComponent: false })
                        } } />
                    </Col>
                </Row>
            </Container>
        }

        return (<Container className="d-flex flex-column" fluid>
            {error && this.renderError(error)}
            <Row>
                <Col md={{ size: 12 }}><div className='d-flex justify-content-end'><FontAwesomeIcon className='cursorPointer' onClick={() => this.props.exit()} icon={faTimes} /></div></Col>
                <Col>
                    <div className="d-flex flex-column justify-content-center relative">
                        {error && this.renderError(error)}
                        <Form className="mb-2">
                            <FormGroup>
                                <Label for="moduleTitle">Título</Label>
                                <Input name="title" id="moduleTitle" placeholder="Nome da fase" value={title} onChange={(event: any) => this.setState({ title: event.target.value })} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="position">Posição</Label>
                                <Input type="select" value={pos} name="selectMulti" id="position" onChange={(event: any) => this.setState({ pos: +event.target.value })}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                </Input>
                                <FormText>As fases aparecem para o usuário em ordem da menor para a maior posição.</FormText>
                            </FormGroup>
                            {this.props.subModule.type === SubModuleTypes.QUIZ && this.renderMistakesAllowedInput(mistakesAllowed)}
                            {this.props.subModule.type === SubModuleTypes.LINK && this.renderLinkInput(this.state.linkInput)}
                            {this.props.subModule.type === SubModuleTypes.FILE && this.renderFileUploadOrPreview(this.state.selectedFile)}
                        </Form>
                        {this.props.subModule.type === SubModuleTypes.VIDEO && this.renderVideoUploadOrPreview()}
                        <Button block disabled={title.length < 3} color="primary" onClick={() => this.updateSubModule(this.props.subModule.id, title, this.state.pos, mistakesAllowed, linkInput, this.props.subModule.type)}>Salvar</Button>
                    </div>
                </Col>
            </Row>
        </Container>)
    }

}