import User from './User'

export class RankingEntry {

    user: User
    score: number
    lastUpdate: number

    constructor(user: User, score: number, lastUpdate: number | undefined = undefined) {
        this.user = user
        this.score = score
        this.lastUpdate = lastUpdate || 0
    }

    getData(): any {
        return {
            user: this.user.getData(),
            score: this.score,
            lastUpdate: this.lastUpdate,
        }
    }

}

export const RankingTypes = {
    default: 'default',
    custom: 'custom',
    segmented: 'segmented'
}