import axios from 'axios'
import { DeckCardReport } from '../models/DeckCardsReport';
import DeckCard from '../models/DeckCards';
import { UserMaxScoreInSubModule } from '../models/Scores';
import { SubModuleFinishedReport, createReportFromData } from '../models/SubModuleFinishedReport';
import { SubModule } from '../models/SubModule';
import { QuizQuestionsReport } from '../models/QuizQuestionsReport';
import QuizQuestion from '../models/QuizQuestion';
import Module from '../models/Module';

export default class ScoreService {
    async saveUserDeckScore(token: string, mainUrl: string, startTime: number, endTime: number, deckCardsReport: Array<DeckCardReport<DeckCard>>, subModuleId: string) {
        let url = `${mainUrl}/mobile/score/deckScore`
        let result = await axios.post(url, { startTime, endTime, subModuleId, deckCardsReport }, { headers: { 'Authorization': `Token ${token}` } })
        return new SaveUserDeckScoreRes(result.data.data)
    }

    async saveUserQuizScore(token: string, mainUrl: string, startTime: number, endTime: number, quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>, subModuleId: string) {
        let url = `${mainUrl}/mobile/score/quizScore`
        let result = await axios.post(url, { startTime, endTime, subModuleId, quizQuestionsReport }, { headers: { 'Authorization': `Token ${token}` } })
        return new SaveUserQuizScoreRes(result.data.data)
    }

    async saveUserSubModuleVideoScore(token: string, mainUrl: string, startTime: number, endTime: number, subModuleId: string) {
        let url = `${mainUrl}/mobile/score/videoScore`
        await axios.post(url, { startTime, endTime, subModuleId }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async getUserLastScore(token: string, mainUrl: string, subModuleId: string, subModuleType: string) {
        let url = `${mainUrl}/mobile/score/lastScore`
        let result = await axios.post(url, { subModuleId, subModuleType }, { headers: { 'Authorization': `Token ${token}` } })
        return new GetUserLastScoreRes(result.data.data)
    }
}

export class SaveUserDeckScoreRes {
    userMaxScoreInSubModule: UserMaxScoreInSubModule
    constructor(data: any) {
        this.userMaxScoreInSubModule = new UserMaxScoreInSubModule(data.userMaxScoreInSubModule)
    }
}

export class SaveUserQuizScoreRes {
    userMaxScoreInSubModule: UserMaxScoreInSubModule
    constructor(data: any) {
        this.userMaxScoreInSubModule = new UserMaxScoreInSubModule(data.userMaxScoreInSubModule)
    }
}

export class GetUserLastScoreRes {
    lastReport?: SubModuleFinishedReport
    userMaxScore?: UserMaxScoreInSubModule
    nextSubModule?: SubModule
    subModule: SubModule
    module: Module
    constructor(data: any) {
        if(data.lastReport) this.lastReport = createReportFromData(data.lastReport)
        if(data.userMaxScore) this.userMaxScore = new UserMaxScoreInSubModule(data.userMaxScore)
        if(data.nextSubModule) this.nextSubModule = new SubModule(data.nextSubModule)
        this.subModule = new SubModule(data.subModule)
        this.module = new Module(data.module)
    }
}