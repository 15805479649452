import chroma from "chroma-js";

const firstBlockWidth = 90
const secondBlockWidth = 100
const thirdBlockWidth = 110
const fourthBlockWidth = 120

const isBright = (color: string) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
    let rgb = result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [255, 255, 255]
    let brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);
    return brightness > 150
}

export const drawStartTransition = (ctx: CanvasRenderingContext2D, transitionProgress: number, transitionWidth: number, transitionColor: string, height: number, width: number) => {
    let chromaColor = chroma(transitionColor);
    let isTransitionColorBright = isBright(transitionColor)
    ctx.fillStyle = isTransitionColorBright ? chromaColor.darken(2).hex() : chromaColor.brighten(2).hex()
    ctx.fillRect(transitionWidth, 0, width, height)
}

export const drawEndTransition = (ctx: CanvasRenderingContext2D, transitionProgress: number, transitionWidth: number, transitionColor: string, height: number) => {
    let chromaColor = chroma(transitionColor);
    let colorsList = []
    let isTransitionColorBright = isBright(transitionColor)
    for (var i = 0; i < 5; i++) {
        const color = isTransitionColorBright ? chromaColor.darken(i * 0.5) : chromaColor.brighten(i * 0.5);
        colorsList.push(color.hex())
    }
    let animationBlockThreshold = 1 - transitionProgress

    ctx.fillStyle = colorsList[0];
    ctx.fillRect(transitionWidth - (firstBlockWidth * animationBlockThreshold), 0, firstBlockWidth, height);

    if (transitionWidth > firstBlockWidth) {
        ctx.fillStyle = colorsList[1];
        ctx.fillRect(transitionWidth - ((firstBlockWidth + secondBlockWidth) * animationBlockThreshold), 0, secondBlockWidth, height);
    }

    if (transitionWidth > ((firstBlockWidth + secondBlockWidth) * animationBlockThreshold)) {
        ctx.fillStyle = colorsList[2];
        ctx.fillRect(transitionWidth - ((firstBlockWidth + secondBlockWidth + thirdBlockWidth) * animationBlockThreshold), 0, thirdBlockWidth, height);
    }

    const fourthBlockStart = firstBlockWidth + secondBlockWidth + thirdBlockWidth
    if (transitionWidth > (fourthBlockStart * animationBlockThreshold)) {
        ctx.fillStyle = colorsList[3];
        ctx.fillRect(transitionWidth - ((fourthBlockStart + fourthBlockWidth) * animationBlockThreshold), 0, fourthBlockWidth, height);

    }

    const lastPartStart = firstBlockWidth + secondBlockWidth + thirdBlockWidth + fourthBlockWidth
    ctx.fillStyle = colorsList[4];
    ctx.fillRect(0, 0, transitionWidth - (lastPartStart * animationBlockThreshold), height)
}