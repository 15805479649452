import React, { Component, CSSProperties } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { QuestionAnswer } from '../../models/Question'

interface AnswerComponentProps {
    answerText: string,
    img: string | undefined,
    selectedAnswer: number,
    answerNumber: number,
    questionAnswer: QuestionAnswer,
    correctAnswer: number,
    screenHeight: string,
    onAnswerSelected: (answerNumber: number) => void
    removeBorder?: boolean
}

export default class AnswerComponent extends Component<AnswerComponentProps> {

    renderDiv(style: CSSProperties, icon: IconDefinition | undefined = undefined, screenHeight: string, img: string | undefined) {
        var paddingTop = 5, paddingBottom = 5, paddingLeft = 2, paddingRight = 2, marginTop = 5, marginBottom = 5
        var imageSize = '2.5em'
        if(screenHeight === 'medium') {
            imageSize = '3em'
            paddingTop = 15; paddingBottom = 15; paddingLeft = 5; paddingRight = 5; marginTop = 10; marginBottom = 10
        } else if(screenHeight === 'big') {
            imageSize = '4em'
            paddingTop = 20; paddingBottom = 20; paddingLeft = 20; paddingRight = 20; marginTop = 15; marginBottom = 15
        }
        return (
            <div onClick={() => { this.props.onAnswerSelected(this.props.answerNumber) }} style={{...style, paddingTop, paddingBottom, paddingLeft, paddingRight, marginTop, marginBottom}}>
                {img && <img alt='imagem da resposta' src={img} style={{ objectFit: 'contain', width: imageSize, height: imageSize, marginRight: 5 }} />}
                {this.props.answerText} {this.renderIcon(icon)}
            </div>
        )
    }

    renderIcon(icon: IconDefinition | undefined = undefined) {
        if (icon) return (<FontAwesomeIcon style={{ marginLeft: 5 }} icon={icon} size='2x' />)
    }

    render() {
        if (this.props.selectedAnswer === this.props.answerNumber) {
            if (this.props.questionAnswer === QuestionAnswer.WrongAnswer) {
                return this.renderDiv(questionWrongAnswerStyle, faTimesCircle, this.props.screenHeight, this.props.img)
            } else {
                return this.renderDiv(questionCorrectAnswerStyle, faCheckCircle, this.props.screenHeight, this.props.img)
            }
        } else if (this.props.questionAnswer === QuestionAnswer.WrongAnswer && this.props.answerNumber === this.props.correctAnswer) {
            return this.renderDiv(questionCorrectAnswerStyle, faCheckCircle, this.props.screenHeight, this.props.img)
        } else {
            return this.renderDiv(this.props.removeBorder ? questionDefaultNoBorderStyle : questionDefaultStyle, undefined, this.props.screenHeight, this.props.img)
        }
    }
}

const questionDefaultStyle: CSSProperties = {
    textAlign: 'center',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    color: 'black', background: 'white', borderStyle: 'solid', borderWidth: 'thin', borderRadius: 5, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)'
}

const questionDefaultNoBorderStyle: CSSProperties = {
    textAlign: 'center',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    color: 'black', background: '#FFFFFFDA', borderRadius: 5, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)'
}

const questionWrongAnswerStyle: CSSProperties = {
    textAlign: 'center', borderColor: 'red', borderStyle: 'solid', borderWidth: 1, borderRadius: 5,
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    color: 'white', background: 'red', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}

const questionCorrectAnswerStyle: CSSProperties = {
    textAlign: 'center', borderColor: 'green', borderStyle: 'solid', borderWidth: 1, borderRadius: 5,
    display: 'flex', justifyContent: 'center', alignItems: 'center', 
    color: 'white', background: 'green', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}