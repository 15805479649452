/*
    var vid = new Whammy.Video();
    vid.add(canvas or data url)
    vid.compile()
*/

import { IRiff } from "./interfaces"

const doubleToString = (num: number) => {
    return [].slice.call(
        new Uint8Array(
            (
                new Float64Array([num]) //create a float64 array
            ).buffer) //extract the array buffer
        , 0) // convert the Uint8Array into a regular array
        .map(function (e) { //since it's a regular array, we can now use map
            return String.fromCharCode(e) // encode all the bytes individually
        })
        .reverse() //correct the byte endianness (assume it's little endian for now)
        .join('') // join the bytes in holy matrimony as a string
}

interface EBML_DATA_ARRAY_TYPE {
    id: number
    data: any
    size?: number
}

const EBML_DATA: EBML_DATA_ARRAY_TYPE = {
    "id": 0x1a45dfa3, // EBML
    "data": [
        {
            "data": 1,
            "id": 0x4286 // EBMLVersion
        },
        {
            "data": 1,
            "id": 0x42f7 // EBMLReadVersion
        },
        {
            "data": 4,
            "id": 0x42f2 // EBMLMaxIDLength
        },
        {
            "data": 8,
            "id": 0x42f3 // EBMLMaxSizeLength
        },
        {
            "data": "webm",
            "id": 0x4282 // DocType
        },
        {
            "data": 2,
            "id": 0x4287 // DocTypeVersion
        },
        {
            "data": 2,
            "id": 0x4285 // DocTypeReadVersion
        }
    ]
}

const INFO: (info: { duration: number }) => EBML_DATA_ARRAY_TYPE = (info) => {
    return {
        "id": 0x1549a966, // Info
        "data": [
            {
                "data": 1e6, //do things in millisecs (num of nanosecs for duration scale)
                "id": 0x2ad7b1 // TimecodeScale
            },
            {
                "data": "whammy",
                "id": 0x4d80 // MuxingApp
            },
            {
                "data": "whammy",
                "id": 0x5741 // WritingApp
            },
            {
                "data": doubleToString(info.duration),
                "id": 0x4489 // Duration
            }
        ]
    }
}

const TRACK_VIDEO: (info: { width: number, height: number }) => EBML_DATA_ARRAY_TYPE = (info) => {
    return {
        "id": 0xe0,  // Video
        "data": [
            {
                "data": info.width,
                "id": 0xb0 // PixelWidth
            },
            {
                "data": info.height,
                "id": 0xba // PixelHeight
            }
        ]
    }
}

const TRACKS: (info: { width: number, height: number }) => EBML_DATA_ARRAY_TYPE = (info) => {
    return {
        "id": 0x1654ae6b, // Tracks
        "data": [
            {
                "id": 0xae, // TrackEntry
                "data": [
                    {
                        "data": 1,
                        "id": 0xd7 // TrackNumber
                    },
                    {
                        "data": 1,
                        "id": 0x73c5 // TrackUID
                    },
                    {
                        "data": 0,
                        "id": 0x9c // FlagLacing
                    },
                    {
                        "data": "und",
                        "id": 0x22b59c // Language
                    },
                    {
                        "data": "V_VP8",
                        "id": 0x86 // CodecID
                    },
                    {
                        "data": "VP8",
                        "id": 0x258688 // CodecName
                    },
                    {
                        "data": 1,
                        "id": 0x83 // TrackType
                    },
                    TRACK_VIDEO(info)
                ]
            }
        ]
    }

}

export class WhammyVideo {

    frames: Array<{ image: string | ImageData, duration: number }>;
    duration: number
    quality: number

    constructor(speed: number, quality?: number) { // a more abstract-ish API
        this.frames = [];
        this.duration = 1000 / speed;
        this.quality = quality || 0.8;
    }

    //woot, a function that's actually written for this project!
    //this parses some json markup and makes it into that binary magic
    //which can then get shoved into the matroska comtainer (peaceably)

    makeSimpleBlock = (data: any) => {
        var flags = 0;
        if (data.keyframe) flags |= 128;
        if (data.invisible) flags |= 8;
        if (data.lacing) flags |= (data.lacing << 1);
        if (data.discardable) flags |= 1;
        if (data.trackNum > 127) {
            throw "TrackNumber > 127 not supported";
        }
        var out = [data.trackNum | 0x80, data.timecode >> 8, data.timecode & 0xff, flags].map(function (e) {
            return String.fromCharCode(e)
        }).join('') + data.frame;

        return out;
    }

    toWebM = (frames: Array<{ width: number; height: number; data: any; riff: any; duration: number; }>, outputAsArray: boolean) => {
        var info = this.checkFrames(frames);

        //max duration by cluster in milliseconds
        var CLUSTER_MAX_DURATION = 30000;

        var EBML = [
            EBML_DATA,
            {
                "id": 0x18538067, // Segment
                "data": [
                    INFO(info),
                    TRACKS(info),
                    {
                        "id": 0x1c53bb6b, // Cues
                        "data": [
                            //cue insertion point
                        ]
                    }

                    //cluster insertion point
                ]
            }
        ];


        var segment = EBML[1];
        var cues = segment.data[2] as EBML_DATA_ARRAY_TYPE;

        //Generate clusters (max duration)
        var frameNumber = 0;
        var clusterTimecode = 0;
        while (frameNumber < frames.length) {

            var cuePoint = {
                "id": 0xbb, // CuePoint
                "data": [
                    {
                        "data": Math.round(clusterTimecode),
                        "id": 0xb3 // CueTime
                    },
                    {
                        "id": 0xb7, // CueTrackPositions
                        "data": [
                            {
                                "data": 1,
                                "id": 0xf7 // CueTrack
                            },
                            {
                                "data": 0, // to be filled in when we know it
                                "size": 8,
                                "id": 0xf1 // CueClusterPosition
                            }
                        ]
                    }
                ]
            };

            cues.data.push(cuePoint);

            var clusterFrames = [];
            var clusterDuration = 0;
            do {
                clusterFrames.push(frames[frameNumber]);
                clusterDuration += frames[frameNumber].duration;
                frameNumber++;
            } while (frameNumber < frames.length && clusterDuration < CLUSTER_MAX_DURATION);

            var clusterCounter = 0;
            var mappedClusterFrames = clusterFrames.map((webp) => {
                var block = this.makeSimpleBlock({
                    discardable: 0,
                    frame: webp.data.slice(4),
                    invisible: 0,
                    keyframe: 1,
                    lacing: 0,
                    trackNum: 1,
                    timecode: Math.round(clusterCounter)
                });
                clusterCounter += webp.duration;
                return {
                    data: block,
                    id: 0xa3
                };
            })
            var cluster: any = {
                "id": 0x1f43b675, // Cluster
                "data": [
                    {
                        "data": Math.round(clusterTimecode),
                        "id": 0xe7 // Timecode
                    },
                    ...mappedClusterFrames
                ]
            }

            //Add cluster to segment
            segment.data.push(cluster);
            clusterTimecode += clusterDuration;
        }

        //First pass to compute cluster positions
        var position = 0;
        for (var i = 0; i < segment.data.length; i++) {
            if (i >= 3) {
                let cuesData = cues.data[i - 3] as EBML_DATA_ARRAY_TYPE
                cuesData.data[1].data = position;
                //cues.data[i - 3].data[1].data[1].data = position;
            }
            var data = this.generateEBML([segment.data[i]], outputAsArray);
            if (data instanceof ArrayBuffer) {
                position += data.byteLength
            } else if (data instanceof Uint8Array) {
                position += data.length
            }

            //position += data.size || data.byteLength || data.length;
            if (i != 2) { // not cues
                //Save results to avoid having to encode everything twice
                segment.data[i] = data;
            }
        }

        return this.generateEBML(EBML, outputAsArray)
    }

    add = (frame: CanvasRenderingContext2D | HTMLCanvasElement | ImageData | string, duration?: number) => {
        if (typeof duration != 'undefined' && this.duration) throw "you can't pass a duration if the fps is set";
        if (typeof duration == 'undefined' && !this.duration) throw "if you don't have the fps set, you need to have durations here.";
        if (frame instanceof CanvasRenderingContext2D) { //CanvasRenderingContext2D
            frame = frame.canvas;
        }
        if (frame instanceof HTMLCanvasElement) {
            frame = frame.toDataURL('image/webp', this.quality);
            // quickly store image data so we don't block cpu. encode in compile method.
            //frame = frame.getContext('2d')!.getImageData(0, 0, frame.width, frame.height);
        } else if (typeof frame != "string") {
            throw "frame must be a a HTMLCanvasElement, a CanvasRenderingContext2D or a DataURI formatted string"
        }
        if (typeof frame === "string" && !(/^data:image\/webp;base64,/ig).test(frame)) {
            throw "Input must be formatted properly as a base64 encoded DataURI of type image/webp";
        }
        this.frames.push({
            image: frame,
            duration: duration || this.duration
        });
    }


    // deferred webp encoding. Draws image data to canvas, then encodes as dataUrl
    encodeFrames = (callback: () => void) => {

        if (this.frames[0].image instanceof ImageData) {

            var frames = this.frames;
            var tmpCanvas = document.createElement('canvas');
            var tmpContext = tmpCanvas.getContext('2d');
            tmpCanvas.width = this.frames[0].image.width;
            tmpCanvas.height = this.frames[0].image.height;

            var encodeFrame = (index: number) => {
                var frame = frames[index];
                tmpContext!.putImageData(frame.image as ImageData, 0, 0);
                frame.image = tmpCanvas.toDataURL('image/webp', this.quality);
                if (index < frames.length - 1) {
                    setTimeout(function () { encodeFrame(index + 1); }, 1);
                } else {
                    callback();
                }
            }

            encodeFrame(0);
        } else {
            callback();
        }
    };

    // here's something else taken verbatim from weppy, awesome rite?

    parseWebP = (riff: any) => {
        var VP8 = riff.RIFF[0].WEBP[0];

        var frame_start = VP8.indexOf('\x9d\x01\x2a'); //A VP8 keyframe starts with the 0x9d012a header
        for (var i = 0, c = []; i < 4; i++) c[i] = VP8.charCodeAt(frame_start + 3 + i);

        var width, /*horizontal_scale*,*/ height, /*vertical_scale,*/ tmp;

        //the code below is literally copied verbatim from the bitstream spec
        tmp = (c[1] << 8) | c[0];
        width = tmp & 0x3FFF;
        //horizontal_scale = tmp >> 14;
        tmp = (c[3] << 8) | c[2];
        height = tmp & 0x3FFF;
        //vertical_scale = tmp >> 14;
        return {
            width: width,
            height: height,
            data: VP8,
            riff: riff,
            duration: 0
        }
    }

    compile = (outputAsArray: boolean, callback: (output: Blob | Uint8Array) => void, progress: (frame: number) => void) => {

        this.encodeFrames(() => {
            // esse demora pra caralho
            console.log('encoded frames ' + (new Date()).toISOString())
            // depois do enocdeFrames, o this.frames.image é uma string
            // tinha um new aqui q eu n entendi pra que
            var webm = this.toWebM(this.frames.map((frame, index) => {
                var webp = this.parseWebP(parseRIFF(atob((frame.image as string).slice(23))));
                webp.duration = frame.duration;
                progress(index);
                return webp;
            }), outputAsArray);
            callback(webm);
        });
    }


    // sums the lengths of all the frames and gets the duration, woo

    checkFrames = (frames: any) => {
        var width = frames[0].width,
            height = frames[0].height,
            duration = frames[0].duration;
        for (var i = 1; i < frames.length; i++) {
            if (frames[i].width !== width) throw "Frame " + (i + 1) + " has a different width";
            if (frames[i].height !== height) throw "Frame " + (i + 1) + " has a different height";
            if (frames[i].duration < 0 || frames[i].duration > 0x7fff) throw "Frame " + (i + 1) + " has a weird duration (must be between 0 and 32767)";
            duration += frames[i].duration;
        }
        return {
            duration: duration,
            width: width,
            height: height
        };
    }


    numToBuffer = (num: number) => {
        var parts = [];
        while (num > 0) {
            parts.push(num & 0xff)
            num = num >> 8
        }
        return new Uint8Array(parts.reverse());
    }

    numToFixedBuffer = (num: number, size: number) => {
        var parts = new Uint8Array(size);
        for (var i = size - 1; i >= 0; i--) {
            parts[i] = num & 0xff;
            num = num >> 8;
        }
        return parts;
    }

    strToBuffer = (str: string) => {
        // return new Blob([str]);

        var arr = new Uint8Array(str.length);
        for (var i = 0; i < str.length; i++) {
            arr[i] = str.charCodeAt(i)
        }
        return arr;
        // this is slower
        // return new Uint8Array(str.split('').map(function(e){
        // 	return e.charCodeAt(0)
        // }))
    }


    //sorry this is ugly, and sort of hard to understand exactly why this was done
    // at all really, but the reason is that there's some code below that i dont really
    // feel like understanding, and this is easier than using my brain.

    bitsToBuffer = (bits: string) => {
        var data = [];
        var pad = (bits.length % 8) ? (new Array(1 + 8 - (bits.length % 8))).join('0') : '';
        bits = pad + bits;
        for (var i = 0; i < bits.length; i += 8) {
            data.push(parseInt(bits.substr(i, 8), 2))
        }
        return new Uint8Array(data);
    }

    generateEBML = (json: any, outputAsArray: boolean = false) => {
        var ebml: Array<BlobPart> = [];
        for (var i = 0; i < json.length; i++) {
            if (!('id' in json[i])) {
                //already encoded blob or byteArray
                ebml.push(json[i]);
                continue;
            }

            var data = json[i].data;
            if (typeof data == 'object') data = this.generateEBML(data, outputAsArray);
            if (typeof data == 'number') data = ('size' in json[i]) ? this.numToFixedBuffer(data, json[i].size) : this.bitsToBuffer(data.toString(2));
            if (typeof data == 'string') data = this.strToBuffer(data);

            /*if (data.length) {
                var z: any = z;
            }*/

            var len = data.size || data.byteLength || data.length;
            var zeroes = Math.ceil(Math.ceil(Math.log(len) / Math.log(2)) / 8);
            var size_str = len.toString(2);
            var padded = (new Array((zeroes * 7 + 7 + 1) - size_str.length)).join('0') + size_str;
            var size = (new Array(zeroes)).join('0') + '1' + padded;

            //i actually dont quite understand what went on up there, so I'm not really
            //going to fix this, i'm probably just going to write some hacky thing which
            //converts that string into a buffer-esque thing

            ebml.push(this.numToBuffer(json[i].id));
            ebml.push(this.bitsToBuffer(size));
            ebml.push(data)


        }

        //output as blob or byteArray
        if (outputAsArray) {
            //convert ebml to an array
            var buffer = this.toFlatArray(ebml)
            return new Uint8Array(buffer);
        } else {
            return new Blob(ebml, { type: "video/webm" });
        }
    }

    toFlatArray = (arr: Array<any>, outBuffer: Array<any> | null = null) => {
        if (outBuffer == null) {
            outBuffer = [];
        }
        for (var i = 0; i < arr.length; i++) {
            if (typeof arr[i] == 'object') {
                //an array
                this.toFlatArray(arr[i], outBuffer)
            } else {
                //a simple element
                outBuffer.push(arr[i]);
            }
        }
        return outBuffer;
    }

    /*parseRIFF = (str: string) => {
        var offset = 0;
        var chunks: any = {};

        while (offset < str.length) {
            var id = str.substr(offset, 4);
            chunks[id] = chunks[id] || [];
            if (id == 'RIFF' || id == 'LIST') {
                var len = parseInt(str.substr(offset + 4, 4).split('').map(function (i) {
                    var unpadded = i.charCodeAt(0).toString(2);
                    return (new Array(8 - unpadded.length + 1)).join('0') + unpadded
                }).join(''), 2);
                var data = str.substr(offset + 4 + 4, len);
                offset += 4 + 4 + len;
                chunks[id].push(this.parseRIFF(data));
            } else if (id == 'WEBP') {
                // Use (offset + 8) to skip past "VP8 "/"VP8L"/"VP8X" field after "WEBP"
                chunks[id].push(str.substr(offset + 8));
                offset = str.length;
            } else {
                // Unknown chunk type; push entire payload
                chunks[id].push(str.substr(offset + 4));
                offset = str.length;
            }
        }
        return chunks;
    }*/
}

type ChunkSizeAndBinaryData = string;

function readUint32LittleEndian(buffer: string, offset: number): number {
    const val = parseInt(
        buffer
            .substr(offset, 4)
            .split('')
            .map(function (i) {
                const unpadded = i.charCodeAt(0).toString(2);
                return new Array(8 - unpadded.length + 1).join('0') + unpadded;
            })
            .reverse()
            .join(''),
        2,
    );
    return val;
}

/**
 * 对于 VP8X，需要提取出其中的 VP8 或 VP8L bit stream chunk。
 * 关于 VP8X 格式，参见 Extended file format: https://developers.google.com/speed/webp/docs/riff_container#extended_file_format
 * @param buffer VP8X Chunk数据，不含 "VP8X" tag
 */
function extractBitStreamFromVp8x(buffer: string): ChunkSizeAndBinaryData {
    /*
   32bit VP8X Chunk size
   8bit Flags: Rsv I L E X A R
   24bit Reserved
   24bit Canvas Width Minus One
   24bit Canvas Height Minus One
  */
    let offset = 4 + 1 + 3 + 3 + 3;
    while (offset < buffer.length) {
        const chunkTag = buffer.substr(offset, 4);
        offset += 4;
        const chunkSize = readUint32LittleEndian(buffer, offset);
        offset += 4;
        switch (chunkTag) {
            case 'VP8 ':
            case 'VP8L':
                // eslint-disable-next-line no-case-declarations
                const size = buffer.substr(offset - 4, 4);
                // eslint-disable-next-line no-case-declarations
                const body = buffer.substr(offset, chunkSize);
                return size + body;
            default:
                offset += chunkSize;
                break;
        }
    }
    throw new Error('VP8X format error: missing VP8/VP8L chunk.');
}

export default function parseRIFF(string: string): IRiff {
    let offset = 0;
    const chunks: {
        [index: string]: any;
    } = {};

    while (offset < string.length) {
        const id = string.substr(offset, 4);
        chunks[id] = chunks[id] || [];
        if (id === 'RIFF' || id === 'LIST') {
            const len = readUint32LittleEndian(string, offset + 4);
            const data = string.substr(offset + 4 + 4, len);
            offset += 4 + 4 + len;
            chunks[id].push(parseRIFF(data));
        } else if (id === 'WEBP') {
            const vpVersion = string.substr(offset + 4, 4);
            switch (vpVersion) {
                case 'VP8X':
                    chunks[id].push(extractBitStreamFromVp8x(string.substr(offset + 8)));
                    break;
                case 'VP8 ':
                case 'VP8L':
                    // Use (offset + 8) to skip past "VP8 " / "VP8L" field after "WEBP"
                    chunks[id].push(string.substr(offset + 8));
                    break;
                default:
                    // eslint-disable-next-line no-console
                    console.error(`not supported webp version: "${vpVersion}"`);
                    break;
            }
            offset = string.length;
        } else {
            // Unknown chunk type; push entire payload
            chunks[id].push(string.substr(offset + 4));
            offset = string.length;
        }
    }
    return (chunks as any) as IRiff;
}

/*	
export fromImageArray = (images: Array<string>, fps: number, outputAsArray: boolean) => {
    return toWebM(images.map(function (image) {
        var webp = parseWebP(parseRIFF(atob(image.slice(23))))
        webp.duration = 1000 / fps;
        return webp;
    }), outputAsArray)
}
*/

