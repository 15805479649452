import React, { Component, useEffect } from 'react';
import { Container, Row, Col, Button, Alert, FormGroup, Label, Input } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faUser, faPhone, faCommentDots, faCheckCircle, faMinusCircle, faClock, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'

import Company from '../../models/Company';
import UserAccessValidation from '../../models/UserAccessValidation';
import { LastSmsSent, SMSTypes, ErrorSMS } from '../../models/SMS';
import LoadingScreen from '../loading';
import TokenHelper from '../../auth/TokenHelper';
import AdminAccessValidationService from '../../services/admin/adminAccessValidationService';
import AdminSmsService from '../../services/admin/adminSmsService'
import config from '../../config'
import { AuthData, SignUpConfig } from '../../models/CompanyConfig';
import EnterService from '../../services/enterService';
import Select from 'react-select'
import SearchInputView from '../../components/SearchInputView';
import ReactList from 'react-list';
import { useUser } from '../../hooks/useUser';

interface State {
    signUpConfig: SignUpConfig | undefined;
    userAccessValidation: Array<UserAccessValidation>;
    lastSmsSent: Array<LastSmsSent>;
    selectedPhones: Array<string>;
    searchInput: string;
    selectedMessageTemplate: number;
    authDataSelectedValues: { [id: string]: string[] };
};

const INITIAL_STATE: State = {
    signUpConfig: undefined,
    userAccessValidation: [],
    lastSmsSent: [],
    selectedPhones: [],
    searchInput: '',
    selectedMessageTemplate: 0,
    authDataSelectedValues: {},
};

const WhatsAppMessageTemplates = [
    'Acesse o seu treinamento {{1}} no link {{2}} _Responda essa mensagem para poder clicar no link_',
    'Complete o treinamento {{1}} e ganhe pontos e um certificado. Para fazer é só acessar o link {{2}} _responda esta mensagem para poder clicar no link_',
    'Olá, vimos que você ainda não entrou na plataforma de treinamento {{1}}. Para entrar é só acessar o link {{2}} _responda esta mensagem para poder clicar no link_'
]


const SmsPanelScreen = () => {

    const navigate = useNavigate();
    const [state, setState] = React.useState<State>(INITIAL_STATE)
    const { company, user } = useUser()
    const [signUpConfig, setSignUpConfig] = React.useState<SignUpConfig | undefined>(undefined)
    const [loadingError, setLoadingError] = React.useState<{ isLoading: boolean, error?: string }>({ isLoading: false })


    useEffect(() => {
        if (company) loadCompanySignUpConfig(company.id)
    }, [company])

    useEffect(() => {
        if (signUpConfig) {
            loadUserAccessAndLastMessageSent(company!.id)
        }
    }, [signUpConfig])


    const loadCompanySignUpConfig = async (companyId: string) => {
        try {
            setLoadingError({ isLoading: true, error: undefined })
            let enterService = new EnterService()
            let { signUpConfig } = await enterService.getCompanySignUpConfig(config.endpoint, companyId)
            setSignUpConfig(signUpConfig)
        } catch (error) {
            setLoadingError({ isLoading: false, error: error.toString() })
        }
    }

    const loadUserAccessAndLastMessageSent = async (companyId: string) => {
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            setLoadingError({ isLoading: true, error: undefined })
            let adminAccessValidationService = new AdminAccessValidationService()
            let { accessValidation, lastSmsSent } = await adminAccessValidationService.getAllAccessValidationWithLastSms(token, config.endpoint)
            setState({ ...state, userAccessValidation: accessValidation, lastSmsSent })
            setLoadingError({ isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadUserAccessAndLastMessageSent(companyId)
            } else {
                setLoadingError({ isLoading: false, error: error.toString() })
            }
        }
    }

    const sendMessages = async (company: Company, selectedMessageTemplate: number, selectedPhones: Array<string>) => {
        setLoadingError({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let message = WhatsAppMessageTemplates[selectedMessageTemplate].replace('{{1}}', company.name).replace('{{2}}', `https://www.gamific.app/app/enter/${company.id}`)
            let adminService = new AdminSmsService()
            // tem que atualizar o status do último sms enviado
            let newLastSmsSent = await adminService.sendWhatsAppMessage(token, config.endpoint, selectedPhones, message)
            let removeOutdatedLastSmsSent = state.lastSmsSent.filter(oldLastSmsSent => {
                return newLastSmsSent.filter(newSms => newSms.phone === oldLastSmsSent.phone).length === 0
            })
            let lastSmsSent = [...newLastSmsSent, ...removeOutdatedLastSmsSent]
            setState({ ...state, lastSmsSent, selectedPhones: [] })
            setLoadingError({ isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                sendMessages(company, selectedMessageTemplate, selectedPhones)
            } else {
                setLoadingError({ isLoading: false, error: error.toString() })
            }
        }
    }

    const goBack = () => navigate(-1)

    const selectUserPhone = (selectedPhones: Array<string>, userPhone: string) => {
        if (selectedPhones.filter(phone => phone === userPhone).length === 0) {
            // concat cria um novo array, por causa do state do react nao podemos
            // só dar um push no Array antigo e esperar que o render seja chamado novamente
            let newSelectedPhones = selectedPhones.concat(userPhone)
            setState({ ...state, selectedPhones: newSelectedPhones })
        }
    }

    const deselectUserPhone = (selectedPhones: Array<string>, userPhone: string) => {
        // filter tb gera um novo array
        let newSelectedPhones = selectedPhones.filter(phone => phone !== userPhone)
        setState({ ...state, selectedPhones: newSelectedPhones })
    }

    const renderHeader = (company: Company) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { goBack() }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Acesso de Usuários</b></div>
                    <img alt='foto da empresa' style={{ height: 60, marginBottom: 10, marginTop: 10 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderMessageForm = (company: Company, signUpConfig: SignUpConfig, searchInput: string, selectedPhones: Array<string>, selectedMessageTemplate: number, authDataSelectedValues: { [id: string]: string[] }) => {
        let isSendButtonEnabled = selectedPhones.length
        return (<Row style={{ paddingTop: 10 }}>
            <Col md={{ size: 8, offset: 2 }}>
                {signUpConfig.authData && renderAuthDataSelection(signUpConfig.authData, authDataSelectedValues)}
                <FormGroup tag="fieldset">
                    <div><b>Mensagem</b></div>
                    {WhatsAppMessageTemplates.map((messageTemplate, index) => {
                        let correctMessage = messageTemplate.replace('{{1}}', company.name).replace('{{2}}', `https://www.gamific.app/app/enter/${company.id}`).replace(/_responda es(t|s)a mensagem para poder clicar no link_/i, '')
                        return (<FormGroup key={index} check style={{ marginBottom: 10 }}>
                            <Label check>
                                <Input type="radio" name={`radio${index}`} value={index} checked={selectedMessageTemplate === index} onChange={(event: any) => { event.persist(); setState({ ...state, selectedMessageTemplate: parseInt(event.target.value) }) }} />{' '}{correctMessage}
                            </Label>
                        </FormGroup>)
                    })}
                </FormGroup>
            </Col>
            <Col md={{ size: 8, offset: 2 }}>
                <div className="d-flex flex-column justify-content-center" style={{ fontSize: 12, marginLeft: 5, marginTop: 5, color: '#1d3256' }}>
                    <div><i>Apenas usuários com número cadastrado podem ser adicionados</i></div>
                    <div style={{ marginBottom: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faPhone} />{`${selectedPhones.length} telefones selecionados`}</div>
                    <Button color="success" disabled={!isSendButtonEnabled} onClick={() => sendMessages(company, selectedMessageTemplate, selectedPhones)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCheckCircle} />Enviar SMS</Button>
                </div>
            </Col>
            <Col md={{ size: 8, offset: 2 }}>
                <div className="d-flex" style={{ fontSize: 14, marginLeft: 5, marginTop: 10, color: '#1d3256' }}>
                    <div><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faLink} /><b>Link para o jogo</b></div>
                    <div style={{ marginLeft: 5 }}>{`https://www.gamific.app/app/enter/${company.id}`}</div>
                </div>
            </Col>
        </Row>)
    }

    const renderLastSmsSent = (lastSmsSent: LastSmsSent) => {
        let lastSmsSentDate = new Date(lastSmsSent.smsData.dateMillis)
        let formattedDate = `${lastSmsSentDate.toLocaleDateString("pt-BR")} - ${lastSmsSentDate.toLocaleTimeString()}`
        let sms = lastSmsSent.smsData
        var isError = sms.type === SMSTypes.Error
        var message = ''
        if (isError) {
            let errorSms = sms as ErrorSMS
            message = `Erro ao enviar SMS: ${errorSms.error}`
        } else if (sms.type === SMSTypes.EnterLink) {
            message = sms.message || 'Envio de link para jogar'
        }
        return (<div style={{ marginTop: 5 }}>
            <div><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faCommentDots} /><b>Último sms enviado para o usuário:</b></div>
            <div style={{ marginRight: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faClock} /><i>{formattedDate}</i></div>
            <div style={{ marginRight: 5, color: isError ? 'red' : 'black' }}>{message}</div>
        </div>)
    }

    const renderPhone = (userPhone: string, lastSmsSent: LastSmsSent | undefined) => {
        return (<div className="d-flex flex-column" style={{ fontSize: 12, marginLeft: 5, marginTop: 5, color: '#1d3256' }}>
            <div><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faPhone} />{userPhone}</div>
            {lastSmsSent && renderLastSmsSent(lastSmsSent)}
        </div>)
    }

    const lowerCaseAndRemovePunctuationFromString = (data: string) => {
        return data.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ")
    }

    const updateSelectedValues = (id: string, value: string[]) => {
        let authDataSelectedValues = state.authDataSelectedValues
        authDataSelectedValues[id] = value
        //if (!authDataSelectedValues[id]) authDataSelectedValues[id] = [value]
        //else if (authDataSelectedValues[id].filter(val => val === value).length === 0) authDataSelectedValues[id].push(value)
        setState({ ...state, authDataSelectedValues })
    }

    const renderAuthDataSelection = (authData: { [id: string]: AuthData }, authDataSelectedValues: { [id: string]: string[] }) => {
        let authDataKeys = Object.keys(authData)
        return (<div style={{ marginBottom: 5 }}>
            {authDataKeys.filter(key => !authData[key].show).map((id, index) => {
                let currentAuthData = authData[id]
                let selectedAuthDataValue = authDataSelectedValues[id] || 'Nenhum'

                if (Array.isArray(currentAuthData.values) && currentAuthData.values.length > 0) {

                    let options = currentAuthData.values.map(val => { return { label: val, value: val } })
                    return <FormGroup key={index} style={{ marginBottom: 10 }}>
                        <Label>{currentAuthData.title}</Label>
                        <Select isMulti placeholder={currentAuthData.placeholder} options={options} onChange={(opt, action) => {
                            updateSelectedValues(id, opt!.map(opt => opt.value))
                        }} />
                    </FormGroup>
                } else {
                    return (
                        <FormGroup key={index}>
                            <Label>{currentAuthData.title}</Label>
                            <Input style={{ fontFamily: 'Montserrat' }} name={currentAuthData.title} id={id} value={selectedAuthDataValue} placeholder={currentAuthData.placeholder} onChange={(event: any) => updateSelectedValues(id, [event.target.value as string])} />
                        </FormGroup>
                    )
                }
            })}
        </div>)
    }

    const renderAuthData = (userAccessAuthData: any, signUpConfigAuthData: { [id: string]: AuthData }) => {
        let authDataKeys = Object.keys(userAccessAuthData)
        return authDataKeys.map((authDataKey, index) => {
            let authDataValue = userAccessAuthData[authDataKey]
            let signUpAuthDataInfo = signUpConfigAuthData[authDataKey]
            return (<div key={index} className="d-flex flex-column" style={{ marginTop: 2 }}>
                <div style={{ fontSize: 10, marginLeft: 5, marginTop: 2, color: 'black' }}>{`${signUpAuthDataInfo.title} - ${authDataValue}`}</div>
            </div>)
        })
    }

    const renderUserAccessValidation = (userAccessValidation: Array<UserAccessValidation>, lastSmsSent: Array<LastSmsSent>, selectedPhones: Array<string>, searchInput: string, authData: { [id: string]: AuthData } | undefined, authDataSelectedValues: { [id: string]: string[] }) => {
        let authDataIds = Object.keys(authDataSelectedValues)
        let sortedUserAccessValidation = userAccessValidation.filter(data => {
            let primaryPropertyWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(data.primaryPropertyValue)
            let searchInputWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(searchInput)
            if (primaryPropertyWithoutPunctuation.includes(searchInputWithoutPunctuation)) return true
            if (data.name) {
                let nameWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(data.name)
                return nameWithoutPunctuation.includes(searchInputWithoutPunctuation)
            }
            return false
        }).sort((a, b) => {
            if (a.name && b.name) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
                else return 0
            } else if (a.name && !b.name) {
                return 1
            } else if (!a.name && b.name) {
                return - 1
            } else {
                if (a.primaryPropertyValue.toLowerCase() > b.primaryPropertyValue.toLowerCase()) return 1
                else if (a.primaryPropertyValue.toLowerCase() < b.primaryPropertyValue.toLowerCase()) return -1
                else return 0
            }
        }).filter(access => {
            for (var i = 0; i < authDataIds.length; i++) {
                let id = authDataIds[i]
                if (!access.authData || !access.authData[id]) return false
                let selectedValuesArray = authDataSelectedValues[id]
                if (selectedValuesArray && selectedValuesArray.length > 0) {
                    if (selectedValuesArray.filter(val => { return val === access.authData[id] }).length === 0) return false
                }
            }
            return true
        })

        let renderItem = (index: number, key: number | string) => {
            let data = sortedUserAccessValidation[index]
            let isSelected = selectedPhones.filter(phone => phone === data.phone).length > 0
            let userLastMessageSent = data.phone ? _.first(lastSmsSent.filter(lastSmsSent => data.phone === lastSmsSent.phone)) : undefined
            return (<div key={key} className="d-flex" style={{ marginTop: 10, paddingBottom: 5, borderRadius: 2, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                <div className="d-flex" style={{ flex: 1, paddingBottom: 5 }}>
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <div className="d-flex flex-column" style={{ marginTop: 2 }}>
                            <div style={{ fontSize: 12, marginLeft: 5, marginTop: 5, color: '#1d3256' }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faUser} />{data.primaryPropertyValue}</div>
                            {data.name && <div style={{ fontSize: 18, color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', marginTop: 5, marginLeft: 5 }}>{data.name}</div>}
                            {authData && data.authData && renderAuthData(data.authData, authData)}
                            {data.phone && renderPhone(data.phone, userLastMessageSent)}
                        </div>
                    </div>
                    {data.phone ? <div className="p-2 d-flex flex-column justify-content-center">
                        {!isSelected && <Button color="success" onClick={() => selectUserPhone(selectedPhones, data.phone!)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCheckCircle} />Adicionar no envio</Button>}
                        {isSelected && <Button color="danger" onClick={() => deselectUserPhone(selectedPhones, data.phone!)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faMinusCircle} />Remover do envio</Button>}
                    </div> :
                        <div>
                            <div className="p-2 d-flex flex-column justify-content-center" style={{ fontSize: 18, color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', marginTop: 5, marginLeft: 5 }}>Sem telefone</div>
                        </div>}
                </div>
            </div>)
        }

        return <Row className="affix-content" style={{ marginTop: 10 }}>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <div className="d-flex flex-column" style={{ flex: 1 }}>
                    <div style={{ fontSize: 16, color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', margin: 5 }}>{`Total: ${sortedUserAccessValidation.length}`}</div>
                </div>
            </Col>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <ReactList itemRenderer={renderItem} length={sortedUserAccessValidation.length} type='variable' />
            </Col>
        </Row>
    }

    let { userAccessValidation, lastSmsSent, searchInput, selectedPhones, selectedMessageTemplate, authDataSelectedValues } = state
    let { error, isLoading } = loadingError

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    return (<Container className="d-flex flex-column" fluid style={{ minHeight: '100vh', background: 'white' }}>
        {error && <Alert color="danger">{error}</Alert>}
        {company && renderHeader(company)}
        <SearchInputView placeholder='Procurar usuário' updateSearchInput={(searchInput: string) => setState({ ...state, searchInput })} />
        {company && signUpConfig && renderMessageForm(company, signUpConfig, searchInput, selectedPhones, selectedMessageTemplate, authDataSelectedValues)}
        {signUpConfig && renderUserAccessValidation(userAccessValidation, lastSmsSent, selectedPhones, searchInput, signUpConfig.authData, authDataSelectedValues)}
    </Container>)
}


export default SmsPanelScreen