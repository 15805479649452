import React, { Component, Fragment } from 'react';
import _ from 'lodash'
import Select from 'react-select'
import Module from '../../../../models/Module';
import CardGif from '../../../../models/CardGif';
import { Col, Button, Label, Row, Form, FormGroup, Input, Spinner, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLink } from '@fortawesome/free-solid-svg-icons';

interface SelectGifProps {
    uploadFile: (file: File | undefined) => void,
    getCardGifs: (callback: (modules: Array<Module>, cardImages: Array<CardGif>) => void) => void,
    onGifSelected: (gif: string) => void,
    goBackToEdit: () => void
}

interface SelectGifComponentState {
    isLoading: boolean;
    modules: Array<Module>;
    cardGifs: Array<CardGif>;
    isModuleDropdownOpen: boolean,
    selectedModule: Module | undefined,
    linkInput: string;
}

const SELECT_IMAGE_INITIAL_STATE: SelectGifComponentState = {
    isLoading: true,
    modules: [],
    cardGifs: [],
    isModuleDropdownOpen: false,
    selectedModule: undefined,
    linkInput: '',
}

export default class SelectGifComponent extends Component<SelectGifProps, SelectGifComponentState> {

    constructor(props: SelectGifProps) {
        super(props)

        this.state = { ...SELECT_IMAGE_INITIAL_STATE }
    }

    componentDidMount() {
        this.props.getCardGifs((modules, cardGifs) => {
            this.setState({ modules, cardGifs, isLoading: false })
        })
    }

    toggleModuleDropdownOpen = () => {
        this.setState({ isModuleDropdownOpen: !this.state.isModuleDropdownOpen });
    }

    renderCardGifs(modules: Array<Module>, selectedModule: Module | undefined, cardGifs: Array<CardGif>) {
        let moduleGifs = cardGifs.filter(cardGif => {
            if (selectedModule) return cardGif.moduleId === selectedModule.id
            else return true
        })

        return moduleGifs.map((moduleGif, index) => {
            let module = _.head(modules.filter(module => module.id === moduleGif.moduleId))
            return (<Col key={index} xs="6" md="3" sm="4">
                <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                    <div style={{ color: 'DarkGray', fontFamily: 'Montserrat', verticalAlign: 'middle' }}>{module ? module.title : ''}</div>
                    <div className="d-flex flex-column justify-content-center">
                        <img className="m-3" style={{ objectFit: 'cover', maxHeight: 250, maxWidth: 250 }} src={moduleGif.gifUrl} alt="Card cap" />
                        <Button style={{ borderStyle: 'none' }} outline color="primary" onClick={() => this.props.onGifSelected(moduleGif.gifUrl)}>Selecionar <FontAwesomeIcon icon={faCheckCircle} /></Button>
                    </div>
                </div>
            </Col>)
        })
    }

    renderModuleSelection(modules: Array<Module>, selectedModule: Module | undefined) {
        let options = modules.map(module => { return { label: module.title, value: module.id } }) //[{ label: 'Todas', value: 'Todas' }, ...modules.map(module => { return { label: module.title, value: module.id } })]
        return <Col className="d-flex flex-column justify-content-center relative mt-3" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
            <FormGroup >
                <Select placeholder="Selecionar imagens de uma Missão" isClearable={true} value={selectedModule ? { label: selectedModule.title, value: selectedModule.id } : undefined} options={options} onChange={
                    opt => {
                        if (!opt) this.setState({ selectedModule: undefined })
                        else {
                            let module = _.first(modules.filter(module => module.id === opt!.value))
                            this.setState({ selectedModule: module })
                        }
                    }
                } />
            </FormGroup></Col>
    }

    render() {

        let { isLoading, modules, selectedModule, cardGifs, linkInput } = this.state

        if (isLoading) { return (<Col className="d-flex justify-content-center" md={{ size: 6, offset: 3 }}><Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />{' '}</Col>) }

        return (<Fragment>
            <Row>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div className="d-flex flex-column justify-content-center mt-3" style={{ background: '#dbdbdbda', borderRadius: 5, padding: 10 }}>
                        <Form>
                            <FormGroup>
                                <Label for="image">Carregar Gif</Label>
                                <Input type="file" name="file" id="image" accept="image/gif" onChange={(event) => this.props.uploadFile(event.target.files ? event.target.files[0] : undefined)} />
                                <FormText color="muted">Carregar um gif do seu dispositivo.</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Input valid={linkInput.length > 5} name="gifUrl" id="gifUrl" placeholder="URL do Gif" value={linkInput} onChange={(event) => this.setState({ linkInput: event.target.value })} />
                                <FormText color="muted">Utilizar o link de uma imagem da web.</FormText>
                            </FormGroup>
                            <Button disabled={linkInput.length < 6} outline color="primary" onClick={() => this.props.onGifSelected(linkInput)}>Usar Link <FontAwesomeIcon icon={faLink} /></Button>
                        </Form>
                    </div>
                </Col>
            </Row>
            <Row>{modules.length > 0 && this.renderModuleSelection(modules, selectedModule)}</Row>
            <Row>{cardGifs.length > 0 && this.renderCardGifs(modules, selectedModule, cardGifs)}</Row>
        </Fragment>)
    }

}
