import React from 'react';
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faImage } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Col, Container, Row, Spinner } from 'reactstrap';

import * as ROUTES from '../../constants/routes'
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../constants/images'
import User from '../../models/User';
import TokenHelper from '../../auth/TokenHelper';
import ProfileService from '../../services/profileService';
import config from '../../config'
import LoadingScreen from '../loading';
import AccountCircleSvg from '../../images/account_circle-24px.svg';
import FileHelper from '../../files/FileHelper'
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useUser } from '../../hooks/useUser';

interface State {
    error: string | undefined;
    isLoading: boolean;
    thumbnail: string | undefined;
}

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    thumbnail: undefined,
}

const fileHelper = new FileHelper()

const ProfilePicScreen = () => {


    const navigate = useNavigate()
    const uploadButtonRef = React.useRef<HTMLInputElement>(null)
    const [state, setState] = React.useState(INITIAL_STATE)
    const { height } = useWindowDimensions()
    const { user, company, noUser, noCompany } = useUser()

    React.useEffect(() => {
        if (noUser && noCompany) {
            setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.' })
        } else if (noUser && company) {
            navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${company.id}`)
        }
    }, [noCompany, noUser, company])


    const uploadFile = async (user: User, file: File | undefined) => {
        if (!file) return
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let imageUrl = await fileHelper.getFileDataUrl(file)
            let image = await fileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await fileHelper.resizeImage(canvas, image, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            let formData = new FormData()
            formData.append('file', resizedImageBlob)
            let profileService = new ProfileService()
            let { mainPicUrl, thumbnail } = await profileService.uploadProfilePic(token, config.endpoint, formData)
            user.pic = mainPicUrl
            user.thumbnail = thumbnail
            let cookies = new Cookies()
            cookies.set('user', user.getData(), { path: '/', maxAge: 31536000 })
            navigate(`/${user.companyId}${ROUTES.MAIN}`, { replace: true })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                uploadFile(user, file)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const openSelectImageDialog = () => {
        if (uploadButtonRef.current != null) {
            uploadButtonRef.current.click()
        }
    }

    const renderError = (error: string) => {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>

        );
    }

    const renderUserPicOrLoading = (userPic: string | undefined, loadingProfilePic: boolean) => {
        if (loadingProfilePic) {
            return (<div className="d-flex justify-content-center">
                <Spinner style={{ minHeight: '25vh', maxHeight: '25vh', color: 'black', marginBottom: 5, marginTop: 10 }} />{' '}
            </div>)
        }
        return (<div className="d-flex justify-content-center" onClick={() => openSelectImageDialog()}>
            <img alt='company pic' style={{ minHeight: '25vh', maxHeight: '25vh', marginBottom: 5, marginTop: 10, borderRadius: 5 }} src={userPic || AccountCircleSvg} />
        </div>)
    }


    const { isLoading, error } = state;

    if (isLoading || !company) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    let backgroundImage = company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column" style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }} fluid>
        {error && renderError(error)}
        {user && company && <Row>
            <Col className="d-flex flex-column align-items-center" md={{ size: 6, offset: 3 }}>
                {renderUserPicOrLoading(user.pic, isLoading)}
                <input id="myInput" type="file" ref={uploadButtonRef} style={{ display: 'none' }} onChange={(event) => uploadFile(user, event.target.files ? event.target.files[0] : undefined)} />
                <Button style={{ borderStyle: 'none', background: company.mainColor || '#aaa' }} onClick={() => openSelectImageDialog()}><FontAwesomeIcon icon={faImage} /> Selecione uma foto para o perfil</Button>
                <Button style={{ borderStyle: 'none', background: '#aaa', marginTop: 15 }} onClick={() => navigate(`/${company.id}${ROUTES.MAIN}`)}>Continuar <FontAwesomeIcon icon={faArrowCircleRight} /></Button>
            </Col>
        </Row>}
    </Container>)

}


export default ProfilePicScreen
