import React, { Fragment } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LinkSvg from '../images/link.svg'

export interface ExternalResourceComponentProps {
	onExit: () => void
	url: string
	title?: string
}

let stripParameters = (shortcodeString: string) => {
	// Split parameters or split folder separator
	if (shortcodeString.includes('?')) {
		shortcodeString = shortcodeString.split('?')[0];
	}

	if (shortcodeString.includes('/')) {
		shortcodeString = shortcodeString.split('/')[0];
	}

	if (shortcodeString.includes('&')) {
		shortcodeString = shortcodeString.split('&')[0];
	}

	return shortcodeString;
}

let getYouTubeVideoId = (youtubeString: string) => {

	let string_ = youtubeString;

	// Remove time hash at the end of the string
	string_ = string_.replace(/#t=.*$/, '');

	// Strip the leading protocol
	string_ = string_.replace(/^https?:\/\//, '');

	// Shortcode
	const shortcode = /youtube:\/\/|youtu\.be\/|y2u\.be\//g;

	if (shortcode.test(string_)) {
		const shortcodeid = string_.split(shortcode)[1];
		return stripParameters(shortcodeid);
	}

	// Shorts
	const shortsUrl = /\/shorts\//g;
	if (shortsUrl.test(string_)) {
		return stripParameters(string_.split(shortsUrl)[1]);
	}

	// V= or vi=
	const parameterv = /v=|vi=/g;

	if (parameterv.test(string_)) {
		const array = string_.split(parameterv);
		return stripParameters(array[1].split('&')[0]);
	}

	// /v/ or /vi/ or /watch/
	const inlinev = /\/v\/|\/vi\/|\/watch\//g;

	if (inlinev.test(string_)) {
		const inlineid = string_.split(inlinev)[1];
		return stripParameters(inlineid);
	}

	// Format an_webp
	const parameterwebp = /\/an_webp\//g;

	if (parameterwebp.test(string_)) {
		const webp = string_.split(parameterwebp)[1];
		return stripParameters(webp);
	}

	// /e/
	const eformat = /\/e\//g;

	if (eformat.test(string_)) {
		const estring = string_.split(eformat)[1];
		return stripParameters(estring);
	}

	// Embed
	const embedreg = /\/embed\//g;

	if (embedreg.test(string_)) {
		const embedid = string_.split(embedreg)[1];
		return stripParameters(embedid);
	}

	// ignore /user/username pattern
	const usernamereg = /\/user\/([a-zA-Z\d]*)$/g;

	if (usernamereg.test(string_)) {
		return undefined;
	}

	// User
	const userreg = /\/user\/(?!.*videos)/g;

	if (userreg.test(string_)) {
		const elements = string_.split('/');
		return stripParameters(elements.pop() || '');
	}

	// Attribution_link
	const attrreg = /\/attribution_link\?.*v%3D([^%&]*)(%26|&|$)/;

	if (attrreg.test(string_)) {
		let match = string_.match(attrreg)
		return match && match.length > 1 ? stripParameters(match[1]) : undefined
	}

	return undefined;
}

let getTiktokVideoId = (urlString: string) => {
	// Parse basic url and embeds
	const basicReg = /tiktok\.com(.*)\/video\/(\d+)/gm;
	const basicParsed = basicReg.exec(urlString);
	if (basicParsed && basicParsed.length > 2) {
		return basicParsed[2];
	}

	return undefined;
}

const ExternalResourceComponent = (props: ExternalResourceComponentProps) => {

	var url = props.url.trim()

	if (/tiktok\.com/.test(url)) {
		let videoId = getTiktokVideoId(url)
		if (videoId) url = `https://www.tiktok.com/embed/${videoId}`
	} else if (/youtube|youtu\.be|y2u\.be|i.ytimg\./.test(url)) {
		let ytVideoId = getYouTubeVideoId(url)
		if (ytVideoId) url = `https://www.youtube.com/embed/${ytVideoId}`
	}

	return <Fragment>
		<Row className='no-gutters' style={{ background: '#FFFFFFDA', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.2)', paddingTop: 5, paddingBottom: 5, marginBottom: 2 }}>
			<Col className='d-flex align-items-center' style={{ height: '4em' }} md={{ size: 8, offset: 2 }}>
				<Button color='none' outline onClick={() => { props.onExit() }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
				{props.title === undefined && <img alt='icone de link externo' style={{ height: 45, width: 45, objectFit: 'contain' }} src={LinkSvg} />}
				<div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap' }}>
					<div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#1d3256', verticalAlign: 'middle', margin: 10 }}>{props.title || 'Link Externo'}</div>
				</div>
			</Col>
		</Row>
		<Row className='no-gutters' style={{ flex: 1 }}>
			<Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
				<iframe title="link externo" style={{ width: '100%', height: '100%' }} src={url} frameBorder="0"></iframe>
			</Col>
		</Row>
	</Fragment>

}

export default ExternalResourceComponent