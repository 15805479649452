import React, { Component, Fragment } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp, faCheckCircle, faTimesCircle, faArrowLeft, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

interface State {
    userList: Array<{ position: number, text: string }>
    isFinished: boolean
}

interface Props { title: string, list: Array<{ position: number, text: string }>, onOrderedListFinished: () => void, onExit: () => void }

export default class OrderedListComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        // é necessário repetir cada imagens no jogo da memória
        let userList = this.shuffle(_.cloneDeep(this.props.list)).sort((a, b) => a.position - b.position)
        this.state = { userList, isFinished: false }
    }

    shuffle = (array: Array<{ position: number, text: string }>) => {
        var currentIndex = array.length, temporaryValue = array[0].position, randomIndex = 0;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex].position;
            array[currentIndex].position = array[randomIndex].position;
            array[randomIndex].position = temporaryValue;
        }

        return array;
    }

    moveElementDown(userList: Array<{ position: number, text: string }>, elementGoingUp: { position: number, text: string }) {
        // pega a posicao antiga do elemento que vai subir
        let oldElementPosition = elementGoingUp.position
        // pega a posicao nova do elemento que vai subir
        let newElementPosition = elementGoingUp.position + 1
        // pega o array sem as posiçoes que vão ser trocadas
        let arrayWithoutSwapingElements = userList.filter(element => {
            if (element.position === oldElementPosition) return false
            else if (element.position === newElementPosition) return false
            else return true
        })
        // pega o elemento que esta na posicao acima
        let elementGoingDown = _.head(userList.filter(element => element.position === newElementPosition))
        // Check de segurança se por algum motivo ele for nulo
        if (elementGoingDown) {
            elementGoingUp.position = newElementPosition
            elementGoingDown.position = oldElementPosition
            let updatedList = arrayWithoutSwapingElements.concat(elementGoingDown, elementGoingUp).sort((a, b) => a.position - b.position)
            this.setState({ userList: updatedList })
        }
    }

    moveElementUp(userList: Array<{ position: number, text: string }>, elementGoingDown: { position: number, text: string }) {
        // pega a posicao antiga do elemento que vai subir
        let oldElementPosition = elementGoingDown.position
        // pega a posicao nova do elemento que vai subir
        let newElementPosition = elementGoingDown.position - 1
        // pega o array sem as posiçoes que vão ser trocadas
        let arrayWithoutSwapingElements = userList.filter(element => {
            if (element.position === oldElementPosition) return false
            else if (element.position === newElementPosition) return false
            else return true
        })
        // pega o elemento que esta na posicao acima
        let elementGoingUp = _.head(userList.filter(element => element.position === newElementPosition))
        // Check de segurança se por algum motivo ele for nulo
        if (elementGoingUp) {
            elementGoingDown.position = newElementPosition
            elementGoingUp.position = oldElementPosition
            let updatedList = arrayWithoutSwapingElements.concat(elementGoingDown, elementGoingUp).sort((a, b) => a.position - b.position)
            this.setState({ userList: updatedList })
        }
    }

    finish(userList: Array<{ position: number, text: string }>) {
        var isCorrect = true
        userList.forEach(element => {
            let correctElement = _.head(this.props.list.filter(el => el.position === element.position))
            if (correctElement) {
                if (correctElement.text !== element.text) isCorrect = false
            } else {
                isCorrect = false
            }
        })
        
        this.setState({ isFinished: true }, () => {
            setTimeout(() => {
                if (isCorrect) this.props.onOrderedListFinished()
                else this.props.onExit()
            }, 2000)
        })
    }

    renderIsElementCorrect(element: { position: number, text: string }) {
        var isElementCorrect = false
        for (var i = 0; i < this.props.list.length; i++) {
            let propsListElement = this.props.list[i]
            if (propsListElement.position === element.position && propsListElement.text === element.text) {
                isElementCorrect = true
                break
            }
        }
        if (isElementCorrect) {
            return <FontAwesomeIcon style={{ margin: 5 }} size='2x' color='green' icon={faCheckCircle} />
        } else {
            return <FontAwesomeIcon style={{ margin: 5 }} size='2x' color='red' icon={faTimesCircle} />
        }
    }

    renderElement(userList: Array<{ position: number, text: string }>, element: { position: number, text: string }, isFinished: boolean) {
        return (<Col key={element.position} className="d-flex flex-column justify-content-center" md={{ size: 8, offset: 2 }}>
            <div className="d-flex align-items-center">
                <div style={{ fontSize: '1em', width: '1.5em', height: '1.5em', fontFamily: 'Montserrat', marginRight: 5, marginLeft: 5, borderRadius: '50%', textAlign: 'center', verticalAlign: 'center', background: '#FFFFFFDA', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}><b>{element.position}</b></div>
                <div className="d-flex justify-content-center align-items-center" style={{ flex: 1, background: '#FFFFFFDA', margin: 5, padding: 10, borderRadius: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                    <div style={{ flex: 1, fontSize: '0.9em', fontFamily: 'Montserrat' }}>{element.text}</div>
                    {!isFinished && element.position > 1 && <Button style={{ margin: 2 }} onClick={() => this.moveElementUp(userList, element)}><FontAwesomeIcon icon={faArrowAltCircleUp} /></Button>}
                    {!isFinished && element.position === 1 && <Button style={{ margin: 2 }} onClick={() => this.moveElementDown(userList, element)}><FontAwesomeIcon icon={faArrowAltCircleDown} /></Button>}
                    {isFinished && this.renderIsElementCorrect(element)}
                </div>
            </div>
        </Col>)
    }

    render() {

        let { userList, isFinished } = this.state

        return (<Fragment>
            <Row className='no-gutters' style={{ background: '#FFFFFFDA', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.2)', paddingTop: 5, paddingBottom: 5 }}>
                <Col className='d-flex justify-content-start align-items-center' style={{ minHeight: '4em' }} md={{ size: 8, offset: 2 }}>
                    <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#343a40' icon={faArrowLeft} size='2x' /></Button>
                    <div className='d-flex flex-column justify-content-center align-items-start' >
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 16 }}>Lista de Etapas</div>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 12 }}>Coloque as etapas na ordem correta utilizando as setas</div>
                    </div>
                </Col>
            </Row>
            <Row className='no-gutters' style={{ marginTop: 10, marginBottom: 10, overflow: 'auto' }}>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 8, offset: 2 }}>
                    <div style={{ fontFamily: 'Montserrat', margin: 5, fontSize: '1em' }}><b>{this.props.title}</b></div>
                </Col>
                {userList.map(element => this.renderElement(userList, element, isFinished))}
                <Col className="d-flex flex-column justify-content-center" md={{ size: 8, offset: 2 }}>
                    <Button disabled={isFinished} style={{ margin: 5, padding: 10 }} color='primary' onClick={() => this.finish(userList)}>Finalizar</Button>
                </Col>
            </Row>
        </Fragment>)
    }
}