import React, { Component } from 'react';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import User from '../../../../models/User';
import { MonthUserCompanyScore } from '../../../../models/Scores';
import Module from '../../../../models/Module';
import { SubModule } from '../../../../models/SubModule';
import TokenHelper from '../../../../auth/TokenHelper';
import AdminService from '../../../../services/adminService';
import config from '../../../../config'
import * as XLSX from 'xlsx'
import { ReportConfig, SignUpConfig } from '../../../../models/CompanyConfig';
import { saveAs } from 'file-saver';

interface Props {
    users: Array<User>,
    monthUsersCompanyScore: Array<MonthUserCompanyScore>,
    module: Module,
    subModules: Array<SubModule>,
    year: number,
    reportConfig: ReportConfig | undefined
    siginUpConfig: SignUpConfig
}

interface State {
    selectedMonth: number,
    error: string | undefined;
    isLoading: boolean;
    dateUsersFinishedModuleInPeriod: {
        userId: string;
        finishedTime: number;
        subModulesFirstFinishedDate: {
            [subModuleId: string]: number;
        };
    }[] | undefined;
}

const INITIAL_STATE: State = {
    selectedMonth: (new Date()).getMonth(),
    error: undefined,
    isLoading: false,
    dateUsersFinishedModuleInPeriod: undefined
}

const currentMonth = (new Date()).getMonth()
const allMonthsArray = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
const currentYear = (new Date()).getFullYear()
const availableMonths = (year: number) => currentYear === year ? allMonthsArray.slice(0, currentMonth) : allMonthsArray

export default class MonthReportDownloadComponent extends Component<Props, State> {



    constructor(props: Props) {
        super(props)

        this.state = { ...INITIAL_STATE }
    }

    getTextForSelectedMonth(month: number) {
        switch (month) {
            case 1:
                return 'Janeiro'
            case 2:
                return 'Fevereiro'
            case 3:
                return 'Março'
            case 4:
                return 'Abril'
            case 5:
                return 'Maio'
            case 6:
                return 'Junho'
            case 7:
                return 'Julho'
            case 8:
                return 'Agosto'
            case 9:
                return 'Setembro'
            case 10:
                return 'Outubro'
            case 11:
                return 'Novembro'
            case 12:
                return 'Dezembro'
            default:
                return 'Janeiro'
        }
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        this.setState({ isLoading: true, error: undefined, dateUsersFinishedModuleInPeriod: undefined })
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let adminService = new AdminService()
            let { module, moduleSubModules, dateUsersFinishedModuleInPeriod } = await adminService.getFinishDateForModule(token, config.endpoint, this.props.year, this.state.selectedMonth, this.props.module.id)
            this.setState({ dateUsersFinishedModuleInPeriod, isLoading: false })

        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.getData()
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    createAndDownloadReport() {

        const reportConfig = this.props.reportConfig
        const primaryProperty = this.props.siginUpConfig.primaryProperty

        let wb = XLSX.utils.book_new()
        var ws_data: Array<any> = []
        var ws_data_index = 0

        let primaryPropertyTitle = primaryProperty === 'email' ? 'Email' : 'CPF'

        // Coloca nomes das colunas
        ws_data[ws_data_index] = ['Nome', primaryPropertyTitle]

        // Auth Data
        if (reportConfig && reportConfig.authData) {
            let authDataKeys = Object.keys(reportConfig.authData)
            for (var i = 0; i < authDataKeys.length; i++) {
                let key = authDataKeys[i]
                let currentAuthData = reportConfig.authData[key]
                ws_data[ws_data_index].push(currentAuthData.title)
            }
        }

        // Auth Data Complement
        if (reportConfig && reportConfig.authDataComplementArray) {
            for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
                let authDataComplement = reportConfig.authDataComplementArray[n]
                ws_data[ws_data_index].push(authDataComplement.title)
            }
        }

        ws_data[ws_data_index].push('Data de conclusão')

        // Próxima linha
        ws_data_index++

        this.props.users.sort((a, b) => {
            if (a.username.toLowerCase() > b.username.toLowerCase()) return 1
            else if (a.username.toLowerCase() < b.username.toLowerCase()) return -1
            else return 0
        }).forEach(user => {
            let primaryPropertyValue = primaryProperty === 'email' ? user.email : user.doc
            const dateUserFinishedModuleInPeriod = this.state.dateUsersFinishedModuleInPeriod?.find(dateUserFinishedModuleInPeriod => dateUserFinishedModuleInPeriod.userId === user.id)
            if (!dateUserFinishedModuleInPeriod) return
            ws_data[ws_data_index] = [user.username, primaryPropertyValue]

            // Auth Data
            if (reportConfig && reportConfig.authData) {
                let authDataKeys = Object.keys(reportConfig.authData)
                for (var i = 0; i < authDataKeys.length; i++) {
                    let key = authDataKeys[i]
                    let currentAuthData = reportConfig.authData[key]
                    let userAuthData = user.authData ? (user.authData[currentAuthData.id] || '-') : '-'
                    ws_data[ws_data_index].push(userAuthData)
                }
            }

            // Auth Data Complement
            if (reportConfig && reportConfig.authDataComplementArray) {
                for (var n = 0; n < reportConfig.authDataComplementArray.length; n++) {
                    let authDataComplement = reportConfig.authDataComplementArray[n]
                    if (user.authData && user.authData[authDataComplement.authDataId]) {
                        let userAuthDataValue = user.authData[authDataComplement.authDataId]
                        ws_data[ws_data_index].push(authDataComplement.authDataComplementValues[userAuthDataValue] || '')
                    } else {
                        ws_data[ws_data_index].push('')
                    }
                }
            }

            let date = new Date(dateUserFinishedModuleInPeriod.finishedTime)
            let formattedDate = `${date.toLocaleDateString("pt-BR")} - ${date.toLocaleTimeString("pt-BR")}`
            ws_data[ws_data_index].push(formattedDate)

            // Próxima linha
            ws_data_index++
        })

        const reportName = `RelatórioDoMes${this.getTextForSelectedMonth(this.state.selectedMonth)}DoAno${this.props.year}`
        let fileExtension = '.xlsx';

        let ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(wb, ws, this.getTextForSelectedMonth(this.state.selectedMonth));
        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: fileType });
        saveAs(blob, reportName + fileExtension)
    }

    /*processData() {
        if(this.props.subModules.length === 0) return
        const lastSubModule = this.props.subModules.reduce((acc, subModule) => {
            if (subModule.pos > acc.pos) return subModule
            return acc
        }, this.props.subModules[0])
        const usersCompanyScoreUntilSelectedMonth = this.props.monthUsersCompanyScore.filter(monthUserCompanyScore => monthUserCompanyScore.month <= this.state.selectedMonth)
        const usersWhoPlayedId = this.props.monthUsersCompanyScore.reduce((acc, monthUserCompanyScore) => {
            if (!acc.includes(monthUserCompanyScore.userId)) acc.push(monthUserCompanyScore.userId)
            return acc
        }, new Array<string>())

        for (var userIndex = 0; userIndex < usersWhoPlayedId.length; userIndex++) {
            const currentUserId = usersWhoPlayedId[userIndex]
            const user = this.props.users.find(user => user.id === currentUserId)
            if (!user) continue
            const userCompanyScoreUntilSelectedMonth = usersCompanyScoreUntilSelectedMonth.filter(monthUserCompanyScore => monthUserCompanyScore.userId === currentUserId)
            const allModuleSubModulesPlayedByUserInPeriod = userCompanyScoreUntilSelectedMonth.reduce((acc, monthUserCompanyScore) => {
                const allSubModulesPlayedInMonth = Object.keys(monthUserCompanyScore.subModuleScores)
                allSubModulesPlayedInMonth.forEach(subModuleId => {
                    let subModule = this.props.subModules.find(subModule => subModule.id === subModuleId)
                    if(subModule) {
                        if(!acc.includes(subModuleId) && monthUserCompanyScore.subModuleScores[subModuleId] >= (subModule.maxScore / 2)) acc.push(subModuleId)
                    }
                })
                return acc
            }, [] as Array<string>)
            const finishedAllSubModules = allModuleSubModulesPlayedByUserInPeriod.length === this.props.subModules.length
            const userCompanyScoreInLastMonth = usersCompanyScoreUntilSelectedMonth.find(monthUserCompanyScore => monthUserCompanyScore.month == this.state.selectedMonth)
            const userPlayedLastSubModule = userCompanyScoreInLastMonth && userCompanyScoreInLastMonth.subModuleScores[lastSubModule.id] >= (lastSubModule.maxScore / 2)
        }

    }*/



    render() {

        return <Col className="d-flex mb-5 gap-2" md={{ size: 8, offset: 2 }}>
            <Button outline color='primary' disabled={this.state.isLoading} onClick={() => this.createAndDownloadReport()}>{this.state.isLoading ? 'Carregando...' : 'Baixar relatório de conclusão mensal'}</Button>
            <UncontrolledDropdown>
                <DropdownToggle caret>
                    {this.getTextForSelectedMonth(this.state.selectedMonth)}
                </DropdownToggle>
                <DropdownMenu>
                    {availableMonths(this.props.year).map((month, index) => {
                            return (<DropdownItem key={index} onClick={() => this.setState({ selectedMonth: index + 1 }, () => this.getData())} color='primary'>{month}</DropdownItem>)
                        })
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        </Col>
    }

}