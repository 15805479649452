import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'
import { PickImageCardProps } from './interfaces';
import { SelectAnswerCard } from '../../../../../models/DeckCards';
import FileHelper from '../../../../../files/FileHelper';
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../../../../constants/images';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import SelectImageComponent from '../SelectImageComponent';

enum SelectAnswerCardSelectedImgPick {
    Answer0 = 0,
    Answer1 = 1,
    Answer2 = 2,
    Answer3 = 3,
    Feedback = 4,
    Question = 5
}

interface SelectAnswerCardState {
    question: string
    questionImageUrl?: string
    answer0: string
    answer1: string
    answer2: string
    answer3: string
    imgAnswer0?: string
    imgAnswer1?: string
    imgAnswer2?: string
    imgAnswer3?: string
    selectedImgAnswerPick?: SelectAnswerCardSelectedImgPick
    correctAnswers: Array<number>
    feedbackText: string
    feedbackImageUrl?: string
    isLoading: boolean
}

const SELECT_ANSWER_CARD_INITIAL_STATE: SelectAnswerCardState = {
    question: '',
    answer0: '',
    answer1: '',
    answer2: '',
    answer3: '',
    selectedImgAnswerPick: undefined,
    correctAnswers: [],
    feedbackText: '',
    feedbackImageUrl: undefined,
    isLoading: false,
}

export default class EditSelectAnswerCardComponent extends Component<PickImageCardProps<SelectAnswerCard>, SelectAnswerCardState> {

    FileHelper = new FileHelper()

    constructor(props: PickImageCardProps<SelectAnswerCard>) {
        super(props)

        this.state = {
            ...SELECT_ANSWER_CARD_INITIAL_STATE,
            question: props.card.question,
            questionImageUrl: props.card.questionImageUrl,
            answer0: props.card.answer0,
            answer1: props.card.answer1,
            answer2: props.card.answer2 || '',
            answer3: props.card.answer3 || '',
            imgAnswer0: props.card.imgAnswer0,
            imgAnswer1: props.card.imgAnswer1,
            imgAnswer2: props.card.imgAnswer2,
            imgAnswer3: props.card.imgAnswer3,
            correctAnswers: props.card.correctAnswers,
            feedbackText: props.card.feedbackText || '',
            feedbackImageUrl: props.card.feedbackImageUrl
        }

    }

    editCard = (question: string, questionImageUrl: string | undefined, correctAnswers: Array<number>, answer0: string, answer1: string, answer2: string, answer3: string, imgAnswer0: string | undefined, imgAnswer1: string | undefined, imgAnswer2: string | undefined, imgAnswer3: string | undefined, feedbackText: string, feedbackImageUrl: string | undefined) => {
        let { id, type, pos, companyId, moduleId, subModuleId } = this.props.card
        let selectAnswerCardData: any = {
            id,
            type,
            pos,
            companyId,
            moduleId,
            subModuleId,
            question,
            correctAnswers,
            answer0,
            answer1,
        }
        if (questionImageUrl) selectAnswerCardData.questionImageUrl = questionImageUrl
        if (answer2.length > 0) selectAnswerCardData.answer2 = answer2
        if (answer3.length > 0) selectAnswerCardData.answer3 = answer3
        if (imgAnswer0) selectAnswerCardData.imgAnswer0 = imgAnswer0
        if (imgAnswer1) selectAnswerCardData.imgAnswer1 = imgAnswer1
        if (imgAnswer2) selectAnswerCardData.imgAnswer2 = imgAnswer2
        if (imgAnswer3) selectAnswerCardData.imgAnswer3 = imgAnswer3
        if (feedbackText.length > 0) selectAnswerCardData.feedbackText = feedbackText
        if (feedbackImageUrl) selectAnswerCardData.feedbackImageUrl = feedbackImageUrl
        let selectAnswerCard = new SelectAnswerCard(selectAnswerCardData)
        this.props.editCardInterface.editCard(selectAnswerCard)
    }

    isInputValid = (question: string, answer0: string, answer1: string, answer2: string, answer3: string, correctAnswers: Array<number>) => {
        if (question.length < 3) return false
        if (answer0.length === 0) return false
        if (answer1.length === 0) return false
        if (correctAnswers.length === 0) return false
        // verifica se o usuário selecionou uma opção mas não preencheu o texto
        for (var i = 0; i < correctAnswers.length; i++) {
            let answer = correctAnswers[i]
            if (answer === 0 && answer0.length === 0) return false
            if (answer === 1 && answer1.length === 0) return false
            if (answer === 2 && answer2.length === 0) return false
            if (answer === 3 && answer3.length === 0) return false
        }
        return true
    }

    onAnswerSelected(answer: number, selection: boolean, correctAnswers: Array<number>) {
        if (selection) {
            // adiciona a resposta
            // se ja tiver a resposta no array nao precisa
            if (_.includes(correctAnswers, answer)) return
            // concat cria um novo array com o elemento passado como parametro
            let newCorrectAnswers = correctAnswers.concat(answer)
            this.setState({ correctAnswers: newCorrectAnswers })
        } else {
            // remove a resposta
            // filter cria um novo array sem o elemento filtrado
            let newCorrectAnswers = correctAnswers.filter(arrayAnswer => answer !== arrayAnswer)
            this.setState({ correctAnswers: newCorrectAnswers })
        }
    }

    uploadFile = async (file: File | undefined) => {
        if (!file) return

        try {
            let imageUrl = await this.FileHelper.getFileDataUrl(file)
            let imageElement = await this.FileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await this.FileHelper.resizeImage(canvas, imageElement, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            this.props.cardImageGetUpload.uploadCardImage(resizedImageBlob, image => image && this.onImageSelected(image))
        } catch (error) {
            this.props.editCardInterface.onError(error)
        }
    }

    onImageSelected = (imageUrl: string) => {
        let selectedImgAnswerPick = this.state.selectedImgAnswerPick
        if (selectedImgAnswerPick === SelectAnswerCardSelectedImgPick.Answer0) {
            this.setState({ imgAnswer0: imageUrl, isLoading: false, selectedImgAnswerPick: undefined }, () => this.props.updateIsPickingFile(false))
        } else if (selectedImgAnswerPick === SelectAnswerCardSelectedImgPick.Answer1) {
            this.setState({ imgAnswer1: imageUrl, isLoading: false, selectedImgAnswerPick: undefined }, () => this.props.updateIsPickingFile(false))
        } else if (selectedImgAnswerPick === SelectAnswerCardSelectedImgPick.Answer2) {
            this.setState({ imgAnswer2: imageUrl, isLoading: false, selectedImgAnswerPick: undefined }, () => this.props.updateIsPickingFile(false))
        } else if (selectedImgAnswerPick === SelectAnswerCardSelectedImgPick.Answer3) {
            this.setState({ imgAnswer3: imageUrl, isLoading: false, selectedImgAnswerPick: undefined }, () => this.props.updateIsPickingFile(false))
        } else if (selectedImgAnswerPick === SelectAnswerCardSelectedImgPick.Feedback) {
            this.setState({ feedbackImageUrl: imageUrl, isLoading: false }, () => this.props.updateIsPickingFile(false))
        } else {
            this.setState({ questionImageUrl: imageUrl, isLoading: false }, () => this.props.updateIsPickingFile(false))
        }
    }

    renderQuestionImagePreview(image: string) {
        return (<div className="p-2 mb-3 rounded bg-light d-flex flex-column align-items-start">
            <div className="text-secondary mb-2">Imagem da Pergunta <i>(Imagem opcional que aparece com a pergunta)</i></div>
            <img alt='card' src={image} style={{ height: 'auto', width: 'auto', maxHeight: 200, objectFit: 'contain' }} />
            <Button className="mt-2 mb-2" color="danger" onClick={() => this.setState({ questionImageUrl: undefined })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faTrash} />Remover Imagem</Button>
        </div>)
    }

    renderSelectQuestionImageSection() {
        return (<div className="p-2 mb-3 rounded bg-light d-flex flex-column align-items-start" >
            <div className="text-secondary mb-2">Imagem da Pergunta <i>(Imagem opcional que aparece com a pergunta)</i></div>
            <Button style={{ marginLeft: 5, marginRight: 5, marginBottom: 15 }} color="secondary" onClick={() => this.setState({ selectedImgAnswerPick: SelectAnswerCardSelectedImgPick.Question }, () => this.props.updateIsPickingFile(true))}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faImage} />Selecionar Imagem</Button>
        </div>)
    }

    renderFeedbackImagePreview(image: string) {
        return (<div className="d-flex flex-column align-items-start">
            <Label style={{ marginLeft: 5 }} for="image_preview">Imagem de Explicação <i>(Imagem opcional que aparece se o usuário errar)</i></Label>
            <img id="image_preview" alt='card' src={image} style={{ height: 'auto', width: 'auto', maxHeight: 200, objectFit: 'contain' }} />
            <Button style={{ marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 15 }} color="danger" onClick={() => this.setState({ feedbackImageUrl: undefined })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faTrash} />Remover Imagem</Button>
        </div>)
    }

    renderSelectFeedbackImageSection() {
        return (<div className="d-flex flex-column align-items-start">
            <div className="text-secondary mb-2">Imagem de Explicação <i>(Imagem opcional que aparece se o usuário errar)</i></div>
            <Button color="secondary" onClick={() => this.setState({ selectedImgAnswerPick: SelectAnswerCardSelectedImgPick.Feedback }, () => this.props.updateIsPickingFile(true))}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faImage} />Selecionar Imagem</Button>
        </div>)
    }

    renderForm(question: string, questionImageUrl: string | undefined, answer0: string, answer1: string, answer2: string, answer3: string, imgAnswer0: string | undefined, imgAnswer1: string | undefined, imgAnswer2: string | undefined, imgAnswer3: string | undefined, correctAnswers: Array<number>, feedbackText: string, feedbackImageUrl?: string) {
        let imgAnswers = [imgAnswer0, imgAnswer1, imgAnswer2, imgAnswer3]
        let onAnswerImgClick = (imgIndex: SelectAnswerCardSelectedImgPick) => this.setState({ selectedImgAnswerPick: imgIndex }, () => this.props.updateIsPickingFile(true))
        let onRemoveImgClick = (imgIndex: number) => {
            if (imgIndex === 0) this.setState({ imgAnswer0: undefined })
            if (imgIndex === 1) this.setState({ imgAnswer1: undefined })
            if (imgIndex === 2) this.setState({ imgAnswer2: undefined })
            if (imgIndex === 3) this.setState({ imgAnswer3: undefined })
        }
        let renderSelectImageButton = (index: number) => <Button outline style={{ border: 'none', marginLeft: 10, paddingTop: 0, paddingBottom: 0, paddingRight: 5, paddingLeft: 5 }} onClick={() => onAnswerImgClick(index)}>
            <FontAwesomeIcon color='#1d3256' icon={faImage} size='1x' />
        </Button>
        let renderRemoveImageButton = (index: number) => <Button block outline style={{ textAlign: 'center', marginLeft: 10, paddingTop: 0, paddingBottom: 0, paddingRight: 5, paddingLeft: 5 }} onClick={() => onRemoveImgClick(index)}>
            <div className="d-flex align-items-center justify-content-center">
                <img alt='imagem da resposta' src={imgAnswers[index]} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em', marginRight: 5 }} />
                <FontAwesomeIcon icon={faTimes} size='1x' />
            </div>
        </Button>
        return (<Row>
            <Col className="mt-2 d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <Form style={{ margin: 5 }}>
                    <FormGroup>
                        <Label for="cardQuestion">Pergunta</Label>
                        <Input name="question" id="cardQuestion" placeholder="Pergunta do card" value={question} onChange={(event: any) => this.setState({ question: event.target.value })} />
                    </FormGroup>
                    {!questionImageUrl && this.renderSelectQuestionImageSection()}
                    {questionImageUrl && this.renderQuestionImagePreview(questionImageUrl)}
                    <FormGroup>
                        <InputGroup size="lg">
                            <InputGroupText>
                                <Input addon type="checkbox" aria-label="Checkbox for following text input" checked={_.includes(correctAnswers, 0)}
                                    onChange={e => this.onAnswerSelected(0, e.target.checked, correctAnswers)} />
                                {imgAnswers[0] ? renderRemoveImageButton(0) : renderSelectImageButton(SelectAnswerCardSelectedImgPick.Answer0)}
                            </InputGroupText>
                            <Input name="ans0" id="answer0" placeholder="Opção 1" value={answer0} onChange={(event: any) => this.setState({ answer0: event.target.value })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup size="lg">
                            <InputGroupText>
                                <Input addon type="checkbox" aria-label="Checkbox for following text input" checked={_.includes(correctAnswers, 1)}
                                    onChange={e => this.onAnswerSelected(1, e.target.checked, correctAnswers)} />
                                {imgAnswers[1] ? renderRemoveImageButton(1) : renderSelectImageButton(SelectAnswerCardSelectedImgPick.Answer1)}
                            </InputGroupText>
                            <Input name="ans1" id="answer1" placeholder="Opção 2" value={answer1} onChange={(event: any) => this.setState({ answer1: event.target.value })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup size="lg">
                            <InputGroupText>
                                <Input addon type="checkbox" aria-label="Checkbox for following text input" checked={_.includes(correctAnswers, 2)}
                                    onChange={e => this.onAnswerSelected(2, e.target.checked, correctAnswers)} />
                                {imgAnswers[2] ? renderRemoveImageButton(2) : renderSelectImageButton(SelectAnswerCardSelectedImgPick.Answer2)}
                            </InputGroupText>
                            <Input name="ans2" id="answer2" placeholder="Opção 3" value={answer2} onChange={(event: any) => this.setState({ answer2: event.target.value })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup size="lg">
                            <InputGroupText>
                                <Input addon type="checkbox" aria-label="Checkbox for following text input" checked={_.includes(correctAnswers, 3)}
                                    onChange={e => this.onAnswerSelected(3, e.target.checked, correctAnswers)} />
                                {imgAnswers[3] ? renderRemoveImageButton(3) : renderSelectImageButton(SelectAnswerCardSelectedImgPick.Answer3)}
                            </InputGroupText>
                            <Input name="ans3" id="answer3" placeholder="Opção 4" value={answer3} onChange={(event: any) => this.setState({ answer3: event.target.value })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="p-2 rounded bg-light">
                        <Label for="explanation">Texto de Explicação <i>(Texto opcional que aparece se o usuário errar)</i></Label>
                        <Input name="explanation" id="explanation" value={feedbackText} onChange={(event: any) => this.setState({ feedbackText: event.target.value })} />
                        {!feedbackImageUrl && this.renderSelectFeedbackImageSection()}
                        {feedbackImageUrl && this.renderFeedbackImagePreview(feedbackImageUrl)}
                    </FormGroup>
                </Form>
                <Button style={{ margin: 5 }} disabled={!this.isInputValid(question, answer0, answer1, answer2, answer3, correctAnswers)} color="primary" onClick={() => this.editCard(question, questionImageUrl, correctAnswers, answer0, answer1, answer2, answer3, imgAnswer0, imgAnswer1, imgAnswer2, imgAnswer3, feedbackText, feedbackImageUrl)}>Editar</Button>
            </Col>
        </Row>)
    }

    render() {
        let { question, questionImageUrl, answer0, answer1, answer2, answer3, imgAnswer0, imgAnswer1, imgAnswer2, imgAnswer3, correctAnswers, feedbackText, feedbackImageUrl } = this.state
        if (!this.props.isPickingFile) return this.renderForm(question, questionImageUrl, answer0, answer1, answer2, answer3, imgAnswer0, imgAnswer1, imgAnswer2, imgAnswer3, correctAnswers, feedbackText, feedbackImageUrl)
        else return (<SelectImageComponent getCardImages={this.props.cardImageGetUpload.getCardImages} uploadFile={this.uploadFile} onImageSelected={this.onImageSelected} goBackToEdit={() => this.props.updateIsPickingFile(false)} />)
    }

}