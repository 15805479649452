import React, { Component } from 'react';
import { Alert, Container, Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

import LoadingScreen from '../../loading'
import Company from '../../../models/Company';
import config from '../../../config'

import User from '../../../models/User';
import ContentService from '../../../services/contentService'
import TokenHelper from '../../../auth/TokenHelper';
import { Frequency } from '../../../models/Module';
import QuizQuestion from '../../../models/QuizQuestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import GameComponent from './GameComponent';
import ScoreService from '../../../services/scoreService';
import { QuizQuestionsReport } from '../../../models/QuizQuestionsReport';

interface State {
    error: string | undefined;
    isLoading: boolean;
    isFinished: boolean
    retry: (() => void) | undefined
    modalMessage: string | undefined
    quizQuestions: Array<QuizQuestion> | undefined
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    isFinished: false,
    retry: undefined,
    modalMessage: undefined,
    quizQuestions: undefined
};

interface Props { 
    quizFinished:() => void, 
    userScoreChanged: ((newScore: number) => void), 
    onExit: () => void,
    subModuleId: string, 
    user: User,
    company: Company
}

export default class RealTimeQuizBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        this.loadGameData(this.props.subModuleId)
    }

    async loadGameData(subModuleId: string) {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let quizQuestions = await contentService.getQuizQuestions(token, config.endpoint, subModuleId)
            this.setState({ quizQuestions, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadGameData(subModuleId)
            } else {
                // Meu Deus que coisa horrível
                if (error.response && error.response.data && error.response.data.error && error.response.data.error.code === 'Max_Attempts_Exceeded') {
                    let limiterQuiz = error.response.data.attempts && error.response.data.attempts.limiterQuiz
                    let frequency = error.response.data.attempts && error.response.data.attempts.frequency
                    var modalLimitText = 'Você já atingiu o limite de vezes que pode jogar o Quiz no período.'
                    if (frequency === Frequency.Day) {
                        modalLimitText = `Você já jogou o Quiz ${limiterQuiz} vezes hoje. Amanhã você pode jogar novamente.`
                    } else if (frequency === Frequency.Week) {
                        modalLimitText = `Você já jogou o Quiz ${limiterQuiz} vezes essa semana. Semana que vem você pode jogar novamente.`
                    } else if (frequency === Frequency.Month) {
                        modalLimitText = `Você já jogou o Quiz ${limiterQuiz} vezes esse mês. Mês que vem você pode jogar novamente.`
                    }
                    this.setState({ isLoading: false, modalMessage: modalLimitText })
                } else {
                    this.setState({ isLoading: false, error: error.toString() })
                }
            }
        }
    }

    onFinishQuiz = async (startTime: number, endTime: number, quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let scoreService = new ScoreService()
            await scoreService.saveUserQuizScore(token, config.endpoint, startTime, endTime, quizQuestionsReport, this.props.subModuleId)
            this.props.quizFinished()
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.onFinishQuiz(startTime, endTime, quizQuestionsReport)
            } else {
                this.setState({ isLoading: false, isFinished: true, error: error.toString(), retry: () => { this.onFinishQuiz(startTime, endTime, quizQuestionsReport) } })
            }
        }
    }

    renderErrorAndRetry(error: string, retry: () => void) {
        return (<Container className='d-flex flex-column' fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                    <div style={{ alignSelf: 'center', marginTop: 10 }}>
                        <Button color='none' outline onClick={() => { retry() }}><FontAwesomeIcon color='#343a40' icon={faRedo} size='2x' />Tentar Novamente</Button>
                    </div>
                </Col>
            </Row>
        </Container>)
    }

    renderError(error: string) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                    {error}
                </Alert>
            </Col>
        </Row>
        );
    }

    renderModal(message: string) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => this.props.onExit()}>
                    <ModalBody>
                        <div style={{ fontFamily: 'Montserrat' }}>{message}</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.props.onExit()}>OK</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    render() {

        let { error, isLoading, quizQuestions, retry, modalMessage } = this.state

        return (<div>
            {error && this.renderError(error)}
            {modalMessage && this.renderModal(modalMessage)}
            {isLoading && <LoadingScreen image={this.props.company.pic} />} 
            {!isLoading && error && retry && this.renderErrorAndRetry(error, retry)}
            {!isLoading && quizQuestions && <GameComponent company={this.props.company} userScoreChanged={this.props.userScoreChanged} subModuleId={this.props.subModuleId} onExit={this.props.onExit} onFinish={this.onFinishQuiz} quizQuestions={quizQuestions} />}
        </div>)
    }

}