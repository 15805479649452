export default class QuizQuestion {
    id: string
    companyId: string
    moduleId: string
    subModuleId: string
    type: string

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
        this.type = data.type
    }

    getData(): any {
        return {
            id: this.id,
            companyId: this.companyId,
            moduleId: this.moduleId,
            subModuleId: this.subModuleId,
            type: this.type
        }
    }
}

export class DefaultQuestion extends QuizQuestion {
    question: string
    questionImageUrl?: string
    answer0: string
    answer1: string
    answer2?: string
    answer3?: string
    imgAnswer0?: string
    imgAnswer1?: string
    imgAnswer2?: string
    imgAnswer3?: string
    correctAnswer: number
    answerTime?: number
    constructor(data: any) {
        super(data)
        this.question = data.question
        this.questionImageUrl = data.questionImageUrl
        this.answer0 = data.answer0
        this.answer1 = data.answer1
        this.answer2 = data.answer2
        this.answer3 = data.answer3
        this.imgAnswer0 = data.imgAnswer0
        this.imgAnswer1 = data.imgAnswer1
        this.imgAnswer2 = data.imgAnswer2
        this.imgAnswer3 = data.imgAnswer3
        this.correctAnswer = data.correctAnswer
        this.answerTime = data.answerTime
    }

    getData(): any {
        let result: any = {
            ...super.getData(),
            question: this.question,
            answer0: this.answer0,
            answer1: this.answer1,
            correctAnswer: this.correctAnswer
        }
        if(this.questionImageUrl) result.questionImageUrl = this.questionImageUrl
        if(this.answer2) result.answer2 = this.answer2
        if(this.answer3) result.answer3 = this.answer3
        if(this.imgAnswer0) result.imgAnswer0 = this.imgAnswer0
        if(this.imgAnswer1) result.imgAnswer1 = this.imgAnswer1
        if(this.imgAnswer2) result.imgAnswer2 = this.imgAnswer2
        if(this.imgAnswer3) result.imgAnswer3 = this.imgAnswer3
        if(this.answerTime) result.answerTime = this.answerTime
        return result
    }
}

export class MemoryQuestion extends QuizQuestion {

    title?: string
    images: Array<{ imgUrl: string, text: string }>

    constructor(data: any) {
        super(data)
        this.title = data.title
        this.images = data.images
    }

    getData(): any {
        let data: any = { ...super.getData(), images: this.images }
        if(this.title) data.title = this.title
        return data
    }
}

export class WordGuessQuestion extends QuizQuestion {

    title: string
    word: string

    constructor(data: any) {
        super(data)
        this.title = data.title
        this.word = data.word
    }

    getData(): any {
        let data: any = { ...super.getData(), title: this.title, word: this.word }
        return data
    }
}

export class SelectAnswerQuestion extends QuizQuestion {

    question: string
    questionImageUrl?: string
    answer0: string
    answer1: string
    answer2?: string
    answer3?: string
    imgAnswer0?: string
    imgAnswer1?: string
    imgAnswer2?: string
    imgAnswer3?: string
    correctAnswers: Array<number>
    answerTime?: number
    constructor(data: any) {
        super(data)
        this.question = data.question
        this.questionImageUrl = data.questionImageUrl
        this.answer0 = data.answer0
        this.answer1 = data.answer1
        this.answer2 = data.answer2
        this.answer3 = data.answer3
        this.imgAnswer0 = data.imgAnswer0
        this.imgAnswer1 = data.imgAnswer1
        this.imgAnswer2 = data.imgAnswer2
        this.imgAnswer3 = data.imgAnswer3
        this.correctAnswers = data.correctAnswers
        this.answerTime = data.answerTime
    }

    getData(): any {
        let data = {
            ...super.getData(),
            question: this.question,
            answer0: this.answer0,
            answer1: this.answer1,
            correctAnswers: this.correctAnswers
        }
        if(this.questionImageUrl) data.questionImageUrl = this.questionImageUrl
        if(this.answer2) data.answer2 = this.answer2
        if(this.answer3) data.answer3 = this.answer3
        if(this.imgAnswer0) data.imgAnswer0 = this.imgAnswer0
        if(this.imgAnswer1) data.imgAnswer1 = this.imgAnswer1
        if(this.imgAnswer2) data.imgAnswer2 = this.imgAnswer2
        if(this.imgAnswer3) data.imgAnswer3 = this.imgAnswer3
        if(this.answerTime) data.answerTime = this.answerTime
        return data
    }

}

export function getQuizQuestion(data: any): QuizQuestion {
    if(data.type === QuizQuestionType.Default) {
        return new DefaultQuestion(data)
    } else if(data.type === QuizQuestionType.MemoryGame) {
        return new MemoryQuestion(data)
    } else if(data.type === QuizQuestionType.WordGuess) {
        return new WordGuessQuestion(data)
    } else if(data.type === QuizQuestionType.SelectAnswer) {
        return new SelectAnswerQuestion(data)
    } else {
        throw `Unknown card type: ${data.type}`
    }
}

export const QuizQuestionType = {
    Default: 'default',
    MemoryGame: 'memory',
    SelectAnswer: 'select-answer',
    WordGuess: 'word-guess'
}