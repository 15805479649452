import React, { Component } from 'react';
import { Navigate, BrowserRouter, Routes, useNavigate, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

// Landing
import LandingScreen from './screens/landing'

// App
import EnterScreen from './screens/enter'
import SignUpScreen from './screens/signup'
import LoginScreen from './screens/login'
import MainScreen from './screens/main'
import SubModuleSelectionScreen from './screens/main/SubModuleSelectionScreen'
import GameScreen from './screens/game'
import RealTimeGameScreen from './screens/game/realtime'
import GameResultScreen from './screens/game/GameResultScreen'
import ModuleRankingSelectionScreen from './screens/ranking/ModuleRankingSelectionScreen'
import ModuleRankingScreen from './screens/ranking/ModuleRankingScreen'
import ForumScreen from './screens/forum'
import CompanyRankingSelectionScreen from './screens/ranking/CompanyRankingSelectionScreen'
import CompanyRankingScreen from './screens/ranking/CompanyRankingScreen'
import SelectCompanyScreen from './screens/main/SelectCompanyScreen'
import InsertNewPasswordScreen from './screens/recoverpwd/InsertNewPasswordScreen'
import HelpScreen from './screens/help'
import ProfileScreen from './screens/profile'
import UseTermsScreen from './screens/terms/useTerms'
import PrivacyPolicySrceen from './screens/terms/privacyPolicy'
import ProfilePicScreen from './screens/signup/profilePic'
import DevScreen from './screens/dev/index'

// Admin
import AdminScreen from './screens/admin/index'
import UploadCredentialsScreen from './screens/admin/credentials/UploadCredentialsScreen'
import UsageControlScreen from './screens/admin/dashboard/UsageReport/UsageControlScreen'
import ChartsScreen from './screens/admin/dashboard/ChartsScreen'
import FormsScreen from './screens/admin/forms'
import SelectModuleScreen from './screens/admin/content/SelectModuleScreen'
import SelectSubModuleScreen from './screens/admin/content/SelectSubModuleScreen'
import CreateModuleScreen from './screens/admin/content/CreateModuleScreen'
import EditModuleScreen from './screens/admin/content/EditModuleScreen'
import ModuleContentReportScreen from './screens/admin/content/ModuleContentReportScreen'
import CardsScreen from './screens/admin/content/cards'
import CreateSubModuleScreen from './screens/admin/content/CreateSubModuleScreen'
import ActivitiesScreen from './screens/admin/ActivitiesScreen'
import UserAccessMainScreen from './screens/admin/credentials/UserAccessMainScreen'
import SmsPanelScreen from './screens/admin/SmsPanelScreen'
import AdminProfileScreen from './screens/admin/profile'
import SmsResponsePanelScreen from './screens/admin/SmsResponsePanelScreen'
import ConfigScreen from './screens/admin/config'

// Test
//import VideoScreenTest from './screens/game/VideoScreenTest'


import * as ROUTES from './constants/routes'

let appRoutes = [
  { path: `${ROUTES.APP_LANDING}/dev/:projectId`, component: <DevScreen /> },
  { path: `${ROUTES.APP_LANDING}`, component: <SelectCompanyScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ENTER}/:companyId`, component: <EnterScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.NEW_PWD}/:companyId/:token`, component: <InsertNewPasswordScreen /> },
  { path: `/:companyId${ROUTES.SIGN_UP}`, component: <SignUpScreen /> },
  { path: `/:companyId${ROUTES.PROFILE_PIC}`, component: <ProfilePicScreen /> },
  { path: `/:companyId${ROUTES.LOGIN}`, component: <LoginScreen /> },
  { path: `/:companyId${ROUTES.MAIN}`, component: <MainScreen /> },
  { path: `/:companyId${ROUTES.SUBMODULE_SELECT}`, component: <SubModuleSelectionScreen /> },
  { path: `/:companyId${ROUTES.GAME}`, component: <GameScreen /> },
  { path: `/:companyId${ROUTES.REALTIME_GAME}`, component: <RealTimeGameScreen /> },
  { path: `/:companyId${ROUTES.RESULT}`, component: <GameResultScreen /> },
  { path: `/:companyId${ROUTES.SELECT_MODULE_RANKING}/:moduleId`, component: <ModuleRankingSelectionScreen /> },
  { path: `/:companyId${ROUTES.MODULE_RANKING}/:moduleId`, component: <ModuleRankingScreen /> },
  { path: `/:companyId${ROUTES.FORUM}/:moduleId`, component: <ForumScreen /> },
  { path: `/:companyId${ROUTES.SELECT_COMPANY_RANKING}`, component: <CompanyRankingSelectionScreen /> },
  { path: `/:companyId${ROUTES.COMPANY_RANKING}`, component: <CompanyRankingScreen /> },
  { path: `/:companyId${ROUTES.HELP}`, component: <HelpScreen /> },
  { path: `/:companyId${ROUTES.PROFILE}`, component: <ProfileScreen /> },
  { path: `/:companyId${ROUTES.USE_TERMS}`, component: <UseTermsScreen /> },
  { path: `/:companyId${ROUTES.PRIVACY_POLICY}`, component: <PrivacyPolicySrceen />},
]

let adminRoutes = [
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}`, component: <AdminScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.USAGE_CONTROL}`, component: <UsageControlScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.USAGE_CHARTS}`, component: <ChartsScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.UPLOAD_CREDENTIALS}`, component: <UploadCredentialsScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SELECT_MODULE}`, component: <SelectModuleScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.MODULE_CONTENT_REPORT}/:moduleId`, component: <ModuleContentReportScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.CREATE_MODULE}`, component: <CreateModuleScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.EDIT_MODULE}/:moduleId`, component: <EditModuleScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SUBMODULE_SELECT}/:moduleId`, component: <SelectSubModuleScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.CREATE_SUBMODULE}/:moduleId`, component: <CreateSubModuleScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.CARDS}/:subModuleId`, component: <CardsScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.ACTIVITIES}/:userId`, component: <ActivitiesScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.USER_ACCESS_MAIN}`, component: <UserAccessMainScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SMS_PANEL}`, component: <SmsPanelScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SMS_RESPONSE_PANEL}`, component: <SmsResponsePanelScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.PROFILE}/:userId`, component: <AdminProfileScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.FORMS}`, component: <FormsScreen /> },
  { path: `${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.CONFIG}`, component: <ConfigScreen /> },
]

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.LANDING} element={<LandingScreen />} />
          {appRoutes.map(({ path, component }) => <Route key={path} path={path} element={component} />)}
          {adminRoutes.map(({ path, component }) => <Route key={path} path={path} element={component} />)}
        </Routes>
      </BrowserRouter>
    )
  }
}


export default App;
