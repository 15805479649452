import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage, Layer, Text, Image } from 'react-konva'
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../../../../constants/images';
import FileHelper from '../../../../../files/FileHelper';
import { DeckCardTypes, IAttribution, ImageErrorsCard } from '../../../../../models/DeckCards';
import LoadingScreen from '../../../../loading';
import SelectImageComponent from '../SelectImageComponent';
import { PickImageCardProps } from './interfaces';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import getImageDimensions from '../../../../../utils/getImageDimensions';

interface URLImageProps {
    src: string,
    width: number,
    height: number,
}

class URLImage extends React.Component<URLImageProps> {

    state = {
        image: new window.Image()
    };

    componentDidMount() {
        const image = new window.Image();
        image.src = this.props.src
        image.onload = () => {
            // setState will redraw layer
            // because "image" property is changed
            this.setState({
                image: image
            });
        };
    }

    render() {
        return (
            <Image
                image={this.state.image}
                width={this.props.width}
                height={this.props.height}
            />
        );
    }
}

interface ImageErrorsCardState {
    errors: Array<{ x: number, y: number }>
    title: string;
    imageUrl: string | undefined;
    isLoading: boolean;
    screenWidth: number;
    screenHeight: number;
    imageWidth: number;
    imageHeight: number;
    attribution?: IAttribution;
}

const IMAGE_ERRORS_CARD_INITIAL_STATE: ImageErrorsCardState = {
    errors: [],
    title: '',
    imageUrl: undefined,
    isLoading: false,
    screenWidth: 0,
    screenHeight: 0,
    imageWidth: 0,
    imageHeight: 0,
    attribution: undefined,
}

export default class CreateImageErrorsCardComponent extends Component<PickImageCardProps, ImageErrorsCardState> {

    FileHelper = new FileHelper()

    constructor(props: PickImageCardProps) {
        super(props)

        this.state = { ...IMAGE_ERRORS_CARD_INITIAL_STATE }

    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        this.updateWindowDimensions()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth, screenHeight: window.innerHeight });
    }

    createCard = (title: string, imageUrl: string, errors: Array<{ x: number, y: number }>) => {
        let { subModule, lastPosition } = this.props
        let imageErrorsCard = new ImageErrorsCard({
            id: subModule.id + '_' + lastPosition.toString(),
            type: DeckCardTypes.ImageErrors,
            pos: lastPosition,
            companyId: subModule.companyId,
            moduleId: subModule.moduleId,
            subModuleId: subModule.id,
            title,
            imageUrl,
            errors
        })
        if(this.state.attribution) imageErrorsCard.attribution = this.state.attribution
        this.props.createCard.addCard(imageErrorsCard)
    }

    uploadImage = async (file: File | undefined) => {
        if (!file) return

        try {
            let imageUrl = await this.FileHelper.getFileDataUrl(file)
            let imageElement = await this.FileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await this.FileHelper.resizeImage(canvas, imageElement, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            this.props.cardImageGetUpload.uploadCardImage(resizedImageBlob, image => image && this.onImageSelected(image))
        } catch (error) {
            this.props.createCard.onError(error)
        }
    }

    onImageSelected = (imageUrl: string) => {
        getImageDimensions((width, height) => this.setState({ imageWidth: width, imageHeight: height, isLoading: false, imageUrl, attribution: undefined }, () => this.props.updateIsPickingFile(false)), imageUrl)
    }

    onPromptImageSelected = (imageUrl: string, attribution: IAttribution) => {
        getImageDimensions((width, height) => this.setState({ imageWidth: width, imageHeight: height, isLoading: false, imageUrl, attribution }, () => this.props.updateIsPickingFile(false)), imageUrl)
    }

    renderImageForErrorSet(imageUrl: string, errors: Array<{ x: number, y: number }>, screenWidth: number, screenHeight: number, imageWidth: number, imageHeight: number) {

        let ri = imageWidth / imageHeight
        let rs = screenWidth / screenHeight

        let scale = rs > ri ? screenHeight / imageHeight : screenWidth / imageWidth

        let width = imageWidth * scale, height = imageHeight * scale

        let fontMultiplier = width > 768 ? 2 : 1

        const onMouseDown = (evt: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {

            let stage = evt.target.getStage()
            if (!stage) return
            let pos = stage.getPointerPosition();
            if (!pos) return

            let x = pos.x / scale
            let y = pos.y / scale

            if (errors.length === 0) return this.setState({ errors: [{ x, y }] })

            let diagonalTreshold = width > 768 ? 30 : 15
            let diagonal = Math.sqrt(width * width + height * height)

            for (var i = 0; i < errors.length; i++) {
                let currentError = errors[i]
                let currentDist = Math.sqrt(Math.pow(x - currentError.x, 2) + Math.pow(y - currentError.y, 2))
                if (currentDist < (diagonal / diagonalTreshold)) return this.setState({
                    errors: [...errors.filter(error => {
                        return error.x !== currentError.x || error.y !== currentError.y
                    })]
                })
            }

            var currentError = errors[0]
            var currentDist = Math.sqrt(Math.pow(x - currentError.x, 2) + Math.pow(y - currentError.y, 2))
            for (var i = 1; i < errors.length; i++) {
                let dist = Math.sqrt(Math.pow(x - errors[i].x, 2) + Math.pow(y - errors[i].y, 2))
                if (dist < currentDist) {
                    currentDist = dist
                    currentError = errors[i]
                }
            }

            this.setState({ errors: [...errors, { x, y }] })
        }

        return <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <Stage width={width} height={height}>
                    <Layer onMouseDown={onMouseDown} onTouchStart={onMouseDown}>
                        <URLImage src={imageUrl} width={width} height={height} />
                        {errors.map(error => {return { x: error.x * scale, y: error.y * scale }}).map((error) => (
                            <Text text="X" width={20 * fontMultiplier} height={20 * fontMultiplier} x={error.x - (10 * fontMultiplier)} y={error.y - (10 * fontMultiplier)} fontSize={20 * fontMultiplier} 
                                fontStyle="bold" fill="red" align='center' verticalAlign='middle' stroke='black' />
                        ))}
                    </Layer>
                </Stage>
            </div>
        </Col>
    }

    renderSelectImageSection() {

        return <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
            <div className="p-2 mb-3 rounded bg-light d-flex flex-column align-items-start" >
                <div className="text-secondary mb-2">Imagem dos Erros</div>
                <Button style={{ marginBottom: 15 }} color="secondary" onClick={() => this.props.updateIsPickingFile(true)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faImage} />Selecionar Imagem</Button>
            </div>
        </Col>
    }

    renderForm(title: string, errors: Array<{ x: number, y: number }>, imageUrl: string | undefined, screenWidth: number, screenHeight: number, imageWidth: number, imageHeight: number) {
        return (<Fragment>
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <Form style={{ margin: 5 }}>
                        <FormGroup>
                            <Label for="cardText">Título do Jogo</Label>
                            <Input value={title} name="title" id="cardTitle" placeholder="Título" onChange={(event: any) => this.setState({ title: event.target.value })} />
                        </FormGroup>
                    </Form>
                </Col>
                {imageUrl ? this.renderImageForErrorSet(imageUrl, errors, screenWidth, screenHeight, imageWidth, imageHeight) : this.renderSelectImageSection()}
            </Row>
            <Row>
                <Col className="d-flex flex-column justify-content-center mt-1 mb-1" md={{ size: 6, offset: 3 }}>
                    {errors.length < 3 && <div style={{ fontSize: 'small', marginLeft: 5 }}><FontAwesomeIcon icon={faExclamationCircle} /> A imagem precisa ter ao menos 3 erros.</div>}
                    <Button style={{ marginLeft: 5, marginRight: 5, marginBottom: 5 }} disabled={errors.length < 2 && !imageUrl} color="primary" onClick={() => this.createCard(title, imageUrl!, errors)}>Adicionar</Button>
                </Col>
            </Row>
        </Fragment>)
    }

    render() {

        let { isLoading, imageUrl, title, errors, screenWidth, screenHeight, imageWidth, imageHeight } = this.state
        if (isLoading) { return <LoadingScreen /> }
        if (!this.props.isPickingFile) return this.renderForm(title, errors, imageUrl, screenWidth, screenHeight, imageWidth, imageHeight)
        else return (<SelectImageComponent getCardImages={this.props.cardImageGetUpload.getCardImages} uploadFile={this.uploadImage} onImageSelected={this.onImageSelected} onPromptImageSelected={this.onPromptImageSelected} goBackToEdit={() => this.props.updateIsPickingFile(false)} />)

    }

}
