import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Alert, Progress } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import * as ROUTES from '../../constants/routes'
import LoadingScreen from '../loading'
import Company from '../../models/Company';
import config from '../../config'
import TokenHelper from '../../auth/TokenHelper';
import AdminService from '../../services/adminService';
import User from '../../models/User';
import { SubModuleFinishedReport, DeckSubModuleFinishedReport } from '../../models/SubModuleFinishedReport';
import { SubModule, SubModuleTypes } from '../../models/SubModule';
import Module from '../../models/Module';
import { faUserCircle, faTimes, faListOl } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeckCardTypes } from '../../models/DeckCards';
import { POINTS_PER_ANSWER_DECK } from '../../constants/ScoreConstants';
import { useUser } from '../../hooks/useUser';

interface State {
    lastReports: Array<SubModuleFinishedReport>,
    companyModules: Array<Module>,
    companySubModules: Array<SubModule>
    maxScoreInModules: { [moduleId: string]: { [subModuleId: string]: number } }
    user: User | undefined
};

const INITIAL_STATE: State = {
    lastReports: [],
    companyModules: [],
    companySubModules: [],
    maxScoreInModules: {},
    user: undefined
};

const ActivitiesScreen = () => {

    const navigate = useNavigate();
    const [loadingError, setLoadingError] = React.useState<{ isLoading: boolean, error?: string }>({ isLoading: false })
    const [state, setState] = React.useState<State>(INITIAL_STATE)
    const { company } = useUser()
    const { userId } = useParams()

    useEffect(() => {
        if(company && userId) {
            loadActivities(company.id, userId)
        }
    }, [company, userId])

    const loadActivities = async (companyId: string, userId: string) => {
        setLoadingError({ isLoading: true })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setLoadingError({ isLoading: false, error: 'Usuário não possui token de acesso.' })
            let adminService = new AdminService()
            let { user, lastReports, companyModules, companySubModules, maxScoreInModules } = await adminService.getLastReports(token, config.endpoint, companyId, userId)
            setState({ user, lastReports, companyModules, companySubModules, maxScoreInModules })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadActivities(companyId, userId)
            } else {
                setLoadingError({ isLoading: false, error: error.toString() })
            }
        }
    }

    const goToCompanyRanking = (companyId: string) => {
        navigate(`/${companyId}${ROUTES.SELECT_COMPANY_RANKING}`)
    }

    const getMaxScore = (subModule: SubModule, finishedReport: SubModuleFinishedReport, maxScoreInQuizzes: { [id: string]: number }) => {
        let subModuleType = subModule.type
        if (subModuleType === SubModuleTypes.DECK) {
            let deckSubModuleReport = finishedReport as DeckSubModuleFinishedReport
            let maxScore = deckSubModuleReport.deckCardsReport.filter(deckCardReport => deckCardReport.card.type === DeckCardTypes.Question || deckCardReport.card.type === DeckCardTypes.WordSearch).length * POINTS_PER_ANSWER_DECK
            return maxScore
        } else if (subModuleType === SubModuleTypes.QUIZ) {
            return maxScoreInQuizzes[subModule.id] || 1
        } else if (subModuleType === SubModuleTypes.VIDEO) {
            // TODO: max score in videos
            return 1
        } else {
            return 1
        }
    }

    const renderError = (error: string) => {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => setLoadingError({ error: undefined, isLoading: false })}>
                        {error}
                    </Alert>
                </Col>
            </Row>

        );
    }

    const renderUserProfilePic = (user: User) => {
        if (!user.pic) return (<div><FontAwesomeIcon color='#1d3256' icon={faUserCircle} size='4x' /></div>)
        if (user.thumbnail) return <img style={{ width: '4em', height: '4em', borderRadius: '2em' }} alt='prof pic' src={user.thumbnail} />
        else return <img style={{ width: '4em', height: '4em', borderRadius: '2em' }} alt='prof pic' src={user.pic} />
    }

    const renderHeader = (user: User) => {
        return (
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        {renderUserProfilePic(user)}
                    </div>
                    <div className="d-flex justify-content-center">
                        {user.username}
                    </div>
                    <div style={{ position: 'absolute', alignSelf: 'center', top: 10, left: 10 }}>
                        <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                    </div>
                </Col>
            </Row>)
    }

    const renderBottomNavBar = (company: Company) => {

        return (<Row style={{ marginTop: 5, marginBottom: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ width: '100%' }}>
                    <Button style={{ padding: 5, margin: 5, width: '100%', background: '#343a40', boxShadow: '0 5px 9px 0 rgba(0,0,0,0.4)' }} onClick={() => { goToCompanyRanking(company.id) }}><FontAwesomeIcon icon={faListOl} />{` Ranking ${company.name}`}</Button>
                </div>
            </Col>
        </Row>)
    }

    const renderReport = (report: SubModuleFinishedReport, company: Company, companyModules: Array<Module>, companySubModules: Array<SubModule>, maxScoreInModules: { [moduleId: string]: { [subModuleId: string]: number } }) => {
        let module = _.head(companyModules.filter(module => module.id === report.moduleId))
        let subModule = _.head(companySubModules.filter(subModule => subModule.id === report.subModuleId))
        let moduleName = module ? module.title : ''
        let subModuleName = subModule ? subModule.title : ''
        let maxScorePossible = module && maxScoreInModules[module.id] && maxScoreInModules[module.id] && subModule && maxScoreInModules[module.id][subModule.id]
        let reportDate = new Date(report.date_in_millis)
        return (<div key={report.userId + report.date_in_millis} className="d-flex flex-column" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', marginBottom: 5, marginTop: 5, minHeight: 150 }}>
                <div style={{ marginLeft: 10, marginRight: 10, marginTop: 10, color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'start', fontSize: 14 }}>{`Jogou ${moduleName}`}</div>
                <div className="d-flex justify-content-start align-items-center" style={{ margin: 5 }}>
                    <img alt='foto do módulo' style={{ height: 50, width: 50, minWidth: 50, borderRadius: 5, margin: 5 }} src={subModule ? subModule.pic : company.pic} />
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'start', fontSize: 12 }}>{subModuleName}</div>
                </div>
                <div style={{ height: 1, background: '#1d3256', marginLeft: 5, margin: 5 }} />
                <Progress animated color='warning' style={{ marginLeft: 5, marginRight: 5, marginBottom: 5 }}  value={report.score} max={maxScorePossible || 0}>{`Pontuação: ${report.score} de ${maxScorePossible || 0}`}</Progress>
                <div style={{ marginLeft: 5, color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'start', fontSize: 12 }}>{`${reportDate.toLocaleDateString()} - ${reportDate.toLocaleTimeString()}`}</div>
            </div>)
    }

    const renderActivities = (company: Company, companyModules: Array<Module>, companySubModules: Array<SubModule>, lastReports: Array<SubModuleFinishedReport>, maxScoreInModules: { [moduleId: string]: { [subModuleId: string]: number } }) => {
        let sortedReportsByDate = lastReports.sort((a, b) => { return b.date_in_millis - a.date_in_millis })
        return (<Row style={{ flex: 1, overflow: 'auto' }}>
            <Col className="d-flex flex-column" md={{ size: 6, offset: 3 }}>
                {sortedReportsByDate.map(report => renderReport(report, company, companyModules, companySubModules, maxScoreInModules))}
            </Col>
        </Row>)
    }

    let {  user, lastReports, companyModules, companySubModules, maxScoreInModules } = state
    let { error, isLoading } = loadingError

    if (isLoading) { return <LoadingScreen image={company?.pic} /> }

    return (<Container className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
        {error && renderError(error)}
        {user && renderHeader(user)}
        {company && lastReports.length > 0 && renderActivities(company, companyModules, companySubModules, lastReports, maxScoreInModules)}
        {company && renderBottomNavBar(company)}
    </Container>)

}

export default ActivitiesScreen