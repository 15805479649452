import React, { CSSProperties } from "react";

interface Props {
    bgColor: string,
    completed: number,
    height?: number
    radius?: number
}

const ProgressBar = ({ bgColor, completed, height, radius }: Props) => {
  
    const containerStyles: CSSProperties = {
      height: height || 5,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: radius || 25,
    }
  
    const fillerStyles: CSSProperties = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgColor,
      borderRadius: 'inherit',
      textAlign: 'right'
    }
  
    const labelStyles: CSSProperties = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles} />
        </div>
      </div>
    );
  };
  
  export default ProgressBar;