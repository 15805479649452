import React, { Component } from 'react';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../../../../constants/images';
import FileHelper from '../../../../../files/FileHelper';
import { DeckCardTypes, DescriptionCard, IAttribution } from '../../../../../models/DeckCards';
import LoadingScreen from '../../../../loading';
import SelectImageComponent from '../SelectImageComponent';
import { PickImageCardProps } from './interfaces';

interface DescriptionCardState {
    title: string
    text1: string
    text2: string
    text3: string
    image: string
    isLoading: boolean
    attribution?: IAttribution
}

const DESCRIPTION_CARD_INITIAL_STATE: DescriptionCardState = {
    title: 'VAMOS APRENDER',
    text1: '',
    text2: '',
    text3: '',
    image: 'https://storage.googleapis.com/deafult_media/default_image_1.png',
    isLoading: false,
    attribution: undefined,
}

export default class CreateDescriptionCardComponent extends Component<PickImageCardProps, DescriptionCardState> {

    FileHelper = new FileHelper()

    constructor(props: PickImageCardProps) {
        super(props)

        this.state = { ...DESCRIPTION_CARD_INITIAL_STATE }

    }

    createCard = (title: string, texts: Array<string>, image: string | undefined) => {
        let { subModule, lastPosition } = this.props
        let descriptionCard = new DescriptionCard({
            id: subModule.id + '_' + lastPosition.toString(),
            type: DeckCardTypes.Description,
            pos: lastPosition,
            companyId: subModule.companyId,
            moduleId: subModule.moduleId,
            subModuleId: subModule.id,
            title,
            image,
            texts
        })
        this.props.createCard.addCard(descriptionCard)
    }

    uploadFile = async (file: File | undefined) => {
        if (!file) return

        try {
            let imageUrl = await this.FileHelper.getFileDataUrl(file)
            let imageElement = await this.FileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await this.FileHelper.resizeImage(canvas, imageElement, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            this.props.cardImageGetUpload.uploadCardImage(resizedImageBlob, image => image && this.onImageSelected(image))
        } catch (error) {
            this.props.createCard.onError(error)
        }
    }

    onImageSelected = (image: string) => {
        this.setState({ image, attribution: undefined, isLoading: false }, () => this.props.updateIsPickingFile(false))
    }

    onPromptImageSelected = (image: string, attribution: IAttribution) => {
        this.setState({ image, attribution, isLoading: false }, () => this.props.updateIsPickingFile(false))
    }

    isInputValid = (title: string, text1: string, image: string | undefined) => {
        if (!image) return false
        if (title.length < 3) return false
        if (text1.length === 0) return false
        return true
    }

    renderForm(title: string, image: string | undefined, texts: Array<string>) {
        return (<Row>
            <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <div className="d-flex flex-column mt-3 p-2 rounded" style={{ margin: 5, background: '#FFFFFFDA' }}>
                    <img style={{ objectFit: 'contain', maxHeight: 200, maxWidth: 3000, margin: 5 }} src={image} alt="Foto do card" />
                    <Button outline style={{ margin: 5, alignSelf: 'center' }} color="secondary" onClick={() => this.props.updateIsPickingFile(true)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faImage} />Trocar Imagem</Button>
                    <Form className="mt-2">
                        <FormGroup>
                            <Label for="cardText">Título</Label>
                            <Input name="title" id="cardText" placeholder="Texto do card" value={title} onChange={(event: any) => this.setState({ title: event.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="cardText1">Texto 1</Label>
                            <Input name="text1" id="cardText1" value={texts.length > 0 ? texts[0] : ''} onChange={(event: any) => this.setState({ text1: event.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="cardText2">Texto 2</Label>
                            <Input name="text2" id="cardText2" value={texts.length > 0 ? texts[1] : ''} onChange={(event: any) => this.setState({ text2: event.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="cardText3">Texto 3</Label>
                            <Input name="text3" id="cardText3" value={texts.length > 0 ? texts[2] : ''} onChange={(event: any) => this.setState({ text3: event.target.value })} />
                        </FormGroup>
                    </Form>
                </div>
            </Col>
            <Col className="d-flex flex-column justify-content-center relative mb-1" md={{ size: 6, offset: 3 }}>
                <Button style={{ margin: 5 }} disabled={!this.isInputValid(title, texts.length > 0 ? texts[0] : '', image)} color="primary" onClick={() => this.createCard(title, texts, image!)}>Adicionar</Button>
            </Col>
        </Row>)
    }

    render() {
        let { isLoading, image, title, text1, text2, text3 } = this.state
        let texts: Array<string> = []
        if (text1.length > 0) texts.push(text1); if (text2.length > 0) texts.push(text2); if (text3.length > 0) texts.push(text3)
        if (isLoading) { return <LoadingScreen /> }

        if (!this.props.isPickingFile) return this.renderForm(title, image, texts)
        else return (<SelectImageComponent getCardImages={this.props.cardImageGetUpload.getCardImages} uploadFile={this.uploadFile} onImageSelected={this.onImageSelected} onPromptImageSelected={this.onPromptImageSelected} goBackToEdit={() => this.props.updateIsPickingFile(false)} />)
    }

}