export default class VideoFrameGrabber {

    isVideoLoaded = false
    loadedMetaData = false
    videoHeight = 0
    videoWidth = 0
    duration = 0
    callback: ((video: HTMLVideoElement) => void) | undefined = undefined
    seekedCallback: (() => void) | undefined = undefined
    error: ((error: ErrorEvent) => void) | undefined = undefined
    video = document.createElement("video");

    constructor() {
        this.video.crossOrigin = "anonymous"
        //this.video.setAttribute('download', '')
        this.video.muted = true
        this.video.addEventListener('loadedmetadata', (event) => {
            console.log('loaded metadata')
            const {
                videoHeight,
                videoWidth,
                duration
            } = event.currentTarget as HTMLVideoElement;
            this.videoHeight = videoHeight
            this.videoWidth = videoWidth
            this.duration = duration
            this.loadedMetaData = true
        }, false);

        this.video.addEventListener('loadeddata', () => {
            //console.log('loaded data')
            this.video.currentTime = 0
            //this.isVideoLoaded = true
            //this.callback && this.callback(this.video)
        }, false);

        this.video.addEventListener('error', (event) => {
            this.error && this.error(event)
        })

        this.video.addEventListener('seeked', () => {
            console.log('seeked')
            // gambiarra pra mostrar o video no canva
            if(!this.isVideoLoaded) {
                this.isVideoLoaded = true
                this.callback && this.callback(this.video)
            } else {
                this.seekedCallback && this.seekedCallback()
            }
        }, false);

        this.video.addEventListener('canplay', function() {
            console.log('canplay')
        });

        this.video.addEventListener('ended', function() {
            console.log('ended')
        });
    }

    emptyData() {
        this.isVideoLoaded = false
        this.loadedMetaData = false
        this.videoHeight = 0
        this.videoWidth = 0
        this.duration = 0
    }

    loadVideo(videoUrl: string, callback: (video: HTMLVideoElement) => void, error: (error: Event) => void) {
        this.error = error
        this.callback = callback
        this.video.src = videoUrl
    }

    setVideoSrc(src: string) {
        this.video.src = src
    }

    setVideoCurrentTime(time: number): Promise<HTMLVideoElement> {
        return new Promise((resolve) => {
            if (!isNaN(this.video.duration) && this.video.duration >= time) {
                this.seekedCallback = () => { resolve(this.video) }
                this.video.currentTime = time
            } else {
                resolve(this.video)
            }
        })
    }
}