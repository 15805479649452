import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditCardProps } from './interfaces';
import { OrderedListCard } from '../../../../../models/DeckCards';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

interface OrderedListCardState {
    list: Array<{ position: number, text: string }>
    title: string
    newListText: string
}

const ORDERED_LIST_CARD_INITIAL_STATE: OrderedListCardState = {
    list: [],
    title: '',
    newListText: ''
}

export default class EditOrderedListCardComponent extends Component<EditCardProps<OrderedListCard>, OrderedListCardState> {

    constructor(props: EditCardProps<OrderedListCard>) {
        super(props)

        this.state = {
            ...ORDERED_LIST_CARD_INITIAL_STATE,
            list: props.card.list,
            title: props.card.title
        }

    }

    editCard = (title: string, list: Array<{ position: number, text: string }>) => {
        let { id, type, pos, companyId, moduleId, subModuleId } = this.props.card
        let orderedListCard = new OrderedListCard({
            id,
            type,
            pos,
            companyId,
            moduleId,
            subModuleId,
            title,
            list
        })
        this.props.editCardInterface.editCard(orderedListCard)
    }

    addNewListText(list: Array<{ position: number, text: string }>, text: string) {
        let newPos = list.length + 1
        let newList = list.concat({ position: newPos, text })
        this.setState({ list: newList, newListText: '' })
    }

    removeListText(list: Array<{ position: number, text: string }>, pos: number) {
        let newList = list.filter(({ position }) => position !== pos).map((val, i) => { return { position: i + 1, text: val.text } })
        this.setState({ list: newList })
    }

    renderListElement(list: Array<{ position: number, text: string }>, position: number, text: string) {
        return (<Col className="d-flex flex-column justify-content-center" md={{ size: 6, offset: 3 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', margin: 5, padding: 10, borderRadius: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                <div style={{ fontSize: '1em', marginRight: 5 }}><b>{position}</b></div>
                <div style={{ flex: 1, fontSize: '0.9em' }}>{text}</div>
                <Button onClick={() => this.removeListText(list, position)}><FontAwesomeIcon icon={faTrash} /></Button>
            </div>
        </Col>)
    }

    renderForm(title: string, list: Array<{ position: number, text: string }>, newListText: string) {
        return (<Fragment>
            <Row>
                <Col className="mt-2 d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <Form style={{ margin: 5 }}>
                        <FormGroup>
                            <Label for="cardTitle">Título</Label>
                            <Input value={title} name="title" id="cardTitle" placeholder="Texto que aparece na parte superior do card" onChange={(event: any) => this.setState({ title: event.target.value })} />
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            <Row>
                {list.length > 0 && list.map(({ position, text }) => this.renderListElement(list, position, text))}
            </Row>
            <Row>
                <Col className="mt-2 d-flex justify-content-center align-items-center mb-2" md={{ size: 6, offset: 3 }}>
                    <Form style={{ flex: 1, marginRight: 5, marginLeft: 5 }}>
                        <Input value={newListText} name="text" id="cardText" placeholder={`Etapa ${list.length + 1}`} onChange={(event: any) => this.setState({ newListText: event.target.value })} />
                    </Form>
                    <Button disabled={newListText.length < 1} style={{ marginRight: 5 }} color="success" onClick={() => {
                        this.addNewListText(list, newListText)
                    }}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                </Col>
                <Col className="d-flex flex-column justify-content-center mb-2" md={{ size: 6, offset: 3 }}>
                    <Button color="primary" style={{ margin: 5 }} disabled={list.length < 2 || title.length < 1} onClick={() => {
                        this.editCard(title, list)
                    }}>Editar</Button>
                </Col>
            </Row>
        </Fragment>)
    }

    render() {

        let { list, title, newListText } = this.state
        return this.renderForm(title, list, newListText)

    }

}