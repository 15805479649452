import React, { Component, useEffect } from 'react';
import { Container, Row, Col, Button, Alert, Form, FormGroup, Input, Label, Spinner } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import User from '../../models/User';
import Cookies from 'universal-cookie';
import LoadingScreen from '../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import AccountCircleSvg from '../../images/account_circle-24px.svg';
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../constants/images'
import TokenHelper from '../../auth/TokenHelper';
import ProfileService from '../../services/profileService';
import config from '../../config'
import * as ROUTES from '../../constants/routes'
import FileHelper from '../../files/FileHelper'
import EnterService from '../../services/enterService';
import { AuthData, SignUpConfig } from '../../models/CompanyConfig';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useUser } from '../../hooks/useUser';

interface State {
    error: string | undefined;
    isLoading: boolean;
    signUpConfig: SignUpConfig | undefined
    nameInputString: string;
    mainPicUrl: string | undefined;
    thumbnail: string | undefined;
    authDataSelectedValues: { [id: string]: string };
    isAuthDataDropdownOpen: { [id: string]: boolean };
    loadingProfilePic: boolean;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    signUpConfig: undefined,
    nameInputString: '',
    mainPicUrl: undefined,
    thumbnail: undefined,
    loadingProfilePic: false,
    isAuthDataDropdownOpen: {},
    authDataSelectedValues: {},
};

const fileHelper = new FileHelper()

const ProfileScreen = () => {

    const uploadButtonRef = React.useRef<HTMLInputElement>(null)
    const [state, setState] = React.useState<State>({ ...INITIAL_STATE })
    const navigate = useNavigate()
    const { height } = useWindowDimensions()
    const { user, company, noUser, noCompany } = useUser()

    useEffect(() => {
        if (user && company) {
            setState({ ...state, nameInputString: user.username, authDataSelectedValues: user.authData || {} })
            loadSignUpConfig(company.id)
        } else if (noUser || noCompany) {
            setState({ ...state, error: 'Ops, link invalido. Você precisa fazer login primeiro.' })
        }
    }, [user, company, noUser, noCompany])

    const loadSignUpConfig = async (companyId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let enterService = new EnterService()
        try {
            let signUpRes = await enterService.getCompanySignUpConfig(config.endpoint, companyId)
            let signUpConfig = signUpRes.signUpConfig
            setState({ ...state, isLoading: false, signUpConfig })
        } catch (error) {
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const updateUser = async (signUpConfig: SignUpConfig, nameInputString: string, mainPicUrl: string | undefined, thumbnail: string | undefined, authDataSelectedValues: { [id: string]: string } = {}) => {
        let updateData: any = {}
        if (nameInputString.length > 1) updateData.username = nameInputString
        if (mainPicUrl) updateData.pic = mainPicUrl
        if (thumbnail) updateData.thumbnail = thumbnail
        if (signUpConfig.authData && Object.keys(signUpConfig.authData).map(key => { return signUpConfig.authData![key] }).filter(authData => { return authData.userCanEdit }).length > 0) updateData.authData = authDataSelectedValues

        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let profileService = new ProfileService()
            let user = await profileService.updateUser(token, config.endpoint, updateData)
            let cookies = new Cookies()
            cookies.set('user', user.getData(), { path: '/', maxAge: 31536000 })
            navigate(`/${user.companyId}${ROUTES.MAIN}`)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                updateUser(signUpConfig, nameInputString, mainPicUrl, thumbnail, authDataSelectedValues)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const isInputInvalid = (user: User, nameInputString: string, mainPicUrl: string | undefined) => {
        if (mainPicUrl) return false
        if (nameInputString.length < 2) return true
        return false
    }

    const canEditAuthData = (signUpConfig: SignUpConfig) => signUpConfig.authData && Object.keys(signUpConfig.authData).map(key => { return signUpConfig.authData![key] }).filter(authData => { return authData.userCanEdit }).length > 0

    const uploadFile = async (user: User, file: File | undefined) => {
        if (!file) return
        setState({ ...state, loadingProfilePic: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let imageUrl = await fileHelper.getFileDataUrl(file)
            let image = await fileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await fileHelper.resizeImage(canvas, image, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            let formData = new FormData()
            formData.append('file', resizedImageBlob)
            let profileService = new ProfileService()
            let { mainPicUrl, thumbnail } = await profileService.uploadProfilePic(token, config.endpoint, formData)
            user.pic = mainPicUrl
            user.thumbnail = thumbnail
            let cookies = new Cookies()
            cookies.set('user', user.getData(), { path: '/', maxAge: 31536000 })
            setState({ ...state, loadingProfilePic: false, mainPicUrl, thumbnail })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                uploadFile(user, file)
            } else {
                setState({ ...state, loadingProfilePic: false, error: error.toString() })
            }
        }

    }

    const openSelectImageDialog = () => {
        uploadButtonRef.current?.click()
    }

    const renderUsernameInput = (nameInputString: string) => {
        return (<FormGroup>
            <Label for="usernameTitle">Nome</Label>
            <Input name="title" id="usernameTitle" placeholder={'Por favor, digite seu nome'} value={nameInputString} onChange={(event: any) => setState({ ...state, nameInputString: event.target.value })} />
        </FormGroup>)
    }

    const renderUserPicOrLoading = (userPic: string | undefined, mainPicUrl: string | undefined, loadingProfilePic: boolean) => {
        if (loadingProfilePic) {
            return (<div className="d-flex justify-content-center">
                <Spinner style={{ width: 150, height: 150, color: 'black', marginBottom: 5, marginTop: 10, borderRadius: '50%' }} />{' '}
            </div>)
        }
        return (<div className="d-flex justify-content-center" onClick={() => openSelectImageDialog()}>
            <img alt='company pic' style={{ objectFit: 'cover', width: 150, height: 150, marginBottom: 5, marginTop: 10, borderRadius: 75, borderStyle: 'solid' }} src={mainPicUrl || userPic || AccountCircleSvg} />
        </div>)
    }

    const renderError = (error: string) => {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>

        );
    }

    const dropDownAuthDataSelection = (event: any, id: string) => {
        let authDataSelectedValues = state.authDataSelectedValues
        let selection = event as string
        if (selection === 'Nenhum') {
            delete authDataSelectedValues[id]
            setState({ ...state, authDataSelectedValues })
        } else {
            authDataSelectedValues[id] = selection
            setState({ ...state, authDataSelectedValues })
        }
    }

    const toggleAuthDataDropdownOpen = (id: string) => {
        let isAuthDataDropdownOpen = state.isAuthDataDropdownOpen
        isAuthDataDropdownOpen[id] = !isAuthDataDropdownOpen[id]
        setState({ ...state, isAuthDataDropdownOpen })
    }

    const renderAuthDataSelection = (authData: { [id: string]: AuthData }, authDataSelectedValues: { [id: string]: string }) => {
        let authDataKeys = Object.keys(authData)
        return (<div style={{ marginBottom: 5 }}>
            {authDataKeys.filter(key => authData[key].show && authData[key].userCanEdit).map((id, index) => {
                let currentAuthData = authData[id]
                if (currentAuthData.values) {
                    let options = currentAuthData.values.map(val => { return { label: val, value: val } })
                    let currentValue = authDataSelectedValues[id] ? { label: authDataSelectedValues[id], value: authDataSelectedValues[id] } : undefined
                    return <FormGroup key={index} style={{ marginBottom: 10 }}>
                        <Label>{currentAuthData.title}</Label>
                        <Select isClearable placeholder={currentAuthData.placeholder} value={currentValue} options={options} onChange={opt => dropDownAuthDataSelection(opt ? opt!.label : undefined, id)} />
                    </FormGroup>
                } else {
                    let selectedAuthDataValue = authDataSelectedValues[id]
                    return (
                        <FormGroup key={index}>
                            <Label>{currentAuthData.title}</Label>
                            <Input name={currentAuthData.title} id={id} value={selectedAuthDataValue} placeholder={currentAuthData.placeholder} onChange={(event: any) => dropDownAuthDataSelection(event.target.value as string, id)} />
                        </FormGroup>
                    )
                }
            })}
        </div>)
    }

    const { signUpConfig, error, isLoading, nameInputString, mainPicUrl, thumbnail, loadingProfilePic, authDataSelectedValues } = state;

    if (isLoading || !company) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    let backgroundImage = company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && renderError(error)}
        <Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#616975' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#0b0c10', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 16, marginLeft: 10 }}>Perfil</div>
                </div>
            </Col>
        </Row>
        {user && <Row>
            <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                {renderUserPicOrLoading(user.pic, mainPicUrl, loadingProfilePic)}
                <input id="myInput" type="file" ref={uploadButtonRef} style={{ display: 'none' }} onChange={(event) => uploadFile(user, event.target.files ? event.target.files[0] : undefined)} />
                <div className="d-flex justify-content-center">
                    <Button style={{ borderStyle: 'none', background: '#111111', margin: 10, width: 'auto' }} onClick={() => openSelectImageDialog()}><FontAwesomeIcon icon={faImage} /> Selecione uma foto para o perfil</Button>
                </div>
                <Form style={{ marginTop: 10 }}>
                    {renderUsernameInput(nameInputString)}
                    {signUpConfig && signUpConfig.authData && canEditAuthData(signUpConfig) && renderAuthDataSelection(signUpConfig.authData, authDataSelectedValues)}
                </Form>
                {signUpConfig && <Button style={{ borderStyle: 'none', background: company.mainColor || '#000' }} block disabled={isInputInvalid(user, nameInputString, mainPicUrl)} onClick={() => { updateUser(signUpConfig, nameInputString, mainPicUrl, thumbnail, authDataSelectedValues) }}>Salvar</Button>}
            </Col>
        </Row>}
    </Container>)
}

export default ProfileScreen
