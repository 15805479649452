import { faCheck, faDownload, faExclamationCircle, faSave, faUpload, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import * as XLSX from 'xlsx'
import TokenHelper from '../../../../auth/TokenHelper';

import AdminModuleAccessComponentService from '../../../../services/admin/adminModuleAccessComponentService';
import { applyCpfMask, validateCPF } from '../../../../utils/checkCpfAndEmail';
import config from '../../../../config'

interface ModuleAccessComponentProps {
    companyId: string,
    moduleId: string,
    primaryProperty: string,
}

const getValidAndInvalidData = (primaryProperty: string, sheetData: Array<any>) => {
    let validData: Array<string> = []
    let invalidData: Array<string> = []
    let header = sheetData[0] as string[]
    let primaryPropertySearchName = primaryProperty === 'email' ? 'email' : 'cpf'
    let primaryPropertyIndex = header.reduce((prev, curr, index) => {
        if (curr.trim().toLowerCase() === primaryPropertySearchName) return index
        else return prev
    }, 0)
    for (var i = 1; i < sheetData.length; i++) {
        let currentRowData = sheetData[i]
        if(!currentRowData[primaryPropertyIndex]) continue
        let value = currentRowData[primaryPropertyIndex]
        if (primaryProperty === 'email') {
            if (value.toString().length > 3 && value.toString().includes('@')) validData.push(value.toString().toLowerCase().trim())
            else invalidData.push(currentRowData['Email'])
        } else {
            let dataWithCPFMask = applyCpfMask(value.trim())
            let validatedData = validateCPF(dataWithCPFMask)
            if (validatedData) validData.push(dataWithCPFMask)
            else invalidData.push(currentRowData['CPF'] || '')
        }
    }
    return { validData, invalidData }
}

const downloadExampleFile = (primaryProperty: string) => {

    let wb = XLSX.utils.book_new()
    var ws_data: Array<any> = []
    var ws_data_index = 0

    let primaryPropertyTitle = primaryProperty === 'email' ? 'Email' : 'CPF'

    // Coloca nomes das colunas
    ws_data[ws_data_index] = [primaryPropertyTitle]; ws_data_index++
    ws_data[ws_data_index] = primaryProperty === 'email' ? ['usuario1@gmail.com'] : ['123.456.789-10']; ws_data_index++
    ws_data[ws_data_index] = primaryProperty === 'email' ? ['usuario2@hotmail.com'] : ['111.222.333-44']; ws_data_index++
    ws_data[ws_data_index] = primaryProperty === 'email' ? ['usuario3@email.com.br'] : ['555.222.111-33']; ws_data_index++

    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, 'Bloqueio de Usuários');
    let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    saveAs(new Blob([excelBuffer], { type: fileType }), 'PlanilhaDeAcessoAoTreinamento.xlsx');
}

const downloadFileWithInvalidData = (primaryProperty: string, invalidData: Array<string>) => {

    let wb = XLSX.utils.book_new()
    var ws_data: Array<any> = []
    var ws_data_index = 0

    let primaryPropertyTitle = primaryProperty === 'email' ? 'Email' : 'CPF'

    // Coloca nomes das colunas
    ws_data[ws_data_index] = [primaryPropertyTitle]; ws_data_index++

    for (var i = 0; i < invalidData.length; i++) {
        ws_data[ws_data_index] = [invalidData[i]]; ws_data_index++
    }

    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, 'Dados Inválidos');
    let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    saveAs(new Blob([excelBuffer], { type: fileType }), 'DadosInválidos.xlsx');
}

// create a new functional component
const ModuleAccessUploadComponent = (props: ModuleAccessComponentProps) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [sheetData, setSheetData] = useState<any[]>([])
    const [showSuccess, setShowSuccess] = useState(false)
    const [uploadButton, setUploadButton] = useState<HTMLInputElement | null>(null)

    let uploadFile = (file: File | undefined) => {
        if (!file) return
        let fileReader = new FileReader()
        fileReader.onload = () => {
            try {
                let result = fileReader.result
                let wb = XLSX.read(result, { type: 'binary' })
                let sheetNames = wb.SheetNames
                let data = XLSX.utils.sheet_to_json(wb.Sheets[sheetNames[0]], { header: 1 })
                setSheetData(data)
            } catch (error) {
                setError(error)
            }
        }
        fileReader.readAsBinaryString(file)
    }

    let updateModuleAccess = async (primaryPropertyValues: Array<string>) => {
        setLoading(true)
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setError('Usuário não possui token de acesso.')
            let adminModuleAccessComponentService = new AdminModuleAccessComponentService()
            await adminModuleAccessComponentService.updateModuleUserAccess(token, config.endpoint, props.moduleId, primaryPropertyValues)
            setError(null)
            setShowSuccess(true)
            setLoading(false)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                updateModuleAccess(primaryPropertyValues)
            } else {
                setError(error.toString())
                setLoading(false)
            }
        }
    }

    let renderError = (error: string) => <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
        <div className="d-flex" style={{ boxShadow: '2px 4px 8px 2px rgba(80,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
            <div className="d-flex flex-column align-items-center justify-content-between text-danger" style={{ width: '100%' }}>
                <div style={{ fontSize: 'large' }}><FontAwesomeIcon icon={faExclamationCircle} /> <b>Ops, ocorreu um erro...</b></div>
                <div className="m-2 text-center" style={{ fontSize: 'small' }}>{error}</div>
                <Button className='mt-1' outline color="danger" onClick={() => setError(null)}>OK</Button>
            </div>
        </div>
    </Col>

    if (loading) {
        return <Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div className="d-flex flex-column align-items-center" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                    <div style={{ color: '#626571', fontSize: 'large', marginBottom: 20 }}><b>Carregando...</b></div>
                    <Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />
                </div>
            </Col>
        </Row>
    }

    if (sheetData.length > 0) {
        let { validData, invalidData } = getValidAndInvalidData(props.primaryProperty, sheetData)
        return <Row>
            {showSuccess && <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div className="d-flex" style={{ boxShadow: '2px 4px 8px 2px rgba(0,80,0,0.3)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                    <div className="d-flex flex-column align-items-center justify-content-between" style={{ width: '100%' }}>
                        <div style={{ color: '#28a745', fontSize: 'large' }}><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon> <b>{validData.length} usuários liberados!</b></div>
                        <div className="m-2 text-center" style={{ color: '#28a745', fontSize: 'small' }}>Os usuários receberam acesso ao treinamento com sucesso!</div>
                        <Button className='mt-1' outline color='success' onClick={() => setShowSuccess(false)}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> OK</Button>
                    </div>
                </div>
            </Col>}
            {error && renderError(error)}
            {invalidData.length > 0 && <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div className="d-flex" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                    <div className="d-flex flex-column align-items-center justify-content-between" style={{ width: '100%' }}>
                        <div style={{ color: '#626571', fontSize: 'large' }}><FontAwesomeIcon icon={faExclamationCircle} /> <b>A planilha contém dados inválidos</b></div>
                        <div className="m-2 text-center" style={{ color: '#787b8a', fontSize: 'small' }}>Foram encontrados {invalidData.length} dados inválidos na planilha. Você pode continuar salvando os dados válidos.</div>
                        <Button className='mt-1' outline onClick={() => downloadFileWithInvalidData(props.primaryProperty, invalidData)}><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Baixar dados inválidos</Button>
                    </div>
                </div>
            </Col>}
            {validData.length > 0 && !showSuccess && <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div className="d-flex" style={{ boxShadow: '2px 4px 8px 2px rgba(0,80,0,0.3)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                    <div className="d-flex flex-column align-items-center justify-content-between" style={{ width: '100%' }}>
                        <div style={{ color: '#198754', fontSize: 'large' }}><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon> <b>Liberar {validData.length} usuários</b></div>
                        <div className="m-2 text-center" style={{ color: '#198754', fontSize: 'small' }}>Os usuários liberados poderão acessar o treinamento, mesmo se ele possuir restrições de tipo de usuário</div>
                        <Button className='mt-1' outline color='success' onClick={() => updateModuleAccess(validData)}><FontAwesomeIcon icon={faSave}></FontAwesomeIcon> Liberar usuários</Button>
                    </div>
                </div>
            </Col>}
        </Row>
    }

    return <Row>
        {error && renderError(error)}
        <Col className="d-flex flex-column align-items-center" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
            <input id="myInput" type="file" ref={(ref) => setUploadButton(ref)} style={{ display: 'none' }} onChange={(event) => uploadFile(event.target.files ? event.target.files[0] : undefined)} />
            <div className="d-flex" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                <div className="d-flex flex-column align-items-center justify-content-between" style={{ width: '100%' }}>
                    <div style={{ color: '#626571', fontSize: 'large', width: 30, height: 30, borderRadius: 15, borderStyle: 'solid', borderWidth: 'thin', textAlign: 'center' }}>1</div>
                    <div className="m-2 text-center" style={{ color: '#787b8a', fontSize: 'small' }}>Baixe o modelo XLSX e edite com os dados dos usuários que terão o acesso liberado.</div>
                    <Button className='mt-1' outline onClick={() => downloadExampleFile(props.primaryProperty)}><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Baixar o modelo</Button>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-between" style={{ width: '100%' }}>
                    <div style={{ color: '#626571', fontSize: 'large', width: 30, height: 30, borderRadius: 15, borderStyle: 'solid', borderWidth: 'thin', textAlign: 'center' }}>2</div>
                    <div className="m-2 text-center" style={{ color: '#787b8a', fontSize: 'small' }}>Importe o XLSX editado para liberar os usuários.</div>
                    <Button className='mt-1' outline onClick={() => uploadButton && uploadButton.click()}><FontAwesomeIcon icon={faUpload}></FontAwesomeIcon> Importar arquivo</Button>
                </div>
            </div>
        </Col>
    </Row>
}

export default ModuleAccessUploadComponent