import React, { Component, CSSProperties } from 'react';
import QuizQuestion, { QuizQuestionType, DefaultQuestion, MemoryQuestion, SelectAnswerQuestion, WordGuessQuestion } from '../../../models/QuizQuestion';
import { QuizQuestionsReport, DefaultQuestionReport, MemoryQuestionReport, SelectAnswerQuestionReport, WordGuessQuestionReport } from '../../../models/QuizQuestionsReport';
import { QuestionAnswer } from '../../../models/Question';
import * as _ from 'lodash'
import { Container, Row, Col, Progress } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle, IconDefinition, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { POINTS_PER_QUIZ_QUESTION, POINTS_PER_MEMORY_GAME, POINTS_PER_WORD_GUESS } from '../../../constants/ScoreConstants';
import MemoryGameComponent from '../../../deck/MemoryGameComponent';
import { MEDIUM_HEIGHT, SMALL_HEIGHT } from '../../../constants/screens'
import RenderSelectAnswerQuestion from '../../../quiz/RenderSelectAnswerQuestion';
import WordGuessComponent from '../../../quiz/WordGuessComponent';
import Company from '../../../models/Company';

const answerTime = 45

interface State {
    isNavBarMenuOpen: boolean,
    currentQuizQuestion: number,
    isTimerOn: boolean,
    timeInSeconds: number,
    start: number,
    selectedAnswer: number,
    height: number
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false,
    currentQuizQuestion: 0,
    isTimerOn: false,
    timeInSeconds: 0,
    start: 0,
    selectedAnswer: -1,
    height: 0
};

interface Props { company: Company, userScoreChanged: (newScore: number) => void, quizQuestions: Array<QuizQuestion>, subModuleId: string, onExit: () => void, onFinish: (startTime: number, endTime: number, quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>) => void }

export default class GameComponent extends Component<Props, State> {

    timer: any

    quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>
    startTime: number
    endTime: number | undefined

    constructor(props: Props) {
        super(props)
        this.startTime = (new Date()).getTime()
        this.quizQuestionsReport = []
        this.state = { ...INITIAL_STATE }

        this.startTimer = this.startTimer.bind(this)
        this.stopTimer = this.stopTimer.bind(this)

        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion]
        this.addQuestionReport(currentQuizQuestion)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion]
        if (currentQuizQuestion instanceof DefaultQuestion || currentQuizQuestion instanceof SelectAnswerQuestion) {
            // Se for uma pergunta precisa inicializar o timer
            this.startTimer()
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        clearInterval(this.timer)
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ height: window.innerHeight });
    }

    startTimer() {
        this.setState({
            isTimerOn: true,
            timeInSeconds: 0,
            start: Date.now(),
            selectedAnswer: -1,
        })
        clearInterval(this.timer)
        this.timer = setInterval(() => { this.timerTick() }, 500);
    }

    stopTimer() {
        this.setState({ isTimerOn: false })
        clearInterval(this.timer)
    }

    timerTick() {
        let timeInSeconds = Math.round((Date.now() - this.state.start) / 1000)
        if (timeInSeconds <= answerTime) {
            this.setState({
                timeInSeconds: Math.round((Date.now() - this.state.start) / 1000)
            })
        } else {
            this.onQuestionTimeout()
            clearInterval(this.timer)
            this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
        }
    }

    addQuestionReport(quizQuestion: QuizQuestion) {
        if (this.quizQuestionsReport.filter(questionReport => questionReport.quizQuestion.id === quizQuestion.id).length > 0) return
        if (quizQuestion.type === QuizQuestionType.Default) {
            let defaultQuestionReport = new DefaultQuestionReport(quizQuestion as DefaultQuestion)
            this.quizQuestionsReport.push(defaultQuestionReport)
        } else if (quizQuestion.type === QuizQuestionType.SelectAnswer) {
            let selectAnswerCardReport = new SelectAnswerQuestionReport(quizQuestion as SelectAnswerQuestion)
            this.quizQuestionsReport.push(selectAnswerCardReport)
        } else if (quizQuestion.type === QuizQuestionType.MemoryGame) {
            let quizQuestionReport = new MemoryQuestionReport(quizQuestion as MemoryQuestion)
            this.quizQuestionsReport.push(quizQuestionReport)
        } else if (quizQuestion.type === QuizQuestionType.WordGuess) {
            let wordGuessQuestionReport = new WordGuessQuestionReport(quizQuestion as WordGuessQuestion)
            this.quizQuestionsReport.push(wordGuessQuestionReport)
        }
    }

    onDefaultQuestionAnswerSelected = (answerNumber: number) => {
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion] as DefaultQuestion
        let cardReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === currentQuizQuestion.id))
        let questionCardReport = cardReport as DefaultQuestionReport
        if (questionCardReport) {
            if (questionCardReport.questionAnswer !== QuestionAnswer.NotAnswered) return
            questionCardReport.timeToAnswer = this.state.timeInSeconds >= 0 ? this.state.timeInSeconds : 0
            if (answerNumber === currentQuizQuestion.correctAnswer) {
                questionCardReport.questionAnswer = QuestionAnswer.CorrectAnswer
                // Avisa que atualizou o score
                let newScore = this.getScore(); this.props.userScoreChanged(newScore)
                if (this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                    setTimeout(() => {
                        this.goToNextQuestion(this.state.currentQuizQuestion + 1)
                    }, 2000)
                } else {
                    setTimeout(() => {
                        this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                    }, 2000)
                }
            } else {
                questionCardReport.questionAnswer = QuestionAnswer.WrongAnswer
                setTimeout(() => {
                    this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                }, 2000)
            }
            clearInterval(this.timer)
            this.setState({ selectedAnswer: answerNumber, isTimerOn: false })
        }
    }

    onMemoryGameFinished = (memoryQuestion: MemoryQuestion, attempts: number) => {
        let memoryQuestionReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === memoryQuestion.id)) as MemoryQuestionReport
        if (memoryQuestionReport) {
            if (memoryQuestionReport.allImagesFound) return
            memoryQuestionReport.allImagesFound = true
            memoryQuestionReport.attempts = attempts
            // Avisa que atualizou o score
            let newScore = this.getScore(); this.props.userScoreChanged(newScore)
            if (this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                this.goToNextQuestion(this.state.currentQuizQuestion + 1)
            } else {
                setTimeout(() => {
                    this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                }, 2000)
            }
        }
    }

    onWordGuessFinished = (wordGuessQuestion: WordGuessQuestion, attempts: number) => {
        let wordGuessReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === wordGuessQuestion.id)) as WordGuessQuestionReport
        if(wordGuessReport) {
            if (wordGuessReport.wordFound) return
            wordGuessReport.wordFound = true
            wordGuessReport.attempts = attempts
            if (this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                setTimeout(() => {
                    this.goToNextQuestion(this.state.currentQuizQuestion + 1)
                }, 2000)
            } else {
                setTimeout(() => {
                    this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                }, 2000)
            }
        }
    }

    onFullScreenExit = () => {
        this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
    }

    onSelectAnswerQuestionAnswerSelected = (selectedAnswers: Array<number>) => {
        let selectAnswerQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion] as SelectAnswerQuestion
        let cardReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === selectAnswerQuizQuestion.id))
        let selectAnswerQuestionReport = cardReport as SelectAnswerQuestionReport
        if (selectAnswerQuestionReport) {
            if (selectAnswerQuestionReport.questionAnswer !== QuestionAnswer.NotAnswered) return
            selectAnswerQuestionReport.timeToAnswer = this.state.timeInSeconds >= 0 ? this.state.timeInSeconds : 0
            selectAnswerQuestionReport.selectedAnswers = selectedAnswers
            if (_.isEqual(selectAnswerQuestionReport.quizQuestion.correctAnswers.sort(), selectedAnswers.sort())) {
                selectAnswerQuestionReport.questionAnswer = QuestionAnswer.CorrectAnswer
                // Avisa que atualizou o score
                let newScore = this.getScore(); this.props.userScoreChanged(newScore)
                if (this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                    setTimeout(() => {
                        this.goToNextQuestion(this.state.currentQuizQuestion + 1)
                    }, 2000)
                } else {
                    setTimeout(() => {
                        this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                    }, 2000)
                }
            } else {
                selectAnswerQuestionReport.questionAnswer = QuestionAnswer.WrongAnswer
                setTimeout(() => {
                    this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                }, 2000)
            }
            clearInterval(this.timer)
            this.setState({ isTimerOn: false })
        }
    }

    onQuestionTimeout() {
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion]
        let cardReport = _.head(this.quizQuestionsReport.filter(quizQuestionReport => quizQuestionReport.quizQuestion.id === currentQuizQuestion.id))
        if (cardReport) {
            if (cardReport instanceof DefaultQuestionReport || cardReport instanceof SelectAnswerQuestionReport) {
                cardReport.questionAnswer = QuestionAnswer.Timeout
            }
        }
    }

    goToNextQuestion(nextQuestionIndex: number) {
        let currentQuizQuestion = this.props.quizQuestions[nextQuestionIndex]
        this.addQuestionReport(currentQuizQuestion)
        this.setState({ currentQuizQuestion: nextQuestionIndex }, () => {
            if (currentQuizQuestion instanceof DefaultQuestion || currentQuizQuestion instanceof SelectAnswerQuestion) {
                this.startTimer()
            }
        })
    }

    getScore() {
        return this.quizQuestionsReport.reduce((acc, val) => {
            if (val instanceof DefaultQuestionReport || val instanceof SelectAnswerQuestionReport) {
                if (val.questionAnswer === QuestionAnswer.CorrectAnswer) {
                    acc += POINTS_PER_QUIZ_QUESTION - val.timeToAnswer!
                }
            } else if (val instanceof MemoryQuestionReport) {
                if (val.allImagesFound) {
                    acc += POINTS_PER_MEMORY_GAME - val.attempts
                }
            } else if (val instanceof WordGuessQuestionReport) {
                if(val.wordFound) {
                    acc += POINTS_PER_WORD_GUESS
                }
            }
            return acc
        }, 0)
    }

    renderHeader(score: number) {
        return (<Row style={{ paddingTop: 5, paddingBottom: 5, marginTop: 5 }}>
            <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                <div style={{ flex: 1, marginLeft: 5 }}>
                    <div style={{ color: '#0f3252', fontFamily: 'Montserrat' }}><FontAwesomeIcon color='#0f3252' icon={faTrophy} />{` Pontuação: ${score}`}</div>
                    <Progress color="game-progress" value={answerTime - this.state.timeInSeconds} max={answerTime} >
                        <div className="text-center" style={{ color: 'white', fontFamily: 'Montserrat', fontSize: '0.8em', paddingLeft: 5, paddingRight: 5 }}><b>{`${answerTime - this.state.timeInSeconds} segundos`}</b></div>
                    </Progress>
                </div>
            </Col>
        </Row>)
    }

    renderScore() {
        let score = this.getScore()
        return (<Row>
            <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                <div className="text-center" style={{ color: 'white', fontFamily: 'Montserrat' }}><FontAwesomeIcon color='white' icon={faTrophy} />{` Pontuação: ${score}`}</div>
            </Col>
        </Row>)
    }

    renderDefaultQuestion(defaultQuestion: DefaultQuestion, selectedAnswer: number, defaultQuestionReport: DefaultQuestionReport) {
        let answer = defaultQuestionReport.questionAnswer
        let answersCount = defaultQuestion.answer2 ? (defaultQuestion.answer3 ? 4 : 3) : 2
        var titleMargin = 5
        var titleFontSize = '1em'
        var screenHeightSize = 'small'
        // se for celular com tamanho medio
        if (this.state.height > SMALL_HEIGHT && this.state.height <= MEDIUM_HEIGHT) {
            screenHeightSize = 'medium'
            if (answersCount < 4) {
                titleMargin = 10
            }
        }
        // se for tablet ou computador
        else if (this.state.height > MEDIUM_HEIGHT) {
            titleMargin = 10
            screenHeightSize = 'big'
            if (answersCount < 4) {
                titleFontSize = '1.3em'
            } else {
                titleFontSize = '1.2em'
            }
        }
        return (<Row style={{ flex: 1 }}>
            <Col style={{ marginTop: 5 }} md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'stretch', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: titleMargin, fontSize: titleFontSize, textAlign: 'center', color: 'black', fontFamily: 'Montserrat' }}>{defaultQuestion.question}</div>
                    <AnswerComponent screenHeight={screenHeightSize} answerText={defaultQuestion.answer0} selectedAnswer={selectedAnswer} answerNumber={0}
                        questionAnswer={answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} answersCount={answersCount} />
                    <AnswerComponent screenHeight={screenHeightSize} answerText={defaultQuestion.answer1} selectedAnswer={selectedAnswer} answerNumber={1}
                        questionAnswer={answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} answersCount={answersCount} />
                    {defaultQuestion.answer2 && (
                        <AnswerComponent screenHeight={screenHeightSize} answerText={defaultQuestion.answer2} selectedAnswer={selectedAnswer} answerNumber={2}
                            questionAnswer={answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} answersCount={answersCount} />
                    )}
                    {defaultQuestion.answer3 && (
                        <AnswerComponent screenHeight={screenHeightSize} answerText={defaultQuestion.answer3} selectedAnswer={selectedAnswer} answerNumber={3}
                            questionAnswer={answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} answersCount={answersCount} />
                    )}
                </div>
            </Col>
        </Row>)
    }

    renderSelectAnswerQuestion(selectAnswerQuestion: SelectAnswerQuestion, selectAnswerQuestionReport: SelectAnswerQuestionReport, screenHeight: number) {
        var screenHeightSize = 'small'
        // se for celular com tamanho medio
        if (screenHeight > SMALL_HEIGHT && screenHeight <= MEDIUM_HEIGHT) {
            screenHeightSize = 'medium'
        }
        // se for tablet ou computador
        else if (screenHeight > MEDIUM_HEIGHT) {
            screenHeightSize = 'big'
        }

        return <RenderSelectAnswerQuestion screenHeight={screenHeightSize} selectAnswerQuestion={selectAnswerQuestion} questionAnswer={selectAnswerQuestionReport.questionAnswer} onCheckAnswer={this.onSelectAnswerQuestionAnswerSelected} />
    }


    render() {

        let { selectedAnswer, height } = this.state
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion]
        let questionReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === currentQuizQuestion.id))

        if (currentQuizQuestion instanceof DefaultQuestion) {
            let score = this.getScore()
            let defaultQuestionReport = questionReport as DefaultQuestionReport
            return (<Container fluid className='d-flex flex-column' style={{ minHeight: '100vh', background: 'white' }}>
                {this.renderHeader(score)}
                {this.renderDefaultQuestion(currentQuizQuestion, selectedAnswer, defaultQuestionReport)}
            </Container>)
        } else if (currentQuizQuestion instanceof SelectAnswerQuestion) {
            let score = this.getScore()
            let selectAnswerQuestionReport = questionReport as SelectAnswerQuestionReport
            return (<Container fluid className='d-flex flex-column' style={{ minHeight: '100vh', background: 'white' }}>
                {this.renderHeader(score)}
                {this.renderSelectAnswerQuestion(currentQuizQuestion, selectAnswerQuestionReport, height)}
            </Container>)
        } else if (currentQuizQuestion instanceof MemoryQuestion) {
            var screenHeight = 'small'
            // se for celular com tamanho medio
            if (height > SMALL_HEIGHT && height <= MEDIUM_HEIGHT) {
                screenHeight = 'medium'
            }
            // se for tablet ou computador
            else if (height > MEDIUM_HEIGHT) {
                screenHeight = 'big'
            }
            return <Container fluid className='d-flex flex-column' style={{ minHeight: '100vh', background: 'white' }}>
                <MemoryGameComponent title={currentQuizQuestion.title} images={currentQuizQuestion.images} screenSize={screenHeight} onMemoryGameFinished={(attempts) => this.onMemoryGameFinished(currentQuizQuestion as MemoryQuestion, attempts)} onExit={() => this.onFullScreenExit()} showExit={false} />
            </Container>
        } else if (currentQuizQuestion instanceof WordGuessQuestion) {
            var screenHeight = 'small'
            // se for celular com tamanho medio
            if (height > SMALL_HEIGHT && height <= MEDIUM_HEIGHT) {
                screenHeight = 'medium'
            }
            // se for tablet ou computador
            else if (height > MEDIUM_HEIGHT) {
                screenHeight = 'big'
            }
            return <WordGuessComponent company={this.props.company} title={currentQuizQuestion.title} word={currentQuizQuestion.word} height={screenHeight} onWordGuessFinished={(attempts) => this.onWordGuessFinished(currentQuizQuestion as WordGuessQuestion, attempts)} onExit={() => this.onFullScreenExit()} showExitButton={false} />
        }
    }
}

interface AnswerComponentProps {
    answerText: string,
    selectedAnswer: number,
    answerNumber: number,
    questionAnswer: QuestionAnswer
    answersCount: number
    screenHeight: string,
    onAnswerSelected: (answerNumber: number) => void
}

export class AnswerComponent extends Component<AnswerComponentProps> {

    renderDiv(style: CSSProperties, icon: IconDefinition | undefined = undefined, screenHeight: string) {
        var paddingTop = 5, paddingBottom = 5, paddingLeft = 2, paddingRight = 2, marginTop = 5, marginBottom = 5
        if (screenHeight === 'medium') {
            paddingTop = 15; paddingBottom = 15; paddingLeft = 5; paddingRight = 5; marginTop = 10; marginBottom = 10
        } else if (screenHeight === 'big') {
            paddingTop = 30; paddingBottom = 30; paddingLeft = 20; paddingRight = 20; marginTop = 20; marginBottom = 20
        }
        return (
            <div onClick={() => { this.props.onAnswerSelected(this.props.answerNumber) }} style={{ ...style, paddingTop, paddingBottom, paddingLeft, paddingRight, marginTop, marginBottom }}>
                <strong>{this.props.answerText}</strong> {this.renderIcon(icon)}
            </div>
        )
    }

    renderIcon(icon: IconDefinition | undefined = undefined) {
        if (icon) return (<FontAwesomeIcon icon={icon} size='2x' />)
    }

    render() {
        if (this.props.selectedAnswer === this.props.answerNumber) {
            if (this.props.questionAnswer === QuestionAnswer.WrongAnswer) {
                return this.renderDiv(questionWrongAnswerStyle, faTimesCircle, this.props.screenHeight)
            } else {
                return this.renderDiv(questionCorrectAnswerStyle, faCheckCircle, this.props.screenHeight)
            }
        } else {
            return this.renderDiv(questionDefaultStyle, undefined, this.props.screenHeight)
        }
    }
}

const questionDefaultStyle: CSSProperties = {
    textAlign: 'center',
    display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white',
    color: 'black', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}

const questionWrongAnswerStyle: CSSProperties = {
    textAlign: 'center', borderColor: 'red', borderStyle: 'solid', borderWidth: 1,
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    color: 'white', background: 'red', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}

const questionCorrectAnswerStyle: CSSProperties = {
    textAlign: 'center', borderColor: 'green', borderStyle: 'solid', borderWidth: 1,
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    color: 'white', background: 'green', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}