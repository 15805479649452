import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import TokenHelper from '../../../../auth/TokenHelper';
import AdminModuleAccessComponentService from '../../../../services/admin/adminModuleAccessComponentService';
import { applyCpfMask, validateCPF, validateEmail } from '../../../../utils/checkCpfAndEmail';
import config from '../../../../config';
import ModuleUserAccess from '../../../../models/ModuleUserAccess';

interface Props {
    primaryProperty: string,
    moduleId: string,
    moduleAccessAdded: (access: ModuleUserAccess) => void,
}


const AddModuleAccessComponent = (props: Props) => {

    const { moduleId, primaryProperty, moduleAccessAdded } = props

    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [primaryPropertyValue, setPrimaryPropertyValue] = useState<string>('');

    const addModuleAccess = async (primaryPropertyValue: string) => {
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            setIsLoading(true)
            setError(undefined)
            let adminModuleAccessService = new AdminModuleAccessComponentService()
            let moduleUserAccess = await adminModuleAccessService.addModuleUserAccess(token, config.endpoint, moduleId, primaryPropertyValue)
            moduleAccessAdded(moduleUserAccess)
        } catch(error) {
            setIsLoading(false)
            setError(error.toString())
        }
    }

    const renderDocInput = (primaryPropertyValue: string) => {
        let isDocValid = validateCPF(primaryPropertyValue)
        return (<FormGroup>
            <Label style={{ fontFamily: 'Montserrat' }} for="docTitle">CPF</Label>
            <Input style={{ fontFamily: 'Montserrat' }} name="title" id="docTitle" value={primaryPropertyValue} onChange={(event: any) => setPrimaryPropertyValue(applyCpfMask(event.target.value))} />
            {!isDocValid && <div style={{ fontFamily: 'Montserrat', fontSize: 'small', color: 'red', marginTop: 2 }}><i>Por favor digite um CPF válido</i></div>}
        </FormGroup>)
    }

    const renderEmailInput = (primaryPropertyValue: string) => {
        return (<FormGroup>
            <Label style={{ fontFamily: 'Montserrat' }} for="docTitle">Email</Label>
            <Input style={{ fontFamily: 'Montserrat' }} name="title" id="docTitle" value={primaryPropertyValue} onChange={(event: any) => setPrimaryPropertyValue(event.target.value.toLowerCase())} />
        </FormGroup>)
    }

    const isInputInvalid = (primaryProperty: string, primaryPropertyValue: string) => {
        if (primaryProperty === 'doc') {
            return !validateCPF(primaryPropertyValue)
        } else if (primaryProperty === 'email') {
            return !validateEmail(primaryPropertyValue)
        } else {
            return false
        }
    }

    return <Row>
        {error && <Row><Col md={{ size: 8, offset: 2 }}><Alert color="danger">{alert}</Alert></Col></Row>}
        <Col className="d-flex flex-column justify-content-center" md={{ size: 8, offset: 2 }}>
            <Form>
                {primaryProperty === 'doc' && renderDocInput(primaryPropertyValue)}
                {primaryProperty === 'email' && renderEmailInput(primaryPropertyValue)}
            </Form>
            {isLoading && <div style={{ fontSize: 12, marginLeft: 5, marginTop: 5, color: '#1d3256' }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faSave} />Salvando...</div>}
            {!isLoading && <Button color='primary' block disabled={isInputInvalid(primaryProperty, primaryPropertyValue)} onClick={() => addModuleAccess(primaryPropertyValue)}>Adicionar</Button>}
        </Col>
    </Row>

}

export default AddModuleAccessComponent