import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Alert, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as _ from 'lodash'
import axios from 'axios'

import LoadingScreen from '../loading'
import InviteUserComponent from './InviteUserComponent'
import config from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faLock, faListOl, faCheckCircle, faArrowLeft, faInfoCircle, faComments, faPlayCircle, faSignInAlt, faFill, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';

import * as ROUTES from '../../constants/routes'
import ContentService from '../../services/contentService';
import TokenHelper from '../../auth/TokenHelper';
import Module, { Frequency } from '../../models/Module';
import { SubModule, SubModuleQuiz, SubModuleTypes } from '../../models/SubModule'
import { UserMaxScoreInSubModule } from '../../models/Scores';
import LibraryContent, { LibraryContentType, LinkContent } from '../../models/Library';
import Versus from '../../images/versus.svg'
import ProgressBar from '../../components/ProgressBar'
import RankingSvg from '../../images/ranking.svg'
import ContentSvg from '../../images/content.svg'
import StarSvg from '../../images/star.svg'
import LinkSvg from '../../images/link.svg'
import LiveSvg from '../../images/live.svg'
import FileSvg from '../../images/file.svg'
import PDFPreview from '../../components/pdf/PDFPreview_v2';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useUser } from '../../hooks/useUser';
import UserModuleFeedback from '../../models/UserModuleFeedback';
import { RespondModuleFeedbackModal } from './RespondModuleFeedbackModal';

export const SelectedScreen = {
    default: 'default',
    library: 'library',
    playRealtime: 'playrealtime',
    showLink: 'showlink',
    showLive: 'showlive',
    showFile: 'showFile',
}

interface State {
    error: string | undefined;
    isLoading: boolean;
    module: Module | undefined;
    subModules: Array<SubModule>;
    userModuleFeedback?: UserModuleFeedback;
    userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>
    userQuizAttempts: { [key: string]: number }
    modalMessage: string | undefined
    libraryContent: Array<LibraryContent>
    selectedScreen: string
    externalResourceLink?: string
    startLivestream?: { broadcaster: boolean, token: string }
    isForumEnabled: boolean
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    module: undefined,
    userModuleFeedback: undefined,
    subModules: [],
    userMaxScoreInSubModules: [],
    userQuizAttempts: {},
    modalMessage: undefined,
    libraryContent: [],
    selectedScreen: SelectedScreen.default,
    startLivestream: undefined,
    isForumEnabled: false
};

const SubModuleSelectionScreen = () => {

    const navigate = useNavigate()
    const [state, setState] = useState({ ...INITIAL_STATE })
    const { width, height } = useWindowDimensions();
    const { companyId } = useParams();
    const [searchParams] = useSearchParams();
    const moduleId = searchParams.get('moduleId')
    const { user, company, noUser, noCompany } = useUser()
    const [showAddFeedbackModal, setShowAddFeedbackModal] = useState(false)

    useEffect(() => {
        if (company && user) {
            if (moduleId) {
                loadModuleAndSubModules(company.id, moduleId)
            } else {
                setState({ ...state, error: 'Missing moduleId in query.' })
            }
        }
        if (noCompany || noUser) {
            if (companyId === 'app') {
                setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.', isLoading: false })
            } else {
                navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
            }
        }
    }, [company, user, noCompany, noUser, moduleId])

    const loadModuleAndSubModules = async (companyId: string, moduleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let { module, subModules, userMaxScoreInSubModules, userQuizAttempts, libraryContent, isForumEnabled, userModuleFeedback } = await contentService.getCompanyModuleAndSubmodules(token, config.endpoint, companyId, moduleId)
            setState({ ...state, module, subModules, userMaxScoreInSubModules, userQuizAttempts, isLoading: false, libraryContent, isForumEnabled, userModuleFeedback })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadModuleAndSubModules(companyId, moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const goToModuleRanking = (companyId: string, moduleId: string) => {
        navigate(`/${companyId}${ROUTES.SELECT_MODULE_RANKING}/${moduleId}`)
    }

    const goToModuleForum = (companyId: string, moduleId: string) => {
        navigate(`/${companyId}${ROUTES.FORUM}/${moduleId}`)
    }

    const playSubModule = (module: Module, subModule: SubModule, userQuizAttempts: { [key: string]: number }) => {
        let userQuizAttemptsInSubModule = userQuizAttempts[subModule.id] || 0
        if (subModule.type === SubModuleTypes.QUIZ && module.limiterQuiz > 0 && userQuizAttemptsInSubModule >= module.limiterQuiz) {
            var modalLimitText = 'Você já atingiu o limite de vezes que pode jogar o Quiz no período.'
            if (module.frequency === Frequency.Day) {
                modalLimitText = `Você já jogou ${subModule.title} ${userQuizAttemptsInSubModule} vezes hoje. Amanhã você pode jogar novamente.`
            } else if (module.frequency === Frequency.Week) {
                modalLimitText = `Você já jogou ${subModule.title} ${userQuizAttemptsInSubModule} vezes essa semana. Semana que vem você pode jogar novamente.`
            } else if (module.frequency === Frequency.Month) {
                modalLimitText = `Você já jogou ${subModule.title} ${userQuizAttemptsInSubModule} vezes esse mês. Mês que vem você pode jogar novamente.`
            }
            setState({ ...state, modalMessage: modalLimitText })
        } else {
            navigate(`/${module.companyId}${ROUTES.GAME}?subModuleId=${subModule.id}&subModuleType=${subModule.type}`)
        }
    }

    const playRealtime = (companyId: string, subModuleId: string, roomId: string) => {
        navigate(`/${companyId}${ROUTES.REALTIME_GAME}?subModuleId=${subModuleId}&roomId=${roomId}`)
    }

    const downloadCertificate = async (moduleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let response = await fetch(`${config.endpoint}/mobile/content/certificate/${moduleId}`, {
                method: "GET",
                headers: { 'Authorization': `Token ${token}` }
            })
            let buffer = await response.arrayBuffer()
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "certificado.png");
            document.body.appendChild(link);
            link.click();
            setState({ ...state, isLoading: false, error: undefined })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                downloadCertificate(moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }

    }

    const enterLiveStream = async (broadcaster: boolean, subModuleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let url = `${config.endpoint}/integration/tokenForApp`
            let result = await axios.post(url, { subModuleId }, { headers: { 'Authorization': `Token ${token}` } })
            setState({ ...state, isLoading: false, selectedScreen: SelectedScreen.showLive, startLivestream: { broadcaster, token: result.data.data.token } })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) enterLiveStream(broadcaster, subModuleId)
            else setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const saveUserFeedback = async (feedback: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let userModuleFeedback = await contentService.saveUserModuleFeedback(token, config.endpoint, company!.id, state.module!.id, feedback)
            setState({ ...state, isLoading: false, error: undefined, userModuleFeedback })
            setShowAddFeedbackModal(false)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                saveUserFeedback(feedback)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const renderError = (error: string) => {
        return (
            <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                {error}
            </Alert>
        );
    }

    const renderSubModuleSelection = (module: Module, subModules: Array<SubModule>, userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>, userQuizAttempts: { [key: string]: number }, hasLibraryContent: boolean, finishedAllSubModules: boolean) => {
        let realtimeQuiz = _.head(subModules.filter(subModule => subModule.type === SubModuleTypes.QUIZ)) && !module.hideRealtimeQuizButton
        let isOdd = module.feedback ? (subModules.length + 1) % 2 : subModules.length % 2
        return (
            <Container style={{ height: '100%', overflow: 'auto', zIndex: 1, }}>
                <Row className="d-flex justify-content-start">
                    <Col className="d-flex flex-column" md={{ size: 10, offset: 1 }} style={{ color: '#353f45', maxHeight: 100 }}>
                        <div style={{ textAlign: 'center', marginTop: 20 }}><b>SELECIONE UMA FASE PARA JOGAR</b></div>
                        <div style={{ textAlign: 'center', marginBottom: 5, fontSize: 'small' }}><i><FontAwesomeIcon className="mr-1" icon={faInfoCircle} />Para concluir uma fase você precisa atingir 50% da pontuação</i></div>
                    </Col>
                    {renderSubModules(module, subModules, userMaxScoreInSubModules, userQuizAttempts)}
                    {module.feedback && <Col lg={{ size: 5, offset: !isOdd ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
                        <div className="d-flex flex-row align-items-center" style={{ position: 'relative', marginTop: 15, marginBottom: 15, borderRadius: 5, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', minHeight: 180, paddingLeft: 10, background: '#FFFFFFDA' }} onClick={() => { if(finishedAllSubModules && !userModuleFeedback) setShowAddFeedbackModal(true) }}>
                            <FontAwesomeIcon color='#353f45' size='3x' icon={faClipboardCheck} />
                            <div className="d-flex flex-column justify-content-center p-2" style={{ width: '100%', paddingBottom: 5, color: '#353f45' }}>
                                <div style={{ fontSize: 'large' }}><b>{module.feedback.title}</b></div>
                                <div style={{ fontSize: 'large', marginTop: 2, marginBottom: 5 }}>{module.feedback.description}</div>
                                {module.feedback.blockCertificate && !state.userModuleFeedback && <div style={{ fontSize: 'small', marginBottom: 5, color: 'rgb(99, 113, 121)' }}><FontAwesomeIcon icon={faInfoCircle} /> Você precisa responder para liberar o certificado.</div>}
                                {!!state.userModuleFeedback && <div className="mr-auto" style={{ color: '#40b34f' }}><FontAwesomeIcon icon={faCheckCircle} /> <b>Concluído</b></div>}
                            </div>
                            <div style={{ margin: 'auto', padding: 5 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                        </div>
                    </Col>}
                    {realtimeQuiz && <Col lg={{ size: 5, offset: isOdd ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
                        <div key='realtime-quiz' className="d-flex flex-row align-items-center" style={{ position: 'relative', marginTop: 15, marginBottom: 15, borderRadius: 5, boxShadow: '2px 4px 8px 2px rgba(66,66,33,0.5)', minHeight: 180, background: '#FFFFFFDA', paddingLeft: 5, paddingRight: 5, paddingTop: 10, paddingBottom: 10 }} onClick={() => { setState({ ...state, selectedScreen: SelectedScreen.playRealtime }) }}>
                            <img style={{ width: '4em', height: '4em', marginLeft: 5 }} alt='dois jogadores jogando juntos' src={Versus} />
                            <div className="d-flex flex-column justify-content-center" style={{ width: '100%', color: '#353f45' }}>
                                <div style={{ marginLeft: 5, marginTop: 5, flex: 1 }}><b>Desafie um colega</b></div>
                                <div style={{ marginLeft: 5, marginTop: 5, flex: 1 }}>Selecione um colega online e jogue contra ele.</div>
                            </div>
                            <div style={{ margin: 'auto', padding: 5 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                        </div>
                    </Col>}
                    {hasLibraryContent && <Col lg={{ size: 5, offset: realtimeQuiz ? (isOdd ? 1 : 0) : (isOdd ? 0 : 1) }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
                        <div key='realtime-quiz' className="d-flex flex-row align-items-center" style={{ position: 'relative', marginTop: 15, marginBottom: 15, borderRadius: 5, boxShadow: '2px 4px 8px 2px rgba(66,66,33,0.5)', minHeight: 180, background: 'white', paddingLeft: 5, paddingRight: 5, paddingTop: 10, paddingBottom: 10 }} onClick={() => { setState({ ...state, selectedScreen: SelectedScreen.library }) }}>
                            <img style={{ width: '4em', height: '4em', marginLeft: 5 }} alt='dois jogadores jogando juntos' src={ContentSvg} />
                            <div className="d-flex flex-column justify-content-center" style={{ width: '100%', color: '#353f45' }}>
                                <div style={{ marginLeft: 5, marginTop: 5, flex: 1 }}><b>Conteúdo Complementar</b></div>
                                <div style={{ marginLeft: 5, marginTop: 5, flex: 1 }}>Material adicional para aprender mais.</div>
                            </div>
                            <div style={{ margin: 'auto', padding: 5 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                        </div>
                    </Col>}
                </Row>
            </Container>)
    }

    const renderSubModules = (module: Module, subModules: Array<SubModule>, userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>, userQuizAttempts: { [key: string]: number }) => {
        let sortedSubModules = subModules.sort((a, b) => { return a.pos - b.pos })
        return sortedSubModules.map((subModule, index) => {
            let userMaxScoreInSubModule = _.head(userMaxScoreInSubModules.filter(maxScore => maxScore.subModuleId === subModule.id))
            let scoreToGetCertificate = Math.round(subModule.maxScore / 2)
            // hasCertificate pode ser undefined. se for entao tem certificado. só quando ele é falso é q n tem certificado
            var showScoreToGetCertificate = module.hasCertificate !== false
            if (showScoreToGetCertificate) {
                // se é um subModule que não tem pontos não precisa mostrar
                if (subModule.maxScore > 0) showScoreToGetCertificate = userMaxScoreInSubModule ? userMaxScoreInSubModule!.score < scoreToGetCertificate : true
                else showScoreToGetCertificate = false
            }
            // se tiver algum nivel q ele nao terminou antes, ele nao pode fazer este
            var isLocked = sortedSubModules.filter(data => data.pos < subModule.pos).reduce((res, subModule) => {
                // se o subModule nao tiver pontuacao nao tem problema
                if (subModule.maxScore === 0) return res
                let maxScoreInSubModule = _.first(userMaxScoreInSubModules.filter(userMaxScore => userMaxScore.subModuleId === subModule.id))
                if (!maxScoreInSubModule || maxScoreInSubModule.score === 0) {
                    res = true
                }
                return res
            }, false)
            let userScoreInSubModule = userMaxScoreInSubModule ? userMaxScoreInSubModule!.score : undefined
            let finished = userScoreInSubModule !== undefined ? userScoreInSubModule >= scoreToGetCertificate : false
            let progressBarColor = finished ? '#40b34f' : (userScoreInSubModule && userScoreInSubModule > (subModule.maxScore * 0.25) ? '#f1b938' : '#f0652f')
            let isOdd = index % 2

            if (subModule.type === SubModuleTypes.LINK) {
                let linkContent = subModule.externalResourceUrl
                return (<Col key={subModule.id} lg={{ size: 5, offset: isOdd ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }} onClick={() => { if (!isLocked) { setState({ ...state, selectedScreen: SelectedScreen.showLink, externalResourceLink: linkContent }) } }}>
                    <div className="d-flex flex-row align-items-center" style={{ position: 'relative', marginTop: 15, marginBottom: 15, borderRadius: 5, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', minHeight: 180, paddingLeft: 10, background: '#FFFFFFDA' }}>
                        <img style={{ width: '4em', height: '4em', marginLeft: 5, marginRight: 10 }} alt='link externo' src={LinkSvg} />
                        <div className="d-flex flex-column justify-content-center pt-2" style={{ width: '100%', paddingBottom: 5, color: '#353f45' }}>
                            {isLocked && (<div style={{ fontSize: 14, marginTop: 5, color: '#353f45' }}><FontAwesomeIcon icon={faLock} /> Termine as fases anteriores para poder jogar</div>)}
                            <div style={{ fontSize: 'large' }}><b>Fase {index + 1}</b></div>
                            <div style={{ fontSize: 'large', marginTop: 2, marginBottom: 5 }}>{subModule.title.toUpperCase()}</div>
                        </div>
                        <div style={{ margin: 'auto', padding: 5 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                        {isLocked && <div style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, background: '#AAAAAA48', margin: 'auto', borderRadius: 5 }} />}
                    </div>
                </Col>)
            } else if (subModule.type === SubModuleTypes.FILE) {
                let linkContent = subModule.externalResourceUrl
                return (<Col key={subModule.id} lg={{ size: 5, offset: isOdd ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }} onClick={() => { if (!isLocked) { setState({ ...state, selectedScreen: SelectedScreen.showFile, externalResourceLink: linkContent }) } }}>
                    <div className="d-flex flex-row align-items-center" style={{ position: 'relative', marginTop: 15, marginBottom: 15, borderRadius: 5, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', minHeight: 180, paddingLeft: 10, background: '#FFFFFFDA' }}>
                        <img style={{ width: '4em', height: '4em', marginLeft: 5, marginRight: 10 }} alt='arquivo' src={FileSvg} />
                        <div className="d-flex flex-column justify-content-center pt-2" style={{ width: '100%', paddingBottom: 5, color: '#353f45' }}>
                            {isLocked && (<div style={{ fontSize: 14, marginTop: 5, color: '#353f45' }}><FontAwesomeIcon icon={faLock} /> Termine as fases anteriores para poder jogar</div>)}
                            <div style={{ fontSize: 'large' }}><b>Fase {index + 1}</b></div>
                            <div style={{ fontSize: 'large', marginTop: 2, marginBottom: 5 }}>{subModule.title.toUpperCase()}</div>
                        </div>
                        <div style={{ margin: 'auto', padding: 5 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                        {isLocked && <div style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, background: '#AAAAAA48', margin: 'auto', borderRadius: 5 }} />}
                    </div>
                </Col>)
            } else if (subModule.type === SubModuleTypes.LIVE) {
                return (<Col key={subModule.id} lg={{ size: 5, offset: isOdd ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }} >
                    <div className="d-flex flex-row align-items-center" style={{ position: 'relative', marginTop: 15, marginBottom: 15, borderRadius: 5, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', minHeight: 180, paddingLeft: 10, background: '#FFFFFFDA' }}>
                        <img style={{ width: '4em', height: '4em', marginLeft: 5, marginRight: 10 }} alt='link externo' src={LiveSvg} />
                        <div className="d-flex flex-column justify-content-center pt-2" style={{ flex: 1, paddingBottom: 5, color: '#353f45' }}>
                            <div style={{ fontSize: 'large' }}><b>Fase {index + 1}</b></div>
                            <div style={{ fontSize: 'large', marginTop: 2, marginBottom: 5 }}>{subModule.title.toUpperCase()}</div>
                            {user && user.admin && <Button onClick={() => enterLiveStream(true, subModule.id)} style={{ marginTop: 5, maxWidth: 170 }} color="primary"><FontAwesomeIcon icon={faPlayCircle} style={{ marginRight: 5 }} />Iniciar Live</Button>}
                            <Button outline style={{ marginTop: 5, maxWidth: 170 }} onClick={() => enterLiveStream(false, subModule.id)} color="danger"><FontAwesomeIcon icon={faSignInAlt} style={{ marginRight: 5 }} />Participar</Button>
                        </div>
                    </div>
                </Col>)
            } else if (subModule.type === SubModuleTypes.QUIZ) {
                let subModuleQuiz = subModule as SubModuleQuiz
                let userQuizAttemptsInSubModule = userQuizAttempts[subModule.id] || 0

                let quizLimitText = ''
                if (module.limiterQuiz > 0) {
                    if (userQuizAttemptsInSubModule >= module.limiterQuiz) {
                        if (userQuizAttemptsInSubModule > 0)
                            if (module.frequency === Frequency.Day) {
                                quizLimitText = `Você já jogou ${userQuizAttemptsInSubModule} vezes hoje. Amanhã você pode jogar novamente.`
                            } else if (module.frequency === Frequency.Week) {
                                quizLimitText = `Você já jogou ${userQuizAttemptsInSubModule} vezes essa semana. Semana que vem você pode jogar novamente.`
                            } else if (module.frequency === Frequency.Month) {
                                quizLimitText = `Você já jogou ${userQuizAttemptsInSubModule} vezes esse mês. Mês que vem você pode jogar novamente.`
                            }
                    } else {
                        if (module.frequency === Frequency.Day) {
                            quizLimitText = `Você já jogou ${userQuizAttemptsInSubModule} vezes hoje. Você pode jogar até ${module.limiterQuiz} vezes por dia.`
                        } else if (module.frequency === Frequency.Week) {
                            quizLimitText = `Você já jogou ${userQuizAttemptsInSubModule} vezes essa semana. Você pode jogar até ${module.limiterQuiz} vezes por semana.`
                        } else if (module.frequency === Frequency.Month) {
                            quizLimitText = `Você já jogou ${userQuizAttemptsInSubModule} vezes esse mês. Você pode jogar até ${module.limiterQuiz} vezes por mês.`
                        }
                    }
                }

                return (<Col key={subModule.id} lg={{ size: 5, offset: isOdd ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }} onClick={() => { if (!isLocked) playSubModule(module, subModule, userQuizAttempts) }}>
                    <div className="d-flex flex-row" style={{ position: 'relative', marginTop: 15, marginBottom: 15, borderRadius: 5, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', minHeight: 180, paddingLeft: 10, background: '#FFFFFFDA' }}>
                        <div className="d-flex flex-column justify-content-center pt-2" style={{ width: '100%' }}>
                            {isLocked && (<div style={{ fontSize: 14, marginTop: 5, color: '#353f45' }}><FontAwesomeIcon icon={faLock} /> Termine as fases anteriores para poder jogar</div>)}
                            <div style={{ fontSize: 'large', color: '#353f45', }}><b>Fase {index + 1}</b></div>
                            <div style={{ fontSize: 'large', color: '#353f45', marginTop: 2 }}>{subModule.title.toUpperCase()}</div>
                            {renderProgressText(userScoreInSubModule, subModule.maxScore, progressBarColor)}
                            {subModule.maxScore > 0 && <div style={{ marginTop: 5, marginBottom: 10 }}><ProgressBar bgColor={progressBarColor} completed={(userScoreInSubModule || 0) / subModule.maxScore * 100} height={8} radius={2} /></div>}
                            {subModule.maxScore === 0 && <div style={{ marginTop: 5, marginBottom: 10 }}><ProgressBar bgColor={progressBarColor} completed={userScoreInSubModule !== undefined ? 100 : 0} height={8} radius={2} /></div>}
                            {quizLimitText.length > 0 && <div style={{ fontSize: 'small', marginBottom: 5, color: 'rgb(99, 113, 121)' }}><FontAwesomeIcon icon={faPlayCircle} /> {quizLimitText}</div>}
                            {!subModuleQuiz.mistakesAllowed && <div style={{ fontSize: 'small', marginBottom: 5, color: 'rgb(99, 113, 121)' }}><FontAwesomeIcon icon={faInfoCircle} /> O quiz termina se você errar.</div>}
                            <div className="d-flex mb-2" style={{ color: '#353f45' }}>
                                {finished && <div className="mr-auto" style={{ color: '#40b34f' }}><FontAwesomeIcon icon={faCheckCircle} /> <b>Concluído</b></div>}
                                {!finished && subModule.maxScore > 0 && <div className="mr-auto" style={{ fontSize: 'small' }}><b>{scoreToGetCertificate} pontos</b> para concluir</div>}
                            </div>
                        </div>
                        <div style={{ margin: 'auto', padding: 5 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                        {isLocked && <div style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, background: '#AAAAAA48', margin: 'auto', borderRadius: 5 }} />}
                    </div>
                </Col>)
            } else {
                return (<Col key={subModule.id} lg={{ size: 5, offset: isOdd ? 0 : 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }} onClick={() => { if (!isLocked) playSubModule(module, subModule, userQuizAttempts) }}>
                    <div className="d-flex flex-row" style={{ position: 'relative', marginTop: 15, marginBottom: 15, borderRadius: 5, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', minHeight: 180, paddingLeft: 10, background: '#FFFFFFDA' }}>
                        <div className="d-flex flex-column justify-content-center pt-2" style={{ width: '100%' }}>
                            {isLocked && (<div style={{ fontSize: 14, marginTop: 5, color: '#353f45' }}><FontAwesomeIcon icon={faLock} /> Termine as fases anteriores para poder jogar</div>)}
                            <div style={{ fontSize: 'large', color: '#353f45', }}><b>Fase {index + 1}</b></div>
                            <div style={{ fontSize: 'large', color: '#353f45', marginTop: 2 }}>{subModule.title.toUpperCase()}</div>
                            {renderProgressText(userScoreInSubModule, subModule.maxScore, progressBarColor)}
                            {subModule.maxScore > 0 && <div style={{ marginTop: 5, marginBottom: 10 }}><ProgressBar bgColor={progressBarColor} completed={(userScoreInSubModule || 0) / subModule.maxScore * 100} height={8} radius={2} /></div>}
                            {subModule.maxScore === 0 && <div style={{ marginTop: 5, marginBottom: 10 }}><ProgressBar bgColor={progressBarColor} completed={userScoreInSubModule !== undefined ? 100 : 0} height={8} radius={2} /></div>}
                            <div className="d-flex mb-2" style={{ color: '#353f45' }}>
                                {finished && <div className="mr-auto" style={{ color: '#40b34f' }}><FontAwesomeIcon icon={faCheckCircle} /> <b>Concluído</b></div>}
                                {!finished && subModule.maxScore > 0 && <div className="mr-auto" style={{ fontSize: 'small' }}><b>{scoreToGetCertificate} pontos</b> para concluir</div>}
                            </div>
                        </div>
                        <div style={{ margin: 'auto', padding: 5 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                        {isLocked && <div style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, background: '#AAAAAA48', margin: 'auto', borderRadius: 5 }} />}
                    </div>
                </Col>)
            }
        })
    }

    const renderProgressText = (userScore: number | undefined, maxScorePossible: number, color: string) => {
        // #353f45
        if (maxScorePossible === 0) {
            return (<div className='d-flex align-items-center' style={{ marginTop: 10 }}>
                <img style={{ width: '0.95em', height: '0.95em', paddingBottom: 2, opacity: color === '#40b34f' ? 1 : 0.25 }} alt='ícone de troféu' src={StarSvg} />
            </div>)
        } else {
            return (<div className='d-flex align-items-center' style={{ marginTop: 10 }}>
                <img style={{ width: '0.95em', height: '0.95em', paddingBottom: 2, opacity: color === '#40b34f' ? 1 : 0.25 }} alt='ícone de troféu' src={StarSvg} />
                <div style={{ marginLeft: 2, color: userScore === 0 ? '#868686' : color, height: '1em', lineHeight: '1em', fontSize: '1em' }}><b>{userScore || 0}</b> de <b>{maxScorePossible}</b></div>
            </div>)
        }
    }

    const renderHeader = (module: Module) => {
        return (<Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { navigate(`/${module.companyId}${ROUTES.MAIN}`) }}><FontAwesomeIcon color='#0b0c10' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={module.pic} />
                    {/* Nunca fiz tanta gambiarra na minha vida igual essa pra fazer o texto to nome da missão ficar em uma linha e terminar com ... */}
                    <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap' }}>
                        <div style={{ color: '#353f45', verticalAlign: 'middle', fontSize: 16, marginLeft: 10 }}><b>Missão</b></div>
                        <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#353f45', verticalAlign: 'middle', marginLeft: 10 }}>{module.title}</div>
                    </div>
                    <Button className="d-none d-md-flex flex-column align-items-center" color='none' outline onClick={() => { goToModuleRanking(module.companyId, module.id) }}><img alt='ícone de ranking' style={{ width: '2em', height: '2em' }} src={RankingSvg} /> <span style={{ verticalAlign: 'middle', color: '#5e9fca', fontSize: 'small' }}><b>Ranking</b></span></Button>
                </div>
            </Col>
        </Row>)
    }

    const renderModal = (message: string) => {
        return (<Row>
            <Col md={{ size: 10, offset: 1 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => setState({ ...state, modalMessage: undefined })}>
                    <ModalBody>{message}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => setState({ ...state, modalMessage: undefined })}>OK</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    const renderLibraryContent = (content: LibraryContent) => {
        if (content.type === LibraryContentType.Link) {
            let linkContent = content as LinkContent
            return (<div key={content.id} className="d-flex flex-row justify-content-center align-items-center" style={{ position: 'relative', marginTop: 10, marginBottom: 5, borderRadius: 10, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', minHeight: 100 }} onClick={() => linkContent.openNewTab ? window.open(linkContent.link, "_blank") : window.open(linkContent.link)}>
                <FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} icon={faInfoCircle} size='3x' />
                <div className="d-flex flex-column justify-content-center" style={{ width: '100%', color: 'black', marginLeft: 5 }}>
                    <div style={{ fontSize: 16, marginTop: 5 }}>{content.title}</div>
                    <div style={{ fontSize: 12, marginTop: 5, marginRight: 5, marginBottom: 5, flex: 1 }}>{linkContent.description}</div>
                </div>
                <div style={{ margin: 'auto', padding: 5 }} ><FontAwesomeIcon color='#1E3F64' size='2x' icon={faAngleRight} /></div>
            </div>)
        } else {
            return <div key={content.id} />
        }
    }

    const renderLibrary = (module: Module, libraryContent: Array<LibraryContent>) => {
        let sortedContent = libraryContent.sort((a, b) => { return a.pos - b.pos })
        return <Container fluid className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
            <Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                <Col md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <Button color='none' outline onClick={() => { setState({ ...state, selectedScreen: SelectedScreen.default }) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={module.pic} />
                        <div style={{ color: '#1d3256', verticalAlign: 'middle', textAlign: 'center', fontSize: 16, marginLeft: 10 }}>Conteúdo Complementar</div>
                    </div>
                </Col>
            </Row>
            <Row style={{ flex: 1, overflow: 'auto', marginTop: 15 }}>
                <Col className="d-flex flex-column" md={{ size: 10, offset: 1 }}>
                    {sortedContent.map(content => renderLibraryContent(content))}
                </Col>
            </Row>
        </Container>
    }

    const renderShowLink = (subModuleLink: string) => {
        return <Container fluid className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
            <Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                <Col md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <Button color='none' outline onClick={() => { setState({ ...state, selectedScreen: SelectedScreen.default, externalResourceLink: undefined }) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={LinkSvg} />
                        <div style={{ color: '#1d3256', verticalAlign: 'middle', textAlign: 'center', fontSize: 16, marginLeft: 10 }}>Link Externo</div>
                    </div>
                </Col>
            </Row>
            <Row style={{ flex: 1, overflow: 'auto', marginTop: 5 }}>
                <Col className="d-flex flex-column" md={{ size: 10, offset: 1 }}>
                    <iframe title="link externo" style={{ width: '100%', height: '100%' }} src={subModuleLink} frameBorder="0"></iframe>
                </Col>
            </Row>
        </Container>
    }

    const renderShowFile = (file: string, width: number) => {
        return <Container fluid className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
            <Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                <Col md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <Button color='none' outline onClick={() => { setState({ ...state, selectedScreen: SelectedScreen.default, externalResourceLink: undefined }) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={FileSvg} />
                        <div style={{ color: '#1d3256', verticalAlign: 'middle', textAlign: 'center', fontSize: 16, marginLeft: 10 }}>Arquivo</div>
                    </div>
                </Col>
            </Row>
            <Row style={{ flex: 1, overflow: 'auto', marginTop: 5 }}>
                <Col>
                    <PDFPreview width={width * 0.95} filename={file} />
                </Col>
            </Row>
        </Container>
    }

    const renderLiveScreen = (broadcaster: boolean, token: string) => {
        return <Container fluid className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
            <Row>
                <Col md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <Button color='none' outline onClick={() => { setState({ ...state, selectedScreen: SelectedScreen.default, externalResourceLink: undefined }) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={LiveSvg} />
                        <div style={{ color: '#1d3256', verticalAlign: 'middle', textAlign: 'center', fontSize: 16, marginLeft: 10 }}>Live</div>
                    </div>
                </Col>
            </Row>
            <Row style={{ flex: 1, overflow: 'auto', marginTop: 5 }}>
                <Col className="d-flex flex-column" md={{ size: 10, offset: 1 }}>
                    <iframe title="link externo" allow="camera;microphone;display-capture" style={{ width: '100%', height: '100%' }} src={`${config.livestream}?id=${token}${broadcaster ? '&host=true' : ''}`} frameBorder="0"></iframe>
                </Col>
            </Row>
        </Container>
    }


    let { error, isLoading, module, subModules, userMaxScoreInSubModules,
        userQuizAttempts, modalMessage, selectedScreen, libraryContent,
        externalResourceLink, startLivestream, isForumEnabled, userModuleFeedback } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    // Usuario esta na tela de jogar em tempo real
    if (module && selectedScreen === SelectedScreen.library) return renderLibrary(module, libraryContent)
    if (company && module && user && selectedScreen === SelectedScreen.playRealtime) {
        let quiz = _.head(subModules.filter(subModule => subModule.type === SubModuleTypes.QUIZ))
        if (quiz) {
            return <InviteUserComponent company={company} module={module} subModule={quiz} user={user} goBack={() => setState({ ...state, selectedScreen: SelectedScreen.default })} playGame={playRealtime} />
        }
    }

    if (selectedScreen === SelectedScreen.showLink && externalResourceLink) return renderShowLink(externalResourceLink)
    if (selectedScreen === SelectedScreen.showLive && startLivestream) return renderLiveScreen(startLivestream.broadcaster, startLivestream.token)
    if (selectedScreen === SelectedScreen.showFile && externalResourceLink) return renderShowFile(externalResourceLink, width)

    // Lógica pra mostrar o botão de baixar certificado
    // hasCertificate pode ser undefined. se for entao tem certificado. só quando ele é falso é q n tem certificado
    var showDownloadCertificateButton = module && module.hasCertificate !== false

    // Verifica se tem quiz. Caso contrario ainda nao terminou de liberar as fases
    if (showDownloadCertificateButton && subModules.filter(subModule => subModule.type === SubModuleTypes.QUIZ).length === 0) {
        showDownloadCertificateButton = false
    }

    var  finishedAllSubModules = true

    subModules.filter(subModule => subModule.type !== SubModuleTypes.LINK).forEach(subModule => {
        let userMaxScoreInCurrentSubModule = _.head(userMaxScoreInSubModules.filter(maxScore => maxScore.subModuleId === subModule.id))
        if (!userMaxScoreInCurrentSubModule) {
            if (subModule.maxScore > 0) finishedAllSubModules = false
        } else if (userMaxScoreInCurrentSubModule.score < Math.round(subModule.maxScore / 2)) {
            finishedAllSubModules = false
        }
    })

    // Se tem quiz, verifica se jogou todos as fases
    if (showDownloadCertificateButton) {
        if(!finishedAllSubModules) showDownloadCertificateButton = false
        else if(module?.feedback?.blockCertificate && !userModuleFeedback) showDownloadCertificateButton = false
    }

    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container fluid className="d-flex flex-column" style={{ overflow: 'hidden', height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && renderError(error)}
        {module && renderHeader(module)}
        {company && module && subModules && renderSubModuleSelection(module, subModules, userMaxScoreInSubModules, userQuizAttempts, libraryContent.length > 0, finishedAllSubModules)}
        {module && <Row className='pt-2 pb-md-2 ' style={{ background: '#FFFFFFDA' }}>
            <Col className='d-block d-md-none mb-2' md={{ size: 10, offset: 1 }}>
                <Button style={{ paddingTop: 10, paddingBottom: 10, borderStyle: 'none', width: '100%' }} onClick={() => { goToModuleRanking(module!.companyId, module!.id) }}><FontAwesomeIcon icon={faListOl} /> Ranking da Missão</Button>
            </Col>
            {isForumEnabled && <Col className='mb-2' md={{ size: 10, offset: 1 }}>
                <Button color='primary' style={{ paddingTop: 10, paddingBottom: 10, borderStyle: 'none', width: '100%' }} onClick={() => { goToModuleForum(module!.companyId, module!.id) }}><FontAwesomeIcon icon={faComments} /> Fórum</Button>
            </Col>}
            {showDownloadCertificateButton && <Col className='mb-2' md={{ size: 10, offset: 1 }}>
                <Button color='success' style={{ paddingTop: 10, paddingBottom: 10, borderStyle: 'none', width: '100%' }} onClick={() => { downloadCertificate(module!.id) }}><FontAwesomeIcon icon={faCheckCircle} /> Baixar Certificado</Button>
            </Col>}
        </Row>}
        {modalMessage && renderModal(modalMessage)}
        {showAddFeedbackModal && module?.feedback && <RespondModuleFeedbackModal title={module.feedback.title} description={module.feedback.description} minCharCount={module.feedback.minCharCount} onClose={() => setShowAddFeedbackModal(false)} onSave={(feedback: string) => { saveUserFeedback(feedback) } } />}
    </Container>)

}

export default SubModuleSelectionScreen