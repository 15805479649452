import React, { Component, useEffect } from 'react';
import { Container, Row, Col, Button, Alert, Card, CardHeader, Label, CardBody, CardTitle, CardSubtitle, CardFooter, CardImg } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import * as _ from 'lodash'
import Module from '../../../models/Module';
import TokenHelper from '../../../auth/TokenHelper';
import AdminContentService from '../../../services/admin/adminContentService';
import config from '../../../config'
import LoadingScreen from '../../loading';
import { SubModule, SubModuleTypes } from '../../../models/SubModule';
import { DeckSubModuleFinishedReport, QuizQuestionFinishedReport, SubModuleFinishedReport } from '../../../models/SubModuleFinishedReport';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeckCard, { DeckCardTypes, MemoryCard, OrderedListCard, QuestionCard, SelectAnswerCard, WordSearchCard } from '../../../models/DeckCards';
import { MemoryCardReport, OrderedListCardReport, QuestionCardReport, SelectAnswerCardReport, WordSearchCardReport } from '../../../models/DeckCardsReport';
import { QuestionAnswer } from '../../../models/Question';
import ProgressBar from '../../../components/ProgressBar';
import QuizQuestion, { DefaultQuestion, MemoryQuestion, QuizQuestionType, SelectAnswerQuestion, WordGuessQuestion } from '../../../models/QuizQuestion';
import { DefaultQuestionReport, MemoryQuestionReport, SelectAnswerQuestionReport, WordGuessQuestionReport } from '../../../models/QuizQuestionsReport';
import SubModuleRating from '../../../models/SubModuleRating';
import { useUser } from '../../../hooks/useUser';


interface State {
    error: any | undefined;
    isLoading: boolean;
    module: Module | undefined;
    subModules: Array<SubModule>;
    reports: Array<SubModuleFinishedReport>
    subModuleRatings: Array<SubModuleRating>
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    module: undefined,
    subModules: [],
    reports: [],
    subModuleRatings: []
};

const ModuleContentReportScreen = () => {

    const navigate = useNavigate()
    const { moduleId } = useParams()
    const { company } = useUser()
    const [state, setState] = React.useState<State>(INITIAL_STATE)

    useEffect(() => {
        if (moduleId) {
            loadData(moduleId)
        }
    }, [moduleId])

    const loadData = async (moduleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let { module, subModules, reports, subModuleRatings } = await adminContentService.getModuleReport(token, config.endpoint, moduleId)
            setState({ ...state, module, subModules, reports, subModuleRatings, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadData(moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const renderHeader = (module: Module) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={module.pic} />
                    <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap' }}>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', fontSize: 16, marginLeft: 10 }}><b>Desempenho</b></div>
                        <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 10 }}>{module.title}</div>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    const renderFooter = (mistakes: number, corrects: number) => {
        let total = corrects + mistakes
        let percentage = corrects / total
        let progressBarColor = (percentage > 0.85) ? '#40b34f' : (percentage > 0.5 ? '#f1b938' : '#f0652f')
        return (<CardFooter>
            <div className='d-flex flex-column align-items-center' style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                <div style={{ fontFamily: 'Montserrat', color: '#353f45', fontSize: 'small', marginBottom: 5 }}><b>Acertos: {(percentage * 100).toFixed(1)}%</b></div>
                <ProgressBar bgColor={progressBarColor} completed={percentage * 100} height={8} radius={2} />
            </div>
        </CardFooter>)
    }

    // DECK CARDS
    const renderWordSearchCard = (card: WordSearchCard, mistakes: number, corrects: number) => {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <Card style={{ margin: 5 }}>
                <CardHeader><Label><strong>{card.pos + 1}</strong></Label> Caça-Palavras</CardHeader>
                <CardBody>
                    <CardTitle>
                        <div style={{ fontSize: '0.9em', fontFamily: 'Montserrat' }}>{card.title}</div>
                    </CardTitle>
                    <CardSubtitle>
                        <div style={{ fontSize: '0.9em', fontFamily: 'Montserrat' }}><b>{card.word}</b></div>
                    </CardSubtitle>
                </CardBody>
                {renderFooter(mistakes, corrects)}
            </Card>
        </Col>)
    }

    const renderQuestionCard = (card: QuestionCard, mistakes: number, corrects: number) => {
        let rightQuestionStyle: React.CSSProperties = { border: "1px solid green", fontSize: '0.9em', fontFamily: 'Montserrat', color: 'green', marginTop: 8, padding: 4 }
        let wrongQuestionStyle: React.CSSProperties = { border: "1px solid #eAeAeA", fontSize: '0.9em', fontFamily: 'Montserrat', color: '#808080', marginTop: 8, padding: 4 }
        let renderImgCard = (img: string) => <img alt='imagem do card' src={img} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em', marginRight: 5 }} />
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <Card style={{ margin: 5 }}>
                <CardHeader><Label><strong>{card.pos + 1}</strong></Label> Pergunta</CardHeader>
                <CardBody>
                    <CardTitle>
                        <div style={{ fontSize: '0.9em', fontFamily: 'Montserrat' }}><b>{card.question}</b></div>
                    </CardTitle>
                    <CardSubtitle>
                        {<div style={card.correctAnswer === 0 ? rightQuestionStyle : wrongQuestionStyle}>{card.imgAnswer0 && renderImgCard(card.imgAnswer0)}{card.answer0}</div>}
                        {<div style={card.correctAnswer === 1 ? rightQuestionStyle : wrongQuestionStyle} >{card.imgAnswer1 && renderImgCard(card.imgAnswer1)}{card.answer1}</div>}
                        {card.answer2 && <div style={card.correctAnswer === 2 ? rightQuestionStyle : wrongQuestionStyle}>{card.imgAnswer2 && renderImgCard(card.imgAnswer2)}{card.answer2}</div>}
                        {card.answer3 && <div style={card.correctAnswer === 3 ? rightQuestionStyle : wrongQuestionStyle} >{card.imgAnswer3 && renderImgCard(card.imgAnswer3)}{card.answer3}</div>}
                    </CardSubtitle>
                    {card.feedbackText && <CardSubtitle style={{ marginTop: 10 }}>
                        <div style={{ fontFamily: 'Montserrat' }}>{card.feedbackText}</div>
                    </CardSubtitle>}
                    {card.feedbackImageUrl && <CardImg style={{ objectFit: 'contain', maxHeight: 150, maxWidth: 3000, marginTop: 10 }} top src={card.feedbackImageUrl} alt="Card image cap" />}
                </CardBody>
                {renderFooter(mistakes, corrects)}
            </Card>
        </Col>)
    }

    const renderSelectAnswerCard = (card: SelectAnswerCard, mistakes: number, corrects: number) => {
        let rightAnswerStyle: React.CSSProperties = { border: "1px solid green", fontSize: '0.9em', fontFamily: 'Montserrat', color: 'green', marginTop: 8, padding: 4 }
        let wrongAnswerStyle: React.CSSProperties = { border: "1px solid #eAeAeA", fontSize: '0.9em', fontFamily: 'Montserrat', color: '#808080', marginTop: 8, padding: 4 }
        let renderImgCard = (img: string) => <img alt='imagem do card' src={img} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em' }} />
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <Card style={{ margin: 5 }}>
                <CardHeader><Label><strong>{card.pos + 1}</strong></Label> Selecionar Resposta</CardHeader>
                <CardBody>
                    <CardTitle>
                        <div style={{ fontSize: '0.9em', fontFamily: 'Montserrat' }}><b>{card.question}</b></div>
                    </CardTitle>
                    <CardSubtitle>
                        {<div style={_.includes(card.correctAnswers, 0) ? rightAnswerStyle : wrongAnswerStyle}>{card.imgAnswer0 && renderImgCard(card.imgAnswer0)} {card.answer0}</div>}
                        {<div style={_.includes(card.correctAnswers, 1) ? rightAnswerStyle : wrongAnswerStyle} >{card.imgAnswer1 && renderImgCard(card.imgAnswer1)} {card.answer1}</div>}
                        {card.answer2 && <div style={_.includes(card.correctAnswers, 2) ? rightAnswerStyle : wrongAnswerStyle}>{card.imgAnswer2 && renderImgCard(card.imgAnswer2)} {card.answer2}</div>}
                        {card.answer3 && <div style={_.includes(card.correctAnswers, 3) ? rightAnswerStyle : wrongAnswerStyle} >{card.imgAnswer3 && renderImgCard(card.imgAnswer3)} {card.answer3}</div>}
                    </CardSubtitle>
                    {card.feedbackText && <CardSubtitle style={{ marginTop: 10 }}>
                        <div style={{ fontFamily: 'Montserrat' }}>{card.feedbackText}</div>
                    </CardSubtitle>}
                    {card.feedbackImageUrl && <CardImg style={{ objectFit: 'contain', maxHeight: 150, maxWidth: 3000, marginTop: 10 }} top src={card.feedbackImageUrl} alt="Card image cap" />}
                </CardBody>
                {renderFooter(mistakes, corrects)}
            </Card>
        </Col>)
    }

    const renderMemoryCard = (card: MemoryCard, mistakes: number, corrects: number) => {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <Card style={{ margin: 5 }}>
                <CardHeader><Label><strong>{card.pos + 1}</strong></Label> Memória</CardHeader>
                <CardBody>
                    {card.title && <CardTitle> <div style={{ fontFamily: 'Montserrat' }}><b>{card.title}</b></div></CardTitle>}
                    <div className='d-flex flex-column'>
                        {card.images.map(cardImage => <div className='d-flex mt-2'>
                            <img alt='imagem do jogo da memoria' style={{ objectFit: 'cover', height: 50, width: 50 }} src={cardImage.imgUrl} />
                            <div className='ml-2' style={{ fontFamily: 'Montserrat', fontSize: 'small' }}>{cardImage.text}</div>
                        </div>)}
                    </div>
                </CardBody>
                {renderFooter(mistakes, corrects)}
            </Card>
        </Col>)
    }

    const renderOrderedListCard = (card: OrderedListCard, mistakes: number, corrects: number) => {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <Card style={{ margin: 5 }}>
                <CardHeader><Label><strong>{card.pos + 1}</strong></Label> Lista Ordenada</CardHeader>
                <CardBody>
                    <CardTitle>
                        <div style={{ fontSize: '0.9em', fontFamily: 'Montserrat' }}><b>{card.title}</b></div>
                    </CardTitle>
                    <CardSubtitle>
                        {card.list.map((listElement: { position: number, text: string }) => { return (<div style={{ fontSize: '0.9em', fontFamily: 'Montserrat' }} key={listElement.position.toString()}>{listElement.position} - {listElement.text}</div>) })}
                    </CardSubtitle>
                </CardBody>
                {renderFooter(mistakes, corrects)}
            </Card>
        </Col>)
    }

    // QUESTION CARDS
    const renderDefaultQuestionCard = (card: DefaultQuestion, mistakes: number, corrects: number) => {
        let rightQuestionStyle: React.CSSProperties = { border: "1px solid green", fontSize: '0.9em', fontFamily: 'Montserrat', color: 'green', marginTop: 8, padding: 4 }
        let wrongQuestionStyle: React.CSSProperties = { border: "1px solid #eAeAeA", fontSize: '0.9em', fontFamily: 'Montserrat', color: '#808080', marginTop: 8, padding: 4 }
        let renderImgCard = (img: string) => <img alt='imagem do card' src={img} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em', marginRight: 5 }} />
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <Card style={{ margin: 5 }}>
                <CardHeader><Label><strong>Pergunta</strong></Label></CardHeader>
                <CardBody>
                    <CardTitle>
                        <div style={{ fontSize: '0.9em', fontFamily: 'Montserrat' }}><b>{card.question}</b></div>
                    </CardTitle>
                    <CardSubtitle>
                        {<div style={card.correctAnswer === 0 ? rightQuestionStyle : wrongQuestionStyle}>{card.imgAnswer0 && renderImgCard(card.imgAnswer0)}{card.answer0}</div>}
                        {<div style={card.correctAnswer === 1 ? rightQuestionStyle : wrongQuestionStyle} >{card.imgAnswer1 && renderImgCard(card.imgAnswer1)}{card.answer1}</div>}
                        {card.answer2 && <div style={card.correctAnswer === 2 ? rightQuestionStyle : wrongQuestionStyle}>{card.imgAnswer2 && renderImgCard(card.imgAnswer2)}{card.answer2}</div>}
                        {card.answer3 && <div style={card.correctAnswer === 3 ? rightQuestionStyle : wrongQuestionStyle} >{card.imgAnswer3 && renderImgCard(card.imgAnswer3)}{card.answer3}</div>}
                    </CardSubtitle>
                </CardBody>
                {renderFooter(mistakes, corrects)}
            </Card>
        </Col>)
    }

    const renderSelectAnswerQuestionCard = (card: SelectAnswerQuestion, mistakes: number, corrects: number) => {
        let rightAnswerStyle: React.CSSProperties = { border: "1px solid green", fontSize: '0.9em', fontFamily: 'Montserrat', color: 'green', marginTop: 8, padding: 4 }
        let wrongAnswerStyle: React.CSSProperties = { border: "1px solid #eAeAeA", fontSize: '0.9em', fontFamily: 'Montserrat', color: '#808080', marginTop: 8, padding: 4 }
        let renderImgCard = (img: string) => <img alt='imagem do card' src={img} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em' }} />
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <Card style={{ margin: 5 }}>
                <CardHeader><Label><strong>Pergunta com Várias Respostas</strong></Label></CardHeader>
                <CardBody>
                    <CardTitle>
                        <div style={{ fontSize: '0.9em', fontFamily: 'Montserrat' }}><b>{card.question}</b></div>
                    </CardTitle>
                    <CardSubtitle>
                        {<div style={_.includes(card.correctAnswers, 0) ? rightAnswerStyle : wrongAnswerStyle}>{card.imgAnswer0 && renderImgCard(card.imgAnswer0)} {card.answer0}</div>}
                        {<div style={_.includes(card.correctAnswers, 1) ? rightAnswerStyle : wrongAnswerStyle} >{card.imgAnswer1 && renderImgCard(card.imgAnswer1)} {card.answer1}</div>}
                        {card.answer2 && <div style={_.includes(card.correctAnswers, 2) ? rightAnswerStyle : wrongAnswerStyle}>{card.imgAnswer2 && renderImgCard(card.imgAnswer2)} {card.answer2}</div>}
                        {card.answer3 && <div style={_.includes(card.correctAnswers, 3) ? rightAnswerStyle : wrongAnswerStyle} >{card.imgAnswer3 && renderImgCard(card.imgAnswer3)} {card.answer3}</div>}
                    </CardSubtitle>
                </CardBody>
                {renderFooter(mistakes, corrects)}
            </Card>
        </Col>)
    }

    const renderWordGuessCard = (card: WordGuessQuestion, mistakes: number, corrects: number) => {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <Card style={{ margin: 5 }}>
                <CardHeader><Label></Label>Forca</CardHeader>
                <CardBody>
                    <CardTitle> <div style={{ fontFamily: 'Montserrat' }}><b>{card.title}</b></div></CardTitle>
                    <CardSubtitle><div style={{ fontFamily: 'Montserrat' }}>{card.word}</div></CardSubtitle>
                </CardBody>
                {renderFooter(mistakes, corrects)}
            </Card>
        </Col>)
    }

    const renderQuestionMemoryCard = (card: MemoryQuestion, mistakes: number, corrects: number) => {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <Card style={{ margin: 5 }}>
                <CardHeader><Label></Label>Memória</CardHeader>
                {card.title && <CardTitle> <div style={{ fontFamily: 'Montserrat' }}><b>{card.title}</b></div></CardTitle>}
                <div className='d-flex flex-column'>
                    {card.images.map(cardImage => <div className='d-flex mt-2'>
                        <img alt='imagem do jogo da memoria' style={{ objectFit: 'cover', height: 50, width: 50 }} src={cardImage.imgUrl} />
                        <div className='ml-2' style={{ fontFamily: 'Montserrat', fontSize: 'small' }}>{cardImage.text}</div>
                    </div>)}
                </div>
                {renderFooter(mistakes, corrects)}
            </Card>
        </Col>)
    }

    const renderData = (subModules: Array<SubModule>, reports: Array<SubModuleFinishedReport>, ratings: Array<SubModuleRating>) => {
        return subModules.filter(subModule => subModule.type === SubModuleTypes.DECK || subModule.type === SubModuleTypes.QUIZ).sort((a, b) => a.pos - b.pos).map(subModule => {
            let subModuleReports = reports.filter(report => report.subModuleId === subModule.id)
            let subModuleRatings = ratings.filter(rating => rating.subModuleId === subModule.id)
            if (subModule.type === SubModuleTypes.DECK) return renderDeckCardReportData(subModule, subModuleReports.map(report => report as DeckSubModuleFinishedReport), subModuleRatings)
            else return renderQuizReportData(subModule, subModuleReports.map(report => report as QuizQuestionFinishedReport), subModuleRatings)
        })
    }

    const renderDeckCardReportData = (subModule: SubModule, reports: Array<DeckSubModuleFinishedReport>, ratings: Array<SubModuleRating>) => {
        let reportDataArray: Array<{ card: DeckCard, mistakes: number, corrects: number }> = []
        for (var i = 0; i < reports.length; i++) {
            let subModuleReport = reports[i]
            subModuleReport.deckCardsReport.forEach(deckCardReport => {
                var deckCardReportData = _.first(reportDataArray.filter(data => data.card.id === deckCardReport.card.id))
                if (!deckCardReportData) {
                    deckCardReportData = { card: deckCardReport.card, mistakes: 0, corrects: 0 }
                    reportDataArray.push(deckCardReportData)
                }
                if (deckCardReport.card.type === DeckCardTypes.Memory) {
                    let memoryDeckCardReport = deckCardReport as MemoryCardReport
                    if (memoryDeckCardReport.allImagesFound) deckCardReportData.corrects += 1
                    else deckCardReportData.mistakes += 1
                } else if (deckCardReport.card.type === DeckCardTypes.OrderedList) {
                    let orderedListDeckCardReport = deckCardReport as OrderedListCardReport
                    if (orderedListDeckCardReport.listOrderedCorrect) deckCardReportData.corrects += 1
                    else deckCardReportData.mistakes += 1
                } else if (deckCardReport.card.type === DeckCardTypes.Question) {
                    let questionDeckCardReport = deckCardReport as QuestionCardReport
                    if (questionDeckCardReport.questionAnswer === QuestionAnswer.CorrectAnswer) deckCardReportData.corrects += 1
                    else deckCardReportData.mistakes += 1
                } else if (deckCardReport.card.type === DeckCardTypes.SelectAnswer) {
                    let selectAnswerCardReport = deckCardReport as SelectAnswerCardReport
                    if (selectAnswerCardReport.questionAnswer === QuestionAnswer.CorrectAnswer) deckCardReportData.corrects += 1
                    else deckCardReportData.mistakes += 1
                } else if (deckCardReport.card.type === DeckCardTypes.WordSearch) {
                    let wordSearchCardReport = deckCardReport as WordSearchCardReport
                    if (wordSearchCardReport.wordFound) deckCardReportData.corrects += 1
                    else deckCardReportData.mistakes += 1
                }
            })
        }
        let likes = ratings.filter(rating => rating.rating > 4).length
        let total = ratings.length
        let percentage = likes / total
        let progressBarColor = (percentage > 0.85) ? '#40b34f' : (percentage > 0.5 ? '#f1b938' : '#f0652f')
        return <Row key={subModule.id}>
            <Col md={{ size: 12 }}>
                <div className="d-flex flex-column justify-content-center pt-2 mb-2 mt-2 ml-2" style={{ width: '100%' }}>
                    <div style={{ fontSize: 'large', color: '#353f45', fontFamily: 'Montserrat', }}><b>Fase {subModule.pos}</b></div>
                    <div style={{ fontSize: 'large', color: '#353f45', fontFamily: 'Montserrat', marginTop: 2 }}>{subModule.title.toUpperCase()}</div>
                </div>
            </Col>
            <Col md={{ size: 12 }}>
                <div className='d-flex flex-column justify-content-center mb-3 ml-2' style={{ maxWidth: 250 }}>
                    <div style={{ fontFamily: 'Montserrat', color: '#353f45', fontSize: 'small', marginBottom: 5 }}><b>{(percentage * 100).toFixed(1)}% Gostaram</b></div>
                    <ProgressBar bgColor={progressBarColor} completed={percentage * 100} height={8} radius={2} />
                </div>
            </Col>
            {reportDataArray.sort((a, b) => {
                let totalA = a.corrects + a.mistakes
                let percentageA = a.corrects / totalA
                let totalB = b.corrects + b.mistakes
                let percentageB = b.corrects / totalB
                return (percentageA - percentageB) * 100
            }).map(reportData => {
                let card = reportData.card
                if (card instanceof WordSearchCard) {
                    return renderWordSearchCard(card, reportData.mistakes, reportData.corrects)
                } else if (card instanceof QuestionCard) {
                    return renderQuestionCard(card, reportData.mistakes, reportData.corrects)
                } else if (card instanceof SelectAnswerCard) {
                    return renderSelectAnswerCard(card, reportData.mistakes, reportData.corrects)
                } else if (card instanceof MemoryCard) {
                    return renderMemoryCard(card, reportData.mistakes, reportData.corrects)
                } else if (card instanceof OrderedListCard) {
                    return renderOrderedListCard(card, reportData.mistakes, reportData.corrects)
                } else return <div />
            })}
            <Col key={subModule.id} md={{ size: 12 }}>
                <div style={{ height: 1, background: 'gray', marginTop: 5, marginBottom: 10 }} />
            </Col>
        </Row>
    }

    const renderQuizReportData = (subModule: SubModule, reports: Array<QuizQuestionFinishedReport>, ratings: Array<SubModuleRating>) => {
        let reportDataArray: Array<{ card: QuizQuestion, mistakes: number, corrects: number }> = []
        for (var i = 0; i < reports.length; i++) {
            let subModuleReport = reports[i]
            subModuleReport.quizQuestionsReport.forEach(quizQuestionReportInValidation => {
                // #1 - verifica se o card desse report está no Array
                var reportData = _.first(reportDataArray.filter(currentDataInArray => {
                    // se não é do mesmo tipo já não tem como ser igual
                    if (currentDataInArray.card.type !== quizQuestionReportInValidation.quizQuestion.type) return false
                    // pega o card do report
                    let quizQuestion = quizQuestionReportInValidation.quizQuestion
                    // verifica o tipo do card e faz a verificação de acordo com o tipo
                    if (quizQuestion instanceof DefaultQuestion) {
                        let defaultQuestionInValidation = quizQuestion as DefaultQuestion
                        let defaultQuestionInArray = currentDataInArray.card as DefaultQuestion
                        // se o titulo da pergunta for diferente não tem como ser a mesma
                        if (defaultQuestionInValidation.question !== defaultQuestionInArray.question) return false
                        // verifica as respostas
                        return (defaultQuestionInValidation.answer0 === defaultQuestionInArray.answer0) &&
                            (defaultQuestionInValidation.answer1 === defaultQuestionInArray.answer1) &&
                            (defaultQuestionInValidation.answer2 === defaultQuestionInArray.answer2) &&
                            (defaultQuestionInValidation.answer3 === defaultQuestionInArray.answer3)
                    } else if (quizQuestion instanceof SelectAnswerQuestion) {
                        let selectAnswerInValidation = quizQuestion as SelectAnswerQuestion
                        let selectAnswerInArray = currentDataInArray.card as SelectAnswerQuestion
                        // se o titulo da pergunta for diferente não tem como ser a mesma
                        if (selectAnswerInValidation.question !== selectAnswerInArray.question) return false
                        // verifica as respostas
                        return (selectAnswerInValidation.answer0 === selectAnswerInArray.answer0) &&
                            (selectAnswerInValidation.answer1 === selectAnswerInArray.answer1) &&
                            (selectAnswerInValidation.answer2 === selectAnswerInArray.answer2) &&
                            (selectAnswerInValidation.answer3 === selectAnswerInArray.answer3)
                    } else if (quizQuestion instanceof MemoryQuestion) {
                        let memoryQuestionInValidation = quizQuestion as MemoryQuestion
                        let memoryQuestionInArray = currentDataInArray.card as MemoryQuestion
                        // se o titulo for diferente não tem como ser a mesma
                        if (memoryQuestionInValidation.title !== memoryQuestionInArray.title) return false
                        // verifica as imagens
                        if (memoryQuestionInValidation.images.length !== memoryQuestionInArray.images.length) return false
                        for (var j = 0; j < memoryQuestionInValidation.images.length; j++) {
                            let validationImage = memoryQuestionInValidation.images[0]
                            let inArrayImage = memoryQuestionInArray.images[0]
                            if (validationImage.imgUrl !== inArrayImage.imgUrl || validationImage.text !== inArrayImage.text) return false
                        }
                        return true
                    } else if (quizQuestion instanceof WordGuessQuestion) {
                        let wordGuessInValidation = quizQuestion as WordGuessQuestion
                        let wordGuessInArray = currentDataInArray.card as WordGuessQuestion
                        // se o titulo for diferente não tem como ser a mesma
                        if (wordGuessInValidation.title !== wordGuessInArray.title) return false
                        // se a palavra for diferente não é igual
                        return wordGuessInValidation.word === wordGuessInArray.word
                    } else return false
                }))
                // Se ainda não tem essa questão no array, coloca ela
                if (!reportData) {
                    reportData = { card: quizQuestionReportInValidation.quizQuestion, mistakes: 0, corrects: 0 }
                    reportDataArray.push(reportData)
                }
                // agora q ja foi verificado se precisava colocar no array, tem qeu verificar se a pessoa acertou ou errou
                // OH SHIT, HERE WE GO AGAIN
                if (quizQuestionReportInValidation.quizQuestion.type === QuizQuestionType.Default) {
                    let defaultQuestionReport = quizQuestionReportInValidation as DefaultQuestionReport
                    if (defaultQuestionReport.questionAnswer === QuestionAnswer.CorrectAnswer) reportData.corrects += 1
                    else reportData.mistakes += 1
                } else if (quizQuestionReportInValidation.quizQuestion.type === QuizQuestionType.MemoryGame) {
                    let memoryQuestionReport = quizQuestionReportInValidation as MemoryQuestionReport
                    if (memoryQuestionReport.allImagesFound) reportData.corrects += 1
                    else reportData.mistakes += 1
                } else if (quizQuestionReportInValidation.quizQuestion.type === QuizQuestionType.SelectAnswer) {
                    let selectAnswerQuestionReport = quizQuestionReportInValidation as SelectAnswerQuestionReport
                    if (selectAnswerQuestionReport.questionAnswer === QuestionAnswer.CorrectAnswer) reportData.corrects += 1
                    else reportData.mistakes += 1
                } else if (quizQuestionReportInValidation.quizQuestion.type === QuizQuestionType.WordGuess) {
                    let wordGuessReport = quizQuestionReportInValidation as WordGuessQuestionReport
                    if (wordGuessReport.wordFound) reportData.corrects += 1
                    else reportData.mistakes += 1
                }
            })
        }
        let likes = ratings.filter(rating => rating.rating > 4).length
        let total = ratings.length
        let percentage = likes / total
        let progressBarColor = (percentage > 0.85) ? '#40b34f' : (percentage > 0.5 ? '#f1b938' : '#f0652f')
        return <Row key={subModule.id}>
            <Col md={{ size: 12 }}>
                <div className="d-flex flex-column justify-content-center pt-2 mb-2 mt-2 ml-2" style={{ width: '100%' }}>
                    <div style={{ fontSize: 'large', color: '#353f45', fontFamily: 'Montserrat', }}><b>Fase {subModule.pos}</b></div>
                    <div style={{ fontSize: 'large', color: '#353f45', fontFamily: 'Montserrat', marginTop: 2 }}>{subModule.title.toUpperCase()}</div>
                </div>
            </Col>
            <Col md={{ size: 12 }}>
                <div className='d-flex flex-column justify-content-center mb-3 ml-2' style={{ maxWidth: 250 }}>
                    <div style={{ fontFamily: 'Montserrat', color: '#353f45', fontSize: 'small', marginBottom: 5 }}><b>{(percentage * 100).toFixed(1)}% Gostaram</b></div>
                    <ProgressBar bgColor={progressBarColor} completed={percentage * 100} height={8} radius={2} />
                </div>
            </Col>
            {reportDataArray.sort((a, b) => {
                let totalA = a.corrects + a.mistakes
                let percentageA = a.corrects / totalA
                let totalB = b.corrects + b.mistakes
                let percentageB = b.corrects / totalB
                return (percentageA - percentageB) * 100
            }).map(reportData => {
                let card = reportData.card
                if (card instanceof DefaultQuestion) {
                    return renderDefaultQuestionCard(card, reportData.mistakes, reportData.corrects)
                } else if (card instanceof SelectAnswerQuestion) {
                    return renderSelectAnswerQuestionCard(card, reportData.mistakes, reportData.corrects)
                } else if (card instanceof WordGuessQuestion) {
                    return renderWordGuessCard(card, reportData.mistakes, reportData.corrects)
                } else if (card instanceof MemoryQuestion) {
                    return renderQuestionMemoryCard(card, reportData.mistakes, reportData.corrects)
                } else return <div />
            })}
            <Col key={subModule.id} md={{ size: 12 }}>
                <div style={{ height: 1, background: 'gray', marginTop: 5, marginBottom: 10 }} />
            </Col>
        </Row>
    }

    let { error, isLoading, module, subModules, reports, subModuleRatings } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    return (<Container className="d-flex flex-column" fluid style={{ height: '100vh', background: 'white' }}>
        {error && <Alert color="danger">{error}</Alert>}
        {module && renderHeader(module)}
        {renderData(subModules, reports, subModuleRatings)}
    </Container>)

}

export default ModuleContentReportScreen