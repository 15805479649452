import { faComment, faExclamationTriangle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import TokenHelper from '../../auth/TokenHelper';

import User from '../../models/User';
import ForumService from '../../services/forumService';
import config from '../../config';
import ForumComment from '../../models/ForumComment';

interface UserCommentComponentProps {
    user: User
    moduleId: string
    newCommentCallback: (comment: ForumComment) => void
}

const UserCommentComponent = (props: UserCommentComponentProps) => {
    let { user, moduleId, newCommentCallback } = props
    const [userComment, setUserComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    let postComment = async () => {
        setIsLoading(true)
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setError('Usuário não possui token de acesso.')
            let forumService = new ForumService()
            let newComment = await forumService.addComment(token, config.endpoint, user.companyId, moduleId, user.username, userComment, [], 0, undefined, user.pic)
            newCommentCallback(newComment)
            setIsLoading(false); setUserComment('')
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                postComment()
            } else {
                setError(error.toString())
                setIsLoading(false)
            }
        }
    }

    return <Col className="d-flex flex-column pt-2" md={{ size: 8, offset: 2 }}>
            <div className="d-flex" style={{ marginBottom: 5, marginLeft: 5, marginRight: 5, paddingBottom: 5, borderRadius: 5, boxShadow: '2px 4px 4px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', padding: 10 }}>
                {user.pic ? <img style={{ width: '4em', height: '4em', borderRadius: '2em', objectFit: 'cover' }} alt='prof pic' src={user.pic} /> : <FontAwesomeIcon color='black' icon={faUserCircle} size='4x' />}
                <div className="d-flex flex-column ml-2" style={{ flex: 1 }}>
                    <div style={{ color: '#353f45', verticalAlign: 'middle', marginBottom: 5 }}><b>{user.username}</b></div>
                    <Input type="textarea" value={userComment} name="userComment" id="userComment" placeholder="Escreva um comentário" onChange={(event: any) => setUserComment(event.target.value)} />
                    <Button color="info" disabled={isLoading || userComment.length === 0} outline style={{ marginTop: 5, alignSelf: 'flex-start', marginBottom: 5 }} onClick={() => postComment()}>{isLoading ? 'Postando...' : 'Postar'}<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faComment} /></Button>
                    {error.length > 0 && <div style={{ color: 'red', fontSize: 'small' }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faExclamationTriangle} />{error}</div>}
                </div>
            </div>
        </Col>
}

export default UserCommentComponent