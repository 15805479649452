import { faArrowLeft, faCheckCircle, faPlusCircle, faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, Spinner } from "reactstrap";
import { getVideosFromPrompt } from './services'
import FileHelper from "../../files/FileHelper";

interface UploadVideoComponentProps {
    selectedVideoUrl: string | undefined
    onVideoSelected: (videoUrl: string) => void
    onExit: () => void
}

export default function UploadVideoComponent(props: UploadVideoComponentProps) {

    const uploadButtonRef = useRef<HTMLInputElement>(null)
    const [error, setError] = useState<string | undefined>(undefined)
    const [promptInput, setPromptInput] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [orientation, setOrientation] = useState<string>('landscape')
    const [orientationDropdownOpen, setOrientationDropdownOpen] = useState<boolean>(false)
    const [videos, setVideos] = useState<Array<{ videoUrl: string, thumbnail: string }>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [searching, setSearching] = useState<boolean>(false)
    const fileHelper = new FileHelper()

    const processFile = async (file: File) => {
        try {
            let fileDataUrl = await fileHelper.getFileDataUrl(file)
            props.onVideoSelected(fileDataUrl)
        } catch (error) {
            setError(error)
        }
    }

    const searchForVideoPrompt = async (promptInput: string, orientation: string, page: number) => {
        setLoading(true)
        setError(undefined)
        try {
            let newVideos = await getVideosFromPrompt(promptInput, orientation, page)
            if (page > 1) {
                setVideos([...newVideos, ...videos])
            } else {
                setVideos(newVideos)
            }
            setLoading(false)
            setPage(page)
        } catch (error) {
            setError(error.toString())
            setLoading(false)
        }
    }

    const loadVideo = (videoUrl: string) => {
        setLoading(true)
        const xhr = new XMLHttpRequest();
        xhr.open('GET', videoUrl, true);
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            if (xhr.status === 200) {
                console.log('status 200')
                const myBlob = xhr.response;
                let loadedUrl = (window.webkitURL || window.URL).createObjectURL(myBlob);
                props.onVideoSelected(loadedUrl)
                props.onExit()
            } else {
                setError(event.toString())
            }
            setLoading(false)
        }
        xhr.onerror = (err) => { setError(err.toString()); setLoading(false) }
        xhr.send()
    }

    const renderPromptVideos = (videos: Array<{ videoUrl: string, thumbnail: string }>) => {
        return videos.map(({ videoUrl, thumbnail }, index) => {
            return <div key={index} className="d-flex flex-column mt-2 mb-2 p-1" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div className="d-flex flex-column align-items-center">
                    <video controls width="100%" src={videoUrl} poster={thumbnail} />
                    <Button style={{ borderStyle: 'none' }} outline color="primary" onClick={() => { loadVideo(videoUrl) }}>Selecionar <FontAwesomeIcon icon={faCheckCircle} /></Button>
                </div>
            </div>
        })
    }

    if (loading) { return <div className="d-flex justify-content-center align-items-center bg-light p-2 m-2 rounded border"><Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />{' '}</div> }

    if (searching) {
        return <div className="bg-light p-2 m-2 rounded border">
            <Button className="mb-2" color="light" size="sm" onClick={() => setSearching(false)}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
            <div className="d-flex flex-column justify-content-center">
                {error && <div>{error}</div>}
                <Form>
                    <FormGroup>
                        <InputGroup>
                            <Input name="videoPrompt" id="videoPrompt" placeholder="Descrição do video" value={promptInput} onChange={(event) => setPromptInput(event.target.value)} />
                            <Button onClick={() => searchForVideoPrompt(promptInput, orientation, 1)}><FontAwesomeIcon icon={faSearch} /></Button>
                        </InputGroup>
                    </FormGroup>
                    <div className="d-flex mb-2">
                        <Dropdown className="me-1" isOpen={orientationDropdownOpen} toggle={() => setOrientationDropdownOpen(!orientationDropdownOpen)} >
                            <DropdownToggle caret>{orientation === 'landscape' ? 'Horizontal' : 'Vertical'}</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => setOrientation('landscape')}>Horizontal</DropdownItem>
                                <DropdownItem onClick={() => setOrientation('portrait')}>Vertical</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        {videos.length > 0 && <Button onClick={() => searchForVideoPrompt(promptInput, orientation, page + 1)}><FontAwesomeIcon icon={faPlusCircle} /> Mais Videos</Button>}
                    </div>
                </Form>
            </div>
            <div style={{ maxHeight: 500, overflowY: 'scroll' }}>
                {videos.length > 0 && renderPromptVideos(videos)}
            </div>
        </div>
    } else {
        return <div className="bg-light p-2 m-2 rounded border">
            <Button color="light" size="sm" onClick={() => props.onExit()}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
            {error && <div>{error}</div>}
            <input id="myInput" type="file" ref={uploadButtonRef} style={{ display: 'none' }} onChange={(event) => { if (event.target.files) processFile(event.target.files[0]) }} />
            <div className="d-flex flex-column justify-content-center m-2">
                {props.selectedVideoUrl && <video controls style={{ height: 180, width: '100%', marginBottom: 5, marginTop: 10, objectFit: 'contain' }} src={props.selectedVideoUrl} />}
                {!props.selectedVideoUrl && <div className="rounded bg-white border" style={{ height: 180, marginBottom: 5, marginTop: 10 }} />}
                <Button outline onClick={() => uploadButtonRef && uploadButtonRef.current?.click()}><FontAwesomeIcon icon={faUpload} /> Fazer Upload</Button>
                <Button className="mt-1" outline onClick={() => setSearching(true)}><FontAwesomeIcon icon={faSearch} /> Buscar Video</Button>
            </div>
        </div>
    }
}