import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'
import EnterComponent, { EnterComponentNavigation } from '../../components/enter'
import Cookies from 'universal-cookie';

const EnterScreen = () => {

    const navigate = useNavigate()
    const companyId = useParams().companyId
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    // http://localhost:3001/app/enter/-apoioecolimp

    let goToMain = useCallback((primaryProperty?: string) => {
        if (primaryProperty) {
            navigate(`/${companyId}${ROUTES.LOGIN}?primaryProperty=${primaryProperty}`)
        } else {
            navigate(`/${companyId}${ROUTES.MAIN}`)
        }
    }, [navigate])

    let navigation: EnterComponentNavigation = {
        goToCreateAccount: (primaryPropertyValue?: string) => {
            if (primaryPropertyValue) {
                navigate(`/${companyId}${ROUTES.SIGN_UP}?primaryProperty=${primaryPropertyValue}`)
            } else {
                navigate(`/${companyId}${ROUTES.SIGN_UP}/`)
            }
        }
    }

    // bug bizarro EnterComponent que chama a funcao antes do navigate antes do hook estar montado e nao funciona
    if(!isMounted) return null


    return <EnterComponent companyId={companyId!} navigation={navigation} goToMain={goToMain} />
}

export default EnterScreen

