export default class Company {
    id: string
    mainColor?: string
    secondaryColor?: string
    name: string
    pic: string
    backgroundImages?: Array<string>
    backgroundPosition: string

    constructor(data: any) {
        this.id = data.id
        this.name = data.name
        this.pic = data.pic
        this.mainColor = data.mainColor
        this.secondaryColor = data.secondaryColor
        this.backgroundImages = data.backgroundImages
        this.backgroundPosition = data.backgroundPosition || 'left top'
    }

    getData(): any {
        return {
            id: this.id,
            name: this.name,
            pic: this.pic,
            mainColor: this.mainColor,
            secondaryColor: this.secondaryColor,
            backgroundImages: this.backgroundImages,
            backgroundPosition: this.backgroundPosition
        }
    }
}