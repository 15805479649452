import Module from "../models/Module";
import { UserMaxScoreInSubModule } from "../models/Scores";
import { SubModule, SubModuleTypes } from "../models/SubModule";

export const getModulesFromCategory = (modules: Array<Module>, categoryId: string): Array<Module> => {
    return modules.filter(module => { return module.categories.filter(id => { return id === categoryId }).length > 0 })
}

export const getUserScoreInModule = (moduleId: string, userMaxScoreInSubModules:  UserMaxScoreInSubModule[]) => {
    return userMaxScoreInSubModules.filter(data => data.moduleId === moduleId).reduce((acc, val) => {
        acc += val.score
        return acc
    }, 0)
}

export const getSubModulesFinished = (subModules: Array<SubModule>, userMaxScoreInSubModules:  UserMaxScoreInSubModule[]) => {
    return subModules.reduce((acc: number, subModule) => {
        if (subModule.type === SubModuleTypes.LINK) return acc
        //if (subModule.maxScore === 0 && ) acc += 1
        else {
            let userMaxScoreInSubModule = userMaxScoreInSubModules.filter(maxScore => { return maxScore.subModuleId === subModule.id })
            if (userMaxScoreInSubModule.length > 0 && userMaxScoreInSubModule[0].score >= (subModule.maxScore / 2)) acc += 1
        }
        return acc
    }, 0)
}