import React, { useState } from "react";
import { Alert, Button, Col, Row } from "reactstrap";
import { faArrowLeft, faEdit, faImage, faPlus, faTimes, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VideoElement } from "../interfaces";
import ManageImageComponent from "./ManageImageComponent";
import SelectVideoComponent from '../SelectVideoComponent'
import { getImage } from "../utils";
import VideosCache from "../VideosCache";
import VideoFrameGrabber from "../VideoFrameGrabber";
import ManageClipComponent from "./ManageClipComponent";
import { SliderConfig } from "./interfaces";

interface ImagesMenuProps {
    height: number,
    isPlaying: boolean,
    videoElements: Array<VideoElement>,
    setVideoElements: (videoElements: Array<VideoElement>) => void
    backgroundImage: HTMLImageElement | undefined,
    setBackgroundImage: (backgroundImage: HTMLImageElement | undefined) => void,
    backgroundVerticalPosition: number,
    setBackgroundVerticalPosition: (backgroundVerticalPosition: number) => void,
    onVideoSelected: (videoUrl: string | undefined) => void
    selectedVideoUrl: string | undefined,
    videosCache: VideosCache
}

export default function ImagesMenu(props: ImagesMenuProps) {

    const [changeBackgroundImage, setChangeBackgroundImage] = useState<"image" | "video" | "select" | undefined>(undefined)
    const [addingNewElement, setAddingNewElement] = useState<"image" | "video" | "select" | undefined>(undefined)
    const [elementEditingIndex, setElementEditingIndex] = useState<number>(-1)
    const [error, setError] = useState<string | undefined>(undefined)

    let { isPlaying, videoElements, setVideoElements, backgroundImage, setBackgroundImage, onVideoSelected, backgroundVerticalPosition, setBackgroundVerticalPosition, selectedVideoUrl, videosCache } = props

    const loadVideoAndCreateNewElement = (index: number, videoUrl: string) => {
        let videoFrameGrabber = new VideoFrameGrabber()
        let loadVideo = (url: string) => videoFrameGrabber.loadVideo(url, () => { addNewElementToArray(undefined, videoFrameGrabber, 0.5); setAddingNewElement(undefined); }, (error) => { console.log(error); setError(error.toString()) })
        videosCache.getVideoBlob(videoUrl).then((blob) => {
            videosCache.saveVideoInDb('selectedVideo_' + index, blob, true).then(url => { loadVideo(url!) }).catch((err) => { console.log(err); loadVideo(videoUrl) })
        }).catch((err) => { console.log(err); loadVideo(videoUrl) })
    }
    const addNewElementToArray = (image: HTMLImageElement | undefined, frameGrabber: VideoFrameGrabber | undefined, scale: number) => setVideoElements([...videoElements, new VideoElement(image, frameGrabber, scale, false, 1, 0, 0)])
    const showError = (error: string) => <Col><Alert color="danger" toggle={() => setError(undefined)}>{error}</Alert></Col>

    if (changeBackgroundImage) {
        if (changeBackgroundImage === "image") {
            let sliderConfig: SliderConfig = {
                title: "Posição na Verfical:",
                min: 0,
                max: 1,
                step: 0.01,
                value: backgroundVerticalPosition,
                setSliderValue: setBackgroundVerticalPosition
            }
            return <Row>
                {error && showError(error)}
                <Col md={{ size: 12 }}>
                    <ManageImageComponent onExit={() => setChangeBackgroundImage(undefined)} sliders={[sliderConfig]}
                        fileDataUrl={backgroundImage ? backgroundImage.src : undefined} onImageSelected={(imageUrl: string) => { getImage(imageUrl).then(img => { setBackgroundImage(img); onVideoSelected(undefined) }).catch(err => { console.log(err); setError(err.toString()) }).finally(() => setChangeBackgroundImage(undefined)) }} />
                </Col>
            </Row>
        } else if (changeBackgroundImage === "video") {
            return <Row>
                {error && showError(error)}
                <Col md={{ size: 12 }}>
                    <SelectVideoComponent onVideoSelected={onVideoSelected} onExit={() => setChangeBackgroundImage(undefined)} selectedVideoUrl={undefined} />
                </Col>
            </Row>
        } else {
            return <Row>
                {error && showError(error)}
                <Col md={{ size: 12 }}>
                    <div className="bg-light p-2 m-2 rounded border">
                        <Button color="light" size="sm" onClick={() => setChangeBackgroundImage(undefined)}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
                        <div className="d-flex flex-column justify-content-center m-2">
                            <Button className="mt-1" outline disabled={isPlaying} onClick={() => setChangeBackgroundImage("video")}><FontAwesomeIcon icon={faVideo} /> Video</Button>
                            <Button className="mt-2" outline disabled={isPlaying} onClick={() => setChangeBackgroundImage("image")}><FontAwesomeIcon icon={faImage} /> Imagem</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        }
    }

    if (addingNewElement) {
        if (addingNewElement === "image") {
            let sliderConfig: SliderConfig = {
                title: "Posição na Verfical:",
                min: 0,
                max: 1,
                step: 0.01,
                value: backgroundVerticalPosition,
                setSliderValue: setBackgroundVerticalPosition
            }
            return <Row>
                {error && showError(error)}
                <Col md={{ size: 12 }}>
                    <ManageImageComponent onExit={() => setAddingNewElement(undefined)} sliders={[sliderConfig]}
                        fileDataUrl={backgroundImage ? backgroundImage.src : undefined} onImageSelected={(imageUrl: string) => { getImage(imageUrl).then(img => { addNewElementToArray(img, undefined, 1) }).catch(err => console.log(err)).finally(() => setAddingNewElement(undefined)) }} />
                </Col>
            </Row>
        } else if (addingNewElement === "video") {
            return <Row>
                {error && showError(error)}
                <Col md={{ size: 12 }}>
                    <SelectVideoComponent onVideoSelected={(videoUrl) => loadVideoAndCreateNewElement(videoElements.length, videoUrl)} onExit={() => setAddingNewElement(undefined)} selectedVideoUrl={undefined} />
                </Col>
            </Row>
        } else {
            return <Row>
                {error && showError(error)}
                <Col md={{ size: 12 }}>
                    <div className="bg-light p-2 m-2 rounded border">
                        <Button color="light" size="sm" onClick={() => setAddingNewElement(undefined)}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
                        <div className="d-flex flex-column justify-content-center m-2">
                            <Button className="mt-1" outline disabled={isPlaying} onClick={() => setAddingNewElement("video")}><FontAwesomeIcon icon={faVideo} /> Video</Button>
                            <Button className="mt-2" outline disabled={isPlaying} onClick={() => setAddingNewElement("image")}><FontAwesomeIcon icon={faImage} /> Imagem</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        }
    }

    if (elementEditingIndex >= 0) {
        let elementEditing = videoElements[elementEditingIndex]
        let { image, frameGrabber, horizontalPosition, verticalPosition, scale } = elementEditing

        let sliderConfig: Array<SliderConfig> = [{
            title: "Posição na Horizontal:",
            min: 0,
            max: 1,
            step: 0.01,
            value: horizontalPosition,
            setSliderValue: (value: number) => { setVideoElements([...videoElements.map((element, i) => { if (elementEditingIndex === i) { element.horizontalPosition = value }; return element })]) }
        },
        {
            title: "Posição na Vertical:",
            min: 0,
            max: 1,
            step: 1,
            value: verticalPosition,
            setSliderValue: (value: number) => { setVideoElements([...videoElements.map((element, i) => { if (elementEditingIndex === i) { element.verticalPosition = value }; return element })]) }
        },
        {
            title: "Alpha:",
            min: 0,
            max: 1,
            step: 0.1,
            value: elementEditing.alpha,
            setSliderValue: (value: number) => { setVideoElements([...videoElements.map((element, i) => { if (elementEditingIndex === i) { element.alpha = value }; return element })]) }
        }]

        if (image) {
            return <Row className="bg-light p-2 m-2 rounded border">
                {error && showError(error)}
                <Col md={{ size: 12 }}>
                    <ManageImageComponent onExit={() => setElementEditingIndex(-1)} fileDataUrl={image ? image.src : undefined} sliders={sliderConfig}
                        onImageSelected={(imageUrl: string) => { getImage(imageUrl).then(img => { setVideoElements([...videoElements.map((element, i) => { if (elementEditingIndex === i) { element.image = img }; return element })]) }).catch(err => console.log(err)).finally(() => setElementEditingIndex(-1)) }}
                        setScale={(scale) => setVideoElements(videoElements.map((element, i) => { if (elementEditingIndex === i) { element.scale = scale }; return element }))} scale={scale}
                    />
                </Col>
            </Row>
        } else {
            return <Row>
                {error && showError(error)}
                <Col md={{ size: 12 }}>
                    <ManageClipComponent onExit={() => setElementEditingIndex(-1)} sliders={sliderConfig} videoUrl={frameGrabber!.video.src} 
                        setScale={(scale) => setVideoElements(videoElements.map((element, i) => { if (elementEditingIndex === i) { element.scale = scale }; return element }))} scale={scale}  />
                </Col>
            </Row>
        }
    }

    return <Row className="bg-light p-2 m-2 rounded border">
        {error && showError(error)}
        <Col className="d-flex flex-column" md={{ size: 12 }}>
            <Button className="mt-1" outline disabled={isPlaying} onClick={() => setAddingNewElement("select")}><FontAwesomeIcon icon={faPlus} /> Novo elemento</Button>
            <Button className="mt-2" outline disabled={isPlaying} onClick={() => setChangeBackgroundImage("select")}><FontAwesomeIcon icon={faImage} /> Selecionar Fundo</Button>
        </Col>
        {backgroundImage && <Col md={{ size: 12 }}>
            <div className="d-flex flex-column align-items-center rounded border mt-2 bg-white">
                {backgroundImage && <img style={{ height: 180, marginBottom: 5, marginTop: 10, objectFit: 'contain' }} src={backgroundImage.src} alt="background" />}
            </div>
        </Col>}
        {selectedVideoUrl && <Col md={{ size: 12 }}>
            <div className="d-flex flex-column align-items-center rounded border mt-2 bg-white">
                {<video style={{ height: 180, marginBottom: 5, marginTop: 10, objectFit: 'contain' }} src={selectedVideoUrl} />}
            </div>
        </Col>}
        {
            videoElements.map((element, index) => {
                let { image, frameGrabber } = element
                return <Col key={`VideoElement${index}`} md={{ size: 12 }}>
                    <div className="d-flex flex-column align-items-center rounded border bg-white mt-2">
                        {image && <img style={{ height: 180, marginBottom: 5, marginTop: 10, objectFit: 'contain' }} src={image.src} alt="background" />}
                        {frameGrabber && <video style={{ height: 180, marginBottom: 5, marginTop: 10, objectFit: 'contain' }} src={frameGrabber.video.src} />}
                        <div className="d-flex justify-content-evenly mt-1 mb-1 w-100">
                            <Button outline onClick={() => setElementEditingIndex(index)}><FontAwesomeIcon icon={faEdit} /> Editar</Button>
                            <Button color="danger" outline onClick={() => videoElements.length === 1 ? setVideoElements([]) : setVideoElements([...videoElements.filter((_, i) => i !== index)])}><FontAwesomeIcon icon={faTimes} /> Remover</Button>
                        </div>
                    </div>
                </Col>
            })
        }
    </Row>

}