import axios from 'axios'
import ModuleUserAccess from '../../models/ModuleUserAccess'

export default class AdminModuleAccessComponentService {

    async updateModuleUserAccess(token: string, mainUrl: string, moduleId: string, moduleAcccessArray: Array<any>): Promise<Array<ModuleUserAccess>> {
        let url = `${mainUrl}/admin/moduleAccess/upload/${moduleId}`
        let result = await axios.post(url, { data: moduleAcccessArray }, { headers: { 'Authorization': `Token ${token}` } })
        let moduleUserAccessArrayResult = result.data.data.moduleAccessArray.map((data: any) => new ModuleUserAccess(data))
        return moduleUserAccessArrayResult
    }

    async getModuleUserAccess(token: string, mainUrl: string, moduleId: string): Promise<Array<ModuleUserAccess>> {
        let url = `${mainUrl}/admin/moduleAccess/list/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let moduleUserAccessArrayResult = result.data.data.moduleAccessArray.map((data: any) => new ModuleUserAccess(data))
        return moduleUserAccessArrayResult
    }

    async deleteModuleUserAccess(token: string, mainUrl: string, moduleId: string, primaryPropertyValue: string) {
        let url = `${mainUrl}/admin/moduleAccess/deleteAccess`
        await axios.post(url, { primaryPropertyValue, moduleId }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async addModuleUserAccess(token: string, mainUrl: string, moduleId: string, primaryPropertyValue: string): Promise<ModuleUserAccess> {
        let url = `${mainUrl}/admin/moduleAccess/addAccess`
        let result = await axios.post(url, { moduleId, primaryPropertyValue }, { headers: { 'Authorization': `Token ${token}` } })
        let moduleUserAccessResult = new ModuleUserAccess(result.data.data.moduleUserAccess)
        return moduleUserAccessResult
    }
}