import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectedVideoFile } from "./interfaces";
import SelecVideoComponent from "./selectVideo";
import VideosCache from "./VideosCache";
import { getVideoFileUrl } from "./services";

interface VideosMenuProps {
    projectId: string
    selectedVideoFiles: Array<SelectedVideoFile>,
    setSelectedVideoFiles: (selectedVideos: Array<SelectedVideoFile>) => void
    videosCache: VideosCache
}

export default function VideosMenu(props: VideosMenuProps) {

    let { selectedVideoFiles, setSelectedVideoFiles } = props
    const [isSelectingVideo, setIsSelectingVideo] = useState<boolean>(false)

    const addVideo = (filename: string, thumbnail?: string) => {

        let newSelectedVideos = [...selectedVideoFiles]
        newSelectedVideos.push({ file: filename, pos: newSelectedVideos.length, thumbnail })
        setSelectedVideoFiles(newSelectedVideos)

    }

    const removeVideo = (index: number) => {
        let newSelectedVideos = [...selectedVideoFiles]
        newSelectedVideos.splice(index, 1)
        setSelectedVideoFiles(newSelectedVideos.map((selectedVideoFile, index) => { return { ...selectedVideoFile, pos: index } }))
    }

    if (isSelectingVideo) {
        return <SelecVideoComponent projectId={props.projectId} fileSelected={(filename: string) => {
            addVideo(filename); setIsSelectingVideo(false)
        }} goBack={() => setIsSelectingVideo(false)} videosCache={props.videosCache} />
    }

    return <Row className="bg-light p-2 m-2 rounded border">
        <Col className="d-flex flex-column" md={{ size: 12 }}>
            <Button className="mt-1" outline onClick={() => setIsSelectingVideo(true)}><FontAwesomeIcon icon={faPlus} /> Novo Bloco</Button>
        </Col>
        {
            selectedVideoFiles.map((selectedVideoFile, index) => {
                return <Col key={`VideoFile_${selectedVideoFile.pos}`} md={{ size: 12 }}>
                    <div className="d-flex align-items-center rounded border bg-white p-1 mt-2">
                        <div className="d-flex" style={{ flex: 1 }}><b className="me-1 ms-1">{selectedVideoFile.pos + 1}</b><div>{selectedVideoFile.file}</div></div>
                        {selectedVideoFile.thumbnail && <img style={{ height: 180, marginBottom: 5, marginTop: 10, objectFit: 'contain' }} src={selectedVideoFile.thumbnail} alt="preview" />}
                        <Button className="m-1" color="danger" outline onClick={() => removeVideo(index)}><FontAwesomeIcon icon={faTimes} /></Button>
                    </div>
                </Col>
            })
        }
    </Row>
}