import React, { Component, Fragment } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { WordGuessQuestion } from '../../../../../models/QuizQuestion';
import { EditCardProps } from './interfaces';

interface WordGuessQuestionState {
    title: string
    word: string
}

const WORD_SEARCH_CARD_INITIAL_STATE: WordGuessQuestionState = {
    title: '',
    word: ''
}

export default class EditWordGuessQuestionComponent extends Component<EditCardProps<WordGuessQuestion>, WordGuessQuestionState> {

    constructor(props: EditCardProps<WordGuessQuestion>) {
        super(props)

        this.state = { 
            ...WORD_SEARCH_CARD_INITIAL_STATE,
            title: props.card.title,
            word: props.card.word 
        }

    }

    editCard = (title: string, word: string) => {
        let { id, type, companyId, moduleId, subModuleId } = this.props.card
        let wordSearchCard = new WordGuessQuestion({
            id,
            type,
            companyId,
            moduleId,
            subModuleId,
            title,
            word
        })
        this.props.editCard(wordSearchCard)
    }

    isInputValid = (title: string, word: string) => {
        if (title.length < 3) return false
        if (word.length < 2 && word.length > 10) return false
        return true
    }

    render() {
        let { word, title } = this.state
        return (<Row>
            <Col className="d-flex flex-column justify-content-center mt-2" md={{ size: 6, offset: 3 }}>
                <Form style={{ margin: 5 }}>
                    <FormGroup>
                        <Label for="cardTitle">Título</Label>
                        <Input name="title" id="cardTitle" value={title} placeholder="Título" onChange={(event: any) => this.setState({ title: event.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="word">Palavra ( máximo 10 caracteres )</Label>
                        <Input name="wrd" id="word" value={word} onChange={(event: any) => {
                            let val = event.target.value
                            if (val.length <= 10) this.setState({ word: val })
                        }} />
                    </FormGroup>
                </Form>
            </Col>
            <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <Button style={{ margin: 5 }} disabled={!this.isInputValid(title, word)} color="primary" onClick={() => this.editCard(title, word)}>Editar</Button>
            </Col>
        </Row>)
    }
}