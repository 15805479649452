import axios from 'axios'
import CompanyConfig from '../../models/CompanyConfig'

export default class AdminConfigService {

    async getCompanyConfig(token: string, mainUrl: string): Promise<CompanyConfig> {
        let url = `${mainUrl}/admin/config/companyConfig`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let companyConfig = new CompanyConfig(result.data.data.companyConfig)
        return companyConfig
    }

    async updateModuleCategories(token: string, mainUrl: string, moduleCategories: Array<{ id: string, title: string }>) {
        let url = `${mainUrl}/admin/config/updateModuleCategories`
        await axios.post(url, { moduleCategories }, { headers: { 'Authorization': `Token ${token}` } })
    }

}