import axios from 'axios'
import User from '../../models/User'

export default class AdminUsersService {

    async getUser(token: string, mainUrl: string, userId: string): Promise<User> {
        let url = `${mainUrl}/admin/users/user/${userId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new User(result.data.data.user)
    }

    async updateUser(token: string, mainUrl: string, userId: string, email?: string, username?: string, phone?: string, active?: boolean, authData?: { [id: string]: string }): Promise<User> {
        let url = `${mainUrl}/admin/users/update/${userId}`
        let result = await axios.post(url, { email, username, authData, phone, active }, { headers: { 'Authorization': `Token ${token}` } })
        return new User(result.data.data.user)
    }

}