import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Alert, Form, FormGroup, Label } from 'reactstrap'
import Cookies from 'universal-cookie'

import LoadingScreen from '../loading'
import Company from '../../models/Company'
import { SignUpConfig, DocConfig, EmailConfig } from '../../models/CompanyConfig'
import EnterService from '../../services/enterService'
import config from '../../config'

interface State {
    isLoading: boolean
    signUpConfig: SignUpConfig | undefined,
    company: Company | undefined,
    error: any | undefined,
    doc: string | undefined,
    email: string | undefined,
    height: number,
}

const INITIAL_STATE: State = {
    isLoading: false,
    signUpConfig: undefined,
    company: undefined,
    error: undefined,
    doc: undefined,
    email: undefined,
    height: 0,
}

export interface EnterComponentNavigation {
    goToCreateAccount: (primaryProperty?: string) => void
}

interface Props { companyId: string, navigation: EnterComponentNavigation, goToMain: (primaryProperty?: string) => void }

export default class EnterComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.localStorage.removeItem('selectedCategory')

        let companyId = this.props.companyId
        let cookies = new Cookies()
        let userData = cookies.get('user')
        let refreshToken = cookies.get('refreshToken')
        if (userData && userData.companyId === companyId && refreshToken) {
            this.props.goToMain()
        } else {
            this.loadCompanySignUpConfig(companyId)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ height: window.innerHeight });
    }

    async loadCompanySignUpConfig(companyId: string) {
        try {
            this.setState({ isLoading: true, error: undefined })
            let enterService = new EnterService()
            let { company, signUpConfig } = await enterService.getCompanySignUpConfig(config.endpoint, companyId)
            let cookies = new Cookies()
            cookies.set('company', company.getData(), { path: '/', maxAge: 31536000, sameSite: 'none', secure: true });
            this.setState({ isLoading: false, company, signUpConfig })
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    updateDoc = (docConfig: DocConfig, newValue: string) => {
        if (docConfig.mask) {
            if (docConfig.mask === 'cpf') {
                let cpfWithMask = this.applyCpfMask(newValue)
                return this.setState({ doc: cpfWithMask })
            }
        }

        this.setState({ doc: newValue })
    }

    updateEmail = (newValue: string) => {
        this.setState({ email: newValue.trim() })
    }

    applyCpfMask = (value: string) => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    testCPF = (strCPF: string) => {
        strCPF = strCPF.replace(/\D/g, '')
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (let n = 1; n <= 10; n++) Soma = Soma + parseInt(strCPF.substring(n - 1, n)) * (12 - n);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    isInputInvalid = (signUpConfig: SignUpConfig) => {
        if (signUpConfig.primaryProperty === 'doc') {
            let docConfig = signUpConfig.doc!
            if (docConfig.mask) {
                if (docConfig.mask === 'cpf') {
                    return !this.testCPF(this.state.doc || '')
                }
            }

            return this.state.doc ? this.state.doc.length > 3 : false
        } else if (signUpConfig.primaryProperty === 'email') {
            return !this.isEmailValid(this.state.email || '')
        } else {
            return false
        }
    }

    isEmailValid = (email: string): boolean => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    checkUserCredential = async (signUpConfig: SignUpConfig, companyId: string) => {
        try {
            this.setState({ isLoading: true, error: undefined })
            let value = signUpConfig.primaryProperty === 'doc' ? this.state.doc : this.state.email
            let enterService = new EnterService()
            let userExists = await enterService.getUserExists(config.endpoint, signUpConfig.primaryProperty, value || '', companyId)
            if (userExists) {
                if (signUpConfig.password) {
                    //this.props.navigation.goToMain(value)
                    this.props.goToMain(value)
                } else {
                    // para usuarios que nao precisam de password
                    await this.loginWithoutPassword(signUpConfig.primaryProperty, companyId)
                }
            } else {
                this.props.navigation.goToCreateAccount(value)
            }
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    loginWithoutPassword = async (primaryProperty: string, companyId: string) => {
        let signInData: any = { companyId }
        if (primaryProperty === 'doc') signInData.doc = this.state.doc
        else signInData.email = this.state.email
        let enterService = new EnterService()
        let userRes = await enterService.signInUser(config.endpoint, companyId, signInData)
        let cookies = new Cookies()
        cookies.set('user', userRes.user.getData(), { path: '/', maxAge: 31536000 })
        cookies.set('token', userRes.tokenData.token, { path: '/', maxAge: 31536000 })
        cookies.set('refreshToken', userRes.tokenData.refreshToken, { path: '/', maxAge: 31536000 })
        this.props.goToMain()
    }

    handleSubmit(e: any, config: SignUpConfig, companyId: string) {
        e.preventDefault();
        if (!this.isInputInvalid(config)) {
            this.checkUserCredential(config, companyId)
        }
    }

    renderEnterMessage(message: string) {
        return (<Row>
            <Col className="d-flex flex-column justify-content-center" md={{ size: 6, offset: 3 }}>
                <div style={{ fontSize: 16, color: 'black', verticalAlign: 'middle', margin: 5 }}>{message}</div>
            </Col>
        </Row>)
    }

    renderEnterForm(config: SignUpConfig, company: Company, inputDoc: string, height: number) {
        return (<Row>
                <Col className="d-flex flex-column justify-content-center pt-2" md={{ size: 6, offset: 3 }}>
                    {config.enterMessage && <div style={{ fontSize: 'large', color: 'black', verticalAlign: 'middle', textAlign: 'center', margin: 15 }}><b>{config.enterMessage}</b></div>}
                    <Form onSubmit={(e: any) => this.handleSubmit(e, config, company.id)}>
                        {config.primaryProperty === 'doc' && this.renderDocInput(inputDoc, config.doc!)}
                        {config.primaryProperty === 'email' && this.renderEmailInput(config.email!)}
                    </Form>
                    <Button style={{ background: company.mainColor || '#000', borderStyle: 'none' }} block disabled={this.isInputInvalid(config)} onClick={() => this.checkUserCredential(config, company.id)}>Entrar</Button>
                </Col>
            </Row>)
    }

    renderDocInput(inputDoc: string, docConfig: DocConfig) {
        let showInvalidDocError = inputDoc.length > 0 && !this.testCPF(inputDoc)
        return (<FormGroup>
            <Label for="docTitle">{docConfig.title}</Label>
            <Input style={{ opacity: 0.85 }} name="title" id="docTitle" placeholder={docConfig.placeholder} value={inputDoc} onChange={(event: any) => this.updateDoc(docConfig, event.target.value)} />
            {showInvalidDocError && <div style={{ fontSize: 'small', color: 'red', marginTop: 2 }}><i>Por favor digite um {docConfig.title} válido</i></div>}
        </FormGroup>)
    }

    renderEmailInput(emailConfig: EmailConfig) {
        return (<FormGroup>
            <Label for="docTitle">{emailConfig.title}</Label>
            <Input style={{ opacity: 0.85 }} name="title" id="docTitle" placeholder={emailConfig.placeholder} value={this.state.email || ''} onChange={(event: any) => this.updateEmail(event.target.value)} />
        </FormGroup>)
    }

    renderError(error: any) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    renderHeader(company: Company) {
        return <Row className="pt-2 pb-2 mt-3">
            <Col className="d-flex flex-column justify-content-center" md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center">
                    <img alt='company logo' style={{ height: 100 }} src={company.pic} />
                </div>
            </Col>
        </Row>
    }

    render() {
        let { doc, error, isLoading, signUpConfig, company, height } = this.state

        if (isLoading) return <LoadingScreen image={company ? company.pic : undefined} />
        let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
        let backgroundPosition = company?.backgroundPosition || 'left top'

        return (<Container className="d-flex flex-column" fluid style={{ background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, height, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
            {company && this.renderHeader(company)}
            {error && this.renderError(error)}
            {signUpConfig && company && this.renderEnterForm(signUpConfig, company, doc || '', height)}
        </Container>)
    }
}