import axios from 'axios'
import { RankingEntry } from '../models/Ranking'
import User from '../models/User'
import { RankingConfig } from '../models/CompanyConfig'
import { UserModuleAttempts } from '../screens/ranking/intefaces'

export default class RankingService {

    /*  COMPANY RANKING  */
    async getCompanyDefaultRanking(token: string, mainUrl: string, companyId: string, id: string = 'company_general'): Promise<Array<RankingEntry>> {
        let url = `${mainUrl}/mobile/rankings/company/${id}/${companyId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let rankingEntriesArray = result.data.data.rankingEntries.map((data: any) => {
            let user = new User(data.user)
            let score = data.score as number
            let lastUpdate = data.lastUpdate as number
            return new RankingEntry(user, score, lastUpdate)
        })
        return rankingEntriesArray
    }

    async getCompanySegmentedRanking(token: string, mainUrl: string, companyId: string, id: string): Promise<Array<RankingEntry>> {
        let url = `${mainUrl}/mobile/rankings/segmented/${id}/${companyId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let rankingEntriesArray = result.data.data.rankingEntries.map((data: any) => {
            let user = new User(data.user)
            let score = data.score as number
            let lastUpdate = data.lastUpdate as number
            return new RankingEntry(user, score, lastUpdate)
        })
        return rankingEntriesArray
    }

    async getCompanyRankingConfig(token: string, mainUrl: string, companyId: string): Promise<Array<RankingConfig>> {
        let url = `${mainUrl}/mobile/rankings/main/config/${companyId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let rankingConfigArray = result.data.data.rankings.map((data: any) => {
            return new RankingConfig(data)
        })
        return rankingConfigArray
    }

    /*  MODULE RANKING  */
    async getModuleRankingConfig(token: string, mainUrl: string, companyId: string): Promise<Array<RankingConfig>> {
        let url = `${mainUrl}/mobile/rankings/module/config/${companyId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let rankingConfigArray = result.data.data.rankings.map((data: any) => {
            return new RankingConfig(data)
        })
        return rankingConfigArray
    }

    async getModuleDefaultRanking(token: string, mainUrl: string, companyId: string, moduleId: string, id: string = 'module_general'): Promise<Array<RankingEntry>> {
        let url = `${mainUrl}/mobile/rankings/module/default/${id}/${companyId}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let rankingEntriesArray = result.data.data.rankingEntries.map((data: any) => {
            let user = new User(data.user)
            let score = data.score as number
            let lastUpdate = data.lastUpdate as number
            return new RankingEntry(user, score, lastUpdate)
        })
        return rankingEntriesArray
    }

    async getModuleSegmentedRanking(token: string, mainUrl: string, companyId: string, moduleId: string, id: string): Promise<Array<RankingEntry>> {
        let url = `${mainUrl}/mobile/rankings/module/segmented/${id}/${companyId}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let rankingEntriesArray = result.data.data.rankingEntries.map((data: any) => {
            let user = new User(data.user)
            let score = data.score as number
            let lastUpdate = data.lastUpdate as number
            return new RankingEntry(user, score, lastUpdate)
        })
        return rankingEntriesArray
    }

    async getUsersSubModuleAttempts(token: string, mainUrl: string, companyId: string, moduleId: string): Promise<UserModuleAttempts[]> {
        let url = `${mainUrl}/mobile/rankings/module/attempts/${companyId}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return result.data.data.usersSubModuleAttempts as UserModuleAttempts[]
    }

}