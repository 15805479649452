import React, { Component } from 'react';
import { Container, Row, Col, Button, Alert, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap'
import _ from 'lodash'

import io from 'socket.io-client'
import User from '../../models/User';
import Company from '../../models/Company';
import Module from '../../models/Module';
import { SubModule } from '../../models/SubModule';
import { faArrowLeft, faChevronRight, faGamepad, faUserCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config'


class Invitation {
    from: string
    username: string
    pic: string | undefined
    thumbnail: string | undefined
    time: number

    constructor(data: any) {
        this.from = data.from
        this.username = data.username
        this.pic = data.pic
        this.thumbnail = data.thumbnail
        this.time = data.time
    }
}

interface State {
    error: string | undefined;
    isLoading: boolean;
    onlineUsers: Array<User>;
    isInvitingUserToPlay: User | undefined
    receivedInvatationFromUserToPlay: Invitation | undefined
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    onlineUsers: [],
    isInvitingUserToPlay: undefined,
    receivedInvatationFromUserToPlay: undefined,
};

interface Props { user: User, company: Company, module: Module, subModule: SubModule, goBack: () => void, playGame: (companyId: string, subModuleId: string, roomId: string) => void }

export default class PlayRealtimeComponent extends Component<Props, State> {

    socket: SocketIOClient.Socket | undefined = undefined

    constructor(props: Props) {
        super(props);
        this.socket = io(config.socket, {

            query: {
                type: 'default', userId: this.props.user.id, quiz: this.props.subModule.id
            }, autoConnect: false
        })
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        if (!this.socket) return
        if (!this.socket.connected) this.socket.connect()

        this.socket.on("connect", () => {
            console.log('connect')
            console.log(this.socket!.connected)
            if (this.socket) {
                // Pede pra ver quem esta online
                this.socket.emit('user_active')
                // Avisa os usuarios da empresa que esta online
                this.socket.emit('request_users')
                console.log('request_users')
            }

            setTimeout(() => {
                if (this.socket) {
                    // Pede pra ver quem esta online
                    this.socket.emit('user_active')
                    // Avisa os usuarios da empresa que esta online
                    this.socket.emit('request_users')
                    console.log('request_users')
                }
            }, 2000)
            
            this.setState({ error: undefined })
        });

        // Erro ao conectar
        this.socket.on('connect_error', (err: any) => {
            if (err.message === "invalid credentials") {
                //socket.auth.token = "efgh";
                //socket.connect();
            }
            console.log(err)
            this.setState({ error: err.toString() })
        });

        // Tem um usuario perguntando quem esta online. Avisa ele que esta online
        this.socket.on('user_ping', (data: any) => this.socket!.emit('user_active', { to: data.from }))

        // Algum usuario ficou online ou offline. Atualiza a lista
        this.socket.on('user_state_change', (data: any) => {
            let { userId, username, pic, thumbnail, authData, active } = data

            // evento enviado pelo próprio usuário
            if (userId === this.props.user.id) return

            // verifica se tem o usuario no array
            let isUserInArray = this.state.onlineUsers.filter(user => user.id === userId).length > 0

            // Se não tem o usuário no Array e ele ficou ativo, tem que adicionar ele no Array
            if (active && !isUserInArray) {
                let onlineUsers = [...this.state.onlineUsers, new User({ id: userId, username, pic, thumbnail, authData })]
                this.setState({ onlineUsers })
            }
            // Se o usuário ficou inativo e tem ele no Array, tem que remover ele 
            else if (isUserInArray && !active) {
                let onlineUsers = this.state.onlineUsers.filter(user => user.id !== userId)
                this.setState({ onlineUsers })
            }
        })

        // Algum usuario convidou este usuario para jogar
        this.socket.on('user_play_invitation', (data: any) => {
            if (data.from && data.username) {
                // Se este usuario esta convidando um usuario pra jogar, nao aceite o pedido
                if (this.state.isInvitingUserToPlay) {
                    this.socket && this.socket.emit('refuse_invitation', { to: data.from })
                } else {
                    // Se ele nao esta convidando niguem pra jogar, pode aceitar
                    let invitation = new Invitation(data)
                    this.setState({ receivedInvatationFromUserToPlay: invitation })
                }
            }
        })

        // O usuario que foi convidado para jogar recusou o convite
        this.socket.on('user_play_refuse', (data: any) => {
            if (data.from) {
                this.setState({ isInvitingUserToPlay: undefined, error: `${data.username} não pode jogar agora.` })
            }
        })

        // O usuario que foi convidado para jogar aceitou o convite
        this.socket.on('user_play_accept', (data: any) => {
            if (data.from) {
                let invitedUserId = this.state.isInvitingUserToPlay && this.state.isInvitingUserToPlay.id
                if (invitedUserId === data.from) {
                    this.socket && this.socket.emit('start_game', { to: invitedUserId })
                    this.props.playGame(this.props.company.id, this.props.subModule.id, this.props.user.id)
                }
            }
        })

        // O usuario que convidou e teve o aceite iniciou o jogo
        this.socket.on('user_started_game', (data: any) => {
            if (data.from && data.roomId) {
                this.props.playGame(this.props.company.id, this.props.subModule.id, data.roomId)
            }
        })

    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.close()
        }
    }

    inviteUserToPlay(user: User) {
        if (this.socket) {
            this.setState({ isInvitingUserToPlay: user })
            this.socket.emit('invite_to_play', { to: user.id })
        }
    }

    refuseInvitationToPlay(invitation: Invitation) {
        if (this.socket) {
            this.socket.emit('refuse_invitation', { to: invitation.from })
        }
        this.setState({ receivedInvatationFromUserToPlay: undefined })
    }

    acceptInvitationToPlay(invitation: Invitation) {
        if (this.socket) {
            this.socket.emit('accept_invitation', { to: invitation.from })
        }
        this.setState({ receivedInvatationFromUserToPlay: undefined })
    }

    renderError(error: string) {
        return (
            <Row style={{ marginTop: 10 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>
        );
    }

    renderHeader(module: Module, onlineUsers: User[], isConnected: boolean) {
        return (<Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <Button color='none' outline onClick={() => { this.props.goBack() }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, marginBottom: 10, marginTop: 10 }} src={module.pic} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 16, marginLeft: 10 }}>Sala {module.title}</div>
                        {isConnected && <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 14, marginLeft: 10 }}><FontAwesomeIcon color='#0f3252' icon={faUsers} /> Pessoas na sala: {onlineUsers.length + 1}</div>}
                    </div>
                </div>
            </Col>
        </Row>)
    }

    renderUserProfilePic(user: User) {
        if (!user.pic) return (<div><FontAwesomeIcon color='#1d3256' icon={faUserCircle} size='2x' /></div>)
        if (user.thumbnail) return <img style={{ width: '2em', height: '2em', borderRadius: '1em', objectFit: 'cover' }} alt='prof pic' src={user.thumbnail} />
        else return <img style={{ width: '2em', height: '2em', borderRadius: '1em', objectFit: 'cover' }} alt='prof pic' src={user.pic} />
    }

    renderUserStatus(user: User, isConnected: boolean) {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex align-items-center" style={{ margin: 5, padding: 5 }}>
                        {this.renderUserProfilePic(user)}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5, fontSize: 16 }}>{user.username}</div>
                            {isConnected && <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5, fontSize: 12 }}>Selecione um colega abaixo para convidar para um jogo</div>}
                        </div>
                    </div>
                </Col>
            </Row >)
    }

    renderUser(user: User) {
        return (<Col md={{ size: 6, offset: 3 }}>
            <div className="d-flex align-items-center" style={{ background: 'white', margin: 5, padding: 5, borderRadius: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }} onClick={() => this.inviteUserToPlay(user)}>
                {this.renderUserProfilePic(user)}
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}>{user.username}</div>
                <div><FontAwesomeIcon color='#1d3256' icon={faChevronRight} /></div>
            </div>
        </Col>)
    }

    renderOnlineUsers(onlineUsers: User[]) {
        if (onlineUsers.length === 0) {
            return (<Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5, fontSize: 16 }}>Chame seus colegas para entrar na sala deste jogo. Quando estiverem online nesta missão eles vão aparecer aqui.</div>
                </Col>
            </Row>)
        } else {
            return (<Row>
                {onlineUsers.map(user => this.renderUser(user))}
            </Row>)
        }
    }

    renderInvitingUserToPlayModal(user: User) {

        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Modal isOpen modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => this.setState({ isInvitingUserToPlay: undefined })}>
                    <ModalHeader toggle={() => this.setState({ isInvitingUserToPlay: undefined })}>Convidadando {user.username} para jogar...</ModalHeader>
                    <ModalBody>
                        <Spinner />
                    </ModalBody>
                </Modal>
            </Col>
        </Row>)
    }

    renderInvitationToPlay(invitation: Invitation) {

        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Modal isOpen modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => this.refuseInvitationToPlay(invitation)}>
                    <ModalHeader toggle={() => this.refuseInvitationToPlay(invitation)}>{invitation.username} está te chamando para jogar <FontAwesomeIcon icon={faGamepad} /></ModalHeader>
                    <ModalBody>
                        <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-around' }}>
                            <Button color='primary' onClick={() => this.acceptInvitationToPlay(invitation)} >Aceitar</Button>
                            <Button color='danger' onClick={() => this.refuseInvitationToPlay(invitation)}>Recusar</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Col>
        </Row>)
    }

    render() {
        let { error, onlineUsers, isInvitingUserToPlay, receivedInvatationFromUserToPlay } = this.state
        return (<Container fluid className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
            {this.renderHeader(this.props.module, onlineUsers, this.socket !== undefined && this.socket.connected)}
            {error && this.renderError(error)}
            {this.renderUserStatus(this.props.user, this.socket !== undefined && this.socket.connected)}
            {this.renderOnlineUsers(onlineUsers)}
            {isInvitingUserToPlay && this.renderInvitingUserToPlayModal(isInvitingUserToPlay)}
            {receivedInvatationFromUserToPlay && this.renderInvitationToPlay(receivedInvatationFromUserToPlay)}
        </Container>)
    }

}