import React, { Component, CSSProperties } from 'react';
import _ from 'lodash'
import { Button, Col, Row } from 'reactstrap';
import { SelectAnswerQuestion } from '../models/QuizQuestion';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { QuestionAnswer } from '../models/Question';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Zoom from 'react-medium-image-zoom'
import SelectedSvg from '../images/selected.svg'
import SelectedRedSvg from '../images/selected_red.svg'
import SelectedGreenSvg from '../images/selected_green.svg'


interface SelectAnswerComponentProps {
    selectAnswerQuestion: SelectAnswerQuestion
    questionAnswer: QuestionAnswer
    onCheckAnswer: (selectedAnswers: Array<number>) => void
    screenHeight: string
}

interface SelectAnswerComponentState {
    selectedAnswers: Array<number>
}

export default class RenderSelectAnswerQuestion extends Component<SelectAnswerComponentProps, SelectAnswerComponentState> {

    constructor(props: SelectAnswerComponentProps) {
        super(props)
        this.state = { selectedAnswers: [] }
    }

    // Bem, aparentemente mudar o state do componente Parent não necessariamente
    // faz o state do componente Child ser atualizado.
    // https://stackoverflow.com/questions/48502546/reactjs-child-not-updating-after-parent-state-changed
    componentWillReceiveProps(nextProps: SelectAnswerComponentProps) {
        // essa funcao vai ser chamada toda vez que o timer for atualizado
        // portanto é necessario verificar se o card foi trocado
        if(nextProps.selectAnswerQuestion.id !== this.props.selectAnswerQuestion.id) {
            this.setState({ selectedAnswers: [] });
        }
        // Meu deus, é gambiarra em cima de gambiarra
    }

    onAnswerSelectChange(answer: number, selectedAnswers: Array<number>, selection: boolean) {
        if(selection) {
            // adiciona a resposta na seleção do usuário
            // se ja tiver a resposta no array nao precisa adicionar de novo
            if(_.includes(selectedAnswers, answer)) return
            // concat cria um novo array com o elemento passado como parametro
            let newSelectedAnswers = selectedAnswers.concat(answer)
            this.setState({ selectedAnswers: newSelectedAnswers })
        } else {
            // remove a resposta
            // filter cria um novo array sem o elemento filtrado
            let newSelectedAnswers = selectedAnswers.filter(arrayAnswer => answer !== arrayAnswer)
            this.setState({ selectedAnswers: newSelectedAnswers })
        }
    }

    renderAnswerForState(index: number, text: string, img: string | undefined, selectedAnswers: Array<number>) {
        let screenHeight = this.props.screenHeight
        let isSelected = _.includes(selectedAnswers, index)
        let icon = (isSelected && !img) ? true : false
        let style = isSelected ? questionSelectedStyle : questionDefaultStyle
        let imageSize = '2.5em'
        var paddingTop = 10, paddingBottom = 10, paddingLeft = 2, paddingRight = 2, marginTop = 10, marginBottom = 5
        if(screenHeight === 'medium') {
            paddingTop = 15; paddingBottom = 15; paddingLeft = 5; paddingRight = 5; marginTop = 15; marginBottom = 10
            imageSize = '3em'
        } else if(screenHeight === 'big') {
            paddingTop = 25; paddingBottom = 25; paddingLeft = 15; paddingRight = 15; marginTop = 20; marginBottom = 20
            imageSize = '4em'
        }
        if(icon) {
            return (<div style={{...style, paddingTop, paddingBottom, paddingLeft, paddingRight, marginTop, marginBottom}} onClick={() => this.onAnswerSelectChange(index, selectedAnswers, !isSelected)}><img style={{ width: '2em', height: '2em', marginRight: 2 }} alt='ícone de selecionado' src={SelectedSvg} /> {text}</div>)
        } else {
            return (<div style={{...style, paddingTop, paddingBottom, paddingLeft, paddingRight, marginTop, marginBottom}} onClick={() => this.onAnswerSelectChange(index, selectedAnswers, !isSelected)}>{img && <img alt='imagem da resposta' src={img} style={{ objectFit: 'contain', width: imageSize, height: imageSize, marginRight: 5 }} />}{text}</div>)
        }
    }

    renderAnswerSelection(selectedAnswers: Array<number>, selectAnswerQuestion: SelectAnswerQuestion) {
        let fontSize = selectAnswerQuestion.question.length < 100 ? '1.2em' : '1em'
        let imageHeight = this.props.screenHeight === 'big' ? 200 : (this.props.screenHeight === 'medium' ? 160 : 120)
        return (<Row style={{ flex: 1, overflow: 'auto', zIndex: 1 }}>
            <Col style={{ marginTop: 15 }} md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'stretch', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: 4, textAlign: 'center', color: 'black', fontFamily: 'Montserrat', fontWeight: "bold", fontSize }}>{selectAnswerQuestion.question}</div>
                    {selectAnswerQuestion.questionImageUrl && <div className="d-flex flex-column align-items-center justify-content-center"><Zoom><img src={selectAnswerQuestion.questionImageUrl} alt='imagem da pergunta' style={{ height: imageHeight, objectFit: 'contain', marginBottom: 5, marginTop: 5 }} /></Zoom></div>}
                    <div style={{ marginTop: 5, marginBottom: 5, marginLeft: 4, marginRight: 4, textAlign: 'center', color: 'black', fontFamily: 'Montserrat', fontWeight: "lighter", fontSize: 'small', paddingTop: 5, paddingBottom: 5 }}><FontAwesomeIcon style={{ marginRight: 2 }} icon={faInfoCircle} /><i>Você pode selecionar mais de uma resposta</i></div>
                    {this.renderAnswerForState(0, selectAnswerQuestion.answer0, selectAnswerQuestion.imgAnswer0, selectedAnswers)}
                    {this.renderAnswerForState(1, selectAnswerQuestion.answer1, selectAnswerQuestion.imgAnswer1, selectedAnswers)}
                    {selectAnswerQuestion.answer2 && this.renderAnswerForState(2, selectAnswerQuestion.answer2, selectAnswerQuestion.imgAnswer2, selectedAnswers)}
                    {selectAnswerQuestion.answer3 && this.renderAnswerForState(3, selectAnswerQuestion.answer3, selectAnswerQuestion.imgAnswer3, selectedAnswers)}
                    <Button disabled={selectedAnswers.length === 0} color='info' style={{ margin: 5, height: 0.3 * imageHeight }} onClick={() => this.props.onCheckAnswer(selectedAnswers)}>CONCLUIR</Button>
                </div>
            </Col>
        </Row>)
    }

    renderFeedback(questionAnswer: QuestionAnswer, selectedAnswers: Array<number>, selectAnswerQuestion: SelectAnswerQuestion) {
        let fontSize = selectAnswerQuestion.question.length < 100 ? '1.2em' : '1em'
        let imageHeight = this.props.screenHeight === 'big' ? 200 : (this.props.screenHeight === 'medium' ? 160 : 120)
        return (<Row style={{ flex: 1, overflow: 'auto', zIndex: 1 }}>
            <Col style={{ marginTop: 5 }} md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'stretch', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: 4, textAlign: 'center', color: 'black', fontFamily: 'Montserrat', fontWeight: "bold", fontSize }}>{selectAnswerQuestion.question}</div>
                    {selectAnswerQuestion.questionImageUrl && <div className="d-flex flex-column align-items-center justify-content-center"><Zoom><img src={selectAnswerQuestion.questionImageUrl} alt='imagem da pergunta' style={{ height: imageHeight, objectFit: 'contain', marginBottom: 5, marginTop: 5 }} /></Zoom></div>}
                    <div style={{ marginTop: 5, marginBottom: 5, marginLeft: 4, marginRight: 4, textAlign: 'center', color: 'black', fontFamily: 'Montserrat', fontWeight: "lighter", fontSize: 'small', paddingTop: 5, paddingBottom: 5 }}><FontAwesomeIcon style={{ marginRight: 2 }} icon={faInfoCircle} /><i>Você pode selecionar mais de uma resposta</i></div>
                    {this.renderAnswerFeedback(0, selectAnswerQuestion.answer0, selectAnswerQuestion.imgAnswer0, questionAnswer === QuestionAnswer.NotAnswered ? selectedAnswers : selectedAnswers, selectAnswerQuestion.correctAnswers)}
                    {this.renderAnswerFeedback(1, selectAnswerQuestion.answer1, selectAnswerQuestion.imgAnswer1, questionAnswer === QuestionAnswer.NotAnswered ? selectedAnswers : selectedAnswers, selectAnswerQuestion.correctAnswers)}
                    {selectAnswerQuestion.answer2 && this.renderAnswerFeedback(2, selectAnswerQuestion.answer2, selectAnswerQuestion.imgAnswer2, questionAnswer === QuestionAnswer.NotAnswered ? selectedAnswers : selectedAnswers, selectAnswerQuestion.correctAnswers)}
                    {selectAnswerQuestion.answer3 && this.renderAnswerFeedback(3, selectAnswerQuestion.answer3, selectAnswerQuestion.imgAnswer3, questionAnswer === QuestionAnswer.NotAnswered ? selectedAnswers : selectedAnswers, selectAnswerQuestion.correctAnswers)}
                </div>
            </Col>
        </Row>)
    }

    renderAnswerFeedback(index: number, text: string, img: string | undefined, selectedAnswers: Array<number>, correctAnswers: Array<number>) {
        let screenHeight = this.props.screenHeight
        let isSelected = _.includes(selectedAnswers, index)
        // se for uma resposta correta está no array correctAnswers e tem que estar selecionada
        // caso não seja, ela não pode estar selecionada para ser correta
        let isCorrect = _.includes(correctAnswers, index) ? isSelected : !isSelected
        let style = isSelected ? questionSelectedStyle : questionDefaultStyle
        let imageSize = '2.5em'
        var paddingTop = 10, paddingBottom = 10, paddingLeft = 2, paddingRight = 2, marginTop = 5, marginBottom = 5
        if (screenHeight === 'medium') {
            imageSize = '3em'
            paddingTop = 15; paddingBottom = 15; paddingLeft = 5; paddingRight = 5; marginTop = 10; marginBottom = 10
        } else if (screenHeight === 'big') {
            imageSize = '4em'
            paddingTop = 25; paddingBottom = 25; paddingLeft = 15; paddingRight = 15; marginTop = 20; marginBottom = 20
        }
        // Caso 1: Selecionou a alternativa correta
        if (isSelected && isCorrect) {
            return (<div style={{ ...style, position: 'relative', paddingTop, paddingBottom, paddingLeft, paddingRight, marginTop, marginBottom, borderColor: 'green', color: 'green' }}>
                {img ? <img alt='imagem da resposta' src={img} style={{ objectFit: 'contain', width: imageSize, height: imageSize, marginRight: 5 }} /> : <img style={{ width: '2em', height: '2em', marginRight: 2 }} alt='ícone de selecionado' src={SelectedGreenSvg} />} {text}
                <FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} size='2x' color='green' icon={faCheckCircle} />
            </div>)
        } // Caso 2: Selecionou a alternativa errada
        else if (isSelected && !isCorrect) {
            return (<div style={{ ...style, position: 'relative', paddingTop, paddingBottom, paddingLeft, paddingRight, marginTop, marginBottom, borderColor: 'red', color: 'red' }}>
                {img ? <img alt='imagem da resposta' src={img} style={{ objectFit: 'contain', width: imageSize, height: imageSize, marginRight: 5 }} />  : <img style={{ width: '2em', height: '2em', marginRight: 2 }} alt='ícone de selecionado' src={SelectedRedSvg} />} {text}
                <FontAwesomeIcon style={{ right: 5, marginLeft: 5, marginRight: 5 }} size='2x' color='red' icon={faTimesCircle} />
            </div>)
        } // Caso 3: Não selecionou uma alternativa correta
        else if (!isSelected && !isCorrect) {
            return (<div style={{ ...style, position: 'relative', paddingTop, paddingBottom, paddingLeft, paddingRight, marginTop, marginBottom, borderColor: 'orange', color: 'orange' }}>
                {img && <img alt='imagem da resposta' src={img} style={{ objectFit: 'contain', width: imageSize, height: imageSize, marginRight: 5 }} />}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontFamily: 'Montserrat' }}>{text}</div>
                    <div style={{ fontFamily: 'Montserrat', fontSize: 12 }}><i>Deveria ser selecionada</i></div>
                </div>
                <FontAwesomeIcon style={{ marginLeft: 5 }} size='2x' color='orange' icon={faExclamationTriangle} />
            </div>)
        } // Caso 4 não selecionou alternativa incorreta
        else {
            return (<div style={{ ...style, position: 'relative', paddingTop, paddingBottom, paddingLeft, paddingRight, marginTop, marginBottom }}>
                {img && <img alt='imagem da resposta' src={img} style={{ objectFit: 'contain', width: imageSize, height: imageSize, marginRight: 5 }} />}
                {text}
            </div>)
        }
    }

    render() {
        let { questionAnswer, selectAnswerQuestion } = this.props
        let selectedAnswers = this.state.selectedAnswers
        if(questionAnswer === QuestionAnswer.NotAnswered) {
            // segue o jogo
            return this.renderAnswerSelection(selectedAnswers, selectAnswerQuestion)
        } else {
            // mostra pro usuário o que ele acertou e errou
            return this.renderFeedback(questionAnswer, selectedAnswers, selectAnswerQuestion)
        }
    }
}

const questionDefaultStyle: CSSProperties = {
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 4, fontFamily: 'Montserrat',
    paddingLeft: 2, paddingRight: 2, color: '#AAA', background: '#FFFFFFDA',
    borderRadius: 5, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)'
}

const questionSelectedStyle: CSSProperties = {
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 4, fontFamily: 'Montserrat',
    paddingLeft: 2, paddingRight: 2, color: 'black', borderStyle: 'solid', borderWidth: 'thin',
    borderRadius: 5, background: '#FFFFFFDA',
}