import React, { Component, useEffect } from 'react';
import { Container, Row, Col, Button, Alert } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'
import Company from '../../models/Company';
import LoadingScreen from '../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';

// Menu Images
import MenuUsers from '../../images/users.svg'
import MenuProgress from '../../images/AdminMenuProgress.svg'
import MenuGraphics from '../../images/AdminMenuGraphics.svg'
import MenuContent from '../../images/AdminMenuContent.svg'
import MenuForms from '../../images/AdminMenuForms.svg'
import MenuConfig from '../../images/AdminMenuConfig.svg'
import { useUser } from '../../hooks/useUser';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

interface State {
    error: string | undefined;
    isLoading: boolean;
    company: Company | undefined;
    height: number,
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    company: undefined,
    height: 0,
};


const AdminScreen = () => {

    const navigate = useNavigate();
    const { user, company } = useUser()
    const [loadingError, setLoadingError] = React.useState<{ isLoading: boolean, error?: string }>({ isLoading: false })
    const { width, height } = useWindowDimensions();

    const goBack = (companyId: string) => navigate(`/${companyId}${ROUTES.MAIN}`)

    const goToUsageControl = () => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.USAGE_CONTROL}`)

    const goToUsageGraphics = () => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.USAGE_CHARTS}`)

    const goToSelectModule = () => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SELECT_MODULE}`)

    const goToUserAccessMain = () => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.USER_ACCESS_MAIN}`)

    const goToForms = () => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.FORMS}`)

    const goToConfig = () => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.CONFIG}`)

    const renderHeader = (company: Company) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ background: '#FFFFFFDA', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { goBack(company.id) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Painel Admin</b></div>
                    <img alt='foto da empresa' style={{ height: 60 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderOptions = () => {
        let renderOption = (title: string, description: string, image: string, onClick: () => void) =>
            <Col id={image} className="d-flex flex-column" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div className="d-flex flex-row" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }} onClick={onClick}>
                    <div className="d-flex flex-column justify-content-center mt-2" style={{ width: '100%' }}>
                        <div className="d-flex align-items-center" style={{ marginBottom: 2 }}>
                            <img className="moduleImage" alt='foto da missão' src={image} />
                            <div className="cursorPointer" style={{ marginLeft: 10, flex: 1 }}>
                                <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginBottom: 5 }}><b>{title}</b></div>
                                <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle' }}>{description}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: 'auto', padding: 10 }} ><FontAwesomeIcon color='#353f45' size='2x' icon={faAngleRight} /></div>
                </div>
            </Col>
        return (
            <Row style={{ overflow: 'auto', zIndex: 1 }}>
                {renderOption("Relatório de Usuários", "Usuários se cadastraram no app, treinamentos finalizados e porcentagem de aprendizado. Permite também filtrar, editar as informações do perfil e baixar o certificado do usuário.", MenuProgress, goToUsageControl)}
                {renderOption("Dashboard Geral", "Gráficos que permitem acompanhar o engajamento e performance geral nos treinamentos o longo de um período.", MenuGraphics, goToUsageGraphics)}
                {renderOption("Treinamentos e Conteúdo", "Permite criar e editar novos treinamentos além de verificar as questões que os usuários mais erram.", MenuContent, goToSelectModule)}
                {renderOption("Acesso de Usuários", "Permite habilitar ou desabilitar o cadastro de determinados usuários além de enviar mensagens WhatsApp com o link do treinamento.", MenuUsers, goToUserAccessMain)}
                {renderOption("Configurações", "Permite editar as configurações da plataforma, como categorias dos treinamentos.", MenuConfig, goToConfig)}
                {renderOption("Pesquisas Internas", "Realize pesquisas com seus colaboradores recolhendo respostas através de formulários no app.", MenuForms, goToForms)}
            </Row>
        )
    }

    let { error, isLoading } = loadingError

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container fluid className="d-flex flex-column relative" style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && <Alert color="danger">{error}</Alert>}
        {company && renderHeader(company)}
        {renderOptions()}
    </Container>)

}

export default AdminScreen