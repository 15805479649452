export enum QuestionAnswer {
    NotAnswered = 'NotAnswered',
    Timeout = 'Timeout',
    CorrectAnswer = 'CorrectAnswer',
    WrongAnswer = 'WrongAnswer'
}

export class Question {
    id: string
    answer: QuestionAnswer
    selectedAnswer: number
    constructor(id: string, answer: QuestionAnswer, selectedAnswer: number = -1) {
        this.id = id
        this.answer = answer
        this.selectedAnswer = selectedAnswer
    }
}
