import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Alert, Form, FormGroup, Label, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, FormText } from 'reactstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Company from '../../../models/Company';
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../../constants/images'
import LoadingScreen from '../../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faImage, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import TokenHelper from '../../../auth/TokenHelper';
import AdminContentService from '../../../services/admin/adminContentService';
import config from '../../../config';
import * as ROUTES from '../../../constants/routes'
import FileHelper from '../../../files/FileHelper'
import { AuthData, ContentConfig, SignUpConfig } from '../../../models/CompanyConfig';
import Select from 'react-select'
import { Frequency, ModuleTemplateType } from '../../../models/Module';
import ContentService from '../../../services/contentService';
import checkIfUrlIsValid from '../../../utils/checkIfUrlIsValid';
import { useUser } from '../../../hooks/useUser';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';


interface State {
    signUpConfig: SignUpConfig | undefined;
    contentConfig?: ContentConfig;
    error: any | undefined;
    isLoading: boolean;
    title: string;
    pos: number;
    fileDataUrl: string | undefined;
    authDataSelectedValues: { [id: string]: string[] }
    categoriesSelectedValues: Array<{ id: string, title: string }>
    limiterQuiz: number
    frequency: string
    authDataFilterOn: boolean
    contentUrl: string
    warningMessage?: string
    saveAfterWarning?: () => void
    tooltipOpen: boolean,
    tooltipMissionTemplateOpen: boolean,
    templateType: ModuleTemplateType
};

const INITIAL_STATE: State = {
    signUpConfig: undefined,
    error: undefined,
    isLoading: false,
    title: '',
    pos: 1,
    fileDataUrl: undefined,
    authDataSelectedValues: {},
    categoriesSelectedValues: [],
    limiterQuiz: 15,
    frequency: Frequency.Day,
    authDataFilterOn: false,
    contentUrl: '',
    tooltipOpen: false,
    tooltipMissionTemplateOpen: false,
    templateType: ModuleTemplateType.DEFAULT
};

const fileHelper = new FileHelper()

const CreateModuleScreen = () => {


    const uploadButtonRef = React.useRef<HTMLInputElement>(null)
    const navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { company } = useUser()
    const { height } = useWindowDimensions()
    const [searchParams, setSearchParams] = useSearchParams();

    const posFromUrl = searchParams.get("pos")

    React.useEffect(() => {
        if (posFromUrl) {
            setState({ ...state, pos: parseInt(posFromUrl) })
        }
    }, [posFromUrl])


    React.useEffect(() => {
        if (company) {
            loadCompanySignUpContentConfig(company.id)
        } else {
            setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.' })
        }
    }, [company])



    const loadCompanySignUpContentConfig = async (companyId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let { signUpConfig, contentConfig } = await contentService.getCompanySignUpAndContentConfig(token, config.endpoint, companyId)
            setState({ ...state, isLoading: false, signUpConfig, contentConfig })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadCompanySignUpContentConfig(companyId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const isInputValid = (title: string, templateType: ModuleTemplateType, url: string) => {
        if (title.length < 3) return false
        if (templateType === ModuleTemplateType.URL_AI) return checkIfUrlIsValid(url)
        return true
    }

    const setFileDataUrl = async (file: File) => {
        try {
            let fileDataUrl = await fileHelper.getFileDataUrl(file)
            setState({ ...state, fileDataUrl })
        } catch (error) {
            setState({ ...state, error: error.toString() })
        }
    }

    const uploadFile = async (moduleId: string, imageUrl: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let image = await fileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await fileHelper.resizeImage(canvas, image, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            let formData = new FormData()
            formData.append('file', resizedImageBlob)
            let adminContentService = new AdminContentService()
            await adminContentService.uploadModulePic(token, config.endpoint, moduleId, formData)
            navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SUBMODULE_SELECT}/${moduleId}`)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                uploadFile(moduleId, imageUrl)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const proxyCreateModule = (title: string, pos: number, defaultPic: string, fileDataUrl: string | undefined, authDataSelectedValues: { [id: string]: string[] }, categories: Array<string>, authDataFilterOn: boolean, limiterQuiz: number, frequency: string, templateType: ModuleTemplateType, url: string, companyConfigAuthData?: { [id: string]: AuthData }) => {
        if (companyConfigAuthData) {
            // verifica se ele não deixou nenhuma propriedade de restrição liberada para todos os usuários
            let unrestrictedPropertyNames: Array<string> = []
            Object.keys(companyConfigAuthData).forEach(companyConfigAuthDataId => {
                if (!authDataSelectedValues[companyConfigAuthDataId] || !authDataFilterOn) unrestrictedPropertyNames.push(companyConfigAuthData[companyConfigAuthDataId].title)
            })
            if (unrestrictedPropertyNames.length > 0) {
                // mostra mensagem avisando que para determinadas propriedades ele está liberado para todos
                let properties = unrestrictedPropertyNames.reduce((acc, curr, index) => {
                    if (index === unrestrictedPropertyNames.length - 1) acc += curr
                    else acc += `${curr}, `
                    return acc
                }, '')
                let warningMessage = `As seguintes propriedades estão sem restrição de acesso: ${properties}. Tem certeza que deseja continuar?`
                let saveAfterWarning = () => createModule(title, pos, defaultPic, fileDataUrl, authDataFilterOn ? authDataSelectedValues : {}, categories, limiterQuiz, frequency, templateType, url)
                setState({ ...state, warningMessage, saveAfterWarning })
            } else {
                createModule(title, pos, defaultPic, fileDataUrl, authDataFilterOn ? authDataSelectedValues : {}, categories, limiterQuiz, frequency, templateType, url)
            }
        } else {
            createModule(title, pos, defaultPic, fileDataUrl, authDataFilterOn ? authDataSelectedValues : {}, categories, limiterQuiz, frequency, templateType, url)
        }
    }

    const createModule = async (title: string, pos: number, defaultPic: string, fileDataUrl: string | undefined, authDataSelectedValues: { [id: string]: string[] }, categories: Array<string>, limiterQuiz: number, frequency: string, templateType: ModuleTemplateType, url: string) => {
        setState({ ...state, isLoading: true, error: undefined, warningMessage: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let module = await adminContentService.createModule(token, config.endpoint, title, pos, defaultPic, authDataSelectedValues, categories, limiterQuiz, frequency, templateType, url)
            if (fileDataUrl) {
                uploadFile(module.id, fileDataUrl)
            } else {
                navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SUBMODULE_SELECT}/${module.id}`)
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                createModule(title, pos, defaultPic, fileDataUrl, authDataSelectedValues, categories, limiterQuiz, frequency, templateType, url)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const openSelectImageDialog = () => {
        if (uploadButtonRef.current != null) {
            uploadButtonRef.current.click()
        }
    }

    const dropDownAuthDataSelection = (selection: Array<{ value: string, label: string }>, id: string) => {
        let authDataSelectedValues = state.authDataSelectedValues
        if (selection === undefined || selection.length === 0) {
            delete authDataSelectedValues[id]
            setState({ ...state, authDataSelectedValues })
        } else {
            authDataSelectedValues[id] = selection.map(selection => selection.value)
            setState({ ...state, authDataSelectedValues })
        }
    }

    const onCategorySelected = (selection: Array<{ value: string, label: string }>) => {
        setState({ ...state, categoriesSelectedValues: selection.map(({ value, label }) => { return { id: value, title: label } }) })
    }

    const renderWarning = (warningMessage: string, saveAfterWarning: (() => void) | undefined) => {
        return (<Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => setState({ ...state, warningMessage: undefined, saveAfterWarning: undefined })}>
                    <ModalHeader>Tem certeza que quer salvar esta Missão sem restrições de acesso?</ModalHeader>
                    <ModalBody>
                        <div style={{ fontFamily: 'Montserrat' }}>{warningMessage}</div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center align-items-center' >
                        <Button color="secondary" onClick={() => setState({ ...state, warningMessage: undefined, saveAfterWarning: undefined })}>Não, voltar para edição</Button>
                        <Button color="primary" onClick={() => { if (saveAfterWarning) saveAfterWarning() }}>Sim, quero salvar</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    const renderModulePicOrLoading = (companyPic: string, modulePic: string | undefined, loadingProfilePic: boolean) => {
        if (loadingProfilePic) {
            return (<div className="d-flex justify-content-center">
                <Spinner style={{ height: 140, color: 'black', marginBottom: 5, marginTop: 10 }} />{' '}
            </div>)
        }
        return (<div className="d-flex justify-content-center" onClick={() => openSelectImageDialog()}>
            <img alt='company pic' style={{ height: 140, marginBottom: 5, marginTop: 10, borderRadius: 70, borderColor: 'LightGrey', borderStyle: 'solid' }} src={modulePic || companyPic} />
        </div>)
    }

    const renderHeader = (company: Company) => {
        return (<Row className="pt-2 pb-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Nova Missão</b></div>
                    <img alt='foto da empresa' style={{ height: 60 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderAuthDataDropdownSelection = (authData: { [id: string]: AuthData }, authDataSelectedValues: { [id: string]: string[] }) => {
        let authDataKeys = Object.keys(authData)
        return (<div style={{ marginBottom: 5, marginTop: 10 }}>
            {authDataKeys.filter(id => { return authData[id].values && authData[id].values.length > 0 }).map((id, index) => {
                let currentAuthData = authData[id]
                let currentValue = authDataSelectedValues[id] ? authDataSelectedValues[id].map(val => { return { value: val, label: val } }) : []
                return (<FormGroup key={index} style={{ marginBottom: 10 }}>
                    <Label>{currentAuthData.title}</Label>
                    <Select isMulti placeholder="Liberado para todos" value={currentValue} options={currentAuthData.values.map(val => { return { value: val, label: val } })} onChange={opt => dropDownAuthDataSelection(opt as Array<{ value: string, label: string }>, id)} />
                </FormGroup>)
            })}
        </div>)
    }

    const renderModuleCategoryDropdownSelection = (categories: Array<{ id: string, title: string }>, categoriesSelectedValues: Array<{ id: string, title: string }>) => {
        return (<FormGroup style={{ marginBottom: 10 }}>
            <Label>Categoria</Label>
            <Select isMulti placeholder="Nenhuma" value={categoriesSelectedValues.map(({ id, title }) => { return { value: id, label: title } })} options={categories.map(({ id, title }) => { return { value: id, label: title } })} onChange={opt => onCategorySelected(opt as Array<{ value: string, label: string }>)} />
        </FormGroup>)
    }

    const renderForm = (title: string, pos: number, defaultPic: string, signUpConfig: SignUpConfig, contentConfig: ContentConfig | undefined, authDataSelectedValues: { [id: string]: string[] }, categoriesSelectedValues: Array<{ id: string, title: string }>, limiterQuiz: number, frequency: string, authDataFilterOn: boolean, fileDataUrl: string | undefined, templateType: ModuleTemplateType, url: string) => {
        let templateText = [
            'Nosso template padrão que separa o treinamento em três partes: duas de aprendizagem e uma avaliação de conhecimento.',
            'Ideal para treinamentos mais curtos contendo duas partes: uma de aprendizagem e outra de avaliação de conhecimento.',
            'Contém um video e uma avaliação de conhecimento.',
            'Cria o trienamento a partir do conteúdo de um site utilizando Inteligência Artificial.'
        ]
        return (<Col className="d-flex flex-column justify-content-center relative" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
            <input id="myInput" type="file" ref={uploadButtonRef} style={{ display: 'none' }} onChange={(event) => { if (event.target.files) setFileDataUrl(event.target.files[0]) }} />
            <Form className="bg-light p-2 m-2 rounded">
                <FormGroup>
                    <Label for="moduleTitle">Título</Label>
                    <Input name="title" id="moduleTitle" placeholder="Nome da missão" onChange={(event: any) => setState({ ...state, title: event.target.value })} />
                </FormGroup>
                <FormGroup>
                    <Label for="position">
                        <span id="PositionText">Posição <FontAwesomeIcon icon={faQuestionCircle} /></span>
                        <Tooltip placement="top" isOpen={state.tooltipOpen} autohide={false} target="PositionText" toggle={() => setState({ ...state, tooltipOpen: !state.tooltipOpen })}>
                            As missões aparecem para o usuário em ordem da menor para a maior posição.
                        </Tooltip>
                    </Label>
                    <Input type="select" name="selectMulti" id="position" value={pos} onChange={(event: any) => setState({ ...state, pos: +event.target.value })}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="templateType">
                        <span id="TemplateTypeText">Template da Missão <FontAwesomeIcon icon={faQuestionCircle} /></span>
                        <Tooltip placement="top" isOpen={state.tooltipMissionTemplateOpen} autohide={false} target="TemplateTypeText" toggle={() => setState({ ...state, tooltipMissionTemplateOpen: !state.tooltipMissionTemplateOpen })}>
                            O template da missão é um esboço de conteúdo já formatado que agiliza a criação do treinamento bastando preenchê-lo com as informações necessárias.
                        </Tooltip>
                    </Label>
                    <Input type="select" name="selectMulti" id="position" value={templateType} onChange={(event: any) => setState({ ...state, templateType: +event.target.value })}>
                        <option value={ModuleTemplateType.DEFAULT}>Treinamento Padrão</option>
                        <option value={ModuleTemplateType.SHORT}>Treinamento Curto</option>
                        <option value={ModuleTemplateType.VIDEO}>Video</option>
                        <option value={ModuleTemplateType.URL_AI}>Site</option>
                    </Input>
                    <FormText><span style={{ color: 'black' }}>{templateText[state.templateType]}</span></FormText>
                </FormGroup>
                {templateType === ModuleTemplateType.URL_AI && <FormGroup>
                    <Label for="contentUrl">Endereço do Site</Label>
                    <Input invalid={!checkIfUrlIsValid(url)} name="title" id="contentUrl" placeholder="https://www.site.com" onChange={(event: any) => setState({ ...state, contentUrl: event.target.value })} />
                </FormGroup>}
                {signUpConfig.authData && <FormGroup switch>
                    <Input checked={authDataFilterOn} type="switch" role="switch" onChange={e => setState({ ...state, authDataFilterOn: e.target.checked })} />
                    <Label check>Restringir acesso por tipo de usuário</Label>
                    <FormText>
                        <br />Permite definir uma segmentação por tipo de usuário que pode acessar o treinamento.
                    </FormText>
                    {authDataFilterOn && signUpConfig.authData && renderAuthDataDropdownSelection(signUpConfig.authData, authDataSelectedValues)}
                </FormGroup>}
                {contentConfig && contentConfig.moduleCategories && contentConfig.moduleCategories.length > 0 && renderModuleCategoryDropdownSelection(contentConfig.moduleCategories, categoriesSelectedValues)}
                <FormGroup switch className="mt-2">
                    <Label check for="limiterQuizCheckbox">Limitar número de tentativas do Quiz</Label>
                    <Input id="limiterQuizCheckbox" type="checkbox" checked={limiterQuiz > 0} onChange={e => setState({ ...state, limiterQuiz: e.target.checked ? 10 : 0 })} />
                    <FormText>
                        <br />O quiz é um banco de questões aleatórias que servem para avaliar se ocorreu o aprendizado. Você pode limitar quantas vezes o usuário pode jogar o quiz em um determinado período.
                    </FormText>
                </FormGroup>
                {limiterQuiz > 0 && <Row className='mt-1'>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="limiterQuiz">Número de Tentativas</Label>
                            <Input name="limiterQuiz" id="limiterQuiz" type="number" value={limiterQuiz} min={1} max={100} onChange={(event: any) => setState({ ...state, limiterQuiz: +event.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="frequency">Período</Label>
                            <Input type="select" value={frequency} name="frequency" id="frequency" onChange={(event: any) => setState({ ...state, frequency: event.target.value })}>
                                <option value={Frequency.Day}>Por dia</option>
                                <option value={Frequency.Week}>Por semana</option>
                                <option value={Frequency.Month}>Por mês</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>}
            </Form>
            <Button block disabled={!isInputValid(title, templateType, url)} color="primary" onClick={() => proxyCreateModule(title, pos, defaultPic, fileDataUrl, authDataSelectedValues, categoriesSelectedValues.map(({ id }) => { return id }), authDataFilterOn, limiterQuiz, frequency, templateType, url, signUpConfig.authData)}>Salvar</Button>
        </Col>)
    }

    let { warningMessage, saveAfterWarning, error, isLoading, fileDataUrl, title, pos, signUpConfig, contentConfig, authDataSelectedValues,
        limiterQuiz, frequency, authDataFilterOn, categoriesSelectedValues, templateType, contentUrl } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    let defaultPic = 'https://storage.googleapis.com/deafult_media/default_mission_1.png'

    return (<Container className="d-flex flex-column relative" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && <Row><Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}><Alert color="danger">{error}</Alert></Col></Row>}
        {warningMessage && renderWarning(warningMessage, saveAfterWarning)}
        {company && renderHeader(company)}
        <Row style={{ paddingBottom: 10, overflow: 'auto', zIndex: 1 }}>
            <Col className="mt-2 d-flex flex-column justify-content-center" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                {renderModulePicOrLoading(defaultPic, fileDataUrl, isLoading)}
                <div className="d-flex justify-content-center">
                    <Button outline style={{ margin: 10, width: 'auto' }} onClick={() => openSelectImageDialog()}><FontAwesomeIcon icon={faImage} /> Selecione uma foto para a missão</Button>
                </div>
            </Col>
            {company && signUpConfig && renderForm(title, pos, defaultPic, signUpConfig, contentConfig, authDataSelectedValues, categoriesSelectedValues, limiterQuiz, frequency, authDataFilterOn, fileDataUrl, templateType, contentUrl)}
        </Row>
    </Container>)
}


export default CreateModuleScreen