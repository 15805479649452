import axios from 'axios'
import User from '../models/User'

export default class ProfileService {
    uploadProfilePic = async (token: string, mainUrl: string, data: FormData): Promise<UserPicResult> => {
        let url = `${mainUrl}/mobile/users/userPic`
        let result = await axios({
            method: 'post',
            url,
            data,
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        return new UserPicResult(result.data.data)
    }

    updateUser = async (token: string, mainUrl: string, updateData: any): Promise<User> => {
        let url = `${mainUrl}/mobile/users/update`
        let result = await axios({
            method: 'post',
            url,
            data: updateData,
            headers: {
                'Authorization': `Token ${token}`
            }
        })
        return new User(result.data.data.user)
    }

    getUser = async (token: string, mainUrl: string, userId: string): Promise<User | undefined> => {
        let url = `${mainUrl}/mobile/users/user`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        if(result && result.data && result.data.data && result.data.data.user) {
            return new User(result.data.data.user)
        }
    }
}

export class UserPicResult {
    mainPicUrl: string
    thumbnail: string
    constructor(data: any) {
        this.mainPicUrl = data.mainPicUrl
        this.thumbnail = data.thumbnail
    }
}