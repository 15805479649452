import React, { useEffect } from 'react';
import { Container, Alert } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Company from '../../../../models/Company';
import { SubModule, SubModuleTypes } from '../../../../models/SubModule';
import TokenHelper from '../../../../auth/TokenHelper';
import AdminContentService from '../../../../services/admin/adminContentService';
import config from '../../../../config';
import LoadingScreen from '../../../loading';
import DeckComponent from './DeckComponent'
import QuizComponent from './QuizComponent';
import * as ROUTES from '../../../../constants/routes';
import { useUser } from '../../../../hooks/useUser';

enum Action {
    ViewCards = 1,
    CreateCard,
    CreateImage,
}

interface State {
    company: Company | undefined;
    error: any | undefined;
    isLoading: boolean;
    subModule: SubModule | undefined;
    action: Action;
};

const INITIAL_STATE: State = {
    company: undefined,
    error: undefined,
    isLoading: false,
    subModule: undefined,
    action: Action.ViewCards,
};

const CardsScreen = () => {

    const navigate = useNavigate()
    const { subModuleId } = useParams()
    const { company } = useUser()
    const [state, setState] = React.useState<State>(INITIAL_STATE)

    useEffect(() => {
        if (subModuleId) loadSubModule(subModuleId)
    }, [subModuleId])



    const loadSubModule = async (subModuleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let subModule = await adminContentService.getSubModule(token, config.endpoint, subModuleId)
            setState({ ...state, subModule, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadSubModule(subModuleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const goBack = () => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SUBMODULE_SELECT}/${state.subModule!.moduleId}`)


    let { error, isLoading, subModule } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    if (error) {
        return <Container className="d-flex flex-column" fluid style={{ height: '100vh', background: 'white' }}>
            <Alert color="danger">{error}</Alert>
        </Container>
    }

    if (subModule) {
        if (subModule.type === SubModuleTypes.DECK) return <DeckComponent subModule={subModule} endpoint={config.endpoint} goBack={goBack} />
        else if (subModule.type === SubModuleTypes.QUIZ) return <QuizComponent subModule={subModule} endpoint={config.endpoint} goBack={goBack} />
    }

    return <div />

}


export default CardsScreen