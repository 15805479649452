import React from 'react';
import { Container, Row, Col, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { SubModule, SubModuleTypes, SubModuleVideo } from '../../../models/SubModule';
import Company from '../../../models/Company';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faGamepad, faEdit, faArrowLeft, faTrashAlt, faPlusCircle, faInfoCircle, faVideo, faLink, faExclamationTriangle, faStream, faFile, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import * as ROUTES from '../../../constants/routes';
import TokenHelper from '../../../auth/TokenHelper';
import config from '../../../config';
import Module from '../../../models/Module'
import AdminContentService from '../../../services/admin/adminContentService';
import LoadingScreen from '../../loading';
import _, { min } from 'lodash'
import EditSubModuleComponent from './EditSubModuleComponent';
import ToolTipComponent from './TooltipComponent';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useUser } from '../../../hooks/useUser';
import { render } from 'react-dom';
import { SetModuleFeedbackModal } from './SetModuleFeedbackModal';


interface State {
    error: any | undefined;
    isLoading: boolean;
    module: Module | undefined;
    subModules: Array<SubModule>;
    subModuleVideos: Array<SubModuleVideo>;
    subModuleDeleteId: string | undefined;
    editSubModule: SubModule | undefined;
    isSubModuleToolTipOpen: { [id: string]: boolean }
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    module: undefined,
    subModules: [],
    subModuleVideos: [],
    subModuleDeleteId: undefined,
    editSubModule: undefined,
    isSubModuleToolTipOpen: {}
};

const SelectSubModuleScreen = () => {

    const { height } = useWindowDimensions()
    const navigate = useNavigate()
    const { moduleId } = useParams()
    const { company } = useUser()
    const [state, setState] = React.useState<State>(INITIAL_STATE)
    const [showEditFeedbackModal, setShowEditFeedbackModal] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (company && moduleId) {
            loadModule(company.id, moduleId)
        }
    }, [company, moduleId])

    const loadModule = async (companyId: string, moduleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let { module, subModules, subModuleVideos } = await adminContentService.getModuleSubModulesAndData(token, config.endpoint, moduleId)
            setState({ ...state, module, subModules, subModuleVideos, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadModule(companyId, moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const loadSubModules = async (moduleId: string) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let subModules = await adminContentService.getCompanySubModules(token, config.endpoint, moduleId)
            let isSubModuleToolTipOpen = subModules.reduce<{ [id: string]: boolean }>((acc, subModule) => { acc[subModule.id] = false; return acc }, {})
            setState({ ...state, subModules, isLoading: false, isSubModuleToolTipOpen })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadSubModules(moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const deleteSubModule = async (subModuleId: string) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            await adminContentService.deleteSubModule(token, config.endpoint, subModuleId)
            let subModules = state.subModules.filter(subModule => subModule.id !== subModuleId)
            setState({ ...state, subModuleDeleteId: undefined, subModules: subModules.sort((a, b) => a.pos - b.pos) })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                deleteSubModule(subModuleId)
            } else {
                setState({ ...state, subModuleDeleteId: undefined, isLoading: false, error: error.toString() })
            }
        }
    }

    const goToCreateSubModule = (moduleId: string, pos: number) => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.CREATE_SUBMODULE}/${moduleId}?pos=${pos + 1}`)

    const goToSubModuleCards = (subModuleId: string) => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.CARDS}/${subModuleId}`)

    const goToEditSubModule = (subModule: SubModule) => setState({ ...state, editSubModule: subModule })

    const changeSubModuleVisibility = async (id: string, visible: boolean) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            await adminContentService.changeSubModuleVisibility(token, config.endpoint, id, visible)
            let subModules = state.subModules
            let subModule = _.first(subModules.filter(subModule => subModule.id === id))
            if (subModule) {
                subModule.visible = visible
                setState({ ...state, subModules })
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                changeSubModuleVisibility(id, visible)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const renderHeader = (module: Module, pos: number, editSubModule: SubModule | undefined) => {
        return (<Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { editSubModule ? setState({ ...state, editSubModule: undefined }) : navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SELECT_MODULE}`) }}><FontAwesomeIcon color='#0b0c10' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={module.pic} />
                    {/* Nunca fiz tanta gambiarra na minha vida igual essa pra fazer o texto to nome da missão ficar em uma linha e terminar com ... */}
                    <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap', marginLeft: 10, color: '#353f45' }}>
                        <div><b>{editSubModule ? 'Editar Fase' : 'Missão'}</b></div>
                        <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', verticalAlign: 'middle' }}>{editSubModule ? editSubModule.title : module.title}</div>
                    </div>
                    <Button className="d-flex justify-content-start align-items-center" color="primary" style={{ fontSize: '1em', borderStyle: 'none', paddingTop: 10, paddingBottom: 10 }} onClick={() => { goToCreateSubModule(module.id, pos) }}>
                        <FontAwesomeIcon style={{ verticalAlign: 'middle', marginRight: 5 }} icon={faPlusCircle} />
                        <div className="d-flex flex-column align-items-start ml-1" style={{ fontSize: '0.7em' }}>
                            <span style={{ verticalAlign: 'middle', marginTop: 1 }}>Nova Fase</span>
                        </div>
                    </Button>
                </div>
            </Col>
        </Row>)
    }

    const updateUserModuleFeedback = async (title: string, description: string, minCharCount: number, blockCertificate: boolean) => {
        let tokenHelper = new TokenHelper()
        try {
            setState({ ...state, isLoading: true, error: undefined })
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            const feedback = { title, description, minCharCount, blockCertificate }
            await adminContentService.setModuleFeedback(token, config.endpoint, module!.id, feedback)
            let updatedModule = { ...state.module!, feedback } as Module
            setState({ ...state, module: updatedModule, isLoading: false, error: undefined })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                updateUserModuleFeedback(title, description, minCharCount, blockCertificate)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const activateUserModuleFeedback = async () => {
        let tokenHelper = new TokenHelper()
        try {
            setState({ ...state, isLoading: true, error: undefined })
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            const feedback = { title: 'Autoavaliação do conhecimento', description: 'O que você achou deste treinamento?', minCharCount: 100, blockCertificate: true }
            await adminContentService.setModuleFeedback(token, config.endpoint, module!.id, feedback)
            let updatedModule = { ...state.module!, feedback } as Module
            setState({ ...state, module: updatedModule, isLoading: false, error: undefined })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                activateUserModuleFeedback()
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const deActivateUserModuleFeedback = async () => {
        let tokenHelper = new TokenHelper()
        try {
            setState({ ...state, isLoading: true, error: undefined })
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            await adminContentService.deactivateModuleFeedback(token, config.endpoint, state.module!.id)
            let updatedModule = { ...state.module!, feedback: undefined } as Module
            setState({ ...state, module: updatedModule, isLoading: false, error: undefined })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                activateUserModuleFeedback()
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const renderSubModules = (subModules: Array<SubModule>, subModuleVideos: Array<SubModuleVideo>) => {
        const isLastButtonOdd = subModules.length % 2 === 1
        return (<Row className="pt-4 ml-1 mr-1" style={{ overflow: 'auto' }}>
            <Col className="d-flex flex-column" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div style={{ verticalAlign: 'middle', textAlign: 'center', fontSize: 'small' }}><FontAwesomeIcon style={{ marginRight: 2 }} icon={faInfoCircle} /><i>Os tópicos de uma <b>missão</b> são separados em <b>fases</b>.</i></div>
                <div style={{ verticalAlign: 'middle', textAlign: 'center', fontSize: 'small' }}><i>Cada fase trata de um assunto específico de uma parte do treinamento.</i></div>
            </Col>
            {subModules.sort((a, b) => a.pos - b.pos).map((subModule, index) => {
                let isOdd = index % 2
                // meu deus que gambiarra horrível só pra poder retornar os dados certos de acordo com o tipo sem precisar ficar fazendo if-else ou ? :
                let tooltip = (() => {
                    switch (subModule.type) {
                        case SubModuleTypes.DECK: return 'Conteúdo gamificado que ensina através de informações, imagens, gifs e elementos interativos.'
                        case SubModuleTypes.QUIZ: return "Permite avaliar o aprendizado do usuário através de um banco de questões que aparecem em ordem aleatória e com tempo para responder."
                        case SubModuleTypes.VIDEO: return "Video que o usuário assiste dentro da plataforma e ganha pontos ao assistir até o final.<br>Recomendamos videos de até 3 minutos e com tamanho máximo de 32MB"
                        case SubModuleTypes.LINK: return "Link que leva para um site, arquivo ou recurso externo. O usuário clica e ele abre em uma nova aba."
                        case SubModuleTypes.LIVE: return "Live de treinamento que acontece em tempo real."
                        case SubModuleTypes.FILE: return "Arquivo que o usuário visualiza como parte do treinamento."
                        default: return "Desconhecido"
                    }
                })()
                let description = (() => {
                    switch (subModule.type) {
                        case SubModuleTypes.DECK: return "Trilha de Aprendizagem"
                        case SubModuleTypes.QUIZ: return "Avaliação de Conhecimento"
                        case SubModuleTypes.VIDEO: return "Video"
                        case SubModuleTypes.LINK: return "Link"
                        case SubModuleTypes.LIVE: return "Live"
                        case SubModuleTypes.FILE: return "Arquivo"
                        default: return "Desconhecido"
                    }
                })()
                let actionButton = (() => {
                    switch (subModule.type) {
                        case SubModuleTypes.DECK: return <Button size="sm" color="primary" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { goToSubModuleCards(subModule.id) }}><FontAwesomeIcon icon={faGamepad} /> Conteúdo</Button>
                        case SubModuleTypes.QUIZ: return <Button size="sm" color="primary" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { goToSubModuleCards(subModule.id) }}><FontAwesomeIcon icon={faGamepad} /> Conteúdo</Button>
                        case SubModuleTypes.VIDEO: return <Button size="sm" color="primary" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { goToEditSubModule(subModule) }}><FontAwesomeIcon icon={faVideo} /> Video</Button>
                        case SubModuleTypes.LINK: return <Button size="sm" color="primary" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { goToEditSubModule(subModule) }}><FontAwesomeIcon icon={faLink} /> Link</Button>
                        case SubModuleTypes.LIVE: return <Button size="sm" color="primary" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { goToEditSubModule(subModule) }}><FontAwesomeIcon icon={faStream} /> Live</Button>
                        case SubModuleTypes.FILE: return <Button size="sm" color="primary" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { goToEditSubModule(subModule) }}><FontAwesomeIcon icon={faFile} /> Arquivo</Button>
                        default: return <div />
                    }
                })()
                return (
                    <Col key={subModule.id} lg={{ size: 4, offset: isOdd ? 0 : 2 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
                        <div className="d-flex flex-row" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                            <div className="d-flex flex-column justify-content-center mt-2" style={{ width: '100%' }}>
                                <div className="d-flex align-items-center" style={{ marginBottom: 2 }}>
                                    <div style={{ marginLeft: 10, flex: 1 }}>
                                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle' }}><b>Fase {subModule.pos}</b></div>
                                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle' }}>{subModule.title.toUpperCase()}</div>
                                        <ToolTipComponent target={`TemplateTypeText${subModule.id}`} tooltip={tooltip} description={description} />
                                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', fontSize: 'small' }}>
                                            {subModule.maxScore > 0 && <span className="me-2">Pontuação máxima: <b style={{ color: '#40b34f' }}>{subModule.maxScore}</b></span>}
                                            {!subModule.visible && <span><FontAwesomeIcon icon={faEyeSlash} /> Escondido</span>}
                                        </div>
                                        <Button size="sm" outline style={{ borderStyle: 'none', marginLeft: -5, paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2, marginTop: 2 }} onClick={() => { goToEditSubModule(subModule) }}><FontAwesomeIcon icon={faEdit} /> <b>Editar</b></Button>
                                    </div>
                                </div>
                                {subModule.type === SubModuleTypes.VIDEO && subModuleVideos.filter((video) => video.subModuleId === subModule.id).length === 0 && <span className="mr-2" style={{ color: 'red', verticalAlign: 'middle', fontSize: 'small', marginLeft: 10 }}><FontAwesomeIcon icon={faExclamationTriangle} /> <b>Nenhum video carregado</b></span>}
                                {subModule.type === SubModuleTypes.LINK && !subModule.externalResourceUrl && <span className="mr-2" style={{ color: 'red', verticalAlign: 'middle', fontSize: 'small', marginLeft: 10 }}><FontAwesomeIcon icon={faExclamationTriangle} /> <b>Nenhum link carregado</b></span>}
                                <div className="d-flex mt-2">
                                    {!subModule.visible && <Button size="sm" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { changeSubModuleVisibility(subModule.id, true) }} color="success"><FontAwesomeIcon icon={faEye} /> Mostrar</Button>}
                                    {subModule.visible && <Button size="sm" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { changeSubModuleVisibility(subModule.id, false) }} color="warning"><FontAwesomeIcon icon={faEyeSlash} /> Esconder</Button>}
                                    {actionButton}
                                    <Button size="sm" outline color="danger" style={{ marginTop: 5, marginLeft: 5, borderStyle: 'none' }} onClick={() => { setState({ ...state, subModuleDeleteId: subModule.id }) }}><FontAwesomeIcon icon={faTrashAlt} /> Deletar</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                )
            })}
            <Col lg={{ size: 4, offset: isLastButtonOdd ? 0 : 2 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
                {module?.feedback ? <div className="d-flex flex-row" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                    <div className="d-flex flex-column justify-content-center mt-2" style={{ width: '100%' }}>
                        <div className="d-flex align-items-center" style={{ marginBottom: 2 }}><b>{module.feedback.title}</b></div>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle' }}>{module.feedback.description}</div>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', fontSize: 'small' }}>
                            <span className="me-2">Mínimo caracteres: <b>{module.feedback.minCharCount}</b></span>
                        </div>
                        <div className='mt-2'>
                            <Button size="sm" outline style={{ borderStyle: 'none', paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2, marginTop: 2 }} onClick={() => { setShowEditFeedbackModal(true) }}><FontAwesomeIcon icon={faEdit} /> <b>Editar</b></Button>
                        </div>
                        <div className='mt-4'>
                            <Button size="sm" outline color="danger" style={{ marginTop: 5,  borderStyle: 'none' }} onClick={() => { deActivateUserModuleFeedback() }}><FontAwesomeIcon icon={faPowerOff} /> Desativar</Button>
                        </div>
                    </div>
                </div> :
                    <div className="d-flex flex-column align-items-center gap-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle' }}><b>Autoavaliação do conhecimento</b></div>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center' }}>Pergunta discursiva que deve ser respondida para avaliação da eficácia do treinamento</div>
                        <Button color='primary' onClick={() => activateUserModuleFeedback()}>Ativar</Button>
                    </div>}
            </Col>
        </Row>)
    }

    const renderDeleteMessage = (subModuleId: string) => {
        let selectedSubModule = _.head(state.subModules.filter(subModule => subModule.id === subModuleId))!
        return (<Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => setState({ ...state, subModuleDeleteId: undefined })}>
                    <ModalHeader>
                        <div>Tem certeza que quer deletar esta fase?</div>
                    </ModalHeader>
                    <ModalBody>
                        <div>Ao deletar {selectedSubModule.title} todo o seu conteúdo será perdido.</div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center align-items-center' >
                        <Button color="primary" onClick={() => setState({ ...state, subModuleDeleteId: undefined })}>Não</Button>
                        <Button color="secondary" onClick={() => deleteSubModule(subModuleId)}>Sim, desejo deletar</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    const renderEditSubModule = (company: Company, subModule: SubModule) => {
        let onUpdate = (subModule: SubModule, subModuleVideo?: SubModuleVideo) => {
            let subModules = [...state.subModules]
            let index = subModules.reduce((acc, val, index) => val.id === subModule.id ? index : acc, 0)
            subModules[index] = subModule
            if (subModuleVideo) {
                let subModuleVideos = [...state.subModuleVideos, subModuleVideo]
                setState({ ...state, subModules, subModuleVideos, editSubModule: undefined })
            } else {
                setState({ ...state, subModules, editSubModule: undefined })
            }
        }
        return <Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div className="mt-2">
                    <EditSubModuleComponent company={company} subModule={subModule} onUpdate={onUpdate} exit={() => setState({ ...state, editSubModule: undefined })} />
                </div>
            </Col>
        </Row>
    }

    let { error, isLoading, module, subModules, subModuleVideos, subModuleDeleteId, editSubModule } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column relative" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && <Alert color="danger">{error}</Alert>}
        {module && renderHeader(module, subModules.reduce((acc, prev) => { return prev.pos > acc ? prev.pos : acc }, 1), editSubModule)}
        {subModuleDeleteId && renderDeleteMessage(subModuleDeleteId)}
        {showEditFeedbackModal && <SetModuleFeedbackModal moduleId={module!.id} title={module!.feedback!.title} description={module!.feedback!.description} minCharCount={module!.feedback!.minCharCount} blockCertificate={module!.feedback!.blockCertificate} onClose={() => { setShowEditFeedbackModal(false) }} onUpdate={(title: string, description: string, minCharCount: number, blockCertificate: boolean) => {
            setShowEditFeedbackModal(false)
            updateUserModuleFeedback(title, description, minCharCount, blockCertificate)
        }} />}
        {editSubModule && renderEditSubModule(company!, editSubModule)}
        {!editSubModule && renderSubModules(subModules, subModuleVideos)}
    </Container>)
}

export default SelectSubModuleScreen