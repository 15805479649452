import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { Button, Col, Row } from 'reactstrap';

const WebViewContainer = (props: { url: string, exitText: string, onExit: () => void }) => {

    return (<Fragment>
        <Row className='no-gutters' style={{ background: '#FFFFFFDA', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.2)', paddingTop: 5, paddingBottom: 5, marginBottom: 2 }}>
            <Col className='d-flex align-items-center' style={{ height: '4em' }} md={{ size: 8, offset: 2 }}>
                <Button color='none' outline onClick={() => { props.onExit() }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap' }} onClick={() => { props.onExit() }}>
                    <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#1d3256', verticalAlign: 'middle' }}><b>{props.exitText}</b></div>
                </div>
            </Col>
        </Row>
        <Row className='no-gutters' style={{ flex: 1 }}>
			<Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
				<iframe title="link externo" style={{ width: '100%', height: '100%' }} src={props.url} frameBorder="0"></iframe>
			</Col>
		</Row>
    </Fragment>)

}

export default WebViewContainer