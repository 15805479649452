import React, { Component } from 'react';
import { CreateQuizProps } from './interfaces';
import { QuizQuestionType, WordGuessQuestion } from '../../../../../models/QuizQuestion';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

interface WordGuessQuestionState {
    title: string
    word: string
}

const WORD_GUESS_QUESTION_INITIAL_STATE: WordGuessQuestionState = {
    title: '',
    word: '',
}

export default class CreateWordGuessComponent extends Component<CreateQuizProps, WordGuessQuestionState> {

    constructor(props: CreateQuizProps) {
        super(props)
        this.state = { ...WORD_GUESS_QUESTION_INITIAL_STATE }
    }

    createCard = (title: string, word: string) => {
        let { subModule, lastPosition } = this.props
        let wordGuessQuestion = new WordGuessQuestion({
            id: subModule.id + '_' + lastPosition.toString(),
            type: QuizQuestionType.WordGuess,
            companyId: subModule.companyId,
            moduleId: subModule.moduleId,
            subModuleId: subModule.id,
            title,
            word
        })
        this.props.addQuizCard(wordGuessQuestion)
    }

    isInputValid = (title: string, word: string) => {
        if (title.length < 3) return false
        if (word.length < 2 && word.length > 10) return false
        return true
    }

    render() {
        let { word, title } = this.state
        return (<Row>
            <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <Form style={{ margin: 5 }}>
                    <FormGroup>
                        <Label for="cardTitle">Título</Label>
                        <Input name="title" id="cardTitle" placeholder="Título" onChange={(event: any) => this.setState({ title: event.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="word">Palavra ( máximo 10 caracteres )</Label>
                        <Input name="wrd" id="word" value={word} onChange={(event: any) => {
                            let val = event.target.value
                            if (val.length <= 10) this.setState({ word: val })
                        }} />
                    </FormGroup>
                </Form>
            </Col>
            <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <Button style={{ margin: 5 }} disabled={!this.isInputValid(title, word)} color="primary" onClick={() => this.createCard(title, word)}>Adicionar</Button>
            </Col>
        </Row>)
    }

}