import React from 'react'
import Chrome from '@uiw/react-color-chrome'
import { GithubPlacement } from '@uiw/react-color-github'

function ColorPicker(props: { color: string, onColorUpdate: (newColor: string) => void }) {
    let { color, onColorUpdate } = props
    return (
        <Chrome
          color={color}
          style={{ float: 'left' }}
          placement={GithubPlacement.Top}
          onChange={(color) => {
            onColorUpdate(color.hexa)
          }}
        />
    );
  }
  export default ColorPicker;

