import React, { Component, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config'
import Company from '../../../models/Company';
import { Alert, Button, Col, Container, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import User from '../../../models/User';
import { MonthUserCompanyScore } from '../../../models/Scores';
import Module from '../../../models/Module';
import { SubModule } from '../../../models/SubModule';
import TokenHelper from '../../../auth/TokenHelper';
import AdminService from '../../../services/adminService';
import ActivitySummaryComponent from './ActivitySummaryComponent';
import { getUsersWhoShouldPlayModule } from './ProgressHelper'
import { FiltersDropdown } from './ChartsFilterComponents';
import { ReportConfig, SignUpConfig } from '../../../models/CompanyConfig';
import { DashboardAuthDataInput } from './DashboardAuthDataInput';
import { getFilteredUsers } from './UsageReport/UsageFilters';
import { useUser } from '../../../hooks/useUser';


interface State {
    error: string | undefined;
    isLoading: boolean;
    users: Array<User>;
    monthUsersCompanyScore: Array<MonthUserCompanyScore>;
    modules: Array<Module>;
    subModules: Array<SubModule>;
    selectedYear: number;
    selectedModule: Module | undefined;
    reportConfig: ReportConfig | undefined
    signUpConfig: SignUpConfig | undefined;
    authDataSelectedValues: { [id: string]: string }
}

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    users: [],
    monthUsersCompanyScore: [],
    modules: [],
    subModules: [],
    selectedYear: (new Date()).getFullYear(),
    selectedModule: undefined,
    reportConfig: undefined,
    signUpConfig: undefined,
    authDataSelectedValues: {},
}

const ChartsScreen = () => {

    const navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { company, noCompany } = useUser()

    useEffect(() => {
        if (company) loadYearData(state.selectedYear)
        if (noCompany) setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.' })
    }, [company, noCompany])

    useEffect(() => {
        loadYearData(state.selectedYear)
    }, [state.selectedYear])

    const updateAuthDataSelection = (selection: string | undefined, id: string) => {
        let authDataSelectedValues = state.authDataSelectedValues
        if (selection === undefined || selection.length === 0) {
            delete authDataSelectedValues[id]
            setState({ ...state, authDataSelectedValues })
        } else {
            authDataSelectedValues[id] = selection
            setState({ ...state, authDataSelectedValues })
        }
    }

    const loadYearData = async (selectedYear: number) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let adminService = new AdminService()
            let { users, monthUsersCompanyScore, modules, subModules, reportConfig, signUpConfig } = await adminService.getYearUsage(token, config.endpoint, selectedYear)
            setState({ ...state, users, monthUsersCompanyScore, modules, subModules, isLoading: false, selectedModule: undefined, reportConfig, signUpConfig })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadYearData(selectedYear)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const renderHeader = (company: Company) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Gráficos de Uso</b></div>
                    <img alt='foto da empresa' style={{ height: 60, marginBottom: 10, marginTop: 10 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderYearFilter = (selectedYear: number, modules: Array<Module>, selectedModule?: Module) => {
        return (<Row className="mt-1">
            <FiltersDropdown selectedYear={selectedYear} newYearSelected={(selectedYear) => setState({ ...state, selectedYear })} selectedModule={selectedModule} modules={modules} newModuleSelected={(selectedModule) => setState({ ...state, selectedModule })} />
        </Row>)
    }

    const renderContent = (isLoading: boolean, users: Array<User>, monthUsersCompanyScore: Array<MonthUserCompanyScore>, selectedModule: Module | undefined, modules: Array<Module>, subModules: Array<SubModule>, selectedYear: number) => {
        if (isLoading) {
            return (<Row className="mt-3">
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />{' '}
                    </div>
                </Col>
            </Row>)
        } else {
            let sortedModules = selectedModule ? [selectedModule] : modules.sort((a, b) => a.pos - b.pos)
            let selectedSubModules = selectedModule ? subModules.filter(subModule => subModule.moduleId === selectedModule.id) : subModules
            let slectedUsers = selectedModule ? getUsersWhoShouldPlayModule(users, selectedModule) : users
            if (!state.signUpConfig) return (<div>Ops, parece que essa empresa não tem configurações de relatório.</div>)
            return (<ActivitySummaryComponent users={slectedUsers} monthUsersCompanyScore={monthUsersCompanyScore} modules={sortedModules} subModules={selectedSubModules} year={selectedYear} signUpConfig={state.signUpConfig!} reportConfig={state.reportConfig} />)
        }
    }

    let { error, isLoading, selectedYear, selectedModule, users, monthUsersCompanyScore, modules, subModules, signUpConfig, authDataSelectedValues } = state

    const filteredUsers = getFilteredUsers(users, '', authDataSelectedValues)

    return (<Container className="d-flex flex-column" fluid style={{ overflow: 'hidden', height: '100vh', background: 'white' }}>
        {error && <Alert color="danger">{error}</Alert>}
        {company && renderHeader(company)}
        {renderYearFilter(selectedYear, modules, selectedModule)}
        {signUpConfig && <DashboardAuthDataInput authData={signUpConfig.authData} authDataSelectedValues={authDataSelectedValues} updateAuthDataSelection={(selection: string | undefined, id: string) => updateAuthDataSelection(selection, id)} />}
        {renderContent(isLoading, filteredUsers, monthUsersCompanyScore, selectedModule, modules, subModules, selectedYear)}
    </Container>)

}

export default ChartsScreen

