import React, { useState, useEffect } from "react";
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'

interface Props {
    audioSrc: string
    start: () => void
    setDuration?: (duration: number) => void
}

export default function AudioPlayButton(props: Props) {

    const [audio] = useState(new Audio())
    const [canPlay, setCanPlay] = useState<boolean>(false)
    const [isPlaying, setIsPlaying] = useState<boolean>(false)

    useEffect(() => {
        audio.onplay = () => { props.start() }
        audio.oncanplay = () => { setCanPlay(true) }
        audio.addEventListener('ended', () => {
            setIsPlaying(false)
        })
        audio.onerror = (event) => {
            console.log('error')
            console.log(event)
        }
        if (props.setDuration) {
            audio.addEventListener('loadedmetadata', () => {
                // Obtain the duration in seconds of the audio file
                props.setDuration!(audio.duration)
            }, false);
        }
        audio.src = props.audioSrc
    }, [])

    useEffect(() => {
        if (isPlaying) {
            audio.play()
        }
    }, [isPlaying, audio])

    return <Button outline disabled={isPlaying || !canPlay} onClick={() => { setIsPlaying(true) }}><FontAwesomeIcon className="me-1" icon={faVolumeUp} />{isPlaying ? 'Tocando...' : 'Tocar Audio'}</Button>
}