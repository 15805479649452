import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Row, Col, Button, Alert, Input } from 'reactstrap'

import LoadingScreen from '../loading'
import Cookies from 'universal-cookie';
import Company from '../../models/Company';
import config from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl, faUserCircle, faBars, faTimesCircle, faCog, faArrowLeft, faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import User from '../../models/User';
import ContentService from '../../services/contentService';
import TokenHelper from '../../auth/TokenHelper';
import Module from '../../models/Module';
import { UserMaxScoreInSubModule } from '../../models/Scores';
import { SubModule } from '../../models/SubModule';
import SelectModuleComponent from './SelectModuleComponent'
import './style.css'
import AccountCircleSvg from '../../images/account_circle-24px.svg';
import ProfileService from '../../services/profileService';
import { SignUpConfig, ContentConfig, RankingConfig } from '../../models/CompanyConfig';
import * as ROUTES from '../../constants/routes'
import { useUser } from '../../hooks/useUser';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

interface State {
    signUpConfig?: SignUpConfig;
    contentConfig?: ContentConfig;
    companyRankingConfig?: { [id: string]: RankingConfig; }
    error: string | undefined;
    isLoading: boolean;
    modules: Array<Module>;
    companySubModules: Array<SubModule>;
    userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>;
    isMenuOpen: boolean;
    userTeam: string | undefined;
    searchInput: string;
    categorySelected: string,
};

const INITIAL_STATE: State = {
    signUpConfig: undefined,
    contentConfig: undefined,
    error: undefined,
    isLoading: false,
    modules: [],
    companySubModules: [],
    userMaxScoreInSubModules: [],
    isMenuOpen: false,
    userTeam: undefined,
    searchInput: '',
    categorySelected: '',
};

type TParams = { companyId: string };


const MainScreen = () => {

    const navigate = useNavigate()
    const { user, company, noUser, noCompany } = useUser()
    const [state, setState] = React.useState<State>({ ...INITIAL_STATE })
    const { height } = useWindowDimensions();
    const { companyId } = useParams<TParams>();

    React.useEffect(() => {
        if (user) getUserData(user.id)
    }, [user])

    React.useEffect(() => {
        if (company) loadCompanySignUpContentRankingConfig(company.id)
    }, [company])

    React.useEffect(() => {
        if ((noCompany || noUser)) {
            if (companyId === 'app') setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.', isLoading: false })
            else navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
        } 
    }, [noCompany, noUser, companyId])

    React.useEffect(() => {
        if(state.contentConfig && companyId) loadModules(companyId, state.contentConfig)
    }, [state.contentConfig, companyId])


    const loadModules = async (companyId: string, contentConfig: ContentConfig | undefined) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let contentService = new ContentService()
            let res = await contentService.getCompanyModules(token, config.endpoint, companyId)
            let { modules, companySubModules, userMaxScoreInSubModules } = res
            let categoryInCache = window.localStorage.getItem('selectedCategory')
            let isCategoryInCacheValid = contentConfig && contentConfig && contentConfig.moduleCategories && contentConfig.moduleCategories.length > 0 && contentConfig.moduleCategories!.filter(moduleCategory => {
                return moduleCategory.id === categoryInCache
            }).length > 0
            let categorySelected = (isCategoryInCacheValid && categoryInCache !== null) ? categoryInCache : ''
            setState({ ...state, modules, companySubModules, userMaxScoreInSubModules, categorySelected, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadModules(companyId, contentConfig)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const getUserData = async (userId: string) => {
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let profileService = new ProfileService()
            let user = await profileService.getUser(token, config.endpoint, userId)
            if (user) {
                let cookies = new Cookies()
                cookies.set('user', user.getData(), { path: '/', maxAge: 31536000 })
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                getUserData(userId)
            }
        }
    }

    const loadCompanySignUpContentRankingConfig = async (companyId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let { signUpConfig, contentConfig, companyRankingConfig } = await contentService.getCompanySignUpAndContentConfig(token, config.endpoint, companyId)
            setState({ ...state, isLoading: false, signUpConfig, contentConfig, companyRankingConfig })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadCompanySignUpContentRankingConfig(companyId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const goToCompanyRanking = (companyId: string) => {
        navigate(`/${companyId}${ROUTES.SELECT_COMPANY_RANKING}`)
    }

    const goToProfile = (companyId: string) => {
        navigate(`/${companyId}${ROUTES.PROFILE}`)
    }

    const goToHelp = (companyId: string) => {
        navigate(`/${companyId}${ROUTES.HELP}`)
    }

    const goToAdmin = () => {
       navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}`)
    }

    const playModule = (companyId: string, moduleId: string) => {
        navigate(`/${companyId}${ROUTES.SUBMODULE_SELECT}?moduleId=${moduleId}`)
    }

    const logout = (companyId: string) => {
        let cookies = new Cookies()
        cookies.remove('user', { path: '/' })
        cookies.remove('company', { path: '/' })
        cookies.remove('token', { path: '/' })
        cookies.remove('refreshToken', { path: '/' })
        navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
    }

    const renderError = (error: string) => {
        return (
            <Row>
                <Col md={{ size: 10, offset: 1 }}>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>

        );
    }

    const renderHeader = (company: Company, isMenuOpen: boolean) => {
        return (
            <Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                <Col md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Button color='none' outline onClick={() => { setState({ ...state, isMenuOpen: !isMenuOpen }) }}><FontAwesomeIcon style={{ color: '#353f45' }} icon={faBars} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, marginBottom: 10, marginTop: 10, objectFit: 'contain' }} src={company.pic} />
                        <Button color='none' outline onClick={() => { goToHelp(company.id) }}><FontAwesomeIcon style={{ verticalAlign: 'middle' }} color='#353f45' icon={faQuestionCircle} size='2x' /></Button>
                    </div>

                </Col>
            </Row>)
    }

    const renderMenu = (user: User, companyId: string) => {
        return (<Row style={{ zIndex: 2 }}>
            <Col className="relative" md={{ size: 12 }}>
                <div className="d-flex flex-column" style={{ zIndex: 1, position: 'absolute', top: 80, left: 0, width: '100%', height: '100vh', background: '#FFFFFFDF' }}>
                    <div className="d-flex flex-column align-items-center relative" style={{ flex: 1 }}>
                        <img alt='user pic' style={{ objectFit: 'cover', height: 100, width: 100, borderRadius: '50%', marginBottom: 5, marginTop: 10, alignSelf: 'center', borderStyle: 'solid' }} src={user.thumbnail || AccountCircleSvg} />
                        <div className="d-flex justify-content-center" style={{ color: '#0b0c10', verticalAlign: 'middle', fontSize: 16, textAlign: 'center', marginLeft: 5, marginRight: 5 }}>
                            {user.username}
                        </div>
                        <Button className="d-flex justify-content-center align-items-center" style={{ padding: 5, margin: 5, width: '80%', maxWidth: 250, backgroundColor: '#5682a9', borderColor: '#5682a9' }} onClick={() => { goToProfile(companyId) }}><FontAwesomeIcon style={{ marginRight: 10 }} icon={faUserCircle} /> Perfil</Button>
                        {state.companyRankingConfig && <Button className="d-flex justify-content-center align-items-center" style={{ padding: 5, margin: 5, width: '80%', maxWidth: 250, backgroundColor: '#5682a9', borderColor: '#5682a9' }} onClick={() => { goToCompanyRanking(companyId) }}><FontAwesomeIcon style={{ marginRight: 10 }} icon={faListOl} /> Ranking</Button>}
                        {user.admin && <Button className="d-flex justify-content-center align-items-center" style={{ padding: 5, margin: 5, width: '80%', maxWidth: 250, backgroundColor: '#5682a9', borderColor: '#5682a9' }} onClick={() => { goToAdmin() }}><FontAwesomeIcon style={{ marginRight: 10 }} icon={faCog} /> Painel Admin</Button>}
                        <Button className="d-flex justify-content-center align-items-center" style={{ padding: 5, margin: 5, width: '80%', maxWidth: 250, backgroundColor: '#5682a9', borderColor: '#5682a9' }} onClick={() => { logout(companyId) }}><FontAwesomeIcon style={{ marginRight: 10 }} icon={faTimesCircle} /> Sair da Conta</Button>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    const renderUserProfilePic = (user: User) => {
        if (!user.pic) return (<div><FontAwesomeIcon color='black' icon={faUserCircle} size='4x' /></div>)
        else return <img style={{ width: '4em', height: '4em', borderRadius: '2em', objectFit: 'cover' }} alt='prof pic' src={user.pic} />
    }

    const renderLastMonthChampion = (lastMonthChampion: User, lastMonthChampionScore: number, userTeam: string | undefined) => {
        return (<Row>
            <Col md={{ size: 10, offset: 1 }}>
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: 15 }}>
                    <div className="d-flex flex-row align-items-center justify-content-center" style={{ padding: 5, borderRadius: 2 }}>
                        {renderUserProfilePic(lastMonthChampion)}
                        <div className="d-flex flex-column">
                            <div style={{ fontSize: 12, color: 'black', verticalAlign: 'middle', marginTop: 5, marginLeft: 5, marginRight: 5 }}><b>Campeão do Mês Passado</b></div>
                            <div style={{ fontSize: 10, color: 'black', verticalAlign: 'middle', margin: 5 }}>{lastMonthChampion.username} - {lastMonthChampionScore} pontos</div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    const renderSelectMission = (searchInput: string) => {
        return (
            <Row>
                <Col className="d-flex flex-column" md={{ size: 10, offset: 1 }}>
                    <div style={{ color: '#353f45', textAlign: 'center', marginTop: 20, marginBottom: 5 }}><b>SELECIONE UMA MISSÃO PARA JOGAR</b></div>
                </Col>
                <Col className="d-flex flex-column" md={{ size: 10, offset: 1 }}>
                    <Input style={{ opacity: 0.85 }} name="title" id="docTitle" placeholder='Procurar missão' value={searchInput} onChange={(event: any) => {
                        setState({ ...state, searchInput: event.target.value })
                    }} />
                </Col>
            </Row>)
    }

    const renderCategoryNavigation = (moduleCategories: Array<{ id: string, title: string }>, selectedCategory: string) => {
        if (selectedCategory === 'none') {
            return <Row style={{ background: '#FFFFFFDA' }}>
                <Col className="mt-3 mb-3" md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', color: '#353f45' }}>
                        <div style={{ width: '4em' }} onClick={() => { setState({ ...state, categorySelected: '' }) }}><Button onClick={() => { setState({ ...state, categorySelected: '' }) }} outline style={{ color: '#353f45', border: 'none' }} ><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button></div>
                        <div style={{ fontSize: 'large', alignSelf: 'center', textAlign: 'center', flexGrow: 1 }}><b>OUTROS</b></div>
                        <div style={{ width: '4em', textAlign: 'center' }}><FontAwesomeIcon icon={faBookOpen} /></div>
                    </div>
                </Col>
            </Row>
        } else {
            return <Row style={{ background: '#FFFFFFDA' }}>
                <Col className="mt-3 mb-3" md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', color: '#353f45' }}>
                        <div style={{ width: '4em' }} onClick={() => { setState({ ...state, categorySelected: '' }) }}><Button onClick={() => { setState({ ...state, categorySelected: '' }) }} outline style={{ color: '#353f45', border: 'none' }} ><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button></div>
                        <div style={{ fontSize: 'large', alignSelf: 'center', textAlign: 'center', flexGrow: 1 }}><b>{moduleCategories.filter(category => { return category.id === selectedCategory })[0].title.toUpperCase()}</b></div>
                        <div style={{ width: '4em', textAlign: 'center' }}><FontAwesomeIcon icon={faBookOpen} /></div>
                    </div>
                </Col>
            </Row>
        }
    }

    let { error, isLoading, modules, companySubModules, userMaxScoreInSubModules, contentConfig, isMenuOpen, searchInput, categorySelected } = state

        if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

        let companyColor = company ? (company.mainColor || '#FFFFFF') : '#FFFFFF'
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(companyColor)
        let rgb = result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [255, 255, 255]
        let brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);
        let textColour = (brightness > 150) ? '#0b0c10' : 'white';

        let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
        let backgroundPosition = company?.backgroundPosition || 'left top'

        let hasCategory = contentConfig && contentConfig.moduleCategories && contentConfig.moduleCategories.length > 0 && modules.filter(module => { return module.categories.length > 0 }).length > 0
        let showSelectMission = !hasCategory || categorySelected !== ''

        return (<Container fluid id="outer-container" className="d-flex flex-column relative" style={{ overflow: 'hidden', height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
            {error && renderError(error)}
            {company && renderHeader(company, isMenuOpen)}
            {user && company && isMenuOpen && renderMenu(user, company.id)}
            <Row><Col className='d-flex justify-content-center' style={{ background: companyColor, paddingTop: 10, paddingBottom: 10, marginTop: 12 }}><div style={{ color: textColour }}>Olá {user ? user.username : ''}</div></Col></Row>
            {hasCategory && categorySelected !== '' && renderCategoryNavigation(contentConfig!.moduleCategories!, categorySelected)}
            {showSelectMission && renderSelectMission(searchInput)}
            {modules && <SelectModuleComponent company={company!} modules={modules} companySubModules={companySubModules} userMaxScoreInSubModules={userMaxScoreInSubModules} playModule={playModule} searchInput={searchInput} contentConfig={contentConfig} categorySelected={categorySelected} onCategorySelected={(categorySelected: string) => { setState({ ...state, categorySelected }); window.localStorage.setItem('selectedCategory', categorySelected) }} />}
        </Container>)

}

export default MainScreen