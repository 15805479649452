import React, { Component } from 'react';
import { Container, Row, Col, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

import Company from '../../models/Company';
import User from '../../models/User';
import Cookies from 'universal-cookie';
import LoadingScreen from '../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './style.css'
import { useUser } from '../../hooks/useUser';

const titles = ['Como funciona a pontuação?', 'O que é o Ranking da Missão?', 'O que é o Ranking da Empresa?']
const bodies = [
    "No jogo temos diferentes missões. Cada missão possui fases e um quiz de avaliação final. Os fases possuem perguntas, caça-palavras e jogo da memória. Você ganha 10 pontos para cada pergunta que acertar e cada palavra que encontrar. O jogo da memória vale 30 pontos, mas você perde 1 ponto para cada tentativa que errar. O quiz possui perguntas aleatórias que valem 45 pontos e você tem 45 segundos para respondê-las, mas você perde 1 ponto para cada segundo que demora a responder. Cada video assistido vale 25 pontos.",
    "Uma missão possui diferentes fases e um quiz de avaliação final. A sua pontuação no ranking de uma missão e a soma da maior pontuação que você conseguiu fazer em cada fase e no quiz de avaliação.",
    "A sua pontuação no ranking da empresa é a soma da sua pontuação no ranking de cada missão."
]

interface State {
    error: string | undefined;
    isLoading: boolean;
    selectedOption: number | undefined;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    selectedOption: undefined
};

const HelpScreen = () => {

    const navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { user, company, noUser, noCompany } = useUser()

    React.useEffect(() => {
        if (noUser || noCompany) {
            setState({ ...state, error: 'Ops, link invalido. Você precisa fazer login primeiro.' })
        }
    }, [noUser, noCompany])


    const closeModal = () => {
        setState({ ...state, selectedOption: undefined })
    }

    const getModalTitle = (index: number) => {
        if(index > 0 && index < titles.length) {
            return titles[index]
        } else {
            return ''
        }
    }

    const getBody = (index: number) => {
        if(index < bodies.length) {
            return bodies[index]
        } else {
            return ''
        }
    }

    const renderError = (error: string) => {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>

        );
    }

    const renderHeader = (company: Company) => {
        return (<Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#616975' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#0b0c10', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 16, marginLeft: 10 }}>Ajuda</div>
                </div>
            </Col>
        </Row>)
    }

    const renderHelpOptions = (mainColor: string | null | undefined) => {
        return (<Row style={{ marginTop: 15 }}>
            {titles.map((title, i) => {
                return (<Col key={i} md={{ size: 6, offset: 3 }}>
                    <div className="test" />
                    <Button className="help-button" style={{ background: mainColor || '#343a40' }} onClick={() => { setState({ ...state, selectedOption: i }) }}>{title}</Button>
                </Col>)
            })}
        </Row>)
    }

    const renderSelectedOptionModal = (selectedOption: number | undefined) => {
        
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Modal isOpen={selectedOption !== undefined} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={closeModal}>
                    <ModalHeader toggle={closeModal}>{getModalTitle(selectedOption!)}</ModalHeader>
                    <ModalBody>
                        {getBody(selectedOption!)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={closeModal}>OK</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    let { selectedOption, error, isLoading } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    return (<Container fluid className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
        {error && renderError(error)}
        {company && renderHeader(company)}
        {renderHelpOptions(company && company.mainColor)}
        {renderSelectedOptionModal(selectedOption)}
    </Container>)

}

export default HelpScreen