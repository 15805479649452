import React, { Component, Fragment } from "react";
import _ from 'lodash'
import QuizQuestion, { DefaultQuestion, QuizQuestionType, MemoryQuestion, SelectAnswerQuestion, getQuizQuestion, WordGuessQuestion } from "../../../../models/QuizQuestion";
import Cookies from 'universal-cookie';
import QuizSvg from '../../../../images/quiz.svg'
import { SubModule } from "../../../../models/SubModule";
import TokenHelper from "../../../../auth/TokenHelper";
import AdminContentService from "../../../../services/admin/adminContentService";
import config from '../../../../config'
import { Row, Col, Button, Card, Alert, Container, Modal, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faPlus, faTrash, faArrowLeft, faEdit, faPlusCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../../../loading";
import CreateQuizComponent from "./CreateQuizCard";
import EditQuizComponent from "./EditQuizCard";
import Company from "../../../../models/Company";
import { faClock } from "@fortawesome/free-regular-svg-icons";

enum Action {
    View = 1,
    Edit = 2,
    Create = 3
}

interface State {
    company: Company | undefined;
    action: Action;
    quizCards: Array<QuizQuestion>
    cardsEdit: Array<QuizQuestion>
    isLoading: boolean;
    error: any | undefined;
    shouldSaveCards: boolean;
    quizCardEdit: QuizQuestion | undefined;
    showExitPopUp: boolean;
    height: number;
}

const INITIAL_STATE: State = {
    company: undefined,
    quizCards: [],
    cardsEdit: [],
    isLoading: false,
    error: undefined,
    shouldSaveCards: false,
    quizCardEdit: undefined,
    action: Action.View,
    showExitPopUp: false,
    height: 0,
};

interface Props {
    subModule: SubModule,
    endpoint: string,
    goBack: () => void
}

export default class QuizComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = { ...INITIAL_STATE }

    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        let cookies = new Cookies()
        let companyData = cookies.get('company')
        if (companyData) {
            let company = new Company(companyData)
            this.setState({ company }, () => this.loadQuizCards(this.props.subModule.id))
        } else {
            this.setState({ error: 'Ops, link invalido. Por favor escolha uma empresa.' })
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ height: window.innerHeight });
    }

    async loadQuizCards(subModuleId: string) {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let quizCards = await adminContentService.getQuizQuestions(token, this.props.endpoint, subModuleId)
            let cardsEdit = quizCards.map(card => {
                if (card.type === QuizQuestionType.Default) {
                    return new DefaultQuestion(Object.assign({}, card))
                } else if (card.type === QuizQuestionType.SelectAnswer) {
                    return new SelectAnswerQuestion(Object.assign({}, card))
                } else if (card.type === QuizQuestionType.MemoryGame) {
                    return new MemoryQuestion(Object.assign({}, card))
                } else if (card.type === QuizQuestionType.WordGuess) {
                    return new WordGuessQuestion(Object.assign({}, card))
                } else {
                    return new QuizQuestion(Object.assign({}, card))
                }
            })
            this.setState({ quizCards, cardsEdit, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadQuizCards(subModuleId)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    removeCard = (card: QuizQuestion) => {
        let cardId = card.id
        let cardsEdit = this.state.cardsEdit.filter(card => card.id !== cardId).map(card => getQuizQuestion(Object.assign({}, card))!)
        cardsEdit.forEach((card, i) => {
            card.id = this.props.subModule.id + '_' + i
        })
        this.setState({ cardsEdit, shouldSaveCards: true })
    }

    editCard = (card: QuizQuestion) => {
        let cardsEdit: Array<QuizQuestion> = this.state.cardsEdit.map(currentCard => {
            if (currentCard.id === card.id) {
                return card
            } else {
                return getQuizQuestion(Object.assign({}, currentCard))!
            }
        })
        this.setState({ cardsEdit, action: Action.View, shouldSaveCards: true })
    }

    addQuizCard = (card: QuizQuestion) => {
        let editing = this.state.action === Action.Edit
        if (editing) {
            this.removeCard(card)
        }
        let cardsEdit = [...this.state.cardsEdit, card]
        this.setState({ cardsEdit, action: Action.View, shouldSaveCards: true })
    }

    save = async (subModule: SubModule, cards: Array<QuizQuestion>) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            await adminContentService.saveQuizCards(token, config.endpoint, subModule.moduleId, subModule.id, cards)
            this.setState({ shouldSaveCards: false, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.save(subModule, cards)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    renderExitMessage() {
        return (<Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => this.setState({ showExitPopUp: false })}>
                    <ModalBody>
                        <div style={{ fontFamily: 'Montserrat' }}>Tem certeza que deseja sair sem salvar? Todas as mudanças serão perdidas.</div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center align-items-center' >
                        <Button color="secondary" onClick={() => this.setState({ showExitPopUp: false })}>Não</Button>
                        <Button color="danger" onClick={() => this.props.goBack()}>Sim</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    renderHeader(subModule: SubModule, action: Action, shouldSaveCards: boolean) {
        if (action === Action.View) {
            return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '4em' }}>
                        <Button color='none' outline onClick={() => shouldSaveCards ? this.setState({ showExitPopUp: true }) : this.props.goBack()}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 10 }}><b>{subModule.title}</b></div>
                        <Button className="d-flex flex-column align-items-center" color='none' outline onClick={() => { this.setState({ action: Action.Create }) }}><FontAwesomeIcon style={{ verticalAlign: 'middle' }} color='#0f3252' icon={faPlus} /> <span style={{ verticalAlign: 'middle', color: '#0f3252' }}>Criar Quiz</span></Button>
                    </div>
                </Col>
            </Row>)
        } else {
            return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', minHeight: '4em' }}>
                        <Button color='none' outline onClick={() => this.setState({ action: Action.View })}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <div className='d-flex flex-column'>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 10 }}><b>{subModule.title}</b></div>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 10 }}>{action === Action.Create ? 'Criar' : 'Editar'} Card</div>
                        </div>
                    </div>
                </Col>
            </Row>)
        }
    }

    renderFooter(card: QuizQuestion) {
        return (<div className="mt-2">
            <Button outline color="primary" style={{ marginRight: 10 }} size="sm" onClick={() => this.setState({ quizCardEdit: card, action: Action.Edit })}><FontAwesomeIcon icon={faEdit} /> Editar</Button>
            <Button outline color="danger" size="sm" onClick={() => this.removeCard(card)}><FontAwesomeIcon icon={faTrash} /> Remover</Button>
        </div>)
    }

    renderDefaultQuestionCard(card: DefaultQuestion) {
        let normalQuestionStyle: React.CSSProperties = { borderRadius: 5, borderStyle: 'solid', borderWidth: 'thin', borderColor: 'LightGray', fontSize: '0.9em', color: '#808080', marginTop: 10, paddingTop: 8, paddingBottom: 8, paddingRight: 5, paddingLeft: 5 }
        let rightQuestionStyle: React.CSSProperties = { ...normalQuestionStyle, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', borderColor: 'green' }
        let renderImgCard = (img: string) => <img alt='imagem do card' src={img} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em', marginRight: 5 }} />
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', verticalAlign: 'middle' }}>Pergunta</div>
                <div className="d-flex flex-column justify-content-center">
                    <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}><b>{card.question}</b></div>
                    {card.questionImageUrl && <img style={{ objectFit: 'contain', maxHeight: 150, maxWidth: 3000, marginTop: 10 }} src={card.questionImageUrl} alt="Pergunta" />}
                    <div style={{ fontSize: 'small', color: '#353f45', marginLeft: 5, marginTop: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faClock} />{card.answerTime || 45} segundos</div>
                    {<div style={card.correctAnswer === 0 ? rightQuestionStyle : normalQuestionStyle}>{card.imgAnswer0 && renderImgCard(card.imgAnswer0)}{card.answer0}</div>}
                    {<div style={card.correctAnswer === 1 ? rightQuestionStyle : normalQuestionStyle} >{card.imgAnswer1 && renderImgCard(card.imgAnswer1)}{card.answer1}</div>}
                    {card.answer2 && <div style={card.correctAnswer === 2 ? rightQuestionStyle : normalQuestionStyle}>{card.imgAnswer2 && renderImgCard(card.imgAnswer2)}{card.answer2}</div>}
                    {card.answer3 && <div style={card.correctAnswer === 3 ? rightQuestionStyle : normalQuestionStyle} >{card.imgAnswer3 && renderImgCard(card.imgAnswer3)}{card.answer3}</div>}
                </div>
                {this.renderFooter(card)}
            </div>
        </Col>)
    }

    renderSelectAnswerQuestionCard(card: SelectAnswerQuestion) {
        let normalQuestionStyle: React.CSSProperties = { borderRadius: 5, borderStyle: 'solid', borderWidth: 'thin', borderColor: 'LightGray', fontSize: '0.9em', color: '#808080', marginTop: 10, paddingTop: 8, paddingBottom: 8, paddingRight: 5, paddingLeft: 5 }
        let rightQuestionStyle: React.CSSProperties = { ...normalQuestionStyle, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', borderColor: 'green' }
        let renderImgCard = (img: string) => <img alt='imagem do card' src={img} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em', marginRight: 5 }} />
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', verticalAlign: 'middle' }}>Selecionar Resposta</div>
                <div className="d-flex flex-column justify-content-center">
                    <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}><b>{card.question}</b></div>
                    {card.questionImageUrl && <img style={{ objectFit: 'contain', maxHeight: 150, maxWidth: 3000, marginTop: 10 }} src={card.questionImageUrl} alt="Pergunta" />}
                    <div style={{ fontSize: 'small', color: '#353f45', marginLeft: 5, marginTop: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faClock} />{card.answerTime || 45} segundos</div>
                    {<div style={_.includes(card.correctAnswers, 0) ? rightQuestionStyle : normalQuestionStyle}>{card.imgAnswer0 && renderImgCard(card.imgAnswer0)} {card.answer0}</div>}
                    {<div style={_.includes(card.correctAnswers, 1) ? rightQuestionStyle : normalQuestionStyle} >{card.imgAnswer1 && renderImgCard(card.imgAnswer1)} {card.answer1}</div>}
                    {card.answer2 && <div style={_.includes(card.correctAnswers, 2) ? rightQuestionStyle : normalQuestionStyle}>{card.imgAnswer2 && renderImgCard(card.imgAnswer2)} {card.answer2}</div>}
                    {card.answer3 && <div style={_.includes(card.correctAnswers, 3) ? rightQuestionStyle : normalQuestionStyle} >{card.imgAnswer3 && renderImgCard(card.imgAnswer3)} {card.answer3}</div>}
                </div>
                {this.renderFooter(card)}
            </div>
        </Col>)
    }

    renderMemoryCard(card: MemoryQuestion) {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', verticalAlign: 'middle' }}>Memória</div>
                {card.title && <div className="mt-2" style={{ color: '#353f45', textAlign: 'center' }}><b>{card.title}</b></div>}
                <div className='d-flex flex-column mb-2'>
                    {card.images.map(cardImage => <div className='d-flex mt-3 align-items-center p-2' style={{ borderRadius: 5, borderStyle: 'solid', borderWidth: 'thin', borderColor: 'LightGray' }}>
                        <img alt='imagem do jogo da memoria' style={{ objectFit: 'cover', height: 50, width: 50 }} src={cardImage.imgUrl} />
                        <div className='ml-2' style={{ fontFamily: 'Montserrat', fontSize: 'small' }}>{cardImage.text}</div>
                    </div>)}
                </div>
                {this.renderFooter(card)}
            </div>
        </Col>)
    }

    renderWordGuessCard(card: WordGuessQuestion) {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column justify-content-between mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5, minHeight: 250 }}>
                <div style={{ color: 'DarkGray', fontFamily: 'Montserrat', verticalAlign: 'middle' }}>Acertar Palavra</div>
                <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}>{card.title}</div>
                <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}><b>{card.word.toUpperCase()}</b></div>
                {this.renderFooter(card)}
            </div>
        </Col>)
    }

    renderCards(cards: Array<QuizQuestion>) {
        return cards.map((card) => {
            if (card instanceof DefaultQuestion) {
                return this.renderDefaultQuestionCard(card)
            } else if (card instanceof SelectAnswerQuestion) {
                return this.renderSelectAnswerQuestionCard(card)
            } else if (card instanceof MemoryQuestion) {
                return this.renderMemoryCard(card)
            } else if (card instanceof WordGuessQuestion) {
                return this.renderWordGuessCard(card)
            } else {
                return (<Card key={card.id}></Card>)
            }
        })
    }

    renderNoCardsMessage() {
        return (<Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
            <p>Você ainda não adicionou nenhum card para esse desafio.</p>
        </Col>)
    }

    renderError(error: any) {
        return (<Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                    {error}
                </Alert>
            </Col>
        </Row>
        );
    }

    renderSaveButton(subModule: SubModule, cards: Array<QuizQuestion>) {
        return (<Row style={{ background: '#FFFFFFDA' }}>
            <Col className="d-flex justify-content-center pb-2" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Button outline color='primary' style={{ minWidth: 200 }} onClick={() => { this.save(subModule, cards) }}><FontAwesomeIcon style={{ verticalAlign: 'middle' }} icon={faSave} size='2x' /> <span style={{ verticalAlign: 'middle' }}>Salvar</span></Button>
            </Col>
        </Row>)
    }

    renderViewCards(cards: Array<QuizQuestion>, shouldSaveCards: boolean, title: string) {
        return (<Fragment>
            <Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', minHeight: '4em' }}>
                        <Button color='none' outline onClick={() => shouldSaveCards ? this.setState({ showExitPopUp: true }) : this.props.goBack()}><FontAwesomeIcon color='#0b0c10' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={QuizSvg} />
                        {/* Nunca fiz tanta gambiarra na minha vida igual essa pra fazer o texto to nome da missão ficar em uma linha e terminar com ... */}
                        <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap', marginLeft: 10, color: '#353f45' }}>
                            <div><b>Fase {this.props.subModule.pos}</b></div>
                            <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', verticalAlign: 'middle' }}>{this.props.subModule.title}</div>
                        </div>
                        <Button className="d-flex justify-content-start align-items-center" color="primary" style={{ fontSize: '1em', borderStyle: 'none', paddingTop: 10, paddingBottom: 10 }} onClick={() => { this.setState({ action: Action.Create }) }}>
                            <FontAwesomeIcon style={{ verticalAlign: 'middle', marginRight: 5 }} icon={faPlusCircle} />
                            <div className="d-flex flex-column align-items-start ml-1" style={{ fontSize: '0.7em' }}>
                                <span style={{ verticalAlign: 'middle', marginTop: 1 }}>Nova Questão</span>
                            </div>
                        </Button>
                    </div>
                </Col>
            </Row>
            {shouldSaveCards && this.renderSaveButton(this.props.subModule, cards)}
            <Row className="pt-2" style={{ overflow: 'auto', zIndex: 1 }}>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div className="mt-2 mb-1" style={{ width: '100%', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.8em', paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 8 }}><FontAwesomeIcon style={{ marginRight: 2 }} icon={faInfoCircle} /><i>A avaliação é composta por um banco de cards interativos que aparecem em ordem aleatória. Quanto mais rápido o usuário responde ou menos tentativas precisa fazer para acertar, mais pontos ele ganha.</i></div>
                </Col>
                {cards.length === 0 ? this.renderNoCardsMessage() : this.renderCards(cards)}
            </Row>
        </Fragment>)
    }

    render() {

        let { company, error, cardsEdit, action, shouldSaveCards, isLoading, showExitPopUp, quizCardEdit, height } = this.state

        if (isLoading) { return <LoadingScreen /> }
        let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
        let backgroundPosition = company?.backgroundPosition || 'left top'

        return (<Container className="d-flex flex-column relative" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', overflow: 'none', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
            {error && this.renderError(error)}
            {showExitPopUp && this.renderExitMessage()}
            {action === Action.View && this.renderViewCards(cardsEdit, shouldSaveCards, this.props.subModule.title)}
            {action === Action.Create && (<CreateQuizComponent exit={() => this.setState({ action: Action.View })} addQuizCard={this.addQuizCard} subModule={this.props.subModule} lastPosition={cardsEdit.length} />)}
            {action === Action.Edit && <EditQuizComponent exit={() => this.setState({ action: Action.View })} card={quizCardEdit!} editCard={this.editCard} subModule={this.props.subModule} />}
        </Container>)
    }

}