import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Container, Row, Col, Button } from 'reactstrap'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'

import * as ROUTES from '../../constants/routes'
import LoadingScreen from '../loading'
import config from '../../config'
import TokenHelper from '../../auth/TokenHelper';
import ScoreService from '../../services/scoreService';
import { SubModule, SubModuleTypes } from '../../models/SubModule';
import { DeckSubModuleFinishedReport, QuizQuestionFinishedReport } from '../../models/SubModuleFinishedReport';
import { DeckCardTypes } from '../../models/DeckCards';
import { QuestionCardReport, WordSearchCardReport, MemoryCardReport, OrderedListCardReport, SelectAnswerCardReport, ImageErrorsCardReport } from '../../models/DeckCardsReport';
import { QuestionAnswer } from '../../models/Question';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl, faRedo, faArrowRight, faThumbsUp, faThumbsDown, faArrowLeft, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faClock, faHandPointer } from '@fortawesome/free-regular-svg-icons';
import { POINTS_PER_ANSWER_DECK, MEMORY_GAME_MAX_ATTEMPTS, POINTS_PER_MEMORY_GAME, POINTS_PER_ORDERED_LIST_DECK, WORD_GUESS_MAX_ATTEMPTS, POINTS_PER_WORD_GUESS, IMAGE_ERRORS_MAX_ATTEMPTS, POINTS_PER_IMAGE_ERROR } from '../../constants/ScoreConstants'
import { UserMaxScoreInSubModule } from '../../models/Scores';
import { DefaultQuestionReport, MemoryQuestionReport, SelectAnswerQuestionReport, WordGuessQuestionReport } from '../../models/QuizQuestionsReport';
import ContentService from '../../services/contentService';
import StarSvg from '../../images/star.svg';
import AwardSvg from '../../images/award.svg'
import StepListSvg from '../../images/steplist.svg'
import QuestionSvg from '../../images/question.svg'
import WordSearchSvg from '../../images/wordsearch.svg'
import MemorySvg from '../../images/memory.svg'
import ImageErrorsSvg from '../../images/imageerrors.svg'
import Module from '../../models/Module';
import { useUser } from '../../hooks/useUser';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';


interface State {
    error: string | undefined;
    isLoading: boolean;
    isRating: boolean;
    userMaxScore?: UserMaxScoreInSubModule
    deckSubModuleFinishedReport?: DeckSubModuleFinishedReport;
    quizQuestionFinishedReport?: QuizQuestionFinishedReport
    subModule?: SubModule;
    module?: Module;
    nextSubModule?: SubModule;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    isRating: false,
    deckSubModuleFinishedReport: undefined,
    subModule: undefined,
    module: undefined,
    nextSubModule: undefined,
};


const GameResultScreen = () => {

    const navigate = useNavigate()
    const [state, setState] = useState(INITIAL_STATE)
    const { user, company, noUser, noCompany } = useUser()
    const { height } = useWindowDimensions()
    const companyId = useParams().companyId
    const [searchParams, setSearchParams] = useSearchParams()

    let { subModuleId, subModuleType } = queryString.parse(searchParams.toString())

    useEffect(() => {
        if (subModuleId && subModuleType) loadResultScreenData(subModuleId as string, subModuleType as string)
    }, [subModuleId, subModuleType])

    useEffect(() => {
        if (noUser || noCompany) {
            if (companyId === 'app') setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.', isLoading: false })
            else navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
        }
    }, [user, company, noUser, noCompany])


    const onExit = (companyId: string, moduleId: string) => {
        navigate(`/${companyId}${ROUTES.SUBMODULE_SELECT}?moduleId=${moduleId}`, { replace: true })
    }

    const goToRanking = (companyId: string, moduleId: string) => {
        navigate(`/${companyId}${ROUTES.SELECT_MODULE_RANKING}/${moduleId}`, { replace: true })
    }

    const loadResultScreenData = async (subModuleId: string, subModuleType: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let scoreService = new ScoreService()
            let scoreReportRes = await scoreService.getUserLastScore(token, config.endpoint, subModuleId, subModuleType)
            let scoreReport = scoreReportRes.lastReport
            let subModule = scoreReportRes.subModule
            let module = scoreReportRes.module
            let nextSubModule = scoreReportRes.nextSubModule
            let userMaxScore = scoreReportRes.userMaxScore
            if (scoreReport) {
                if (subModuleType === SubModuleTypes.DECK) {
                    let deckSubModuleFinishedReport = scoreReport as DeckSubModuleFinishedReport
                    setState({ ...state, isLoading: false, deckSubModuleFinishedReport, module, subModule, userMaxScore, nextSubModule })
                } else if (subModuleType === SubModuleTypes.QUIZ) {
                    let quizQuestionFinishedReport = scoreReport as QuizQuestionFinishedReport
                    setState({ ...state, isLoading: false, quizQuestionFinishedReport, module, subModule, userMaxScore, nextSubModule })
                }
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadResultScreenData(subModuleId, subModuleType)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const rateSubModule = async (subModule: SubModule, rating: number) => {
        setState({ ...state, isRating: true })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return
            let contentService = new ContentService()
            await contentService.saveSubModuleRating(token, config.endpoint, subModule.companyId, subModule.moduleId, subModule.id, rating)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                rateSubModule(subModule, rating)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const renderError = (error: string) => {
        return (
            <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                {error}
            </Alert>
        );
    }

    const renderQuizQuestion = (title: string, answer: QuestionAnswer, time: number | undefined, marginTopAndBottom: number, marginLeftAndRight: number, answerTime?: number) => {
        if (answer === QuestionAnswer.CorrectAnswer) {
            // bug que salva timeToAnswer como undefined se ele for igual a 0
            let correctTimeToAnswer = time || 0
            return (<Col key={title} md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight, marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5, boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3 }}>
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#353f45', textAlign: 'start' }}><b>Pergunta</b></div>
                        <div style={{ color: '#353f45', textAlign: 'start' }}>{title}</div>
                        <div style={{ color: '#353f45', textAlign: 'start', marginTop: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faClock} />{`${correctTimeToAnswer} segundos`}</div>
                        <div style={{ color: 'green', textAlign: 'start', marginTop: 5 }}><b>{`+${(answerTime || 45) - correctTimeToAnswer} pontos`}</b></div>
                    </div>
                </div>
            </Col>)
        } else {
            let answerStatus = answer === QuestionAnswer.WrongAnswer ? 'Errou' : (answer === QuestionAnswer.Timeout ? 'Tempo Esgotado' : 'Não Respondeu')
            return (<Col md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-center align-items-center" style={{
                    background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight,
                    marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5,
                    boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3
                }}>
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#353f45', textAlign: 'start' }}><b>Pergunta</b></div>
                        <div style={{ color: '#353f45', textAlign: 'start' }}>{title}</div>
                        <div style={{ color: 'red', textAlign: 'start', marginTop: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faTimesCircle} /><b>{answerStatus}</b></div>
                    </div>
                </div>
            </Col>)
        }
    }

    const renderQuizMemoryQuestion = (memoryQuestionReport: MemoryQuestionReport, marginTopAndBottom: number, marginLeftAndRight: number) => {
        if (memoryQuestionReport.allImagesFound) {
            let attempts = memoryQuestionReport.attempts
            return (<Col key={memoryQuestionReport.quizQuestion.id} md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-center align-items-center" style={{
                    background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight,
                    marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5,
                    boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3
                }}>
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#353f45', textAlign: 'start' }}><b>Jogo da Memória</b></div>
                        <div style={{ color: '#353f45', textAlign: 'start', marginTop: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faHandPointer} />{`Você fez ${attempts} de ${MEMORY_GAME_MAX_ATTEMPTS} tentativas.`}</div>
                        <div style={{ color: 'green', textAlign: 'start', marginTop: 5 }}><b>{`+${POINTS_PER_MEMORY_GAME - attempts} pontos`}</b></div>
                    </div>
                </div>
            </Col>)
        } else {
            return (<Col key={memoryQuestionReport.quizQuestion.id} md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-center align-items-center" style={{
                    background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight,
                    marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5,
                    boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3
                }}>
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#353f45', textAlign: 'start' }}>Jogo da Memória</div>
                        <div style={{ color: 'red', textAlign: 'start', marginTop: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faTimesCircle} /><b>Não achou todas as imagens</b></div>
                    </div>
                </div>
            </Col>)
        }
    }

    const renderWordGuessQuestion = (wordGuessQuestionReport: WordGuessQuestionReport, marginTopAndBottom: number, marginLeftAndRight: number) => {
        if (wordGuessQuestionReport.wordFound) {
            let attempts = wordGuessQuestionReport.attempts
            return (<Col key={wordGuessQuestionReport.quizQuestion.id} md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-center align-items-center" style={{
                    background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight,
                    marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5,
                    boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3
                }}>
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#353f45', textAlign: 'start' }}><b>Jogo da Forca</b></div>
                        <div style={{ color: '#353f45', textAlign: 'start' }}>{wordGuessQuestionReport.quizQuestion.title}</div>
                        <div style={{ color: '#353f45', textAlign: 'start', marginTop: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faHandPointer} />{`Você fez ${attempts} de ${WORD_GUESS_MAX_ATTEMPTS} tentativas`}</div>
                        <div style={{ color: 'green', textAlign: 'start', marginTop: 5 }}><b>{`+${POINTS_PER_WORD_GUESS - attempts} pontos`}</b></div>
                    </div>
                </div>
            </Col>)
        } else {
            return (<Col key={wordGuessQuestionReport.quizQuestion.id} md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-center align-items-center" style={{
                    background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight,
                    marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5,
                    boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3
                }}>
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#353f45', textAlign: 'start' }}><b>Jogo da Forca</b></div>
                        <div style={{ color: '#353f45', textAlign: 'start' }}>{wordGuessQuestionReport.quizQuestion.title}</div>
                        <div style={{ color: 'red', textAlign: 'start' }}><b>Não achou a palavra</b></div>
                    </div>
                </div>
            </Col>)
        }
    }

    const renderQuizQuestionReport = (quizQuestionFinishedReport: QuizQuestionFinishedReport) => {

        let marginTopAndBottom = 10
        let marginLeftAndRight = 5

        return (<Fragment>
            {quizQuestionFinishedReport.quizQuestionsReport.map(quizQuestionReport => {
                if (quizQuestionReport instanceof DefaultQuestionReport || quizQuestionReport instanceof SelectAnswerQuestionReport) {
                    return renderQuizQuestion(quizQuestionReport.quizQuestion.question, quizQuestionReport.questionAnswer, quizQuestionReport.timeToAnswer, marginTopAndBottom, marginLeftAndRight, quizQuestionReport.quizQuestion.answerTime)
                } else if (quizQuestionReport instanceof MemoryQuestionReport) {
                    let memoryQuestionReport = quizQuestionReport as MemoryQuestionReport
                    return renderQuizMemoryQuestion(memoryQuestionReport, marginTopAndBottom, marginLeftAndRight)
                } else if (quizQuestionReport instanceof WordGuessQuestionReport) {
                    let wordGuessQuestionReport = quizQuestionReport as WordGuessQuestionReport
                    return renderWordGuessQuestion(wordGuessQuestionReport, marginTopAndBottom, marginLeftAndRight)
                } else {
                    return (<div key={quizQuestionReport.quizQuestion.id}></div>)
                }
            })}
            <Col md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-center align-items-center" style={{
                    background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight,
                    marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, paddingTop: 10, paddingBottom: 10, paddingRight: 5, paddingLeft: 5,
                    boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3, minHeight: 80
                }}>
                    <img style={{ width: 25, height: 25, marginLeft: 10 }} alt='ícone de troféu' src={StarSvg} />
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#353f45', textAlign: 'start' }}>Pontuação</div>
                        <div style={{ color: '#353f45', textAlign: 'start' }}><b>{quizQuestionFinishedReport.score}</b></div>
                    </div>
                </div>
            </Col>
        </Fragment>)
    }

    const renderOrderedListScore = (orderedListsComplete: number, totalOrderedLists: number, marginTopAndBottom: number, marginLeftAndRight: number) => {
        return (<Col md={{ size: 8, offset: 2 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight, marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5, boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3 }}>
                <img style={{ width: '2em', height: '2em' }} alt='ícone da lista ordenada' src={StepListSvg} />
                <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                    <div style={{ color: '#353f45', textAlign: 'start' }}><b>Listas de Etapas</b></div>
                    <div style={{ color: '#0b0c10', textAlign: 'start' }}>{`Você acertou ${orderedListsComplete} de ${totalOrderedLists} listas.`}</div>
                    <div style={{ color: '#353f45', textAlign: 'start' }}><b>{`+${orderedListsComplete * POINTS_PER_ORDERED_LIST_DECK} pontos`}</b></div>
                </div>
            </div>
        </Col>)
    }

    const renderMemoryGameScore = (attempts: number, marginTopAndBottom: number, marginLeftAndRight: number) => {
        return (<Col md={{ size: 8, offset: 2 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight, marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5, boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3 }}>
                <img style={{ height: '2em', width: '2em' }} alt='ícone de jogo da memória' src={MemorySvg} />
                <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                    <div style={{ color: '#353f45', textAlign: 'start' }}><b>Jogo da Memória</b></div>
                    <div style={{ color: '#0b0c10', textAlign: 'start' }}>{`Você fez ${attempts} de ${MEMORY_GAME_MAX_ATTEMPTS} tentativas.`}</div>
                    <div style={{ color: '#353f45', textAlign: 'start' }}><b>{`+${POINTS_PER_MEMORY_GAME - attempts} pontos`}</b></div>
                </div>
            </div>
        </Col>)
    }

    const renderImageErrorsScore = (attempts: number, errors: number, marginTopAndBottom: number, marginLeftAndRight: number) => {
        return (<Col md={{ size: 8, offset: 2 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight, marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5, boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3 }}>
                <img style={{ height: '2em', width: '2em' }} alt='ícone de jogo da memória' src={ImageErrorsSvg} />
                <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                    <div style={{ color: '#353f45', textAlign: 'start' }}><b>Jogo dos Erros</b></div>
                    <div style={{ color: '#0b0c10', textAlign: 'start' }}>{`Você fez ${attempts} de ${IMAGE_ERRORS_MAX_ATTEMPTS - errors} tentativas.`}</div>
                    <div style={{ color: '#353f45', textAlign: 'start' }}><b>{`+${POINTS_PER_IMAGE_ERROR - attempts} pontos`}</b></div>
                </div>
            </div>
        </Col>)
    }

    const renderWordSearch = (wordsFound: number, totalWordSearch: number, marginTopAndBottom: number, marginLeftAndRight: number) => {
        return (<Col md={{ size: 8, offset: 2 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight, marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5, boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3 }}>
                <img style={{ width: '2em', height: '2em' }} alt='ícone de caça-palavras' src={WordSearchSvg} />
                <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                    <div style={{ color: '#353f45', textAlign: 'start' }}><b>Caça-Palavras</b></div>
                    <div style={{ color: '#0b0c10', textAlign: 'start' }}>{`Você encontrou ${wordsFound} de ${totalWordSearch} palavras.`}</div>
                    <div style={{ color: '#353f45', textAlign: 'start' }}><b>{`+${wordsFound * POINTS_PER_ANSWER_DECK} pontos`}</b></div>
                </div>
            </div>
        </Col>)
    }

    const renderDeckReport = (deckReport: DeckSubModuleFinishedReport) => {
        let questionCardReports = deckReport.deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type === DeckCardTypes.Question
        }).map(data => data as QuestionCardReport)
        let selectAnswerCardReports = deckReport.deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type === DeckCardTypes.SelectAnswer
        }).map(data => data as SelectAnswerCardReport)
        let wordSearchCardReports = deckReport.deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type === DeckCardTypes.WordSearch
        }).map(data => data as WordSearchCardReport)
        let memoryCardReports = deckReport.deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type === DeckCardTypes.Memory
        }).map(cardReport => cardReport as MemoryCardReport)
        let orderedListReports = deckReport.deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type === DeckCardTypes.OrderedList
        }).map(cardReport => cardReport as OrderedListCardReport)
        let imageErrorsReports = deckReport.deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type === DeckCardTypes.ImageErrors
        }).map(cardReport => cardReport as ImageErrorsCardReport)

        // os cards de selecionar resposta certa também contam como cards de pergunta
        let totalQuestions = questionCardReports.length + selectAnswerCardReports.length
        let correctQuestions = questionCardReports.filter(qCardReport => qCardReport.questionAnswer === QuestionAnswer.CorrectAnswer).length
        let correctAnswerSelection = selectAnswerCardReports.filter(saCardReport => saCardReport.questionAnswer === QuestionAnswer.CorrectAnswer).length
        let correctAnsweredQuestions = correctQuestions + correctAnswerSelection

        let totalWordSearch = wordSearchCardReports.length
        let wordsFound = wordSearchCardReports.filter(wCardReport => wCardReport.wordFound).length

        let totalOrderedLists = orderedListReports.length
        let orderedListsCompleted = orderedListReports.filter(report => report.listOrderedCorrect).length

        let marginTopAndBottom = 10
        let marginLeftAndRight = 5

        return (<Fragment>
            {wordSearchCardReports.length > 0 && renderWordSearch(wordsFound, totalWordSearch, marginTopAndBottom, marginLeftAndRight)}
            <Col md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ background: '#FFFFFFDA', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight, marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, padding: 5, boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)', borderRadius: 3 }}>
                    <img style={{ width: '2em', height: '2em' }} alt='ícone com lista de perguntas' src={QuestionSvg} />
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#353f45', textAlign: 'start' }}><b>Perguntas</b></div>
                        <div style={{ color: '#0b0c10', textAlign: 'start' }}>{`Você acertou ${correctAnsweredQuestions} de ${totalQuestions} perguntas.`}</div>
                        <div style={{ color: '#353f45', textAlign: 'start' }}><b>{`+${correctAnsweredQuestions * POINTS_PER_ANSWER_DECK} pontos`}</b></div>
                    </div>
                </div>
            </Col>
            {orderedListReports.length > 0 && renderOrderedListScore(orderedListsCompleted, totalOrderedLists, marginTopAndBottom, marginLeftAndRight)}
            {memoryCardReports.length > 0 && memoryCardReports.filter(memoryCardReport => memoryCardReport.allImagesFound).map(memoryCardReport => renderMemoryGameScore(memoryCardReport.attempts !== undefined ? memoryCardReport.attempts : MEMORY_GAME_MAX_ATTEMPTS, marginTopAndBottom, marginLeftAndRight))}
            {imageErrorsReports.length > 0 && imageErrorsReports.filter(imageErrorCardReport => imageErrorCardReport.allErrorsFound).map(imageErrorsCardReport => renderImageErrorsScore(imageErrorsCardReport.attempts !== undefined ? imageErrorsCardReport.attempts : IMAGE_ERRORS_MAX_ATTEMPTS, imageErrorsCardReport.card.errors.length, marginTopAndBottom, marginLeftAndRight))}
            <Col md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginLeft: marginLeftAndRight, marginRight: marginLeftAndRight, marginTop: marginTopAndBottom, marginBottom: marginTopAndBottom, paddingTop: 10, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', borderRadius: 3 }}>
                    <img style={{ width: 25, height: 25, marginLeft: 10 }} alt='ícone de troféu' src={StarSvg} />
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#353f45', textAlign: 'start' }}>Pontuação</div>
                        <div style={{ color: '#353f45', textAlign: 'start' }}><b>{deckReport.score}</b></div>
                    </div>
                </div>
            </Col>
        </Fragment>)
    }

    const renderUserMaxScore = (userMaxScore: UserMaxScoreInSubModule) => {
        return (<Col md={{ size: 8, offset: 2 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ background: '#FFFFFFDA', marginTop: 10, padding: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', minHeight: 80, borderRadius: 3 }}>
                <img style={{ width: 30, height: 30, marginLeft: 10 }} alt='ícone de prêmio' src={AwardSvg} />
                <div className="d-flex flex-column justify-content-center" style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 10, paddingBottom: 10, flex: 1 }}>
                    <div style={{ color: '#353f45', textAlign: 'start' }}>Sua maior pontuação nesta fase foi</div>
                    <div style={{ color: '#be641f', textAlign: 'start' }}><b>{userMaxScore.score}</b></div>
                </div>
            </div>
        </Col>)
    }

    const renderHeader = (pic: string, subModule: SubModule) => {
        return (<Row style={{ background: '#FFFFFFDA', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { onExit(subModule.companyId, subModule.moduleId) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={pic} />
                    <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap' }}>
                        <div style={{ color: '#353f45', verticalAlign: 'middle', fontSize: 16, marginLeft: 10 }}><b>Fase {subModule.pos}</b></div>
                        <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#353f45', verticalAlign: 'middle', marginLeft: 10 }}>{subModule.title}</div>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    const renderNext = (subModule: SubModule, companyColor: string | undefined) => {
        return (<Col md={{ size: 8, offset: 2 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 15, width: '100%', }}>
                <Button style={{ padding: 10, width: '100%', background: companyColor || '#616975', borderStyle: 'none' }} onClick={() => {
                    navigate(`/${subModule.companyId}${ROUTES.GAME}?subModuleId=${subModule.id}&subModuleType=${subModule.type}`, { replace: true })
                }}>Próximo<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowRight} /></Button>
            </div>
        </Col>)
    }

    const renderRedo = (subModule: SubModule) => {
        return (<Col md={{ size: 8, offset: 2 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 15, width: '100%' }}>
                <Button color='none' style={{ color: '#353f45', padding: 5, width: '80%', }} onClick={() => {
                    navigate(`/${subModule.companyId}${ROUTES.GAME}?subModuleId=${subModule.id}&subModuleType=${subModule.type}`, { replace: true })
                }}><FontAwesomeIcon icon={faRedo} /> <b>Jogar novamente</b></Button>
            </div>
        </Col>)
    }

    const renderRanking = (companyId: string, moduleId: string) => {
        return (<Col md={{ size: 8, offset: 2 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 10, marginBottom: 5, width: '100%' }}>
                <Button color='none' style={{ color: '#353f45', padding: 5, width: '80%' }} onClick={() => { goToRanking(companyId, moduleId) }}><FontAwesomeIcon icon={faListOl} /> Ver Ranking</Button>
            </div>
        </Col>)
    }

    const renderSubModuleRating = (subModule: SubModule) => {
        return (<Col md={{ size: 8, offset: 2 }}>
            <div className="d-flex flex-column justify-content-center" style={{ marginTop: 15, marginBottom: 10 }}>
                <div style={{ color: '#353f45', textAlign: 'center' }}><strong>O que você achou desta Fase?</strong></div>
                <div className="d-flex justify-content-around" style={{ marginTop: 10, marginBottom: 5, flex: 1 }}>
                    <div onClick={() => rateSubModule(subModule, 1)}><FontAwesomeIcon icon={faThumbsDown} size='2x' color='red' /></div>
                    <div onClick={() => rateSubModule(subModule, 5)}><FontAwesomeIcon icon={faThumbsUp} size='2x' color='green' /></div>
                </div>
            </div>
        </Col>)
    }

    let { error, isLoading, deckSubModuleFinishedReport, quizQuestionFinishedReport, module, subModule, userMaxScore, nextSubModule, isRating } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    let shouldRenderNext = subModule ? ((subModule.maxScore > 0 || subModule.maxScore === 0) ? (nextSubModule && userMaxScore && userMaxScore.score >= (subModule.maxScore / 2)) : nextSubModule) : false
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {module && subModule && renderHeader(module.pic, subModule)}
        <Row style={{ overflow: 'auto', paddingBottom: 10, paddingTop: 5 }}>
            {error && renderError(error)}
            {deckSubModuleFinishedReport && renderDeckReport(deckSubModuleFinishedReport)}
            {quizQuestionFinishedReport && renderQuizQuestionReport(quizQuestionFinishedReport)}
            {userMaxScore && renderUserMaxScore(userMaxScore)}
            {subModule && renderRedo(subModule)}
            {shouldRenderNext && company && renderNext(nextSubModule!, company.mainColor)}
            {subModule && !nextSubModule && renderRanking(subModule.companyId, subModule.moduleId)}
            {!isRating && subModule && renderSubModuleRating(subModule)}
        </Row>
    </Container>)
}


export default GameResultScreen