import React, { useState, useEffect } from 'react';
import { Alert, Button, Col, Container, Input, Row, Spinner } from 'reactstrap';
import { faArrowLeft, faFileUpload, faTrash, faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchInputView from '../../../../components/SearchInputView';
import ModuleUserAccess from '../../../../models/ModuleUserAccess';
import TokenHelper from '../../../../auth/TokenHelper';
import AdminModuleAccessComponentService from '../../../../services/admin/adminModuleAccessComponentService';
import config from '../../../../config';
import { lowerCaseAndRemovePunctuationFromString } from '../../../../utils/punctuation';
import ReactList from 'react-list';
import ModuleAccessUploadComponent from './ModuleAccessUploadComponent';
import AddModuleAccessComponent from './AddModuleAccessComponent';

enum Action {
    ViewModuleAccess = 1,
    AddModuleAccess,
    UploadFile,
}

interface Props {
    primaryProperty: string,
    companyId: string,
    moduleId: string,
    title: string,
    pic: string,
    height: number,
    backgroundImage: string | undefined,
    backgroundPosition: string | undefined,
    exit: () => void,
}

const ModuleAccessMainComponent = (props: Props) => {

    const { companyId, moduleId, primaryProperty, pic, title, backgroundImage, backgroundPosition, height, exit } = props

    const [action, setAction] = useState(Action.ViewModuleAccess);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [searchInput, setSearchInput] = useState<string>('');
    const [moduleUserAccess, setModuleUserAccess] = useState<ModuleUserAccess[]>([]);

    let deleteModuleAccess = async (primaryPropertyValue: string) => {
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            setError(undefined)
            let adminModuleAccessService = new AdminModuleAccessComponentService()
            await adminModuleAccessService.deleteModuleUserAccess(token, config.endpoint, moduleId, primaryPropertyValue)
            setModuleUserAccess(moduleUserAccess.filter(access => access.primaryPropertyValue !== primaryPropertyValue))
        } catch(error) {
            setError(error.toString())
        }
    }

    useEffect(() => {
        let fetchData = async () => {
            let tokenHelper = new TokenHelper()
            try {
                var token = tokenHelper.getToken()
                if (!token) {
                    token = await tokenHelper.getNewToken()
                }
                setIsLoading(true)
                setError(undefined)
                // create a new instance of the service AdminModuleAccessService
                let adminModuleAccessService = new AdminModuleAccessComponentService()
                let moduleUserAccess = await adminModuleAccessService.getModuleUserAccess(token, config.endpoint, moduleId)
                setIsLoading(false)
                setModuleUserAccess(moduleUserAccess)
            } catch (error) {
                let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
                if (tokenRefresh) {
                    fetchData()
                } else {
                    setIsLoading(false)
                    setError(error.toString())
                }
            }
        }
        fetchData()
    }, [])

    const headerTitle = action === Action.ViewModuleAccess ? 'Usuários com Acesso Liberado' : action === Action.AddModuleAccess ? 'Adicionar Acesso de Usuário' : 'Upload de Arquivo';

    const renderForAction = (action: Action) => {
        switch (action) {
            case Action.ViewModuleAccess:

                let filteredModuleUserAccess = moduleUserAccess.filter(access => {
                    let primaryPropertyWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(access.primaryPropertyValue)
                    let searchInputWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(searchInput)
                    if (primaryPropertyWithoutPunctuation.includes(searchInputWithoutPunctuation)) return true
                    return false
                })

                const renderItem = (index: number, key: number | string) => {
                    let moduleUserAccess = filteredModuleUserAccess[index]
                    return <div key={key} className="d-flex" style={{ marginTop: 15, marginBottom: 15, marginLeft: 5, marginRight: 5, paddingBottom: 5, borderRadius: 5, boxShadow: '2px 4px 4px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                        <div className="d-flex align-items-center" style={{ flex: 1, padding: 5 }}>
                            <div style={{ fontSize: 'small', color: 'black', verticalAlign: 'middle' }}>{moduleUserAccess.primaryPropertyValue}</div>
                        </div>
                        <div className="p-2 d-flex flex-column justify-content-center">
                            <Button color="danger" onClick={() => deleteModuleAccess(moduleUserAccess.primaryPropertyValue)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </div>
                    </div>

                }

                return <Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col md={{ size: 8, offset: 2 }}>
                            <Button style={{ margin: 5 }} color="success" onClick={() => setAction(Action.AddModuleAccess)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faUserPlus} />Adicionar Acesso</Button>
                            <Button style={{ margin: 5 }} color="primary" onClick={() => setAction(Action.UploadFile)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faFileUpload} />Subir Planilha</Button>
                        </Col>
                    </Row>
                    <SearchInputView placeholder='Procurar usuário' updateSearchInput={(searchInput: string) => setSearchInput(searchInput)} />
                    <Row style={{ overflow: 'auto' }}>
                        <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                            <div className="d-flex flex-column" style={{ flex: 1 }}>
                                <div style={{ fontSize: 'small', color: 'black', verticalAlign: 'middle', margin: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faUsers} />{filteredModuleUserAccess.length}</div>
                            </div>
                        </Col>
                        <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                            <ReactList itemRenderer={renderItem} length={filteredModuleUserAccess.length} type='simple' />
                        </Col>
                    </Row>
                </Row>
            case Action.AddModuleAccess:
                return <AddModuleAccessComponent primaryProperty={primaryProperty} moduleId={moduleId} moduleAccessAdded={(access) => { setModuleUserAccess([access, ...moduleUserAccess]); setAction(Action.ViewModuleAccess) }} />
            case Action.UploadFile:
                return <ModuleAccessUploadComponent companyId={companyId} moduleId={moduleId} primaryProperty={primaryProperty} />
            default:
                return <div/>
        }
    }

    return (<Container className="d-flex flex-column" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', overflow: 'none', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && <Row><Col md={{ size: 8, offset: 2 }}><Alert color="danger">{alert}</Alert></Col></Row>}
        <Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { if(action !== Action.ViewModuleAccess) { setAction(Action.ViewModuleAccess) } else exit() }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <div className="d-flex flex-column align-items-center">
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large' }}><b>{headerTitle}</b></div>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center' }}>Missão {title}</div>
                    </div>
                    <img alt='foto da empresa' style={{ height: 60, marginBottom: 10, marginTop: 10 }} src={pic} />
                </div>
            </Col>
        </Row>
        {isLoading && <Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div className="d-flex flex-column align-items-center" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                    <div style={{ color: '#626571', fontSize: 'large', marginBottom: 20 }}><b>Carregando...</b></div>
                    <Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />
                </div>
            </Col>
        </Row>}
        {!isLoading && renderForAction(action)}
    </Container>)
}

export default ModuleAccessMainComponent