export default class CardGif {
    id: string
    gifUrl: string
    tag: string
    companyId: string
    moduleId?: string
    subModuleId?: string

    constructor(data: any) {
        this.id = data.id
        this.gifUrl = data.gifUrl
        this.tag = data.tag
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
    }
}