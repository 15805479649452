import axios from 'axios'
import Module from '../models/Module'
import getSubModule, { SubModule, SubModuleVideo } from '../models/SubModule'
import DeckCard, { getDeckCard } from '../models/DeckCards'
import QuizQuestion, { getQuizQuestion } from '../models/QuizQuestion'
import { UserMaxScoreInSubModule } from '../models/Scores'
import LibraryContent, { getLibraryContent } from '../models/Library'
import Company from '../models/Company'
import { ContentConfig, RankingConfig, SignUpConfig } from '../models/CompanyConfig'
import UserModuleFeedback from '../models/UserModuleFeedback'

export default class ContentService {

    getCompanySignUpAndContentConfig = async (token: string, mainUrl: string, companyId: string): Promise<SignUpContentConfigRes> => {
        let url = `${mainUrl}/mobile/content/companySignUpContentRankingConfig/${companyId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new SignUpContentConfigRes(result.data.data)
    }

    async getCompanyModules(token: string, mainUrl: string, companyId: string): Promise<CompanyModulesRes> {
        let url = `${mainUrl}/mobile/content/modules/user/${companyId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let res = new CompanyModulesRes(result.data.data)
        return res
    }

    async getCompanyModule(token: string, mainUrl: string, companyId: string, moduleId: string): Promise<Module> {
        let url = `${mainUrl}/mobile/content/module/${companyId}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let module = new Module(result.data.data.module)
        return module
    }

    async getCompanyModuleAndSubmodules(token: string, mainUrl: string, companyId: string, moduleId: string) {
        let url = `${mainUrl}/mobile/content/moduleAndSubModules/user/${companyId}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let moduleAndSubmoduleRes = new ModuleAndSubmoduleRes(result.data.data)
        return moduleAndSubmoduleRes
    }

    async getSubModuleAndNext(token: string, mainUrl: string, subModuleId: string): Promise<{ subModule: SubModule, nextSubModule: SubModule | undefined }> {
        let url = `${mainUrl}/mobile/content/subModuleAndNext/${subModuleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let subModule = getSubModule(result.data.data.subModule)
        let nextSubModule = result.data.data.nextSubModule ? getSubModule(result.data.data.nextSubModule) : undefined
        return { subModule, nextSubModule }
    }

    async getDeckCards(token: string, mainUrl: string, subModuleId: string): Promise<Array<DeckCard>> {
        let url = `${mainUrl}/mobile/content/deckCards/${subModuleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let deckCards = result.data.data.deckCards.map((data: any) => getDeckCard(data))
        return deckCards
    }

    async getQuizQuestions(token: string, mainUrl: string, subModuleId: string): Promise<Array<QuizQuestion>> {
        let url = `${mainUrl}/mobile/content/quizQuestions/${subModuleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` }})
        let quizQuestions = result.data.data.quizQuestions.map((data: any) => getQuizQuestion(data))
        return quizQuestions
    }

    async getSubModuleVideo(token: string, mainUrl: string, subModuleId: string): Promise<SubModuleVideo> {
        let url = `${mainUrl}/mobile/content/subModuleVideo/${subModuleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` }})
        let subModuleVideo = new SubModuleVideo(result.data.data.subModuleVideo)
        return subModuleVideo
    }

    async getFileSecureUrl(token: string, mainUrl: string, filename: string): Promise<string> {
        let url = `${mainUrl}/mobile/content/file`
        let result = await axios.post(url, { filename }, { headers: { 'Authorization': `Token ${token}` } })
        return result.data.data.secureUrl
    }

    async saveSubModuleRating(token: string, mainUrl: string, companyId: string, moduleId: string, subModuleId: string, rating: number) {
        let url = `${mainUrl}/mobile/content/subModuleRating`
        await axios({
            method: 'post',
            url,
            data: { companyId, moduleId, subModuleId, rating },
            headers: {
                'Authorization': `Token ${token}`
            }
        })
    }

    async saveUserModuleFeedback(token: string, mainUrl: string, companyId: string, moduleId: string, feedback: string) {
        let url = `${mainUrl}/mobile/content/moduleFeedback`
        let result = await axios.post(url, { companyId, moduleId, feedback }, { headers: { 'Authorization': `Token ${token}` }})
        return new UserModuleFeedback(result.data.data.userModuleFeedback)
    }
}

export class CompanyModulesRes {
    modules: Array<Module>
    companySubModules: Array<SubModule>
    userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>
    
    constructor(data: any) {
        this.modules = data.companyModules.map((data: any) => new Module(data))
        this.companySubModules = data.companySubModules.map((data: any) => new SubModule(data))
        this.userMaxScoreInSubModules = data.userMaxScoreInSubModules.map((data: any) => new UserMaxScoreInSubModule(data))
    }
}

export class ModuleAndSubmoduleRes {
    module: Module
    subModules: Array<SubModule>
    userModuleFeedback?: UserModuleFeedback
    userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>
    userQuizAttempts: { [key: string]: number }
    libraryContent: Array<LibraryContent>
    isForumEnabled: boolean

    constructor(data: any) {
        this.module = new Module(data.module)
        this.subModules = data.subModules.map((subModuleData: any) => new SubModule(subModuleData))
        this.userMaxScoreInSubModules = data.userMaxScoreInSubModules.map((data: any) => new UserMaxScoreInSubModule(data))
        this.userQuizAttempts = data.userQuizAttempts
        this.libraryContent = data.libraryContent.map((data: any) => getLibraryContent(data))
        this.isForumEnabled = data.isForumEnabled
        if(data.userModuleFeedback) this.userModuleFeedback = new UserModuleFeedback(data.userModuleFeedback)
    }
}

export class OpenModuleAndSubmoduleRes {
    module: Module
    subModules: Array<SubModule>
    constructor(data: any) {
        this.module = new Module(data.module)
        this.subModules = data.subModules.map((subModuleData: any) => new SubModule(subModuleData))
    }
}

export class SignUpContentConfigRes {
    company: Company
    signUpConfig: SignUpConfig
    contentConfig?: ContentConfig
    companyRankingConfig?: { [id: string]: RankingConfig; }
    constructor(data: any) {
        this.company = new Company(data.company)
        this.signUpConfig = new SignUpConfig(data.signUpConfig)
        if(data.contentConfig) this.contentConfig = new ContentConfig(data.contentConfig)
        if(data.companyRankingConfig) this.companyRankingConfig = data.companyRankingConfig
    }
}