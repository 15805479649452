import React, { useState } from 'react';

import loadingGif from "../../images/loading_no_background.gif";


export default function ShowGif(param: { url: string, gifMaxSize: number }) {
    const [loading, setLoading] = useState(true);
    let imageLoaded = () => { setLoading(false) }
    let { url, gifMaxSize } = param
    return <img src={url} onLoad={imageLoaded} alt='' style={{ height: 'auto', width: '100%', maxHeight: gifMaxSize, objectFit: 'contain', background: loading ? `transparent url(${loadingGif}) center no-repeat` : 'transparent' }} />
}