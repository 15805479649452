import React, { Component, useEffect } from 'react';
import { Alert, Container, Row, Col, Button } from 'reactstrap'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import * as _ from 'lodash'

import LoadingScreen from '../loading'
import Cookies from 'universal-cookie';
import Company from '../../models/Company';
import config from '../../config'
import User from '../../models/User';
import TokenHelper from '../../auth/TokenHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faInfoCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import RankingService from '../../services/rankingService';
import { RankingEntry, RankingTypes } from '../../models/Ranking';
import Module from '../../models/Module';
import { UserModuleAttempts } from './intefaces'
import ContentService from '../../services/contentService';
import AwardSvg from '../../images/award.svg'
import { RankingConfig } from '../../models/CompanyConfig';
import StarSvg from '../../images/star.svg'
import GoldMedalSvg from '../../images/Number1Medal.svg'
import * as ROUTES from '../../constants/routes'
import { useUser } from '../../hooks/useUser';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

interface State {
    error: string | undefined;
    isLoading: boolean;
    rankingId?: string;
    module?: Module;
    rankingEntries?: Array<RankingEntry>;
    rankingConfig?: RankingConfig;
    usersModuleAttempts?: UserModuleAttempts[]
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    rankingId: undefined,
    module: undefined,
    rankingEntries: undefined,
    rankingConfig: undefined,
};


const SubModuleRankingScreen = () => {

    const { companyId, moduleId } = useParams()
    const navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { user, company, noUser, noCompany } = useUser()
    const { height } = useWindowDimensions()
    const [searchParams] = useSearchParams()

    const rankingId = searchParams.get('rankingId') || ''

    useEffect(() => {
        if (company && moduleId && rankingId) {
            loadModule(company.id, moduleId, rankingId)
        }
    }, [company, moduleId, rankingId])

    useEffect(() => { if (state.module) loadRankingConfig(state.module.companyId, state.module.id, rankingId) }, [state.module])

    useEffect(() => {
        if ((noUser || noCompany) && companyId) {
            if (companyId === 'app') setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.', isLoading: false })
            else navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
        }
    }, [noUser, noCompany, companyId])

    const loadModule = async (companyId: string, moduleId: string, rankingId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let module = await contentService.getCompanyModule(token, config.endpoint, companyId, moduleId)
            setState({ ...state, module })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadModule(companyId, rankingId, moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    useEffect(() => {
        if (state.rankingConfig && companyId && moduleId) loadRanking(companyId, moduleId, state.rankingConfig)
    }, [state.rankingConfig, companyId, state.module])

    const loadRankingConfig = async (companyId: string, moduleId: string, rankingId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.', isLoading: false })
            let rankingService = new RankingService()
            let rankingConfigs = await rankingService.getModuleRankingConfig(token, config.endpoint, companyId)
            let rankingConfig = _.head(rankingConfigs.filter(rk => rk.id === rankingId))
            if (rankingConfig) {
                setState({ ...state, rankingConfig })
            } else {
                setState({ ...state, isLoading: false, error: 'Ranking Config not found' })
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadRankingConfig(companyId, moduleId, rankingId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const loadRanking = async (companyId: string, moduleId: string, rankingConfig: RankingConfig) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.', isLoading: false })
            let rankingService = new RankingService()
            let rankingId = rankingConfig.id
            let rankingType = rankingConfig.type
            var rankingEntries: Array<RankingEntry> = []
            if (rankingId) {
                if (rankingType === RankingTypes.segmented) {
                    rankingEntries = await rankingService.getModuleSegmentedRanking(token, config.endpoint, companyId, moduleId, rankingId)
                } else {
                    rankingEntries = await rankingService.getModuleDefaultRanking(token, config.endpoint, companyId, moduleId, rankingId)
                }
            } else {
                rankingEntries = await rankingService.getModuleDefaultRanking(token, config.endpoint, companyId, moduleId)
            }
            setState({ ...state, rankingEntries: rankingEntries.sort((a, b) => { if (b.score !== a.score) { return b.score - a.score } else { return a.lastUpdate - b.lastUpdate } }), isLoading: false })
            if (rankingConfig.attemptsTiebraker) loadSubModuleAttempts(companyId, moduleId)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadRanking(companyId, moduleId, rankingConfig)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const loadSubModuleAttempts = async (companyId: string, moduleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.', isLoading: false })
            let rankingService = new RankingService()
            let usersModuleAttempts = await rankingService.getUsersSubModuleAttempts(token, config.endpoint, companyId, moduleId)
            setState({ ...state, isLoading: false, error: undefined, usersModuleAttempts })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadSubModuleAttempts(companyId, moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const onExit = () => {
        navigate(-1)
    }

    const renderError = (error: string) => {
        return (
            <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                {error}
            </Alert>
        );
    }

    const renderModuleHeader = (module: Module, title: string) => {
        return <Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', maxHeight: '5em' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, marginBottom: 10, marginTop: 10, objectFit: 'contain' }} src={module.pic} />
                    <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap' }}>
                        <div style={{ color: '#353f45', verticalAlign: 'middle', fontSize: 16, marginLeft: 10 }}><b>Ranking {title}</b></div>
                        <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#353f45', verticalAlign: 'middle', marginLeft: 10 }}>{module.title}</div>
                    </div>
                </div>
            </Col>
        </Row>
    }

    const renderRanking = (user: User, rankingEntries: Array<RankingEntry>, rankingConfig: RankingConfig, rankingPos: any, isAdmin: boolean) => {
        return (
            <Row style={{ overflow: 'auto', paddingTop: 10, paddingBottom: 10, zIndex: 1 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex align-items-center mt-2">
                        <img style={{ width: '2em', height: '2em', marginRight: 5, paddingBottom: 5 }} alt='ícone de troféu' src={AwardSvg} />
                        <b>Top {rankingConfig.count}</b>
                    </div>
                </Col>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-start align-items-center" style={{ marginTop: 5, marginLeft: 5 }}>
                        <div><FontAwesomeIcon color='black' icon={faInfoCircle} /></div>
                        <div style={{ color: 'black', textAlign: 'start', marginLeft: 10, fontSize: 12 }}><i>Sua pontuação é a soma da pontuação em cada fase</i></div>
                    </div>
                </Col>
                {rankingEntries.filter((entry) => rankingPos[entry.user.id] <= rankingConfig.count).map(rankingEntry => renderRankingEntry(user, rankingEntry, rankingConfig, rankingPos[rankingEntry.user.id], isAdmin))}
            </Row>)
    }

    const renderUserProfilePic = (user: User) => {
        if (!user.pic) return (<div><FontAwesomeIcon color='#1d3256' icon={faUserCircle} size='2x' /></div>)
        if (user.thumbnail) return <img style={{ width: '2em', height: '2em', borderRadius: '1em', objectFit: 'cover' }} alt='prof pic' src={user.thumbnail} />
        else return <img alt='prof pic' src={user.pic} />
    }

    const renderUserAttempts = (userId: string, usersModuleAttempts: UserModuleAttempts[]) => {
        const userModuleAttemptSearch = _.find(usersModuleAttempts.filter(attempt => attempt.userId === userId))
        if (userModuleAttemptSearch) {
            let attempts = userModuleAttemptSearch.subModuleAttempts.reduce((acc, curr) => { acc += curr.attemptsToMaxScore; return acc }, 0)
            return <div style={{ textAlign: 'start', fontSize: 'small' }}><i>Tentativas até pontuação máxima: {attempts}</i></div>
        } else {
            return <div />
        }
    }

    const renderRankingEntry = (user: User, rankingEntry: RankingEntry, rankingConfig: RankingConfig, pos: number, isAdmin: boolean) => {
        var svgIcon = StarSvg
        if (pos === 1) svgIcon = GoldMedalSvg
        return (
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex align-items-center" style={{ background: rankingEntry.user.id === user.id ? '#dbdbdbda' : '#FFFFFFDA', marginTop: 15, padding: 5, boxShadow: '1px 2px 3px 1px rgba(0,0,0,0.2)', minHeight: isAdmin ? 90 : 80, borderRadius: 5 }}>
                    <div style={{ fontSize: 18, color: 'black', fontStyle: 'bold', textAlign: 'center', margin: 5, width: '1em' }}><b>{pos}</b></div>
                    {renderUserProfilePic(rankingEntry.user)}
                    <div className="d-flex flex-column" style={{ flex: 1, marginLeft: 5, color: '#353f45' }}>
                        {isAdmin && <div style={{ textAlign: 'start', fontSize: '0.9em' }}>{rankingEntry.user.doc || rankingEntry.user.email}</div>}
                        <div style={{ textAlign: 'start' }}>{rankingEntry.user.username}</div>
                        {rankingConfig.dateTiebraker && <div style={{ textAlign: 'start', fontSize: 'small' }}><i>{(new Date(rankingEntry.lastUpdate)).toLocaleDateString()} {(new Date(rankingEntry.lastUpdate)).toLocaleTimeString()}</i></div>}
                        {rankingConfig.attemptsTiebraker && state.usersModuleAttempts && renderUserAttempts(rankingEntry.user.id, state.usersModuleAttempts)}
                    </div>
                    <div style={{ flexShrink: 0, padding: 5, borderColor: '#a1a6af', borderRadius: 10, color: '#a1a6af', borderStyle: 'solid', borderWidth: 1, textAlign: 'start', marginRight: 5, marginLeft: 5 }}><img style={{ width: '0.95em', height: '0.95em', paddingBottom: 2 }} alt='ícone de estrela' src={svgIcon} /> {rankingEntry.score}</div>
                </div>
            </Col>)
    }

    const renderUserRankingEntry = (rankingEntry: RankingEntry, pos: number, rankingConfig: RankingConfig) => {
        var svgIcon = StarSvg
        if (pos === 1) svgIcon = GoldMedalSvg
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex align-items-center" style={{ background: '#FFFFFFDA', borderColor: '#EEEEEEDA', borderWidth: 'thin', borderStyle: 'solid', marginBottom: 5, padding: 5, boxShadow: '3px 4px 8px 3px rgba(0,0,0,0.5)', minHeight: 80, borderRadius: 5 }}>
                        <div style={{ fontSize: 18, color: 'black', fontFamily: 'Montserrat', fontStyle: 'bold', textAlign: 'center', margin: 5, width: '1em' }}><b>{pos}</b></div>
                        {renderUserProfilePic(rankingEntry.user)}
                        <div className="d-flex flex-column" style={{ flex: 1, marginLeft: 5 }}>
                            <div style={{ color: '#353f45', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5 }}>{rankingEntry.user.username}</div>
                            {rankingConfig.dateTiebraker && <div style={{ color: '#353f45', fontFamily: 'Montserrat', textAlign: 'start', fontSize: 'small' }}><i>{(new Date(rankingEntry.lastUpdate)).toLocaleDateString()} {(new Date(rankingEntry.lastUpdate)).toLocaleTimeString()}</i></div>}
                        </div>
                        <div style={{ padding: 5, borderColor: '#a1a6af', borderRadius: 10, color: '#a1a6af', borderStyle: 'solid', borderWidth: 1, textAlign: 'start', marginRight: 5, marginLeft: 5 }}><img style={{ width: '0.95em', height: '0.95em', paddingBottom: 2 }} alt='ícone de estrela' src={svgIcon} /> {rankingEntry.score}</div>
                    </div>
                </Col>
            </Row >)
    }


    let { module, error, isLoading, rankingEntries, rankingConfig, usersModuleAttempts } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    var rankingPos: { [id: string]: number } = {}

    if (rankingEntries && rankingEntries.length > 0) {
        var lastScore = rankingEntries.length > 0 ? (rankingEntries[0].score + 1) : 0
        var currentPos = 0
        rankingPos = rankingEntries.sort((a, b) => {
            if (a.score !== b.score) {
                return b.score - a.score
            } else if (rankingConfig && rankingConfig.dateTiebraker) {
                return a.lastUpdate - b.lastUpdate
            } else if (rankingConfig && rankingConfig.attemptsTiebraker && usersModuleAttempts) {
                const aModuleAttemptSearch = _.find(usersModuleAttempts.filter(attempt => attempt.userId === a.user.id))
                const bModuleAttemptSearch = _.find(usersModuleAttempts.filter(attempt => attempt.userId === b.user.id))
                if (aModuleAttemptSearch && bModuleAttemptSearch) {
                    return aModuleAttemptSearch.subModuleAttempts.reduce((acc, curr) => { acc += curr.attemptsToMaxScore; return acc }, 0) - bModuleAttemptSearch.subModuleAttempts.reduce((acc, curr) => { acc += curr.attemptsToMaxScore; return acc }, 0)
                } else {
                    return 0
                }
            } else {
                return 0
            }
        }).reduce((acc: any, entry) => {

            if (rankingConfig && rankingConfig.dateTiebraker && entry.score === lastScore) {
                currentPos++
                lastScore = entry.score
            } if (rankingConfig && rankingConfig.attemptsTiebraker && entry.score === lastScore) {
                currentPos++
                lastScore = entry.score
            } else {
                if (entry.score < lastScore) {
                    currentPos++
                    lastScore = entry.score
                }
            }
            acc[entry.user.id] = currentPos
            return acc
        }, {})
    }

    let userRankingEntry = (user && rankingEntries) ? _.head(rankingEntries.filter(entry => entry.user.id === user!.id)) : undefined
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container fluid className="d-flex flex-column relative" style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && renderError(error)}
        {module && rankingConfig && renderModuleHeader(module, rankingConfig.title)}
        {user && rankingEntries && rankingConfig && renderRanking(user, rankingEntries, rankingConfig, rankingPos, user.admin === true)}
        {userRankingEntry && rankingConfig && rankingPos[userRankingEntry.user.id] > rankingConfig.count && renderUserRankingEntry(userRankingEntry, rankingPos[userRankingEntry.user.id], rankingConfig)}
    </Container>)

}

export default SubModuleRankingScreen