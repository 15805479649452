import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Alert, Form, FormGroup, InputGroup, Input } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import Company from '../../../models/Company';
import { faArrowLeft, faPlusCircle, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TokenHelper from '../../../auth/TokenHelper';
import AdminConfigService from '../../../services/admin/adminConfigService';
import config from '../../../config'
import LoadingScreen from '../../loading';
import { useUser } from '../../../hooks/useUser';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

interface State {
    error: string | undefined;
    confirmedMessage: string | undefined;
    isLoading: boolean;
    moduleCategories: Array<{ id: string, title: string }>,
    newCategoryInput: string
};

const INITIAL_STATE: State = {
    error: undefined,
    confirmedMessage: undefined,
    isLoading: false,
    moduleCategories: [],
    newCategoryInput: ''
};

const ConfigScreen = () => {

    let navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { company } = useUser()
    const { height } = useWindowDimensions()

    useEffect(() => {
        if (company) {
            loadCompanyConfig()
        } else {
            setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.' })
        }
    }, [company])



    const loadCompanyConfig = async () => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, isLoading: false, error: 'Usuário não possui token de acesso.' })
            let adminConfigService = new AdminConfigService()
            let companyConfig = await adminConfigService.getCompanyConfig(token, config.endpoint)
            let moduleCategories = companyConfig.contentConfig && companyConfig.contentConfig.moduleCategories ? companyConfig.contentConfig.moduleCategories : []
            setState({ ...state, moduleCategories, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadCompanyConfig()
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const updateModuleCategories = async (moduleCategories: Array<{ id: string, title: string }>) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminConfigService = new AdminConfigService()
            await adminConfigService.updateModuleCategories(token, config.endpoint, moduleCategories)
            setState({ ...state, isLoading: false, confirmedMessage: 'Categorias atualizadas com sucesso!' })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                updateModuleCategories(moduleCategories)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const goBack = () => navigate(-1)

    const renderHeader = (company: Company) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ background: '#FFFFFFDA', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { goBack() }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Configurações</b></div>
                    <img alt='foto da empresa' style={{ height: 60 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderModuleCategories = (moduleCategories: Array<{ id: string, title: string }>, newCategoryInput: string) => {
        return <Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Form className="bg-light p-2 m-2 rounded border fs-6">
                    <FormGroup>
                        <InputGroup>
                            <Input name="newCategoryInput" id="newCategoryInput" placeholder="Nova Categoria" value={newCategoryInput} onChange={(event: any) => setState({ ...state, newCategoryInput: event.target.value })} />
                            <Button outline color="primary" onClick={() => setState({ ...state, newCategoryInput: '', moduleCategories: [...moduleCategories, { id: newCategoryInput.toLocaleLowerCase().trim(), title: newCategoryInput }] })}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                        </InputGroup>
                    </FormGroup>
                </Form>
            </Col>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                {moduleCategories.map((category) => {
                    return <div className="d-flex align-items-center mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                        <div style={{ flex: 1 }}><b>{category.title}</b></div><Button className='ms-1' size="sm" outline color="danger" onClick={() => { setState({ ...state, moduleCategories: [...moduleCategories.filter((data) => data.id !== category.id)] }) }}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                    </div>
                })}
            </Col>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Button className='mt-1' color="primary" onClick={() => updateModuleCategories(moduleCategories)} ><FontAwesomeIcon className='me-1' icon={faSave} />Salvar</Button>
            </Col>
        </Row>
    }

    let { error, isLoading, moduleCategories, newCategoryInput, confirmedMessage } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container fluid className="d-flex flex-column relative" style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {company && renderHeader(company)}
        <Row><Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}><Alert isOpen={error !== undefined} toggle={() => setState({ ...state, error: undefined })} color="danger">{error}</Alert></Col></Row>
        <Row><Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}><Alert isOpen={confirmedMessage !== undefined} toggle={() => setState({ ...state, confirmedMessage: undefined })} color="success">{confirmedMessage}</Alert></Col></Row>
        {renderModuleCategories(moduleCategories, newCategoryInput)}
    </Container>)
}

export default ConfigScreen