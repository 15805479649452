import React, { Component, Fragment } from 'react';
import QuizQuestion, { QuizQuestionType, DefaultQuestion, MemoryQuestion, SelectAnswerQuestion, WordGuessQuestion } from '../../models/QuizQuestion';
import { QuizQuestionsReport, DefaultQuestionReport, MemoryQuestionReport, SelectAnswerQuestionReport, WordGuessQuestionReport } from '../../models/QuizQuestionsReport';
import { QuestionAnswer } from '../../models/Question';
import * as _ from 'lodash'
import { Container, Row, Col, Button, Progress } from 'reactstrap';
import Zoom from 'react-medium-image-zoom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { POINTS_PER_QUIZ_QUESTION, POINTS_PER_MEMORY_GAME, POINTS_PER_WORD_GUESS } from '../../constants/ScoreConstants';
import MemoryGameComponent from '../../deck/MemoryGameComponent';
import { MEDIUM_HEIGHT, SMALL_HEIGHT } from '../../constants/screens'
import './style.css'
import RenderSelectAnswerQuestion from '../../quiz/RenderSelectAnswerQuestion';
import WordGuessComponent from '../../quiz/WordGuessComponent';
import StarSvg from '../../images/star.svg'
import AnswerComponent from './AnswerComponent';
import { SubModuleQuiz } from '../../models/SubModule';
import Company from '../../models/Company';

const answerTime = 45

interface State {
    isNavBarMenuOpen: boolean,
    currentQuizQuestion: number,
    isTimerOn: boolean,
    timeInSeconds: number,
    start: number,
    selectedAnswer: number,
    height: number
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false,
    currentQuizQuestion: 0,
    isTimerOn: false,
    timeInSeconds: 0,
    start: 0,
    selectedAnswer: -1,
    height: 0
};

interface Props { company: Company, subModule: SubModuleQuiz, quizQuestions: Array<QuizQuestion>, onExit: () => void, onFinish: (startTime: number, endTime: number, quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>) => void }

export default class QuizScreen extends Component<Props, State> {

    timer: any

    quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>
    startTime: number
    endTime: number | undefined

    constructor(props: Props) {
        super(props)
        this.startTime = (new Date()).getTime()
        this.quizQuestionsReport = []
        this.state = { ...INITIAL_STATE }

        this.startTimer = this.startTimer.bind(this)
        this.stopTimer = this.stopTimer.bind(this)

        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion]
        this.addQuestionReport(currentQuizQuestion)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion]
        if (currentQuizQuestion instanceof DefaultQuestion || currentQuizQuestion instanceof SelectAnswerQuestion) {
            // Se for uma pergunta precisa inicializar o timer
            this.startTimer(currentQuizQuestion)
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        clearInterval(this.timer)
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ height: window.innerHeight });
    }

    startTimer(question: DefaultQuestion | SelectAnswerQuestion) {
        this.setState({
            isTimerOn: true,
            timeInSeconds: 0,
            start: Date.now(),
            selectedAnswer: -1,
        })
        clearInterval(this.timer)
        this.timer = setInterval(() => { this.timerTick(question) }, 500);
    }

    stopTimer() {
        this.setState({ isTimerOn: false })
        clearInterval(this.timer)
    }

    timerTick(question: DefaultQuestion | SelectAnswerQuestion) {
        let timeInSeconds = Math.round((Date.now() - this.state.start) / 1000)
        if (timeInSeconds <= (question.answerTime || answerTime)) {
            this.setState({
                timeInSeconds: Math.round((Date.now() - this.state.start) / 1000)
            })
        } else {
            this.onQuestionTimeout()
            clearInterval(this.timer)
            this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
        }
    }

    addQuestionReport(quizQuestion: QuizQuestion) {
        if (this.quizQuestionsReport.filter(questionReport => questionReport.quizQuestion.id === quizQuestion.id).length > 0) return
        if (quizQuestion.type === QuizQuestionType.Default) {
            let defaultQuestionReport = new DefaultQuestionReport(quizQuestion as DefaultQuestion)
            this.quizQuestionsReport.push(defaultQuestionReport)
        } else if (quizQuestion.type === QuizQuestionType.SelectAnswer) {
            let selectAnswerCardReport = new SelectAnswerQuestionReport(quizQuestion as SelectAnswerQuestion)
            this.quizQuestionsReport.push(selectAnswerCardReport)
        } else if (quizQuestion.type === QuizQuestionType.MemoryGame) {
            let quizQuestionReport = new MemoryQuestionReport(quizQuestion as MemoryQuestion)
            this.quizQuestionsReport.push(quizQuestionReport)
        } else if (quizQuestion.type === QuizQuestionType.WordGuess) {
            let wordGuessQuestionReport = new WordGuessQuestionReport(quizQuestion as WordGuessQuestion)
            this.quizQuestionsReport.push(wordGuessQuestionReport)
        }
    }

    onDefaultQuestionAnswerSelected = (answerNumber: number) => {
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion] as DefaultQuestion
        let cardReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === currentQuizQuestion.id))
        let questionCardReport = cardReport as DefaultQuestionReport
        if (questionCardReport) {
            if (questionCardReport.questionAnswer !== QuestionAnswer.NotAnswered) return
            // bug do caralho que manda um timeToAnswer negativo pra bosta do backend. tnc.
            // o cara que descobrir como resolver essa merda de bug ganha um aumento no salário.
            questionCardReport.timeToAnswer = this.state.timeInSeconds >= 0 ? this.state.timeInSeconds : 0
            if (answerNumber === currentQuizQuestion.correctAnswer) {
                questionCardReport.questionAnswer = QuestionAnswer.CorrectAnswer
                if (this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                    setTimeout(() => {
                        this.goToNextQuestion(this.state.currentQuizQuestion + 1)
                    }, 2000)
                } else {
                    setTimeout(() => {
                        this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                    }, 2000)
                }
            } else {
                questionCardReport.questionAnswer = QuestionAnswer.WrongAnswer
                if (this.props.subModule.mistakesAllowed && this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                    setTimeout(() => {
                        this.goToNextQuestion(this.state.currentQuizQuestion + 1)
                    }, 2000)
                } else {
                    setTimeout(() => {
                        this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                    }, 2000)
                }
            }
            clearInterval(this.timer)
            this.setState({ selectedAnswer: answerNumber, isTimerOn: false })
        }
    }

    onMemoryGameFinished = (memoryQuestion: MemoryQuestion, attempts: number) => {
        let memoryQuestionReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === memoryQuestion.id)) as MemoryQuestionReport
        if (memoryQuestionReport) {
            if (memoryQuestionReport.allImagesFound) return
            memoryQuestionReport.allImagesFound = true
            memoryQuestionReport.attempts = attempts
            if (this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                this.goToNextQuestion(this.state.currentQuizQuestion + 1)
            } else {
                setTimeout(() => {
                    this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                }, 2000)
            }
        }
    }

    onWordGuessFinished = (wordGuessQuestion: WordGuessQuestion, attempts: number) => {
        let wordGuessReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === wordGuessQuestion.id)) as WordGuessQuestionReport
        if (wordGuessReport) {
            if (wordGuessReport.wordFound) return
            wordGuessReport.wordFound = true
            wordGuessReport.attempts = attempts
            if (this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                setTimeout(() => {
                    this.goToNextQuestion(this.state.currentQuizQuestion + 1)
                }, 2000)
            } else {
                setTimeout(() => {
                    this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                }, 2000)
            }
        }
    }

    onFullScreenExit = () => {
        this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
    }

    onSelectAnswerQuestionAnswerSelected = (selectedAnswers: Array<number>) => {
        let selectAnswerQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion] as SelectAnswerQuestion
        let cardReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === selectAnswerQuizQuestion.id))
        let selectAnswerQuestionReport = cardReport as SelectAnswerQuestionReport
        if (selectAnswerQuestionReport) {
            if (selectAnswerQuestionReport.questionAnswer !== QuestionAnswer.NotAnswered) return
            selectAnswerQuestionReport.timeToAnswer = this.state.timeInSeconds >= 0 ? this.state.timeInSeconds : 0
            selectAnswerQuestionReport.selectedAnswers = selectedAnswers
            if (_.isEqual(selectAnswerQuestionReport.quizQuestion.correctAnswers.sort(), selectedAnswers.sort())) {
                selectAnswerQuestionReport.questionAnswer = QuestionAnswer.CorrectAnswer
                if (this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                    setTimeout(() => {
                        this.goToNextQuestion(this.state.currentQuizQuestion + 1)
                    }, 2000)
                } else {
                    setTimeout(() => {
                        this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                    }, 2000)
                }
            } else {
                selectAnswerQuestionReport.questionAnswer = QuestionAnswer.WrongAnswer
                if (this.props.subModule.mistakesAllowed && this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                    setTimeout(() => {
                        this.goToNextQuestion(this.state.currentQuizQuestion + 1)
                    }, 2000)
                } else {
                    setTimeout(() => {
                        this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                    }, 2000)
                }
            }
            clearInterval(this.timer)
            this.setState({ isTimerOn: false })
        }
    }

    onQuestionTimeout() {
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion]
        let cardReport = _.head(this.quizQuestionsReport.filter(quizQuestionReport => quizQuestionReport.quizQuestion.id === currentQuizQuestion.id))
        if (cardReport) {
            if (cardReport instanceof DefaultQuestionReport || cardReport instanceof SelectAnswerQuestionReport) {
                cardReport.questionAnswer = QuestionAnswer.Timeout
            }
        }
    }

    goToNextQuestion(nextQuestionIndex: number) {
        let currentQuizQuestion = this.props.quizQuestions[nextQuestionIndex]
        this.addQuestionReport(currentQuizQuestion)
        this.setState({ currentQuizQuestion: nextQuestionIndex }, () => {
            if (currentQuizQuestion instanceof DefaultQuestion || currentQuizQuestion instanceof SelectAnswerQuestion) {
                this.startTimer(currentQuizQuestion)
            }
        })
    }

    getScore() {
        return this.quizQuestionsReport.reduce((acc, val) => {
            if (val instanceof DefaultQuestionReport || val instanceof SelectAnswerQuestionReport) {
                if (val.questionAnswer === QuestionAnswer.CorrectAnswer) {
                    acc += POINTS_PER_QUIZ_QUESTION - val.timeToAnswer!
                }
            } else if (val instanceof MemoryQuestionReport) {
                if (val.allImagesFound) {
                    acc += POINTS_PER_MEMORY_GAME - val.attempts
                }
            } else if (val instanceof WordGuessQuestionReport) {
                if (val.wordFound) {
                    acc += POINTS_PER_WORD_GUESS
                }
            }
            return acc
        }, 0)
    }

    renderHeader(score: number, question: DefaultQuestion | SelectAnswerQuestion) {
        return (<Row style={{ minHeight: '4em', boxShadow: '2px 1px 2px 1px rgba(0,0,0,0.2)', background: 'white', paddingTop: 5, paddingBottom: 5 }}>
            <Col className='d-flex justify-content-center align-items-center' style={{ minHeight: '4em' }} md={{ size: 8, offset: 2 }}>
                <div style={{ alignSelf: 'center' }}>
                    <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                </div>
                <div style={{ flex: 1, marginLeft: 5 }}>
                    <div className='d-flex align-items-end' style={{ marginBottom: 2 }}>
                        <img style={{ width: '1.5em', height: '1.5em', marginRight: 5, marginBottom: 5 }} alt='ícone de troféu' src={StarSvg} />
                        <div style={{ color: '#353f45', fontSize: 'large' }}><b>{score}</b></div>
                    </div>
                    <Progress color="game-progress" value={(question.answerTime || answerTime) - this.state.timeInSeconds} max={question.answerTime || answerTime} >
                        <div className="text-center" style={{ color: 'white', fontSize: '0.8em', paddingLeft: 5, paddingRight: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faClock} /><b>{`${(question.answerTime || answerTime) - this.state.timeInSeconds} segundos`}</b></div>
                    </Progress>
                </div>
            </Col>
        </Row>)
    }

    renderDefaultQuestion(defaultQuestion: DefaultQuestion, selectedAnswer: number, defaultQuestionReport: DefaultQuestionReport) {
        let answer = defaultQuestionReport.questionAnswer
        let answersCount = defaultQuestion.answer2 ? (defaultQuestion.answer3 ? 4 : 3) : 2
        var titleMargin = 10
        var titleFontSize = '1em'
        var screenHeightSize = 'small'
        // se for celular com tamanho medio
        if (this.state.height > SMALL_HEIGHT && this.state.height <= MEDIUM_HEIGHT) {
            screenHeightSize = 'medium'
            if (defaultQuestion.question.length < 100) {
                titleFontSize = '1.2em'
            }
        }
        // se for tablet ou computador
        else if (this.state.height > MEDIUM_HEIGHT) {
            titleMargin = 10
            screenHeightSize = 'big'
            if (answersCount < 4) {
                titleFontSize = '1.3em'
            } else {
                titleFontSize = '1.2em'
            }
        }

        let questionImageHeight = screenHeightSize === 'big' ? 200 : (screenHeightSize === 'medium' ? 160 : 120)

        return (<Row style={{ flex: 1, overflow: 'auto' }}>
            <Col style={{ marginTop: 5 }} md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'stretch', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: titleMargin, fontSize: titleFontSize, textAlign: 'center', color: 'black' }}><b>{defaultQuestion.question}</b></div>
                    {defaultQuestion.questionImageUrl && <div className="d-flex flex-column align-items-center justify-content-center"><Zoom><img src={defaultQuestion.questionImageUrl} alt='imagem da pergunta' style={{ height: questionImageHeight, objectFit: 'contain', marginBottom: 5, marginTop: 5 }} /></Zoom></div>}
                    <AnswerComponent removeBorder={true} screenHeight={screenHeightSize} answerText={defaultQuestion.answer0} selectedAnswer={selectedAnswer} answerNumber={0} img={defaultQuestion.imgAnswer0}
                        questionAnswer={answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} correctAnswer={defaultQuestion.correctAnswer} />
                    <AnswerComponent removeBorder={true} screenHeight={screenHeightSize} answerText={defaultQuestion.answer1} selectedAnswer={selectedAnswer} answerNumber={1} img={defaultQuestion.imgAnswer1}
                        questionAnswer={answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} correctAnswer={defaultQuestion.correctAnswer} />
                    {defaultQuestion.answer2 && (
                        <AnswerComponent removeBorder={true} screenHeight={screenHeightSize} answerText={defaultQuestion.answer2} selectedAnswer={selectedAnswer} answerNumber={2} img={defaultQuestion.imgAnswer2}
                            questionAnswer={answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} correctAnswer={defaultQuestion.correctAnswer} />
                    )}
                    {defaultQuestion.answer3 && (
                        <AnswerComponent removeBorder={true} screenHeight={screenHeightSize} answerText={defaultQuestion.answer3} selectedAnswer={selectedAnswer} answerNumber={3} img={defaultQuestion.imgAnswer3}
                            questionAnswer={answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} correctAnswer={defaultQuestion.correctAnswer} />
                    )}
                </div>
            </Col>
        </Row>)
    }

    renderSelectAnswerQuestion(selectAnswerQuestion: SelectAnswerQuestion, selectAnswerQuestionReport: SelectAnswerQuestionReport, screenHeight: number) {
        var screenHeightSize = 'small'
        // se for celular com tamanho medio
        if (screenHeight > SMALL_HEIGHT && screenHeight <= MEDIUM_HEIGHT) {
            screenHeightSize = 'medium'
        }
        // se for tablet ou computador
        else if (screenHeight > MEDIUM_HEIGHT) {
            screenHeightSize = 'big'
        }

        return <RenderSelectAnswerQuestion screenHeight={screenHeightSize} selectAnswerQuestion={selectAnswerQuestion} questionAnswer={selectAnswerQuestionReport.questionAnswer} onCheckAnswer={this.onSelectAnswerQuestionAnswerSelected} />
    }

    renderForQuestion(currentQuizQuestion: QuizQuestion, questionReport: QuizQuestionsReport<QuizQuestion> | undefined, height: number, selectedAnswer: number) {

        if (currentQuizQuestion instanceof DefaultQuestion) {
            let score = this.getScore()
            let defaultQuestionReport = questionReport as DefaultQuestionReport
            return (<Fragment>
                {this.renderHeader(score, currentQuizQuestion)}
                {this.renderDefaultQuestion(currentQuizQuestion, selectedAnswer, defaultQuestionReport)}
            </Fragment>)
        } else if (currentQuizQuestion instanceof SelectAnswerQuestion) {
            let score = this.getScore()
            let selectAnswerQuestionReport = questionReport as SelectAnswerQuestionReport
            return (<Fragment>
                {this.renderHeader(score, currentQuizQuestion)}
                {this.renderSelectAnswerQuestion(currentQuizQuestion, selectAnswerQuestionReport, height)}
            </Fragment>)
        } else if (currentQuizQuestion instanceof MemoryQuestion) {
            var screenHeight = 'small'
            // se for celular com tamanho medio
            if (height > SMALL_HEIGHT && height <= MEDIUM_HEIGHT) {
                screenHeight = 'medium'
            }
            // se for tablet ou computador
            else if (height > MEDIUM_HEIGHT) {
                screenHeight = 'big'
            }
            return <MemoryGameComponent title={currentQuizQuestion.title} images={currentQuizQuestion.images} screenSize={screenHeight} onMemoryGameFinished={(attempts) => this.onMemoryGameFinished(currentQuizQuestion as MemoryQuestion, attempts)} onExit={() => this.onFullScreenExit()} showExit={true} />
        } else if (currentQuizQuestion instanceof WordGuessQuestion) {
            var screenHeight = 'small'
            // se for celular com tamanho medio
            if (height > SMALL_HEIGHT && height <= MEDIUM_HEIGHT) {
                screenHeight = 'medium'
            }
            // se for tablet ou computador
            else if (height > MEDIUM_HEIGHT) {
                screenHeight = 'big'
            }
            return <WordGuessComponent company={this.props.company} title={currentQuizQuestion.title} word={currentQuizQuestion.word} height={screenHeight} onWordGuessFinished={(attempts) => this.onWordGuessFinished(currentQuizQuestion as WordGuessQuestion, attempts)} onExit={() => this.onFullScreenExit()} showExitButton={true} />
        }

    }


    render() {

        let { selectedAnswer, height } = this.state
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion]
        let questionReport = _.head(this.quizQuestionsReport.filter(report => report.quizQuestion.id === currentQuizQuestion.id))

        let company = this.props.company
        let backgroundImage = company.backgroundImages ? company.backgroundImages[0] : undefined
        let backgroundPosition = company?.backgroundPosition || 'left top'

        return (<Container fluid className="d-flex flex-column relative" style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
            {this.renderForQuestion(currentQuizQuestion, questionReport, height, selectedAnswer)}
        </Container>)
    }
}