import React, { Component } from 'react';
import { Alert, Button, Col, Form, FormGroup, FormText, Input, InputGroup, InputGroupText, Row, Spinner } from 'reactstrap';
import { faArrowLeft, faCheckCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteVideoFile, getAllVideoFiles, getVideoFileUrl } from './services';
import VideosCache from './VideosCache';

/** VIDEO VIEW */
export interface VideoViewProps {
    projectId: string
    onFileSelected: (filename: string) => void
    onFileDeleted: (filename: string) => void
    file: string
}

const VideoView = (props: VideoViewProps) => {
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [error, setError] = React.useState('');

    const onDeleteFileClick = async () => {
        setIsDeleting(true)
        try {
            await deleteVideoFile(props.projectId, props.file)
            setIsDeleting(false)
            props.onFileDeleted(file)
        } catch (error) {
            setIsDeleting(false)
            setError(error.toString())
        }
    }

    let file = props.file
    return (<div className="d-flex-column align-items-center mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
        {error && <div className="mt-1 mb-1"><Alert color="danger">{error}</Alert></div>}
        <div className="mb-2"><b>{file}</b></div>
        <div className="d-flex align-items-center" >
            <Button className="me-1" outline color="primary" onClick={() => props.onFileSelected(file)}>Selecionar <FontAwesomeIcon icon={faCheckCircle} /></Button>
            <Button className='ms-1' size="sm" outline color="danger" disabled={isDeleting} onClick={() => { onDeleteFileClick() }}><FontAwesomeIcon icon={faTrashAlt} /> {isDeleting ? 'Deletando...' : 'Deletar'}</Button>
        </div>
    </div>)
}

/** SELECT VIDEO COMPONENT */
interface SelectVideoProps {
    projectId: string
    fileSelected: (filename: string) => void
    goBack: () => void
    videosCache: VideosCache
}

interface SelectVideoComponentState {
    isLoading: boolean;
    error: string | undefined;
    files: Array<string>;
}

const INITIAL_STATE: SelectVideoComponentState = {
    isLoading: true,
    error: undefined,
    files: [],
}

export default class SelecVideoComponent extends Component<SelectVideoProps, SelectVideoComponentState> {

    constructor(props: SelectVideoProps) {
        super(props)

        this.state = { ...INITIAL_STATE }
    }

    async componentDidMount() {
        try {
            let files = await getAllVideoFiles(this.props.projectId)
            this.setState({ files, isLoading: false })
        } catch (error) {
            this.setState({ error: error.toString(), isLoading: false })
        }
    }

    onFileSelected(filename: string) {
        this.setState({ isLoading: true, error: undefined })
        let { projectId, videosCache } = this.props
        let videoUrl = getVideoFileUrl(projectId, filename)
        videosCache.getVideoFromDb(videoUrl).then((videoInCache) => {
            if(!videoInCache) {
                videosCache.getVideoBlob(videoUrl).then((blob) => { 
                    videosCache.saveVideoInDb(videoUrl, blob).then(() => {
                        this.props.fileSelected(filename)
                    }).catch((error) => {
                        this.setState({ isLoading: false, error: error.toString() })
                    })
                }).catch((error) => {
                    this.setState({ isLoading: false, error: error.toString() })
                })
            } else this.props.fileSelected(filename)
        })
    }

    render() {
        let { isLoading, error, files } = this.state
        return <Row>
            <Col className="mb-2 mt-2 bg-light p-2 rounded border" md={{ size: 10, offset: 1 }}>
                <Button color="light" size="sm" onClick={this.props.goBack}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
                {error && <Alert color="danger">{error}</Alert>}
                {isLoading && <div className="d-flex flex-column justify-content-center mt-3" style={{ background: '#dbdbdbda', borderRadius: 5, padding: 10 }}>
                    <Spinner color="primary" />
                </div>}
                {!isLoading && files.map((file, index) => {
                    return <VideoView key={`VideoView_${index}`} file={file} onFileSelected={(filename: string) => this.onFileSelected(filename)} onFileDeleted={(deletedFile) => this.setState({ files: [...this.state.files.filter(file => file !== deletedFile)] })} projectId={this.props.projectId} />
                })}
            </Col>
        </Row>
    }
}