import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FileHelper from '../../../../../files/FileHelper';
import { DeckCardTypes, GifCard } from '../../../../../models/DeckCards';
import LoadingScreen from '../../../../loading';
import SelectGifComponent from '../SelectGifComponent';
import ColorPicker from './ColorPicker';
import { PickGifCardProps } from './interfaces';

interface GifCardState {
    text: string;
    textColor: string;
    gif: string;
    isLoading: boolean;
}

const GIF_CARD_INITIAL_STATE: GifCardState = {
    text: '',
    textColor: '#000000',
    gif: 'https://storage.googleapis.com/deafult_media/default_git_1.gif',
    isLoading: false,
}

export default class CreateGifCardComponent extends Component<PickGifCardProps, GifCardState> {

    FileHelper = new FileHelper()

    constructor(props: PickGifCardProps) {
        super(props)

        this.state = { ...GIF_CARD_INITIAL_STATE }

    }

    uploadGif = async (file: File | undefined) => {
        if (!file) return

        try {
            this.props.gifImageGetUpload.uploadCardGif(file, gif => gif && this.onGifSelected(gif))
        } catch (error) {
            this.props.createCard.onError(error)
        }
    }

    onGifSelected = (gif: string) => {
        this.setState({ gif, isLoading: false }, () => this.props.updateIsPickingFile(false))
    }

    createCard = (text: string | undefined, textColor: string, gifUrl: string) => {
        let { subModule, lastPosition } = this.props
        let gifCard = new GifCard({
            id: subModule.id + '_' + lastPosition.toString(),
            type: DeckCardTypes.Gif,
            pos: lastPosition,
            companyId: subModule.companyId,
            moduleId: subModule.moduleId,
            subModuleId: subModule.id,
            text,
            textColor,
            gifUrl
        })
        this.props.createCard.addCard(gifCard)
    }

    isInputValid = (gif: string | undefined) => {
        if (!gif) return false
        return true
    }

    renderForm(text: string, textColor: string, gif: string | undefined) {
        return (<Row>
            <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <div className="d-flex flex-column p-2 rounded mt-3" style={{ margin: 5, background: '#FFFFFFDA' }}>
                    <img style={{ objectFit: 'contain', maxHeight: 200, maxWidth: 3000, margin: 5 }} src={gif} alt="Foto do card" />
                    <Button outline style={{ margin: 5, alignSelf: 'center' }} color="secondary" onClick={() => this.props.updateIsPickingFile(true)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faImage} />Trocar Gif</Button>
                    <Form className="mt-2">
                        <FormGroup>
                            <Input style={{ color: textColor, fontWeight: 'bold' }} className="text-center" name="title" id="cardText" placeholder="Texto do card" value={text} onChange={(event: any) => this.setState({ text: event.target.value })} />
                        </FormGroup>
                    </Form>
                    <div className="d-flex flex-column align-items-center">
                        <ColorPicker color={textColor} onColorUpdate={(textColor: string) => this.setState({ textColor })} />
                    </div>
                </div>
            </Col>
            <Col className="d-flex flex-column justify-content-center relative mb-1" md={{ size: 6, offset: 3 }}>
                <Button style={{ margin: 5 }} disabled={!this.isInputValid(gif)} color="primary" onClick={() => this.createCard(text, textColor, gif!)}>Adicionar</Button>
            </Col>
        </Row>)
    }

    render() {
        let { isLoading, gif, text, textColor } = this.state
        if (isLoading) { return <LoadingScreen /> }
        if (!this.props.isPickingFile) return this.renderForm(text, textColor, gif)
        else return (<SelectGifComponent getCardGifs={this.props.gifImageGetUpload.getCardGifs} uploadFile={this.uploadGif} onGifSelected={this.onGifSelected} goBackToEdit={() => this.props.updateIsPickingFile(false)} />)
    }
}