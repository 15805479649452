export class SubModule {
    id: string
    companyId: string
    title: string
    pic: string
    moduleId: string
    pos: number
    visible: boolean
    type: string
    time: number | undefined
    maxScore: number
    externalResourceUrl: string | undefined
    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.title = data.title
        this.pic = data.pic
        this.moduleId = data.moduleId
        this.pos = data.pos
        this.visible = data.visible
        this.type = data.type
        this.time = data.time
        this.maxScore = data.maxScore
        this.externalResourceUrl = data.externalResourceUrl
    }
}

export class SubModuleQuiz extends SubModule {
    mistakesAllowed: boolean
    constructor(data: any) {
        super(data)
        this.mistakesAllowed = data.mistakesAllowed || false
    }
}

// Apesar do nome não é uma estância de SubModule, e sim uma classe independente
// Cazzo, preciso encontrar uma nomenclatura melhor pra essas estâncias
export class SubModuleVideo {
    id: string
    companyId: string
    moduleId: string
    subModuleId: string
    videoUrl: string

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
        this.videoUrl = data.videoUrl
    }
}

/*export class SubModuleLink {
    id: string
    companyId: string
    moduleId: string
    subModuleId: string
    url: string

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
        this.url = data.url
    }
}*/

export enum SubModuleTypes {
    DECK = 'deck',
    QUIZ = 'quiz',
    VIDEO = 'video',
    LINK = 'link',
    LIVE = 'live',
    FILE = 'file'
}

export default function getSubModule(data: any): SubModule {
    if(data.type === SubModuleTypes.QUIZ) {
        return new SubModuleQuiz(data)
    } else {
        return new SubModule(data)
    }
}