export default class UserAccessValidation {
    primaryProperty: string
    primaryPropertyValue: string
    authData?: any
    name?: string
    phone?: string

    constructor(data: any) {
        this.primaryProperty = data.primaryProperty
        this.primaryPropertyValue = data.primaryPropertyValue
        if(data.authData) this.authData = data.authData
        if(data.name) this.name = data.name
        if(data.phone) this.phone = data.phone
    }

    getData(): any {
        let data: any = { primaryProperty: this.primaryProperty, primaryPropertyValue: this.primaryPropertyValue }
        if(this.authData) data.authData = this.authData
        if(this.name) data.name = this.name
        if(this.phone) data.phone = this.phone
        return data
    }
}