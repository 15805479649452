import React, { Component, Fragment } from "react"
import { Alert, Button, Col, Container, Row } from "reactstrap"
import CardImage from "../../../../../models/CardImage"
import DeckCard, { DeckCardTypes, DescriptionCard, GifCard, InfoCard, MemoryCard, OrderedListCard, QuestionCard, SelectAnswerCard, WordSearchCard } from "../../../../../models/DeckCards"
import Zoom from 'react-medium-image-zoom'
import Module from "../../../../../models/Module"
import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TokenHelper from "../../../../../auth/TokenHelper"
import { SubModule } from "../../../../../models/SubModule"
import AdminContentService from "../../../../../services/admin/adminContentService"
import config from "../../../../../config"
import CardGif from "../../../../../models/CardGif"
import EditInfoCardComponent from "./EditInfoCardComponent"
import { IEditCard } from "./interfaces"
import { IGifGetUpload, IImageGetUpload } from "../CreateDeckCard/interfaces"
import GameSvg from '../../../../../images/game.svg'
import ImageSvg from '../../../../../images/image.svg'
import EditGifCardComponent from "./EditGifCardComponent"
import EditDescriptionCardComponent from "./EditDescriptionCardComponent"
import EditQuestionCardComponent from "./EditQuestionCardComponent"
import EditSelectAnswerCardComponent from "./EditSelectAnswerCardComponent"
import EditMemoryCardComponent from "./EditMemoryCardComponent"
import EditWordSearchComponent from "./EditWordSearchComponent"
import EditOrderedListCardComponent from "./EditOrderedListCardComponent"

// Card Gifs
import InfoCardGif from "../../../../../images/CardGifs/InfoCard.gif";
import GifCardGif from "../../../../../images/CardGifs/GifCard.gif";
import DescriptionCardGif from "../../../../../images/CardGifs/DescriptionCard.gif";
import WordSearchCardGif from "../../../../../images/CardGifs/WordSearchCard.gif";
import MemoryGameCardGif from "../../../../../images/CardGifs/MemoryGameCard.gif";
import QuestionCardGif from "../../../../../images/CardGifs/QuestionCard.gif";
import SelectAnswerCardGif from "../../../../../images/CardGifs/SelectAnswerCard.gif";
import OrderedListCardGif from "../../../../../images/CardGifs/OrderedListCard.gif";

interface Props {
    subModule: SubModule,
    card: DeckCard,
    editCard: (card: DeckCard) => void
    exit: () => void
}

interface State {
    error: any | undefined;
    isPickingFile: boolean;
    showPreview: boolean,
}

const INITIAL_STATE: State = {
    error: undefined,
    isPickingFile: false,
    showPreview: true,
};

export default class EditDeckCardComponent extends Component<Props, State> implements IEditCard, IImageGetUpload, IGifGetUpload {

    constructor(props: Props) {
        super(props)

        this.state = { ...INITIAL_STATE }

    }

    onExit = () => {
        if (this.state.isPickingFile) {
            this.setState({ isPickingFile: false })
        } else {
            this.props.exit()
        }
    }

    editCard = (card: DeckCard) => this.props.editCard(card)

    onError = (error: any) => this.setState({ error })

    uploadCardImage = async (imageBlob: Blob, imageUploadCallback: (imageUrl: string | undefined) => void) => {
        let tokenHelper = new TokenHelper()
        let subModule = this.props.subModule
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let formData = new FormData()
            formData.append('file', imageBlob)
            let adminContentService = new AdminContentService()
            let cardImage = await adminContentService.uploadCardImage(token, config.endpoint, formData, subModule.moduleId, subModule.id)
            imageUploadCallback(cardImage.imageUrl)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.uploadCardImage(imageBlob, imageUploadCallback)
            } else {
                imageUploadCallback(undefined)
                this.setState({ error })
            }
        }
    }

    uploadCardGif = async (gifFile: File, gifUploadCallback: (gifUrl: string | undefined) => void) => {
        let tokenHelper = new TokenHelper()
        let subModule = this.props.subModule
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let formData = new FormData()
            formData.append('file', gifFile)
            let adminContentService = new AdminContentService()
            let cardGif = await adminContentService.uploadCardGif(token, config.endpoint, formData, subModule.moduleId, subModule.id)
            gifUploadCallback(cardGif.gifUrl)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.uploadCardImage(gifFile, gifUploadCallback)
            } else {
                gifUploadCallback(undefined)
                this.setState({ error })
            }
        }
    }

    getCardImages = async (callback: (modules: Array<Module>, cardImages: Array<CardImage>) => void) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let { modules, cardImages } = await adminContentService.getCardImages(token, config.endpoint)
            callback(modules, cardImages)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.getCardImages(callback)
            } else {
                this.setState({ error })
            }
        }
    }

    getCardGifs = async (callback: (modules: Array<Module>, cardGifs: Array<CardGif>) => void) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let { modules, cardGifs } = await adminContentService.getCardGifs(token, config.endpoint)
            callback(modules, cardGifs)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.getCardGifs(callback)
            } else {
                this.setState({ error })
            }
        }
    }

    renderError(error: any) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                    {error.toString()}
                </Alert>
            </Col>
        </Row>
        );
    }

    renderFormForCard(card: DeckCard, isPickingFile: boolean) {
        if (card instanceof InfoCard) return (<EditInfoCardComponent card={card as InfoCard} editCardInterface={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} />)
        if (card instanceof GifCard) return (<EditGifCardComponent card={card as GifCard} editCardInterface={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} gifImageGetUpload={this} />)
        else if (card instanceof DescriptionCard) return (<EditDescriptionCardComponent card={card as DescriptionCard} editCardInterface={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} />)
        else if (card instanceof QuestionCard) return (<EditQuestionCardComponent card={card as QuestionCard} editCardInterface={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} />)
        else if (card instanceof SelectAnswerCard) return (<EditSelectAnswerCardComponent card={card as SelectAnswerCard} editCardInterface={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} />)
        else if (card instanceof MemoryCard) return (<EditMemoryCardComponent card={card as MemoryCard} editCardInterface={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} />)
        else if (card instanceof WordSearchCard) return (<EditWordSearchComponent card={card as WordSearchCard} editCardInterface={this} />)
        else if (card instanceof OrderedListCard) return (<EditOrderedListCardComponent card={card as OrderedListCard} editCardInterface={this} />)
        else return (<div></div>)
    }

    renderTypeInfo(type: string, showPreview: boolean) {
        var templateGif = InfoCardGif
        var feedbackText = "Card com texto explicativo e uma imagem."
        var cardTypeValue = "Texto e Imagem"
        if (type === DeckCardTypes.Gif) {
            templateGif = GifCardGif
            feedbackText = "Card com um texto explicativo e um GIF."
            cardTypeValue = "Texto e Gif"
        } else if (type === DeckCardTypes.Description) {
            templateGif = DescriptionCardGif
            feedbackText = "Card com título, imagem e textos secundários."
            cardTypeValue = "Título, Textos e Imagem"
        } else if (type === DeckCardTypes.WordSearch) {
            templateGif = WordSearchCardGif
            feedbackText = "O usuário precisa achar uma palavra ( até 10 caracteres ) selecionando as letras em sequência horizontal ou vertical."
            cardTypeValue = "Caça-Palavras"
        } else if (type === DeckCardTypes.Memory) {
            templateGif = MemoryGameCardGif
            feedbackText = "O usuário precisa achar os cards com imagens e texto iguais. Quanto menos ele erra, mais pontos ganha."
            cardTypeValue = "Jogo da Memória"
        } else if (type === DeckCardTypes.Question) {
            templateGif = QuestionCardGif
            feedbackText = "Pergunta com apenas uma resposta correta. Você pode colocar imagem na pergunta, nas respostas e uma imagem final de explicação caso erre."
            cardTypeValue = "Acertar Resposta"
        } else if (type === DeckCardTypes.SelectAnswer) {
            templateGif = SelectAnswerCardGif
            feedbackText = "Pergunta que pode selecionar múltiplas opções. Você pode colocar imagem na pergunta, nas opções e uma imagem final de explicação caso erre."
            cardTypeValue = "Selecionar Opções"
        } else if (type === DeckCardTypes.OrderedList) {
            templateGif = OrderedListCardGif
            feedbackText = "Lista de etapas que aparecem em ordem aleatória e o usuário precisa ordenar na ordem correta."
            cardTypeValue = "Passo-a-Passo"
        }
        return (<Row>
            <Col className="d-flex flex-column justify-content-center relative pt-3" md={{ size: 6, offset: 3 }}>
                <div className='d-flex flex-column ml-2'>
                    <div style={{ color: '#1d3256', verticalAlign: 'middle' }}><b>{cardTypeValue}</b></div>
                    <span style={{ color: 'black' }}>{feedbackText}</span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ margin: 5, background: 'white', borderRadius: '0.25rem', paddingTop: showPreview ? 10 : 5, paddingBottom: 5, borderColor: '#ced4da', borderStyle: 'solid', borderWidth: 'thin' }}>
                    <div className="cursorPointer" style={{ marginLeft: 5, marginRight: 5, borderStyle: 'none' }} color="secondary" onClick={() => this.setState({ showPreview: !showPreview })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={showPreview ? faEyeSlash : faEye} />{showPreview ? 'Esconder Template' : 'Mostrar Template'}</div>
                    {showPreview && <Zoom><img src={templateGif} alt='Gif do template' style={{ height: 300, width: 190, objectFit: 'contain' }} /></Zoom>}
                </div>
            </Col>
        </Row>)
    }

    render() {
        let { error, isPickingFile, showPreview } = this.state
        return (<Fragment>
            <Row className="pt-2 pb-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                <Col md={{ size: 6, offset: 3 }}>
                    <div style={{ display: 'flex', alignItems: 'center', minHeight: '4em' }}>
                        <Button color='none' outline onClick={() => this.onExit()}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} src={isPickingFile ? ImageSvg : GameSvg} />
                        <div className='d-flex flex-column ml-2'>
                            <div style={{ color: '#1d3256', verticalAlign: 'middle' }}><b>{isPickingFile ? 'Selecionar Imagem' : 'Editar Card'}</b></div>
                            <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', verticalAlign: 'middle' }}>{this.props.subModule.title}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ overflow: 'auto' }}>
                <Col>
                    <Container fluid>
                        {error && this.renderError(error)}
                        {!isPickingFile && this.renderTypeInfo(this.props.card.type, showPreview)}
                        {this.renderFormForCard(this.props.card, isPickingFile)}
                    </Container>
                </Col>
            </Row>
        </Fragment>)
    }

}