export const POINTS_PER_ANSWER_DECK = 10
export const POINTS_PER_ORDERED_LIST_DECK = 10
export const POINTS_PER_MEMORY_GAME = 30
export const POINTS_PER_WORD_GUESS = 25
export const POINTS_PER_QUIZ_QUESTION = 45
export const POINTS_PER_VIDEO = 25
export const POINTS_PER_IMAGE_ERROR = 45

export const MEMORY_GAME_MAX_ATTEMPTS = 25
export const WORD_GUESS_MAX_ATTEMPTS = 25
export const IMAGE_ERRORS_MAX_ATTEMPTS = 45