import React, { useState } from "react";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Select from 'react-select';
import _ from 'lodash'

import Module from "../../../models/Module";

interface SelectModuleProps {
    selectedModule: Module | undefined
    modules: Array<Module>
    newModuleSelected: (module: Module | undefined) => void
}

export const SelectedModuleDropdown = (props: SelectModuleProps) => {
    let { selectedModule, modules, newModuleSelected } = props
    let selectedTitle = selectedModule ? selectedModule.title : 'Todas as Missões'
    let options = [{ label: 'Todas as Missões', value: '-all' }, ...modules.map(module => { return { label: module.title, value: module.id } })]
    return <div style={{ width: '100%' }}><Select placeholder={selectedTitle.length > 45 ? selectedTitle.substring(0, 45) + '...' : selectedTitle} options={options} onChange={
            opt => {
                let module = _.first(modules.filter(module => module.id === opt!.value))
                newModuleSelected(module)
            }
        } /></div>
}

interface FiltersDropdownProps {
    selectedYear: number
    newYearSelected: (year: number) => void
    selectedModule: Module | undefined
    modules: Array<Module>
    newModuleSelected: (module: Module | undefined) => void
}

export const FiltersDropdown = (props: FiltersDropdownProps) => {
    let { selectedYear, newYearSelected, selectedModule, modules, newModuleSelected } = props
    const [isSelectYearDropdownOpen, setIsSelectYearDropdownOpen] = useState(false);
    let currentYear = (new Date()).getFullYear()
    return (<Col className="d-flex" md={{ size: 6, offset: 2 }}>
        <Dropdown style={{ marginRight: 5 }} isOpen={isSelectYearDropdownOpen} toggle={() => setIsSelectYearDropdownOpen(!isSelectYearDropdownOpen)}>
            <DropdownToggle caret>
                {selectedYear.toString()}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => newYearSelected(currentYear)}>{currentYear.toString()}</DropdownItem>
                <DropdownItem onClick={() => newYearSelected(currentYear - 1)}>{(currentYear - 1).toString()}</DropdownItem>
                <DropdownItem onClick={() => newYearSelected(currentYear - 2)}>{(currentYear - 2).toString()}</DropdownItem>
                <DropdownItem onClick={() => newYearSelected(currentYear - 3)}>{(currentYear - 3).toString()}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
        {modules && <SelectedModuleDropdown selectedModule={selectedModule} modules={modules} newModuleSelected={newModuleSelected} />}
    </Col>)

}