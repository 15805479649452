const dev = {
    endpoint: 'http://localhost:3000',
    isProduction: false,
    socket: 'http://localhost:3000',
    livestream: 'https://lira-game.web.app'
}

const prod = {
    endpoint: 'https://api.resumeai.site',
    //endpoint: 'https://lira-game.ue.r.appspot.com',
    isProduction: true,
    socket: 'https://liraedu.com',
    livestream: 'https://lira-game.web.app'
}

const config = process.env.REACT_APP_STAGE === 'dev' ? dev : prod;

export default config