import QuizQuestion, { DefaultQuestion, QuizQuestionType, getQuizQuestion, MemoryQuestion, SelectAnswerQuestion, WordGuessQuestion } from "./QuizQuestion"
import { QuestionAnswer } from "./Question"

export class QuizQuestionsReport<T extends QuizQuestion> {

    quizQuestion: T
    
    constructor(quizQuestion: T) {
        this.quizQuestion = quizQuestion
    }

    getData(): any {
        return {
            quizQuestion: this.quizQuestion.getData(),
        }
    }
}

export class DefaultQuestionReport extends QuizQuestionsReport<DefaultQuestion> {
    questionAnswer: QuestionAnswer
    timeToAnswer?: number

    constructor(defaultQuestion: DefaultQuestion) {
        super(defaultQuestion)
        this.questionAnswer = QuestionAnswer.NotAnswered
    }

    getData(): any {
        let data = {
            ...super.getData(),
            questionAnswer: this.questionAnswer
        }
        if(this.timeToAnswer !== undefined) data.timeToAnswer = this.timeToAnswer
        return data
    }
}

export class MemoryQuestionReport extends QuizQuestionsReport<MemoryQuestion> {
    timeToAnswer?: number
    allImagesFound: boolean
    attempts: number

    constructor(memoryQuestion: MemoryQuestion) {
        super(memoryQuestion)
        this.attempts = 0
        this.allImagesFound = false
    }

    getData(): any {
        let data = {
            ...super.getData(),
            attempts: this.attempts,
            allImagesFound: this.allImagesFound,
        }
        if(this.timeToAnswer !== undefined) data.timeToAnswer = this.timeToAnswer
        return data
    }
}

export class WordGuessQuestionReport extends QuizQuestionsReport<WordGuessQuestion> {
    timeToAnswer?: number
    wordFound: boolean
    attempts: number

    constructor(wordGuessQuestion: WordGuessQuestion) {
        super(wordGuessQuestion)
        this.attempts = 0
        this.wordFound = false
    }

    getData(): any {
        let data = {
            ...super.getData(),
            attempts: this.attempts,
            wordFound: this.wordFound,
        }
        if(this.timeToAnswer !== undefined) data.timeToAnswer = this.timeToAnswer
        return data
    }
}

export class SelectAnswerQuestionReport extends QuizQuestionsReport<SelectAnswerQuestion> {
    timeToAnswer?: number
    questionAnswer: QuestionAnswer
    selectedAnswers: Array<number>

    constructor(selectAnswerQuestion: SelectAnswerQuestion) {
        super(selectAnswerQuestion)
        this.questionAnswer = QuestionAnswer.NotAnswered
        this.selectedAnswers = []
    }

    getData(): any {
        let data = {
            ...super.getData(),
            questionAnswer: this.questionAnswer,
            selectedAnswers: this.selectedAnswers
        }
        if(this.timeToAnswer !== undefined) data.timeToAnswer = this.timeToAnswer
        return data
    }
}

export function getQuizQuestionReportArray(dataArray: Array<any>): Array<QuizQuestionsReport<QuizQuestion>> {
    let array: Array<QuizQuestionsReport<QuizQuestion>> = []
    for(let i = 0; i < dataArray.length; i++) {
        let data = dataArray[i]
        let type = data.quizQuestion.type
        if(type === QuizQuestionType.Default) {
            let questionCard = getQuizQuestion(data.quizQuestion) as DefaultQuestion
            let questionCardReport = new DefaultQuestionReport(questionCard)
            questionCardReport.questionAnswer = data.questionAnswer
            questionCardReport.timeToAnswer = data.timeToAnswer
            array.push(questionCardReport)
        } else if(type === QuizQuestionType.MemoryGame) {
            let memoryCard = getQuizQuestion(data.quizQuestion) as MemoryQuestion
            let memoryQuestionReport = new MemoryQuestionReport(memoryCard)
            memoryQuestionReport.timeToAnswer = data.timeToAnswer
            memoryQuestionReport.attempts = data.attempts
            memoryQuestionReport.allImagesFound = data.allImagesFound
            array.push(memoryQuestionReport)
        } else if(type === QuizQuestionType.WordGuess) {
            let wordGuessCard = getQuizQuestion(data.quizQuestion) as WordGuessQuestion
            let wordGuessQuestionReport = new WordGuessQuestionReport(wordGuessCard)
            wordGuessQuestionReport.timeToAnswer = data.timeToAnswer
            wordGuessQuestionReport.attempts = data.attempts
            wordGuessQuestionReport.wordFound = data.wordFound
            array.push(wordGuessQuestionReport)
        } else if(type === QuizQuestionType.SelectAnswer) {
            let selectAnswerCard = getQuizQuestion(data.quizQuestion) as SelectAnswerQuestion
            let selectAnswerCardReport = new SelectAnswerQuestionReport(selectAnswerCard)
            selectAnswerCardReport.timeToAnswer = data.timeToAnswer
            selectAnswerCardReport.questionAnswer = data.questionAnswer
            selectAnswerCardReport.selectedAnswers = data.selectedAnswers
            array.push(selectAnswerCardReport)
        } else {
            let question = getQuizQuestion(data.quizQuestion)
            let deckCardReport = new QuizQuestionsReport(question)
            array.push(deckCardReport)
        }
    }
    return array
}