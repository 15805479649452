import React, { Component } from 'react';
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faArrowRight, faListOl, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingScreen from '../loading';
import { SubModule } from '../../models/SubModule';
import TokenHelper from '../../auth/TokenHelper';
import ContentService from '../../services/contentService';
import config from '../../config'

interface State {
    screenWidth: number
    screenHeight: number
    hasFinished: boolean
    error: string | undefined;
    isLoading: boolean
    subModule: SubModule | undefined
    nextSubModule: SubModule | undefined
    showNext: boolean
    retry: (() => void) | undefined
};

const INITIAL_STATE: State = {
    screenWidth: 0,
    screenHeight: 0,
    hasFinished: false,
    error: undefined,
    isLoading: false,
    subModule: undefined,
    nextSubModule: undefined,
    showNext: false,
    retry: undefined
}

interface Props { videoUrl: string, subModuleId: string, onExit: (moduleId: string | undefined) => void, onFinish: (startTime: number, endTime: number) => void, companyColor: string | undefined, goToRanking: (moduleId: string) => void, goToNext: (subModule: SubModule) => void, companyPic: string | undefined }

export default class VideoScreen extends Component<Props, State> {

    startTime: number
    endTime: number | undefined

    constructor(props: Props) {
        super(props)
        this.startTime = (new Date()).getTime()
        this.state = { ...INITIAL_STATE }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.loadSubModule(this.props.subModuleId)
    }

    loadSubModule = async (subModuleId: string) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let { subModule, nextSubModule } = await contentService.getSubModuleAndNext(token, config.endpoint, subModuleId)
            this.setState({ subModule, nextSubModule, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadSubModule(subModuleId)
            } else {
                this.setState({ isLoading: false, error: error.toString(), retry: () => { this.loadSubModule(subModuleId) } })
            }
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth, screenHeight: window.innerHeight });
    }

    onProgress = ({ played }: { played: number }) => {
        if (played > 0.8 && !this.state.hasFinished) {
            this.setState({ hasFinished: true, showNext: true })
            this.endTime = (new Date()).getTime()
            this.props.onFinish(this.startTime, this.endTime)
        }
    }

    renderVideoPlayer(videoUrl: string, screenWidth: number, screenHeight: number) {


        let fileType = videoUrl.substring(videoUrl.length - 4)
        if (fileType === '.mp3') {
            return <ReactAudioPlayer
                onEnded={() => {
                    this.setState({ hasFinished: true, showNext: true })
                    this.endTime = (new Date()).getTime()
                    this.props.onFinish(this.startTime, this.endTime)
                }}
                src={videoUrl}
                autoPlay
                controls
            />
        } else {
            var width = '100%', height = 'auto'
            if (!screenHeight && !screenWidth) return <div></div>
            if (screenHeight < screenWidth) { width = '100%'; height = '70vh' }
            return <ReactPlayer onProgress={this.onProgress} width={width} height={height} url={videoUrl} controls config={{
                file: {
                    attributes: {
                        controlsList: 'nodownload'
                    }
                }
            }} />
        }
    }

    goToRanking = (moduleId: string) => {
        this.props.goToRanking(moduleId)
    }

    renderHeader(moduleId: string | undefined, title: string) {
        return (<Row style={{ background: 'white', boxShadow: '2px 1px 2px 1px rgba(0,0,0,0.2)' }}>
            <Col className='d-flex align-items-center' style={{ paddingTop: 10, paddingBottom: 10, minHeight: '4em' }} md={{ size: 8, offset: 2 }}>
                <div style={{ alignSelf: 'center' }}>
                    <Button color='none' outline onClick={() => { this.props.onExit(moduleId) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                </div>
                <div className='d-flex flex-column' style={{ marginLeft: 5 }}>
                    <div style={{ fontFamily: 'Montserrat', color: '#353f45' }}><b>Midia</b></div>
                    <div style={{ fontFamily: 'Montserrat', color: '#353f45' }}>{title}</div>
                </div>
            </Col>
        </Row>)
    }

    renderErrorAndRetry(error: string, retry: () => void) {
        return (<Container className='d-flex flex-column' fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                    <div style={{ alignSelf: 'center', marginTop: 10 }}>
                        <Button color='none' outline onClick={() => { retry() }}><FontAwesomeIcon color='#343a40' icon={faRedo} size='2x' />Tentar Novamente</Button>
                    </div>
                </Col>
            </Row>
        </Container>)
    }

    renderRanking(moduleId: string) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 10, marginBottom: 5, width: '100%' }}>
                    <Button style={{ padding: 5, width: '80%', borderStyle: 'none' }} onClick={() => { this.goToRanking(moduleId) }}><FontAwesomeIcon icon={faListOl} /> Ver Ranking</Button>
                </div>
            </Col>
        </Row>)
    }

    renderNext(subModule: SubModule) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 15, width: '100%' }}>
                    <Button style={{ padding: 10, width: '100%', background: '#1d3256' }} onClick={() => {
                        this.props.goToNext(subModule)
                    }}>Próximo<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowRight} /></Button>
                </div>
            </Col>
        </Row>)
    }

    render() {
        let { screenWidth, screenHeight, error, retry, isLoading, subModule, nextSubModule, showNext } = this.state

        if (isLoading) { return <LoadingScreen image={this.props.companyPic} /> }
        if (error && retry) {
            return this.renderErrorAndRetry(error, retry)
        }

        return (<Container fluid style={{ minHeight: '100vh' }}>
            {this.renderHeader(subModule ? subModule.moduleId : undefined, subModule ? subModule.title : '')}
            <Row>
                <Col style={{ marginTop: 10 }} className="d-flex flex-column align-items-center">
                    {this.renderVideoPlayer(this.props.videoUrl, screenWidth, screenHeight)}
                </Col>
            </Row>
            {nextSubModule && showNext && this.renderNext(nextSubModule)}
        </Container>)
    }

}