import React, { Component, CSSProperties, Fragment } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { MEMORY_GAME_MAX_ATTEMPTS } from '../constants/ScoreConstants'

interface State {
    imagesFound: Array<string>
    selectedImages: Array<number | undefined>
    attempts: number
}

const INTIAL_STATE: State = {
    imagesFound: [],
    selectedImages: Array<number | undefined>(2).fill(undefined),
    attempts: 0
}

interface Props { title: string | undefined, images: Array<{ imgUrl: string, text: string }>, screenSize: string, onMemoryGameFinished: (attempts: number) => void, onExit: () => void, showExit: boolean, noGutters?: boolean }

export default class MemoryGameComponent extends Component<Props, State> {

    imagesRandomizedArray: Array<{ imgUrl: string, text: string }>

    constructor(props: Props) {
        super(props)
        // é necessário repetir cada imagens no jogo da memória
        this.imagesRandomizedArray = this.shuffle([...this.props.images, ...this.props.images])
        this.state = { ...INTIAL_STATE }
    }

    // Bem, aparentemente mudar o state do componente Parent não necessariamente
    // faz o state do componente Child ser atualizado.
    // https://stackoverflow.com/questions/48502546/reactjs-child-not-updating-after-parent-state-changed
    componentWillReceiveProps(nextProps: Props) {
        // essa funcao vai ser chamada toda vez que o timer for atualizado
        // portanto é necessario verificar se o card foi trocado
        if (nextProps.title !== this.props.title) {
            this.imagesRandomizedArray = this.shuffle([...nextProps.images, ...nextProps.images])
            this.setState({ ...INTIAL_STATE });
        }
        // Meu deus, é gambiarra em cima de gambiarra
    }

    shuffle = (array: Array<{ imgUrl: string, text: string }>) => {
        var currentIndex = array.length, temporaryValue = array[0], randomIndex = 0;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    onImageClick = (index: number, imagesFound: Array<string>, selectedImages: Array<number | undefined>, attempts: number) => {
        // Selecionou primeira imagem
        if (selectedImages[0] === undefined) this.setState({ selectedImages: [index, undefined] })
        // Selecionou segunda imagem
        else if (selectedImages[1] === undefined) {
            // Verifica se o usuário não clicou na mesma imagem
            if (selectedImages[0] === index) return
            // pega a primeira imagem
            let firstImage = this.imagesRandomizedArray[selectedImages[0]!]
            // pega a segunda imagem
            let secondImage = this.imagesRandomizedArray[index]
            // ve se a primeira é igual a segunda
            if (firstImage.imgUrl === secondImage.imgUrl) {
                // se elas forem iguals deve atualizar o array de imagens encontradas
                let newImagesFound = [...imagesFound, firstImage.imgUrl]
                // verifica se o jogo acabou
                if (newImagesFound.length === this.imagesRandomizedArray.length / 2) {
                    // atualiza as imagens encontradas e imagens selecionadas e termina o jogo
                    this.setState({ imagesFound: newImagesFound, selectedImages: [undefined, undefined] }, () => this.props.onMemoryGameFinished(attempts))
                } else {
                    // atualiza as imagens encontradas e imagens selecionadas
                    this.setState({ imagesFound: newImagesFound, selectedImages: [undefined, undefined] })
                }
            } else {
                let currentAttempts = attempts + 1
                if (currentAttempts >= MEMORY_GAME_MAX_ATTEMPTS) {
                    this.props.onExit()
                } else {
                    this.setState({ selectedImages: [selectedImages[0], index], attempts: attempts + 1 })
                }
            }
        }
        // Tinha selecionado duas ja e agora comecou a selecao novamente
        else {
            this.setState({ selectedImages: [index, undefined] })
        }
    }

    getCardDivHeight(screenSize: string) {
        if (screenSize === 'big') return 250
        else if (screenSize === 'medium') return 270
        else return 300
    }

    getCardImageHeight(screenSize: string, textSize: number) {
        if (screenSize === 'big') return 120
        else if (screenSize === 'medium') return textSize > 55 ? 100 : 125
        else return textSize > 55 ? 75 : 125
    }

    getFontSize(screenSize: string, textSize: number) {
        if (screenSize === 'big') return textSize > 55 ? '0.8em' : '1em'
        else return textSize > 55 ? '0.6em' : '0.8em'
        //return '0.8em'
    }

    renderImage(index: number, imagesFound: Array<string>, selectedImages: Array<number | undefined>, imgUrl: string, text: string, attempts: number) {
        // 1: verifica se a imagem ja foi encontrada
        let isFoundImage = imagesFound.filter(img => img === imgUrl).length > 0

        let onClick = () => { this.onImageClick(index, imagesFound, selectedImages, attempts) }
        let imageHeight = this.getCardImageHeight(this.props.screenSize, text.length)
        let height = this.getCardDivHeight(this.props.screenSize)
        let fontSize = this.getFontSize(this.props.screenSize, text.length)

        if (isFoundImage) {
            return (<Col key={index} xs="6" md="4" lg="3">
                <div style={{
                    ...defaultStyle, boxShadow: '2px 4px 8px 2px rgba(0,200,0,0.5)', height
                }} onClick={onClick}>
                    <img alt='carta jogo da memória' src={imgUrl} style={{ height: imageHeight, width: '100%', objectFit: 'contain' }} />
                    <div style={{ fontSize, fontFamily: 'Montserrat', textAlign: 'center', marginTop: 10 }}>{text}</div>
                </div>
            </Col>)
        }

        // 2: verifica se a imagem foi selecionada
        let isSelectedImage = selectedImages[0] === index || selectedImages[1] === index

        if (isSelectedImage) {
            return (<Col key={index} xs="6" md="4" lg="3">
                <div style={{
                    ...defaultStyle, height,
                    boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
                }} onClick={onClick}>
                    <img alt='carta jogo da memória' src={imgUrl} style={{ height: imageHeight, width: '100%', objectFit: 'contain' }} />
                    <div style={{ fontSize, fontFamily: 'Montserrat', textAlign: 'center', marginTop: 10 }}>{text}</div>
                </div>
            </Col>)
        }

        return (<Col key={index} xs="6" md="4" lg="3">
            <div style={{
                ...defaultStyle, height,
                boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
            }} onClick={onClick}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1em', fontFamily: 'Montserrat', borderStyle: 'solid', width: 60, height: 60, borderRadius: 30, borderWidth: 'thin' }}><b>{index}</b></div>
            </div>
        </Col>)

    }

    render() {

        let { imagesFound, selectedImages, attempts } = this.state

        return (<Fragment>
            <Row className={this.props.noGutters ? 'no-gutters' : ''} style={{ background: '#FFFFFFDA', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.2)', paddingTop: 5, paddingBottom: 5 }}>
                <Col className='d-flex justify-content-start align-items-center' style={{ minHeight: '4em' }} md={{ size: 8, offset: 2 }}>
                    {this.props.showExit && <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#343a40' icon={faArrowLeft} size='2x' /></Button>}
                    <div className='d-flex flex-column justify-content-center align-items-start' >
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 16 }}>Jogo da Memória</div>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 12 }}>Encontre as cartas com texto e imagem iguais</div>
                    </div>
                </Col>
            </Row>
            <Row className={this.props.noGutters ? 'no-gutters' : ''} style={{ flex: 1, overflow: 'auto' }}>
                {this.props.title && <Col className='d-flex justify-content-center align-items-center mt-2' md={{ size: 8, offset: 2 }}>
                    <div style={{ fontFamily: 'Montserrat', textAlign: 'center', marginLeft: this.props.noGutters ? 5 : 0, marginRight: this.props.noGutters ? 5 : 0 }}><b>{this.props.title}</b></div>
                </Col>}
                <Col className='d-flex justify-content-center align-items-center mt-2 mb-2' md={{ size: 8, offset: 2 }}>
                    <FontAwesomeIcon style={{ marginRight: 5 }} color='red' icon={faTimesCircle} />
                    <div style={{ fontFamily: 'Montserrat' }}>{`Tentativas: ${attempts} de ${MEMORY_GAME_MAX_ATTEMPTS}`}</div>
                </Col>
                {this.imagesRandomizedArray.map(({ imgUrl, text }, index) => this.renderImage(index, imagesFound, selectedImages, imgUrl, text, attempts))}
            </Row>
        </Fragment>)
    }

}

const defaultStyle: CSSProperties = {
    margin: 5, background: '#FFFFFFDA', padding: 5,
    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
    borderRadius: 5
}

