import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';

export interface SearchInputViewProps {
    updateSearchInput: (input: string) => void
    placeholder: string
}

const SearchInputView = (props: SearchInputViewProps) => {
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        const interval = setTimeout(() => {
            props.updateSearchInput(searchInput)
        }, 500)
        return () => clearInterval(interval)
    }, [searchInput])

    return (<Row style={{ marginTop: 5 }}>
        <Col md={{ size: 8, offset: 2 }}>
            <InputGroup>
                <InputGroupText><FontAwesomeIcon icon={faSearch} /></InputGroupText>
                <Input name="title" id="docTitle" placeholder='Procurar usuário' value={searchInput} onChange={(event: any) => {
                    setSearchInput(event.target.value)
                }} />
            </InputGroup>
        </Col>
    </Row>)

}

export default SearchInputView