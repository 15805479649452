export default class CompanyConfig {
    id: string
    companyId: string
    signUpConfig: SignUpConfig
    companyRankingConfig?: { [id: string]: RankingConfig }
    moduleRankingConfig?: { [id: string]: RankingConfig }
    reportConfig?: ReportConfig
    contentConfig?: ContentConfig
    isForumEnabled?: boolean

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.signUpConfig = new SignUpConfig(data.signUpConfig)
        this.isForumEnabled = typeof data.isForumEnabled === 'undefined' ? true : data.isForumEnabled
        if(data.companyRankingConfig) this.companyRankingConfig = this.createRankingConfigMap(data.companyRankingConfig)
        if(data.moduleRankingConfig) this.moduleRankingConfig = this.createRankingConfigMap(data.moduleRankingConfig)
        if(data.reportConfig) this.reportConfig = new ReportConfig(data.reportConfig)
        if(data.contentConfig) this.contentConfig = new ContentConfig(data.contentConfig)
    }

    createRankingConfigMap(data: any): { [id: string]: RankingConfig } {
        let keys = Object.keys(data)
        let result: { [id: string]: RankingConfig } = {}
        for(var i = 0; i < keys.length; i++) {
            let key = keys[i]
            result[key] = new RankingConfig(data[key])
        }
        return result
    }

}

export class SignUpConfig {
    doc?: DocConfig
    email?: EmailConfig
    phone?: PhoneConfig
    enterMessage?: string

    // o cliente sobe um excel com os cps ou e-mails que podem ter acesso. deve-se verificar se o cpf ou e-mail informado
    // pode criar uma conta
    validateUserAccess?: boolean

    // o usuario deve criar uma senha?
    password: boolean

    // doc ou email: se vamos perguntar e-mail ou cpf do usuario na hora de fazer o login
    primaryProperty: string

    // separacao de usuarios
    authData?: { [id: string]: AuthData }

    constructor(data: any) {
        this.primaryProperty = data.primaryProperty
        this.password = data.password
        if(data.doc) this.doc = new DocConfig(data.doc)
        if(data.email) this.email = new EmailConfig(data.email)
        if(data.phone) this.phone = new PhoneConfig(data.phone)
        if(data.validateUserAccess) this.validateUserAccess = data.validateUserAccess
        if(data.enterMessage) this.enterMessage = data.enterMessage

        if(data.authData) {
            this.authData = {}
            for(var key in data.authData) {
                this.authData[key] = new AuthData(data.authData[key])
            }
        }
    }
}

export class PhoneConfig {
    title: string
    placeholder: string
    mask: string
    required: boolean
    constructor(data: any) {
        this.title = data.title
        this.placeholder = data.placeholder
        this.mask = data.mask
        this.required = data.required
    }
}

export class EmailConfig {
    title: string
    placeholder: string
    required: boolean
    constructor(data: any) {
        this.title = data.title
        this.placeholder = data.placeholder
        this.required = data.required
    }
}

export class DocConfig {
    title: string
    placeholder: string
    mask: string
    required: boolean
    constructor(data: any) {
        this.title = data.title
        this.placeholder = data.placeholder
        this.mask = data.mask
        this.required = data.required
    }
}

export class AuthData {
    id: string
    title: string
    placeholder: string
    values: string[]
    show: boolean
    userCanEdit: boolean

    constructor(data: any) {
        this.id = data.id
        this.title = data.title
        this.placeholder = data.placeholder
        this.values = data.values
        this.show = data.show
        this.userCanEdit = data.userCanEdit
    }
}

export class RankingConfig {
    id: string
    pos: number
    title: string
    type: string
    closedSegmentation?: Array<string>
    openSegmentation?: { [id: string]: string }
    period?: string
    dateTiebraker: boolean
    attemptsTiebraker: boolean
    count: number

    constructor(data: any) {
        this.id = data.id
        this.pos = data.pos
        this.title = data.title
        this.type = data.type
        this.closedSegmentation = data.closedSegmentation
        this.openSegmentation = data.openSegmentation
        this.period = data.period
        if(data.dateTiebraker !== undefined) this.dateTiebraker = data.dateTiebraker
        else this.dateTiebraker = false
        if(data.attemptsTiebraker !== undefined) this.attemptsTiebraker = data.attemptsTiebraker
        else this.attemptsTiebraker = false
        if(data.count !== undefined) this.count = data.count
        else this.count = 10
    }
}

export class ReportConfig {
    authData: { [id: string]: { id: string, title: string } }
    showExtraReports: Array<string>
    authDataComplement: boolean
    authDataComplementArray?: Array<AuthDataComplement>
    primaryProperty?: string
    showCreatedDate?: boolean
    showPercentage?: boolean

    constructor(data: any) {
        this.authData = data.authData
        this.showExtraReports = data.showExtraReports || []
        if (data.primaryProperty) this.primaryProperty = data.primaryProperty
        if (data.showCreatedDate !== undefined) this.showCreatedDate = data.showCreatedDate
        if (data.showPercentage !== undefined) this.showPercentage = data.showPercentage
        if (data.authDataComplement === undefined) this.authDataComplement = false
        else this.authDataComplement = data.authDataComplement
        if (data.authDataComplementArray) this.authDataComplementArray = data.authDataComplementArray.map((data: any) => { return new AuthDataComplement(data) })
    }
}

export class AuthDataComplement {
    id: string
    companyId: string
    title: string
    authDataId: string
    authDataComplementValues: { [id: string]: string }
    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.title = data.title
        this.authDataId = data.authDataId
        this.authDataComplementValues = data.authDataComplementValues
    }
}

export class ContentConfig {
    moduleCategories?: Array<{ id: string, title: string }>
    constructor(data: any) {
        if(data.moduleCategories) this.moduleCategories = data.moduleCategories
    }
}