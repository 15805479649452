import axios from 'axios'
import { LastSmsSent, SmsReceived, getSMS, SMS } from '../../models/SMS'

export default class AdminSmsService {

    sendLinkSMS = async (token: string, mainUrl: string, userPhone: string): Promise<void> => {
        let url = `${mainUrl}/admin/sms/sendSMSLink`
        await axios.post(url, { userPhone }, { headers: { 'Authorization': `Token ${token}` } })
    }

    sendLinkWhatsApp = async (token: string, mainUrl: string, userPhone: string): Promise<void> => {
        let url = `${mainUrl}/admin/sms/sendWhatsAppLink`
        await axios.post(url, { userPhone }, { headers: { 'Authorization': `Token ${token}` } })
    }

    sendWhatsAppMessage = async (token: string, mainUrl: string, userPhones: Array<string>, message: string): Promise<Array<LastSmsSent>> => {
        let url = `${mainUrl}/admin/sms/sendWhatsAppMessage`
        let result = await axios.post(url, { userPhones, message }, { headers: { 'Authorization': `Token ${token}` } })
        let lastSmsSent = result.data.data.lastSms.map((data: any) => new LastSmsSent(data))
        return lastSmsSent
    }

    getcompanySmsSentAndReceived = async (token: string, mainUrl: string): Promise<{ smsSentArray: Array<SMS>, smsReceivedArray: Array<SmsReceived> }> => {
        let url = `${mainUrl}/admin/sms/companySmsSentAndReceived`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let smsSentArray = result.data.data.smsSentArray.map((data: any) => getSMS(data))
        let smsReceivedArray = result.data.data.smsReceivedArray.map((data: any) => new SmsReceived(data))
        return { smsSentArray, smsReceivedArray }
    }

}