import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';

import useWindowSize from '../../../components/useWindowSize';
import Company from '../../../models/Company';
import MenuForms from '../../../images/AdminMenuForms.svg'
import { useNavigate } from 'react-router-dom';


const FormsComponent = () => {

    let [company, setCompany] = useState<Company | undefined>(undefined)
    let windowSize = useWindowSize();
    const navigate = useNavigate()

    useEffect(() => {
        let cookies = new Cookies()
        let companyData = cookies.get('company')
        if (companyData) {
            let company = new Company(companyData)
            setCompany(company)
        }
    }, []);


    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return <Container fluid className="d-flex flex-column relative" style={{ height: windowSize.height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        <Row className="pt-2 pb-2 mb-3" style={{ background: '#FFFFFFDA', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Pesquisas Internas</b></div>
                    {company && <img alt='foto da empresa' style={{ height: 60 }} src={company.pic} />}
                </div>
            </Col>
        </Row>
        <Row style={{ overflow: 'auto', zIndex: 1 }}>
            <Col className="d-flex flex-column mt-2" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div className="d-flex flex-row align-items-center" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                    <img className="moduleImage" alt='foto da missão' src={MenuForms} />
                    <div style={{ marginLeft: 10, flex: 1 }}>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginBottom: 5 }}><b>Estamos avaliando desenvolver esta funcionalidade!</b></div>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle' }}>Caso você tenha interesse em ter pesquisas internas dentro do App e queira nos ajudar a desenvoler através de testes e feedback, envie um e-mail para <b>rafael@liraedu.com</b>.</div>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
}

export default FormsComponent