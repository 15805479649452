export default class LiraFile {
    id: string;
    name: string;
    // application/pdf
    type: string;
    size: number;
    url: string;
    // "2023-03-05T00:23:40.812Z"
    createdAt: string;
    updatedAt: string;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.type = data.type;
        this.size = data.size;
        this.url = data.url;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }


}