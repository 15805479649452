
import React, { useState, useEffect, useRef } from "react";
import { Button, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectedVideoFile } from "./interfaces";
import { generateVideoWithBlocks, getBackgroundMusicUrl, getVideoFileUrl } from "./services";
import { faSave, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import VideosCache from "./VideosCache";

interface VideoPreviewProps {
    projectId: string
    selectedVideoFiles: Array<SelectedVideoFile>
    backgroundMusicFile: string
    zoom: number
    height: number
    width: number
    videosCache: VideosCache
}


export default function VideoPreview(props: VideoPreviewProps) {

    const videoRef = useRef<ReactPlayer>(null)
    const [audio] = useState(new Audio())
    const [canPlayAudio, setCanPlayAudio] = useState<boolean>(false)
    const [playingState, setPlayingState] = useState<{ recording: boolean, playing: boolean }>({ recording: false, playing: false })
    const [currentVideo, setCurrentVideo] = useState<{ index: number, url: string }>({ index: 0, url: '' })

    let { selectedVideoFiles, zoom, height, width } = props

    useEffect(() => {
        audio.onplay = () => { setPlayingState({ recording: false, playing: true }) }
        audio.oncanplay = () => { setCanPlayAudio(true) }
        audio.volume = 0.1
        audio.onerror = (event) => {
            console.log('error')
            console.log(event)
        }
        audio.src = getBackgroundMusicUrl(props.backgroundMusicFile)
    }, [])

    useEffect(() => {
        if (playingState.recording) {
            save()
        } else if(playingState.playing && currentVideo.url === '') {
            setVideoUrl(currentVideo.index)
        }
    }, [playingState])

    const save = () => {
        generateVideoWithBlocks(props.projectId, selectedVideoFiles.map(selectedVideoFile => selectedVideoFile.file), props.backgroundMusicFile).then((url) => {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'video.mp4');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((err) => console.log(err)).finally(() => setPlayingState({ recording: false, playing: false }))
    }

    const setVideoUrl = (index: number) => {
        if(index >= selectedVideoFiles.length) return
        let videoUrl = getVideoFileUrl(props.projectId, props.selectedVideoFiles[index].file)
        props.videosCache.getVideoFromDb(videoUrl).then((videoInCache) => {
            if(videoInCache) {
                setCurrentVideo({ index, url: videoInCache })
            } else {
                props.videosCache.getVideoBlob(videoUrl).then((blob) => { props.videosCache.saveVideoInDb(videoUrl, blob) })
                setCurrentVideo({ index, url: videoUrl })
            }
        }).catch((err) => {
            console.log(err)
            setCurrentVideo({ index, url: videoUrl })
        })
    }

    const renderVideoPlayer = () => {
        
        return <ReactPlayer
                width={width * zoom}
                height={height * zoom}
                ref={videoRef}
                onEnded={() => {
                    if(currentVideo.index >= selectedVideoFiles.length - 1) {
                        setVideoUrl(0)
                        if(!audio.paused) audio.pause()
                        setPlayingState({ recording: false, playing: false })
                    } else {
                        setVideoUrl(currentVideo.index + 1)
                    }
                }}
                onError={(err) => console.log(err)}
                playing={playingState.playing}
                url={currentVideo.url}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            />
    }

    return <Col md={{ size: 8 }}>
        <div className="d-flex flex-column align-items-center">
            <div className="rounded bg-light mt-2 mb-1 border" style={{ width: width * zoom, height: height * zoom }}>
                {props.selectedVideoFiles.length > 0 && renderVideoPlayer()}
            </div>
            <div className="d-flex justify-content-evenly p-2 m-2 rounded bg-light">
                <Button className="m-1" outline disabled={playingState.playing || playingState.recording || selectedVideoFiles.length < 1} onClick={() => setPlayingState({ recording: true, playing: false })}><FontAwesomeIcon className="me-1" icon={faSave} />{playingState.recording ? 'SALVANDO...' : 'SALVAR'}</Button>
                <Button className="m-1" outline disabled={!canPlayAudio || playingState.playing || playingState.recording || selectedVideoFiles.length < 1} onClick={() => audio.play()}><FontAwesomeIcon className="me-1" icon={faVolumeUp} />{playingState.playing ? 'Tocando...' : 'Tocar'}</Button>
            </div>
        </div>
    </Col>
}

