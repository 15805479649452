import React from 'react';

import ForumComment from '../../models/ForumComment';
import User from '../../models/User';
import { faClock, faComment, faExclamationTriangle, faReply, faTimes, faTrashAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from 'reactstrap';
import TokenHelper from '../../auth/TokenHelper';
import ForumService from '../../services/forumService';
import config from '../../config';
import { faThumbsUp as faThumbsUpRegular } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as faThumbsUpSolid } from '@fortawesome/free-solid-svg-icons';
import CommentState from './CommentState';

interface CommentComponentProps {
    user: User
    commentState: CommentState
    newCommentCallback: (comment: ForumComment) => void
    commentDeletedCallback: (deletedComment: ForumComment) => void
    commentLikedCallback: (commentId: string, userId: string) => void
    commentDislikedCallback: (commentId: string, userId: string) => void
    updateState: (commentId: string, isLoading: boolean, userCommentTyped: string, isCommentBoxOpen: boolean, error: string | undefined) => void
}

const renderUserProfilePic = (profilePic?: string) => {
    if (!profilePic) return (<div><FontAwesomeIcon color='black' icon={faUserCircle} size='4x' /></div>)
    return <img style={{ width: '4em', height: '4em', borderRadius: '2em', objectFit: 'cover' }} alt='prof pic' src={profilePic} />
}

const formattedDate = (date: Date) => {
    let diff = (new Date()).getTime() - date.getTime(); // the difference in milliseconds
    if (diff < 1000) return 'há alguns instantes';

    let sec = Math.floor(diff / 1000); // convert diff to seconds
    if (sec < 60) return sec + ' seg. atrás';

    let min = Math.floor(diff / 60000); // convert diff to minutes
    if (min < 60) return min + ' min. atrás';
    let d = [
        '0' + date.getDate(),
        '0' + (date.getMonth() + 1),
        '' + date.getFullYear(),
        '0' + date.getHours(),
        '0' + date.getMinutes()
    ].map(component => component.slice(-2)); // take last 2 digits of every component

    // join the components into date
    return d.slice(0, 3).join('.') + ' ' + d.slice(3).join(':');
}

const CommentComponent = (props: CommentComponentProps) => {

    let { user, commentState, commentDeletedCallback, newCommentCallback, commentLikedCallback, commentDislikedCallback,
        updateState } = props
    let comment = commentState.comment
    let { isLoading, userCommentTyped, isCommentBoxOpen, error } = commentState

    let userLiked = commentState.comment.userIdsLiked.filter(userIdLiked => { return userIdLiked === user.id }).length > 0

    let postComment = async () => {
        updateState(comment.id, true, userCommentTyped, isCommentBoxOpen, error)
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return updateState(comment.id, false, userCommentTyped, isCommentBoxOpen, 'Usuário não possui token de acesso.')
            let forumService = new ForumService()
            let newComment = await forumService.addComment(token, config.endpoint, user.companyId, comment.moduleId, user.username, userCommentTyped, [...comment.parentsChain, comment.id], comment.layer + 1, comment.id, user.pic)
            newCommentCallback(newComment)
            updateState(comment.id, false, '', false, undefined)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) postComment()
            else updateState(comment.id, false, userCommentTyped, isCommentBoxOpen, error.toString())
        }
    }

    let deleteComment = async (commentId: string) => {
        updateState(comment.id, true, userCommentTyped, isCommentBoxOpen, error)
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return updateState(comment.id, false, userCommentTyped, isCommentBoxOpen, 'Usuário não possui token de acesso.')
            let forumService = new ForumService()
            let deletedComment = await forumService.deleteComment(token, config.endpoint, commentId)
            commentDeletedCallback(deletedComment)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) deleteComment(commentId)
            else updateState(comment.id, false, userCommentTyped, isCommentBoxOpen, error.toString())
        }
    }

    let likeComment = async () => {
        updateState(comment.id, true, userCommentTyped, isCommentBoxOpen, error)
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return updateState(comment.id, false, userCommentTyped, isCommentBoxOpen, 'Usuário não possui token de acesso.')
            let forumService = new ForumService()
            await forumService.likeComment(token, config.endpoint, comment.id)
            commentLikedCallback(comment.id, user.id)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) likeComment()
            else updateState(comment.id, false, userCommentTyped, isCommentBoxOpen, error.toString())
        }
    }

    let dislikeComment = async () => {
        updateState(comment.id, true, userCommentTyped, isCommentBoxOpen, error)
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return updateState(comment.id, false, userCommentTyped, isCommentBoxOpen, 'Usuário não possui token de acesso.')
            let forumService = new ForumService()
            await forumService.dislikeComment(token, config.endpoint, comment.id)
            commentDislikedCallback(comment.id, user.id)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) dislikeComment()
            else updateState(comment.id, false, userCommentTyped, isCommentBoxOpen, error.toString())
        }
    }

    let likeButtonClick = () => {
        if (isLoading) return
        else if (userLiked) dislikeComment()
        else likeComment()
    }

    let setIsCommentBoxOpen = (isCommentBoxOpen: boolean) => updateState(comment.id, isLoading, userCommentTyped, isCommentBoxOpen, error)
    let setUserCommentTyped = (userCommentTyped: string) => updateState(comment.id, isLoading, userCommentTyped, isCommentBoxOpen, error)

    let sortedChildComments = commentState.children.sort((a, b) => {
        // primeiro o que tem mais interacao, depois o que teve interacao por ultimo, depois o que foi criado por ultimo
        // retorna -1 fica na frente, retorna 1 fica atras
        if (a.comment.interactionCount > b.comment.interactionCount) return -1
        else if (b.comment.interactionCount > a.comment.interactionCount) return 1
        else if (a.comment.lastInteractionInMillis > b.comment.lastInteractionInMillis) return -1
        else if (a.comment.lastInteractionInMillis < b.comment.lastInteractionInMillis) return 1
        else if (a.comment.createdDateInMillis > b.comment.createdDateInMillis) return -1
        else if (a.comment.createdDateInMillis < b.comment.createdDateInMillis) return 1
        else return 0
    })

    return <div key={comment.id} className="d-flex" style={{ marginTop: 15, marginBottom: 15, marginLeft: 5, marginRight: 5, paddingBottom: 5, borderRadius: 5, boxShadow: '2px 4px 4px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
        <div className="d-flex" style={{ flex: 1, padding: 5 }}>
            {renderUserProfilePic(comment.userPic)}
            <div className="d-flex flex-column ml-2" style={{ flex: 1 }}>
                <div style={{ color: '#353f45', verticalAlign: 'middle' }}><b>{comment.username}</b></div>
                <div style={{ color: '#687a86', fontSize: 'small' }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faClock} />{formattedDate(new Date(commentState.comment.createdDateInMillis))}</div>
                <div className="d-flex">{comment.comment}</div>
                <div className='d-flex align-items-center'>
                    <div className="cursorPointer" onClick={() => likeButtonClick()}><FontAwesomeIcon icon={userLiked ? faThumbsUpSolid : faThumbsUpRegular} /> {commentState.comment.likes}</div>
                    {comment.layer < 2 && <Button size="sm" outline color="info" style={{ marginLeft: 5, borderStyle: 'none' }} onClick={() => { setIsCommentBoxOpen(true) }}><FontAwesomeIcon icon={faReply} /> Responder</Button>}
                    {user.id === comment.userId && <Button size="sm" outline color="danger" style={{ marginLeft: 5, borderStyle: 'none' }} onClick={() => { deleteComment(comment.id) }}><FontAwesomeIcon icon={faTrashAlt} /> Deletar</Button>}</div>
                {commentState.error && <div style={{ color: 'red', fontSize: 'small', marginTop: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faExclamationTriangle} />{commentState.error}</div>}
                {isCommentBoxOpen && <div className="d-flex" style={{ marginBottom: 15, marginTop: 10, marginRight: 5, paddingBottom: 5, borderRadius: 5, boxShadow: '2px 4px 4px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', padding: 10, flex: 1 }}>
                    {user.pic ? <img style={{ width: '4em', height: '4em', borderRadius: '2em', objectFit: 'cover' }} alt='prof pic' src={user.pic} /> : <FontAwesomeIcon color='black' icon={faUserCircle} size='4x' />}
                    <div className="d-flex flex-column ml-2" style={{ flex: 1 }}>
                        <div style={{ color: '#353f45', verticalAlign: 'middle', marginBottom: 5 }}><b>{user.username}</b></div>
                        <Input type="textarea" value={userCommentTyped} name="userComment" id="userComment" placeholder="Escreva um comentário" onChange={(event: any) => setUserCommentTyped(event.target.value)} />
                        <div className='d-flex' style={{ marginTop: 5, marginBottom: 5 }}>
                            <Button color="info" disabled={isLoading || userCommentTyped.length === 0} outline style={{ borderStyle: 'none' }} onClick={() => postComment()}>{isLoading ? 'Postando...' : 'Postar'}<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faComment} /></Button>
                            <Button size="sm" outline color="danger" style={{ marginLeft: 5, borderStyle: 'none' }} onClick={() => { setIsCommentBoxOpen(false) }}><FontAwesomeIcon icon={faTimes} /> Cancelar</Button>
                        </div>
                    </div>
                </div>}
                {sortedChildComments.length > 0 && sortedChildComments.map(commentState => {
                    return <CommentComponent user={user} commentState={commentState} commentDeletedCallback={props.commentDeletedCallback} updateState={updateState}
                    newCommentCallback={props.newCommentCallback} commentLikedCallback={commentLikedCallback} commentDislikedCallback={commentDislikedCallback} />
                })}
            </div>
        </div>
    </div>
}

export default CommentComponent