export default class User {
    id: string
    username: string
    companyId: string
    doc?: string
    email?: string
    phone?: string
    pic?: string
    thumbnail?: string
    linkedInId?: string
    admin?: boolean
    authData?: { [key: string]: string }
    createdDateInMillis: number
    lastAccessInMillis: number
    active: boolean

    constructor(data: any) {
        this.id = data.id
        this.username = data.username
        this.doc = data.doc
        this.email = data.email
        this.phone = data.phone
        this.pic = data.pic
        this.thumbnail = data.thumbnail
        this.linkedInId = data.linkedInId
        this.companyId = data.companyId
        this.admin = data.admin
        this.authData = data.authData
        this.createdDateInMillis = data.createdDateInMillis
        this.lastAccessInMillis = data.lastAccessInMillis || data.createdDateInMillis
        this.active = data.active
    }   

    getData(): any {
        let data: any = {
            id: this.id,
            username: this.username,
            companyId: this.companyId,
            createdDateInMillis: this.createdDateInMillis,
            lastAccessInMillis: this.lastAccessInMillis,
            // if its true return true. if it's false or undefined return false
            active: this.active || false
        }
        if(this.doc) data.doc = this.doc
        if(this.email) data.email = this.email
        if(this.phone) data.phone = this.phone
        if(this.linkedInId) data.linkedInId = this.linkedInId
        if(this.pic) data.pic = this.pic
        if(this.thumbnail) data.thumbnail = this.thumbnail
        if(this.admin) data.admin = this.admin
        if(this.authData) data.authData = this.authData
        return data
    }
}