export default class LibraryContent {
    id: string
    title: string
    type: string
    pos: number
    companyId: string
    moduleId: string

    constructor(data: any) {
        this.id = data.id
        this.title = data.title
        this.type = data.type
        this.pos = data.pos
        this.companyId = data.companyId
        this.moduleId = data.moduleId
    }

    getData(): any {
        return {
            id: this.id,
            title: this.title,
            type: this.type,
            pos: this.pos,
            companyId: this.companyId,
            moduleId: this.moduleId,
            
        }
    }
}

export class LinkContent extends LibraryContent {

    link: string
    description: string
    openNewTab: boolean

    constructor(data: any) {
        super(data)
        this.link = data.link
        this.description = data.description
        this.openNewTab = data.openNewTab
    }

    getData(): any {
        return {
            ...super.getData(),
            link: this.link,
            description: this.description,
            openNewTab: this.openNewTab
        }
    }

}

export function getLibraryContent(data: any) {
    if(data.type === LibraryContentType.Link) {
        return new LinkContent(data)
    } else {
        return new LibraryContent(data)
    }
}

export const LibraryContentType = {
    Link: 'link',
}