import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import ReactList from 'react-list';
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Button, Col, Container, Row } from 'reactstrap';
import TokenHelper from '../../auth/TokenHelper';

import config from '../../config'
import ForumComment from '../../models/ForumComment';
import Module from '../../models/Module';
import User from '../../models/User';
import ForumService from '../../services/forumService';
import LoadingScreen from '../loading';
import CommentComponent from './CommentComponent';
import CommentState from './CommentState';
import UserCommentComponent from './UserCommentComponent';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useUser } from '../../hooks/useUser';

interface State {
    error: string | undefined;
    isLoading: boolean;
    module: Module | undefined;
    forumCommentsState: Array<CommentState>;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    module: undefined,
    forumCommentsState: [],
};


const ForumScreen = () => {

    const navigate = useNavigate()
    const moduleId = useParams().moduleId
    const { height } = useWindowDimensions()
    const { company, user, noCompany, noUser } = useUser()

    const [state, setState] = React.useState<State>(INITIAL_STATE)

    useEffect(() => {
        if (!company || !user) setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.' })
        if (company && user && moduleId) loadModuleComments(company.id, moduleId)
    }, [company, user, noCompany, noUser, moduleId])

    const getCommentState = (parentComment: ForumComment, forumCommentChildren: Array<ForumComment>): CommentState => {
        let nextLayerChildComments = forumCommentChildren.filter(childComment => { return childComment.layer === parentComment.layer + 1 }).map(forumComment => {
            let nextChildren = forumCommentChildren.filter(({ parentsChain }) => { return parentsChain.filter(parentId => { return parentId === forumComment.id }).length > 0 })
            return getCommentState(forumComment, nextChildren)
        })
        return {
            comment: parentComment,
            children: nextLayerChildComments,
            error: '',
            isLoading: false,
            userCommentTyped: '',
            isCommentBoxOpen: false,
        }
    }

    const loadModuleComments = async (companyId: string, moduleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let forumService = new ForumService()
            let { forumComments, module } = await forumService.getModuleForumComments(token, config.endpoint, companyId, moduleId)

            console.log(forumComments)

            let forumCommentsState = forumComments.filter(forumComment => { return forumComment.layer === 0 }).map<CommentState>(forumComment => {
                let forumCommentChildren = forumComments.filter(({ parentsChain }) => { return parentsChain.filter(parentId => { return parentId === forumComment.id }).length > 0 })
                return getCommentState(forumComment, forumCommentChildren)
            })

            console.log(forumCommentsState)

            setState({ ...state, module, forumCommentsState, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadModuleComments(companyId, moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const updateState = (commentId: string, isLoading: boolean, userCommentTyped: string, isCommentBoxOpen: boolean, error: string | undefined) => {

        let updateState = (commentState: CommentState) => {
            if (commentState.comment.id === commentId) {
                commentState.isLoading = isLoading
                commentState.userCommentTyped = userCommentTyped
                commentState.isCommentBoxOpen = isCommentBoxOpen
                commentState.error = error
                return true
            } else if (commentState.children.length > 0) {
                for (var n = 0; n < commentState.children.length; n++) {
                    let updated = updateState(commentState.children[n])
                    if (updated) return true
                }
            }
            return false
        }

        let newCommentStateArray = [...state.forumCommentsState]
        for (var i = 0; i < newCommentStateArray.length; i++) {
            let updated = updateState(newCommentStateArray[i])
            if (updated) return setState({ ...state, forumCommentsState: newCommentStateArray })
        }
    }

    const commentDeleted = async (deletedComment: ForumComment) => {

        let removeCommentFromStateChildren = (forumState: CommentState, deletedId: string) => {
            if (forumState.children.length > 0) forumState.children = forumState.children.filter(forum => { return forum.comment.id !== deletedId }).map(forum => { return removeCommentFromStateChildren(forum, deletedId) })
            return forumState
        }

        let newForumState = [...state.forumCommentsState.filter(forumState => {
            return forumState.comment.id !== deletedComment.id
        }).map(forumState => {
            return removeCommentFromStateChildren(forumState, deletedComment.id)
        })]

        setState({ ...state, forumCommentsState: newForumState })
    }

    const commentCreatedCallback = (newComment: ForumComment) => {

        let newCommentState: CommentState = {
            comment: newComment,
            children: [],
            error: '',
            isLoading: false,
            userCommentTyped: '',
            isCommentBoxOpen: false,
        }

        let addNewCommentState = (forumState: CommentState, newCommentState: CommentState) => {
            if (newCommentState.comment.parentId === forumState.comment.id) {
                forumState.children.push(newCommentState)
            } else if (newCommentState.comment.layer <= forumState.comment.layer) {
                return forumState
            } else if (forumState.children.length > 0) {
                forumState.children = forumState.children.map(child => { return addNewCommentState(child, newCommentState) })
            }
            return forumState
        }

        if (newComment.layer === 0) setState({ ...state, forumCommentsState: [...state.forumCommentsState, newCommentState] })
        else setState({
            ...state,
            forumCommentsState: [...state.forumCommentsState.map(forumState => {
                return addNewCommentState(forumState, newCommentState)
            })]
        })
    }

    const commentLikedCallback = (commentId: string, userId: string) => {

        let updateState = (commentState: CommentState) => {
            if (commentState.comment.id === commentId) {
                commentState.comment.likes += 1
                commentState.comment.interactionCount += 1
                commentState.comment.userIdsLiked.push(userId)
                commentState.isLoading = false
                commentState.error = undefined
                return true
            } else if (commentState.children.length > 0) {
                for (var n = 0; n < commentState.children.length; n++) {
                    let updated = updateState(commentState.children[n])
                    if (updated) return true
                }
            }
            return false
        }

        let newCommentStateArray = [...state.forumCommentsState]
        for (var i = 0; i < newCommentStateArray.length; i++) {
            let updated = updateState(newCommentStateArray[i])
            if (updated) return setState({ ...state, forumCommentsState: newCommentStateArray })
        }
    }

    const commentDislikedCallback = (commentId: string, userId: string) => {

        let updateState = (commentState: CommentState) => {
            if (commentState.comment.id === commentId) {
                commentState.comment.likes -= 1
                commentState.comment.interactionCount -= 1
                commentState.comment.userIdsLiked = commentState.comment.userIdsLiked.filter(userLikeId => { return userLikeId !== userId })
                commentState.isLoading = false
                commentState.error = undefined
                return true
            } else if (commentState.children.length > 0) {
                for (var n = 0; n < commentState.children.length; n++) {
                    let updated = updateState(commentState.children[n])
                    if (updated) return true
                }
            }
            return false
        }

        let newCommentStateArray = [...state.forumCommentsState]
        for (var i = 0; i < newCommentStateArray.length; i++) {
            let updated = updateState(newCommentStateArray[i])
            if (updated) return setState({ ...state, forumCommentsState: newCommentStateArray })
        }
    }

    const renderHeader = (module: Module) => {
        return (<Row className="pt-2 pb-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', minHeight: '4em' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={module.pic} />
                    <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap' }}>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', fontSize: 16, marginLeft: 10 }}><b>Fórum</b></div>
                        <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 10 }}>{module.title}</div>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    const renderError = (error: string) => {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>{error}</Alert>
                </Col>
            </Row>
        );
    }

    const renderComments = (user: User, forumCommentsState: Array<CommentState>, moduleId: string) => {

        let firstLayerComments = forumCommentsState.sort((a, b) => {
            // primeiro o que tem mais interacao, depois o que teve interacao por ultimo, depois o que foi criado por ultimo
            // retorna -1 fica na frente, retorna 1 fica atras
            if (a.comment.interactionCount > b.comment.interactionCount) return -1
            else if (b.comment.interactionCount > a.comment.interactionCount) return 1
            else if (a.comment.lastInteractionInMillis > b.comment.lastInteractionInMillis) return -1
            else if (a.comment.lastInteractionInMillis < b.comment.lastInteractionInMillis) return 1
            else if (a.comment.createdDateInMillis > b.comment.createdDateInMillis) return -1
            else if (a.comment.createdDateInMillis < b.comment.createdDateInMillis) return 1
            else return 0
        })

        let renderItem = (index: number, key: number | string) => {
            let comment = firstLayerComments[index]
            return <CommentComponent user={user} commentState={comment} commentDeletedCallback={(deletedComment) => commentDeleted(deletedComment)} updateState={updateState}
                newCommentCallback={commentCreatedCallback} commentLikedCallback={commentLikedCallback} commentDislikedCallback={commentDislikedCallback} />
        }

        console.log(forumCommentsState)

        return <Row style={{ overflow: 'auto', flex: 1 }}>
            {user && module && <UserCommentComponent user={user} moduleId={moduleId} newCommentCallback={commentCreatedCallback} />}
            <Col md={{ size: 8, offset: 2 }}>
                <ReactList itemRenderer={renderItem} length={firstLayerComments.length} type='variable' />
            </Col>
        </Row>
    }

    let { error, isLoading, forumCommentsState, module } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', overflow: 'none', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && renderError(error)}
        {module && renderHeader(module)}
        {forumCommentsState.length === 0 && <Row><Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
            <div className="d-flex align-items-center" style={{ marginTop: 15, marginBottom: 10, marginLeft: 5, marginRight: 5, padding: 10, borderRadius: 5, boxShadow: '2px 4px 4px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', color: '#353f45' }}>
                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faInfoCircle} size='2x' />
                <div style={{ verticalAlign: 'middle', margin: 5 }}>Ainda não existem comentários no fórum desta Missão. Seja o primeiro a começar!</div>
            </div>
        </Col></Row>}
        {user && module && renderComments(user, forumCommentsState, module.id)}
    </Container>)

}

export default ForumScreen