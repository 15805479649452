import React, { useState } from 'react';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface Props {
    title: string,
    description: string,
    minCharCount: number,
    onClose: () => void
    onSave: (feedback: string) => void
}

export const RespondModuleFeedbackModal = ({ title, description, minCharCount, onClose, onSave }: Props) => {

    const [feedbackInput, setFeedbackInput] = useState<string>('')

    const saveDisabled = feedbackInput.length < minCharCount

    return <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }} toggle={() => onClose()}>
        <ModalHeader>
            <div>{title}</div>
        </ModalHeader>
        <ModalBody>
            <div>{description}</div>
            <div>Mínimo {minCharCount} caracteres</div>
            <FormGroup>
                <Input type='textarea'  style={{ opacity: 0.85 }} placeholder='Resposta' value={feedbackInput} onChange={(event) => setFeedbackInput(event.target.value)} />
            </FormGroup>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center align-items-center' >
            <Button color="success" disabled={saveDisabled} onClick={() => { onSave(feedbackInput) }}>Salvar</Button>
            <Button color="secondary" onClick={() => onClose()}>Sair</Button>
        </ModalFooter>
    </Modal>
}