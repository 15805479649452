import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Company from "../models/Company";
import User from "../models/User";


export const useUser = () => {

    const [user, setUser] = useState<User | null>(null);
    const [company, setCompany] = useState<Company | null>(null);
    const [noUser, setNoUser] = useState<boolean | null>(null);
    const [noCompany, setNoCompany] = useState<boolean | null>(null);

    useEffect(() => {
        let cookies = new Cookies()
        let companyData = cookies.get('company')
        let userData = cookies.get('user')
        if (companyData) {
            setCompany(new Company(companyData))
        } else {
            setNoCompany(true)
        }
        if (userData) {
            setUser(new User(userData))
        } else {
            setNoUser(true)
        }
    }, [])

    return { user, company, noUser, noCompany }
}
