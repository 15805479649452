export const applyCpfMask = (value: string) => {

    // algumas planilhas ignoram zeros a esquerda do numero de cpf
    let numerOfMissingZeros = 11 - value.length
    var ao_cpf = numerOfMissingZeros > 0 ? new Int8Array(numerOfMissingZeros).reduce((acc, val) => {
        return acc + val.toString()
    }, "") + value : value
    var cpfValido = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/;
    if (cpfValido.test(ao_cpf) === false) {

        ao_cpf = ao_cpf.replace(/\D/g, ""); //Remove tudo o que não é dígito

        if (ao_cpf.length === 11) {
            ao_cpf = ao_cpf.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
            ao_cpf = ao_cpf.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
            //de novo (para o segundo bloco de números)
            ao_cpf = ao_cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos

            return ao_cpf
        }
    }

    return value
}

export const validateCPF = (strCPF: string) => {
    strCPF = strCPF.replace(/\D/g, '')
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let n = 1; n <= 10; n++) Soma = Soma + parseInt(strCPF.substring(n - 1, n)) * (12 - n);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

export const validateEmail = (email: string): boolean => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}