import React, { Component, Fragment } from 'react';
import TokenHelper from '../auth/TokenHelper';
import AdminFilesService from '../services/admin/adminFilesService';
import config from '../config';
import { Row, Col, Alert, Spinner, Form, FormGroup, FormText, Input, Label, Button } from 'reactstrap';
import LiraFile from '../models/LiraFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import PDFPreview from './pdf/PDFPreview';


/** FILE VIEW */
export interface FileViewProps {
    onFileSelected: (filename: string) => void
    onFileDeleted: (filename: string) => void
    //onFileRenamed: (oldFilename: string, newFilename: string) => void
    file: LiraFile
}

const FileView = (props: FileViewProps) => {
    const [view, setView] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [error, setError] = React.useState('');

    const deleteFile = async () => {
        setIsDeleting(true)
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) {
                setError('Usuário não possui token de acesso.')
                setIsDeleting(false)
                return
            }
            let adminFilesService = new AdminFilesService()
            await adminFilesService.deleteFile(token, config.endpoint, props.file.name)
            setIsDeleting(false)
            props.onFileDeleted(props.file.name)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                deleteFile()
            } else {
                setIsDeleting(false)
                setError(error.toString())
            }
        }
    }

    let file = props.file
    return (<Col md={{ size: 10, offset: 1 }}>
        <div className="d-flex-column align-items-center  mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
            {error && <div className="mt-1 mb-1"><Alert color="danger">{error}</Alert></div>}
            <div className="ms-2">{file.name.split('/')[1]}</div>
            <div className="d-flex align-items-center" >
                <Button style={{ borderStyle: 'none' }} outline color="primary" onClick={() => props.onFileSelected(file.name)}>Selecionar <FontAwesomeIcon icon={faCheckCircle} /></Button>
                {!view && <Button className='ms-1' size="sm" outline style={{ borderStyle: 'none' }} onClick={() => { setView(true) }} color="success"><FontAwesomeIcon icon={faEye} /> Mostrar</Button>}
                <Button className='ms-1' size="sm" outline color="danger" disabled={isDeleting} style={{ borderStyle: 'none' }} onClick={() => { deleteFile() }}><FontAwesomeIcon icon={faTrashAlt} /> {isDeleting ? 'Deletando...' : 'Deletar'}</Button>
            </div>
            {view && <div className="d-flex mt-1 align-items-center"><PDFPreview width={250} height={250} filename={file.name} /></div>}
        </div>
    </Col>)

}


/** SELECT FILE COMPONENT */
interface SelectFileProps {
    fileSelected: (filename: string) => void
    goBack: () => void
}

interface SelectFileComponentState {
    isLoading: boolean;
    error: string | undefined;
    files: Array<LiraFile>
}

const INITIAL_STATE: SelectFileComponentState = {
    isLoading: true,
    error: undefined,
    files: []
}

export default class SelecFileComponent extends Component<SelectFileProps, SelectFileComponentState> {

    constructor(props: SelectFileProps) {
        super(props)

        this.state = { ...INITIAL_STATE }
    }

    async componentDidMount() {
        this.getAllFiles()
    }

    async getAllFiles() {
        this.setState({ isLoading: false })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminFilesService = new AdminFilesService()
            let files = await adminFilesService.getAllFiles(token, config.endpoint)
            this.setState({ files, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.getAllFiles()
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    uploadFile = async (selectedFile: File) => {
        this.setState({ isLoading: false })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let formData = new FormData()
            formData.append('file', selectedFile)
            let adminFilesService = new AdminFilesService()
            let file = await adminFilesService.uploadFile(token, config.endpoint, selectedFile)
            this.props.fileSelected(file.name)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.uploadFile(selectedFile)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    render() {
        let { isLoading, error } = this.state
        return (
            <Fragment>
                <Row>
                    {error && <Col md={{ size: 8, offset: 2 }}><Alert color="danger">{error}</Alert></Col>}
                    {isLoading && <Col md={{ size: 8, offset: 2 }}><Spinner color="primary" /></Col>}
                    <Col className="mb-2" md={{ size: 10, offset: 1 }}>
                        <div className="d-flex flex-column justify-content-center mt-3" style={{ background: '#dbdbdbda', borderRadius: 5, padding: 10 }}>
                            <Form>
                                <FormGroup>
                                    <Label for="image">Carregar PDF</Label>
                                    <Input type="file" name="file" id="pdf-file" accept=".pdf" onChange={(event) => event.target.files && this.uploadFile(event.target.files[0])} />
                                    <FormText color="muted">Carregar uma imagem do seu dispositivo.</FormText>
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                    {this.state.files.map(file => {
                        return <FileView key={file.name} file={file} onFileSelected={this.props.fileSelected} onFileDeleted={this.props.goBack} />
                    })}
                </Row>
            </Fragment>
        )
    }

}