import React, { Component, Fragment } from 'react';
import { Row, Col, Input, Button, Alert, Form, FormGroup, Label, FormText, Container } from 'reactstrap'
import { SubModule } from '../../../../../models/SubModule';
import QuizQuestion, { QuizQuestionType } from '../../../../../models/QuizQuestion';
import Module from '../../../../../models/Module';
import config from '../../../../../config';
import CardImage from '../../../../../models/CardImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import TokenHelper from '../../../../../auth/TokenHelper';
import AdminContentService from '../../../../../services/admin/adminContentService';
import CreateDefaultQuestionComponent from './CreateDefaultQuestionComponent';
import CreateSelectAnswerCardComponent from './CreateSelectAnswerCardComponent';
import CreateMemoryCardComponent from './CreateMemoryCardComponent';
import CreateWordGuessComponent from './CreateWordGuessComponent';
import Zoom from 'react-medium-image-zoom'
import ImageSvg from '../../../../../images/image.svg'
import QuizSvg from '../../../../../images/quiz.svg'

// Question Gifs
import DefaultQuestionGif from "../../../../../images/QuizGifs/DefaultQuestion.gif";
import MemoryGameCardGif from "../../../../../images/CardGifs/MemoryGameCard.gif";
import SelectAnswerQuestionGif from "../../../../../images/QuizGifs/SelectAnswerQuestion.gif";
import WordGuessQuestionGif from "../../../../../images/QuizGifs/WordGuessQuestion.gif";

interface Props {
    subModule: SubModule
    lastPosition: number
    addQuizCard: (card: QuizQuestion) => void
    exit: () => void
}

interface State {
    quizType: string;
    error: any | undefined;
    isPickingFile: boolean;
    showPreview: boolean;
}

const INITIAL_STATE: State = {
    quizType: QuizQuestionType.Default,
    error: undefined,
    isPickingFile: false,
    showPreview: true,
};

export default class CreateQuizComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = { ...INITIAL_STATE }

    }

    changeQuizType = (newType: string) => {
        if (newType === 'Acertar Resposta') {
            this.setState({ quizType: QuizQuestionType.Default })
        } else if (newType === 'Selecionar Opções') {
            this.setState({ quizType: QuizQuestionType.SelectAnswer })
        } else if (newType === 'Jogo da Memória') {
            this.setState({ quizType: QuizQuestionType.MemoryGame })
        } else if (newType === 'Acertar Palavra') {
            this.setState({ quizType: QuizQuestionType.WordGuess })
        }
    }

    uploadCardImage = async (imageBlob: Blob, imageUploadCallback: (imageUrl: string | undefined) => void) => {
        let tokenHelper = new TokenHelper()
        let subModule = this.props.subModule
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let formData = new FormData()
            formData.append('file', imageBlob)
            let adminContentService = new AdminContentService()
            let cardImage = await adminContentService.uploadCardImage(token, config.endpoint, formData, subModule.moduleId, subModule.id)
            imageUploadCallback(cardImage.imageUrl)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.uploadCardImage(imageBlob, imageUploadCallback)
            } else {
                imageUploadCallback(undefined)
                this.setState({ error })
            }
        }
    }

    getCardImages = async (callback: (modules: Array<Module>, cardImages: Array<CardImage>) => void) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let { modules, cardImages } = await adminContentService.getCardImages(token, config.endpoint)
            callback(modules, cardImages)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.getCardImages(callback)
            } else {
                this.setState({ error })
            }
        }
    }

    onExit = () => {
        if (this.state.isPickingFile) {
            this.setState({ isPickingFile: false })
        } else {
            this.props.exit()
        }
    }

    renderError(error: any) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                    {error}
                </Alert>
            </Col>
        </Row>
        );
    }

    renderTypeSelection(type: string, showPreview: boolean) {
        var templateGif = DefaultQuestionGif
        var feedbackText = "Pergunta com apenas uma resposta correta. Você pode colocar imagem na pergunta. O usuário tem 45 segundos para responder, quanto mais rápido responde, mais pontos ele ganha."
        var cardTypeValue = "Acertar Resposta"
        if (type === QuizQuestionType.SelectAnswer) {
            templateGif = SelectAnswerQuestionGif
            feedbackText = "Pergunta que pode selecionar múltiplas opções. Você pode colocar imagem na pergunta. O usuário tem 45 segundos para responder, quanto mais rápido responde, mais pontos ele ganha."
            cardTypeValue = "Selecionar Opções"
        } else if (type === QuizQuestionType.MemoryGame) {
            templateGif = MemoryGameCardGif
            feedbackText = "O usuário precisa achar os cards com imagens e texto iguais. Quanto menos ele erra, mais pontos ganha."
            cardTypeValue = "Jogo da Memória"
        } else if (type === QuizQuestionType.WordGuess) {
            templateGif = WordGuessQuestionGif
            feedbackText = "O usuário precisa acertar uma palavra selecionando as letras. Quanto menos erros ele comete, mais pontos ele ganha."
            cardTypeValue = "Acertar Palavra"
        }
        return (<Row>
            <Col className="d-flex flex-column justify-content-center relative pt-3" md={{ size: 6, offset: 3 }}>
                <Form style={{ margin: 5 }}>
                    <FormGroup>
                        <Label for="quizType">Tipo de Quiz</Label>
                        <Input type="select" name="selectMulti" id="quizType" value={cardTypeValue} onChange={(event: any) => this.changeQuizType(event.target.value)}>
                            <option>Acertar Resposta</option>
                            <option>Selecionar Opções</option>
                            <option>Jogo da Memória</option>
                            <option>Acertar Palavra</option>
                        </Input>
                        <FormText>
                            {feedbackText}
                        </FormText>
                    </FormGroup>
                </Form>
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ margin: 5, background: 'white', borderRadius: '0.25rem', paddingTop: showPreview ? 10 : 5, paddingBottom: 5, borderColor: '#ced4da' }}>
                    <div className="cursorPointer" style={{ marginLeft: 5, marginRight: 5, borderStyle: 'none' }} color="secondary" onClick={() => this.setState({ showPreview: !showPreview })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={showPreview ? faEyeSlash : faEye} />{showPreview ? 'Esconder Template' : 'Mostrar Template'}</div>
                    {showPreview && <Zoom><img src={templateGif} alt='Gif do template' style={{ height: 300, width: 190, objectFit: 'contain' }} /></Zoom>}
                </div>
            </Col>
        </Row>)
    }

    renderFormForSelectedType(type: string, isPickingFile: boolean) {
        if (type === QuizQuestionType.Default) return (<CreateDefaultQuestionComponent addQuizCard={this.props.addQuizCard} onError={(error: any) => this.setState({ error })} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} uploadCardImage={this.uploadCardImage} getCardImages={this.getCardImages} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === QuizQuestionType.SelectAnswer) return (<CreateSelectAnswerCardComponent addQuizCard={this.props.addQuizCard} onError={(error: any) => this.setState({ error })} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} uploadCardImage={this.uploadCardImage} getCardImages={this.getCardImages} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === QuizQuestionType.MemoryGame) return (<CreateMemoryCardComponent addQuizCard={this.props.addQuizCard} onError={(error: any) => this.setState({ error })} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} uploadCardImage={this.uploadCardImage} getCardImages={this.getCardImages} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === QuizQuestionType.WordGuess) return (<CreateWordGuessComponent addQuizCard={this.props.addQuizCard} onError={(error: any) => this.setState({ error })} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else return (<div></div>)
    }

    render() {
        let { quizType, error, isPickingFile, showPreview } = this.state
        return (<Fragment>
            <Row className="pt-2 pb-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', minHeight: '4em' }}>
                        <Button color='none' outline onClick={() => this.onExit()}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} src={isPickingFile ? ImageSvg : QuizSvg} />
                        <div className='d-flex flex-column ml-2'>
                            <div style={{ color: '#1d3256', verticalAlign: 'middle' }}><b>{isPickingFile ? 'Selecionar Imagem' : 'Criar Questão'}</b></div>
                            <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', verticalAlign: 'middle' }}>{this.props.subModule.title}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ overflow: 'auto' }}>
                <Col>
                    <Container fluid>
                        {error && this.renderError(error)}
                        {!isPickingFile && this.renderTypeSelection(quizType, showPreview)}
                        {this.renderFormForSelectedType(quizType, isPickingFile)}
                    </Container>
                </Col>
            </Row>
        </Fragment>)
    }

}