import _ from 'lodash'

import Module from "../../../models/Module"
import { MonthUserCompanyScore } from "../../../models/Scores"
import { SubModule } from "../../../models/SubModule"
import User from "../../../models/User"

// Usuarios que deveriam ter jogado o jogo
export const getUsersWhoShouldPlayModule = (users: Array<User>, module: Module): Array<User> => {
    return users.filter(user => {
        if(module.allowAccessAuthData) {
            if(!user.authData) return false
            for (let key in module.allowAccessAuthData) {
                if (module.allowAccessAuthData[key].filter(val => val === user.authData![key]).length === 0) return false
            }
        }
        return true
    })
}

// Usuarios que fizeram pelomenos 50% da pontuacao de todos os jogos que eles deveriam jogar
export const getUsersWhoFinishedAllModules = (users: Array<User>, monthUsersCompanyScore: Array<MonthUserCompanyScore>, modules: Array<Module>, subModules: Array<SubModule>): Array<User> => {
    var result: Array<User> = []
    for(var i = 0; i < users.length; i++) {
        let user = users[i]
        let userCompanyScores = monthUsersCompanyScore.filter(data => data.userId === user.id)
        // se o usuario nao jogou esse ano, ele esta fora
        if(userCompanyScores.length === 0) continue
        let modulesUserShouldPlay = modules.filter((module) => {
            if(module.allowAccessAuthData) {
                if(!user.authData) return false
                for (let key in module.allowAccessAuthData) {
                    if (module.allowAccessAuthData[key].filter(val => val === user.authData![key]).length === 0) return false
                }
            }
            return true
        })
        var modulesUserGotCertificateCount = 0
        for(var n = 0; n < modulesUserShouldPlay.length; n++) {
            let module = modulesUserShouldPlay[n]
            let moduleSubModules = subModules.filter(subModule => subModule.moduleId === module.id)
            let subModulesUserScoredMoreThanHalf = moduleSubModules.filter(subModule => {
                let biggestScoreInSubModule =  userCompanyScores.reduce((prev, curr) => curr.subModuleScores[subModule.id] > prev ? curr.subModuleScores[subModule.id] : prev, 0)
                return biggestScoreInSubModule >= (subModule.maxScore / 2)
            }).length
            if(subModulesUserScoredMoreThanHalf === moduleSubModules.length) modulesUserGotCertificateCount++
        }
        if(modulesUserGotCertificateCount === modulesUserShouldPlay.length) result.push(user)
    }
    return result
}

// Usuarios que fizeram pelomenos 50% da pontuacao de um nivel
export const getUsersWhoFinishedModule = (users: Array<User>, monthUsersCompanyScore: Array<MonthUserCompanyScore>, subModules: Array<SubModule>): Array<User> => {
    return users.filter(user => {
        let userCompanyScores = monthUsersCompanyScore.filter(data => data.userId === user.id)
        // se o usuario nao jogou esse ano, ele esta fora
        if(userCompanyScores.length === 0) return false
        let subModulesUserScoredMoreThanHalf = subModules.filter(subModule => {
            let biggestScoreInSubModule =  userCompanyScores.reduce((prev, curr) => curr.subModuleScores[subModule.id] > prev ? curr.subModuleScores[subModule.id] : prev, 0)
            return biggestScoreInSubModule >= (subModule.maxScore / 2)
        }).length
        return subModulesUserScoredMoreThanHalf === subModules.length
    })
}

export const getUsersWhoPlayedAnySubModule = (users: Array<User>, monthUsersCompanyScore: Array<MonthUserCompanyScore>, subModules: Array<SubModule>) => {
    return users.filter(user => {
        let userCompanyScores = monthUsersCompanyScore.filter(data => data.userId === user.id)
        for(var i = 0; i < userCompanyScores.length; i++) {
            let score = userCompanyScores[i]
            let subModulesPlayed = Object.keys(score.subModuleScores)
            for(var n = 0; n < subModules.length; n++) {
                let subModule = subModules[n]
                if(subModulesPlayed.filter(id => id === subModule.id).length > 0) return true
            }
        }
        return false
    })
}

export const getUsersWhoPlayedInMonth = (users: Array<User>, subModules: Array<SubModule>, monthUsersCompanyScore: Array<MonthUserCompanyScore>) => {
    const filteredMonthUsersCompanyScore = monthUsersCompanyScore.filter(score => {
        return users.filter(user => user.id === score.userId).length > 0
    })
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
        let scoresInMonth = filteredMonthUsersCompanyScore.filter(scores => scores.month === month)
        return scoresInMonth.reduce((acc, score) => {
            // verifica se jogou algum nivel da missao
            let subModuleIdsPlayedByUser = Object.keys(score.subModuleScores)
            let subModuleIds = subModules.map(subModule => subModule.id)
            if(_.intersection(subModuleIdsPlayedByUser, subModuleIds).length > 0) {
                acc++
            }
            return acc
        }, 0)
    })
}

export const getCumulativeUsersWhoPlayedInMonth = (users: Array<User>, subModules: Array<SubModule>, monthUsersCompanyScore: Array<MonthUserCompanyScore>, year: number) => {
    
    let usersWhoPlayedAccumulated: Array<string> = new Array<string>()
    var acumulatedUsersInYear = 0
    let currentYear =  (new Date()).getFullYear()
    let lastMonth = currentYear === year ? (new Date()).getMonth() + 1 : 12
    var monthArray: Array<number> = []
    for(var i = 1; i <= lastMonth; i++) monthArray.push(i)
    return monthArray.map(month => {

        let scoresInMonth = monthUsersCompanyScore.filter(scores => scores.month === month && users.filter(user => user.id === scores.userId).length > 0)
        let accumulatedInMonth = scoresInMonth.reduce((acc, score) => {

            // verifica se o usuário ja foi contado
            if(usersWhoPlayedAccumulated.filter(userId => userId === score.userId).length > 0) {
                // se ele ja tiver sido contado não precisa calcular novamente
                return acc
            }

            for(var i = 0; i < subModules.length; i++) {
                let subModule = subModules[i]
                let userScoreInMonth = score.subModuleScores[subModule.id]

                // verifica se o usuario ja jogou essa fase
                var currentUserPlayed = _.head(usersWhoPlayedAccumulated.filter(userId => score.userId === userId))

                // se o usuario ja jogou
                if(currentUserPlayed) {
                    // sai do laço for pois ele ja foi computado
                    break;
                } else if(userScoreInMonth !== undefined) {
                    usersWhoPlayedAccumulated.push(score.userId)
                    acc++
                    break;
                }

            }
            
            return acc
        }, acumulatedUsersInYear)
        acumulatedUsersInYear = accumulatedInMonth
        return accumulatedInMonth
    })
}

export const getCumulativeUsersWhoFinishedInMonth = (users: Array<User>, subModules: Array<SubModule>, monthUsersCompanyScore: Array<MonthUserCompanyScore>, year: number) => {
    
    let usersMaxScoreInSubModuleAccumulated: Array<{ userId: string, subModuleId: string, maxScore: number }> = new Array<{ userId: string, subModuleId: string, maxScore: number }>()
    let usersWhoFinishedSubModules: Array<string> = []
    var acumulatedUsersInYear = 0
    let currentYear =  (new Date()).getFullYear()
    let currentMonth = currentYear === year ? (new Date()).getMonth() + 1 : 12
    var monthArray: Array<number> = []
    for(var i = 1; i <= currentMonth; i++) monthArray.push(i)
    return monthArray.map(month => {
        let scoresInMonth = monthUsersCompanyScore.filter(scores => scores.month === month  && users.filter(user => user.id === scores.userId).length > 0)
        let accumulatedInMonth = scoresInMonth.reduce((acc, score) => {

            // verifica se o usuário ja foi contado
            if(usersWhoFinishedSubModules.filter(userId => { return userId === score.userId }).length > 0) {
                // se ele ja tiver sido contado não precisa calcular novamente
                return acc
            }

            // atualiza o usersMaxScoreInSubmoduleAccumulated
            // e tambem verifica quantas fases ele ja finalizou
            let userSubModulesFinished = 0
            for(var i = 0; i < subModules.length; i++) {
                let subModule = subModules[i]
                let userScoreInMonth = score.subModuleScores[subModule.id]
                // score máximo do usuário nessa fase
                var currentUserMaxScoreInSubModuleAccumulated = _.head(usersMaxScoreInSubModuleAccumulated.filter(data => {
                    return data.userId === score.userId && data.subModuleId === subModule.id
                }))
                // se o usuario jogou essa fase este mês
                if(userScoreInMonth) {
                    // se ele tem um score já no acumulado
                    if(currentUserMaxScoreInSubModuleAccumulated) {
                        // verifica se o score no acumulado é menor que o score desse mês
                        if(currentUserMaxScoreInSubModuleAccumulated.maxScore < userScoreInMonth) {
                            // se for, atualiza o score acumulado para o novo que é maior
                            currentUserMaxScoreInSubModuleAccumulated.maxScore = userScoreInMonth
                        }
                    } else {
                        // adiciona o score atual como maximo no acumulado
                        currentUserMaxScoreInSubModuleAccumulated = { userId: score.userId, subModuleId: subModule.id, maxScore: userScoreInMonth }
                        usersMaxScoreInSubModuleAccumulated.push(currentUserMaxScoreInSubModuleAccumulated)
                    }
                }

                // verifica se precisa atualizar a quantidade de fases que ele terminou
                if(currentUserMaxScoreInSubModuleAccumulated && currentUserMaxScoreInSubModuleAccumulated.maxScore >= (subModule.maxScore / 2)) {
                    userSubModulesFinished++
                } else if(subModule.maxScore === 0 && userScoreInMonth !== undefined) {
                    // caso em que a pontuação é zero verifica só se ele jogou
                    userSubModulesFinished++
                }
            }

            // verifica se o usuário concluiu todas as fases
            if(userSubModulesFinished === subModules.length) {
                // se o usuário ainda não foi computado
                if(usersWhoFinishedSubModules.filter(userId => { return userId === score.userId }).length === 0) {
                    usersWhoFinishedSubModules.push(score.userId)
                    // incrementa a quantidade de usuários que concluiu todas as fases no acumulado até o mês atual
                    acc++
                }
            }
            
            return acc
        }, acumulatedUsersInYear)
        acumulatedUsersInYear = accumulatedInMonth
        return accumulatedInMonth
    })
}