import React, { useEffect, useRef, useState } from 'react';
import { Alert, Spinner } from 'reactstrap';
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import TokenHelper from '../../auth/TokenHelper';
import AdminFilesService from '../../services/admin/adminFilesService';
import config from '../../config';
import ContentService from '../../services/contentService';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js' //`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface PDFPreviewPops {
    filename: string,
    width: number
}

const PDFPreview = (props: PDFPreviewPops) => {

    const [numPages, setNumPages] = useState<number>(0);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [secureUrl, setSecureUrl] = useState('')

    const getSecureUrl = async (filename: string) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setError('Usuário não possui token de acesso.')
            let contentService = new ContentService()
            let secureUrl = await contentService.getFileSecureUrl(token, config.endpoint, filename)
            setSecureUrl(secureUrl)
            setLoading(false)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                getSecureUrl(filename)
            } else {
                setError(error.toString())
                setLoading(false)
            }
        }
    }

    useEffect(() => { getSecureUrl(props.filename) }, [])

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    }

    if (loading) {
        return <Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />
    }

    if (error) {
        return <Alert color="danger">{error}</Alert>
    }

    return (
        <Document file={{ url: secureUrl }} onLoadSuccess={onDocumentLoadSuccess}>
            {numPages > 0 && Array.from(Array(numPages).keys()).map((index) => {
                return <Page className={'PDFPage PDFPageOne'} width={props.width} key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false}
                renderInteractiveForms={false} />
            })}
        </Document>
    );

}

export default PDFPreview