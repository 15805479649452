import React, { useState }  from "react";
import { Form, InputGroup, Input, FormGroup, Label, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroupText, Button, Col, Row } from "reactstrap";

interface ConfigMenuProps {
    height: number,
    width: number,
    playing: boolean
    text: string
    setText: (text: string) => void
    verticalStart: number,
    setVerticalStart: (textHeight: number) => void
    horizontalStart: number,
    setHorizontalStart: (textHeight: number) => void
    textColor: string,
    setTextColor: (textColor: string) => void
    showTextBackgroundColor: boolean,
    textBackgroundColor: string,
    setTextBackgroundColor: (textBackgroundColor: string) => void
    textBackgroundType: "fixed_left" | "around_text" | "fixed_right",
    setTextBackgroundType: (textBackgroundType: "fixed_left" | "around_text" | "fixed_right") => void
    setShowTextBackgroundColor: (showTextBackgroundColor: boolean) => void
    font: string,
    setFont: (font: string) => void
    fontLineHeight: number,
    setFontLineHeight: (fontLineHeight: number) => void
    fontSize: number,
    setFontSize: (fontSize: number) => void
    audioFile: string,
    setShowSelectAudio: (showSelectAudio: boolean) => void,
    duration: number,
    setDuration: (duration: number) => void
    showStartTransition: boolean,
    setShowStartTransition: (showStartTransition: boolean) => void
    showEndTransition: boolean,
    setShowEndTransition: (showEndTransition: boolean) => void
    transitionColor: string,
    setTransitionColor: (transitionColor: string) => void
}

export default function ConfigMenu(props: ConfigMenuProps) {

    const [fontDropdownOpen, setFontDropdownOpen] = useState(false);
    const [textBackgroundTypeDropdownOpen, setTextBackgroundTypeDropdownOpen] = useState(false);

    let { text, height, width, verticalStart, horizontalStart, playing, setText, setVerticalStart, setHorizontalStart, textColor, setTextColor, textBackgroundColor, setTextBackgroundColor, showTextBackgroundColor, setShowTextBackgroundColor,
        font, setFont, fontLineHeight, setFontLineHeight, fontSize, setFontSize, audioFile, setShowSelectAudio, duration, setDuration,
        showStartTransition, setShowEndTransition, showEndTransition, setShowStartTransition, transitionColor, setTransitionColor, textBackgroundType } = props
    return <Row>
        <Col md={{ size: 12 }}>
            <Form className="bg-light p-2 m-2 rounded border fs-6">
                <InputGroup>
                    <Input name="text" id="textInput" placeholder="Texto do video" type="textarea" value={text} disabled={playing} onChange={(event: any) => setText(event.target.value)} />
                </InputGroup>
                <InputGroup className="mt-2">
                    <Label for="duration">Altura do Texto: {verticalStart}</Label>
                    <Input id="duration" value={verticalStart} name="duration" type="range" min={1} max={height} step={1} onChange={e => setVerticalStart(+e.target.value)} />
                </InputGroup>
                {textBackgroundType !== 'around_text' && <InputGroup className="mt-2">
                    <Label for="duration">Posição Horizontal do Texto: {horizontalStart}</Label>
                    <Input id="duration" value={horizontalStart} name="duration" type="range" min={1} max={width} step={1} onChange={e => setHorizontalStart(+e.target.value)} />
                </InputGroup>}
                <FormGroup className="mt-2">
                    <Label for="colorInput">
                        Cor do texto
                    </Label>
                    <Input value={textColor} onChange={(event: any) => setTextColor(event.target.value)}
                        id="colorInput"
                        name="color"
                        placeholder="color placeholder"
                        type="color"
                    />
                </FormGroup>
                <FormGroup switch className="mt-1 mb-2">
                    <Input checked={showTextBackgroundColor} type="switch" role="switch" onChange={e => { setShowTextBackgroundColor(e.target.checked) }} />
                    <Label check>Fundo do Texto</Label>
                </FormGroup>
                {showTextBackgroundColor && <FormGroup>
                    <Input value={textBackgroundColor} onChange={(event: any) => setTextBackgroundColor(event.target.value)}
                        id="colorBackgroundInput"
                        name="color"
                        placeholder="color placeholder"
                        type="color"
                    />
                    <Dropdown className="mt-2" isOpen={textBackgroundTypeDropdownOpen} toggle={() => setTextBackgroundTypeDropdownOpen(!textBackgroundTypeDropdownOpen)} >
                        <DropdownToggle caret>{textBackgroundType === 'fixed_left' ? 'Esquerda' : 'Em volta do Texto'}</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => props.setTextBackgroundType('fixed_left')}>Esquerda</DropdownItem>
                            <DropdownItem onClick={() => props.setTextBackgroundType('around_text')}>Em volta do Texto</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </FormGroup>}
                <FormGroup className="fs-5">
                    <Dropdown className="mb-1" isOpen={fontDropdownOpen} toggle={() => setFontDropdownOpen(!fontDropdownOpen)} >
                        <DropdownToggle caret>{font}</DropdownToggle>
                        <DropdownMenu>
                            {['Helvetica', 'Arial', 'Arial Black', 'Verdana', 'Tahoma', 'Trebuchet MS', 'Gill Sans',
                                'Times New Roman', 'Georgia', 'Palatino', 'Baskerville', 'Courier', 'Lucida', 'Monaco',
                                'Bradley Hand', 'Brush Script MT', 'Luminari', 'Comic Sans MS'].map((font, index) => <DropdownItem key={`font_${index}`} onClick={() => setFont(font)}>{font}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                    <InputGroup className="mb-1">
                        <InputGroupText>Altura da Linha</InputGroupText>
                        <Input placeholder="Altura da Linha" type="number" value={fontLineHeight} onChange={(event) => setFontLineHeight(+event.target.value)} />
                        <InputGroupText>px</InputGroupText>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupText>Tamanho da Fonte</InputGroupText>
                        <Input placeholder="Tamanho da fonte" type="number" value={fontSize} onChange={(event) => setFontSize(+event.target.value)} />
                        <InputGroupText>px</InputGroupText>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <InputGroup>
                        <Input disabled={true} name="audioTextInput" id="audioTextInput" placeholder="Arquivo de áudio" value={audioFile} />
                        <Button outline color="primary" onClick={() => setShowSelectAudio(true)}>{audioFile.length > 0 ? 'Trocar' : 'Selecionar'}</Button>
                    </InputGroup>
                </FormGroup>
                <InputGroup className="mt-2">
                    <Label for="duration">Duração: {duration / 1000} segundos</Label>
                    <Input id="duration" value={duration / 1000} name="duration" type="range" min={2} max={30} step={0.5} onChange={e => setDuration(+e.target.value * 1000)} />
                </InputGroup>
                <FormGroup className="mt-2" switch>
                    <Input checked={showStartTransition} type="switch" role="switch" onChange={e => setShowStartTransition(e.target.checked)} />
                    <Label check>Adicionar transição de início</Label>
                </FormGroup>
                <FormGroup className="mt-2" switch>
                    <Input checked={showEndTransition} type="switch" role="switch" onChange={e => setShowEndTransition(e.target.checked)} />
                    <Label check>Adicionar transição de fim</Label>
                </FormGroup>
                <FormGroup className="mt-2">
                    <Label for="colorInput">
                        Cor da transição
                    </Label>
                    <Input value={transitionColor} onChange={(event: any) => setTransitionColor(event.target.value)}
                        id="transitionColorInput"
                        name="transitionColor"
                        placeholder="color placeholder"
                        type="color"
                    />
                </FormGroup>
            </Form>
        </Col>
    </Row>
}