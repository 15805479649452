import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faArrowLeft, faFileImage, faImage, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button, Form, FormGroup, Label, Input, Container, Alert, Spinner, Modal, ModalBody, ModalHeader, ModalFooter, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select'

import Company from '../../../models/Company';
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../../constants/images'
import Module, { Frequency } from '../../../models/Module';
import config from '../../../config';
import * as ROUTES from '../../../constants/routes'
import TokenHelper from '../../../auth/TokenHelper';
import ContentService from '../../../services/contentService';
import AdminContentService from '../../../services/admin/adminContentService';
import LoadingScreen from '../../loading';
import FileHelper from '../../../files/FileHelper'
import { AuthData, ContentConfig, SignUpConfig } from '../../../models/CompanyConfig';
import ModuleAccessMainComponent from './ModuleAccess/ModuleAccessMainComponent';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useUser } from '../../../hooks/useUser';

interface State {
    module?: Module;
    signUpConfig?: SignUpConfig;
    contentConfig?: ContentConfig;
    error?: any;
    isLoading: boolean;
    title: string;
    pos: number;
    authDataSelectedValues: { [id: string]: string[] }
    categoriesSelectedValues: Array<{ id: string, title: string }>
    limiterQuiz: number
    frequency: string
    hasLimitedAccess: boolean
    authDataFilterOn: boolean
    warningMessage?: string
    saveAfterWarning?: () => void
    showModuleAccessUpload: boolean
};

const INITIAL_STATE: State = {
    module: undefined,
    signUpConfig: undefined,
    error: undefined,
    isLoading: false,
    title: '',
    pos: 1,
    authDataSelectedValues: {},
    categoriesSelectedValues: [],
    limiterQuiz: 0,
    frequency: Frequency.Day,
    hasLimitedAccess: false,
    authDataFilterOn: false,
    showModuleAccessUpload: false,
};

const fileHelper = new FileHelper()

const EditModuleScreen = () => {

    const uploadButton = useRef<HTMLInputElement>(null) 
    const { height } = useWindowDimensions()
    const navigate = useNavigate()
    const { moduleId } = useParams()
    const { company } = useUser()
    const [state, setState] = React.useState<State>(INITIAL_STATE)

    useEffect(() => {
        if(company && moduleId) {
            loadCompanySignUpContentConfig(company.id, moduleId)
        }
    }, [company, moduleId])

    useEffect(() => {
        if(company && moduleId && state.contentConfig) {
            loadModule(company.id, moduleId, state.contentConfig)
        }
    }, [company, moduleId, state.contentConfig])


    const loadModule = async (companyId: string, moduleId: string, contentConfig?: ContentConfig) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let module = await contentService.getCompanyModule(token, config.endpoint, companyId, moduleId)
            if (!module) setState({ ...state, error: 'Missão não encontrada!', isLoading: false })
            let categoriesSelectedValues = (module.categories.length > 0 && contentConfig && contentConfig.moduleCategories) ? module.categories.reduce((acc, id) => {
                let categorySearch = contentConfig.moduleCategories?.filter(category => { return category.id === id })
                if (categorySearch && categorySearch.length > 0) acc.push(categorySearch[0])
                return acc
            }, [] as Array<{ id: string, title: string }>) : []
            setState({ ...state, module, title: module.title, pos: module.pos, limiterQuiz: module.limiterQuiz, frequency: module.frequency, authDataSelectedValues: module.allowAccessAuthData || {}, categoriesSelectedValues, isLoading: false, hasLimitedAccess: module.hasLimitedAccess, authDataFilterOn: module.allowAccessAuthData ? true : false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadModule(companyId, moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const loadCompanySignUpContentConfig = async (companyId: string, moduleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let { signUpConfig, contentConfig } = await contentService.getCompanySignUpAndContentConfig(token, config.endpoint, companyId)
            setState({ ...state, isLoading: false, signUpConfig, contentConfig })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadCompanySignUpContentConfig(companyId, moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const isInputValid = (title: string) => {
        return title.length > 3
    }

    const uploadFile = async (moduleId: string, file: File | undefined) => {
        if (!file) return
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let imageUrl = await fileHelper.getFileDataUrl(file)
            let image = await fileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await fileHelper.resizeImage(canvas, image, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            let formData = new FormData()
            formData.append('file', resizedImageBlob)
            let adminContentService = new AdminContentService()
            let pic = await adminContentService.uploadModulePic(token, config.endpoint, moduleId, formData)
            let module = state.module!
            setState({ ...state, module: { ...module, pic }, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                uploadFile(moduleId, file)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const proxyUpdateModule = (moduleId: string, title: string, pos: number, authDataSelectedValues: { [id: string]: string[] }, categories: Array<string>, hasLimitedAccess: boolean, limiterQuiz: number, frequency: string, companyConfigAuthData?: { [id: string]: AuthData }) => {
        if (companyConfigAuthData && !hasLimitedAccess) {
            // verifica se ele não deixou nenhuma propriedade de restrição liberada para todos os usuários
            let unrestrictedPropertyNames: Array<string> = []
            Object.keys(companyConfigAuthData).forEach(companyConfigAuthDataId => {
                if (!authDataSelectedValues[companyConfigAuthDataId]) unrestrictedPropertyNames.push(companyConfigAuthData[companyConfigAuthDataId].title)
            })
            if (unrestrictedPropertyNames.length > 0) {
                // mostra mensagem avisando que para determinadas propriedades ele está liberado para todos
                let properties = unrestrictedPropertyNames.reduce((acc, curr, index) => {
                    if (index === unrestrictedPropertyNames.length - 1) acc += curr
                    else acc += `${curr}, `
                    return acc
                }, '')
                let warningMessage = `As seguintes propriedades estão sem restrição de acesso: ${properties}. Tem certeza que deseja continuar?`
                let saveAfterWarning = () => updateModule(moduleId, title, pos, authDataSelectedValues, categories, hasLimitedAccess, limiterQuiz, frequency)
                setState({ ...state, warningMessage, saveAfterWarning })
            } else {
                updateModule(moduleId, title, pos, authDataSelectedValues, categories, hasLimitedAccess, limiterQuiz, frequency)
            }
        } else {
            updateModule(moduleId, title, pos, authDataSelectedValues, categories, hasLimitedAccess, limiterQuiz, frequency)
        }
    }

    const updateModule = async (moduleId: string, title: string, pos: number, authDataSelectedValues: { [id: string]: string[] }, categories: Array<string>, hasLimitedAccess: boolean, limiterQuiz: number, frequency: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            await adminContentService.editModule(token, config.endpoint, moduleId, title, pos, authDataSelectedValues, categories, hasLimitedAccess, limiterQuiz, frequency)
            navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SELECT_MODULE}`)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                updateModule(moduleId, title, pos, authDataSelectedValues, categories, hasLimitedAccess, limiterQuiz, frequency)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

   const openSelectImageDialog = () => {
        if (uploadButton.current != null) {
            uploadButton.current.click()
        }
    }

    const addSelectedValue = (id: string, value: string) => {
        let authDataSelectedValues = state.authDataSelectedValues
        if (value === 'Todos') {
            delete authDataSelectedValues[id]
        } else {
            if (!authDataSelectedValues[id]) authDataSelectedValues[id] = [value]
            else if (authDataSelectedValues[id].filter(val => val === value).length === 0) authDataSelectedValues[id].push(value)
        }
        setState({ ...state, authDataSelectedValues })
    }

    const removeSelectedValue = (id: string, value: string) => {
        let authDataSelectedValues = state.authDataSelectedValues
        if (authDataSelectedValues[id].length === 1) {
            delete authDataSelectedValues[id]
        } else {
            // vai toma no cu
            authDataSelectedValues[id] = authDataSelectedValues[id].filter(val => val !== value) as [string]
        }
        setState({ ...state, authDataSelectedValues })
    }

    const dropDownAuthDataSelection = (selection: Array<{ value: string, label: string }>, id: string) => {
        let authDataSelectedValues = state.authDataSelectedValues
        if (selection === undefined || selection.length === 0) {
            delete authDataSelectedValues[id]
            setState({ ...state, authDataSelectedValues })
        } else {
            authDataSelectedValues[id] = selection.map(selection => selection.value)
            setState({ ...state, authDataSelectedValues })
        }
    }

    const onCategorySelected = (selection: Array<{ value: string, label: string }>) => {
        setState({ ...state, categoriesSelectedValues: selection.map(({ value, label }) => { return { id: value, title: label } }) })
    }

    const renderHeader = (company: Company) => {
        return (<Row className="pt-2 pb-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SELECT_MODULE}`) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#1d3256', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Editar Missão</b></div>
                    <img alt='foto da empresa' style={{ height: 60, marginBottom: 10, marginTop: 10 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderModulePicOrLoading = (modulePic: string | undefined, loadingProfilePic: boolean) => {
        if (loadingProfilePic) {
            return (<div className="d-flex justify-content-center">
                <Spinner style={{ minHeight: '25vh', maxHeight: '25vh', color: 'black', marginBottom: 5, marginTop: 5 }} />{' '}
            </div>)
        }
        return (<div className="d-flex justify-content-center" onClick={() => openSelectImageDialog()}>
            {modulePic ? <img alt='company pic' style={{ objectFit: 'contain', width: '12em', height: '12em', marginBottom: 5, marginTop: 5, borderRadius: '6em' }} src={modulePic} /> : <FontAwesomeIcon icon={faFileImage} size='4x' />}
        </div>)
    }

    const renderAuthDataDropdownSelection = (authData: { [id: string]: AuthData }, authDataSelectedValues: { [id: string]: string[] }) => {
        let authDataKeys = Object.keys(authData)
        return (<div style={{ marginBottom: 5 }}>
            {authDataKeys.filter(id => { return authData[id].values && authData[id].values.length > 0 }).map((id, index) => {
                let currentAuthData = authData[id]
                let currentValue = authDataSelectedValues[id] ? authDataSelectedValues[id].map(val => { return { value: val, label: val } }) : []
                return (<FormGroup key={index} style={{ marginBottom: 10 }}>
                    <Label>{currentAuthData.title}</Label>
                    <Select isMulti placeholder="Liberado para todos" value={currentValue} options={currentAuthData.values.map(val => { return { value: val, label: val } })} onChange={opt => dropDownAuthDataSelection(opt as Array<{ value: string, label: string }>, id)} />
                </FormGroup>)
            })}
        </div>)

    }

    const renderModuleCategoryDropdownSelection = (categories: Array<{ id: string, title: string }>, categoriesSelectedValues: Array<{ id: string, title: string }>) => {
        return (<FormGroup style={{ marginBottom: 10 }}>
            <Label>Categoria</Label>
            <Select isMulti placeholder="Nenhuma" value={categoriesSelectedValues.map(({ id, title }) => { return { value: id, label: title } })} options={categories.map(({ id, title }) => { return { value: id, label: title } })} onChange={opt => onCategorySelected(opt as Array<{ value: string, label: string }>)} />
        </FormGroup>)
    }

    const renderForm = (signUpConfig: SignUpConfig, contentConfig: ContentConfig | undefined, moduleId: string, title: string, pos: number, authDataSelectedValues: { [id: string]: string[] }, categoriesSelectedValues: Array<{ id: string, title: string }>, authDataFilterOn: boolean, hasLimitedAccess: boolean, limiterQuiz: number, frequency: string) => {
        return (<Col className="d-flex flex-column justify-content-center relative pt-2" md={{ size: 6, offset: 3 }}>
            <Form className="bg-light p-2 m-2 rounded">
                <input id="myInput" type="file" ref={uploadButton} style={{ display: 'none' }} onChange={(event) => uploadFile(moduleId, event.target.files ? event.target.files[0] : undefined)} />
                <FormGroup>
                    <Label for="moduleTitle">Título</Label>
                    <Input name="title" id="moduleTitle" placeholder="Nome da missão" value={title} onChange={(event: any) => setState({ ...state, title: event.target.value })} />
                </FormGroup>
                <FormGroup>
                    <Label for="position">Posição</Label>
                    <Input type="select" value={pos} name="selectMulti" id="position" onChange={(event: any) => setState({ ...state, pos: +event.target.value })}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                    </Input>
                    <FormText>As missões aparecem para o usuário em ordem da menor para a maior posição.</FormText>
                </FormGroup>
                <FormGroup switch>
                    <Input checked={authDataFilterOn} type="switch" role="switch" onChange={e => setState({ ...state, authDataFilterOn: e.target.checked })} />
                    <Label check>Restringir acesso por tipo de usuário</Label>
                    <FormText>
                        <br/>Permite definir uma segmentação por tipo de usuário que pode acessar o treinamento.
                    </FormText>
                    {authDataFilterOn && signUpConfig.authData && renderAuthDataDropdownSelection(signUpConfig.authData, authDataSelectedValues)}
                </FormGroup>
                {contentConfig && contentConfig.moduleCategories && contentConfig.moduleCategories.length > 0 && renderModuleCategoryDropdownSelection(contentConfig.moduleCategories, categoriesSelectedValues)}
                <FormGroup switch className="mt-2">
                    <Input checked={hasLimitedAccess} type="switch" role="switch" onChange={e => setState({ ...state, hasLimitedAccess: e.target.checked })} />
                    <Label check>Liberar acesso para usuários selecionados</Label>
                    <FormText>
                        <br/>Permite subir uma planilha com dados de usuários para acessar este treinamento.<br/>
                    </FormText>
                    {hasLimitedAccess && <Button type='button' outline color='primary' className="mt-1 mb-1" onClick={() => { setState({ ...state, showModuleAccessUpload: true })}}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faUsers} />Editar Lista</Button>}
                </FormGroup>
                <FormGroup switch className="mt-2">
                    <Input type="switch" role="switch" checked={limiterQuiz > 0} onChange={e => setState({ ...state, limiterQuiz: e.target.checked ? 10 : 0 })} />
                    <Label check>Limitar número de tentativas do Quiz</Label>
                    <FormText>
                        <br/>O quiz é um banco de questões aleatórias que servem para avaliar se ocorreu o aprendizado. Você pode limitar quantas vezes o usuário pode jogar o quiz em um determinado período.
                    </FormText>
                </FormGroup>
                {limiterQuiz > 0 && <Row className="mt-1">
                    <Col md={6}>
                        <FormGroup>
                            <Label for="limiterQuiz">Número de Tentativas</Label>
                            <Input name="limiterQuiz" id="limiterQuiz" type="number" value={limiterQuiz} min={1} max={100} onChange={(event: any) => setState({ ...state, limiterQuiz: +event.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="frequency">Período</Label>
                            <Input type="select" value={frequency} name="frequency" id="frequency" onChange={(event: any) => setState({ ...state, frequency: event.target.value })}>
                                <option value={Frequency.Day}>Por dia</option>
                                <option value={Frequency.Week}>Por semana</option>
                                <option value={Frequency.Month}>Por mês</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>}
            </Form>
            <Button block disabled={!isInputValid(title)} color="primary" onClick={() => proxyUpdateModule(moduleId, title, pos, authDataSelectedValues, categoriesSelectedValues.map(({ id }) => { return id }), hasLimitedAccess, limiterQuiz, frequency, signUpConfig.authData)}>Salvar</Button>
        </Col>)
    }

    const renderWarning = (warningMessage: string, saveAfterWarning: (() => void) | undefined) => {
        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => setState({ ...state, warningMessage: undefined, saveAfterWarning: undefined })}>
                    <ModalHeader>Tem certeza que quer salvar esta Missão sem restrições de acesso?</ModalHeader>
                    <ModalBody>
                        <div style={{ fontFamily: 'Montserrat' }}>{warningMessage}</div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center align-items-center' >
                        <Button color="secondary" onClick={() => setState({ ...state, warningMessage: undefined, saveAfterWarning: undefined })}>Não, voltar para edição</Button>
                        <Button color="primary" onClick={() => { if (saveAfterWarning) saveAfterWarning() }}>Sim, quero salvar</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    let { error, warningMessage, saveAfterWarning, isLoading, module, title, pos, signUpConfig, contentConfig,
        authDataSelectedValues, categoriesSelectedValues, authDataFilterOn, hasLimitedAccess, limiterQuiz, frequency, showModuleAccessUpload } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    if(showModuleAccessUpload) return <ModuleAccessMainComponent exit={() => setState({ ...state, showModuleAccessUpload: false })} primaryProperty={signUpConfig!.primaryProperty} companyId={module!.companyId} moduleId={module!.id} title={module!.title} pic={module!.pic} height={height} backgroundImage={backgroundImage} backgroundPosition={backgroundPosition} />

    return (<Container className="d-flex flex-column relative" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && <Row><Col md={{ size: 6, offset: 3 }}><Alert color="danger">{error}</Alert></Col></Row>}
        {warningMessage && renderWarning(warningMessage, saveAfterWarning)}
        {company && renderHeader(company)}
        {module && <Row style={{ marginBottom: 5, overflow: 'auto', zIndex: 1 }}>
            <Col className="d-flex flex-column justify-content-center" md={{ size: 6, offset: 3 }}>
                {renderModulePicOrLoading(module.pic, isLoading)}
                <div className="d-flex justify-content-center">
                    <Button style={{ borderStyle: 'none', background: '#444', margin: 10, width: 'auto' }} onClick={() => openSelectImageDialog()}><FontAwesomeIcon icon={faImage} /> Trocar foto da missão</Button>
                </div>
            </Col>
            {module && signUpConfig && renderForm(signUpConfig, contentConfig, module.id, title, pos, authDataSelectedValues, categoriesSelectedValues, authDataFilterOn, hasLimitedAccess, limiterQuiz, frequency)}
        </Row>}
    </Container>)
}

export default EditModuleScreen