import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCalendarAlt, faCheckCircle, faGamepad, faInfoCircle, faTrashAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'

import User from '../../../models/User';
import { UserCompanyScore } from '../../../models/Scores';
import { SubModule, SubModuleTypes } from '../../../models/SubModule';
import Module from '../../../models/Module';
import { Button } from 'reactstrap';
import StarSvg from '../../../images/star.svg'
import ProgressBar from '../../../components/ProgressBar';

interface UserDataProps { user: User, editUserProfile: (userId: string) => void }

export default class UserDataComponent extends Component<UserDataProps> {

    renderAuthDataProperty(val: string) {
        return <div style={{ fontSize: 12, marginLeft: 5, marginBottom: 5, color: '#353f45' }}>{val}</div>
    }

    renderUserProfilePic(user: User) {
        if (!user.pic) return (<div><FontAwesomeIcon color='black' icon={faUserCircle} size='4x' /></div>)
        return <img style={{ width: '4em', height: '4em', borderRadius: '2em', objectFit: 'cover' }} alt='prof pic' src={user.pic} />
    }

    render() {
        let user = this.props.user
        let createdDate = new Date(user.createdDateInMillis)
        let formattedCratedDate = `${createdDate.toLocaleDateString("pt-BR")} - ${createdDate.toLocaleTimeString("pt-BR")}`
        return <div style={{ marginLeft: 5, marginTop: 2 }} className="d-flex flex-row align-items-center">
            {this.renderUserProfilePic(user)}
            <div className="d-flex flex-column" style={{ flex: 1 }}>
                <div style={{ fontSize: 'small', marginLeft: 5, marginTop: 5, color: '#353f45' }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faInfoCircle} />{user.doc || user.email}</div>
                <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', margin: 5 }}>{user.username}</div>
                {user.authData && Object.keys(user.authData).sort().map(key => this.renderAuthDataProperty(user.authData![key]))}
                {user.active && <span style={{ marginLeft: 5, fontSize: 'small', color: 'rgb(112, 153, 124)' }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCheckCircle} /><b>Conta criada e senha definida em {formattedCratedDate}</b></span>}
                {!user.active && <span style={{ marginLeft: 5, fontSize: 'small', color: 'rgb(164, 47, 47)' }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faBan} /><b>Usuário bloqueado</b></span>}
            </div>
            <Button color='info' outline style={{ padding: 5, marginLeft: 4, marginRight: 4 }} onClick={() => { this.props.editUserProfile(user.id) }}><FontAwesomeIcon icon={faUserCircle} /> Editar Perfil</Button>
        </div>
    }
}

interface UserGameProps { userScore: UserCompanyScore, lastTimeUserPlayed: any, modules: Array<Module>, subModules: Array<SubModule>,
    downloadCertificate: (moduleId: string, userId: string) => void,
    deleteUserScoreInModule: (module: Module) => void,
}

export class UserGameComponent extends Component<UserGameProps> {

    renderModuleScore(userScore: UserCompanyScore, lastTimeUserPlayedModule: number | undefined, module: Module, moduleSubModules: Array<SubModule>) {
        let maxScoreInModuleSum = moduleSubModules.reduce((acc, subModule) => { return acc + subModule.maxScore }, 0)
        let moduleScore = moduleSubModules.reduce((score, subModule) => score + (userScore.subModuleScores[subModule.id] || 0), 0)
        let subModulesFinishedCount = moduleSubModules.filter(subModule => (subModule.maxScore === 0 || (userScore.subModuleScores[subModule.id] &&  userScore.subModuleScores[subModule.id] >= (subModule.maxScore / 2)))).length
        if (subModulesFinishedCount > 0) {
            let scorePercentage = (moduleScore * 100 / maxScoreInModuleSum)
            let scorePercentageStr = scorePercentage.toFixed(2)

            // Lógica pra mostrar o botão de baixar certificado
            // hasCertificate pode ser undefined. se for entao tem certificado. só quando ele é falso é q n tem certificado
            var showDownloadCertificateButton = module && module.hasCertificate !== false && subModulesFinishedCount === moduleSubModules.length

            // Verifica se tem quiz. Caso contrario ainda nao terminou de liberar as fases
            if (showDownloadCertificateButton && moduleSubModules.filter(subModule => subModule.type === SubModuleTypes.QUIZ).length === 0) {
                // Se tem quiz, verifica se jogou todos as fases
                showDownloadCertificateButton = false
            }

            // Se tem quiz, verifica se jogou todos as fases
            if (showDownloadCertificateButton) {
                if (scorePercentage < 50) showDownloadCertificateButton = false
            }

            let progressBarColor = subModulesFinishedCount === moduleSubModules.length ? '#40b34f' : (subModulesFinishedCount > (moduleSubModules.length * 0.5) ? '#f1b938' : '#f0652f' )

            return (<div key={module.id} className="d-flex flex-column" style={{ flex: 1 }}>
                <div style={{ height: 1, background: '#aaa', margin: 4 }} />
                <div style={{ textOverflow: 'ellipsis', color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 5, marginTop: 10 }}><b>Missão</b></div>
                <div style={{ fontSize: 'large', textOverflow: 'ellipsis', color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 5 }}>{module.title}</div>
                <div className="d-flex align-items-center">
                    <img style={{ height: '4em', width: '4em', marginLeft: 5, marginTop: 10, marginBottom: 15, borderRadius: '2em', objectFit: 'contain' }} alt='foto da missão' src={module.pic} />
                    <div className="d-flex flex-column" style={{ flex: 1, marginLeft: 5, marginTop: 15, marginBottom: 15 }}>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 5 }}><img style={{ width: '1em', height: '1em', paddingBottom: 4 }} alt='ícone de pontos' src={StarSvg} />{` ${moduleScore} de ${maxScoreInModuleSum} (${scorePercentageStr}%)`}</div>
                        <div style={{ marginTop: 5, marginLeft: 5, maxWidth: 250 }}><ProgressBar bgColor={progressBarColor} completed={subModulesFinishedCount / moduleSubModules.length * 100} height={8} radius={2} /></div>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faGamepad} />{` ${subModulesFinishedCount} de ${moduleSubModules.length}`}</div>
                        {lastTimeUserPlayedModule && <div style={{ fontSize: 'small', color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCalendarAlt} />{`Jogou pela última vez em ${(new Date(lastTimeUserPlayedModule)).toLocaleDateString()}`}</div>}
                    </div>
                    <div className="d-flex flex-column">
                        {showDownloadCertificateButton && <Button outline color='success' style={{ padding: 5, marginLeft: 4, marginRight: 4 }} onClick={() => { this.props.downloadCertificate(module.id, userScore.userId) }}><FontAwesomeIcon icon={faCheckCircle} /> Certificado</Button>}
                        {moduleScore > 0 && <Button outline color='danger' style={{ padding: 5, marginTop: 10, marginBottom: 10, marginLeft: 4, marginRight: 4 }} onClick={() => { this.props.deleteUserScoreInModule(module) }}><FontAwesomeIcon icon={faTrashAlt} /> Zerar Pontuação</Button>}
                    </div>
                </div>
            </div>)
        }
    }

    render() {

        return (<div className="d-flex flex-column" style={{ marginTop: 2 }}>
            {this.props.modules.map(module => {
                let subModules = this.props.subModules.filter(subModule => subModule.moduleId === module.id)
                let lastTimeUserPlayedModule = this.props.lastTimeUserPlayed ? this.props.lastTimeUserPlayed[module.id] : undefined
                return this.renderModuleScore(this.props.userScore, lastTimeUserPlayedModule, module, subModules)
            })}
        </div>)
    }
}