import { faArrowLeft, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component, CSSProperties, Fragment } from 'react'
import { Row, Col, Button } from 'reactstrap'
import _ from 'lodash'
import { WORD_GUESS_MAX_ATTEMPTS } from '../constants/ScoreConstants'
import Company from '../models/Company'

interface State {
    wordFound: boolean
    selectedWords: Array<string>
    attempts: number
}

const INTIAL_STATE: State = {
    wordFound: false,
    selectedWords: [],
    attempts: 0,
}

interface Props { company: Company, title: string, word: string, height: string, onWordGuessFinished: (attempts: number) => void, onExit: () => void, showExitButton: boolean }

export default class WordGuessComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = { ...INTIAL_STATE }
    }

    lowerCaseAndRemovePunctuationFromString(data: string) {
        return data.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ").replace(/[àáâãäå]/g,"a")
        .replace(new RegExp(/ç/g),"c").replace(new RegExp(/[èéêë]/g),"e").replace(new RegExp(/[ìíîï]/g),"i")
        .replace(new RegExp(/ñ/g),"n").replace(new RegExp(/[òóôõö]/g),"o").replace(new RegExp(/œ/g),"oe")
        .replace(new RegExp(/[ùúûü]/g),"u").replace(new RegExp(/[ýÿ]/g),"y")
    }

    // Bem, aparentemente mudar o state do componente Parent não necessariamente
    // faz o state do componente Child ser atualizado.
    // https://stackoverflow.com/questions/48502546/reactjs-child-not-updating-after-parent-state-changed
    componentWillReceiveProps(nextProps: Props) {
        // essa funcao vai ser chamada toda vez que o timer for atualizado
        // portanto é necessario verificar se o card foi trocado
        if (nextProps.title !== this.props.title || nextProps.word !== this.props.word) {
            this.setState({ ...INTIAL_STATE });
        }
        // Meu deus, é gambiarra em cima de gambiarra
    }

    wordSelected(char: string) {
        if(this.state.wordFound) return
        let newWordsArray = [...this.state.selectedWords]
        newWordsArray.push(char.toLowerCase())
        this.setState({ selectedWords: newWordsArray }, () => this.checkIfWordWasFound(char))
    }

    checkIfWordWasFound(char: string) {
        let wordCharsSplitterAndLowerCase = this.lowerCaseAndRemovePunctuationFromString(this.props.word).split('')
        let foundChar = _.includes(wordCharsSplitterAndLowerCase, char.toLowerCase())
        if(foundChar) {
            // Checa se achou todas as palavras
            let foundWord = wordCharsSplitterAndLowerCase.reduce((acc, char) => {
                if(!acc) return acc
                else if(!_.includes(this.state.selectedWords, char)) return false
                else return true
            }, true)
            if(foundWord) {
                this.props.onWordGuessFinished(this.state.attempts)
                this.setState({ wordFound: true })
            }
        } else {
            let attempts = this.state.attempts + 1
            if(attempts >= WORD_GUESS_MAX_ATTEMPTS) {
                this.props.onExit()
            } else {
                this.setState({ attempts })
            }
        }
    }

    render() {

        let { attempts, selectedWords } = this.state
        let lettersArray = [
            ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
            ['J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'],
            ['S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', ' ']
        ]

        return (<Fragment>
            <Row style={{ background: '#FFFFFFDA', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', paddingTop: 5, paddingBottom: 5 }}>
                <Col className='d-flex justify-content-start align-items-center' style={{ minHeight: '4em' }} md={{ size: 8, offset: 2 }}>
                    {this.props.showExitButton && <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#343a40' icon={faArrowLeft} size='2x' /></Button>}
                    <div className='d-flex flex-column justify-content-center align-items-start' >
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 16 }}>Jogo da Forca</div>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 12 }}>Descubra a palavra clicando nas letras</div>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 10 }}>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 8, offset: 2 }}>
                    <div style={{ fontFamily: 'Montserrat', textAlign: 'center' }}><b>{this.props.title}</b></div>
                </Col>
                <Col className='d-flex justify-content-center' md={{ size: 8, offset: 2 }}>
                    {this.props.word.split('').map((wordChar, index) => {
                        let foundWord = _.includes(selectedWords, this.lowerCaseAndRemovePunctuationFromString(wordChar))
                        return (<div key={`col-${index}`} className="d-flex" style={{ minWidth: '2em', minHeight: '1em' }}>
                            {foundWord ? <div style={letterButtonCorrectStyle}>{wordChar.toUpperCase()}</div> : <div style={letterDefaultStyle}> _ </div>}
                        </div>)
                    })}
                </Col>
            </Row>
            <Row style={{ marginTop: 10, marginBottom: 5 }}>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 8, offset: 2 }}>
                    <FontAwesomeIcon style={{ marginRight: 5 }} color='red' icon={faTimesCircle} />
                    <div style={{ fontFamily: 'Montserrat', verticalAlign: 'center' }}>Tentativas Erradas: <b>{attempts}</b></div>
                </Col>
            </Row>
            <Row style={{ marginTop: 5, marginBottom: 10 }}>
                <Col className='d-flex flex-column justify-content-center' md={{ size: 8, offset: 2 }}>
                    {lettersArray.map((rowLetters, index) => {
                        return <div key={`col-${index}`} className="d-flex justify-content-center">
                            {rowLetters.map((letter) => {
                                let charFound = _.includes(selectedWords, letter.toLowerCase())
                                if (charFound) {
                                    if (_.includes(this.lowerCaseAndRemovePunctuationFromString(this.props.word).split(''), letter.toLowerCase())) {
                                        // A letra é correta
                                        return <div style={letterButtonCorrectStyle}>{letter}</div>
                                    } else {
                                        // A letra é incorreta
                                        return <div style={letterButtonWrongStyle}>{letter}</div>
                                    }
                                } else {
                                    return <div onClick={() => this.wordSelected(letter)} style={letterButtonDefaultStyle}>{letter}</div>
                                }
                            })}
                        </div>
                    })}
                </Col>
            </Row>
        </Fragment>)
    }

}

const letterDefaultStyle: CSSProperties = {
    color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', flex: 1,
    borderWidth: 1, borderColor: '#1d3256', borderRadius: 2, borderStyle: 'solid',
    margin: 5, padding: 4, minWidth: '1em'
}

const letterButtonDefaultStyle: CSSProperties = {
    color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', flex: 1,
    borderWidth: 1, borderRadius: 2, borderStyle: 'none', background: '#FFFFFFDA',
    margin: 5, padding: 4, minWidth: '1em', boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.3)'
}

const letterButtonCorrectStyle: CSSProperties = {
    color: 'white', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', flex: 1,
    borderWidth: 1, borderColor: 'green', borderRadius: 2, borderStyle: 'solid', background: 'green',
    margin: 5, padding: 4
}

const letterButtonWrongStyle: CSSProperties = {
    color: 'white', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', flex: 1,
    borderWidth: 1, borderColor: 'red', borderRadius: 2, borderStyle: 'solid', background: 'red',
    margin: 5, padding: 4
}