export default class UserModuleFeedback {
    id: string
    userId: string
    companyId: string
    moduleId: string
    feedback: string
    createdDate: number
    updatedDate: number
    constructor(data: any) {
        this.id = data.id
        this.userId = data.userId
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.feedback = data.feedback
        this.createdDate = data.createdDate
        this.updatedDate = data.updatedDate
    }
}