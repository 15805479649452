import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Alert, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import Module from '../../../models/Module';
import TokenHelper from '../../../auth/TokenHelper';
import AdminContentService from '../../../services/admin/adminContentService';
import config from '../../../config'
import LoadingScreen from '../../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faEdit, faArrowLeft, faTrashAlt, faChartBar, faInfoCircle, faPlusCircle, faAngleRight, faList } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import * as ROUTES from '../../../constants/routes'
import * as _ from 'lodash'
import { lowerCaseAndRemovePunctuationFromString } from '../../../utils/punctuation';
import ContentService from '../../../services/contentService';
import { ContentConfig } from '../../../models/CompanyConfig';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useUser } from '../../../hooks/useUser';


interface State {
    contentConfig?: ContentConfig;
    error: any | undefined;
    isLoading: boolean;
    modules: Array<Module>;
    selectedModule: Module | undefined;
    moduleDeleteId: string | undefined;
    searchInput: string;
};

const INITIAL_STATE: State = {
    contentConfig: undefined,
    error: undefined,
    isLoading: false,
    modules: [],
    selectedModule: undefined,
    moduleDeleteId: undefined,
    searchInput: '',
};

const SelectModuleScreen = () => {

    const { height } = useWindowDimensions()
    const navigate = useNavigate()
    const { company } = useUser()
    const [state, setState] = React.useState<State>(INITIAL_STATE)

    useEffect(() => {
        if (company) {
            loadCompanySignUpContentConfig(company.id)
        }
    }, [company])

    useEffect(() => {
        if (state.contentConfig) {
            loadModules()
        }
    }, [state.contentConfig])

    const loadCompanySignUpContentConfig = async (companyId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let { contentConfig } = await contentService.getCompanySignUpAndContentConfig(token, config.endpoint, companyId)
            setState({ ...state, isLoading: false, contentConfig })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadCompanySignUpContentConfig(companyId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const loadModules = async () => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let modules = await adminContentService.getCompanyModules(token, config.endpoint)
            setState({ ...state, modules, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadModules()
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const goBack = () => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}`)

    const goToSelectSubModule = (moduleId: string) => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SUBMODULE_SELECT}/${moduleId}`)

    const goToModuleContentReport = (moduleId: string) => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.MODULE_CONTENT_REPORT}/${moduleId}`)

    const goToEditModule = (moduleId: string) => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.EDIT_MODULE}/${moduleId}`)

    const goToCreateModule = (pos: number) => navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.CREATE_MODULE}?pos=${pos + 1}`)

    const changeModuleVisibility = async (id: string, visible: boolean) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            await adminContentService.changeModuleVisibility(token, config.endpoint, id, visible)
            let modules = state.modules
            let module = _.first(modules.filter(module => module.id === id))
            if (module) {
                module.visible = visible
                setState({ ...state, modules })
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                changeModuleVisibility(id, visible)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const deleteModule = async (moduleId: string) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            await adminContentService.deleteModule(token, config.endpoint, moduleId)
            let modules = state.modules.filter(module => module.id !== moduleId)
            setState({ ...state, moduleDeleteId: undefined, modules: modules.sort((a, b) => a.pos - b.pos) })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                deleteModule(moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const renderHeader = (pos: number) => {
        return (<Row className="pt-2 pb-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <div className="d-flex align-items-center justify-content-between" style={{ minHeight: '4em', fontFamily: 'Montserrat', color: '#353f45' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div style={{ verticalAlign: 'middle', textAlign: 'center', fontSize: 'large' }}><b>Treinamentos</b></div>
                    </div>
                    <Button className="d-flex justify-content-start align-items-center" color="primary" style={{ fontSize: '1em', borderStyle: 'none', paddingTop: 10, paddingBottom: 10 }} onClick={() => { goToCreateModule(pos) }}>
                        <FontAwesomeIcon style={{ verticalAlign: 'middle', marginRight: 5 }} icon={faPlusCircle} />
                        <div className="d-flex flex-column align-items-start ml-1" style={{ fontSize: '0.7em' }}>
                            <span style={{ verticalAlign: 'middle', marginTop: 1 }}>Nova Missão</span>
                        </div>
                    </Button>
                </div>
            </Col>
        </Row>)
    }

    const renderSelectMission = (searchInput: string) => {
        return (
            <Row className="mt-3">
                <Col className="d-flex flex-column align-items-center" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div className="mt-2 mb-1" style={{ width: '100%', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.8em', paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 8 }}><FontAwesomeIcon style={{ marginRight: 2 }} icon={faInfoCircle} /><i>Na plataforma, cada tema de treinamento é considerado uma <b>missão</b></i></div>
                    <Input className="mt-1" style={{ fontFamily: 'Montserrat', opacity: 0.85 }} name="title" id="docTitle" placeholder='Procurar missão' value={searchInput} onChange={(event: any) => {
                        setState({ ...state, searchInput: event.target.value })
                    }} />
                </Col>
            </Row>)
    }

    const renderModules = (modules: Array<Module>, searchInput: string, contentConfig?: ContentConfig) => {
        let searchInputWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(searchInput)
        let sortedModulesByPosition = modules.filter(module => {
            let moduleTitleWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(module.title)
            return moduleTitleWithoutPunctuation.includes(searchInputWithoutPunctuation)
        }).sort((a, b) => { return a.pos - b.pos })
        let renderModule = (module: Module) => <Col key={module.id} lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
            <div className="d-flex flex-row" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', marginBottom: 15, marginTop: 15, minHeight: 130, borderRadius: 5, padding: 15 }}>
                <div className="d-flex flex-column justify-content-center mt-2" style={{ width: '100%' }}>
                    <div className="d-flex align-items-center" style={{ marginBottom: 2 }}>
                        <img className="moduleImage" alt='foto da missão' src={module.pic} />
                        <div style={{ marginLeft: 10, flex: 1 }}>
                            <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 5 }}><b>MISSÃO</b></div>
                            <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 5 }}>{module.title.toUpperCase()}</div>
                            <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', fontSize: 'small', marginLeft: 5 }}>Posição {module.pos} {!module.visible && <span style={{ marginLeft: 10 }}><FontAwesomeIcon icon={faEyeSlash} /> Escondido</span>}</div>
                            {contentConfig && contentConfig.moduleCategories && module.categories.length > 0 && <div style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', fontSize: 'small', marginLeft: 5 }}><FontAwesomeIcon icon={faList} /> {module.categories.reduce((acc, id, index) => {
                                let categorySearch = contentConfig.moduleCategories!.filter(category => { return category.id === id })
                                if (categorySearch.length > 0) {
                                    if (index === 0 || index === module.categories.length - 1) {
                                        acc = categorySearch[0].title
                                    } else {
                                        acc = categorySearch[0].title + ', '
                                    }
                                }
                                return acc
                            }, '')}</div>}
                            <Button size="sm" outline style={{ borderStyle: 'none', paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2, marginTop: 2 }} onClick={() => { goToEditModule(module.id) }}><FontAwesomeIcon icon={faEdit} /> <b>Editar</b></Button>
                        </div>
                    </div>
                    <div className="d-flex mt-2">
                        {!module.visible && <Button size="sm" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { changeModuleVisibility(module.id, true) }} color="success"><FontAwesomeIcon icon={faEye} /> Mostrar</Button>}
                        {module.visible && <Button size="sm" outline style={{ marginTop: 5, borderStyle: 'none' }} onClick={() => { changeModuleVisibility(module.id, false) }} color="warning"><FontAwesomeIcon icon={faEyeSlash} /> Esconder</Button>}
                        <Button size="sm" outline color="primary" style={{ marginTop: 5, marginLeft: 5, borderStyle: 'none' }} onClick={() => { goToSelectSubModule(module.id) }}><FontAwesomeIcon icon={faGamepad} /> Conteúdo</Button>
                        <Button size="sm" outline style={{ marginTop: 5, marginLeft: 5, borderStyle: 'none' }} onClick={() => { goToModuleContentReport(module.id) }} color="info"><FontAwesomeIcon icon={faChartBar} /> Desempenho</Button>
                        <Button size="sm" outline color="danger" style={{ marginTop: 5, marginLeft: 5, borderStyle: 'none' }} onClick={() => { setState({ ...state, moduleDeleteId: module.id }) }}><FontAwesomeIcon icon={faTrashAlt} /> Deletar</Button>
                    </div>
                </div>
            </div>
        </Col>
        return (<Row className="pt-2" style={{ overflow: 'auto', zIndex: 1 }}>
            {sortedModulesByPosition.map(module => renderModule(module))}
        </Row>)
    }

    const renderDeleteMessage = (moduleId: string) => {
        let selectedModule = _.head(state.modules.filter(module => module.id === moduleId))!
        return (<Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => setState({ ...state, moduleDeleteId: undefined })}>
                    <ModalHeader>
                        <div style={{ fontFamily: 'Montserrat' }}>Tem certeza que quer deletar esta missão?</div>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ fontFamily: 'Montserrat' }}>Ao deletar {selectedModule.title} todo o seu conteúdo será perdido.</div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center align-items-center' >
                        <Button color="primary" onClick={() => setState({ ...state, moduleDeleteId: undefined })}>Não</Button>
                        <Button color="danger" onClick={() => deleteModule(moduleId)}>Sim, desejo deletar</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    let { error, isLoading, modules, moduleDeleteId, searchInput, contentConfig } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column relative" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {company && renderHeader(modules.reduce((acc, prev) => { return prev.pos > acc ? prev.pos : acc }, 1))}
        {error && <Alert color="danger">{error}</Alert>}
        {moduleDeleteId && renderDeleteMessage(moduleDeleteId)}
        {renderSelectMission(searchInput)}
        {renderModules(modules, searchInput, contentConfig)}
    </Container>)

}

export default SelectModuleScreen