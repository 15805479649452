import React, { Component, Fragment } from 'react';
import * as _ from 'lodash'
import { Row, Col, Input, Button, Alert, Form, FormGroup, Label, FormText, Container } from 'reactstrap'
import DeckCard, { DeckCardTypes } from '../../../../../models/DeckCards';
import TokenHelper from '../../../../../auth/TokenHelper';
import AdminContentService from '../../../../../services/admin/adminContentService';
import { SubModule } from '../../../../../models/SubModule';
import config from '../../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Module from '../../../../../models/Module';
import CardImage from '../../../../../models/CardImage';
import CardGif from '../../../../../models/CardGif';
import Zoom from 'react-medium-image-zoom'
import GameSvg from '../../../../../images/game.svg'
import ImageSvg from '../../../../../images/image.svg'

// Card Gifs
import InfoCardGif from "../../../../../images/CardGifs/InfoCard.gif";
import GifCardGif from "../../../../../images/CardGifs/GifCard.gif";
import DescriptionCardGif from "../../../../../images/CardGifs/DescriptionCard.gif";
import WordSearchCardGif from "../../../../../images/CardGifs/WordSearchCard.gif";
import MemoryGameCardGif from "../../../../../images/CardGifs/MemoryGameCard.gif";
import QuestionCardGif from "../../../../../images/CardGifs/QuestionCard.gif";
import SelectAnswerCardGif from "../../../../../images/CardGifs/SelectAnswerCard.gif";
import OrderedListCardGif from "../../../../../images/CardGifs/OrderedListCard.gif";
import ImageErrrosCardGif from "../../../../../images/CardGifs/ImageErrorsCard.gif"
import CreateInfoCardComponent from './CreateInfoCardComponent';
import CreateGifCardComponent from './CreateGifCardComponent';
import CreateDescriptionCardComponent from './CreateDescriptionCardComponent';
import CreateQuestionCardComponent from './CreateQuestionCardComponent';
import CreateSelectAnswerCardComponent from './CreateSelectAnswerCardComponent';
import CreateWordSearchComponent from './CreateWordSearchComponent';
import CreateMemoryCardComponent from './CreateMemoryCardComponent';
import CreateOrderedListCardComponent from './CreateOrderedListCardComponent';
import CreateImageErrorsCardComponent from './CreateImageErrorsCardComponent'
import { ICreateCard, IGifGetUpload, IImageGetUpload } from './interfaces';

interface Props {
    subModule: SubModule
    lastPosition: number
    addCard: (card: DeckCard) => void
    exit: () => void
}

interface State {
    cardType: string;
    error: any | undefined;
    showPreview: boolean;
    isPickingFile: boolean;
}

const INITIAL_STATE: State = {
    cardType: DeckCardTypes.Info,
    error: undefined,
    showPreview: true,
    isPickingFile: false,
};

export default class DeckComponent extends Component<Props, State> implements ICreateCard, IImageGetUpload, IGifGetUpload {

    constructor(props: Props) {
        super(props)

        this.state = { ...INITIAL_STATE }

    }

    changeCardType = (newType: string) => {
        if (newType === 'Texto e Imagem') {
            this.setState({ cardType: DeckCardTypes.Info })
        } else if (newType === 'Texto e Gif') {
            this.setState({ cardType: DeckCardTypes.Gif })
        } else if (newType === 'Título, Textos e Imagem') {
            this.setState({ cardType: DeckCardTypes.Description })
        } else if (newType === 'Caça-Palavras') {
            this.setState({ cardType: DeckCardTypes.WordSearch })
        } else if (newType === 'Acertar Resposta') {
            this.setState({ cardType: DeckCardTypes.Question })
        } else if (newType === 'Jogo da Memória') {
            this.setState({ cardType: DeckCardTypes.Memory })
        } else if (newType === 'Passo-a-Passo') {
            this.setState({ cardType: DeckCardTypes.OrderedList })
        } else if (newType === 'Selecionar Opções') {
            this.setState({ cardType: DeckCardTypes.SelectAnswer })
        } else if (newType === 'Imagem com Erros') {
            this.setState({ cardType: DeckCardTypes.ImageErrors })
        }
    }

    addCard = (card: DeckCard) => this.props.addCard(card)

    onError = (error: any) => this.setState({ error })

    uploadCardImage = async (imageBlob: Blob, imageUploadCallback: (imageUrl: string | undefined) => void) => {
        let tokenHelper = new TokenHelper()
        let subModule = this.props.subModule
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let formData = new FormData()
            formData.append('file', imageBlob)
            let adminContentService = new AdminContentService()
            let cardImage = await adminContentService.uploadCardImage(token, config.endpoint, formData, subModule.moduleId, subModule.id)
            imageUploadCallback(cardImage.imageUrl)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.uploadCardImage(imageBlob, imageUploadCallback)
            } else {
                imageUploadCallback(undefined)
                this.setState({ error })
            }
        }
    }

    uploadCardGif = async (gifFile: File, gifUploadCallback: (gifUrl: string | undefined) => void) => {
        let tokenHelper = new TokenHelper()
        let subModule = this.props.subModule
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let formData = new FormData()
            formData.append('file', gifFile)
            let adminContentService = new AdminContentService()
            let cardGif = await adminContentService.uploadCardGif(token, config.endpoint, formData, subModule.moduleId, subModule.id)
            gifUploadCallback(cardGif.gifUrl)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.uploadCardImage(gifFile, gifUploadCallback)
            } else {
                gifUploadCallback(undefined)
                this.setState({ error })
            }
        }
    }

    getCardImages = async (callback: (modules: Array<Module>, cardImages: Array<CardImage>) => void) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let { modules, cardImages } = await adminContentService.getCardImages(token, config.endpoint)
            callback(modules, cardImages)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.getCardImages(callback)
            } else {
                this.setState({ error })
            }
        }
    }

    getCardGifs = async (callback: (modules: Array<Module>, cardGifs: Array<CardGif>) => void) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let { modules, cardGifs } = await adminContentService.getCardGifs(token, config.endpoint)
            callback(modules, cardGifs)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.getCardGifs(callback)
            } else {
                this.setState({ error })
            }
        }
    }

    onExit = () => {
        if (this.state.isPickingFile) {
            this.setState({ isPickingFile: false })
        } else {
            this.props.exit()
        }
    }

    renderError(error: any) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                    {error.toString()}
                </Alert>
            </Col>
        </Row>
        );
    }

    renderTypeSelection(type: string, showPreview: boolean) {
        var templateGif = InfoCardGif
        var feedbackText = "Card com texto explicativo e uma imagem."
        var cardTypeValue = "Texto e Imagem"
        if (type === DeckCardTypes.Gif) {
            templateGif = GifCardGif
            feedbackText = "Card com um texto explicativo e um GIF."
            cardTypeValue = "Texto e Gif"
        } else if (type === DeckCardTypes.Description) {
            templateGif = DescriptionCardGif
            feedbackText = "Card com título, imagem e textos secundários."
            cardTypeValue = "Título, Textos e Imagem"
        } else if (type === DeckCardTypes.WordSearch) {
            templateGif = WordSearchCardGif
            feedbackText = "O usuário precisa achar uma palavra ( até 10 caracteres ) selecionando as letras em sequência horizontal ou vertical."
            cardTypeValue = "Caça-Palavras"
        } else if (type === DeckCardTypes.Memory) {
            templateGif = MemoryGameCardGif
            feedbackText = "O usuário precisa achar os cards com imagens e texto iguais. Quanto menos ele erra, mais pontos ganha."
            cardTypeValue = "Jogo da Memória"
        } else if (type === DeckCardTypes.Question) {
            templateGif = QuestionCardGif
            feedbackText = "Pergunta com apenas uma resposta correta. Você pode colocar imagem na pergunta, nas respostas e uma imagem final de explicação caso erre."
            cardTypeValue = "Acertar Resposta"
        } else if (type === DeckCardTypes.SelectAnswer) {
            templateGif = SelectAnswerCardGif
            feedbackText = "Pergunta que pode selecionar múltiplas opções. Você pode colocar imagem na pergunta, nas opções e uma imagem final de explicação caso erre."
            cardTypeValue = "Selecionar Opções"
        } else if (type === DeckCardTypes.OrderedList) {
            templateGif = OrderedListCardGif
            feedbackText = "Lista de etapas que aparecem em ordem aleatória e o usuário precisa ordenar na ordem correta."
            cardTypeValue = "Passo-a-Passo"
        } else if(type === DeckCardTypes.ImageErrors) {
            templateGif = ImageErrrosCardGif
            feedbackText = "Imagem com erros que o usuáio deve encontrar."
            cardTypeValue = "Imagem com Erros"
        }
        return (<Row>
            <Col className="d-flex flex-column justify-content-center relative pt-3" md={{ size: 6, offset: 3 }}>
                <Form style={{ marginLeft: 5, marginRight: 5 }}>
                    <FormGroup>
                        <Label for="cardType">Tipo de Card</Label>
                        <Input type="select" name="selectMulti" id="cardType" value={cardTypeValue} onChange={(event: any) => this.changeCardType(event.target.value)}>
                            <option>Texto e Imagem</option>
                            <option>Texto e Gif</option>
                            <option>Título, Textos e Imagem</option>
                            <option>Caça-Palavras</option>
                            <option>Jogo da Memória</option>
                            <option>Acertar Resposta</option>
                            <option>Selecionar Opções</option>
                            <option>Passo-a-Passo</option>
                            <option>Imagem com Erros</option>
                        </Input>
                        <FormText>
                            <span style={{ color: 'black' }}>{feedbackText}</span>
                        </FormText>
                    </FormGroup>
                </Form>
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ margin: 5, background: 'white', borderRadius: '0.25rem', paddingTop: showPreview ? 10 : 5, paddingBottom: 5, borderColor: '#ced4da', borderStyle: 'solid', borderWidth: 'thin' }}>
                    <div className="cursorPointer" style={{ marginLeft: 5, marginRight: 5, borderStyle: 'none' }} color="secondary" onClick={() => this.setState({ showPreview: !showPreview })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={showPreview ? faEyeSlash : faEye} />{showPreview ? 'Esconder Template' : 'Mostrar Template'}</div>
                    {showPreview && <Zoom><img src={templateGif} alt='Gif do template' style={{ height: 300, width: 190, objectFit: 'contain' }} /></Zoom>}
                </div>
            </Col>
        </Row>)
    }

    renderFormForSelectedType(type: string, isPickingFile: boolean) {
        if (type === DeckCardTypes.Info) return (<CreateInfoCardComponent createCard={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        if (type === DeckCardTypes.Gif) return (<CreateGifCardComponent createCard={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} gifImageGetUpload={this} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === DeckCardTypes.Description) return (<CreateDescriptionCardComponent createCard={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === DeckCardTypes.Question) return (<CreateQuestionCardComponent createCard={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === DeckCardTypes.WordSearch) return (<CreateWordSearchComponent createCard={this} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === DeckCardTypes.Memory) return (<CreateMemoryCardComponent createCard={this} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === DeckCardTypes.OrderedList) return (<CreateOrderedListCardComponent createCard={this} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === DeckCardTypes.SelectAnswer) return (<CreateSelectAnswerCardComponent isPickingFile={isPickingFile} createCard={this} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else if (type === DeckCardTypes.ImageErrors) return (<CreateImageErrorsCardComponent isPickingFile={isPickingFile} createCard={this} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} cardImageGetUpload={this} subModule={this.props.subModule} lastPosition={this.props.lastPosition} />)
        else return (<div></div>)
    }

    render() {
        let { cardType, error, showPreview, isPickingFile } = this.state
        return (<Fragment>
            <Row className="pt-2 pb-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', minHeight: '4em' }}>
                        <Button color='none' outline onClick={() => this.onExit()}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} src={isPickingFile ? ImageSvg : GameSvg} />
                        <div className='d-flex flex-column ml-2'>
                            <div style={{ color: '#1d3256', verticalAlign: 'middle' }}><b>{isPickingFile ? 'Selecionar Imagem' : 'Criar Card'}</b></div>
                            <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', verticalAlign: 'middle' }}>{this.props.subModule.title}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ overflow: 'auto' }}>
                <Col>
                    <Container fluid>
                        {error && this.renderError(error)}
                        {!isPickingFile && this.renderTypeSelection(cardType, showPreview)}
                        {this.renderFormForSelectedType(cardType, isPickingFile)}
                    </Container>
                </Col>
            </Row>
        </Fragment>)
    }

}