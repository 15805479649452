import DeckCard, { QuestionCard, WordSearchCard, DeckCardTypes, getDeckCard, MemoryCard, OrderedListCard, SelectAnswerCard, ImageErrorsCard } from "./DeckCards";
import { QuestionAnswer } from "./Question";

export class DeckCardReport<T extends DeckCard> {

    card: T
    enterTimes: Array<number>
    exitTimes: Array<number>
    
    constructor(card: T) {
        this.card = card
        this.enterTimes = []
        this.exitTimes = []
    }

    addEnterTime() {
        let time = (new Date()).getTime()
        this.enterTimes.push(time)
    }

    addExitTime() {
        let time = (new Date()).getTime()
        this.exitTimes.push(time)
    }

    getData(): any {
        return {
            card: this.card.getData(),
            enterTimes: this.enterTimes,
            exitTimes: this.exitTimes,
        }
    }
}

export class QuestionCardReport extends DeckCardReport<QuestionCard> {
    questionAnswer: QuestionAnswer
    answerTime?: number
    constructor(card: QuestionCard) {
        super(card)
        this.questionAnswer = QuestionAnswer.NotAnswered
    }

    getData(): any {
        let data = {
            ...super.getData(),
            questionAnswer: this.questionAnswer
        }
        if(this.answerTime) data.answerTime = this.answerTime
        return data
    }
}

export class SelectAnswerCardReport extends DeckCardReport<SelectAnswerCard> {
    questionAnswer: QuestionAnswer
    selectedAnswers: Array<number>
    answerTime?: number
    constructor(card: SelectAnswerCard) {
        super(card)
        this.questionAnswer = QuestionAnswer.NotAnswered
        this.selectedAnswers = []
    }

    getData(): any {
        let data = {
            ...super.getData(),
            questionAnswer: this.questionAnswer,
            selectedAnswers: this.selectedAnswers
        }
        if(this.answerTime) data.answerTime = this.answerTime
        return data
    }
}

export class WordSearchCardReport extends DeckCardReport<WordSearchCard> {
    foundTime?: number
    wordFound: boolean
    constructor(card: WordSearchCard) {
        super(card)
        this.wordFound = false
    }

    getData(): any {
        let data = {
            ...super.getData(),
            wordFound: this.wordFound
        }
        if(this.foundTime) data.foundTime = this.foundTime
        return data
    }
}

export class MemoryCardReport extends DeckCardReport<MemoryCard> {
    finishedTime?: number
    allImagesFound: boolean
    attempts?: number

    constructor(card: MemoryCard) {
        super(card)
        this.allImagesFound = false
        this.attempts = 0
    }

    getData(): any {
        let data = {
            ...super.getData(),
            allImagesFound: this.allImagesFound
        }
        if(this.finishedTime) data.finishedTime = this.finishedTime
        if(this.attempts !== undefined) data.attempts = this.attempts
        return data
    }
}

export class OrderedListCardReport extends DeckCardReport<OrderedListCard> {
    finishedTime?: number
    listOrderedCorrect: boolean

    constructor(card: OrderedListCard) {
        super(card)
        this.listOrderedCorrect = false
    }

    getData(): any {
        let data = {
            ...super.getData(),
            listOrderedCorrect: this.listOrderedCorrect
        }
        if(this.finishedTime) data.finishedTime = this.finishedTime
        return data
    }
    
}

export class ImageErrorsCardReport extends DeckCardReport<ImageErrorsCard> {

    finishedTime?: number
    allErrorsFound: boolean
    attempts?: number

    constructor(card: ImageErrorsCard) {
        super(card)
        this.allErrorsFound = false
        this.attempts = 0
    }

    getData(): any {
        let data = {
            ...super.getData(),
            allErrorsFound: this.allErrorsFound
        }
        if(this.finishedTime) data.finishedTime = this.finishedTime
        if(this.attempts !== undefined) data.attempts = this.attempts
        return data
    }

}

export function getCardReportArray(dataArray: Array<any>): Array<DeckCardReport<DeckCard>> {
    let array: Array<DeckCardReport<DeckCard>> = []
    for(let i = 0; i < dataArray.length; i++) {
        let data = dataArray[i]
        let enterTimes = data.enterTimes
        let exitTimes = data.exitTimes
        if(data.card.type === DeckCardTypes.Question) {
            let questionCard = getDeckCard(data.card) as QuestionCard
            let questionCardReport = new QuestionCardReport(questionCard)
            questionCardReport.enterTimes = enterTimes
            questionCardReport.exitTimes = exitTimes
            questionCardReport.questionAnswer = data.questionAnswer
            questionCardReport.answerTime = data.answerTime
            array.push(questionCardReport)
        } else if(data.card.type === DeckCardTypes.WordSearch) {
            let wordSearchCard = getDeckCard(data.card) as WordSearchCard
            let wordSearchCardReport = new WordSearchCardReport(wordSearchCard)
            wordSearchCardReport.enterTimes = enterTimes
            wordSearchCardReport.exitTimes = exitTimes
            wordSearchCardReport.foundTime = data.foundTime
            wordSearchCardReport.wordFound = data.wordFound
            array.push(wordSearchCardReport)
        } else if(data.card.type === DeckCardTypes.Memory) {
            let memoryCard = getDeckCard(data.card) as MemoryCard
            let memoryCardReport = new MemoryCardReport(memoryCard)
            memoryCardReport.allImagesFound = data.allImagesFound
            memoryCardReport.finishedTime = data.finishedTime
            memoryCardReport.attempts = data.attempts
            array.push(memoryCardReport)
        } else if(data.card.type === DeckCardTypes.OrderedList) {
            let orderedListCard = getDeckCard(data.card) as OrderedListCard
            let orderedListCardReport = new OrderedListCardReport(orderedListCard)
            orderedListCardReport.finishedTime = data.finishedTime
            orderedListCardReport.listOrderedCorrect = data.listOrderedCorrect
            array.push(orderedListCardReport)
        } else if(data.card.type === DeckCardTypes.SelectAnswer) {
            let selectAnswerCard = getDeckCard(data.card) as SelectAnswerCard
            let selectAnswerCardReport = new SelectAnswerCardReport(selectAnswerCard)
            selectAnswerCardReport.enterTimes = enterTimes
            selectAnswerCardReport.exitTimes = exitTimes
            selectAnswerCardReport.questionAnswer = data.questionAnswer
            selectAnswerCardReport.answerTime = data.answerTime
            array.push(selectAnswerCardReport)
        } else if(data.card.type === DeckCardTypes.ImageErrors) {
            let imageErrorsCard = getDeckCard(data.card) as ImageErrorsCard
            let imageErrorsCardReport = new ImageErrorsCardReport(imageErrorsCard)
            imageErrorsCardReport.allErrorsFound = data.allErrorsFound
            imageErrorsCardReport.finishedTime = data.finishedTime
            imageErrorsCardReport.attempts = data.attempts
            array.push(imageErrorsCardReport)
        } else {
            let card = getDeckCard(data.card)
            let deckCardReport = new DeckCardReport(card)
            deckCardReport.enterTimes = enterTimes
            deckCardReport.exitTimes = exitTimes
            array.push(deckCardReport)
        }
    }
    return array
}

