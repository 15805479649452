import React, { Component, Fragment, useEffect } from 'react';
import { Container, Row, Col, Input, Button, Alert, FormGroup, Label, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'

import LoadingScreen from '../loading'
import Cookies from 'universal-cookie';
import Company from '../../models/Company';
import { SignUpConfig, DocConfig, EmailConfig, PhoneConfig, AuthData } from '../../models/CompanyConfig';
import EnterService from '../../services/enterService';
import config from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

import * as ROUTES from '../../constants/routes'
import UserAccessValidation from '../../models/UserAccessValidation';
import WebViewContainer from '../../components/webViewContainer';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useUser } from '../../hooks/useUser';

interface State {
    signUpConfig: SignUpConfig | undefined,
    email: string;
    doc: string | undefined,
    password: string;
    username: string;
    phone: string | undefined;
    authDataSelectedValues: { [id: string]: string }
    isAuthDataDropdownOpen: { [id: string]: boolean }
    error: string | undefined;
    isLoading: boolean;
    blockPrimaryPropertyInput: boolean
    blockNameInput: boolean
    blockPhoneInput: boolean
    useTermsAndPrivacyPolicyAgreement: boolean
    showExternalLink: string | undefined
};

const INITIAL_STATE: State = {
    signUpConfig: undefined,
    email: '',
    doc: undefined,
    password: '',
    username: '',
    phone: undefined,
    authDataSelectedValues: {},
    isAuthDataDropdownOpen: {},
    error: undefined,
    isLoading: false,
    blockPrimaryPropertyInput: false,
    blockNameInput: false,
    blockPhoneInput: false,
    useTermsAndPrivacyPolicyAgreement: false,
    showExternalLink: undefined
};

const SignUpScreen = () => {

    const navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { height } = useWindowDimensions()
    const { company, noCompany } = useUser()
    const [searchParams] = useSearchParams()
    const primaryPropertyValue = searchParams.get('primaryProperty')

    useEffect(() => {
        if (company) loadCompanySignUpConfig(company.id)
    }, [company])

    useEffect(() => {
        if (noCompany) setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.' })
    }, [noCompany])

    const loadCompanySignUpConfig = async (companyId: string) => {
        try {
            setState({ ...state, isLoading: true, error: undefined })
            let enterService = new EnterService()
            let company: Company
            let signUpConfig: SignUpConfig
            var userAccessValidation: UserAccessValidation | undefined = undefined
            var newStateProps: any = {}
            if (primaryPropertyValue) {
                let userSignUpRes = await enterService.getUserSignUpConfig(config.endpoint, companyId, primaryPropertyValue)
                company = userSignUpRes.company
                signUpConfig = userSignUpRes.signUpConfig
                userAccessValidation = userSignUpRes.userAccessValidation
                if (signUpConfig.primaryProperty === 'doc') newStateProps = { doc: primaryPropertyValue, blockPrimaryPropertyInput: true }
                else newStateProps = { email: primaryPropertyValue, blockPrimaryPropertyInput: true }
                if (userAccessValidation) {
                    if (userAccessValidation.name) {
                        newStateProps.username = userAccessValidation.name
                        newStateProps.blockNameInput = true
                    }
                    if (userAccessValidation.phone) {
                        newStateProps.phone = userAccessValidation.phone.replace(/\D/g, "")
                        newStateProps.blockPhoneInput = true
                    }
                }

            } else {
                let signUpRes = await enterService.getCompanySignUpConfig(config.endpoint, companyId)
                company = signUpRes.company
                signUpConfig = signUpRes.signUpConfig
            }

            // Valores default pra authData na seleção
            if (signUpConfig.authData) {
                let keys = Object.keys(signUpConfig.authData)
                for (var i = 0; i < keys.length; i++) {
                    let key = keys[i]
                    let authData = signUpConfig.authData[key]
                    if (authData.show && authData.values && authData.values.length > 0) {
                        if (!newStateProps.authDataSelectedValues) {
                            newStateProps.authDataSelectedValues = {}
                        }
                        newStateProps.authDataSelectedValues[key] = authData.values[0]
                    }
                }
            }

            let cookies = new Cookies()
            cookies.set('company', company.getData(), { path: '/', maxAge: 31536000 });
            setState({ ...state, isLoading: false, company, signUpConfig, ...newStateProps })

        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                let errorCode = error.response.data.error.code
                if (errorCode === 'Invalid_Access_Validation') {
                    return setState({ ...state, isLoading: false, error: 'Ops, infelizmente você não tem acesso. Converse com o administrador da sua empresa para liberar o seu acesso na plataforma.' })
                }
            }
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const isEmailValid = (email: string): boolean => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const signUp = async (companyId: string, signUpConfig: SignUpConfig) => {
        let signUpData: any = { username: state.username }
        if (signUpConfig.doc) signUpData.doc = state.doc
        if (signUpConfig.email) signUpData.email = state.email
        if (signUpConfig.phone) signUpData.phone = state.phone
        if (signUpConfig.password) signUpData.password = state.password
        if (signUpConfig.authData && Object.keys(state.authDataSelectedValues).length > 0) {
            signUpData.authData = state.authDataSelectedValues
        }
        setState({ ...state, isLoading: true })
        try {
            let enterService = new EnterService()
            let userRes = await enterService.createUser(config.endpoint, companyId, signUpData)
            let cookies = new Cookies()
            cookies.set('user', userRes.user.getData(), { path: '/', maxAge: 31536000 })
            cookies.set('token', userRes.tokenData.token, { path: '/', maxAge: 31536000 })
            cookies.set('refreshToken', userRes.tokenData.refreshToken, { path: '/', maxAge: 31536000 })
            navigate(`/${companyId}${ROUTES.PROFILE_PIC}`, { replace: true })
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                let errorCode = error.response.data.error.code
                if (errorCode === 'Invalid_Access_Validation') {
                    return setState({ ...state, isLoading: false, error: 'Ops, infelizmente você não tem acesso. Converse com o administrador da sua empresa para liberar o seu acesso na plataforma.' })
                } else if (errorCode === 'Email_Already_Taken') {
                    return setState({ ...state, isLoading: false, error: `Já existe uma conta criada com o e-mail ${signUpData.email}. Por favor, escolha outro e-mail.` })
                } else if (errorCode === 'Doc_Already_Taken') {
                    return setState({ ...state, isLoading: false, error: `Já existe uma conta criada com o documento ${signUpData.doc}. Por favor, escolha outro documento.` })
                }
            }
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const updateUsername = (newValue: string) => {
        setState({ ...state, username: newValue })
    }

    const updateEmail = (newValue: string) => {
        setState({ ...state, email: newValue.trim().toLowerCase() })
    }

    const updatePassword = (newValue: string) => {
        setState({ ...state, password: newValue })
    }

    const updatePhone = (newValue: string) => {
        let onlyDigitsPhone = newValue.replace(/\D/g, "")
        setState({ ...state, phone: onlyDigitsPhone })
    }

    const updateDoc = (docConfig: DocConfig, newValue: string) => {
        if (docConfig.mask) {
            if (docConfig.mask === 'cpf') {
                let cpfWithMask = applyCpfMask(newValue)
                return setState({ ...state, doc: cpfWithMask })
            }
        }

        setState({ ...state, doc: newValue })
    }

    const getPhoneWithMask = (phoneConfig: PhoneConfig, phone: string) => {
        if (phoneConfig.mask) {
            if (phoneConfig.mask === 'br') {
                return applyPhoneBrMask(phone)
            }
        }

        return phone
    }

    const applyCpfMask = (value: string) => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    const applyPhoneBrMask = (value: string) => {
        return value.replace(/\D/g, "")           //Remove tudo o que não é dígito
            .replace(/^(\d{2})(\d)/g, "($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
            .replace(/(\d)(\d{4})$/, "$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
    }

    const testCPF = (strCPF: string) => {
        strCPF = strCPF.replace(/\D/g, '')
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (let n = 1; n <= 10; n++) Soma = Soma + parseInt(strCPF.substring(n - 1, n)) * (12 - n);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    const dropDownAuthDataSelection = (selection: string, id: string) => {
        let authDataSelectedValues = state.authDataSelectedValues
        //let selection = event.target.textContent as string
        if (selection.length === 0) {
            delete authDataSelectedValues[id]
            setState({ ...state, authDataSelectedValues })
        } else {
            authDataSelectedValues[id] = selection
            setState({ ...state, authDataSelectedValues })
        }
    }

    const toggleAuthDataDropdownOpen = (id: string) => {
        let isAuthDataDropdownOpen = state.isAuthDataDropdownOpen
        isAuthDataDropdownOpen[id] = !isAuthDataDropdownOpen[id]
        setState({ ...state, isAuthDataDropdownOpen })
    }

    const isInputInvalid = (signUpConfig: SignUpConfig, useTermsAndPrivacyPolicyAgreement: boolean) => {

        if (!useTermsAndPrivacyPolicyAgreement) return true

        if (signUpConfig.doc && signUpConfig.doc.required) {
            let docConfig = signUpConfig.doc
            if (docConfig.mask) {
                if (docConfig.mask === 'cpf') {
                    if (!testCPF(state.doc || '')) return true
                }
            }

            if (!state.doc || state.doc.length < 4) return true

        }

        if (signUpConfig.email) {
            if (signUpConfig.email.required) {
                if (!state.email || !isEmailValid(state.email)) return true
            } else {
                if (state.email && state.email.length > 0 && !isEmailValid(state.email)) return true
            }
        }

        if (signUpConfig.password) {
            if (!state.password || state.password.length < 8) return true
        }

        return !state.username || state.username.length < 3

    }

    const renderDocInput = (docConfig: DocConfig, disabled: boolean) => {
        return (<FormGroup>
            <Label for="docTitle">{docConfig.title}</Label>
            <Input disabled={disabled} name="title" id="docTitle" placeholder={docConfig.placeholder} value={state.doc || ''} onChange={(event: any) => updateDoc(docConfig, event.target.value)} />
        </FormGroup>)
    }

    const renderPhoneInput = (phoneConfig: PhoneConfig, phone: string | undefined) => {
        let phoneWithMask = phone ? getPhoneWithMask(phoneConfig, phone) : undefined
        return (<FormGroup>
            <Label for="phoneTitle">{phoneConfig.title}</Label>
            <Input name="title" id="phoneTitle" placeholder={phoneConfig.placeholder} value={phoneWithMask} onChange={(event: any) => updatePhone(event.target.value)} />
        </FormGroup>)
    }

    const renderEmailInput = (emailConfig: EmailConfig, email: string, disabled: boolean) => {
        var showInvalidEmailMessage = email.length > 0 && !isEmailValid(email)
        if (emailConfig.required && email.length === 0) showInvalidEmailMessage = true
        return (<FormGroup>
            <Label for="emailTitle">{emailConfig.title}</Label>
            <Input disabled={disabled} name="email" id="emailTitle" placeholder={emailConfig.placeholder} value={email} onChange={(event: any) => updateEmail(event.target.value)} />
            {showInvalidEmailMessage && <small style={{ color: 'red' }}>{email.length === 0 ? 'Digite um email' : 'Email inválido'}</small>}
        </FormGroup>)
    }

    const renderUsernameInput = (disabled: boolean) => {
        return (<FormGroup>
            <Label for="usernameTitle">Nome</Label>
            <Input disabled={disabled} name="title" id="usernameTitle" placeholder={'Por favor, digite seu nome'} value={state.username} onChange={(event: any) => updateUsername(event.target.value)} />
        </FormGroup>)
    }

    const renderPasswordInput = (password: string) => {
        let showInvalidPasswordMessage = password.length > 0 && password.length < 8
        return (<FormGroup>
            <Label for="passwordTitle">Senha (ao menos 8 caracteres)</Label>
            <Input type="password" name="title" id="passwordTitle" placeholder={'Por favor, digite uma senha'} value={password} onChange={(event: any) => updatePassword(event.target.value)} />
            {showInvalidPasswordMessage && <small style={{ color: 'red' }}>Senha inválida</small>}
        </FormGroup>)
    }

    const renderAuthDataSelection = (authData: { [id: string]: AuthData }, authDataSelectedValues: { [id: string]: string }) => {
        let authDataKeys = Object.keys(authData)
        return (authDataKeys.filter(id => { return authData[id].show }).map((id, index) => {
            let currentAuthData = authData[id]
            let selectedAuthDataValue = authDataSelectedValues[id]

            if (Array.isArray(currentAuthData.values) && currentAuthData.values.length > 0) {
                let selectionOptions = [...currentAuthData.values]
                return (
                    <FormGroup key={index} style={{ marginBottom: 10 }}>
                        <Label>{currentAuthData.title}</Label>
                        <Dropdown isOpen={state.isAuthDataDropdownOpen[id]} toggle={() => toggleAuthDataDropdownOpen(id)}>
                            <DropdownToggle caret>
                                {selectedAuthDataValue}
                            </DropdownToggle>
                            <DropdownMenu>
                                {selectionOptions.map((authDataSelection: string) => (<DropdownItem onClick={(event: any) => dropDownAuthDataSelection(event.target.textContent as string, id)}>{authDataSelection}</DropdownItem>))}
                            </DropdownMenu>
                        </Dropdown>
                    </FormGroup>)
            } else {
                return (
                    <FormGroup key={index}>
                        <Label>{currentAuthData.title}</Label>
                        <Input name={currentAuthData.title} id={id} value={selectedAuthDataValue} placeholder={currentAuthData.placeholder} onChange={(event: any) => dropDownAuthDataSelection(event.target.value as string, id)} />
                    </FormGroup>
                )
            }
        }))
    }

    const renderError = (error: string) => {
        return (
            <Row>
                <Col>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>

        );
    }

    const renderHeader = (company: Company) => {
        return (<Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', backgroundColor: '#FFFFFFDA', color: '#0f3252' }}>
            <Col className="d-flex align-items-center justify-content-between" style={{ minHeight: '4em' }} md={{ size: 6, offset: 3 }}>
                <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon icon={faArrowLeft} size='2x' /></Button>
                <div style={{ verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Criar Conta</b></div>
                <img alt='company' style={{ height: 60, width: 60, margin: 5 }} src={company.pic} />
            </Col>
        </Row>)
    }

    const renderSignUpForm = (config: SignUpConfig, company: Company, error: any | undefined, blockPrimaryPropertyInput: boolean, blockNameInput: boolean, blockPhoneInput: boolean, email: string, password: string, phone: string | undefined, authDataSelectedValues: { [id: string]: string }, useTermsAndPrivacyPolicyAgreement: boolean) => {
        let docDisabled = config.primaryProperty === 'doc' && blockPrimaryPropertyInput
        let emailDisabled = config.primaryProperty === 'email' && blockPrimaryPropertyInput
        return (<Row style={{ overflow: 'auto' }}>
            <Col className="d-flex flex-column justify-content-center relative mt-3 mb-2" md={{ size: 6, offset: 3 }}>
                <Form>
                    {config.doc && renderDocInput(config.doc, docDisabled)}
                    {config.email && renderEmailInput(config.email, email, emailDisabled)}
                    {renderUsernameInput(blockNameInput)}
                    {config.phone && !blockPhoneInput && renderPhoneInput(config.phone, phone)}
                    {config.authData && renderAuthDataSelection(config.authData, authDataSelectedValues)}
                    {config.password && renderPasswordInput(password)}
                </Form>
                <div className="d-flex align-items-start">
                    <Button color='none' style={{ padding: 0 }} outline onClick={() => { setState({ ...state, useTermsAndPrivacyPolicyAgreement: !useTermsAndPrivacyPolicyAgreement }) }}><FontAwesomeIcon style={{ verticalAlign: 'middle', fontSize: '1.5em' }} color='#353f45' icon={useTermsAndPrivacyPolicyAgreement ? faCheckSquare : faSquare} /></Button>
                    <div style={{ marginLeft: 15 }}>Li e concordo com os <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => setState({ ...state, showExternalLink: `${window.location.origin}/app/use_terms` })}><b>Termos de Uso</b></span> e <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => setState({ ...state, showExternalLink: `${window.location.origin}/app/privacy_policy` })}><b>Política de Privacidade</b></span>.<br />{!useTermsAndPrivacyPolicyAgreement && (<i style={{ fontSize: 11 }}>Clique no quadrado para concordar.</i>)}</div>
                </div>
                <Button style={{ background: company.mainColor || '#000', borderStyle: 'none', marginTop: 10 }} block disabled={isInputInvalid(config, useTermsAndPrivacyPolicyAgreement)} onClick={() => signUp(company.id, config)}>Criar Conta</Button>
                {!useTermsAndPrivacyPolicyAgreement && <div style={{ color: 'red', marginTop: 5, fontSize: 12 }}><i>Você deve concordar com nossos Termos de Uso e Política de Privacidade</i></div>}
            </Col>
        </Row>)
    }

    let { signUpConfig, error, isLoading, blockPrimaryPropertyInput, blockNameInput, blockPhoneInput, email, password, phone, authDataSelectedValues, useTermsAndPrivacyPolicyAgreement, showExternalLink } = state;

    if (isLoading || !company) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    let render = () => {
        if (showExternalLink) {
            return <WebViewContainer exitText='VOLTAR' url={showExternalLink} onExit={() => setState({ ...state, showExternalLink: undefined })} />
        } else {
            return <Fragment>
                {company && renderHeader(company)}
                {error && renderError(error)}
                {signUpConfig && company && renderSignUpForm(signUpConfig, company, error, blockPrimaryPropertyInput, blockNameInput, blockPhoneInput, email, password, phone, authDataSelectedValues, useTermsAndPrivacyPolicyAgreement)}
            </Fragment>
        }
    }

    return (<Container className="d-flex flex-column relative" fluid style={{ overflow: 'hidden', height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {render()}
    </Container>)

}

export default SignUpScreen