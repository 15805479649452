import React, { Component, useEffect } from 'react';
import XLSX from 'xlsx'

import Company from '../../../models/Company';
import { SignUpConfig, AuthData } from '../../../models/CompanyConfig';
import { useNavigate } from 'react-router-dom';
import EnterService from '../../../services/enterService';
import config from '../../../config'
import { Container, Row, Col, Form, FormGroup, Label, Input, FormText, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Button, Table, Alert } from 'reactstrap';
import LoadingScreen from '../../loading';
import AdminAccessValidationService from '../../../services/admin/adminAccessValidationService';
import TokenHelper from '../../../auth/TokenHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../../../hooks/useUser';

interface State {
    signUpConfig: SignUpConfig | undefined,
    isSheetDropdownOpen: boolean;
    isSheetHeaderDropdownOpen: boolean;
    isSheetUsernameDropdownOpen: boolean;
    isSheetPhoneDropdownOpen: boolean;
    error: any | undefined;
    file: File | undefined;
    sheetNames: string[];
    sheetData: any[];
    selectedSheet: string | undefined;
    selectedColumnForPrimaryProperty: string | undefined;
    selectedColumnForUsername: string | undefined
    selectedColumnForPhone: string | undefined
    selectedColumnForAuthData: { [id: string]: string },
    isAuthDataDropdownOpen: { [id: string]: boolean }
    isSavingData: boolean;
    alert: string | undefined;
    isLoading: boolean;
};

const INITIAL_STATE: State = {
    signUpConfig: undefined,
    isSheetDropdownOpen: false,
    isSheetHeaderDropdownOpen: false,
    isSheetUsernameDropdownOpen: false,
    isSheetPhoneDropdownOpen: false,
    error: undefined,
    file: undefined,
    sheetNames: [],
    sheetData: [],
    selectedSheet: undefined,
    selectedColumnForPrimaryProperty: undefined,
    selectedColumnForUsername: undefined,
    selectedColumnForPhone: undefined,
    selectedColumnForAuthData: {},
    //authDataSelectedValues: {},
    isAuthDataDropdownOpen: {},
    isSavingData: false,
    alert: undefined,
    isLoading: false,
};

class Credential {
    primaryPropertyValue: string
    authData?: { [id: string]: string }
    name?: string
    phone?: string
    isValid: boolean

    constructor(primaryPropertyValue: string) {
        this.primaryPropertyValue = primaryPropertyValue
        this.isValid = true
    }
}


const UploadCredentialsScreen = () => {

    const navigate = useNavigate()
    const { company } = useUser()
    const [state, setState] = React.useState<State>(INITIAL_STATE)

    const lowerCaseAndRemovePunctuationFromString = (data: string) => {
        return data.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ")
    }

    useEffect(() => {
        if (company) loadCompanySignUpConfig(company.id)
    }, [company])



    const loadCompanySignUpConfig = async (companyId: string) => {
        try {
            setState({ ...state, isLoading: true, error: undefined })
            let enterService = new EnterService()
            let { signUpConfig } = await enterService.getCompanySignUpConfig(config.endpoint, companyId)
            let isAuthDataDropdownOpen: { [id: string]: boolean } = {}
            if (signUpConfig.authData) {
                for (let id in signUpConfig.authData) {
                    isAuthDataDropdownOpen[id] = false
                }
            }
            setState({ ...state, isLoading: false, signUpConfig, isAuthDataDropdownOpen })
        } catch (error) {
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const toggleSheetDropdownOpen = () => setState({ ...state, isSheetDropdownOpen: !state.isSheetDropdownOpen });

    const toggleSheetHeaderDropdownOpen = () => setState({ ...state, isSheetHeaderDropdownOpen: !state.isSheetHeaderDropdownOpen })

    const toggleSheetUsernameDropdownOpen = () => setState({ ...state, isSheetUsernameDropdownOpen: !state.isSheetUsernameDropdownOpen })

    const toggleSheetPhoneDropdownOpen = () => setState({ ...state, isSheetPhoneDropdownOpen: !state.isSheetPhoneDropdownOpen })


    const toggleAuthDataDropdownOpen = (id: string) => {
        let isAuthDataDropdownOpen = state.isAuthDataDropdownOpen
        isAuthDataDropdownOpen[id] = !isAuthDataDropdownOpen[id]
        setState({ ...state, isAuthDataDropdownOpen })
    }

    const dropDownSheetSelection = (event: any) => setState({ ...state, selectedSheet: event.target.textContent, selectedColumnForPrimaryProperty: undefined })

    const dropDownSheetPrimaryPropertyColumnSelection = (event: any) => setState({ ...state, selectedColumnForPrimaryProperty: event.target.textContent })

    const dropDownSheetUsernameColumnSelection = (event: any) => setState({ ...state, selectedColumnForUsername: event.target.textContent })

    const dropDownSheetPhoneColumnSelection = (event: any) => setState({ ...state, selectedColumnForPhone: event.target.textContent })

    const dropDownSheetAuthDataColumnSelection = (event: any, id: string) => {
        let selectedColumnForAuthData = state.selectedColumnForAuthData
        let selection = event.target.textContent as string
        if (selection === 'Nenhum') {
            delete selectedColumnForAuthData[id]
            setState({ ...state, selectedColumnForAuthData })
        } else {
            selectedColumnForAuthData[id] = selection
            setState({ ...state, selectedColumnForAuthData })
        }
    }

    const usernameColumnDropDownSheetColumnSelection = (event: any) => {
        let selection = event.target.textContent as string
        if (selection === 'Nenhum') {
            setState({ ...state, selectedColumnForUsername: undefined })
        } else {
            setState({ ...state, selectedColumnForUsername: event.target.textContent })
        }
    }

    const phoneColumnDropDownSheetColumnSelection = (event: any) => {
        let selection = event.target.textContent as string
        if (selection === 'Nenhum') {
            setState({ ...state, selectedColumnForPhone: undefined })
        } else {
            setState({ ...state, selectedColumnForPhone: event.target.textContent })
        }
    }

    const getSheetColumnNamesArrayArray = (selectedSheetData: any) => {
        let sheetDataColumnNamesSet = new Set<string>()
        for (var i = 0; i < selectedSheetData.length; i++) {
            let sheetRowData = selectedSheetData[i]
            let sheetRowHeaders = Object.keys(sheetRowData)
            sheetRowHeaders.forEach(sheetRowHeader => sheetDataColumnNamesSet.add(sheetRowHeader))
        }
        return Array.from(sheetDataColumnNamesSet)
    }

    const getSelectedSheetData = (sheetName: string): any => {
        let selectedSheetIndex = state.sheetNames.indexOf(sheetName)
        return state.sheetData[selectedSheetIndex]
    }

    const uploadFile = (file: File | undefined) => {
        if (!file) return
        let fileReader = new FileReader()
        fileReader.onload = () => {
            try {
                let result = fileReader.result
                let wb = XLSX.read(result, { type: 'binary' })
                let sheetNames = wb.SheetNames
                let sheetData = []
                for (var i = 0; i < sheetNames.length; i++) {
                    let currentSheet = sheetNames[i]
                    let xlData = XLSX.utils.sheet_to_json(wb.Sheets[currentSheet]);
                    sheetData.push(xlData)
                }
                let selectedSheet = sheetNames[0]
                let selectedSheetDataIndex = sheetNames.indexOf(selectedSheet)
                let selectedColumnForPrimaryProperty = getSheetColumnNamesArrayArray(sheetData[selectedSheetDataIndex])[0]
                setState({ ...state, sheetNames, sheetData, selectedSheet, selectedColumnForPrimaryProperty })
            } catch (error) {
                setState({ ...state, error })
            }
        }
        fileReader.readAsBinaryString(file)
        setState({ ...state, error: undefined, file })
    }

    // Método usado pra preparar os dados na planilha para serem mostrados para o usuário
    const getCredentialsArray = (selectedSheetData: any, selectedColumnForPrimaryProperty: string, primaryProperty: string, selectedColumnForUsername: string | undefined, selectedColumnForPhone: string | undefined, selectedColumnForAuthData: { [id: string]: string }, authData: { [id: string]: AuthData } | undefined): Array<Credential> => {
        let credentialsArray: Array<Credential> = []
        for (var i = 0; i < selectedSheetData.length; i++) {
            let sheetRowData = selectedSheetData[i]
            let valueFromSheetRow = sheetRowData[selectedColumnForPrimaryProperty]
            if (valueFromSheetRow) {
                var primaryPropertyValue = formatSelectionForType(primaryProperty, valueFromSheetRow)
                let credentialData = new Credential(primaryPropertyValue || valueFromSheetRow)
                if (!primaryPropertyValue) {
                    credentialData.isValid = false
                    if (primaryProperty === 'doc') {
                        credentialData.primaryPropertyValue = `Documento Inválido: ${valueFromSheetRow}`
                    } else {
                        credentialData.primaryPropertyValue = `Email Inválido: ${valueFromSheetRow}`
                    }
                }
                if (selectedColumnForUsername && sheetRowData[selectedColumnForUsername]) credentialData.name = sheetRowData[selectedColumnForUsername].toString().trim()
                if (selectedColumnForPhone && sheetRowData[selectedColumnForPhone]) {
                    // pega só os dígitos
                    let phoneString = String(sheetRowData[selectedColumnForPhone])
                    if (phoneString) {
                        var onlyNumbersPhone = phoneString.replace(/\D/g, "")
                        if (onlyNumbersPhone.length > 0 && onlyNumbersPhone[0] === '0') {
                            // 011 9 834234293, remove o 0
                            onlyNumbersPhone = onlyNumbersPhone.substring(1)
                        }
                        if (onlyNumbersPhone.length !== 11) {
                            if (onlyNumbersPhone.length === 8) {
                                // provavelmente faltou o código de área e o 9. usa 11 como padrao
                                credentialData.phone = '119' + onlyNumbersPhone
                            } else if (onlyNumbersPhone.length === 9 && onlyNumbersPhone[0] === '9') {
                                // provavelmente faltou o código de área. usa 11 como padrao
                                credentialData.phone = '11' + onlyNumbersPhone
                            } else if (onlyNumbersPhone.length === 10) {
                                // provavelmente faltou o 9. coloca o 
                                credentialData.phone = onlyNumbersPhone.substring(0, 2) + '9' + onlyNumbersPhone.substring(2)
                            } else {
                                credentialData.phone = `Número inválido: ${onlyNumbersPhone}`
                            }
                        } else {
                            credentialData.phone = onlyNumbersPhone
                        }
                        /*if (onlyNumbersPhone.length < 10) {
                            credentialData.phone = `Número inválido: ${onlyNumbersPhone}`
                        } else {
                            credentialData.phone = onlyNumbersPhone
                        }*/
                    }
                }
                if (Object.keys(selectedColumnForAuthData).length > 0 && authData) {
                    credentialData.authData = {}
                    for (let id in selectedColumnForAuthData) {
                        credentialData.authData[id] = String(sheetRowData[selectedColumnForAuthData[id]]).trim()
                        //let authDataColumnValue = this.lowerCaseAndRemovePunctuationFromString(String(sheetRowData[selectedColumnForAuthData[id]]).trim())
                        //let authDataDbValue = authData[id].values.find(val => this.lowerCaseAndRemovePunctuationFromString(val) === authDataColumnValue)
                        //if(authDataDbValue) credentialData.authData[id] = authDataDbValue
                    }
                }
                credentialsArray.push(credentialData)
            }
        }
        return credentialsArray
    }

    // Método usado pra preparar os dados na planilha para serem salvos no banco de dados
    const getCredentialsForSavingArray = (selectedSheetData: any, selectedColumnForPrimaryProperty: string, primaryProperty: string, selectedColumnForUsername: string | undefined, selectedColumnForPhone: string | undefined, selectedColumnForAuthData: { [id: string]: string }, authData: { [id: string]: AuthData } | undefined): Array<Credential> => {
        let credentialsArray: Array<Credential> = []
        for (var i = 0; i < selectedSheetData.length; i++) {
            let sheetRowData = selectedSheetData[i]
            let valueFromSheetRow = sheetRowData[selectedColumnForPrimaryProperty]
            if (valueFromSheetRow) {
                let primaryPropertyValue = formatSelectionForType(primaryProperty, valueFromSheetRow)
                if (!primaryPropertyValue) {
                    // não da pra salvar CPF e Email errado, né meu filho? 
                    continue
                }
                let credentialData = new Credential(primaryPropertyValue)
                if (selectedColumnForUsername && sheetRowData[selectedColumnForUsername]) {
                    let formattedName = String(sheetRowData[selectedColumnForUsername]).trim()
                    if (formattedName && formattedName.trim().length > 0) {
                        credentialData.name = formattedName.trim()
                    }
                }
                if (selectedColumnForPhone && sheetRowData[selectedColumnForPhone]) {
                    // pega só os dígitos
                    let phoneString = String(sheetRowData[selectedColumnForPhone])
                    if (phoneString) {
                        let onlyNumbersPhone = phoneString.replace(/\D/g, "")
                        if (onlyNumbersPhone.length >= 11) {
                            credentialData.phone = onlyNumbersPhone
                        }
                    }
                }
                if (Object.keys(selectedColumnForAuthData).length > 0 && authData) {
                    credentialData.authData = {}
                    for (let id in selectedColumnForAuthData) {
                        credentialData.authData[id] = String(sheetRowData[selectedColumnForAuthData[id]]).trim()
                        //let authDataColumnValue = this.lowerCaseAndRemovePunctuationFromString(String(sheetRowData[selectedColumnForAuthData[id]]).trim())
                        //let authDataDbValue = authData[id].values.find(val => this.lowerCaseAndRemovePunctuationFromString(val) === authDataColumnValue)
                        //if(authDataDbValue) credentialData.authData[id] = authDataDbValue
                        //else console.log(authDataColumnValue)
                    }
                }
                credentialsArray.push(credentialData)
            }
        }
        return credentialsArray
    }

    const saveData = async (selectedSheet: string, selectedColumnForPrimaryProperty: string, primaryProperty: string, selectedColumnForUsername: string | undefined, selectedColumnForPhone: string | undefined, selectedColumnForAuthData: { [id: string]: string }, authData: { [id: string]: AuthData } | undefined) => {
        setState({ ...state, isSavingData: true })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let selectedSheetData = getSelectedSheetData(selectedSheet)
            let credentialsArray = getCredentialsForSavingArray(selectedSheetData, selectedColumnForPrimaryProperty, primaryProperty, selectedColumnForUsername, selectedColumnForPhone, selectedColumnForAuthData, authData)
            let adminAccessValidationService = new AdminAccessValidationService()
            await adminAccessValidationService.updateUserAccessValidation(token, config.endpoint, credentialsArray)
            setState({ ...state, isSavingData: false, error: undefined, alert: 'Dados salvos com sucesso!' })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                saveData(selectedSheet, selectedColumnForPrimaryProperty, primaryProperty, selectedColumnForUsername, selectedColumnForPhone, selectedColumnForAuthData, authData)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const getTypeValue = (primaryProperty: string) => {
        if (primaryProperty === 'email') return 'Email'
        else if (primaryProperty === 'doc') return 'CPF'
        else return 'desconhecido'
    }

    const formatSelectionForType = (primaryProperty: string, value: string): string | undefined => {
        if (primaryProperty === 'doc') {
            return validateCPF(value.toString().trim())
        } else if (primaryProperty === 'email') {
            // email tem que ser tudo minusculo pra evitar erros de digitacao
            let trimmedVal = value.trim().toLowerCase()
            return trimmedVal.length > 5 ? trimmedVal : undefined
        } else {
            return value
        }
    }

    const validateCPF = (value: string) => {

        // algumas planilhas ignoram zeros a esquerda do numero de cpf
        let numerOfMissingZeros = 11 - value.length
        var ao_cpf = numerOfMissingZeros > 0 ? new Int8Array(numerOfMissingZeros).reduce((acc, val) => {
            return acc + val.toString()
        }, "") + value : value
        var cpfValido = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/;
        if (cpfValido.test(ao_cpf) === false) {

            ao_cpf = ao_cpf.replace(/\D/g, ""); //Remove tudo o que não é dígito

            if (ao_cpf.length === 11) {
                ao_cpf = ao_cpf.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
                ao_cpf = ao_cpf.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
                //de novo (para o segundo bloco de números)
                ao_cpf = ao_cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos

                return ao_cpf
            } else {
                return undefined
            }
        }

        return value
    }

    const renderHeader = (company: Company) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>Subir Planilha</b></div>
                    <img alt='foto da empresa' style={{ height: 60, marginBottom: 10, marginTop: 10 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderInputForm = (primaryProperty: string) => {
        return (<Container fluid style={{ marginTop: 5 }}>
            <Row>
                <Col md="4">
                    <Form>
                        <FormGroup>
                            <Label>Tipo de Credencial: {getTypeValue(primaryProperty)}</Label>
                            <br />
                            <Label for="exampleFile">Arquivo Excel</Label>
                            <Input type="file" name="file" id="exampleFile" accept=".xlsx, .xls" onChange={(event) => uploadFile(event.target.files ? event.target.files[0] : undefined)} />
                            <FormText color="muted">
                                Escolha um arquivo excel no formato xlsx.
                            </FormText>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </Container>)
    }

    const renderSheetSelection = (selectedSheet: string, selectedColumnForPrimaryProperty: string, primaryProperty: string, selectedColumnForUsername: string | undefined, selectedColumnForPhone: string | undefined, isSavingData: boolean, authData: { [id: string]: AuthData } | undefined, selectedColumnForAuthData: { [id: string]: string }) => {
        let selectedSheetData = getSelectedSheetData(selectedSheet)
        let sheetColumnNamesArray = getSheetColumnNamesArrayArray(selectedSheetData)
        return (<Container fluid style={{ marginTop: 5 }}>
            <Row>
                <Col>
                    <Label>Planilha</Label>
                    <Dropdown isOpen={state.isSheetDropdownOpen} toggle={toggleSheetDropdownOpen}>
                        <DropdownToggle caret>
                            {selectedSheet}
                        </DropdownToggle>
                        <DropdownMenu onClick={dropDownSheetSelection}>
                            {state.sheetNames.map(sheetName => (<DropdownItem>{sheetName}</DropdownItem>))}
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                {renderSelectedSheetPrimaryProperty(sheetColumnNamesArray, primaryProperty)}
                {renderSelectedSheetUsername(sheetColumnNamesArray, selectedColumnForUsername)}
                {renderSelectedSheetPhone(sheetColumnNamesArray, selectedColumnForPhone)}
                {authData && renderSelectedSheetAuthData(sheetColumnNamesArray, authData, selectedColumnForAuthData)}
            </Row>
            <Row style={{ marginTop: 5 }}>
                <Col><Button disabled={isSavingData} onClick={() => { saveData(selectedSheet, selectedColumnForPrimaryProperty, primaryProperty, selectedColumnForUsername, selectedColumnForPhone, selectedColumnForAuthData, authData) }} color="success">Salvar Credenciais de Acesso</Button></Col>
            </Row>
            <Row style={{ marginTop: 5 }}><Col>{renderSelectedColumnData(selectedSheetData, selectedColumnForPrimaryProperty || sheetColumnNamesArray[0], primaryProperty, selectedColumnForUsername, selectedColumnForPhone, selectedColumnForAuthData, authData)}</Col></Row>
        </Container>)
    }

    const renderSelectedSheetPrimaryProperty = (sheetColumnNamesArray: Array<string>, primaryProperty: string) => {
        let selectedColumn = state.selectedColumnForPrimaryProperty || sheetColumnNamesArray[0]
        let primaryPropertyName = primaryProperty === 'doc' ? 'CPF' : 'E-mail'
        return (
            <Col>
                <Label>{`Coluna ${primaryPropertyName}`}</Label>
                <Dropdown isOpen={state.isSheetHeaderDropdownOpen} toggle={toggleSheetHeaderDropdownOpen}>
                    <DropdownToggle caret>
                        {selectedColumn}
                    </DropdownToggle>
                    <DropdownMenu>
                        {sheetColumnNamesArray.map((sheetColumnName: string) => (<DropdownItem onClick={dropDownSheetPrimaryPropertyColumnSelection}>{sheetColumnName}</DropdownItem>))}
                    </DropdownMenu>
                </Dropdown>
            </Col>)
    }

    const renderSelectedSheetUsername = (sheetColumnNamesArray: Array<string>, selectedColumnForUsername: string | undefined) => {
        let selectionOptions = ['Nenhum', ...sheetColumnNamesArray]
        return (
            <Col>
                <Label>Coluna Nome</Label>
                <Dropdown isOpen={state.isSheetUsernameDropdownOpen} toggle={toggleSheetUsernameDropdownOpen}>
                    <DropdownToggle caret>
                        {selectedColumnForUsername || 'Nenhum'}
                    </DropdownToggle>
                    <DropdownMenu>
                        {selectionOptions.map((sheetColumnName: string) => (<DropdownItem onClick={dropDownSheetUsernameColumnSelection}>{sheetColumnName}</DropdownItem>))}
                    </DropdownMenu>
                </Dropdown>
            </Col>)
    }

    const renderSelectedSheetPhone = (sheetColumnNamesArray: Array<string>, selectedColumnForPhone: string | undefined) => {
        let selectionOptions = ['Nenhum', ...sheetColumnNamesArray]
        return (
            <Col>
                <Label>Coluna Telefone</Label>
                <Dropdown isOpen={state.isSheetPhoneDropdownOpen} toggle={toggleSheetPhoneDropdownOpen}>
                    <DropdownToggle caret>
                        {selectedColumnForPhone || 'Nenhum'}
                    </DropdownToggle>
                    <DropdownMenu>
                        {selectionOptions.map((sheetColumnName: string) => (<DropdownItem onClick={dropDownSheetPhoneColumnSelection} >{sheetColumnName}</DropdownItem>))}
                    </DropdownMenu>
                </Dropdown>
            </Col>)
    }

    const renderSelectedSheetAuthData = (sheetColumnNamesArray: Array<string>, authData: { [id: string]: AuthData }, dropDownSheetAuthDataColumnSelected: { [id: string]: string }) => {
        let authDataKeys = Object.keys(authData)
        return (<div>
            {authDataKeys.map((id, index) => {
                let currentAuthData = authData[id]
                let selectedAuthDataValue = dropDownSheetAuthDataColumnSelected[id]
                let selectionOptions = ['Nenhum', ...sheetColumnNamesArray]
                return (
                    <Col>
                        <Label>{currentAuthData.title}</Label>
                        <Dropdown isOpen={state.isAuthDataDropdownOpen[id]} toggle={() => toggleAuthDataDropdownOpen(id)}>
                            <DropdownToggle caret>
                                {selectedAuthDataValue || 'Nenhum'}
                            </DropdownToggle>
                            <DropdownMenu>
                                {selectionOptions.map((sheetColumnName: string) => (<DropdownItem onClick={(event: any) => dropDownSheetAuthDataColumnSelection(event, id)} >{sheetColumnName}</DropdownItem>))}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>)
            })}
        </div>)
    }

    const renderSelectedColumnData = (selectedSheetData: any, selectedColumn: string, primaryProperty: string, selectedColumnForUsername: string | undefined, selectedColumnForPhone: string | undefined, selectedColumnForAuthData: { [id: string]: string }, authData: { [id: string]: AuthData } | undefined) => {
        let credentialsArray = getCredentialsArray(selectedSheetData, selectedColumn, primaryProperty, selectedColumnForUsername, selectedColumnForPhone, selectedColumnForAuthData, authData)
        let jsxElementArray: Array<JSX.Element> = credentialsArray.map((value, index) => {
            /* 
                Que gambiarra horrível, Deus me perdoe. Pra mostrar pro usuário na tela que o telefone digitado
                é inválido eu vejo se o tamanho da string value.phone é maior que 20. Se for o caso é porque
                o texto é Número Invalido XXXXXX então eu coloco ele em vermelho pra destacar pro usuário
            */
            return (<tr style={{ backgroundColor: value.isValid ? 'white' : 'red' }}>
                <th scope="row">{index}</th>
                <td>{value.primaryPropertyValue}</td>
                {value.name && <td>{value.name}</td>}
                {value.phone && <td style={{ backgroundColor: value.phone.length < 20 ? 'white' : 'red' }}>{value.phone}</td>}
                {value.authData && Object.keys(value.authData).map(id => <td>{value.authData![id]}</td>)}
            </tr>)
        })

        return (<Table>
            <tbody>{jsxElementArray}</tbody>
        </Table>)
    }

    const renderAlert = () => {
        return (
            <Alert color="info" isOpen={state.alert !== undefined} toggle={() => setState({ ...state, alert: undefined })}>
                {state.alert}
            </Alert>
        );
    }

    let { error, selectedSheet, selectedColumnForPrimaryProperty, selectedColumnForUsername,
        selectedColumnForPhone, selectedColumnForAuthData, alert, isLoading,
        signUpConfig, isSavingData } = state
    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    return (<div>
        {error && <Alert color="danger">{error.message || error}</Alert>}
        {alert && renderAlert()}
        {company && renderHeader(company)}
        {signUpConfig && renderInputForm(signUpConfig.primaryProperty)}
        {signUpConfig && selectedSheet && renderSheetSelection(selectedSheet, selectedColumnForPrimaryProperty!, signUpConfig.primaryProperty, selectedColumnForUsername, selectedColumnForPhone, isSavingData, signUpConfig.authData, selectedColumnForAuthData)}
    </div>)
}

export default UploadCredentialsScreen