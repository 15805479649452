

export default class ForumComment {

    id: string
    userId: string
    username: string
    userPic?: string
    createdDateInMillis: number
    lastInteractionInMillis: number
    comment: string
    likes: number
    interactionCount: number
    userIdsLiked: Array<string>
    companyId: string
    moduleId: string
    layer: number
    parentId: string | undefined
    parentsChain: Array<string>

    constructor(data: any) {
        this.id = data.id
        this.userId = data.userId
        this.username = data.username
        this.userPic = data.userPic
        this.createdDateInMillis = data.createdDateInMillis
        this.lastInteractionInMillis = data.lastInteractionInMillis
        this.comment = data.comment
        this.likes = data.likes
        this.interactionCount = data.interactionCount
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.userIdsLiked = data.userIdsLiked || []
        this.layer = data.layer
        this.parentId = data.parentId
        this.parentsChain = data.parentsChain || []
    }

    getData(): any {
        let data: any = {
            id: this.id,
            userId: this.userId,
            username: this.username,
            createdDateInMillis: this.createdDateInMillis,
            lastInteractionInMillis: this.lastInteractionInMillis,
            comment: this.comment,
            likes: this.likes,
            interactionCount: this.interactionCount,
            userIdsLiked: this.userIdsLiked,
            companyId: this.companyId,
            moduleId: this.moduleId,
            layer: this.layer,
            parentsChain: this.parentsChain
        }
        if(this.parentId) data.parentId = this.parentId
        if(this.userPic) data.userPic = this.userPic
        return data
    }

}