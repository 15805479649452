import axios from "axios"
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from "../../constants/images"
import FileHelper from "../../files/FileHelper"
import { IAttribution } from "../../models/DeckCards"
import { getImage } from "./utils"

const backendUrl = "http://35.247.250.229:3000"

export const getAudioFileUrl = (projectId: string, filename: string): string => {
    return `${backendUrl}/audio/${projectId}/${filename}`
}

export const getVideoFileUrl = (projectId: string, filename: string): string => {
    return `${backendUrl}/video/${projectId}/${filename}`
}

export const getBackgroundMusicUrl = (filename: string): string => {
    return `${backendUrl}/backgroundMusic/${filename}`
}

export const getFileBuffer = async (url: string): Promise<ArrayBuffer> => {
    let response = await fetch(url)
    let buffer = await response.arrayBuffer()
    return buffer
}

export const removeBackground = (fileDataUrl: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            let response: Response
            if (fileDataUrl.startsWith('https')) {
                response = await fetch(`${backendUrl}/backgroundRemove`, {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ donwloadFile: fileDataUrl })
                })
            } else {
                let fileHelper = new FileHelper()
                let image = await getImage(fileDataUrl)
                let canvas = document.createElement('canvas')
                let resizedImageBlob = await fileHelper.resizeImage(canvas, image, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
                let formData = new FormData()
                formData.append('file', resizedImageBlob)
                response = await fetch(`${backendUrl}/backgroundRemove`, {
                    method: "POST",
                    headers: { 'Authorization': `Token asdf` },
                    body: formData
                })
            }
            let buffer = await response.arrayBuffer()
            const url = window.URL.createObjectURL(new Blob([buffer]));
            resolve(url)
        } catch (error) {
            reject(error)
        }
    })
}

export const createVideoWithAudio = (projectId: string, outputFileName: string, videoBlob: Blob, audioUrl: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            let formData = new FormData()
            formData.append('file', videoBlob)
            formData.append('audioUrl', audioUrl)
            formData.append('projectId', projectId)
            formData.append('outputFileName', outputFileName)
            let response = await fetch(`${backendUrl}/videoWithAudio`, {
                method: "POST",
                headers: { 'Authorization': `Token asdf` },
                body: formData
            })
            let json = await response.json()
            resolve(json.filename)
        } catch (error) {
            reject(error)
        }
    })
}

export const generateVideoWithBlocks = (projectId: string, filenames: Array<string>, backgroundMusic: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await fetch(`${backendUrl}/generateVideoWithBlocks_v2`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ projectId, filenames, backgroundMusic })
            })
            let buffer = await response.arrayBuffer()
            const url = window.URL.createObjectURL(new Blob([buffer]));
            resolve(url)
        } catch (error) {
            reject(error)
        }
    })
}

export const deleteVideoFile = (projectId: string, filename: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await fetch(`${backendUrl}/videoFile`, {
                method: "DELETE",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ projectId, filename })
            })
            let json = await response.json()
            resolve(json.filename)
        } catch (error) {
            reject(error)
        }
    })
}

export const deleteAudioFile = (projectId: string, filename: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await fetch(`${backendUrl}/audioFile`, {
                method: "DELETE",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ projectId, filename })
            })
            let json = await response.json()
            resolve(json.filename)
        } catch (error) {
            reject(error)
        }
    })
}

export const getAllBackgroundMusicFiles = (): Promise<Array<string>> => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await fetch(`${backendUrl}/backgroundMusicFiles`, {
                method: "GET",
                headers: { 'Content-Type': 'application/json' },
            })
            let json = await response.json()
            resolve(json.files)
        } catch (error) {
            reject(error)
        }
    })
}

export const getAllAudioFiles = (projectId: string): Promise<Array<string>> => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await fetch(`${backendUrl}/audioFiles/${projectId}`, {
                method: "GET",
                headers: { 'Content-Type': 'application/json' },
            })
            let json = await response.json()
            resolve(json.files)
        } catch (error) {
            reject(error)
        }
    })
}

export const getAllVideoFiles = (projectId: string): Promise<Array<string>> => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await fetch(`${backendUrl}/videoFiles/${projectId}`, {
                method: "GET",
                headers: { 'Content-Type': 'application/json' },
            })
            let json = await response.json()
            resolve(json.files || [])
        } catch (error) {
            reject(error)
        }
    })
}

export const createTtsFile = (projectId: string, filename: string, text: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await fetch(`${backendUrl}/tts`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ projectId, filename, text })
            })
            let json = await response.json()
            resolve(json.filename)
        } catch (error) {
            reject(error)
        }
    })
}

export const getImagesFromPrompt = async (prompt: string, orientation: string, page: number = 1): Promise<Array<{ image: string; attribution?: IAttribution; }>> => {
    let url = `${backendUrl}/images`
    let result = await axios.post(url, { prompt, orientation, page }, { headers: { 'Content-Type': 'application/json' } })
    return result.data.data.images
}

export const getVideosFromPrompt = async (prompt: string, orientation: string, page: number = 1): Promise<Array<{ videoUrl: string, thumbnail: string }>> => {
    let url = `${backendUrl}/videoSearch`
    let result = await axios.post(url, { prompt, orientation, page }, { headers: { 'Content-Type': 'application/json' } })
    return result.data.data.videos
}