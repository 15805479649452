import React, { Component, Fragment } from 'react'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { IMAGE_ERRORS_MAX_ATTEMPTS } from '../constants/ScoreConstants'
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage, Layer, Text, Image } from 'react-konva'
import getImageDimensions from '../utils/getImageDimensions'
import SelectedSvg from '../images/selected.svg'

interface URLImageProps {
    src: string,
    width: number,
    height: number,
    x: number,
    y: number
}

class URLImage extends React.Component<URLImageProps> {

    state = {
        image: new window.Image()
    };

    componentDidMount() {
        const image = new window.Image();
        image.src = this.props.src
        image.onload = () => {
            // setState will redraw layer
            // because "image" property is changed
            this.setState({
                image: image
            });
        };
    }

    render() {
        return (
            <Image
                image={this.state.image}
                width={this.props.width}
                height={this.props.height}
                x={this.props.x}
                y={this.props.y}
            />
        );
    }
}

interface State {
    screenWidth: number
    screenHeight: number
    imageWidth: number
    imageHeight: number
    isLoadingImage: boolean;
    isFinished: boolean;
    attempts: Array<{ x: number, y: number }>;
    errorsFound: Array<{ x: number, y: number }>;
}

const INTIAL_STATE: State = {
    screenWidth: 0,
    screenHeight: 0,
    imageWidth: 0,
    imageHeight: 0,
    isLoadingImage: true,
    isFinished: false,
    attempts: [],
    errorsFound: [],
}

interface Props { title: string, errors: Array<{ x: number, y: number }>, imageUrl: string, onImageErrorsFinished: (attempts: number) => void, onExit: () => void, showExit: boolean }

export default class ImageErrorsComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = { ...INTIAL_STATE }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    // Bem, aparentemente mudar o state do componente Parent não necessariamente
    // faz o state do componente Child ser atualizado.
    // https://stackoverflow.com/questions/48502546/reactjs-child-not-updating-after-parent-state-changed
    componentWillReceiveProps(nextProps: Props) {
        // essa funcao vai ser chamada toda vez que o timer for atualizado
        // portanto é necessario verificar se o card foi trocado
        if (nextProps.title !== this.props.title) {
            this.setState({ ...INTIAL_STATE });
        }
        // Meu deus, é gambiarra em cima de gambiarra
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        this.updateWindowDimensions()
        getImageDimensions((width, height) => this.setState({ imageWidth: width, imageHeight: height, isLoadingImage: false }), this.props.imageUrl)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth, screenHeight: window.innerHeight });
    }

    renderImageCointainer(errorsFound: Array<{ x: number, y: number }>, screenWidth: number, screenHeight: number, imageWidth: number, imageHeight: number, isFinished: boolean, attempts: Array<{ x: number, y: number }>) {

        let ri = imageWidth / imageHeight
        let rs = screenWidth / screenHeight

        let scale = rs > ri ? screenHeight / imageHeight : screenWidth / imageWidth

        let width = imageWidth * scale
        let height = imageHeight * scale


        const onMouseDown = (evt: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {

            if (isFinished) return
            let stage = evt.target.getStage()
            if (!stage) return
            let pos = stage.getPointerPosition();
            if (!pos) return

            let x = pos.x / scale
            let y = pos.y / scale

            let diagonalTreshold = width > 768 ? 35 : 10
            let diagonal = Math.sqrt(width * width + height * height)

            for (var i = 0; i < this.props.errors.length; i++) {
                let currentError = this.props.errors[i]
                let currentDist = Math.sqrt(Math.pow(x - currentError.x, 2) + Math.pow(y - currentError.y, 2))
                if (currentDist < (diagonal / diagonalTreshold) && errorsFound.filter(errorFound => {
                    return errorFound.x === currentError.x && errorFound.y === currentError.y
                }).length === 0) {
                    let isFinished = errorsFound.length === this.props.errors.length - 1
                    return this.setState({ errorsFound: [...errorsFound, currentError], isFinished }, () => {
                        if (isFinished) {
                            setTimeout(() => {
                                this.props.onImageErrorsFinished(attempts.length)
                            }, 1000)
                        }
                    })
                }
            }

            let currentAttemps = attempts.length + 1
            if (currentAttemps + this.props.errors.length >= IMAGE_ERRORS_MAX_ATTEMPTS) {
                this.setState({ isFinished: true, attempts: [...attempts, { x, y }] }, () => this.props.onExit())
            } else {
                this.setState({ attempts: [...attempts, { x, y }] })
            }
        }

        let fontMultiplier = width > 768 ? 2 : 1

        return <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex align-items-center mt-3 mb-3">
                <FontAwesomeIcon style={{ marginRight: 5 }} color='red' icon={faTimesCircle} />
                <div style={{ fontFamily: 'Montserrat', verticalAlign: 'center' }}>Tentativas Erradas: <b>{attempts.length}</b></div>
            </div>
            <Stage width={width} height={height}>
                <Layer onMouseDown={onMouseDown} onTouchStart={onMouseDown}>
                    <URLImage x={0} y={0} src={this.props.imageUrl} width={width} height={height} />
                    {errorsFound.map(error => { return { x: error.x * scale, y: error.y * scale } }).map((error) => (
                        <Text text="X" width={20 * fontMultiplier} height={20 * fontMultiplier} x={error.x - (10 * fontMultiplier)} y={error.y - (10 * fontMultiplier)} fontSize={20 * fontMultiplier} 
                            fontStyle="bold" fill="red" align='center' verticalAlign='middle' stroke='black' />
                    ))}
                    {attempts.map(attempt => { return { x: attempt.x * scale, y: attempt.y * scale } }).map(({ x, y }) => 
                        <URLImage src={SelectedSvg} width={20 * fontMultiplier} height={20 * fontMultiplier} x={x - (10 * fontMultiplier)} y={y - (10 * fontMultiplier)} />
                    )}
                </Layer>
            </Stage>
        </div>
    }

    render() {

        let { screenWidth, screenHeight, errorsFound, isLoadingImage, imageHeight, imageWidth, isFinished, attempts } = this.state

        if (isLoadingImage) return <Row className='no-gutters'>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center">
                    <Spinner style={{ width: '10rem', height: '10rem', color: 'black', marginTop: 20 }} />
                </div>
            </Col>
        </Row>

        return (<Fragment>
            <Row className='no-gutters' style={{ background: '#FFFFFFDA', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.2)', paddingTop: 5, paddingBottom: 5 }}>
                <Col className='d-flex justify-content-start align-items-center' style={{ minHeight: '4em' }} md={{ size: 8, offset: 2 }}>
                    {this.props.showExit && <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#343a40' icon={faArrowLeft} size='2x' /></Button>}
                    <div className='d-flex flex-column justify-content-center align-items-start' >
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 16 }}>Jogo dos Erros</div>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 12 }}>{this.props.title}</div>
                    </div>
                </Col>
            </Row>
            <Row className='no-gutters'>
                <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {this.renderImageCointainer(errorsFound, screenWidth, screenHeight, imageWidth, imageHeight, isFinished, attempts)}
                </Col>
            </Row>
        </Fragment>)
    }
}