import React, { Component } from 'react';
import Select from 'react-select'
import { AuthData, DocConfig, EmailConfig, PhoneConfig, SignUpConfig } from '../../../models/CompanyConfig';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { validateEmail } from '../../../utils/checkCpfAndEmail';

interface AVCState {
    primaryPropertyValue: string
    name: string
    phone: string
    authDataSelectedValues: { [id: string]: string }
};

const INITIAL_AVCSTATE: AVCState = {
    primaryPropertyValue: '',
    name: '',
    phone: '',
    authDataSelectedValues: {},
};

interface AVCProps {
    signUpConfig: SignUpConfig
    isLoading: boolean
    addUserAccess: (primaryPropertyValue: string, name?: string, phone?: string, authData?: { [id: string]: string }) => void
}


export default class AddAccessValidationComponent extends Component<AVCProps, AVCState> {

    constructor(props: AVCProps) {
        super(props)

        this.state = { ...INITIAL_AVCSTATE }
    }

    applyCpfMask = (value: string) => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    updateDoc = (docConfig: DocConfig, newValue: string) => {
        if (docConfig.mask) {
            if (docConfig.mask === 'cpf') {
                let cpfWithMask = this.applyCpfMask(newValue)
                return this.setState({ primaryPropertyValue: cpfWithMask })
            }
        }

        this.setState({ primaryPropertyValue: newValue })
    }

    updateEmail = (newValue: string) => {
        this.setState({ primaryPropertyValue: newValue.toLowerCase() })
    }

    updatePhone = (phoneConfig: PhoneConfig | undefined, newValue: string) => {

        if(phoneConfig) {
            if(phoneConfig.mask === 'br') {
                let phoneWithMask = this.applyPhoneBrMask(newValue)
                return this.setState({ phone: phoneWithMask })
            }
        } else {
            let phoneWithMask = this.applyPhoneBrMask(newValue)
                return this.setState({ phone: phoneWithMask })
        }

        this.setState({ phone: newValue })
    }

    testCPF = (strCPF: string) => {
        strCPF = strCPF.replace(/\D/g, '')
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (let n = 1; n <= 10; n++) Soma = Soma + parseInt(strCPF.substring(n - 1, n)) * (12 - n);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    applyPhoneBrMask = (value: string) => {
        return value.replace(/\D/g,"")           //Remove tudo o que não é dígito
            .replace(/^(\d{2})(\d)/g,"($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
            .replace(/(\d)(\d{4})$/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
    }

    isInputInvalid = (signUpConfig: SignUpConfig, primaryPropertyValue: string, name: string) => {
        if (name.length < 3) return true
        else if (signUpConfig.primaryProperty === 'doc') {
            let docConfig = signUpConfig.doc!
            if (docConfig.mask) {
                if (docConfig.mask === 'cpf') {
                    return !this.testCPF(primaryPropertyValue)
                }
            }

            return primaryPropertyValue ? primaryPropertyValue.length > 3 : false
        } else if (signUpConfig.primaryProperty === 'email') {
            return !validateEmail(primaryPropertyValue)
        } else {
            return false
        }
    }

    dropDownAuthDataSelection = (selection: string | undefined, id: string) => {
        let authDataSelectedValues = this.state.authDataSelectedValues
        if (selection === undefined || selection.length === 0) {
            delete authDataSelectedValues[id]
            this.setState({ authDataSelectedValues })
        } else {
            authDataSelectedValues[id] = selection
            this.setState({ authDataSelectedValues })
        }
    }

    renderPhoneInput(phoneConfig: PhoneConfig | undefined) {
        return (<FormGroup>
            <Label style={{ fontFamily: 'Montserrat' }} for="phoneTitle">{phoneConfig ? phoneConfig.title : 'Telefone'}</Label>
            <Input style={{ fontFamily: 'Montserrat' }} name="title" id="phoneTitle" placeholder={phoneConfig ? phoneConfig.placeholder : 'Digite o telefone (opcional)'} value={this.state.phone} onChange={(event: any) => this.updatePhone(phoneConfig, event.target.value)} />
        </FormGroup>)
    }

    renderDocInput(primaryPropertyValue: string, docConfig: DocConfig) {
        let isDocValid = this.testCPF(primaryPropertyValue)
        return (<FormGroup>
            <Label style={{ fontFamily: 'Montserrat' }} for="docTitle">{docConfig.title}</Label>
            <Input style={{ fontFamily: 'Montserrat' }} name="title" id="docTitle" value={primaryPropertyValue} onChange={(event: any) => this.updateDoc(docConfig, event.target.value)} />
            {!isDocValid && <div style={{ fontFamily: 'Montserrat', fontSize: 'small', color: 'red', marginTop: 2 }}><i>Por favor digite um {docConfig.title} válido</i></div>}
        </FormGroup>)
    }

    renderEmailInput(primaryPropertyValue: string, emailConfig: EmailConfig) {
        return (<FormGroup>
            <Label style={{ fontFamily: 'Montserrat' }} for="docTitle">{emailConfig.title}</Label>
            <Input style={{ fontFamily: 'Montserrat' }} name="title" id="docTitle" value={primaryPropertyValue} onChange={(event: any) => this.updateEmail(event.target.value)} />
        </FormGroup>)
    }

    renderNameInput(name: string) {
        let isNameValid = name.length > 2
        return (<FormGroup>
            <Label style={{ fontFamily: 'Montserrat' }} for="nameTitle">Nome</Label>
            <Input style={{ fontFamily: 'Montserrat' }} name="name" id="nameTitle" value={name} onChange={(event: any) => this.setState({ name: event.target.value })} />
            {!isNameValid && <div style={{ fontFamily: 'Montserrat', fontSize: 'small', color: 'red', marginTop: 2 }}><i>Por favor digite um nome válido</i></div>}
        </FormGroup>)
    }

    renderAuthDataSelection(authData: { [id: string]: AuthData }, authDataSelectedValues: { [id: string]: string }) {
        let authDataKeys = Object.keys(authData)
        return (<div style={{ marginBottom: 5 }}>
            {authDataKeys.filter(key => !authData[key].show).map((id, index) => {
                let currentAuthData = authData[id]
                let selectedAuthDataValue = authDataSelectedValues[id] || 'Nenhum'

                if (Array.isArray(currentAuthData.values) && currentAuthData.values.length > 0) {

                    let options = currentAuthData.values.map(val => { return { label: val, value: val } })
                    return <FormGroup key={index} style={{ marginBottom: 10 }}>
                            <Label>{currentAuthData.title}</Label>
                            <Select isClearable placeholder={currentAuthData.placeholder} options={options} onChange={opt => this.dropDownAuthDataSelection(opt ? opt!.label : undefined, id)} />
                        </FormGroup>
                } else {
                    return (
                        <FormGroup key={index}>
                            <Label>{currentAuthData.title}</Label>
                            <Input style={{ fontFamily: 'Montserrat' }} name={currentAuthData.title} id={id} value={selectedAuthDataValue} placeholder={currentAuthData.placeholder} onChange={(event: any) => this.dropDownAuthDataSelection(event.target.value as string, id)} />
                        </FormGroup>
                    )
                }
            })}
        </div>)
    }

    render() {
        let { signUpConfig, isLoading } = this.props
        let { primaryPropertyValue, name, phone, authDataSelectedValues } = this.state
        return (<Row>
            <Col className="d-flex flex-column justify-content-center" md={{ size: 8, offset: 2 }}>
                <Form>
                    {signUpConfig.primaryProperty === 'doc' && this.renderDocInput(primaryPropertyValue, signUpConfig.doc!)}
                    {signUpConfig.primaryProperty === 'email' && this.renderEmailInput(primaryPropertyValue, signUpConfig.email!)}
                    {this.renderNameInput(name)}
                    {this.renderPhoneInput(signUpConfig.phone)}
                    {signUpConfig.authData && this.renderAuthDataSelection(signUpConfig.authData, authDataSelectedValues)}
                </Form>
                {isLoading && <div style={{ fontSize: 12, marginLeft: 5, marginTop: 5, color: '#1d3256' }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faSave} />Salvando...</div>}
                {!isLoading && <Button color='primary' block disabled={this.isInputInvalid(signUpConfig, primaryPropertyValue, name)} onClick={() => this.props.addUserAccess(primaryPropertyValue, name, phone.length > 6 ? phone.replace(/\D/g, "") : undefined, authDataSelectedValues)}>Adicionar</Button>}
            </Col>
        </Row>)
    }

}