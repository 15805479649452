import React, { useRef, useState, useEffect, Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WhammyVideo } from './Whammy'
import { Button, ButtonGroup, Col, Container, Input, InputGroup, InputGroupText, Progress, Row, Spinner } from "reactstrap";
import { getImageProp } from "./drawImageProp";
import AudioScreen from "./AudioPlayButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelecAudioComponent from "./selectAudio";
import { createVideoWithAudio, getAudioFileUrl, getVideoFileUrl } from "./services";
import { faInfoCircle, faPlayCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import ConfigMenu from "./ConfigMenu";
import { SavingFile, SelectedVideoFile, VideoElement } from "./interfaces";
import ElementsMenu from "./VideoElements/ElementsMenu";
import VideosMenu from "./VideosMenu";
import VideoPreview from "./VideoPreview";
import VideoFrameGrabber from "./VideoFrameGrabber";
import { drawEndTransition, drawStartTransition } from "./transition";
import VideosCache from "./VideosCache";
import { roundRect } from "./utils";

// 1080p = 1920 x 1080
// Stories = 1080 x 1920
const width = 1920
const height = 1080
const fps = 30
const animationSpeedInSeconds = 1.5
let zoom = 0.4

interface Props {
    projectId: string
}

const Home = () => {

    // initialize a canvas
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const videosCacheRef = useRef<VideosCache>(new VideosCache())
    const [videoFrameGrabber, setVideoFrameGrabber] = useState<VideoFrameGrabber>(new VideoFrameGrabber())
    const [duration, setDuration] = useState(3000)
    const [text, setText] = useState<string>("")
    const [verticalStart, setVerticalStart] = useState<number>(height / 2)
    const [horizontalStart, setHorizontalStart] = useState<number>(25)
    const [font, setFont] = useState<string>("Trebuchet MS")
    const [fontSize, setFontSize] = useState<number>(50)
    const [fontLineHeight, setFontLineHeight] = useState<number>(55)
    const [textColor, setTextColor] = useState<string>("#000000")
    const [textBackgroundColor, setTextBackgroundColor] = useState<string>("#FFFFFF")
    const [textBackgroundType, setTextBackgroundType] = useState<"fixed_left" | "around_text" | "fixed_right">("fixed_left")
    const [isPlaying, setIsPlaying] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [backgroundImage, setBackgroundImage] = useState<HTMLImageElement | undefined>(undefined)
    const [backgroundVerticalPosition, setBackgroundVerticalPosition] = useState<number>(0)
    const [audioFile, setAudioFile] = useState("")
    const [audioDuration, setAudioDuration] = useState(-1)
    const [showSelectAudio, setShowSelectAudio] = useState(false)
    const [videoElements, setVideoElements] = useState<VideoElement[]>([])
    const [showStartTransition, setShowStartTransition] = useState(false)
    const [showEndTransition, setShowEndTransition] = useState(false)
    const [showTextBackgroundColor, setShowTextBackgroundColor] = useState(true)
    const [error, setError] = useState<string>("")
    const [menuType, setMenuType] = useState<"config" | "elements" | "preview">("config")
    const [selectedVideoFiles, setSelectedVideoFiles] = useState<SelectedVideoFile[]>([])
    const [outputFileName, setOutputFileName] = useState<string>("NovoVideo")
    const [savingFile, setSavingFile] = useState<SavingFile | undefined>(undefined)
    const [transitionColor, setTransitionColor] = useState<string>("#FFFFFF")
    const navigate = useNavigate()
    const { projectId } = useParams()


    let frameDuration = 1000 / fps
    let numFrames = fps * (duration / 1000);
    let startTime = (new Date()).getTime()

    useEffect(() => {
        if (isPlaying) { playVideo() }
    }, [isPlaying])

    useEffect(() => {
        if (isRecording) {
            if (!savingFile) {
                setSavingFile(createSavingFileObject());
                if (videoFrameGrabber && videoFrameGrabber.isVideoLoaded) {
                    let newVideoFrameGrabber = new VideoFrameGrabber()
                    newVideoFrameGrabber.setVideoSrc(videoFrameGrabber.video.src)
                    setVideoFrameGrabber(newVideoFrameGrabber)
                }
            }
        }
    }, [isRecording])

    useEffect(() => {
        if (savingFile && savingFile.progress === 0) { record(savingFile) }
    }, [savingFile])

    useEffect(() => {
        draw(canvasRef.current, createSavingFileObject(), 1, true, zoom)
    }, [videoElements, backgroundImage, showStartTransition, showEndTransition, text, textColor,
        backgroundVerticalPosition, font, fontSize, textBackgroundColor, fontLineHeight, verticalStart, horizontalStart])

    useEffect(() => {
        if (showTextBackgroundColor && textBackgroundColor.length === 0) {
            setTextBackgroundColor("#ffffff")
        } else if (!showTextBackgroundColor && textBackgroundColor.length > 0) {
            setTextBackgroundColor("")
        }
    }, [showTextBackgroundColor])

    useEffect(() => {
        if (menuType === "elements" || menuType === "config") draw(canvasRef.current, createSavingFileObject(), 1, true, zoom)
    }, [menuType])

    const createSavingFileObject = () => { return { filename: outputFileName, progress: 0, duration, videoElements, backgroundImage, showStartTransition, showEndTransition, text, textColor, verticalStart, horizontalStart, backgroundVerticalPosition, font, fontSize, textBackgroundColor, textBackgroundType, fontLineHeight, audioFile, videoFrameGrabber, transitionColor } }

    const playVideo = () => {

        let currentFrame = 1
        numFrames = fps * (duration / 1000);
        startTime = (new Date()).getTime()

        const wait = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

        const updateFrame = () => {
            let currentTime = (new Date()).getTime()
            let timePassed = currentTime - startTime
            let frameThatSouldDisplay = Math.floor(timePassed / frameDuration)
            if (currentFrame > numFrames) {
                videoFrameGrabber.isVideoLoaded && videoFrameGrabber.video.pause()
                videoElements.forEach(videoElement => videoElement.frameGrabber && videoElement.frameGrabber.video.play())
                return setIsPlaying(false)
            } else {
                if (frameThatSouldDisplay > currentFrame) {
                    draw(canvasRef.current, createSavingFileObject(), currentFrame, false, zoom)
                    currentFrame = frameThatSouldDisplay
                    updateFrame()
                } else {
                    wait(frameDuration / 3).then(() => updateFrame())
                }
            }
        }

        if (videoFrameGrabber.isVideoLoaded) {
            videoFrameGrabber.setVideoCurrentTime(0).then(() => {
                videoElements.forEach(videoElement => videoElement.frameGrabber && videoElement.frameGrabber.setVideoCurrentTime(0))
                videoFrameGrabber.video.play().then(() => {
                    videoElements.forEach(videoElement => videoElement.frameGrabber && videoElement.frameGrabber.video.play())
                    updateFrame()
                })
            }).catch((err) => console.log(err))
        } else {
            updateFrame()
        }

    }

    const drawFixedLeftText = (ctx: CanvasRenderingContext2D, animationProgress: number, zoom: number, zoomHeight: number, zoomWidth: number, zoomVerticalStart: number, zoomHorizontalStart: number, fontLineHeight: number, currentText: string, textBackgroundColor: string) => {
        const lines = currentText.split('\n');
        const textHeight = fontLineHeight * zoom

        ctx.globalAlpha = animationProgress > 0.90 ? 0.90 : animationProgress

        for (let n = 0; n < lines.length; n++) {
            let lineText = lines[n]
            const textWidth = ctx.measureText(lineText).width;
            ctx.fillText(lineText, zoomHorizontalStart + (textWidth) / 2, zoomVerticalStart + (n * textHeight));
        }

        if (textBackgroundColor.length > 0) {
            ctx.fillStyle = textBackgroundColor
            ctx.beginPath();
            ctx.moveTo(0, 0);
            ctx.moveTo(zoomWidth * 0.4, 0);
            ctx.bezierCurveTo(zoomWidth * 0.1, zoomHeight * 0.7, zoomWidth * 0.7, zoomHeight * 0.3, zoomWidth * 0.5, zoomHeight);
            ctx.lineTo(zoomWidth * 0.5, zoomHeight);
            ctx.lineTo(0, zoomHeight)
            ctx.lineTo(0, 0)
            ctx.fill();
            /*ctx.beginPath();
            ctx.moveTo((zoomWidth * 1.1) / 2, 0)
            ctx.lineTo((zoomWidth * 0.75) / 2, zoomHeight);
            ctx.lineTo(0, zoomHeight);
            ctx.lineTo(0, 0);
            ctx.fill();*/
        }

        ctx.globalAlpha = 1
    }

    const drawAroundText = (ctx: CanvasRenderingContext2D, animationProgress: number, zoom: number, zoomWidth: number, zoomVerticalStart: number, fontLineHeight: number, currentText: string, textBackgroundColor: string, fontSize: number) => {

        const lines = currentText.split('\n');
        const textHeight = fontLineHeight * zoom

        ctx.globalAlpha = animationProgress > 0.95 ? 0.95 : animationProgress

        for (let n = 0; n < lines.length; n++) {
            let lineText = lines[n]
            ctx.fillText(lineText, (zoomWidth / 2), zoomVerticalStart + (n * textHeight));
        }

        const linesWidth = lines.map((line) => ctx.measureText(line).width)

        const textOffset = 30
        ctx.fillStyle = textBackgroundColor;
        for (let j = 0; j < lines.length; j++) {
            const textWidth = linesWidth[j]
            const textHeightOffset = (textHeight - fontSize) / 2
            let x = (zoomWidth / 2) - (textWidth / 2) - textOffset
            let y = zoomVerticalStart + ((j - 1) * textHeight) + textHeightOffset
            let w = textWidth + (2 * textOffset)
            let radius = zoom * 10
            let r = { tl: radius, tr: radius, br: radius, bl: radius }
            if(linesWidth.length > j + 1) {
                if (linesWidth[j + 1] > textWidth) {
                    r.br = 0
                    r.bl = 0
                }
            }
            if(j > 0 && linesWidth.length > 1) {
                if (linesWidth[j - 1] > textWidth) {
                    r.tl = 0
                    r.tr = 0
                }
            }
            // +1 é um offset pra prevenir espaço embaixo do texto
            roundRect(ctx, x, y, w, textHeight + 1, r, true, false)
        }

        ctx.globalAlpha = 1
    }

    const draw = (canvas: HTMLCanvasElement | null, savingFile: SavingFile, currentFrame: number, isPreview = false, zoom = 1) => {

        let { videoElements, backgroundImage, showStartTransition, showEndTransition, text, textColor, verticalStart, horizontalStart, backgroundVerticalPosition, font, fontSize, textBackgroundColor, textBackgroundType, fontLineHeight, videoFrameGrabber, transitionColor } = savingFile

        let zoomWidth = width * zoom
        let zoomHeight = height * zoom
        let zoomFontSize = fontSize * zoom
        let framesInAnimation = animationSpeedInSeconds * fps

        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (!ctx) { console.log('no ctx'); return }
            ctx.globalCompositeOperation = 'destination-over';
            ctx.font = `bold ${zoomFontSize}pt ${font}`;
            ctx.fillStyle = textColor
            ctx.textAlign = 'center';
            ctx.clearRect(0, 0, width, height); // clear canvas

            if (!isPreview) {

                if (showStartTransition) {
                    let framesInTransition = fps * 0.5
                    if (currentFrame <= framesInTransition) {
                        // gambiarra pra começar no zero
                        let transitionProgress = (currentFrame - 1) / (framesInTransition - 1)
                        let transitionWidth = zoomWidth * transitionProgress
                        drawStartTransition(ctx, transitionProgress, transitionWidth, transitionColor, zoomHeight, zoomWidth)
                        ctx.fillStyle = textColor
                    }
                }

                if (showEndTransition) {

                    let framesInTransition = fps * 0.5
                    let transitionStartFrame = numFrames - framesInTransition
                    if (currentFrame >= transitionStartFrame) {
                        let transitionProgress = (currentFrame - transitionStartFrame) / framesInTransition
                        let transitionWidth = zoomWidth * transitionProgress
                        drawEndTransition(ctx, transitionProgress, transitionWidth, transitionColor, zoomHeight)
                        ctx.fillStyle = textColor
                    }
                }

            }

            if (text.length > 0) {
                let currentAnimationProgress = isPreview ? 1 : (currentFrame > framesInAnimation ? 1 : currentFrame / framesInAnimation)
                if(textBackgroundType === 'fixed_left') {
                    drawFixedLeftText(ctx, currentAnimationProgress, zoom, zoomHeight, zoomWidth, verticalStart * zoom, horizontalStart * zoom, fontLineHeight, text, textBackgroundColor)
                } else if(textBackgroundType === 'around_text') {
                    drawAroundText(ctx, currentAnimationProgress, zoom, zoomWidth, verticalStart * zoom, fontLineHeight, text, textBackgroundColor, zoomFontSize)
                }
            }

            try {
                for (let videoElementIndex = 0; videoElementIndex < videoElements.length; videoElementIndex++) {
                    const videoElement = videoElements[videoElementIndex]
                    videoElement.draw(ctx, zoom, zoomWidth, zoomHeight, currentFrame, framesInAnimation, isPreview)
                    /*const { image, frameGrabber, horizontalPosition, verticalPosition, scale, alpha } = videoElement
                    if (image) {
                        let yPos = verticalPosition * zoom
                        var scaledImageHeight = image.height * scale * zoom;
                        var scaledImageWidth = image.width * scale * zoom;
                        let currentAnimationProgress = currentFrame > framesInAnimation ? 1 : currentFrame / framesInAnimation
                        if (!isPreview) {
                            if (currentAnimationProgress > alpha) currentAnimationProgress = alpha
                        } else {
                            currentAnimationProgress = alpha
                        }

                        ctx.globalAlpha = currentAnimationProgress;
                        ctx.drawImage(image, (zoomWidth - scaledImageWidth) * horizontalPosition, yPos, scaledImageWidth, scaledImageHeight);
                        ctx.globalAlpha = 1;
                    } else if(frameGrabber && frameGrabber.isVideoLoaded) {
                        let yPos = verticalPosition * zoom
                        var scaledVideoHeight = frameGrabber.videoHeight * scale * zoom;
                        var scaledVideoWidth = frameGrabber.videoWidth * scale * zoom;
                        let currentAnimationProgress = currentFrame > framesInAnimation ? 1 : currentFrame / framesInAnimation
                        if (!isPreview) {
                            if (currentAnimationProgress > alpha) currentAnimationProgress = alpha
                        } else {
                            currentAnimationProgress = alpha
                        }

                        ctx.globalAlpha = currentAnimationProgress;
                        ctx.drawImage(frameGrabber.video, (zoomWidth - scaledVideoWidth) * horizontalPosition, yPos, scaledVideoWidth, scaledVideoHeight);
                        ctx.globalAlpha = 1;
                    }*/
                }
                if (backgroundImage) {
                    const { cx, cy, newImageWidth, newImageHeight } = getImageProp(backgroundImage, zoomWidth, zoomHeight, 0, backgroundVerticalPosition)
                    ctx.drawImage(backgroundImage, cx, cy, newImageWidth, newImageHeight, 0, 0, zoomWidth, zoomHeight);
                }
                if (videoFrameGrabber && videoFrameGrabber.isVideoLoaded) {
                    console.log('drawing video')
                    ctx.drawImage(videoFrameGrabber.video, 0, 0, zoomWidth, zoomHeight);
                }

            } catch (err) {
                console.log(err)
            }
        } else {
            console.log('no canvas')
        }
    }

    const record = async (savingFile: SavingFile) => {
        try {

            console.log('started recording ' + (new Date()).toISOString())

            let encoder = new WhammyVideo(fps)
            let currentFrame = 1
            numFrames = fps * (duration / 1000);

            let canvas = document.createElement('canvas');
            canvas.id = "RecordingCanvas";
            canvas.width = width;
            canvas.height = height;

            for (let i = 0; i < numFrames; i++) {
                if (savingFile.videoFrameGrabber && savingFile.videoFrameGrabber.isVideoLoaded) {
                    await savingFile.videoFrameGrabber.setVideoCurrentTime(currentFrame / fps)
                }
                draw(canvas, savingFile, currentFrame)
                encoder.add(canvas);
                currentFrame++
                const savingFileWithUpdatedProgress = { ...savingFile, progress: currentFrame / (numFrames * 2) }
                setSavingFile(savingFileWithUpdatedProgress)
            }

            console.log('finished adding canvas ' + (new Date()).toISOString())

            encoder.compile(false, (output) => {
                console.log('finished compiling video ' + (new Date()).toISOString())
                createVideoWithAudio(projectId!, outputFileName, output as Blob, audioFile).then((filename) => {
                    console.log('finished creating video ' + (new Date()).toISOString())
                    let newSelectedVideos = [...selectedVideoFiles]
                    newSelectedVideos.push({ file: filename, pos: newSelectedVideos.length })
                    setSelectedVideoFiles(newSelectedVideos)
                    setSavingFile(undefined)
                    let videoUrl = getVideoFileUrl(projectId!, filename)
                    videosCacheRef.current.getVideoBlob(videoUrl).then((blob) => { videosCacheRef.current.saveVideoInDb(videoUrl, blob) })
                }).catch((err) => {
                    setError(err)
                    const savingFileWithUpdatedProgress = { ...savingFile, progress: -1 }
                    setSavingFile(savingFileWithUpdatedProgress)
                }).finally(() => {
                    setIsRecording(false)
                })
            }, (currentFrame) => {
                const savingFileWithUpdatedProgress = { ...savingFile, progress: currentFrame / numFrames }
                setSavingFile(savingFileWithUpdatedProgress)
            });

        } catch (err) {
            console.log(err)
        }
    }

    const onVideoSelected = (videoUrl: string | undefined) => {
        let loadVideo = (url: string) => videoFrameGrabber.loadVideo(url, () => { setBackgroundImage(undefined); requestAnimationFrame(() => draw(canvasRef.current, createSavingFileObject(), 1, true, zoom)); }, (error) => { console.log(error); setError(error.toString()) })
        if (videoUrl) {
            videosCacheRef.current.getVideoBlob(videoUrl).then((blob) => { 
                videosCacheRef.current.saveVideoInDb('currentVideo', blob, true).then(url => { loadVideo(url!) }).catch((err) => { console.log(err); loadVideo(videoUrl) })
            }).catch((err) => { console.log(err); loadVideo(videoUrl) })
        } else {
            videoFrameGrabber.emptyData()
        }
    }

    if (showSelectAudio) {
        return <Container>
            <SelecAudioComponent projectId={projectId!} fileSelected={(file: string, duration: number) => { setAudioFile(file); setAudioDuration(duration); setShowSelectAudio(false) }} goBack={() => setShowSelectAudio(false)} />
        </Container>
    }

    return (
        <Container fluid>
            {error.length > 0 && <Row><Col>{error}</Col></Row>}
            <Row>
                <Col md={{ size: 4 }}>
                    <Container fluid>
                        <Row>
                            <Col className="d-flex justify-content-center" md={{ size: 12 }}>
                                <ButtonGroup className="mt-2">
                                    <Button color="dark" outline onClick={() => setMenuType('config')} active={menuType === 'config'}>Config</Button>
                                    <Button color="dark" outline onClick={() => setMenuType('elements')} active={menuType === 'elements'}>Elementos</Button>
                                    <Button color="dark" outline onClick={() => setMenuType('preview')} active={menuType === 'preview'}>Blocos</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {menuType === 'elements' && <ElementsMenu height={height} videoElements={videoElements} setVideoElements={setVideoElements} backgroundImage={backgroundImage} setBackgroundImage={setBackgroundImage} isPlaying={isPlaying}
                            backgroundVerticalPosition={backgroundVerticalPosition} setBackgroundVerticalPosition={setBackgroundVerticalPosition} selectedVideoUrl={(videoFrameGrabber && videoFrameGrabber.isVideoLoaded) ? videoFrameGrabber.video.src : undefined}
                            onVideoSelected={onVideoSelected} videosCache={videosCacheRef.current} />}
                        {menuType === 'config' &&
                            <ConfigMenu height={height} width={width} playing={false} text={text} verticalStart={verticalStart} setVerticalStart={setVerticalStart} horizontalStart={horizontalStart} setHorizontalStart={setHorizontalStart}
                                setText={setText} textColor={textColor} setTextColor={setTextColor} showTextBackgroundColor={showTextBackgroundColor}
                                textBackgroundColor={textBackgroundColor} setTextBackgroundColor={setTextBackgroundColor} textBackgroundType={textBackgroundType} setTextBackgroundType={setTextBackgroundType}
                                setShowTextBackgroundColor={setShowTextBackgroundColor} font={font} setFont={setFont}
                                fontLineHeight={fontLineHeight} setFontLineHeight={setFontLineHeight} fontSize={fontSize} setFontSize={setFontSize} audioFile={audioFile}
                                setShowSelectAudio={setShowSelectAudio} duration={duration} setDuration={setDuration} showStartTransition={showStartTransition}
                                setShowStartTransition={setShowStartTransition} showEndTransition={showEndTransition} setShowEndTransition={setShowEndTransition}
                                transitionColor={transitionColor} setTransitionColor={setTransitionColor}
                            />}
                        {menuType === 'preview' && <VideosMenu projectId={projectId!} selectedVideoFiles={selectedVideoFiles} setSelectedVideoFiles={setSelectedVideoFiles} videosCache={videosCacheRef.current} />}
                    </Container>
                </Col>
                {menuType !== 'preview' && <Col md={{ size: 8 }}>
                    {savingFile && <div className="d-flex justify-content-center align-items-center rounded bg-light mt-2 mb-1 border p-2">
                        <Spinner style={{ marginRight: 20 }}>Carregando...</Spinner>
                        <div className="d-flex flex-column">
                            <div><small>Salvando</small></div>
                            <div><small>{savingFile.filename}</small></div>
                        </div>
                        {savingFile.progress >= 0 && <Progress style={{ width: 250, marginLeft: 20 }} value={savingFile.progress * 100}>{(savingFile.progress * 100).toFixed(2)} %</Progress>}
                        {savingFile.progress === -1 && <Button className="m-1" outline onClick={() => { const savingFileWithUpdatedProgress = { ...savingFile, progress: 0 }; setSavingFile(savingFileWithUpdatedProgress) }}><FontAwesomeIcon className="me-1" icon={faSave} />TENTAR NOVAMENTE</Button>}
                    </div>}
                    <div className="d-flex flex-column align-items-center">
                        <div className="rounded bg-light mt-2 mb-1 border"><canvas className="mt-2" ref={canvasRef} width={width * zoom} height={height * zoom} /></div>
                        <InputGroup>
                            <InputGroupText>Nome do Arquivo</InputGroupText>
                            <Input placeholder="Nome do arquivo" value={outputFileName} onChange={(event) => setOutputFileName(event.target.value)} />
                            <InputGroupText>.webm</InputGroupText>
                        </InputGroup>
                        {audioDuration > 0 && <div className="d-flex justify-content-evenly m-1 p-1 rounded bg-light">
                            <div><FontAwesomeIcon className="me-1" icon={faInfoCircle} /> <small>Duração do Audio: {Math.round(audioDuration)} segundos</small></div>
                        </div>}
                        <div className="d-flex justify-content-evenly p-2 m-2 rounded bg-light">
                            <Button className="m-1" outline disabled={isRecording} onClick={() => setIsRecording(true)}><FontAwesomeIcon className="me-1" icon={faSave} />{isRecording ? 'SALVANDO...' : 'SALVAR'}</Button>
                            <Button className="m-1" outline disabled={isPlaying} onClick={() => setIsPlaying(true)}><FontAwesomeIcon className="me-1" icon={faPlayCircle} />{isPlaying ? 'PLAYING...' : 'PLAY'}</Button>
                            {audioFile.length > 0 && <div className="m-1"><AudioScreen audioSrc={getAudioFileUrl(projectId!, audioFile)} start={() => setIsPlaying(true)} /></div>}
                        </div>
                    </div>
                </Col>}
                {menuType === 'preview' && <VideoPreview projectId={projectId!} selectedVideoFiles={selectedVideoFiles} zoom={zoom} height={height} width={width} videosCache={videosCacheRef.current} backgroundMusicFile="1_happy.mp3" />}
            </Row>

        </Container>
    )


}

export default Home;