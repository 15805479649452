export interface IAttribution {
    authorName: string,
    authorLink: string,
    sourceName: string,
    sourceLink: string
}

export default class DeckCard {
    id: string
    type: string
    pos: number
    companyId: string
    moduleId: string
    subModuleId: string
    constructor(data: any) {
        this.id = data.id
        this.type = data.type
        this.pos = data.pos
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
    }

    getData(): any {
        return {
            id: this.id,
            type: this.type,
            pos: this.pos,
            companyId: this.companyId,
            moduleId: this.moduleId,
            subModuleId: this.subModuleId
        }
    }
}

export class DescriptionCard extends DeckCard {
    title: string
    texts: Array<string>
    image: string
    attribution?: IAttribution
    textToSpeech?: string

    constructor(data: any) {
        super(data)
        this.title = data.title
        this.texts = data.texts
        this.image = data.image
        this.textToSpeech = data.textToSpeech
        this.attribution = data.attribution
    }

    getData(): any {
        let data: any = {
            ...super.getData(),
            texts: this.texts,
            image: this.image
        } 
        if(this.textToSpeech) data.textToSpeech = this.textToSpeech
        if (this.attribution) data.attribution = this.attribution
        return data
    }
}

export class WordSearchCard extends DeckCard {
    title: string
    word: string
    constructor(data: any) {
        super(data)
        this.title = data.title
        this.word = data.word
    }

    getData(): any {
        return {
            ...super.getData(),
            title: this.title,
            word: this.word
        }
    }
}

export class QuestionCard extends DeckCard {
    question: string
    questionImageUrl?: string
    answer0: string
    answer1: string
    answer2?: string
    answer3?: string
    imgAnswer0?: string
    imgAnswer1?: string
    imgAnswer2?: string
    imgAnswer3?: string
    correctAnswer: number
    feedbackText?: string
    feedbackImageUrl?: string

    constructor(data: any) {
        super(data)
        this.question = data.question
        this.questionImageUrl = data.questionImageUrl
        this.answer0 = data.answer0
        this.answer1 = data.answer1
        this.answer2 = data.answer2
        this.answer3 = data.answer3
        this.imgAnswer0 = data.imgAnswer0
        this.imgAnswer1 = data.imgAnswer1
        this.imgAnswer2 = data.imgAnswer2
        this.imgAnswer3 = data.imgAnswer3
        this.correctAnswer = data.correctAnswer
        this.feedbackText = data.feedbackText
        this.feedbackImageUrl = data.feedbackImageUrl
    }

    getData(): any {
        let data = {
            ...super.getData(),
            question: this.question,
            answer0: this.answer0,
            answer1: this.answer1,
            correctAnswer: this.correctAnswer
        }
        if(this.questionImageUrl) data.questionImageUrl = this.questionImageUrl
        if(this.answer2) data.answer2 = this.answer2
        if(this.answer3) data.answer3 = this.answer3
        if(this.imgAnswer0) data.imgAnswer0 = this.imgAnswer0
        if(this.imgAnswer1) data.imgAnswer1 = this.imgAnswer1
        if(this.imgAnswer2) data.imgAnswer2 = this.imgAnswer2
        if(this.imgAnswer3) data.imgAnswer3 = this.imgAnswer3
        if(this.feedbackText) data.feedbackText = this.feedbackText
        if(this.feedbackImageUrl) data.feedbackImageUrl = this.feedbackImageUrl
        return data
    }
}

export class InfoCard extends DeckCard {
    image: string
    info: string
    textColor?: string
    attribution?: IAttribution
    textToSpeech?: string

    constructor(data: any) {
        super(data)
        this.image = data.image
        this.info = data.info
        this.textColor = data.textColor
        this.textToSpeech = data.textToSpeech
        this.attribution = data.attribution
    }

    getData(): any {
        let data: any = {
            ...super.getData(),
            image: this.image,
            info: this.info
        }
        if (this.textColor) data.textColor = this.textColor
        if (this.textToSpeech) data.textToSpeech = this.textToSpeech
        if (this.attribution) data.attribution = this.attribution
        return data
    }
}

export class GifCard extends DeckCard {
    gifUrl: string
    text?: string
    textColor?: string
    attribution?: IAttribution
    textToSpeech?: string

    constructor(data: any) {
        super(data)
        this.gifUrl = data.gifUrl
        this.text = data.text
        this.textColor = data.textColor
        this.textToSpeech = data.textToSpeech
        this.attribution = data.attribution
    }

    getData(): any {
        let data = { ...super.getData(), gifUrl: this.gifUrl }
        if (this.text) data.text = this.text
        if (this.textColor) data.textColor = this.textColor
        if (this.textToSpeech) data.textToSpeech = this.textToSpeech
        if (this.attribution) data.attribution = this.attribution
        return data
    }
}

export class MemoryCard extends DeckCard {

    title?: string
    images: Array<{ imgUrl: string, text: string }>

    constructor(data: any) {
        super(data)
        this.title = data.title
        this.images = data.images
    }

    getData(): any {
        let data = { ...super.getData(), images: this.images }
        if(this.title) data.title = this.title
        return data
    }
}

export class OrderedListCard extends DeckCard {

    title: string
    list: Array<{ position: number, text: string }>

    constructor(data: any) {
        super(data)
        this.title = data.title
        this.list = data.list
    }

    getData(): any {
        let data = { ...super.getData(), title: this.title, list: this.list }
        return data
    }

}

export class SelectAnswerCard extends DeckCard {

    question: string
    questionImageUrl?: string
    answer0: string
    answer1: string
    answer2?: string
    answer3?: string
    imgAnswer0?: string
    imgAnswer1?: string
    imgAnswer2?: string
    imgAnswer3?: string
    correctAnswers: Array<number>
    feedbackText?: string
    feedbackImageUrl?: string

    constructor(data: any) {
        super(data)
        this.question = data.question
        this.questionImageUrl = data.questionImageUrl
        this.answer0 = data.answer0
        this.answer1 = data.answer1
        this.answer2 = data.answer2
        this.answer3 = data.answer3
        this.imgAnswer0 = data.imgAnswer0
        this.imgAnswer1 = data.imgAnswer1
        this.imgAnswer2 = data.imgAnswer2
        this.imgAnswer3 = data.imgAnswer3
        this.correctAnswers = data.correctAnswers
        this.feedbackText = data.feedbackText
        this.feedbackImageUrl = data.feedbackImageUrl
    }

    getData(): any {
        let data = {
            ...super.getData(),
            question: this.question,
            answer0: this.answer0,
            answer1: this.answer1,
            correctAnswers: this.correctAnswers
        }
        if(this.questionImageUrl) data.questionImageUrl = this.questionImageUrl
        if(this.answer2) data.answer2 = this.answer2
        if(this.answer3) data.answer3 = this.answer3
        if(this.imgAnswer0) data.imgAnswer0 = this.imgAnswer0
        if(this.imgAnswer1) data.imgAnswer1 = this.imgAnswer1
        if(this.imgAnswer2) data.imgAnswer2 = this.imgAnswer2
        if(this.imgAnswer3) data.imgAnswer3 = this.imgAnswer3
        if(this.feedbackText) data.feedbackText = this.feedbackText
        if(this.feedbackImageUrl) data.feedbackImageUrl = this.feedbackImageUrl
        return data
    }
}

export class ImageErrorsCard extends DeckCard {

    title: string
    imageUrl: string
    feedbackText?: string
    errors: Array<{ x: number, y: number }>
    attribution?: IAttribution

    constructor(data: any) {
        super(data)
        this.title = data.title
        this.imageUrl = data.imageUrl
        this.errors = data.errors
        this.feedbackText = data.feedbackText
        this.attribution = data.attribution
    }

    getData(): any {
        let data = {
            ...super.getData(),
            title: this.title,
            imageUrl: this.imageUrl,
            errors: this.errors,
        }
        if(this.feedbackText) data.feedbackText = this.feedbackText
        if(this.attribution) data.attribution = this.attribution
        return data
    }

}

export function getDeckCard(data: any) {
    if(data.type === DeckCardTypes.Description) {
        return new DescriptionCard(data)
    } else if(data.type === DeckCardTypes.Info) {
        return new InfoCard(data)
    } else if(data.type === DeckCardTypes.WordSearch) {
        return new WordSearchCard(data)
    } else if(data.type === DeckCardTypes.Question) {
        return new QuestionCard(data)
    } else if(data.type === DeckCardTypes.Gif) {
        return new GifCard(data)
    } else if(data.type === DeckCardTypes.Memory) {
        return new MemoryCard(data)
    } else if(data.type === DeckCardTypes.OrderedList) {
        return new OrderedListCard(data)
    } else if(data.type === DeckCardTypes.SelectAnswer) {
        return new SelectAnswerCard(data)
    } else if(data.type === DeckCardTypes.ImageErrors) {
        return new ImageErrorsCard(data)
    } else {
        throw `Unknown card type: ${data.type}`
    }
}

export const DeckCardTypes = {
    Description: 'description',
    Info: 'info',
    WordSearch: 'word_search',
    Question: 'question',
    Gif: 'gif',
    Memory: 'memory',
    OrderedList: 'ordered_list',
    SelectAnswer: 'select_answer',
    ImageErrors: 'image_errors',
}