import React, { Component, Fragment } from 'react';
import _ from 'lodash'
import { Container, Row, Col, Button, Alert, Card, Modal, ModalBody, ModalFooter } from 'reactstrap'
import DeckCard, { getDeckCard, DescriptionCard, WordSearchCard, QuestionCard, InfoCard, GifCard, MemoryCard, OrderedListCard, SelectAnswerCard, ImageErrorsCard } from '../../../../models/DeckCards';
import TokenHelper from '../../../../auth/TokenHelper';
import ContentService from '../../../../services/contentService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTrash, faSave, faEdit, faPlusCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SubModule } from '../../../../models/SubModule';
import CreateDeckCardComponent from './CreateDeckCard'
import EditDeckCardComponent from './EditDeckCard'
import AdminContentService from '../../../../services/admin/adminContentService';
import config from '../../../../config'
import LoadingScreen from '../../../loading';
import Cookies from 'universal-cookie';
import Company from '../../../../models/Company';
import GameSvg from '../../../../images/game.svg'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import './style.css'

enum Action {
    View = 1,
    Edit = 2,
    Create = 3,
}

interface Props {
    subModule: SubModule,
    endpoint: string,
    goBack: () => void
}

interface State {
    company: Company | undefined;
    action: Action;
    deckCards: Array<DeckCard>
    cardsEdit: Array<DeckCard>
    isLoading: boolean;
    error: any | undefined;
    shouldSaveCards: boolean;
    deckCardEdit: DeckCard | undefined;
    showExitPopUp: boolean;
    height: number;
}

const INITIAL_STATE: State = {
    company: undefined,
    deckCards: [],
    cardsEdit: [],
    isLoading: false,
    error: undefined,
    shouldSaveCards: false,
    deckCardEdit: undefined,
    action: Action.View,
    showExitPopUp: false,
    height: 0,
};


export default class DeckComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = { ...INITIAL_STATE }

    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        let cookies = new Cookies()
        let companyData = cookies.get('company')
        if (companyData) {
            let company = new Company(companyData)
            this.setState({ company }, () => this.loadCards(this.props.subModule.id))
        } else {
            this.setState({ error: 'Ops, link invalido. Por favor escolha uma empresa.' })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ height: window.innerHeight });
    }

    async loadCards(subModuleId: string) {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let cards = await contentService.getDeckCards(token, this.props.endpoint, subModuleId)
            let deckCards = cards.sort((a, b) => {
                return a.pos - b.pos
            })
            let cardsEdit = deckCards.map(card => getDeckCard(Object.assign({}, card))!)
            this.setState({ deckCards, cardsEdit, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadCards(subModuleId)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    changeCardPosition = (oldPosition: number, newPosition: number) => {
        let cardsEdit = this.state.cardsEdit.map(card => getDeckCard(Object.assign({}, card))!)
        let oldPositionCard = cardsEdit.filter(card => card.pos === oldPosition)[0]
        let newPositionCard = cardsEdit.filter(card => card.pos === newPosition)[0]
        oldPositionCard.pos = newPosition
        oldPositionCard.id = this.props.subModule.id + '_' + newPosition
        if (newPositionCard) {
            newPositionCard.pos = oldPosition
            newPositionCard.id = this.props.subModule.id + '_' + oldPosition
        }
        let sortedCardsEdit = cardsEdit.sort((a, b) => { return a.pos - b.pos }).map((card, i) => { card.pos = i; return card })
        this.setState({ cardsEdit: sortedCardsEdit, shouldSaveCards: true })
    }

    removeCard = (card: DeckCard) => {
        let cardId = card.id
        let cardsEdit = this.state.cardsEdit.filter(card => card.id !== cardId).map(card => getDeckCard(Object.assign({}, card))!).sort((a, b) => a.pos - b.pos)
        cardsEdit.forEach((card, i) => {
            card.id = this.props.subModule.id + '_' + i
            card.pos = i
        })
        this.setState({ cardsEdit: cardsEdit, shouldSaveCards: true })
    }

    editCard = (card: DeckCard) => {
        //this.setState({ deckCardEdit: card, action: Action.Edit })
        let cardsEdit: Array<DeckCard> = this.state.cardsEdit.map(currentCard => {
            if (currentCard.id === card.id) {
                return card
            } else {
                return getDeckCard(Object.assign({}, currentCard))!
            }
        }).sort((a, b) => a.pos - b.pos)
        this.setState({ cardsEdit, action: Action.View, shouldSaveCards: true })
    }

    addNewCard = (card: DeckCard) => {
        let editing = this.state.action === Action.Edit
        if (editing) {
            this.removeCard(card)
        }
        let cardsEdit = [...this.state.cardsEdit, card]
        this.setState({ cardsEdit, action: Action.View, shouldSaveCards: true })
    }

    save = async (subModule: SubModule, cards: Array<DeckCard>) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            await adminContentService.saveDeckCards(token, config.endpoint, subModule.moduleId, subModule.id, cards)
            let deckCards = cards.map(card => getDeckCard(Object.assign({}, card))!)
            this.setState({ shouldSaveCards: false, isLoading: false, deckCards })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.save(subModule, cards)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    renderExitMessage() {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => this.setState({ showExitPopUp: false })}>
                    <ModalBody>
                        <div style={{ fontFamily: 'Montserrat' }}>Tem certeza que deseja sair sem salvar? Todas as mudanças serão perdidas.</div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center align-items-center' >
                        <Button color="secondary" onClick={() => this.setState({ showExitPopUp: false })}>Não</Button>
                        <Button color="danger" onClick={() => this.props.goBack()}>Sim</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    renderSaveButton(subModule: SubModule, cards: Array<DeckCard>) {
        return (<Row style={{ background: '#FFFFFFDA' }}>
            <Col className="d-flex justify-content-center pb-2" lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                <Button outline color='primary' style={{ minWidth: 200 }} onClick={() => { this.save(subModule, cards) }}><FontAwesomeIcon style={{ verticalAlign: 'middle' }} icon={faSave} size='2x' /> <span style={{ verticalAlign: 'middle' }}>Salvar</span></Button>
            </Col>
        </Row>)
    }

    renderFooter(card: DeckCard, lastCardIndex: number) {
        return (<div className="mt-2">
            {card.pos > 0 && <Button style={{ margin: 3 }} outline size="sm" color="secondary" onClick={() => this.changeCardPosition(card.pos, card.pos - 1)} ><FontAwesomeIcon icon={faArrowLeft} /></Button>}
            {card.pos !== lastCardIndex && <Button style={{ margin: 3 }} outline size="sm" color="secondary" onClick={() => this.changeCardPosition(card.pos, card.pos + 1)}><FontAwesomeIcon icon={faArrowRight} /></Button>}
            <Button color="primary" style={{ margin: 3 }} outline size="sm" onClick={() => this.setState({ deckCardEdit: card, action: Action.Edit })}><FontAwesomeIcon icon={faEdit} /> Editar</Button>
            <Button color="danger" style={{ margin: 3 }} outline size="sm" onClick={() => this.removeCard(card)}><FontAwesomeIcon icon={faTrash} /> Remover</Button>
        </div>)
    }

    renderInfoCard(card: InfoCard, lastCardIndex: number) {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', fontFamily: 'Montserrat', verticalAlign: 'middle' }}><b>{card.pos + 1}</b> Texto e Imagem</div>
                <div className="d-flex flex-column justify-content-center">
                    <div style={{ position: 'relative', width: '100%', textAlign: 'center', marginBottom: card.attribution ? 0 : 5, marginTop: 15 }}>
                        <div className='bg-image' style={{ backgroundImage: `url("${card.image}")`, position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }} />
                        <img src={card.image} alt='imagem do conteúdo' style={{ position: 'relative', width: '100%', height: 'auto', maxHeight: 150, objectFit: 'contain', zIndex: 100 }} />
                    </div>
                    {card.attribution && <div style={{ color: '#8f8f8f', fontSize: 'x-small', textAlign: 'center', marginBottom: 10, marginTop: 2 }}>Foto de <a style={{ color: '#8e8e8e' }} href={card.attribution.authorLink} target='_blank' rel='noreferrer'><b>{card.attribution.authorName}</b></a> em <a style={{ color: '#8e8e8e' }} href={card.attribution.sourceLink} target='_blank' rel='noreferrer'><b>{card.attribution.sourceName}</b></a></div>}
                    <div style={{ color: card.textColor || 'black', margin: 5, textAlign: 'center' }}><b>{card.info}</b></div>
                </div>
                {this.renderFooter(card, lastCardIndex)}
            </div>
        </Col>)
    }

    renderGifCard(card: GifCard, lastCardIndex: number) {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', fontFamily: 'Montserrat', verticalAlign: 'middle' }}><b>{card.pos + 1}</b> Texto e Gif</div>
                <div className="d-flex flex-column justify-content-center">
                    <img src={card.gifUrl} alt='imagem do conteúdo' style={{ width: '100%', maxHeight: 150, maxWidth: 3000, objectFit: 'contain', marginBottom: card.attribution ? 0 : 5, marginTop: 15 }} />
                    {card.attribution && <div style={{ color: '#8f8f8f', fontSize: 'x-small', textAlign: 'center', marginBottom: 10, marginTop: 2 }}>Foto de <a style={{ color: '#8e8e8e' }} href={card.attribution.authorLink} target='_blank' rel='noreferrer'><b>{card.attribution.authorName}</b></a> em <a style={{ color: '#8e8e8e' }} href={card.attribution.sourceLink} target='_blank' rel='noreferrer'><b>{card.attribution.sourceName}</b></a></div>}
                    {card.text && <div style={{ color: card.textColor || 'black', margin: 5, textAlign: 'center' }}><b>{card.text}</b></div>}
                </div>
                {this.renderFooter(card, lastCardIndex)}
            </div>
        </Col>)
    }

    renderDescriptionCard(card: DescriptionCard, lastCardIndex: number) {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', fontFamily: 'Montserrat', verticalAlign: 'middle' }}><b>{card.pos + 1}</b> Título, Textos e Imagem</div>
                <div className="d-flex flex-column justify-content-center">
                    <img className="mt-2 mb-2" src={card.image} alt='imagem do conteúdo' style={{ width: '100%', maxHeight: 150, maxWidth: 3000, objectFit: 'contain' }} />
                    {card.attribution && <div style={{ color: '#8f8f8f', fontSize: 'x-small', textAlign: 'center', marginBottom: 10, marginTop: 2 }}>Foto de <a style={{ color: '#8e8e8e' }} href={card.attribution.authorLink} target='_blank' rel='noreferrer'><b>{card.attribution.authorName}</b></a> em <a style={{ color: '#8e8e8e' }} href={card.attribution.sourceLink} target='_blank' rel='noreferrer'><b>{card.attribution.sourceName}</b></a></div>}
                    <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}><b>{card.title}</b></div>
                    {card.texts.map((text: string, i: number) => { return (<p key={i.toString()} style={{ textAlign: 'center', color: '#353f45', margin: 5 }}>{text}</p>) })}
                </div>
                {this.renderFooter(card, lastCardIndex)}
            </div>
        </Col>)
    }

    renderWordSearchCard(card: WordSearchCard, lastCardIndex: number) {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column justify-content-between mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5, minHeight: 250 }}>
                <div style={{ color: 'DarkGray', fontFamily: 'Montserrat', verticalAlign: 'middle' }}><b>{card.pos + 1}</b> Caça-palavras</div>
                <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}>{card.title}</div>
                <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}><b>{card.word.toUpperCase()}</b></div>
                {this.renderFooter(card, lastCardIndex)}
            </div>
        </Col>)
    }

    renderQuestionCard(card: QuestionCard, lastCardIndex: number) {
        let normalQuestionStyle: React.CSSProperties = { borderRadius: 5, borderStyle: 'solid', borderWidth: 'thin', borderColor: 'LightGray', fontSize: '0.9em', color: '#808080', marginTop: 10, paddingTop: 8, paddingBottom: 8, paddingRight: 5, paddingLeft: 5 }
        let rightQuestionStyle: React.CSSProperties = { ...normalQuestionStyle, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', borderColor: 'green' }
        let renderImgCard = (img: string) => <img alt='imagem do card' src={img} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em', marginRight: 5 }} />
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', verticalAlign: 'middle' }}><b>{card.pos + 1}</b> Acertar Resposta</div>
                <div className="d-flex flex-column justify-content-center">
                    <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}><b>{card.question}</b></div>
                    {card.questionImageUrl && <img style={{ objectFit: 'contain', maxHeight: 150, maxWidth: 3000, marginTop: 10 }} src={card.questionImageUrl} alt="Pergunta" />}
                    {<div style={card.correctAnswer === 0 ? rightQuestionStyle : normalQuestionStyle}>{card.imgAnswer0 && renderImgCard(card.imgAnswer0)}{card.answer0}</div>}
                    {<div style={card.correctAnswer === 1 ? rightQuestionStyle : normalQuestionStyle} >{card.imgAnswer1 && renderImgCard(card.imgAnswer1)}{card.answer1}</div>}
                    {card.answer2 && <div style={card.correctAnswer === 2 ? rightQuestionStyle : normalQuestionStyle}>{card.imgAnswer2 && renderImgCard(card.imgAnswer2)}{card.answer2}</div>}
                    {card.answer3 && <div style={card.correctAnswer === 3 ? rightQuestionStyle : normalQuestionStyle} >{card.imgAnswer3 && renderImgCard(card.imgAnswer3)}{card.answer3}</div>}
                    {card.feedbackText && <div style={{ marginTop: 10 }}>
                        <div style={{ fontSize: 'small' }}>{card.feedbackText}</div>
                    </div>}
                    {card.feedbackImageUrl && <img style={{ objectFit: 'contain', maxHeight: 100, maxWidth: 1500, marginTop: 10 }} src={card.feedbackImageUrl} alt="Feedback da pergunta" />}
                </div>
                {this.renderFooter(card, lastCardIndex)}
            </div>
        </Col>)
    }

    renderSelectAnswerCard(card: SelectAnswerCard, lastCardIndex: number) {
        let normalQuestionStyle: React.CSSProperties = { borderRadius: 5, borderStyle: 'solid', borderWidth: 'thin', borderColor: 'LightGray', fontSize: '0.9em', color: '#808080', marginTop: 10, paddingTop: 8, paddingBottom: 8, paddingRight: 5, paddingLeft: 5 }
        let rightQuestionStyle: React.CSSProperties = { ...normalQuestionStyle, boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.2)', borderColor: 'green' }
        let renderImgCard = (img: string) => <img alt='imagem do card' src={img} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em', marginRight: 5 }} />
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', verticalAlign: 'middle' }}><b>{card.pos + 1}</b> Selecionar Opções</div>
                <div className="d-flex flex-column justify-content-center">
                    <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}><b>{card.question}</b></div>
                    {card.questionImageUrl && <img style={{ objectFit: 'contain', maxHeight: 150, maxWidth: 3000, marginTop: 10 }} src={card.questionImageUrl} alt="Pergunta" />}
                    {<div style={_.includes(card.correctAnswers, 0) ? rightQuestionStyle : normalQuestionStyle}>{card.imgAnswer0 && renderImgCard(card.imgAnswer0)} {card.answer0}</div>}
                    {<div style={_.includes(card.correctAnswers, 1) ? rightQuestionStyle : normalQuestionStyle} >{card.imgAnswer1 && renderImgCard(card.imgAnswer1)} {card.answer1}</div>}
                    {card.answer2 && <div style={_.includes(card.correctAnswers, 2) ? rightQuestionStyle : normalQuestionStyle}>{card.imgAnswer2 && renderImgCard(card.imgAnswer2)} {card.answer2}</div>}
                    {card.answer3 && <div style={_.includes(card.correctAnswers, 3) ? rightQuestionStyle : normalQuestionStyle} >{card.imgAnswer3 && renderImgCard(card.imgAnswer3)} {card.answer3}</div>}
                    {card.feedbackText && <div style={{ marginTop: 10 }}>
                        <div style={{ fontSize: 'small' }}>{card.feedbackText}</div>
                    </div>}
                    {card.feedbackImageUrl && <img style={{ objectFit: 'contain', maxHeight: 100, maxWidth: 1500, marginTop: 10 }} src={card.feedbackImageUrl} alt="Feedback da pergunta" />}
                </div>
                {this.renderFooter(card, lastCardIndex)}
            </div>
        </Col>)
    }

    renderMemoryCard(card: MemoryCard, lastCardIndex: number) {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', verticalAlign: 'middle' }}><b>{card.pos + 1}</b> Memória</div>
                {card.title && <div className="mt-2" style={{ color: '#353f45', textAlign: 'center' }}><b>{card.title}</b></div>}
                <div className='d-flex flex-column mb-2'>
                    {card.images.map(cardImage => <div className='d-flex mt-3 align-items-center p-2' style={{ borderRadius: 5, borderStyle: 'solid', borderWidth: 'thin', borderColor: 'LightGray' }}>
                        <img alt='imagem do jogo da memoria' style={{ objectFit: 'cover', height: 50, width: 50 }} src={cardImage.imgUrl} />
                        <div className='ml-2' style={{ fontFamily: 'Montserrat', fontSize: 'small' }}>{cardImage.text}</div>
                    </div>)}
                </div>
                {this.renderFooter(card, lastCardIndex)}
            </div>
        </Col>)
    }

    renderOrderedListCard(card: OrderedListCard, lastCardIndex: number) {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', verticalAlign: 'middle' }}><b>{card.pos + 1}</b> Passo-a-Passo</div>
                {card.title && <div className="mt-2 mb-2" style={{ color: '#353f45', textAlign: 'center' }}><b>{card.title}</b></div>}
                {card.list.map((listElement: { position: number, text: string }) => { return (<div className="mb-2 p-2" style={{ borderRadius: 5, borderStyle: 'solid', borderWidth: 'thin', borderColor: 'LightGray' }} key={listElement.position.toString()}>{listElement.position} - {listElement.text}</div>) })}
                {this.renderFooter(card, lastCardIndex)}
            </div>
        </Col>)
    }

    renderImageErrorsCard(card: ImageErrorsCard, lastCardIndex: number) {
        return (<Col key={card.id} xs="6" md="3" sm="4">
            <div className="d-flex flex-column mt-2 mb-2 p-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <div style={{ color: 'DarkGray', fontFamily: 'Montserrat', verticalAlign: 'middle' }}><b>{card.pos + 1}</b> Imagem com Erros</div>
                <div className="d-flex flex-column justify-content-center">
                    <div style={{ color: '#353f45', margin: 5, textAlign: 'center' }}>{card.title}</div>
                    <img src={card.imageUrl} alt='imagem do conteúdo' style={{ width: '100%', maxHeight: 150, maxWidth: 3000, objectFit: 'contain', marginBottom: 10, marginTop: 15 }} />
                    <div className="d-flex align-items-center mb-1">
                        <FontAwesomeIcon style={{ marginRight: 5 }} color='red' icon={faTimesCircle} />
                        <div style={{ fontFamily: 'Montserrat', verticalAlign: 'center' }}><b>{card.errors.length} Erros</b></div>
                    </div>
                </div>
                {this.renderFooter(card, lastCardIndex)}
            </div>
        </Col>)
    }

    renderCards(cards: Array<DeckCard>) {
        let lastCardIndex = cards.length - 1
        return cards.map((card, i) => {
            if (card instanceof DescriptionCard) {
                return this.renderDescriptionCard(card, lastCardIndex)
            } else if (card instanceof InfoCard) {
                return this.renderInfoCard(card, lastCardIndex)
            } else if (card instanceof GifCard) {
                return this.renderGifCard(card, lastCardIndex)
            } else if (card instanceof WordSearchCard) {
                return this.renderWordSearchCard(card, lastCardIndex)
            } else if (card instanceof QuestionCard) {
                return this.renderQuestionCard(card, lastCardIndex)
            } else if (card instanceof SelectAnswerCard) {
                return this.renderSelectAnswerCard(card, lastCardIndex)
            } else if (card instanceof MemoryCard) {
                return this.renderMemoryCard(card, lastCardIndex)
            } else if (card instanceof OrderedListCard) {
                return this.renderOrderedListCard(card, lastCardIndex)
            } else if (card instanceof ImageErrorsCard) {
                return this.renderImageErrorsCard(card, lastCardIndex)
            } else {
                return (<Card></Card>)
            }
        })
    }

    renderNoCardsMessage() {
        return (<Col md={{ size: 6, offset: 3 }}>
            <p>Você ainda não adicionou nenhum card para esse desafio.</p>
        </Col>)
    }

    renderError(error: any) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                    {error}
                </Alert>
            </Col>
        </Row>
        );
    }

    renderViewCards(cards: Array<DeckCard>, shouldSaveCards: boolean, title: string, cardsEdit: DeckCard[]) {
        return (<Fragment>
            <Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', minHeight: '4em' }}>
                        <Button color='none' outline onClick={() => shouldSaveCards ? this.setState({ showExitPopUp: true }) : this.props.goBack()}><FontAwesomeIcon color='#0b0c10' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 10, marginTop: 10 }} src={GameSvg} />
                        {/* Nunca fiz tanta gambiarra na minha vida igual essa pra fazer o texto to nome da missão ficar em uma linha e terminar com ... */}
                        <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap', marginLeft: 10, color: '#353f45' }}>
                            <div><b>Fase {this.props.subModule.pos}</b></div>
                            <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', verticalAlign: 'middle' }}>{this.props.subModule.title}</div>
                        </div>
                        <Button className="d-flex justify-content-start align-items-center" color="primary" style={{ fontSize: '1em', borderStyle: 'none', paddingTop: 10, paddingBottom: 10 }} onClick={() => { this.setState({ action: Action.Create }) }}>
                            <FontAwesomeIcon style={{ verticalAlign: 'middle', marginRight: 5 }} icon={faPlusCircle} />
                            <div className="d-flex flex-column align-items-start ml-1" style={{ fontSize: '0.7em' }}>
                                <span style={{ verticalAlign: 'middle', marginTop: 1 }}>Novo Card</span>
                            </div>
                        </Button>
                    </div>
                </Col>
            </Row>
            {shouldSaveCards && this.renderSaveButton(this.props.subModule, cardsEdit)}
            <Row className="pt-2" style={{ overflow: 'auto', zIndex: 1 }}>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div className="mt-2 mb-1" style={{ width: '100%', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.8em', paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 8 }}><FontAwesomeIcon style={{ marginRight: 2 }} icon={faInfoCircle} /><i>A trilha é composta por cards de micro-aprendizagem. Cada card possui um pedaço de informação relevante pondendo conter mídia e elementos interativos. Recomendamos 15 a 30 cards em cada fase.</i></div>
                </Col>
                {cards.length === 0 ? this.renderNoCardsMessage() : this.renderCards(cards)}
            </Row>
        </Fragment>)
    }

    render() {

        let { company, error, cardsEdit, action, shouldSaveCards, isLoading, showExitPopUp, deckCardEdit, height } = this.state

        if (isLoading) { return <LoadingScreen /> }
        let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
        let backgroundPosition = company?.backgroundPosition || 'left top'

        return (<Container className="d-flex flex-column relative" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', overflow: 'none', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
            {error && this.renderError(error)}
            {showExitPopUp && this.renderExitMessage()}
            {action === Action.View && this.renderViewCards(cardsEdit, shouldSaveCards, this.props.subModule.title, cardsEdit)}
            {action === Action.Create && <CreateDeckCardComponent addCard={this.addNewCard} exit={() => this.setState({ action: Action.View })} subModule={this.props.subModule} lastPosition={cardsEdit.length} />}
            {action === Action.Edit && <EditDeckCardComponent card={deckCardEdit!} exit={() => this.setState({ action: Action.View })} editCard={this.editCard} subModule={this.props.subModule} />}
        </Container>)
    }

}