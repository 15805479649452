import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'
import config from '../../config'
import Company from '../../models/Company';
import User from '../../models/User';
import { RankingConfig } from '../../models/CompanyConfig';
import TokenHelper from '../../auth/TokenHelper';
import RankingService from '../../services/rankingService';
import { Row, Alert, Col, Button, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingScreen from '../loading';
import { useUser } from '../../hooks/useUser';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

interface State {
    error: string | undefined;
    isLoading: boolean;
    company?: Company;
    user?: User;
    rankingConfig: Array<RankingConfig>;
    height: number;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    company: undefined,
    user: undefined,
    rankingConfig: [],
    height: 0,
};


const CompanyRankingSelectionScreen = () => {

    const navigate = useNavigate()
    const [state, setState] = useState(INITIAL_STATE)
    const { company, user, noCompany, noUser } = useUser()
    const { companyId } = useParams()
    const { height } = useWindowDimensions()

    useEffect(() => {
        if (company && user) {
            loadRankingConfig(company.id)
        }
        if (noCompany || noUser) {
            if (companyId === 'app') setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.', isLoading: false })
            else navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
        }
    }, [noCompany, noUser, companyId])

    const loadRankingConfig = async (companyId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let rankingService = new RankingService()
            let rankingConfig = await rankingService.getCompanyRankingConfig(token, config.endpoint, companyId)
            setState({ ...state, rankingConfig: rankingConfig.sort((a, b) => a.pos - b.pos), isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadRankingConfig(companyId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const onExit = () => {
        navigate(-1)
    }

    const goToRanking = (companyId: string, rankingConfig: RankingConfig) => {
        var queryValues = `rankingId=${rankingConfig.id}&rankingType=${rankingConfig.type}`
        if (rankingConfig.title) queryValues = queryValues.concat(`&title=${rankingConfig.title}`)
        navigate(`/${companyId}${ROUTES.COMPANY_RANKING}?${queryValues}`)
    }

    const renderError = (error: string) => {
        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                    {error}
                </Alert>
            </Col>
        </Row>);
    }

    const renderCompanyHeader = (company: Company) => {
        return (<Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, width: 60, marginBottom: 10, marginTop: 10, objectFit: 'contain' }} src={company.pic} />
                    <div style={{ color: '#353f45', fontSize: 16, marginLeft: 10 }}>
                        <div><b>Ranking</b></div>
                        <div>{company.name}</div>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    const renderRankingConfig = (companyId: string, data: RankingConfig, index: number) => {
        return (<Col key={index} md={{ size: 6, offset: 3 }} onClick={() => goToRanking(companyId, data)}>
            <div className="d-flex align-items-center" style={{ background: '#FFFFFFDA', marginTop: 10, marginBottom: 10, padding: 10, boxShadow: '2px 2px 4px 2px rgba(0,0,0,0.2)', borderRadius: 3, minHeight: 70 }}>
                <div style={{ fontSize: 18, color: 'black', fontFamily: 'Montserrat', textAlign: 'start', margin: 5, width: '1em' }}><strong>{data.pos + 1}</strong></div>
                <div style={{ color: '#353f45', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5 }}>{data.title}</div>
                <FontAwesomeIcon style={{ marginRight: 5 }} color='#353f45' icon={faAngleRight} size='2x' />
            </div>
        </Col>)
    }

    const renderMainContent = (companyId: string, rankingConfig: Array<RankingConfig>) => {
        return (<Row style={{ overflow: 'auto', marginTop: 10 }}>
            {rankingConfig.map((data, index) => renderRankingConfig(companyId, data, index))}
        </Row>)
    }

    let { error, isLoading, rankingConfig } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container fluid className="d-flex flex-column relative" style={{ overflow: 'hidden', height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && renderError(error)}
        {company && renderCompanyHeader(company)}
        {company && renderMainContent(company.id, rankingConfig)}
    </Container>)

}


export default CompanyRankingSelectionScreen