import React, { useState, useEffect } from 'react';
import { Spinner, Alert } from 'reactstrap';
import TokenHelper from '../../auth/TokenHelper';
import config from '../../config';
import AdminFilesService from '../../services/admin/adminFilesService';

export interface PDFPreviewPops {
    filename: string,
    width: number | string,
    height: number | string,
}

const PDFPreview = (props: PDFPreviewPops) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [secureUrl, setSecureUrl] = useState('')

    const getSecureUrl = async (filename: string) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setError('Usuário não possui token de acesso.')
            let adminFilesService = new AdminFilesService()
            let secureUrl = await adminFilesService.getFileSecureUrl(token, config.endpoint, filename)
            setSecureUrl(secureUrl)
            setLoading(false)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                getSecureUrl(filename)
            } else {
                setError(error.toString())
                setLoading(false)
            }
        }
    }

    useEffect(() => { getSecureUrl(props.filename) }, [])

    if (loading) {
        return <Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />
    }

    if (error) {
        return <Alert color="danger">{error}</Alert>
    }
    
    return <embed width={props.width} height={props.height} src={secureUrl} type="application/pdf"></embed>
}

export default PDFPreview