import React from 'react';
import { useNavigate, useParams } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'
import config from '../../config'
import { RankingConfig } from '../../models/CompanyConfig';
import TokenHelper from '../../auth/TokenHelper';
import RankingService from '../../services/rankingService';
import { Row, Alert, Col, Button, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingScreen from '../loading';
import Module from '../../models/Module';
import ContentService from '../../services/contentService';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useUser } from '../../hooks/useUser';

interface State {
    error: string | undefined;
    isLoading: boolean;
    module?: Module;
    rankingConfig: Array<RankingConfig>;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    module: undefined,
    rankingConfig: [],
};

const ModuleRankingSelectionScreen = () => {

    const navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { companyId, moduleId } = useParams()
    const { height } = useWindowDimensions()
    const { user, company, noUser, noCompany } = useUser()

    React.useEffect(() => {
        if (user && company && moduleId) {
            loadModule(company.id, moduleId)
        }
    }, [user, company, moduleId])

    React.useEffect(() => {
        if (noUser || noCompany) {
            if (companyId === 'app') navigate(ROUTES.APP_LANDING)
            else navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
        }
    }, [noCompany, noUser, companyId])

    React.useEffect(() => {
        if (state.module && companyId) loadRankingConfig(companyId)
    }, [state.module, companyId])

    const loadModule = async (companyId: string, moduleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let module = await contentService.getCompanyModule(token, config.endpoint, companyId, moduleId)
            setState({ ...state, module, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadModule(companyId, moduleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const loadRankingConfig = async (companyId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let rankingService = new RankingService()
            let rankingConfig = await rankingService.getModuleRankingConfig(token, config.endpoint, companyId)
            setState({ ...state, rankingConfig: rankingConfig.sort((a, b) => a.pos - b.pos), isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadRankingConfig(companyId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const onExit = () => {
        navigate(-1)
    }

    const goToRanking = (companyId: string, rankingConfig: RankingConfig, moduleId: string) => {
        var queryValues = `rankingId=${rankingConfig.id}&rankingType=${rankingConfig.type}`
        if (rankingConfig.title) queryValues = queryValues.concat(`&title=${rankingConfig.title}`)
        navigate(`/${companyId}${ROUTES.MODULE_RANKING}/${moduleId}?${queryValues}`)
    }

    const renderError = (error: string) => {
        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                    {error}
                </Alert>
            </Col>
        </Row>);
    }

    const renderCompanyHeader = (module: Module) => {
        return (<Row style={{ background: '#FFFFFFDA', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <Button color='none' outline onClick={() => { navigate(-1) }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, marginBottom: 10, marginTop: 10, objectFit: 'contain' }} src={module.pic} />
                    <div style={{ minWidth: 0, flex: 1, flexWrap: 'wrap' }}>
                        <div style={{ color: '#353f45', verticalAlign: 'middle', fontSize: 16, marginLeft: 10 }}><b>Ranking da Missão</b></div>
                        <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#353f45', verticalAlign: 'middle', marginLeft: 10 }}>{module.title}</div>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    const renderRankingConfig = (companyId: string, data: RankingConfig, index: number, moduleId: string) => {
        return (<Col key={index} md={{ size: 6, offset: 3 }} onClick={() => goToRanking(companyId, data, moduleId)}>
            <div className="d-flex align-items-center" style={{ background: '#FFFFFFDA', marginTop: 10, marginBottom: 10, padding: 10, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', borderRadius: 3, minHeight: 60 }}>
                <div style={{ fontSize: 18, color: 'black', textAlign: 'start', margin: 5, width: '1em' }}><strong>{data.pos + 1}</strong></div>
                <div style={{ color: '#353f45', textAlign: 'start', flex: 1, marginLeft: 5 }}>{data.title}</div>
                <FontAwesomeIcon style={{ marginRight: 5 }} color='#353f45' icon={faAngleRight} size='2x' />
            </div>
        </Col>)
    }

    const renderMainContent = (companyId: string, rankingConfig: Array<RankingConfig>, moduleId: string) => {
        return (<Row style={{ overflow: 'auto', marginTop: 10 }}>
            {rankingConfig.map((data, index) => renderRankingConfig(companyId, data, index, moduleId))}
        </Row>)
    }


    let { error, isLoading, rankingConfig, module } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container fluid className="d-flex flex-column" style={{ overflow: 'hidden', height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && renderError(error)}
        {module && renderCompanyHeader(module)}
        {module && renderMainContent(module.companyId, rankingConfig, module.id)}
    </Container>)
}

export default ModuleRankingSelectionScreen