import React, { Component } from 'react';
import { faImage, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PickImageCardProps } from './interfaces';
import { QuestionCard } from '../../../../../models/DeckCards';
import FileHelper from '../../../../../files/FileHelper';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../../../../constants/images';
import SelectImageComponent from '../SelectImageComponent';

enum QuestionCardSelectedImgPick {
    Answer0 = 0,
    Answer1 = 1,
    Answer2 = 2,
    Answer3 = 3,
    Feedback = 4,
    Question = 5
}

interface QuestionCardState {
    question: string
    questionImageUrl?: string
    answer0: string
    answer1: string
    answer2: string
    answer3: string
    imgAnswer0?: string
    imgAnswer1?: string
    imgAnswer2?: string
    imgAnswer3?: string
    selectedImgAnswerPick?: QuestionCardSelectedImgPick
    correctAnswer: number
    feedbackText: string
    feedbackImageUrl?: string
    isLoading: boolean
}

const QUESTION_CARD_INITIAL_STATE: QuestionCardState = {
    question: '',
    answer0: '',
    answer1: '',
    answer2: '',
    answer3: '',
    correctAnswer: 0,
    feedbackText: '',
    feedbackImageUrl: undefined,
    isLoading: false,
}

export default class EditQuestionCardComponent extends Component<PickImageCardProps<QuestionCard>, QuestionCardState> {

    FileHelper = new FileHelper()

    constructor(props: PickImageCardProps<QuestionCard>) {
        super(props)

        let { question, questionImageUrl, answer0, answer1, answer2, answer3, imgAnswer0, imgAnswer1,
            imgAnswer2, imgAnswer3, correctAnswer, feedbackText, feedbackImageUrl } = props.card

        this.state = {
            ...QUESTION_CARD_INITIAL_STATE,
            question, questionImageUrl, answer0, answer1, answer2: answer2 || '',
            answer3: answer3 || '', imgAnswer0, imgAnswer1,
            imgAnswer2, imgAnswer3, correctAnswer,
            feedbackText: feedbackText || '', feedbackImageUrl
        }

    }

    editCard = (question: string, questionImageUrl: string | undefined, correctAnswer: number, answer0: string, answer1: string, answer2: string, answer3: string, imgAnswer0: string | undefined, imgAnswer1: string | undefined, imgAnswer2: string | undefined, imgAnswer3: string | undefined, feedbackText: string, feedbackImageUrl: string | undefined) => {
        let { id, type, pos, companyId, moduleId, subModuleId } = this.props.card
        let questionCardData: any = {
            id,
            type,
            pos,
            companyId,
            moduleId,
            subModuleId,
            question,
            correctAnswer,
            answer0,
            answer1,
        }
        if (questionImageUrl) questionCardData.questionImageUrl = questionImageUrl
        if (answer2.length > 0) questionCardData.answer2 = answer2
        if (answer3.length > 0) questionCardData.answer3 = answer3
        if (imgAnswer0) questionCardData.imgAnswer0 = imgAnswer0
        if (imgAnswer1) questionCardData.imgAnswer1 = imgAnswer1
        if (imgAnswer2) questionCardData.imgAnswer2 = imgAnswer2
        if (imgAnswer3) questionCardData.imgAnswer3 = imgAnswer3
        if (feedbackText.length > 0) questionCardData.feedbackText = feedbackText
        if (feedbackImageUrl) questionCardData.feedbackImageUrl = feedbackImageUrl
        let questionCard = new QuestionCard(questionCardData)
        this.props.editCardInterface.editCard(questionCard)
    }

    isInputValid = (question: string, answer0: string, answer1: string) => {
        if (question.length < 3) return false
        if (answer0.length === 0) return false
        if (answer1.length === 0) return false
        return true
    }

    uploadFile = async (file: File | undefined) => {
        if (!file) return

        try {
            let imageUrl = await this.FileHelper.getFileDataUrl(file)
            let imageElement = await this.FileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await this.FileHelper.resizeImage(canvas, imageElement, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            this.props.cardImageGetUpload.uploadCardImage(resizedImageBlob, image => image && this.onImageSelected(image))
        } catch (error) {
            this.props.editCardInterface.onError(error)
        }
    }

    onImageSelected = (imageUrl: string) => {
        let selectedImgAnswerPick = this.state.selectedImgAnswerPick
        if (selectedImgAnswerPick === QuestionCardSelectedImgPick.Answer0) {
            this.setState({ imgAnswer0: imageUrl, isLoading: false, selectedImgAnswerPick: undefined }, () => this.props.updateIsPickingFile(false))
        } else if (selectedImgAnswerPick === QuestionCardSelectedImgPick.Answer1) {
            this.setState({ imgAnswer1: imageUrl, isLoading: false, selectedImgAnswerPick: undefined }, () => this.props.updateIsPickingFile(false))
        } else if (selectedImgAnswerPick === QuestionCardSelectedImgPick.Answer2) {
            this.setState({ imgAnswer2: imageUrl, isLoading: false, selectedImgAnswerPick: undefined }, () => this.props.updateIsPickingFile(false))
        } else if (selectedImgAnswerPick === QuestionCardSelectedImgPick.Answer3) {
            this.setState({ imgAnswer3: imageUrl, isLoading: false, selectedImgAnswerPick: undefined }, () => this.props.updateIsPickingFile(false))
        } else if (selectedImgAnswerPick === QuestionCardSelectedImgPick.Feedback) {
            this.setState({ feedbackImageUrl: imageUrl, isLoading: false }, () => this.props.updateIsPickingFile(false))
        } else {
            this.setState({ questionImageUrl: imageUrl, isLoading: false }, () => this.props.updateIsPickingFile(false))
        }
    }

    renderQuestionImagePreview(image: string) {
        return (<div className="p-2 mb-3 rounded bg-light d-flex flex-column align-items-start">
            <div className="text-secondary mb-2">Imagem da Pergunta <i>(Imagem opcional que aparece com a pergunta)</i></div>
            <img alt='card' src={image} style={{ height: 'auto', width: 'auto', maxHeight: 200, objectFit: 'contain' }} />
            <Button className="mt-2 mb-2" color="danger" onClick={() => this.setState({ questionImageUrl: undefined })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faTrash} />Remover Imagem</Button>
        </div>)
    }

    renderSelectQuestionImageSection() {
        return (<div className="p-2 mb-3 rounded bg-light d-flex flex-column align-items-start" >
            <div className="text-secondary mb-2">Imagem da Pergunta <i>(Imagem opcional que aparece com a pergunta)</i></div>
            <Button style={{ marginLeft: 5, marginRight: 5, marginBottom: 15 }} color="secondary" onClick={() => this.setState({ selectedImgAnswerPick: QuestionCardSelectedImgPick.Question }, () => this.props.updateIsPickingFile(true))}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faImage} />Selecionar Imagem</Button>
        </div>)
    }

    renderFeedbackImagePreview(image: string) {
        return (<div className="d-flex flex-column align-items-start">
            <Label style={{ marginLeft: 5 }} for="image_preview">Imagem de Explicação <i>(Imagem opcional que aparece se o usuário errar)</i></Label>
            <img id="image_preview" alt='card' src={image} style={{ height: 'auto', width: 'auto', maxHeight: 200, objectFit: 'contain' }} />
            <Button style={{ marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 15 }} color="danger" onClick={() => this.setState({ feedbackImageUrl: undefined })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faTrash} />Remover Imagem</Button>
        </div>)
    }

    renderSelectFeedbackImageSection() {
        return (<div className="d-flex flex-column align-items-start">
            <div className="text-secondary mb-1">Imagem de Explicação <i>(Imagem opcional que aparece se o usuário errar)</i></div>
            <Button color="secondary" onClick={() => this.setState({ selectedImgAnswerPick: QuestionCardSelectedImgPick.Feedback }, () => this.props.updateIsPickingFile(true))}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faImage} />Selecionar Imagem</Button>
        </div>)
    }

    renderForm(question: string, questionImageUrl: string | undefined, answer0: string, answer1: string, answer2: string, answer3: string, imgAnswer0: string | undefined, imgAnswer1: string | undefined, imgAnswer2: string | undefined, imgAnswer3: string | undefined, correctAnswer: number, feedbackText: string, feedbackImageUrl: string | undefined) {
        let imgAnswers = [imgAnswer0, imgAnswer1, imgAnswer2, imgAnswer3]
        let onAnswerImgClick = (imgIndex: QuestionCardSelectedImgPick) => this.setState({ selectedImgAnswerPick: imgIndex }, () => this.props.updateIsPickingFile(true))
        let onRemoveImgClick = (imgIndex: number) => {
            if (imgIndex === 0) this.setState({ imgAnswer0: undefined })
            if (imgIndex === 1) this.setState({ imgAnswer1: undefined })
            if (imgIndex === 2) this.setState({ imgAnswer2: undefined })
            if (imgIndex === 3) this.setState({ imgAnswer3: undefined })
        }
        let renderSelectImageButton = (index: QuestionCardSelectedImgPick) => <Button outline style={{ border: 'none', paddingTop: 0, paddingBottom: 0, paddingRight: 5, paddingLeft: 5 }} onClick={() => onAnswerImgClick(index)}>
            <FontAwesomeIcon color='#1d3256' icon={faImage} size='1x' />
        </Button>
        let renderRemoveImageButton = (index: number) => <Button block outline style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0, paddingRight: 5, paddingLeft: 5 }} onClick={() => onRemoveImgClick(index)}>
            <div className="d-flex align-items-center justify-content-center">
                <img alt='imagem da resposta' src={imgAnswers[index]} style={{ objectFit: 'contain', width: '1.5em', height: '1.5em', marginRight: 5 }} />
                <FontAwesomeIcon icon={faTimes} size='1x' />
            </div>
        </Button>
        return (<Row>
            <Col className="mt-2 d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <Form style={{ margin: 5 }}>
                    <FormGroup>
                        <Label for="cardQuestion">Pergunta</Label>
                        <Input name="question" id="cardQuestion" placeholder="Pergunta do card" value={question} onChange={(event: any) => this.setState({ question: event.target.value })} />
                    </FormGroup>
                    {!questionImageUrl && this.renderSelectQuestionImageSection()}
                    {questionImageUrl && this.renderQuestionImagePreview(questionImageUrl)}
                    <FormGroup>
                        <InputGroup size="lg">
                            <InputGroupText><b>1</b></InputGroupText>
                            <InputGroupText>
                                {imgAnswers[0] ? renderRemoveImageButton(0) : renderSelectImageButton(QuestionCardSelectedImgPick.Answer0)}
                            </InputGroupText>
                            <Input name="ans0" id="answer0" placeholder="Resposta 1" value={answer0} onChange={(event: any) => this.setState({ answer0: event.target.value })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup size="lg">
                            <InputGroupText><b>2</b></InputGroupText>
                            <InputGroupText>
                                {imgAnswers[1] ? renderRemoveImageButton(1) : renderSelectImageButton(QuestionCardSelectedImgPick.Answer1)}
                            </InputGroupText>
                            <Input name="ans1" id="answer1" placeholder="Resposta 2" value={answer1} onChange={(event: any) => this.setState({ answer1: event.target.value })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup size="lg">
                            <InputGroupText><b>3</b></InputGroupText>
                            <InputGroupText>
                                {imgAnswers[2] ? renderRemoveImageButton(2) : renderSelectImageButton(QuestionCardSelectedImgPick.Answer2)}
                            </InputGroupText>
                            <Input name="ans2" id="answer2" placeholder="Resposta 3" value={answer2} onChange={(event: any) => this.setState({ answer2: event.target.value })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup size="lg">
                            <InputGroupText><b>4</b></InputGroupText>
                            <InputGroupText>
                                {imgAnswers[3] ? renderRemoveImageButton(3) : renderSelectImageButton(QuestionCardSelectedImgPick.Answer3)}
                            </InputGroupText>
                            <Input name="ans3" id="answer3" placeholder="Resposta 4" value={answer3} onChange={(event: any) => this.setState({ answer3: event.target.value })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="correctAnswer">Resposta Correta</Label>
                        <Input type="select" name="selectMulti" id="correctAnswer" value={correctAnswer + 1} onChange={(event: any) => this.setState({ correctAnswer: +event.target.value - 1 })}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </Input>
                    </FormGroup>
                    <FormGroup className="p-2 rounded bg-light">
                        <Label className="text-secondary" for="explanation">Texto de Explicação <i>(Texto opcional que aparece se o usuário errar)</i></Label>
                        <Input className="mb-1" name="explanation" id="explanation" value={feedbackText} onChange={(event: any) => this.setState({ feedbackText: event.target.value })} />
                        {!feedbackImageUrl && this.renderSelectFeedbackImageSection()}
                        {feedbackImageUrl && this.renderFeedbackImagePreview(feedbackImageUrl)}
                    </FormGroup>
                </Form>
            </Col>
            <Col className="d-flex flex-column justify-content-center relative mb-1" md={{ size: 6, offset: 3 }}>
                <Button style={{ margin: 5 }} block disabled={!this.isInputValid(question, answer0, answer1)} color="primary" onClick={() => this.editCard(question, questionImageUrl, correctAnswer, answer0, answer1, answer2, answer3, imgAnswer0, imgAnswer1, imgAnswer2, imgAnswer3, feedbackText, feedbackImageUrl)}>Editar</Button>
            </Col>
        </Row>)
    }

    render() {
        let { question, questionImageUrl, answer0, answer1, answer2, answer3, imgAnswer0, imgAnswer1, imgAnswer2, imgAnswer3, correctAnswer, feedbackText, feedbackImageUrl } = this.state
        if (!this.props.isPickingFile) return this.renderForm(question, questionImageUrl, answer0, answer1, answer2, answer3, imgAnswer0, imgAnswer1, imgAnswer2, imgAnswer3, correctAnswer, feedbackText, feedbackImageUrl)
        else return (<SelectImageComponent getCardImages={this.props.cardImageGetUpload.getCardImages} uploadFile={this.uploadFile} onImageSelected={this.onImageSelected} goBackToEdit={() => this.props.updateIsPickingFile(false)} />)
    }

}