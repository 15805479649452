import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Container, Row, Col, Form, Button, FormGroup, Label, Input } from 'reactstrap';
import LoadingScreen from '../loading';
import EnterService from '../../services/enterService';
import config from '../../config';
import * as ROUTES from '../../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface State {
    isLoading: boolean
    error: any | undefined
    token: string | undefined
    companyId: string | undefined
    newPassword: string
    pwdConfirm: string
}

const INITIAL_STATE: State = {
    isLoading: false,
    error: undefined,
    token: undefined,
    companyId: undefined,
    newPassword: '',
    pwdConfirm: '',
};

const InserNewPasswordScreen = () => {

    const navigate = useNavigate()
    const [state, setState] = React.useState(INITIAL_STATE)
    const { companyId, token } = useParams()

    const updatePwd = async (token: string, newPwd: string, companyId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        try {
            let enterService = new EnterService()
            await enterService.updateUserPwd(config.endpoint, token, newPwd)
            navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.code === 'Token_Not_Found' || error.response.data.error.code === 'Token_Expired') return setState({ ...state, isLoading: false, error: 'O link de trocar senha expirou. Por favor faça novamente o procedimento de troca de senha.' })
            }
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const isInputInvalid = () => {
        if (state.newPassword.length < 8) return true
        return state.newPassword !== state.pwdConfirm
    }

    const renderError = (error: any) => {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>
        )
    }

    const renderExit = (companyId: string) => {
        return (
            <Row>
                <Col className="d-flex flex-column justify-content-center" md={{ size: 6, offset: 3 }}>
                    <Button color='none' className="d-flex justify-content-center align-items-center" style={{ marginTop: 5 }} onClick={() => { navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`) }}><FontAwesomeIcon size='2x' style={{ marginRight: 10 }} icon={faTimes} />Sair</Button>
                </Col>
            </Row>)
    }

    const renderHeader = () => {
        return (<Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: 'white' }}>
            <Col md={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '4em' }}>
                    <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginLeft: 10 }}><b>TROCAR SENHA</b></div>
                </div>
            </Col>
        </Row>)
    }

    const renderNewPwdForm = (token: string, companyId: string, newPassword: string, pwdConfirm: string) => {
        let confirmError = newPassword.length > 0 && newPassword !== pwdConfirm
        return (
            <Row style={{ marginTop: 10 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    <Form style={{ marginTop: 5 }}>
                        <FormGroup>
                            <Label style={{ fontFamily: 'Montserrat' }} for="passwordTitle">Nova Senha</Label>
                            <Input type="password" name="title" id="passwordTitle" placeholder={'Digite a nova senha'} value={newPassword} onChange={(event: any) => setState({ ...state, newPassword: event.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label style={{ fontFamily: 'Montserrat' }} for="cfmPasswordTitle">Confirmar Senha</Label>
                            <Input type="password" name="title" id="cfmPasswordTitle" placeholder={'Confirme a nova senha'} value={pwdConfirm} onChange={(event: any) => setState({ ...state, pwdConfirm: event.target.value })} />
                            {confirmError && <div style={{ fontFamily: 'Montserrat', fontSize: 'small', color: 'red', marginTop: 2 }}><i>A confirmação está diferente da senha.</i></div>}
                            {newPassword.length < 8 && <div style={{ fontFamily: 'Montserrat', fontSize: 'small', color: 'red', marginTop: 2 }}><i>A senha precisa ter no mínimo 8 caracteres.</i></div>}
                        </FormGroup>
                    </Form>
                    <Button style={{ background: '#5977ff' }} block disabled={isInputInvalid()} onClick={() => updatePwd(token, newPassword, companyId)}>Salvar</Button>
                </Col>
            </Row>
        )
    }

    let { error, isLoading, newPassword, pwdConfirm } = state
    if (isLoading) return <LoadingScreen />
    return (<Container fluid style={{ minHeight: '100vh' }}>
        {error && renderError(error)}
        {renderHeader()}
        {token && companyId && renderNewPwdForm(token, companyId, newPassword, pwdConfirm)}
    </Container>)
}

export default InserNewPasswordScreen;