import { faImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../../../../constants/images';
import FileHelper from '../../../../../files/FileHelper';
import { IAttribution, InfoCard } from '../../../../../models/DeckCards';
import LoadingScreen from '../../../../loading';
import ColorPicker from '../CreateDeckCard/ColorPicker';
import SelectImageComponent from '../SelectImageComponent';
import { PickImageCardProps } from './interfaces';

interface InfoCardState {
    info: string;
    textColor: string;
    image: string;
    attribution?: IAttribution;
    isLoading: boolean;
}

const INFO_CARD_INITIAL_STATE: InfoCardState = {
    info: '',
    textColor: '#000000',
    image: '',
    isLoading: false,
    attribution: undefined,
}

export default class EditInfoCardComponent extends Component<PickImageCardProps<InfoCard>, InfoCardState> {

    FileHelper = new FileHelper()

    constructor(props: PickImageCardProps<InfoCard>) {
        super(props)
        this.state = { ...INFO_CARD_INITIAL_STATE, info: props.card.info, image: props.card.image, textColor: props.card.textColor || '#000000', attribution: props.card.attribution }
    }

    uploadImage = async (file: File | undefined) => {
        if (!file) return

        try {
            let imageUrl = await this.FileHelper.getFileDataUrl(file)
            let imageElement = await this.FileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await this.FileHelper.resizeImage(canvas, imageElement, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            this.props.cardImageGetUpload.uploadCardImage(resizedImageBlob, image => image && this.onImageSelected(image))
        } catch (error) {
            this.props.editCardInterface.onError(error)
        }
    }

    onImageSelected = (image: string) => {
        this.setState({ image, isLoading: false, attribution: undefined }, () => this.props.updateIsPickingFile(false))
    }

    onPromptImageSelected = (image: string, attribution: IAttribution) => {
        this.setState({ image, attribution, isLoading: false }, () => this.props.updateIsPickingFile(false))
    }

    editCard = (info: string, textColor: string, image: string) => {
        let { id, pos, companyId, moduleId, subModuleId, type } = this.props.card
        let infoCard = new InfoCard({
            id,
            type,
            pos,
            companyId,
            moduleId,
            subModuleId,
            info,
            textColor,
            image
        })
        if(this.state.attribution) infoCard.attribution = this.state.attribution
        this.props.editCardInterface.editCard(infoCard)
    }

    isInputValid = (info: string, image: string | undefined) => {
        if (!image) return false
        if (info.length < 3) return false
        return true
    }

    renderForm(info: string, textColor: string, image: string) {
        return (<Row>
            <Col className="mt-2 d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <div className="d-flex flex-column p-2 rounded mt-2" style={{ margin: 5, background: '#FFFFFFDA' }}>
                    <img style={{ objectFit: 'contain', maxHeight: 200, maxWidth: 3000, margin: 5 }} src={image} alt="Foto do card" />
                    <Button outline style={{ margin: 5, alignSelf: 'center' }} color="secondary" onClick={() => this.props.updateIsPickingFile(true)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faImage} />Trocar Imagem</Button>
                    <Form className="mt-2">
                        <FormGroup>
                            <Input style={{ color: textColor, fontWeight: 'bold' }} className="text-center" name="title" id="cardText" placeholder="Texto do card" value={info} onChange={(event: any) => this.setState({ info: event.target.value })} />
                        </FormGroup>
                    </Form>
                    <div className="d-flex flex-column align-items-center">
                        <ColorPicker color={textColor} onColorUpdate={(textColor: string) => this.setState({ textColor })} />
                    </div>
                </div>
            </Col>
            <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                <Button style={{ margin: 5 }} disabled={!this.isInputValid(info, image)} color="primary" onClick={() => this.editCard(info, textColor, image!)}>Editar</Button>
            </Col>
        </Row>)
    }

    render() {
        let { isLoading, image, info, textColor } = this.state
        if (isLoading) { return <LoadingScreen /> }
        if (!this.props.isPickingFile) return this.renderForm(info, textColor, image)
        else return (<SelectImageComponent getCardImages={this.props.cardImageGetUpload.getCardImages} uploadFile={this.uploadImage} onImageSelected={this.onImageSelected} onPromptImageSelected={this.onPromptImageSelected} goBackToEdit={() => this.props.updateIsPickingFile(false)} />)
    }
}