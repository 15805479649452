import axios from 'axios'
import LiraFile from '../../models/LiraFile'

export default class AdminFilesService {
    async getAllFiles(token: string, mainUrl: string): Promise<Array<LiraFile>> {
        let url = `${mainUrl}/admin/files/all`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return result.data.data.files.map((file: any) => new LiraFile(file))
    }

    async deleteFile(token: string, mainUrl: string, filename: string): Promise<void> {
        let url = `${mainUrl}/admin/files/delete`
        await axios.post(url, { filename }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async uploadFile(token: string, mainUrl: string, file: File): Promise<LiraFile> {
        let url = `${mainUrl}/admin/files/upload`
        let formData = new FormData()
        formData.append('file', file)
        let result = await axios.post(url, formData, { headers: { 'Authorization': `Token ${token}` } })
        return new LiraFile(result.data.data.file)
    }

    async getFileSecureUrl(token: string, mainUrl: string, filename: string): Promise<string> {
        let url = `${mainUrl}/admin/files/file`
        let result = await axios.post(url, { filename }, { headers: { 'Authorization': `Token ${token}` } })
        return result.data.data.secureUrl
    }
}