import axios from 'axios'

import Module from '../../models/Module'
import getSubModule, { SubModule, SubModuleTypes, SubModuleVideo } from '../../models/SubModule'
import CardGif from '../../models/CardGif'
import CardImage from '../../models/CardImage'
import QuizQuestion, { getQuizQuestion } from '../../models/QuizQuestion'
import { DeckSubModuleFinishedReport, QuizQuestionFinishedReport, SubModuleFinishedReport } from '../../models/SubModuleFinishedReport'
import SubModuleRating from '../../models/SubModuleRating'

export default class AdminContentService {

    async getCompanyModules(token: string, mainUrl: string): Promise<Array<Module>> {
        let url = `${mainUrl}/admin/content/modules`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let modulesRes = new ModulesRes(result.data.data)
        return modulesRes.modules
    }

    async getCompanySubModules(token: string, mainUrl: string, moduleId: string): Promise<Array<SubModule>> {
        let url = `${mainUrl}/admin/content/subModules/${moduleId}`
        let subModules: Array<SubModule> = []
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        result.data.data.subModules.forEach((subModuleData: any) => {
            let subModule = new SubModule(subModuleData)
            subModules.push(subModule)
        })
        return subModules
    }

    async getModuleSubModulesAndData(token: string, mainUrl: string, moduleId: string): Promise<ModuleDataRes> {
        let url = `${mainUrl}/admin/content/moduleData/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new ModuleDataRes(result.data.data)
    }

    async changeModuleVisibility(token: string, mainUrl: string, moduleId: string, visible: boolean) {
        let url = `${mainUrl}/admin/content/updateModule`
        await axios.post(url, { id: moduleId, visible }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async changeSubModuleVisibility(token: string, mainUrl: string, subModuleId: string, visible: boolean) {
        let url = `${mainUrl}/admin/content/updateSubModule`
        await axios.post(url, { id: subModuleId, visible }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async editSubModule(token: string, mainUrl: string, subModuleId: string, title: string, pos: number, mistakesAllowed: boolean | undefined, externalResourceUrl: string | undefined) {
        let url = `${mainUrl}/admin/content/updateSubModule`
        await axios.post(url, { id: subModuleId, title, pos, mistakesAllowed, externalResourceUrl }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async editModule(token: string, mainUrl: string, moduleId: string, title: string, pos: number, allowAccessAuthData: { [id: string]: string[] }, categories: Array<string>, hasLimitedAccess: boolean, limiterQuiz: number, frequency: string) {
        let url = `${mainUrl}/admin/content/updateModule`
        await axios.post(url, { id: moduleId, title, pos, allowAccessAuthData, categories, hasLimitedAccess, limiterQuiz, frequency }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async setModuleFeedback(token: string, mainUrl: string, moduleId: string, feedback: { title: string, description: string, minCharCount: number, blockCertificate: boolean }) {
        let url = `${mainUrl}/admin/content/module/userFeedback`
        const { title, description, minCharCount, blockCertificate } = feedback
        await axios.post(url, { moduleId, title, description, minCharCount, blockCertificate }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async deactivateModuleFeedback(token: string, mainUrl: string, moduleId: string) {
        let url = `${mainUrl}/admin/content/module/userFeedback`
        await axios.post(url, { moduleId, deactivate: true }, { headers: { 'Authorization': `Token ${token}` } })
    }

    uploadModulePic = async (token: string, mainUrl: string, moduleId: string, data: FormData): Promise<string> => {
        let url = `${mainUrl}/admin/content/modulePic/${moduleId}`
        let result = await axios({
            method: 'post',
            url,
            data,
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        return result.data.data.pic as string
    }

    async createModule(token: string, mainUrl: string, title: string, pos: number, pic: string, allowAccessAuthData: { [id: string]: string[] } | undefined, categories: Array<string>, limiterQuiz: number, frequency: string, template?: number, contentUrl?: string): Promise<Module> {
        let url = `${mainUrl}/admin/content/createModule`
        let result = await axios.post(url, { title, pos, pic, allowAccessAuthData, categories, limiterQuiz, frequency, template, url: contentUrl }, { headers: { 'Authorization': `Token ${token}` } })
        return new Module(result.data.data.module)
    }

    async deleteModule(token: string, mainUrl: string, moduleId: string) {
        let url = `${mainUrl}/admin/content/deleteModule`
        await axios.post(url, { moduleId }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async createSubModule(token: string, mainUrl: string, moduleId: string, title: string, pos: number, pic: string, time: number | undefined, mistakesAllowed: boolean | undefined, externalResourceUrl: string | undefined, type: string): Promise<SubModule> {
        let url = `${mainUrl}/admin/content/createSubModule`
        let result = await axios.post(url, { moduleId, title, pos, pic, time, mistakesAllowed, type, externalResourceUrl }, { headers: { 'Authorization': `Token ${token}` } })
        return getSubModule(result.data.data.subModule)
    }

    async deleteSubModule(token: string, mainUrl: string, subModuleId: string) {
        let url = `${mainUrl}/admin/content/deleteSubModule`
        await axios.post(url, { subModuleId }, { headers: { 'Authorization': `Token ${token}` } })
    }

    async getSubModule(token: string, mainUrl: string, subModuleId: string) {
        let url = `${mainUrl}/admin/content/subModule/${subModuleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return getSubModule(result.data.data.subModule)
    }

    async getSubModuleVideo(token: string, mainUrl: string, subModuleId: string) {
        let url = `${mainUrl}/admin/content/subModuleVideo/${subModuleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        if (result.data.data.subModuleVideo) {
            return new SubModuleVideo(result.data.data.subModuleVideo)
        } else {
            return undefined
        }

    }

    uploadCardImage = async (token: string, mainUrl: string, data: FormData, moduleId: string, subModuleId: string): Promise<CardImage> => {
        let url = `${mainUrl}/admin/content/uploadCardImage/${moduleId}/${subModuleId}`
        let result = await axios({
            method: 'post',
            url,
            data,
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        return new CardImage(result.data.data.image)
    }

    uploadCardGif = async (token: string, mainUrl: string, data: FormData, moduleId: string, subModuleId: string): Promise<CardGif> => {
        let url = `${mainUrl}/admin/content/uploadCardGif/${moduleId}/${subModuleId}`
        let result = await axios({
            method: 'post',
            url,
            data,
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        return new CardGif(result.data.data.gif)
    }

    getSignedUrlForUpload = async (token: string, mainUrl: string, moduleId: string, subModuleId: string, type: string): Promise<string> => {
        let url = `${mainUrl}/admin/content/getSignedUrlForVideoUpload`
        let result = await axios.post(url, { filename: subModuleId, type }, { headers: { 'Authorization': `Token ${token}` } })
        return result.data.data.signedUrl
    }

    uploadVideoFromSignedUrl = async (signedUrl: string, file: File): Promise<void> => {
        await axios.put(signedUrl, file, { headers: { 'Content-Type': file.type } })
    }

    saveSubModuleVideo = async (token: string, mainUrl: string, moduleId: string, subModuleId: string, file: File) => {
        let url = `${mainUrl}/admin/content/saveSubModuleVideo`
        let result = await axios.post(url, { moduleId, subModuleId, type: file.type }, { headers: { 'Authorization': `Token ${token}` } })
        return new SubModuleVideo(result.data.data.subModuleVideo)
    }

    createOrUpdateSubModuleVideo = async (token: string, mainUrl: string, moduleId: string, subModuleId: string, videoUrl: string) => {
        let url = `${mainUrl}/admin/content/subModuleVideo`
        let result = await axios.post(url, { moduleId, subModuleId, videoUrl }, { headers: { 'Authorization': `Token ${token}` } })
        return new SubModuleVideo(result.data.data.subModuleVideo)
    }

    getCardImages = async (token: string, mainUrl: string): Promise<CardImagesRes> => {
        let url = `${mainUrl}/admin/content/cardImages`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new CardImagesRes(result.data.data)
    }

    getCardGifs = async (token: string, mainUrl: string): Promise<CardGifsRes> => {
        let url = `${mainUrl}/admin/content/cardGifs`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new CardGifsRes(result.data.data)
    }

    saveDeckCards = async (token: string, mainUrl: string, moduleId: string, subModuleId: string, cardsData: Array<any>) => {
        let url = `${mainUrl}/admin/content/deckCards`
        await axios.post(url, { moduleId, subModuleId, cardsData }, { headers: { 'Authorization': `Token ${token}` } })
    }

    saveQuizCards = async (token: string, mainUrl: string, moduleId: string, subModuleId: string, quizzesData: Array<any>) => {
        let url = `${mainUrl}/admin/content/quizQuestions`
        await axios.post(url, { moduleId, subModuleId, quizzesData }, { headers: { 'Authorization': `Token ${token}` } })
    }

    getQuizQuestions = async (token: string, mainUrl: string, subModuleId: string): Promise<Array<QuizQuestion>> => {
        let url = `${mainUrl}/admin/content/quizQuestions/${subModuleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let quizQuestions = result.data.data.quizQuestions.map((data: any) => getQuizQuestion(data))
        return quizQuestions
    }

    getModuleReport = async (token: string, mainUrl: string, moduleId: string): Promise<ModuleReportRes> => {
        let url = `${mainUrl}/admin/content/moduleReport/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        return new ModuleReportRes(result.data.data)
    }

}

export class ModulesRes {
    modules: Array<Module>;
    constructor(data: any) {
        this.modules = data.modules.map((data: any) => new Module(data))
    }
}

export class CardGifsRes {
    cardGifs: Array<CardGif>;
    modules: Array<Module>;
    constructor(data: any) {
        this.cardGifs = data.cardGifs.map((data: any) => new CardGif(data))
        this.modules = data.modules.map((data: any) => new Module(data))
    }
}

export class CardImagesRes {
    cardImages: Array<CardImage>;
    modules: Array<Module>;
    constructor(data: any) {
        this.cardImages = data.cardImages.map((data: any) => new CardImage(data))
        this.modules = data.modules.map((data: any) => new Module(data))
    }
}

export class ModuleReportRes {
    module: Module;
    subModules: Array<SubModule>;
    reports: Array<SubModuleFinishedReport>
    subModuleRatings: Array<SubModuleRating>
    constructor(data: any) {
        this.module = new Module(data.module)
        this.subModules = data.subModules.map((data: any) => new SubModule(data))
        this.subModuleRatings = data.subModuleRatings.map((data: any) => new SubModuleRating(data))
        this.reports = data.reports.map((report: any) => {
            let subModuleType = report.type
            if (subModuleType === SubModuleTypes.DECK) {
                return new DeckSubModuleFinishedReport(report)
            } else if (subModuleType === SubModuleTypes.QUIZ) {
                return new QuizQuestionFinishedReport(report)
            }
        })
    }
}

export class ModuleDataRes {
    module: Module;
    subModules: Array<SubModule>;
    //subModuleLinks: Array<SubModuleLink>
    subModuleVideos: Array<SubModuleVideo>
    constructor(data: any) {
        this.module = new Module(data.module)
        this.subModules = data.subModules.map((data: any) => new SubModule(data))
        this.subModuleVideos = data.subModuleVideos.map((data: any) => new SubModuleVideo(data))
    }
}