import React, { Component, useEffect, useRef } from 'react';
import { Alert, Container, Row, Col, Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import io from 'socket.io-client'

import Company from '../../../models/Company';

import User from '../../../models/User';
import RealTimeQuizMain from './RealTimeQuizMain';
import OponentScoreComponent from './OponentScoreComponent'
import GameFinishedComponent from './GameFinishedComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import config from '../../../config'
import { useUser } from '../../../hooks/useUser';

interface State {
    error: string | undefined;
    company: Company | undefined;
    user: User | undefined;
    oponent: User | undefined;
    roomId: string | undefined
    isFinished: boolean
};

const INITIAL_STATE: State = {
    error: undefined,
    company: undefined,
    user: undefined,
    oponent: undefined,
    roomId: undefined,
    isFinished: false,
};

export interface OponentUpdate {
    updateOponentScore: ((newScore: number) => void)
    oponentStatusChanged: ((status: string) => void)
}


const GameScreen = () => {

    const socket = useRef<SocketIOClient.Socket | undefined>(undefined)
    const navigate = useNavigate()
    const { user, company, noUser, noCompany } = useUser()

    // Tem que manter o tracking desses valores, mas não deve atualizar o componente
    const currentUserScore = useRef<number>(0)
    const lastOponentScore = useRef<number>(0)
    const oponentStatus = useRef<string>('not_connected')

    // Para atualizar o componente de score do usuário sem precisar mudar state
    const oponentUpdate = useRef<OponentUpdate | undefined>(undefined)

    const [state, setState] = React.useState<State>({ ...INITIAL_STATE })
    const [searchParams, setSearchParams] = useSearchParams()

    const { subModuleId, roomId } = queryString.parse(searchParams.toString())

    useEffect(() => {
        return () => { socket.current && socket.current.close() }
    }, [])

    useEffect(() => {
        if(user && roomId) iniSocket(user, roomId as string)
    }, [user, roomId])

    useEffect(() => {
        if(noUser || noCompany) setState({ ...state, error: 'Ops, link invalido. Você precisa fazer login primeiro.' })
    }, [noUser, noCompany])

    const iniSocket = (user: User, roomId: string) => {
        if (!socket.current) {
            socket.current = io(config.socket, {
                query: {
                    type: 'game', userId: user.id, roomId
                }, autoConnect: false
            })
        }

        if (!socket.current.connected) socket.current.connect()

        // Pede pra ver quem esta online
        socket.current.emit('request_users_online')
        // Avisa os usuarios da empresa que esta online
        socket.current.emit('user_entered_game')

        socket.current.on("connect", () => {
            setState({ ...state, error: undefined })
        });

        // Erro ao conectar
        socket.current.on('connect_error', (err: any) => {
            if (err.message === "invalid credentials") {
                //socket.auth.token = "efgh";
                //socket.connect();
            }
            console.log(err)
            setState({ ...state, error: err.toString() })
        });

        socket.current.on("user_ping", () => {
            // Avisa os usuarios da empresa que esta online
            socket.current && socket.current.emit('user_entered_game')
        });

        // Usuario entrou
        socket.current.on("user_enter", (data: any) => {
            // Oponente entrou no jogo. Pode comecar
            if (data.userId !== user.id && !state.oponent) {
                let { username, pic, thumbnail } = data
                let oponent = new User({ id: data.userId, username, pic, thumbnail })
                oponentStatus.current = 'connected'
                setState({ ...state, oponent })
            }
        });

        // Oponente atualizou o score
        socket.current.on("score_update", (data: any) => {

            if (data.userId !== user.id && data.score > lastOponentScore.current) {
                lastOponentScore.current = data.score
                oponentUpdate.current && oponentUpdate.current.updateOponentScore(data.score)
            }
        });

        // Oponente terminou
        socket.current.on("user_finished", (data: any) => {
            if (data.userId !== user.id) {
                oponentStatus.current = 'finished'
                oponentUpdate.current && oponentUpdate.current.oponentStatusChanged('finished')
            }
        });


        // Oponente desconectou
        socket.current.on("user_disconnected", (data: any) => {
            if (data.userId !== user.id) {
                // Se o oponente terminou de jogar nao precisa avisar que ele desconectou
                if (oponentStatus.current !== 'finished') {
                    oponentStatus.current = 'disconnected'
                    oponentUpdate.current && oponentUpdate.current.oponentStatusChanged('disconnected')
                }
            }
        });
    }

    const userScoreChanged = (score: number) => {
        let userId = user?.id
        currentUserScore.current = score
        if (socket.current) socket.current.emit('new_score', { userId, score })
    }

    const onExit = () => {
        navigate(-1)
    }

    const quizFinished = () => {
        setState({ ...state, isFinished: true })
        if (socket.current) socket.current.emit('finish_game')
    }

    const renderError = (error: string) => {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                    {error}
                </Alert>
            </Col>
        </Row>
        );
    }

    let { error, oponent, isFinished } = state

        //if (error) return this.renderError(error)
        if (isFinished) {
            return <GameFinishedComponent ref={ref => { if (ref) oponentUpdate.current = ref }} user={user!} oponent={oponent!} userScore={currentUserScore.current} oponentScore={lastOponentScore.current} oponentStatus={oponentStatus.current} goBack={onExit} />
        } else {
            if (oponent && company && user) {
                return (<div>
                    <OponentScoreComponent ref={ref => { if (ref) oponentUpdate.current = ref }} oponent={oponent} onExit={onExit} />
                    <RealTimeQuizMain userScoreChanged={userScoreChanged} subModuleId={subModuleId! as string} onExit={onExit} quizFinished={quizFinished} user={user} company={company} />
                </div>)
            } else {
                return (<Container fluid className='d-flex flex-column' style={{ background: 'white', marginBottom: 10 }}>
                    <Row style={{ boxShadow: '2px 1px 2px 1px rgba(0,0,0,0.2)', background: 'white', paddingTop: 5, paddingBottom: 5 }}>
                        <Col md={{ size: 6, offset: 3 }}>
                            <div className="d-flex align-items-center" style={{ background: 'white', margin: 5, padding: 5 }}>
                                <div style={{ alignSelf: 'center' }}>
                                    <Button color='none' outline onClick={() => { onExit() }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                                </div>
                                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}>Aguardando seu oponente...</div>
                            </div>
                        </Col>
                    </Row>
                    {error && renderError(error)}
                    <Row style={{ marginTop: 10 }}>
                        <Col md={{ size: 6, offset: 3 }}>
                            <div className="d-flex justify-content-center">
                                {company && <img alt='imagem da empresa' style={{ maxHeight: '20vh', marginBottom: 5, borderRadius: 5 }} src={company.pic} />}
                            </div>
                            <div className="d-flex justify-content-center">
                                <Spinner style={{ width: '10rem', height: '10rem', color: 'black' }} />{' '}
                            </div>
                        </Col>
                    </Row>
                </Container>)
            }
        }

}

export default GameScreen
