import React, { Component, useEffect, useState } from 'react';
import { Alert, Container, Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'

import LoadingScreen from '../loading'
import config from '../../config'

import * as ROUTES from '../../constants/routes'
import ContentService from '../../services/contentService'
import TokenHelper from '../../auth/TokenHelper';
import { Frequency } from '../../models/Module';
import { SubModule, SubModuleQuiz, SubModuleTypes } from '../../models/SubModule'
import DeckCard from '../../models/DeckCards';
import DeckCardsScreen from './DeckCardsScreen';
import QuizScreen from './QuizScreen'
import VideoScreen from './VideoScreen'
import { DeckCardReport } from '../../models/DeckCardsReport';
import ScoreService from '../../services/scoreService';
import QuizQuestion from '../../models/QuizQuestion';
import { QuizQuestionsReport } from '../../models/QuizQuestionsReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useUser } from '../../hooks/useUser';

interface State {
    error: string | undefined;
    isLoading: boolean;
    subModule: SubModule | undefined;
    deckCards: Array<DeckCard> | undefined
    quizQuestions: Array<QuizQuestion> | undefined
    videoUrl: string | undefined
    isFinished: boolean
    retry: (() => void) | undefined
    modalMessage: string | undefined
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    subModule: undefined,
    deckCards: undefined,
    quizQuestions: undefined,
    videoUrl: undefined,
    isFinished: false,
    retry: undefined,
    modalMessage: undefined,
};

const GameScreen = () => {

    const navigate = useNavigate()
    const companyId = useParams().companyId
    const { height } = useWindowDimensions()
    const [searchParams, setSearchParams] = useSearchParams()
    const [state, setState] = useState(INITIAL_STATE)
    const { company, user, noCompany, noUser } = useUser()
    const { subModuleId, subModuleType } = queryString.parse(searchParams.toString())

    useEffect(() => {
        if (subModuleId && subModuleType) loadSubModule(subModuleId as string)
    }, [subModuleId, subModuleType])

    useEffect(() => {
        if (companyId === 'app') setState({ ...state, error: 'Ops, link invalido. Por favor escolha uma empresa.', isLoading: false })
        else if (noCompany || noUser) navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
    }, [noCompany, noUser])

    useEffect(() => {
        if (state.subModule) loadGameData(state.subModule)
    }, [state.subModule])


    const loadSubModule = async (subModuleId: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let { subModule } = await contentService.getSubModuleAndNext(token, config.endpoint, subModuleId)
            setState({ ...state, subModule })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadSubModule(subModuleId)
            } else {
                setState({ ...state, isLoading: false, error: error.toString(), retry: () => { loadSubModule(subModuleId) } })
            }
        }
    }


    const onExit = (moduleId: string | undefined = undefined) => {
        if (moduleId) navigate(`/${company!.id}${ROUTES.SUBMODULE_SELECT}?moduleId=${moduleId}`)
        else navigate(-1)
    }

    const goToRanking = (moduleId: string) => {
        navigate(`/${company!.id}${ROUTES.SELECT_MODULE_RANKING}/${moduleId}`)
    }

    const goToNext = (subModule: SubModule) => {
        if (subModule.type === SubModuleTypes.LINK) navigate(`/${company!.id}${ROUTES.SUBMODULE_SELECT}?moduleId=${subModule.moduleId}`)
        setState({ ...state, subModule, videoUrl: undefined })
    }

    const onFinishDeck = async (startTime: number, endTime: number, deckCardsReport: Array<DeckCardReport<DeckCard>>) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let scoreService = new ScoreService()
            await scoreService.saveUserDeckScore(token, config.endpoint, startTime, endTime, deckCardsReport, state.subModule!.id)
            navigate(`/${company!.id}${ROUTES.RESULT}?subModuleId=${state.subModule!.id}&subModuleType=${state.subModule!.type}`, { replace: true })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                onFinishDeck(startTime, endTime, deckCardsReport)
            } else {
                setState({ ...state, isLoading: false, isFinished: true, error: error.toString(), retry: () => { onFinishDeck(startTime, endTime, deckCardsReport) } })
            }
        }
    }

    const onFinishQuiz = async (startTime: number, endTime: number, quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let scoreService = new ScoreService()
            await scoreService.saveUserQuizScore(token, config.endpoint, startTime, endTime, quizQuestionsReport, state.subModule!.id)
            navigate(`/${company!.id}${ROUTES.RESULT}?subModuleId=${state.subModule!.id}&subModuleType=${state.subModule!.type}`, { replace: true })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                onFinishQuiz(startTime, endTime, quizQuestionsReport)
            } else {
                setState({ ...state, isLoading: false, isFinished: true, error: error.toString(), retry: () => { onFinishQuiz(startTime, endTime, quizQuestionsReport) } })
            }
        }
    }

    const onFinishVideo = async (startTime: number, endTime: number) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let scoreService = new ScoreService()
            await scoreService.saveUserSubModuleVideoScore(token, config.endpoint, startTime, endTime, state.subModule!.id)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                onFinishVideo(startTime, endTime)
            } else {
                setState({ ...state, isFinished: true, error: error.toString(), retry: () => { onFinishVideo(startTime, endTime) } })
            }
        }
    }

    const loadGameData = async (subModule: SubModule) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return setState({ ...state, error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            if (subModule.type === SubModuleTypes.DECK) {
                let deckCards = await contentService.getDeckCards(token, config.endpoint, subModule.id)
                let sortedDeckCars = deckCards.sort((a, b) => {
                    return a.pos - b.pos
                })
                setState({ ...state, deckCards: sortedDeckCars, isLoading: false })
            } else if (subModule.type === SubModuleTypes.QUIZ) {
                let quizQuestions = await contentService.getQuizQuestions(token, config.endpoint, subModule.id)
                setState({ ...state, quizQuestions, isLoading: false })
            } else if (subModule.type === SubModuleTypes.VIDEO) {
                let subModuleVideo = await contentService.getSubModuleVideo(token, config.endpoint, subModule.id)
                setState({ ...state, videoUrl: subModuleVideo.videoUrl, isLoading: false })
            }

        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadGameData(subModule)
            } else {
                // Meu Deus que coisa horrível
                if (error.response && error.response.data && error.response.data.error && error.response.data.error.code === 'Max_Attempts_Exceeded') {
                    let limiterQuiz = error.response.data.attempts && error.response.data.attempts.limiterQuiz
                    let frequency = error.response.data.attempts && error.response.data.attempts.frequency
                    var modalLimitText = 'Você já atingiu o limite de vezes que pode jogar o Quiz no período.'
                    if (frequency === Frequency.Day) {
                        modalLimitText = `Você já jogou o Quiz ${limiterQuiz} vezes hoje. Amanhã você pode jogar novamente.`
                    } else if (frequency === Frequency.Week) {
                        modalLimitText = `Você já jogou o Quiz ${limiterQuiz} vezes essa semana. Semana que vem você pode jogar novamente.`
                    } else if (frequency === Frequency.Month) {
                        modalLimitText = `Você já jogou o Quiz ${limiterQuiz} vezes esse mês. Mês que vem você pode jogar novamente.`
                    }
                    setState({ ...state, isLoading: false, modalMessage: modalLimitText })
                } else {
                    setState({ ...state, isLoading: false, error: error.toString() })
                }
            }
        }
    }

    const renderError = (error: string) => {
        return (
            <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                {error}
            </Alert>
        );
    }

    const renderErrorAndRetry = (error: string, retry: () => void) => {
        return (<Container className='d-flex flex-column' fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 8, offset: 2 }}>
                    <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 8, offset: 2 }}>
                    <div style={{ alignSelf: 'center', marginTop: 10 }}>
                        <Button color='none' outline onClick={() => { retry() }}><FontAwesomeIcon color='#343a40' icon={faRedo} size='2x' />Tentar Novamente</Button>
                    </div>
                </Col>
            </Row>
        </Container>)
    }

    const renderModal = (message: string) => {
        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => onExit()}>
                    <ModalBody>{message}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => onExit()}>OK</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    let { error, isLoading, deckCards, quizQuestions, videoUrl, retry, modalMessage } = state

    if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    if (error && retry) {
        return renderErrorAndRetry(error, retry)
    }

    return (<div>
        {error && renderError(error)}
        {modalMessage && renderModal(modalMessage)}
        {deckCards && company && <DeckCardsScreen company={company} subModuleId={state.subModule!.id} onExit={onExit} onFinish={onFinishDeck} deckCards={deckCards} />}
        {quizQuestions && company && <QuizScreen company={company} subModule={state.subModule as SubModuleQuiz} onExit={onExit} onFinish={onFinishQuiz} quizQuestions={quizQuestions} />}
        {videoUrl && <VideoScreen subModuleId={state.subModule!.id} onExit={onExit} onFinish={onFinishVideo} goToRanking={goToRanking} videoUrl={videoUrl} goToNext={goToNext} companyColor={company ? company.mainColor : undefined} companyPic={company ? company.pic : undefined} />}
    </div>)
}

export default GameScreen