import React, { Component, Fragment } from "react"
import { Alert, Button, Col, Container, Row } from "reactstrap"
import _ from 'lodash'

import config from "../../../../../config"
import CardImage from "../../../../../models/CardImage"
import { SubModule } from "../../../../../models/SubModule"
import QuizQuestion, { DefaultQuestion, MemoryQuestion, SelectAnswerQuestion, WordGuessQuestion } from "../../../../../models/QuizQuestion"
import TokenHelper from "../../../../../auth/TokenHelper"
import AdminContentService from "../../../../../services/admin/adminContentService"
import Module from "../../../../../models/Module"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ImageSvg from '../../../../../images/image.svg'
import QuizSvg from '../../../../../images/quiz.svg'
import EditDefaultQuestionComponent from "./EditDefaultQuestionComponent"
import EditSelectAnswerQuestionComponent from "./EditSelectAnswerQuestionComponent"
import EditMemoryQuestionComponent from "./EditMemoryQuestionComponent"
import EditWordGuessQuestionComponent from "./EditWordGuessQuestionComponent"

interface Props {
    subModule: SubModule,
    card: QuizQuestion,
    editCard: (card: QuizQuestion) => void
    exit: () => void
}

interface State {
    error: any | undefined;
    isPickingFile: boolean;
}

const INITIAL_STATE: State = {
    error: undefined,
    isPickingFile: false
};

export default class EditQuizComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = { ...INITIAL_STATE }

    }

    uploadCardImage = async (imageBlob: Blob, imageUploadCallback: (imageUrl: string | undefined) => void) => {
        let tokenHelper = new TokenHelper()
        let subModule = this.props.subModule
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let formData = new FormData()
            formData.append('file', imageBlob)
            let adminContentService = new AdminContentService()
            let cardImage = await adminContentService.uploadCardImage(token, config.endpoint, formData, subModule.moduleId, subModule.id)
            imageUploadCallback(cardImage.imageUrl)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.uploadCardImage(imageBlob, imageUploadCallback)
            } else {
                imageUploadCallback(undefined)
                this.setState({ error })
            }
        }
    }

    getCardImages = async (callback: (modules: Array<Module>, cardImages: Array<CardImage>) => void) => {
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let adminContentService = new AdminContentService()
            let { modules, cardImages } = await adminContentService.getCardImages(token, config.endpoint)
            callback(modules, cardImages)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.getCardImages(callback)
            } else {
                this.setState({ error })
            }
        }
    }

    onExit = () => {
        if (this.state.isPickingFile) {
            this.setState({ isPickingFile: false })
        } else {
            this.props.exit()
        }
    }

    renderError(error: any) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                    {error.toString()}
                </Alert>
            </Col>
        </Row>
        );
    }

    renderFormForCard(card: QuizQuestion, isPickingFile: boolean) {
        if (card instanceof DefaultQuestion) return (<EditDefaultQuestionComponent card={card as DefaultQuestion} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} editCard={this.props.editCard} onError={(error: any) => this.setState({ error })} uploadCardImage={this.uploadCardImage} getCardImages={this.getCardImages} />)
        else if (card instanceof WordGuessQuestion) return (<EditWordGuessQuestionComponent card={card as WordGuessQuestion} editCard={this.props.editCard} onError={(error: any) => this.setState({ error })} />)
        else if (card instanceof MemoryQuestion) return (<EditMemoryQuestionComponent card={card as MemoryQuestion} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} editCard={this.props.editCard} onError={(error: any) => this.setState({ error })} uploadCardImage={this.uploadCardImage} getCardImages={this.getCardImages} />)
        else if (card instanceof SelectAnswerQuestion) return (<EditSelectAnswerQuestionComponent card={card as SelectAnswerQuestion} isPickingFile={isPickingFile} updateIsPickingFile={(isPickingFile: boolean) => this.setState({ isPickingFile })} editCard={this.props.editCard} onError={(error: any) => this.setState({ error })} uploadCardImage={this.uploadCardImage} getCardImages={this.getCardImages} />)
        else return (<div></div>)
    }

    render() {
        let { error, isPickingFile } = this.state
        return (<Fragment>
            <Row className="pt-2 pb-2" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', minHeight: '4em' }}>
                        <Button color='none' outline onClick={() => this.onExit()}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                        <img alt='foto da empresa' style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} src={isPickingFile ? ImageSvg : QuizSvg} />
                        <div className='d-flex flex-column ml-2'>
                            <div style={{ color: '#1d3256', verticalAlign: 'middle' }}><b>{isPickingFile ? 'Selecionar Imagem' : 'Editar Questão'}</b></div>
                            <div style={{ minWidth: 0, display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', verticalAlign: 'middle' }}>{this.props.subModule.title}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ overflow: 'auto' }}>
                <Col>
                    <Container fluid>
                        {error && this.renderError(error)}
                        {this.renderFormForCard(this.props.card, isPickingFile)}
                    </Container>
                </Col>
            </Row>
        </Fragment>)
    }
}