import React, { Component, useEffect } from 'react';

import Company from '../../models/Company'
import { useNavigate } from 'react-router-dom'
import EnterService from '../../services/enterService'
import config from '../../config'
import LoadingScreen from '../loading';
import { Alert, Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import * as ROUTES from '../../constants/routes'
import { useUser } from '../../hooks/useUser';

interface State {
    isLoading: boolean
    error: any | undefined
    companies: Array<Company> | undefined
};

const INITIAL_STATE: State = {
    isLoading: true,
    error: null,
    companies: undefined
};

const SelectCompanyScreen = () => {

    const navigate = useNavigate()
    const { user, company, noUser, noCompany } = useUser()
    const [state, setState] = React.useState<State>({ ...INITIAL_STATE })

    useEffect(() => {
        loadCompanies()
    }, [])


    const loadCompanies = async () => {
        try {
            setState({ ...state, isLoading: true, error: undefined })
            let enterService = new EnterService()
            let companies = await enterService.getCompanies(config.endpoint)
            setState({ ...state, isLoading: false, companies })
        } catch (error) {
            setState({ ...state, isLoading: false, error: error.toString() })
        }
    }

    const renderError = (error: any) => {
        return (
            <Alert color="danger" toggle={() => setState({ ...state, error: undefined })}>
                {error}
            </Alert>
        );
    }

    const renderCompanies = (companies: Array<Company>) => {
        return (<Container fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                        <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'center' }}>Por favor, selecione uma empresa:</div>
                        </div>
                    </div>
                </Col>
            </Row>
            {companies.map(company => {
                return (<Row key={company.id}>
                    <Col className="d-flex flex-column justify-content-center" style={{ marginTop: 10, backgroundColor: 'white' }} md={{ size: 6, offset: 3 }}>
                        <div className="d-flex justify-content-center">
                            <img alt='company logo' style={{ height: '10vh', marginBottom: 5, borderRadius: 5 }} src={company.pic} />
                        </div>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontStyle: 'bold', verticalAlign: 'middle', textAlign: 'center' }}>{company.name}</div>
                        <div className="d-flex justify-content-center align-items-center">
                            <Button style={{ background: company.mainColor || '#000', margin: 10 }} block onClick={() => { navigate(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${company.id}`) }}><FontAwesomeIcon icon={faCheck} /> Selecionar</Button>
                        </div>
                    </Col>
                </Row>)
            })}
        </Container>)
    }

    let { error, isLoading, companies } = state
    if (isLoading) return <LoadingScreen />
    return (<div>
        {error && renderError(error)}
        {companies && renderCompanies(companies)}
    </div>)

}

export default SelectCompanyScreen;
