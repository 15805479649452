import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import DeckCard, { QuestionCard, WordSearchCard, InfoCard, DescriptionCard, DeckCardTypes, GifCard, MemoryCard, OrderedListCard, SelectAnswerCard, ImageErrorsCard } from '../../models/DeckCards';
import WordSearchComponent from '../../deck/WordSearchComponent'
import { Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { faChevronCircleLeft, faChevronCircleRight, faCheckCircle, faSortAlphaUp, faBrain, faList, faArrowLeft, faQuestionCircle, faImage, faPlay } from '@fortawesome/free-solid-svg-icons';
import { QuestionAnswer, Question } from '../../models/Question'
import AnswerComponent from './AnswerComponent'
import { DeckCardReport, QuestionCardReport, WordSearchCardReport, MemoryCardReport, OrderedListCardReport, SelectAnswerCardReport, ImageErrorsCardReport } from '../../models/DeckCardsReport';
import './style.css'
import MemoryGameComponent from '../../deck/MemoryGameComponent';
import ImageErrorsComponent from '../../deck/ImageErrorsComponent';
import OrderedListComponent from '../../deck/OrderedListComponent';
import RenderSelectAnswerCard from '../../deck/RenderSelectAnswerCard';
import { MEDIUM_HEIGHT, SMALL_HEIGHT } from '../../constants/screens'
import ProgressBar from '../../components/ProgressBar'
import DeckBackground from '../../images/gamebackground.jpg'
import Company from '../../models/Company';
import ShowGif from './ShowGif';
import ExternalResourceComponent from '../../deck/ExternalResourceComponent';


const imageHeight = 160

interface State {
    isNavBarMenuOpen: boolean,
    currentIndex: number,
    questions: Array<Question>,
    isCardFullScreen: boolean,
    error: string | undefined,
    height: number,
    width: number,
    showExitModal: boolean,
    showHelpModal: boolean,
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false,
    currentIndex: 0,
    questions: [],
    isCardFullScreen: false,
    error: undefined,
    height: 0,
    width: 0,
    showExitModal: false,
    showHelpModal: false,
}

interface Props { company: Company, deckCards: Array<DeckCard>, subModuleId: string, onExit: () => void, onFinish: (startTime: number, endTime: number, deckCardsReport: Array<DeckCardReport<DeckCard>>) => void }

export default class DeckCardsScreen extends Component<Props, State> {

    deckCardsReport: Array<DeckCardReport<DeckCard>>
    startTime: number
    endTime: number | undefined
    speechUtteranceChunkerCancel = false

    constructor(props: Props) {
        super(props)
        this.startTime = (new Date()).getTime()
        this.deckCardsReport = []
        this.state = { ...INITIAL_STATE, questions: this.props.deckCards.filter(card => card.type === DeckCardTypes.Question).map(question => { return new Question(question.id, QuestionAnswer.NotAnswered) }) }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ height: window.innerHeight, width: window.innerWidth });
    }

    getCardQuestion(card: DeckCard) {
        let filter = this.state.questions.filter(question => { return question.id === card.id })
        return filter[0]
    }

    addCardReport(card: DeckCard) {
        if (this.deckCardsReport.filter(cardReport => cardReport.card.id === card.id).length > 0) return
        if (card.type === DeckCardTypes.Question) {
            let cardReport = new QuestionCardReport(card as QuestionCard)
            cardReport.addEnterTime()
            this.deckCardsReport.push(cardReport)
        } else if (card.type === DeckCardTypes.WordSearch) {
            let cardReport = new WordSearchCardReport(card as WordSearchCard)
            cardReport.addEnterTime()
            this.deckCardsReport.push(cardReport)
        } else if (card.type === DeckCardTypes.Memory) {
            let cardReport = new MemoryCardReport(card as MemoryCard)
            cardReport.addEnterTime()
            cardReport.allImagesFound = false
            this.deckCardsReport.push(cardReport)
        } else if (card.type === DeckCardTypes.OrderedList) {
            let cardReport = new OrderedListCardReport(card as OrderedListCard)
            cardReport.addEnterTime()
            cardReport.listOrderedCorrect = false
            this.deckCardsReport.push(cardReport)
        } else if (card.type === DeckCardTypes.SelectAnswer) {
            let cardReport = new SelectAnswerCardReport(card as SelectAnswerCard)
            cardReport.addEnterTime()
            this.deckCardsReport.push(cardReport)
        } else if (card.type === DeckCardTypes.ImageErrors) {
            let cardReport = new ImageErrorsCardReport(card as ImageErrorsCard)
            cardReport.addEnterTime()
            cardReport.allErrorsFound = false
            this.deckCardsReport.push(cardReport)
        } else {
            let cardReport = new DeckCardReport(card)
            cardReport.addEnterTime()
            this.deckCardsReport.push(cardReport)
        }
    }

    addCardReportExitTime(card: DeckCard) {
        let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id == card.id))
        if (cardReport!.exitTimes.length < cardReport!.enterTimes.length) {
            cardReport!.addExitTime()
        }
    }

    onAnswerSelected = (answerNumber: number) => {
        let questions = this.state.questions
        let currentCard = this.props.deckCards[this.state.currentIndex] as QuestionCard
        let currentQuestion = _.head(questions.filter(question => { return question.id === currentCard.id }))
        if (currentQuestion) {
            if (currentQuestion.answer !== QuestionAnswer.NotAnswered) return
            currentQuestion.selectedAnswer = answerNumber
            let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === currentCard.id))
            let questionCardReport = cardReport as QuestionCardReport
            questionCardReport.answerTime = (new Date()).getTime()
            if (answerNumber === currentCard.correctAnswer) {
                currentQuestion.answer = QuestionAnswer.CorrectAnswer
                questionCardReport.questionAnswer = QuestionAnswer.CorrectAnswer
            } else {
                currentQuestion.answer = QuestionAnswer.WrongAnswer
                questionCardReport.questionAnswer = QuestionAnswer.WrongAnswer
            }
            this.setState({ questions: [...this.state.questions] })
        }
    }

    onCheckAnswer = (report: SelectAnswerCardReport, selectedAnswers: Array<number>) => {
        report.selectedAnswers = selectedAnswers
        report.answerTime = (new Date()).getTime()
        if (_.isEqual(report.card.correctAnswers.sort(), selectedAnswers.sort())) {
            report.questionAnswer = QuestionAnswer.CorrectAnswer
        } else {
            report.questionAnswer = QuestionAnswer.WrongAnswer
        }
        // tem que chamar o render de novo pra mostrar o novo estado da aplicação
        // algumas pessoas dizem que o forceRender vai contra os principios do React
        // eu nao sei se vale a pena colocar os reports no state porque a maioria deles
        // nao exige mudanca na tela quando sao atualizados e ficaria duplicado colocar
        // os reports que exigem update na tela no estado e tambem no array de reports
        this.forceUpdate()
    }

    onWordFound = (wordSearchCard: WordSearchCard) => {

        let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === wordSearchCard.id))
        let wordSearchCardReport = cardReport as WordSearchCardReport
        wordSearchCardReport.foundTime = (new Date()).getTime()
        wordSearchCardReport.wordFound = true
        wordSearchCardReport.addExitTime()

        if (this.state.currentIndex < this.props.deckCards.length - 1) {
            let currentIndex = this.state.currentIndex
            this.setState({ currentIndex: currentIndex + 1, isCardFullScreen: false })
        } else {
            this.setState({ isCardFullScreen: false })
        }
    }

    onMemoryGameFinished = (memoryCard: MemoryCard, attempts: number) => {

        let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === memoryCard.id))
        let memoryCardReport = cardReport as MemoryCardReport
        memoryCardReport.finishedTime = (new Date()).getTime()
        memoryCardReport.allImagesFound = true
        memoryCardReport.attempts = attempts
        memoryCardReport.addExitTime()

        this.addCardReportExitTime(memoryCard)
        if (this.state.currentIndex < this.props.deckCards.length - 1) {
            let currentIndex = this.state.currentIndex
            this.setState({ currentIndex: currentIndex + 1, isCardFullScreen: false })
        } else {
            this.setState({ isCardFullScreen: false })
        }
    }

    onImageErrorsFinished = (imageErrorsCard: ImageErrorsCard, attempts: number) => {

        let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === imageErrorsCard.id))
        let imageErrorsCardReport = cardReport as ImageErrorsCardReport
        imageErrorsCardReport.finishedTime = (new Date()).getTime()
        imageErrorsCardReport.allErrorsFound = true
        imageErrorsCardReport.attempts = attempts
        imageErrorsCardReport.addExitTime()

        this.addCardReportExitTime(imageErrorsCard)
        if (this.state.currentIndex < this.props.deckCards.length - 1) {
            let currentIndex = this.state.currentIndex
            this.setState({ currentIndex: currentIndex + 1, isCardFullScreen: false })
        } else {
            this.setState({ isCardFullScreen: false })
        }
    }

    onOrderedListFinished = (orderedListCard: OrderedListCard) => {
        let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === orderedListCard.id))
        let orderedListCardReport = cardReport as OrderedListCardReport
        orderedListCardReport.finishedTime = (new Date()).getTime()
        orderedListCardReport.listOrderedCorrect = true
        orderedListCardReport.addExitTime()

        this.addCardReportExitTime(orderedListCard)
        if (this.state.currentIndex < this.props.deckCards.length - 1) {
            let currentIndex = this.state.currentIndex
            this.setState({ currentIndex: currentIndex + 1, isCardFullScreen: false })
        } else {
            this.setState({ isCardFullScreen: false })
        }
    }

    onNextClick = (card: DeckCard) => {
        let currentIndex = this.state.currentIndex
        this.addCardReportExitTime(card)

        if (this.state.currentIndex < this.props.deckCards.length - 1) {
            this.setState({ currentIndex: currentIndex + 1 })
        } else {
            if (!this.endTime) this.endTime = (new Date()).getTime()
            this.props.onFinish(this.startTime, this.endTime!, this.deckCardsReport)
        }
    }

    onPreviousClick = (card: DeckCard) => {
        if (this.state.currentIndex === 0) return
        this.addCardReportExitTime(card)

        let currentIndex = this.state.currentIndex
        this.setState({ currentIndex: currentIndex - 1 })
    }

    exitFullScreen = (card: DeckCard) => {
        this.addCardReportExitTime(card)
        this.setState({ isCardFullScreen: false })
    }

    shouldBlockNavigation = () => {
        let currentCard = this.props.deckCards[this.state.currentIndex]
        if (currentCard.type === DeckCardTypes.Question) {
            let cardQuestion = this.getCardQuestion(currentCard)
            return cardQuestion.answer === QuestionAnswer.NotAnswered
        } else if (currentCard.type === DeckCardTypes.SelectAnswer) {
            let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === currentCard.id))
            if (!cardReport) {
                // ainda não renderizou o card, então não tem card report. ele vai ser inicializado com NotAnswered
                // portanto tem que bloquear a navegação pois o usuario ainda nao escolheu as respostas
                return true
            } else {
                let selectAnswerCardReport = cardReport as SelectAnswerCardReport
                return selectAnswerCardReport.questionAnswer === QuestionAnswer.NotAnswered
            }
        } else {
            return false
        }
    }

    renderQuestionCard(card: QuestionCard, screenHeight: number, screenWidth: number) {
        let question = this.getCardQuestion(card)
        let correctAnswer = card.correctAnswer
        let answersCount = card.answer2 ? (card.answer3 ? 4 : 3) : 2
        var titleMargin = 5
        var titleFontSize = '1em'
        var screenHeightSize = 'small'
        // se for celular com tamanho medio
        if (screenHeight > SMALL_HEIGHT && screenHeight <= MEDIUM_HEIGHT) {
            screenHeightSize = 'medium'
            if (answersCount < 4) {
                titleMargin = 10
            }
        }
        // se for tablet ou computador
        else if (screenHeight > MEDIUM_HEIGHT) {
            titleMargin = 20
            screenHeightSize = 'big'
            if (answersCount < 4) {
                titleFontSize = '1.3em'
            } else {
                titleFontSize = '1.2em'
            }
        }

        let showFeedback = question.answer === QuestionAnswer.WrongAnswer || question.answer === QuestionAnswer.Timeout
        let showTextFeedback = showFeedback && card.feedbackText
        let showImageFeedback = showFeedback && card.feedbackImageUrl
        let hideNotSelectedAnswers = showTextFeedback || showImageFeedback

        let showAnswer0 = hideNotSelectedAnswers ? (question.selectedAnswer === 0 || card.correctAnswer === 0) : true
        let showAnswer1 = hideNotSelectedAnswers ? (question.selectedAnswer === 1 || card.correctAnswer === 1) : true
        let showAnswer2 = card.answer2 && (hideNotSelectedAnswers ? (question.selectedAnswer === 2 || card.correctAnswer === 2) : true)
        let showAnswer3 = card.answer3 && (hideNotSelectedAnswers ? (question.selectedAnswer === 3 || card.correctAnswer === 3) : true)

        let isDesktop = screenWidth >= 768

        var questionColor = 'black' //this.props.companyColor || '#353f45'
        // se for muito vermelho muda pra preto por questoes de design
        /*let rgb = parseInt(questionColor.substring(1), 16)
        let r = (rgb >> 16) & 0xff;  // extract red
        let g = (rgb >> 8) & 0xff;  // extract green
        let b = (rgb >> 0) & 0xff;

        let isTooRed = r > 70 && r > 2*g && r > 2*b
        let isTooBlue = b > 70 && b > 2*g && b > 2*r

        questionColor = (isTooRed || isTooBlue) ? 'black' : questionColor*/

        let questionImageHeight = screenHeightSize === 'big' ? 200 : (screenHeightSize === 'medium' ? 160 : 120)

        return <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'stretch', flexDirection: 'column', minHeight: '90%', marginTop: 15, marginRight: isDesktop ? 0 : 5, marginLeft: isDesktop ? 0 : 5 }}>
            <div style={{ margin: titleMargin, textAlign: 'center', color: questionColor, fontWeight: "bold", fontSize: titleFontSize }}>{card.question}</div>
            {card.questionImageUrl && <div className="d-flex flex-column align-items-center justify-content-center"><Zoom><img src={card.questionImageUrl} alt='imagem da pergunta' style={{ height: questionImageHeight, objectFit: 'contain', marginBottom: 5, marginTop: 5 }} /></Zoom></div>}
            {showAnswer0 && <AnswerComponent removeBorder={true} screenHeight={screenHeightSize} answerText={card.answer0} img={card.imgAnswer0} selectedAnswer={question.selectedAnswer} answerNumber={0}
                questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />}
            {showAnswer1 && <AnswerComponent removeBorder={true} screenHeight={screenHeightSize} answerText={card.answer1} img={card.imgAnswer1} selectedAnswer={question.selectedAnswer} answerNumber={1}
                questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />}
            {showAnswer2 && <AnswerComponent removeBorder={true} screenHeight={screenHeightSize} answerText={card.answer2!} img={card.imgAnswer2} selectedAnswer={question.selectedAnswer} answerNumber={2}
                questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />}
            {showAnswer3 && <AnswerComponent removeBorder={true} screenHeight={screenHeightSize} answerText={card.answer3!} img={card.imgAnswer3} selectedAnswer={question.selectedAnswer} answerNumber={3}
                questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />}
            {showTextFeedback && <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{card.feedbackText}</div>}
            {showImageFeedback && <div className="d-flex flex-column align-items-center justify-content-center"><Zoom><img src={card.feedbackImageUrl} alt='imagem do conteúdo' style={{ height: imageHeight, objectFit: 'contain', marginBottom: 10, marginTop: 15 }} /></Zoom></div>}
        </div>

    }

    renderSelectAnswerCard(card: SelectAnswerCard, report: SelectAnswerCardReport, screenHeight: number) {
        var screenHeightSize = 'small'
        // se for celular com tamanho medio
        if (screenHeight > SMALL_HEIGHT && screenHeight <= MEDIUM_HEIGHT) {
            screenHeightSize = 'medium'
        }
        // se for tablet ou computador
        else if (screenHeight > MEDIUM_HEIGHT) {
            screenHeightSize = 'big'
        }

        var questionColor = this.props.company.mainColor || '#353f45'
        // se for muito vermelho muda pra preto por questoes de design
        let rgb = parseInt(questionColor.substring(1), 16)
        let r = (rgb >> 16) & 0xff;  // extract red
        let g = (rgb >> 8) & 0xff;  // extract green
        let b = (rgb >> 0) & 0xff;

        let isTooRed = r > 70 && r > 2 * g && r > 2 * b
        let isTooBlue = b > 70 && b > 2 * g && b > 2 * r

        questionColor = (isTooRed || isTooBlue) ? 'black' : questionColor

        return <RenderSelectAnswerCard key={card.id} companyColor={questionColor} screenHeight={screenHeightSize} selectAnswerCardReport={report} onCheckAnswer={this.onCheckAnswer} />

    }

    renderWordSearchCard(title: string) {
        return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
            <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 15 }}>{title}</div>
            <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 12 }}><i>Encontre a palavra certa clicando nas letras.</i></div>
            <Button style={{ background: this.props.company.mainColor || '#000', margin: 10, borderStyle: 'none', minWidth: 200 }} onClick={() => { this.setState({ isCardFullScreen: true }) }}><FontAwesomeIcon icon={faSortAlphaUp} /> Jogar</Button>
        </div>)
    }

    renderMemoryCard() {
        return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
            <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 20 }}>Jogo da Memória</div>
            <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 12 }}>Encontre as cartas com textos e imagens iguais.</div>
            <Button style={{ background: this.props.company.mainColor || '#000', margin: 10, borderStyle: 'none', minWidth: 200 }} onClick={() => { this.setState({ isCardFullScreen: true }) }}><FontAwesomeIcon icon={faBrain} /> Jogar</Button>
        </div>)
    }

    renderImageErrorsCard() {
        return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
            <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 20 }}>Jogo dos Erros</div>
            <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 12 }}>Encontre os erros na imagem.</div>
            <Button style={{ background: this.props.company.mainColor || '#000', margin: 10, borderStyle: 'none', minWidth: 200 }} onClick={() => { this.setState({ isCardFullScreen: true }) }}><FontAwesomeIcon icon={faImage} /> Jogar</Button>
        </div>)
    }

    renderOrderedList() {
        return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
            <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 20 }}>Lista de Etapas</div>
            <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 12 }}>Coloque as etapas na ordem correta.</div>
            <Button style={{ background: this.props.company.mainColor || '#000', margin: 10, borderStyle: 'none', minWidth: 200 }} onClick={() => { this.setState({ isCardFullScreen: true }) }}><FontAwesomeIcon icon={faList} /> Jogar</Button>
        </div>)
    }

    renderCard = (card: DeckCard) => {
        let boxShadow = '2px 4px 4px 2px rgba(0,0,0,0.2)'
        let borderRadius = 0
        let currentCard = card.pos === this.state.currentIndex
        let marginBottom = 0
        let marginTop = 5
        // TODO: não da pra deixar isso aqui no render mais
        if (currentCard) this.addCardReport(card)
        if (card.type === DeckCardTypes.Description) {
            let descriptionCard = card as DescriptionCard
            var imageMaxSize = 300
            // se for celular com tamanho medio
            if (this.state.height > SMALL_HEIGHT && this.state.height <= MEDIUM_HEIGHT) {
                imageMaxSize = 350
            }
            // se for celular bem grande ( height > 720)
            else if (this.state.height > MEDIUM_HEIGHT) {
                imageMaxSize = 450
            }
            return (<div key={card.id} className="d-flex flex-column align-items-center" style={{ marginTop, marginBottom, minHeight: '100%', boxShadow, borderRadius, color: '#353f45', backgroundColor: '#FFFFFFDA', paddingBottom: 10 }}>
                <div style={{ position: 'relative', width: '100%', textAlign: 'center', borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }}>
                    <div className='bg-image' style={{ backgroundImage: `url("${descriptionCard.image}")`, position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }} />
                    <Zoom><img src={descriptionCard.image} alt='imagem do conteúdo' style={{ width: '100%', height: 'auto', maxHeight: imageMaxSize, objectFit: 'contain' }} /></Zoom>
                </div>
                <div style={{ color: '#353f45', marginTop: 10, marginRight: 4, marginLeft: 4, textAlign: 'center', paddingRight: 8, paddingLeft: 8 }}><b>{descriptionCard.title}</b></div>
                {descriptionCard.texts.map((text: string, i: number) => { return (<p key={i.toString()} style={{ textAlign: 'center', color: '#353f45', margin: 5, paddingRight: 8, paddingLeft: 8 }}>{text}</p>) })}
            </div>)
        } else if (card.type === DeckCardTypes.Info) {
            let infoCard = card as InfoCard
            // padrao para celular muito pequeno ( height <= 500 )
            var fontSize = 'medium'
            var imageMaxSize = 300
            // se for celular com tamanho medio
            if (this.state.height > SMALL_HEIGHT && this.state.height <= MEDIUM_HEIGHT) {
                fontSize = infoCard.info.length > 55 ? 'large' : 'x-large'
                imageMaxSize = 350
            }
            // se for celular bem grande ( height > 720)
            else if (this.state.height > MEDIUM_HEIGHT) {
                fontSize = infoCard.info.length > 55 ? 'x-large' : 'xx-large'
                imageMaxSize = 450
            }
            // boxShadow: '0px 0px 1px 2px rgba(60, 99, 198, 0.3)' #1d3256
            // backgroundImage: `url("${infoCard.image}")`


            if (infoCard.info.split('{@}').length > 3 && infoCard.info.includes('externalLink')) {

                let data = infoCard.info.split('{@}')
                let title = data[3]
                return (<div key={card.id} className="d-flex flex-column align-items-center" style={{ marginTop, marginBottom, minHeight: '100%', boxShadow, borderRadius, backgroundColor: '#FFFFFFDA', color: '#353f45' }}>
                    <div style={{ position: 'relative', width: '100%', textAlign: 'center', borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }} onClick={() => this.setState({ isCardFullScreen: true })}>
                        <div className='bg-image' style={{ backgroundImage: `url("${infoCard.image}")`, position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }} />
                        <div style={{ position: 'relative' }}><img src={infoCard.image} alt='imagem do conteúdo' style={{ width: '100%', height: 'auto', maxHeight: imageMaxSize, objectFit: 'contain' }} /></div>
                    </div>
                    <Button style={{ marginTop: 5, paddingTop: 10, paddingBottom: 10, minWidth: 200 }} color="info" onClick={() => this.setState({ isCardFullScreen: true })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faPlay} />Assistir</Button>
                    {infoCard.info.length > 0 && <div onClick={() => this.setState({ isCardFullScreen: true })} style={{ textAlign: 'center', marginRight: 4, marginLeft: 4, fontSize: fontSize, paddingTop: 10, paddingBottom: 5, paddingRight: 8, paddingLeft: 8, color: infoCard.textColor || 'black' }}><b>{title}</b></div>}
                </div>)

            }

            return (<div key={card.id} className="d-flex flex-column align-items-center" style={{ marginTop, marginBottom, minHeight: '100%', boxShadow, borderRadius, backgroundColor: '#FFFFFFDA', color: '#353f45' }}>
                <div style={{ position: 'relative', width: '100%', textAlign: 'center', borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }}>
                    <div className='bg-image' style={{ backgroundImage: `url("${infoCard.image}")`, position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }} />
                    <Zoom><img src={infoCard.image} alt='imagem do conteúdo' style={{ width: '100%', height: 'auto', maxHeight: imageMaxSize, objectFit: 'contain' }} /></Zoom>
                </div>
                {infoCard.info.length > 0 && <p style={{ textAlign: 'center', marginTop: 10, marginRight: 5, marginLeft: 5, fontSize: fontSize, paddingTop: 10, paddingBottom: 10, paddingRight: 8, paddingLeft: 8, color: infoCard.textColor || 'black' }}><b>{infoCard.info}</b></p>}
            </div>)
        } else if (card.type === DeckCardTypes.Gif) {
            let gifCard = card as GifCard
            var fontSize = 'medium'
            let gitText = gifCard.text || ''
            var gifMaxSize = 300
            // se for celular com tamanho medio
            if (this.state.height > MEDIUM_HEIGHT && this.state.height <= MEDIUM_HEIGHT) {
                fontSize = gitText.length > 55 ? 'large' : 'x-large'
                gifMaxSize = 350
            }
            // se for celular bem grande ( height > 720)
            else if (this.state.height > MEDIUM_HEIGHT) {
                fontSize = gitText.length > 55 ? 'x-large' : 'xx-large'
                gifMaxSize = 450
            }
            return (<div key={card.id} className="d-flex flex-column align-items-center" style={{ marginTop, marginBottom, minHeight: '100%', boxShadow, borderRadius, color: '#353f45', backgroundColor: '#FFFFFFDA' }}>
                {/*<ShowGif url={gifCard.gifUrl} gifMaxSize={gifMaxSize} />*/}
                <div style={{ position: 'relative', width: '100%', textAlign: 'center', borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }}>
                    <div className='bg-image' style={{ backgroundImage: `url("${gifCard.gifUrl}")`, position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }} />
                    <div style={{ position: 'relative' }}><ShowGif url={gifCard.gifUrl} gifMaxSize={gifMaxSize} /></div>
                </div>
                <p style={{ textAlign: 'center', marginTop: 10, fontSize, paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10, color: gifCard.textColor || 'black' }}><b>{gifCard.text || ''}</b></p>
            </div>)
        } else if (card.type === DeckCardTypes.WordSearch) {
            let wordSearchCard = card as WordSearchCard
            let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === card.id))
            let wordSearchCardReport = cardReport as WordSearchCardReport
            if (wordSearchCardReport && wordSearchCardReport.wordFound) {
                return (<div key={card.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 20 }}><b>Caça-palavras</b></div>
                    <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 12 }}>{wordSearchCard.title}</div>
                    <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 16 }}>{wordSearchCard.word}</div>
                    <FontAwesomeIcon color='#0f3252' icon={faCheckCircle} size='2x' />
                </div>)
            }

            if (wordSearchCardReport && !wordSearchCardReport.wordFound && wordSearchCardReport.exitTimes.length === 0) this.setState({ isCardFullScreen: true })
            return this.renderWordSearchCard(wordSearchCard.title)
        } else if (card.type === DeckCardTypes.Question) {
            let questionCard = card as QuestionCard
            return this.renderQuestionCard(questionCard, this.state.height, this.state.width)
        } else if (card.type === DeckCardTypes.SelectAnswer) {
            let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === card.id))
            let selectAnswerCardReport = cardReport as SelectAnswerCardReport
            return this.renderSelectAnswerCard(card as SelectAnswerCard, selectAnswerCardReport, this.state.height)
        } else if (card.type === DeckCardTypes.Memory) {
            let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === card.id))
            let memoryCardReport = cardReport as MemoryCardReport
            if (memoryCardReport) {
                // primeira vez q ele ta indo no card de jogo da memoria tem que mostrar ele em tela cheia
                if (!memoryCardReport.allImagesFound && memoryCardReport.exitTimes.length === 0) this.setState({ isCardFullScreen: true })
                else if (memoryCardReport.allImagesFound) {
                    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                        <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 20 }}><b>Jogo da Memória</b></div>
                        <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 12 }}>Parabéns, você encontrou todos os pares.</div>
                        <FontAwesomeIcon color='#0f3252' icon={faCheckCircle} size='2x' />
                    </div>)
                }
            }
            return this.renderMemoryCard()
        } else if (card.type === DeckCardTypes.ImageErrors) {
            let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === card.id))
            let imageErrorsCardReport = cardReport as ImageErrorsCardReport
            if (imageErrorsCardReport) {
                // primeira vez q ele ta indo no card de jogo da memoria tem que mostrar ele em tela cheia
                if (!imageErrorsCardReport.allErrorsFound && imageErrorsCardReport.exitTimes.length === 0) this.setState({ isCardFullScreen: true })
                else if (imageErrorsCardReport.allErrorsFound) {
                    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                        <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 20 }}><b>Jogo dos Erros</b></div>
                        <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 12 }}>Parabéns, você encontrou todos os erros.</div>
                        <FontAwesomeIcon color='#0f3252' icon={faCheckCircle} size='2x' />
                    </div>)
                }
            }
            return this.renderImageErrorsCard()
        } else if (card.type === DeckCardTypes.OrderedList) {
            let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id === card.id))
            let orderedListCardReport = cardReport as OrderedListCardReport
            let orderedListCard = card as OrderedListCard
            if (orderedListCardReport && orderedListCardReport.listOrderedCorrect) {
                return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 20 }}><b>Lista Ordenada</b></div>
                    <div style={{ margin: 1, textAlign: 'center', color: '#353f45', fontSize: 14, marginBottom: 5 }}>{orderedListCard.title}</div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {orderedListCard.list.sort((a, b) => a.position - b.position).map(({ position, text }) => {
                            return <div style={{ margin: 5, boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.2)', padding: 10, borderRadius: 5, textAlign: 'center', color: '#353f45', backgroundColor: 'white', fontSize: 12 }}><b>{position}</b> - {text}</div>
                        })}
                    </div>
                    <FontAwesomeIcon color='#0f3252' icon={faCheckCircle} size='2x' />
                </div>)
            } else {
                if (orderedListCardReport && !orderedListCardReport.listOrderedCorrect && orderedListCardReport.exitTimes.length === 0) this.setState({ isCardFullScreen: true })
                return this.renderOrderedList()
            }
        } else {
            return (<div>
                <p>Desconhecido</p>
            </div>)
        }
    }

    renderHeader() {
        // background: '#f1efe4'
        // background: 'white', boxShadow: '2px 1px 0px 1px rgba(0,0,0,0.2)'
        return (<Row className='no-gutters' style={{ backgroundColor: '#FFFFFFDA' }}>
            <Col className='d-flex justify-content-center align-items-center' style={{ paddingTop: 10, paddingBottom: 10, minHeight: '4em' }} md={{ size: 8, offset: 2 }}>
                <Button color='none' outline onClick={() => { this.setState({ showExitModal: true }) }}><FontAwesomeIcon style={{ verticalAlign: 'middle' }} color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                <div className='d-flex flex-column align-items-center' style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                    <div style={{ color: '#353f45', fontSize: 'small', marginBottom: 5 }}><b>{this.state.currentIndex + 1} DE {this.props.deckCards.length}</b></div>
                    <ProgressBar height={5} bgColor={'#353f45'} completed={(this.state.currentIndex + 1) / this.props.deckCards.length * 100} />
                </div>
                <Button color='none' outline onClick={() => { this.setState({ showHelpModal: true }) }}><FontAwesomeIcon style={{ verticalAlign: 'middle' }} color={this.props.company.mainColor || '#353f45'} icon={faQuestionCircle} size='2x' /></Button>
            </Col>
        </Row>)
    }

    renderNavigationButtons(shouldBlockNavigation: boolean, currentCard: DeckCard, width: number) {

        let isLastCard = this.state.currentIndex === this.props.deckCards.length - 1

        let color = this.props.company.secondaryColor || this.props.company.mainColor || '#221f14'
        let sideMargins = width >= 768 ? 15 : 0

        let backgroundColor = this.props.company.mainColor || '#d9cdba'
        let complementColor = this.props.company.secondaryColor || backgroundColor

        return <Row className="no-gutters fixed-bottom">
            <Col style={{ paddingLeft: sideMargins, paddingRight: sideMargins }} md={{ size: 8, offset: 2 }}>
                <div className='d-flex justify-content-space-between' style={{ paddingTop: 10, paddingBottom: 10, borderTopRightRadius: 10, borderTopLeftRadius: 10, width: '100%', background: complementColor + 'aa' }}>
                    <Button outline hidden={shouldBlockNavigation} disabled={this.state.currentIndex === 0} style={{ marginLeft: 15, color, borderColor: 'white', backgroundColor: '#FFFFFFDA', boxShadow: '2px 4px 4px 2px rgba(0,0,0,0.2)' }} onClick={() => { this.onPreviousClick(currentCard) }}><FontAwesomeIcon icon={faChevronCircleLeft} /> <b>ANTERIOR</b></Button>
                    <div style={{ flex: 1 }} />
                    <Button outline hidden={shouldBlockNavigation} disabled={this.state.currentIndex >= this.props.deckCards.length} style={{ marginRight: 15, color: isLastCard ? '#40b34f' : color, borderColor: isLastCard ? '#40b34f' : 'white', backgroundColor: '#FFFFFFDA', boxShadow: '2px 4px 4px 2px rgba(0,0,0,0.2)' }} onClick={() => { this.onNextClick(currentCard) }}><b>{isLastCard ? 'FINALIZAR' : 'PRÓXIMO'}</b> <FontAwesomeIcon icon={isLastCard ? faCheckCircle : faChevronCircleRight} /></Button>
                </div>
            </Col>
        </Row>
    }

    renderHelpModal(isModalOpen: boolean) {

        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Modal isOpen={isModalOpen} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => this.setState({ showHelpModal: false })}>
                    <ModalHeader toggle={() => this.setState({ showHelpModal: false })}><FontAwesomeIcon icon={faQuestionCircle} /> AJUDA</ModalHeader>
                    <ModalBody>
                        <img
                            alt=""
                            src={require('../../images/navigation_buttons.png')}
                            width="100%"
                            className="d-inline-block align-top"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <div style={{ color: '#1d3256', fontSize: 16 }}>Utilize os botões <b>Próximo</b> e <b>Anterior</b> na parte inferior desta tela para navegar.</div>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    renderDeck(currentCard: DeckCard) {
        return (<Row className="no-gutters" style={{ flex: 1, paddingBottom: 75, overflow: 'auto' }}>
            <Col md={{ size: 8, offset: 2 }}>
                {this.renderCard(currentCard)}
            </Col>
        </Row>)
    }

    renderExitMessage() {
        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Modal isOpen={true} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => this.setState({ showExitModal: false })}>
                    <ModalBody>Tem certeza que quer desistir do jogo? Ao desistir você não pontuará.</ModalBody>
                    <ModalFooter className='d-flex justify-content-center align-items-center' >
                        <Button color="secondary" onClick={() => this.setState({ showExitModal: false })}>Não, continuar jogando</Button>
                        <Button color="danger" onClick={() => this.props.onExit()}>Sim, sair do jogo</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    render() {

        let { isCardFullScreen, showExitModal, showHelpModal, height, width } = this.state

        let currentCard = this.props.deckCards[this.state.currentIndex]

        let renderBody = () => {
            if (isCardFullScreen) {
                if (currentCard.type === DeckCardTypes.WordSearch) {

                    // default tela bem pequena
                    var rows = 6

                    if (height > SMALL_HEIGHT && height <= MEDIUM_HEIGHT) rows = 8

                    // se for celular bem grande ( height > 720)
                    else if (this.state.height > MEDIUM_HEIGHT) rows = 10

                    let wordSearchCard = currentCard as WordSearchCard
                    return <WordSearchComponent rows={rows} columns={10} word={wordSearchCard.word} title={wordSearchCard.title} onWordFound={() => this.onWordFound(wordSearchCard)} onExit={() => this.exitFullScreen(wordSearchCard)} />
                } else if (currentCard.type === DeckCardTypes.Memory) {
                    var heigthStr = 'small'
                    if (height > SMALL_HEIGHT && height <= MEDIUM_HEIGHT) {
                        heigthStr = 'medium'
                    } else if (this.state.height > MEDIUM_HEIGHT) heigthStr = 'big'
                    let memoryCard = currentCard as MemoryCard
                    return <MemoryGameComponent title={memoryCard.title} images={memoryCard.images} screenSize={heigthStr} onMemoryGameFinished={(attempts) => this.onMemoryGameFinished(memoryCard, attempts)} onExit={() => this.exitFullScreen(memoryCard)} showExit={true} noGutters={true} />
                } else if (currentCard.type === DeckCardTypes.OrderedList) {
                    let orderedListCard = currentCard as OrderedListCard
                    return <OrderedListComponent title={orderedListCard.title} list={orderedListCard.list} onOrderedListFinished={() => this.onOrderedListFinished(orderedListCard)} onExit={() => this.exitFullScreen(orderedListCard)} />
                } else if (currentCard.type === DeckCardTypes.ImageErrors) {
                    let imageErrorsCard = currentCard as ImageErrorsCard
                    return <ImageErrorsComponent title={imageErrorsCard.title} errors={imageErrorsCard.errors} imageUrl={imageErrorsCard.imageUrl} onImageErrorsFinished={(attempts) => this.onImageErrorsFinished(imageErrorsCard, attempts)} onExit={() => this.exitFullScreen(imageErrorsCard)} showExit={true} />
                } else if (currentCard.type === DeckCardTypes.Info) {
                    let infoCard = currentCard as InfoCard
                    let data = infoCard.info.split('{@}')
                    let url = data[1]
                    let title = data[3]
                    return <ExternalResourceComponent title={title} url={url} onExit={() => this.exitFullScreen(infoCard)} />
                }
            } else {
                return <Fragment>
                    {this.renderHeader()}
                    {this.renderHelpModal(showHelpModal)}
                    {showExitModal && this.renderExitMessage()}
                    {this.renderDeck(currentCard)}
                </Fragment>
            }
        }

        let backgroundImage = this.props.company.backgroundImages && this.props.company.backgroundImages.length > 0 ? this.props.company.backgroundImages[0] : DeckBackground
        let backgroundPosition = this.props.company.backgroundPosition
        let shouldBlockNavigation = this.shouldBlockNavigation()

        return (<div className="d-flex flex-column relative" style={{ overflow: 'hidden', background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', backgroundPosition, height, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
            {renderBody()}
            {!isCardFullScreen && this.renderNavigationButtons(shouldBlockNavigation, currentCard, width)}
        </div>)
    }

}