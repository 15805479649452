/**
 * By Ken Fyrstenberg Nilsen
 *
 * drawImageProp(context, image [, x, y, width, height [,offsetX, offsetY]])
 *
 * If image and context are only arguments rectangle will equal canvas
*/

// https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage

export function getImageProp(img: HTMLImageElement, canvaWidth: number, canvaHeight: number, offsetX: number, offsetY: number) {

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.width,
        ih = img.height,
        r = Math.min(canvaWidth / iw, canvaHeight / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, newImageWidth, newImageHeight, ar = 1;

    // decide which gap to fill    
    if (nw < canvaWidth) ar = canvaWidth / nw;                             
    if (Math.abs(ar - 1) < 1e-14 && nh < canvaHeight) ar = canvaHeight / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    newImageWidth = iw / (nw / canvaWidth);
    newImageHeight = ih / (nh / canvaHeight);

    cx = (iw - newImageWidth) * offsetX;
    cy = (ih - newImageHeight) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (newImageWidth > iw) newImageWidth = iw;
    if (newImageHeight > ih) newImageHeight = ih;

    return { cx, cy, newImageWidth, newImageHeight };
}