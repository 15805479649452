import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { ChartOptions, Chart as ChartJS, registerables } from 'chart.js';

import User from '../../../models/User';
import Module from '../../../models/Module';
import { SubModule } from '../../../models/SubModule';
import { MonthUserCompanyScore } from '../../../models/Scores';
import { getUsersWhoFinishedAllModules, getCumulativeUsersWhoPlayedInMonth, getCumulativeUsersWhoFinishedInMonth, getUsersWhoPlayedAnySubModule, getUsersWhoPlayedInMonth } from './ProgressHelper'
import MonthReportDownloadComponent from './UsageReport/MonthReportDownloadComponent';
import { ReportConfig, SignUpConfig } from '../../../models/CompanyConfig';
import { UserFeedbackComponent } from './UserFeedbackComponent';

ChartJS.register(...registerables);

interface Props {
    reportConfig: ReportConfig | undefined
    signUpConfig: SignUpConfig
    users: Array<User>;
    monthUsersCompanyScore: Array<MonthUserCompanyScore>;
    modules: Array<Module>;
    subModules: Array<SubModule>;
    year: number;
}

export default class ActivitySummaryComponent extends Component<Props> {

    getPieData(number1: number, number2: number, labelOne: string, labelTwo: string, color1: string, color2: string) {
        return {
            labels: [
                labelOne,
                labelTwo
            ],
            datasets: [{
                data: [number1, number2],
                backgroundColor: [
                    color1,
                    color2,
                ],
                hoverBackgroundColor: [
                    color1,
                    color2,
                ]
            }]
        }
    }

    getBarData(values: Array<number>) {
        return {
            labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            datasets: [
                {
                    label: 'Usuários que jogaram',
                    data: values,
                    borderWidth: 1,
                    backgroundColor: 'rgba(75, 192, 192, 0.4)'
                }
            ]
        }
    }

    getLineChartData(values1: Array<number>, values2?: Array<number>) {
        return {
            labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            datasets: values2 ? [
                {
                    label: 'Jogaram',
                    data: values1,
                    borderColor: 'rgba(75, 192, 192, 0.4)',
                },
                {
                    label: 'Concluíram o Treinamento',
                    data: values2,
                    borderColor: 'rgba(54, 162, 235, 0.4)',
                }
            ] : [{
                label: 'Jogaram',
                data: values1,
                borderColor: 'rgba(75, 192, 192, 0.4)',
            }]
        }
    }

    render() {
        let { users, monthUsersCompanyScore, modules, subModules } = this.props
        let usersWhoPlayedAnySubModule = getUsersWhoPlayedAnySubModule(users, monthUsersCompanyScore, subModules).length
        let totalUsersNotPlaying = users.filter(user => { return user.active }).length - usersWhoPlayedAnySubModule
        let usersWhoPlayedInMonth = getUsersWhoPlayedInMonth(users, subModules, monthUsersCompanyScore)
        let cumulativeUsersWhoPlayedInMonth = getCumulativeUsersWhoPlayedInMonth(users, subModules, monthUsersCompanyScore, this.props.year)
        let barData = this.getBarData(usersWhoPlayedInMonth)
        let options: ChartOptions = { plugins: { legend: { position: 'top' } } };
        let barOptions: ChartOptions = { indexAxis: 'y', elements: { bar: { borderWidth: 2 } }, responsive: true, plugins: { legend: { display: true }, title: { display: true, text: 'Usuários que jogaram em cada mês' } } }

        if (modules.length === 1) {
            let usersWhoFinishedAllModules = getUsersWhoFinishedAllModules(users, monthUsersCompanyScore, modules, subModules).length
            let totalUsers = users.filter(user => { return user.active }).length - usersWhoFinishedAllModules
            let cumulativeUsersWhoFinishedInMonth = getCumulativeUsersWhoFinishedInMonth(users, subModules, monthUsersCompanyScore, this.props.year)
            let lineDataWithTwoLabels = this.getLineChartData(cumulativeUsersWhoPlayedInMonth, cumulativeUsersWhoFinishedInMonth)
            let lineChartOptions: ChartOptions = { responsive: true, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Usuários acumulados que jogaram e concluíram ao longo do ano' } } }
            return (<Row style={{ overflow: 'auto' }} className="mt-2">
                <MonthReportDownloadComponent users={users} monthUsersCompanyScore={monthUsersCompanyScore} module={modules[0]} subModules={subModules.filter(subModules => { return subModules.moduleId === modules[0].id; })} year={this.props.year} siginUpConfig={this.props.signUpConfig} reportConfig={this.props.reportConfig} />
                <Col className="d-flex flex-column" md={{ size: 6, offset: 2 }}>
                    <div style={{ color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', margin: 5 }}>Resumo das Atividades</div>
                    <div style={{ color: '#F0F0F0', height: 2, margin: 5 }} />
                </Col>
                <Col className="d-flex flex-column" md={{ size: 4, offset: 2 }}>
                    <Pie data={this.getPieData(usersWhoFinishedAllModules, totalUsers, 'Concluíram o Treinamento', 'Ainda não terminaram', 'rgba(54, 162, 235, 0.4)', 'rgba(215, 215, 215, 0.4)')} options={options} />
                </Col>
                <Col className="d-flex flex-column" md={{ size: 4 }}>
                    <Pie data={this.getPieData(usersWhoPlayedAnySubModule, totalUsersNotPlaying, 'Jogaram', 'Sem Jogar', 'rgba(75, 192, 192, 0.4)', 'rgba(215, 215, 215, 0.4)')} options={options} />
                </Col>
                <Col className="d-flex flex-column mt-5 mb-3" md={{ size: 8, offset: 2 }}>
                    <Bar data={barData} options={barOptions} />
                </Col>
                <Col className="d-flex flex-column mt-5 mb-3" md={{ size: 8, offset: 2 }}>
                    <Line data={lineDataWithTwoLabels} options={lineChartOptions} />
                </Col>
                {modules[0].feedback && <UserFeedbackComponent moduleId={modules[0].id} users={users} />}
            </Row>)
        } else {
            // TODOS
            let lineDataWithOneLabel = this.getLineChartData(cumulativeUsersWhoPlayedInMonth)
            let lineChartOptions: ChartOptions = { responsive: true, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Usuários acumulados que jogaram ao longo do ano' } } }
            return (<Row style={{ overflow: 'auto' }} className="mt-3">
                <Col className="d-flex flex-column" md={{ size: 6, offset: 2 }}>
                    <div style={{ color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', margin: 5 }}>Resumo das Atividades</div>
                    <div style={{ color: '#F0F0F0', height: 2, margin: 5 }} />
                </Col>
                <Col className="d-flex flex-column" md={{ size: 4, offset: 2 }}>
                    <Pie data={this.getPieData(usersWhoPlayedAnySubModule, totalUsersNotPlaying, 'Jogaram', 'Sem Jogar', 'rgba(75, 192, 192, 0.4)', 'rgba(215, 215, 215, 0.4)')} options={options} />
                </Col>
                <Col className="d-flex flex-column mt-5 mb-3" md={{ size: 8, offset: 2 }}>
                    <Bar data={barData} options={barOptions} />
                </Col>
                <Col className="d-flex flex-column mt-5 mb-3" md={{ size: 8, offset: 2 }}>
                    <Line data={lineDataWithOneLabel} options={lineChartOptions} />
                </Col>
            </Row>)
        }

    }
}