export default class SubModuleRating {
    id: string
    companyId: string
    moduleId: string
    subModuleId: string
    date: number
    userId: string
    rating: number

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
        this.date = data.date
        this.userId = data.userId
        this.rating = data.rating
    }
}