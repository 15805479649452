import React, { Component, CSSProperties, Fragment } from 'react'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import WordSearchManager from './WordSearchManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

interface State {
    selection: Array<string>
    wordPositioning: Array<Array<boolean>>
    randomLetters: Array<string>
    isModalOpen: boolean
}

interface Props { rows: number, columns: number, word: string, title: string, onWordFound: () => void, onExit: () => void }

export default class WordSearchComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        let currentWord = props.word.toUpperCase()
        let wordSearchManager = new WordSearchManager(this.props.rows, this.props.columns)
        let wordPositioning = wordSearchManager.initWord(currentWord)
        let allLetters = 'AÃÁBCDEÊÉFGHIÍJKLMNOÓÕPQRSTUVWXYZ'
        let randomLetters: Array<string> = []
        let randomLettersLength = (this.props.rows * this.props.columns) - currentWord.length
        for (let i = 0; i < randomLettersLength; i++) {
            randomLetters.push(allLetters[Math.round(Math.random() * (allLetters.length - 1))])
        }
        this.state = { selection: [], wordPositioning, randomLetters, isModalOpen: false }
    }

    onLetterSelected(column: number, row: number) {
        if (this.isLetterSelected(column, row)) {
            let newSelection = this.state.selection.filter(data => data !== `${column},${row}`)
            this.setState({ selection: newSelection }, () => {
                if (this.isWordFound()) this.props.onWordFound()
            })
        } else {
            let newSelection = [...this.state.selection]
            newSelection.push(`${column},${row}`)
            this.setState({ selection: newSelection }, () => {
                if (this.isWordFound()) this.props.onWordFound()
            })
        }
    }

    isLetterSelected(column: number, row: number) {
        return this.state.selection.filter(data => data === `${column},${row}`).length > 0
    }

    getStyleForLetter(column: number, row: number): CSSProperties {
        if (this.isLetterSelected(column, row)) return this.state.wordPositioning[column][row] ? rightLetterSelectedStyle : letterSelectedStyle
        else return letterDefaultStyle
    }

    isWordFound() {
        for (let col = 0; col < this.props.columns; col++) {
            let currentCol = this.state.wordPositioning[col]
            for (let row = 0; row < this.props.rows; row++) {
                let currentRow = currentCol[row]
                // deixa selecionar algumas erradas mesmo se tiver achado todas as certas
                if(currentRow && !this.isLetterSelected(col, row)) return false
                /*if (this.isLetterSelected(col, row)) {
                    if (!currentRow) return false
                } else {
                    if (currentRow) return false
                }*/
            }
        }

        return true
    }

    renderHelpModal(isModalOpen: boolean) {

        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Modal isOpen={isModalOpen} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => this.setState({ isModalOpen: false })}>
                    <ModalHeader toggle={() => this.setState({ isModalOpen: false })}><FontAwesomeIcon icon={faQuestionCircle} /> AJUDA</ModalHeader>
                    <ModalBody>
                        <img
                            alt=""
                            src={require('../images/ajuda_caca_palavras.png')}
                            width="100%"
                            className="d-inline-block align-top"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 16 }}>Selecione as letras em sequência na <b>horizontal</b> ou <b>vertical</b>.</div>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    render() {

        let { isModalOpen } = this.state
        //let array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        let columnArray = Array.from(Array(this.props.columns).keys())
        let rowsArray = Array.from(Array(this.props.rows).keys())
        let currentWord = this.props.word.toUpperCase()
        let currentWordIndex = -1
        let randomLetterIndex = -1

        return (<Fragment>
            <Row className='no-gutters' style={{ background: '#FFFFFFDA', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.2)', paddingTop: 5, paddingBottom: 5 }}>
                <Col className='d-flex justify-content-start align-items-center' style={{ minHeight: '4em' }} md={{ size: 8, offset: 2 }}>
                    <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#353f45' icon={faArrowLeft} size='2x' /></Button>
                    <div className='d-flex flex-column justify-content-center align-items-start' >
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', fontSize: 16 }}>Caça-Palavras</div>
                        <div style={{ color: '#353f45', fontFamily: 'Montserrat', fontSize: 12 }}>Encontre a palavra certa clicando nas letras em sequência</div>
                    </div>
                </Col>
            </Row>
            <Row className='no-gutters' style={{ overflow: 'auto' }}>
                <Col md={{ size: 8, offset: 2 }}>
                    <div className="d-flex justify-content-center p-1" style={{ color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', marginTop: 15 }}>
                        <b>{this.props.title}</b>
                    </div>
                </Col>
                <Col style={{ marginTop: 5, marginBottom: 10 }} md={{ size: 8, offset: 2 }}>
                    <div className="d-flex justify-content-center align-items-center p-1" style={{ color: '#616975', fontFamily: 'Montserrat', fontSize: 14, verticalAlign: 'center' }}>
                        <FontAwesomeIcon style={{ marginRight: 5 }} icon={faInfoCircle}/><i>{`Dica: começa com a letra ${currentWord[0]}`}{currentWord[currentWord.length - 1] === 'S' ? ' e a palavra está no plural (termina com S).' : '.'}</i>
                    </div>
                </Col>
                <Col md={{ size: 8, offset: 2 }}>
                    {columnArray.map(column => {
                        return (
                            <div key={`col-${column}`} className="d-flex">
                                {rowsArray.map(row => {
                                    if (this.state.wordPositioning[column][row]) {
                                        currentWordIndex++
                                        return (<div key={`row-${row}`} style={this.getStyleForLetter(column, row)} className="d-flex flex-column" onClick={() => this.onLetterSelected(column, row)}>
                                            {currentWord[currentWordIndex]}
                                        </div>)
                                    } else {
                                        randomLetterIndex++
                                        return (<div key={`row-${row}`} style={this.getStyleForLetter(column, row)} className="d-flex flex-column" onClick={() => this.onLetterSelected(column, row)}>
                                            {this.state.randomLetters[randomLetterIndex]}
                                        </div>)
                                    }
                                })}
                            </div>
                        )
                    })}
                </Col>
                <Col className="mt-2 d-flex flex-column justify-content-center" md={{ size: 8, offset: 2 }}>
                    <Button style={{ margin: 5, padding: 10 }} color='primary' onClick={() => this.setState({ isModalOpen: true })}><FontAwesomeIcon icon={faQuestionCircle} /> AJUDA</Button>
                </Col>
            </Row>
            {this.renderHelpModal(isModalOpen)}
        </Fragment>)
    }
}

const letterDefaultStyle: CSSProperties = {
    color: 'black', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', flex: 1,
    borderRadius: 2, margin: 5, padding: 1, background: '#FFFFFFDA', boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)'
}

const letterSelectedStyle: CSSProperties = {
    color: 'white', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', flex: 1,
    borderRadius: 2, background: '#AAAD',
    margin: 5, padding: 1
}

const rightLetterSelectedStyle: CSSProperties = {
    color: 'white', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', flex: 1,
    borderRadius: 2, background: '#6fce59dd',
    margin: 5, padding: 1
}