import { DeckCardReport, getCardReportArray } from './DeckCardsReport'
import DeckCard, { getDeckCard } from './DeckCards'
import { SubModuleTypes } from './SubModule'
import { QuizQuestionsReport, getQuizQuestionReportArray } from './QuizQuestionsReport'
import QuizQuestion from './QuizQuestion'

export function createReportFromData(data: any) {
    if(data.type == SubModuleTypes.DECK) {
        return new DeckSubModuleFinishedReport(data)
    } else if(data.type == SubModuleTypes.QUIZ) {
        return new QuizQuestionFinishedReport(data)
    } else {
        return new SubModuleFinishedReport(data)
    }
}

export class SubModuleFinishedReport {

    companyId: string
    moduleId: string
    subModuleId: string
    userId: string
    startTime: number
    endTime: number
    date_in_millis: number
    date: string
    type: string
    score: number

    constructor(data: any) {
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
        this.userId = data.userId
        this.startTime = data.startTime
        this.endTime = data.endTime
        this.date_in_millis = data.date_in_millis
        this.date = data.date
        this.type = data.type
        this.score = data.score
    }

    getData(): any {
        return {
            companyId: this.companyId,
            moduleId: this.moduleId,
            subModuleId: this.subModuleId,
            userId: this.userId,
            startTime: this.startTime,
            endTime: this.endTime,
            date_in_millis: this.date_in_millis,
            date: this.date,
            type: this.type,
            score: this.score
        }
    }
}

export class DeckSubModuleFinishedReport extends SubModuleFinishedReport {
    deckCardsReport: Array<DeckCardReport<DeckCard>>
    constructor(data: any) {
        super(data)
        this.deckCardsReport = getCardReportArray(data.deckCardsReport)
    }

    getData(): any {
        return {
            ...super.getData(),
            deckCardsReport: this.deckCardsReport.map(report => report.getData())
        }
    }
}

export class QuizQuestionFinishedReport extends SubModuleFinishedReport {
    quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>
    constructor(data: any) {
        super(data)
        this.quizQuestionsReport = getQuizQuestionReportArray(data.quizQuestionsReport)
    }

    getData(): any {
        return {
            ...super.getData(),
            quizQuestionsReport: this.quizQuestionsReport.map(report => report.getData())
        }
    }
}