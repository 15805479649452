
import React, { Component, Fragment } from 'react';
import { faExclamationCircle, faImage, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { CARD_IMG_MAX_WIDTH_AND_HEIGHT } from '../../../../../constants/images';
import FileHelper from '../../../../../files/FileHelper';
import { MemoryQuestion, QuizQuestionType } from '../../../../../models/QuizQuestion';
import LoadingScreen from '../../../../loading';
import SelectImageComponent from '../SelectImageComponent';
import { PickImageCardProps } from './interfaces';

interface MemoryCardState {
    images: Array<{ imgUrl: string, text: string }>
    title: string;
    text: string;
    selectedImage: string | undefined;
    isLoading: boolean;
}

const MEMORY_CARD_INITIAL_STATE: MemoryCardState = {
    images: [],
    title: '',
    text: '',
    selectedImage: undefined,
    isLoading: false,
}

export default class CreateMemoryCardComponent extends Component<PickImageCardProps, MemoryCardState> {

    FileHelper = new FileHelper()

    constructor(props: PickImageCardProps) {
        super(props)

        this.state = { ...MEMORY_CARD_INITIAL_STATE }

    }

    createCard = (title: string, images: Array<{ imgUrl: string, text: string }>) => {
        let { subModule, lastPosition } = this.props
        let memoryCard = new MemoryQuestion({
            id: subModule.id + '_' + lastPosition.toString(),
            type: QuizQuestionType.MemoryGame,
            companyId: subModule.companyId,
            moduleId: subModule.moduleId,
            subModuleId: subModule.id,
            images
        })
        if(title.length > 0) memoryCard.title = title
        this.props.addQuizCard(memoryCard)
    }

    uploadImage = async (file: File | undefined) => {
        if (!file) return

        try {
            let imageUrl = await this.FileHelper.getFileDataUrl(file)
            let imageElement = await this.FileHelper.loadImage(imageUrl)
            let canvas = document.createElement('canvas')
            let resizedImageBlob = await this.FileHelper.resizeImage(canvas, imageElement, CARD_IMG_MAX_WIDTH_AND_HEIGHT, CARD_IMG_MAX_WIDTH_AND_HEIGHT)
            this.props.uploadCardImage(resizedImageBlob, image => image && this.onImageSelected(image))
        } catch (error) {
            this.props.onError(error)
        }
    }

    onImageSelected = (image: string) => {
        this.setState({ selectedImage: image, isLoading: false }, () => this.props.updateIsPickingFile(false))
    }

    removeImage = (images: Array<{ imgUrl: string, text: string }>, removeImgUrl: string) => {
        let arrayWithoutImage = [...images.filter(({ imgUrl }) => imgUrl !== removeImgUrl)]
        this.setState({ images: arrayWithoutImage })
    }

    renderSelectImageSection(image: string | undefined, text: string, images: Array<{ imgUrl: string, text: string }>) {
        let imageText = image ? 'Trocar' : 'Selecionar'
        return (<Col md={{ size: 6, offset: 3 }}>
            <div className="d-flex flex-column p-2 rounded bg-light" style={{ margin: 5 }}>
                <Button outline style={{ margin: 5, alignSelf: 'center' }} color="secondary" onClick={() => this.props.updateIsPickingFile(true)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faImage} />{`${imageText} Imagem`}</Button>
                {image && <div className="d-flex flex-column m-2 align-items-center">
                    <img style={{ objectFit: 'contain', maxHeight: 200, maxWidth: 3000 }} src={image} alt="Foto do card" />
                    <Form className="mt-2 mb-2" style={{ width: 'auto' }}>
                        <Input value={text} name="text" id="cardText" placeholder="Texto da Carta" onChange={(event: any) => this.setState({ text: event.target.value })} />
                    </Form>
                    <Button style={{ margin: 5 }} color="success" onClick={() => {
                        this.setState({ images: [...images, { text, imgUrl: image }], selectedImage: undefined, text: '' })
                    }}>Incluir Carta <FontAwesomeIcon icon={faPlusCircle} /></Button>
                </div>}
            </div>
        </Col>)
    }

    renderImage(images: Array<{ imgUrl: string, text: string }>, imgUrl: string, text: string) {
        return (<Col key={imgUrl} xs="6" md="3" sm="4">
            <div className="d-flex flex-column m-2 p-2 align-items-center" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA', borderRadius: 5 }}>
                <img className='mb-2' style={{ objectFit: 'contain', maxHeight: 200, maxWidth: 3000 }} src={imgUrl} alt="Foto do card" />
                <div className="mb-2" style={{ fontSize: '0.9em' }}>{text}</div>
                <Button outline color="danger" size="sm" onClick={() => this.removeImage(images, imgUrl)}><FontAwesomeIcon icon={faTrash} /> Remover</Button>
            </div>
        </Col>)
    }

    renderForm(images: Array<{ imgUrl: string, text: string }>, selectedImage: string | undefined, text: string, title: string) {
        return (<Fragment>
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <Form style={{ margin: 5 }}>
                        <FormGroup>
                            <Label for="cardText">Título do Jogo</Label>
                            <Input value={title} name="title" id="cardTitle" placeholder="Título ( opcional )" onChange={(event: any) => this.setState({ title: event.target.value })} />
                        </FormGroup>
                    </Form>
                </Col>
                {this.renderSelectImageSection(selectedImage, text, images)}
            </Row>
            <Row>
                {images.length > 0 && images.map(({ imgUrl, text }) => this.renderImage(images, imgUrl, text))}
            </Row>
            <Row>
                <Col className="d-flex flex-column justify-content-center mt-1 mb-1" md={{ size: 6, offset: 3 }}>
                    {images.length < 3 && <div style={{ fontSize: 'small', marginLeft: 5 }}><FontAwesomeIcon icon={faExclamationCircle} /> O jogo da memória precisa ter ao menos 3 cartas.</div>}
                    <Button style={{ marginLeft: 5, marginRight: 5, marginBottom: 5 }} disabled={images.length < 3} color="primary" onClick={() => this.createCard(title, images)}>Adicionar</Button>
                </Col>
            </Row>
        </Fragment>)
    }

    render() {

        let { isLoading, images, selectedImage, text, title } = this.state
        if (isLoading) { return <LoadingScreen /> }
        if (!this.props.isPickingFile) return this.renderForm(images, selectedImage, text, title)
        else return (<SelectImageComponent getCardImages={this.props.getCardImages} uploadFile={this.uploadImage} onImageSelected={this.onImageSelected} goBackToEdit={() => this.props.updateIsPickingFile(false)} />)

    }

}