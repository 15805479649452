export default class ModuleUserAccess {
    
    id: string
    companyId: string
    moduleId: string
    primaryPropertyValue: string

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.primaryPropertyValue = data.primaryPropertyValue
    }

    getData(): any {
        let data: any = {
            id: this.id,
            companyId: this.companyId,
            moduleId: this.moduleId,
            primaryPropertyValue: this.primaryPropertyValue
        }
        return data
    }

}