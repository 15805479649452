export class SMS {
    id: string
    sid: string
    companyId: string
    type: string
    dateString: string
    dateMillis: number
    toPhone: string
    status?: string
    message?: string

    constructor(data: any) {
        this.id = data.id
        this.sid = data.sid
        this.companyId = data.companyId
        this.type = data.type
        this.dateString = data.dateString
        this.dateMillis = data.dateMillis
        this.toPhone = data.toPhone
        this.status = data.status
        this.message = data.message
    }

    getData(): any {
        let data: any = {
            id: this.id,
            companyId: this.companyId,
            type: this.type,
            dateString: this.dateString,
            dateMillis: this.dateMillis,
            toPhone: this.toPhone,
        }
        if(this.message) data.message = this.message
        if(this.status) data.status = this.status
        if(this.sid) data.sid = this.sid
        return data
    }
}

export class ErrorSMS extends SMS {
    error: string
    code: string

    constructor(data: any) {
        super(data)
        this.error = data.error
        this.code = data.code
    }

    getData(): any {
        return {
            ...super.getData(),
            error: this.error,
            code: this.code                 
        }
    }
}

export class RecoverPwdSMS extends SMS {
    userId: string
    recoverPwdId: string


    constructor(data: any) {
        super(data)
        this.userId = data.userId
        this.recoverPwdId = data.recoverPwdId
    }

    getData(): any {
        return {
            ...super.getData(),
            userId: this.userId,
            recoverPwdId: this.recoverPwdId
        }
    }
}

export class LastSmsSent {
    id: string
    companyId: string
    phone: string
    smsData: SMS

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.phone = data.phone
        this.smsData = getSMS(data.smsData)
    }

    getData(): any {
        return {
            id: this.id,
            companyId: this.companyId,
            phone: this.phone,
            smsData: this.smsData.getData()
        }
    }
}

export function getSMS(data: any) {
    if(data.type === SMSTypes.Error) {
        return new ErrorSMS(data)
    } else if(data.type === SMSTypes.RecoverPwd) {
        return new RecoverPwdSMS(data)
    } else {
        return new SMS(data)
    }
}

export class SmsReceived {
    id: string
    companyId: string
    phone: string
    dateMillis: number
    text: string

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.phone = data.phone
        this.dateMillis = data.dateMillis
        this.text = data.text
    }

    getData(): any {
        return {
            id: this.id,
            companyId: this.companyId,
            phone: this.phone,
            dateMillis: this.dateMillis,
            text: this.text
        }
    }
}

export const SMSTypes = {
    EnterLink: 'enter_link',
    Message: 'message',
    Error: 'error',
    RecoverPwd: 'recover_pwd',
    AutomaticResponse: 'automatic_response'
}