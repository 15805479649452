import React, { Fragment, useCallback, useEffect } from 'react';
import Company from '../../../models/Company';
import { SignUpConfig, AuthData, PhoneConfig } from '../../../models/CompanyConfig';
import { useNavigate } from 'react-router-dom'

import config from '../../../config'
import { Row, Col, Button, Container, Alert, Input, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faFileUpload, faUserPlus, faSms, faPhone, faArrowLeft, faBan, faUsers, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import LoadingScreen from '../../loading';
import TokenHelper from '../../../auth/TokenHelper';
import UserAccessValidation from '../../../models/UserAccessValidation';
import AdminUserAccessValidationService from '../../../services/admin/adminAccessValidationService';
import AdminSmsService from '../../../services/admin/adminSmsService'

import * as ROUTES from '../../../constants/routes'
import './style.css'
import AddAccessValidationComponent from './AddAccessValidationComponent';
import ReactList from 'react-list';
import SearchInputView from '../../../components/SearchInputView';
import BlockUsersComponent from './BlockUsersComponent';
import { useUser } from '../../../hooks/useUser';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

enum Action {
    ViewAccessValidation = 1,
    AddAccessValidation,
    BlockUsers,
}

const WhatsAppMessageTemplates = [
    //'Olá, a ApoioEcolimp tem uma plataforma de treinamentos online para você se capacitar pelo próprio telefone. Clique no botão abaixo para acessar o treinamento. Qualquer dúvida, fale com o seu gestor.',
    'Acesse o seu treinamento {{1}} no link {{2}} _Responda essa mensagem para poder clicar no link_',
    'Complete o treinamento {{1}} e ganhe pontos e um certificado. Para fazer é só acessar o link {{2}} _responda esta mensagem para poder clicar no link_',
    'Olá, vimos que você ainda não entrou na plataforma de treinamento {{1}}. Para entrar é só acessar o link {{2}} _responda esta mensagem para poder clicar no link_'
]

interface State {
    error: string | undefined;
    isLoading: boolean;
    company: Company | undefined;
    signUpConfig: SignUpConfig | undefined;
    userAccessValidation: Array<UserAccessValidation>;
    searchInput: string;
    action: Action;
    isSendingSms: boolean;
    showSmsPopUp: boolean;
    showWhatsAppPopUp: boolean;
    isSendingWhatsApp: boolean;
    userPhoneInput: string;
    userIdentification: string | undefined
    selectedMessageTemplate: number;
    height: number;
    whatsAppTemplates: Array<string>;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    company: undefined,
    signUpConfig: undefined,
    userAccessValidation: [],
    searchInput: '',
    action: Action.ViewAccessValidation,
    isSendingSms: false,
    showSmsPopUp: false,
    showWhatsAppPopUp: false,
    isSendingWhatsApp: false,
    userPhoneInput: '',
    userIdentification: undefined,
    selectedMessageTemplate: 0,
    height: 0,
    whatsAppTemplates: WhatsAppMessageTemplates,
};

const UserAccessMainScreen = () => {

    const navigate = useNavigate()
    const { company, noCompany } = useUser()
    const [state, setState] = React.useState<State>(INITIAL_STATE)
    const { height } = useWindowDimensions()

    useEffect(() => {
        loadCompanySignUpConfig()
    }, [])

    const loadCompanySignUpConfig = async () => {
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            setState({ ...state, isLoading: true, error: undefined })
            let adminUserAccessValidationService = new AdminUserAccessValidationService()
            let { company, signUpConfig, companyMessage } = await adminUserAccessValidationService.getCompanySignUpConfigForUserAccess(config.endpoint, token)
            let userAccessValidation = await adminUserAccessValidationService.getAllAccessValidation(token, config.endpoint)
            let whatsAppTemplates = companyMessage ? [companyMessage, ...state.whatsAppTemplates] : [...state.whatsAppTemplates]
            setState({ ...state, isLoading: false, company, signUpConfig, whatsAppTemplates, userAccessValidation })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                loadCompanySignUpConfig()
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const addUserAccess = async (primaryPropertyValue: string, name?: string, phone?: string, authData?: { [id: string]: string }) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let adminUserAccessValidationService = new AdminUserAccessValidationService()
            let userAccessValidation = await adminUserAccessValidationService.addUserAccessValidation(token, config.endpoint, primaryPropertyValue, name, phone, authData)
            if (state.userAccessValidation!.filter(data => data.primaryPropertyValue === primaryPropertyValue).length > 0) {
                let oldUserAccessValidation = state.userAccessValidation!.filter(data => data.primaryPropertyValue !== primaryPropertyValue)
                let newUserAccessValidation = [userAccessValidation, ...oldUserAccessValidation]
                setState({ ...state, isLoading: false, action: Action.ViewAccessValidation, userAccessValidation: newUserAccessValidation })
            } else {
                let newUserAccessValidation = [userAccessValidation, ...state.userAccessValidation!]
                setState({ ...state, isLoading: false, action: Action.ViewAccessValidation, userAccessValidation: newUserAccessValidation })
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                addUserAccess(primaryPropertyValue, name, phone, authData)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const deleteUserAccessValidation = async (primaryPropertyValue: string) => {
        setState({ ...state, isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let adminUserAccessValidationService = new AdminUserAccessValidationService()
            await adminUserAccessValidationService.deleteUserAccessValidation(token, config.endpoint, primaryPropertyValue)
            let newUserAccessValidation = state.userAccessValidation!.filter(data => data.primaryPropertyValue !== primaryPropertyValue)
            setState({ ...state, isLoading: false, userAccessValidation: newUserAccessValidation })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                deleteUserAccessValidation(primaryPropertyValue)
            } else {
                setState({ ...state, isLoading: false, error: error.toString() })
            }
        }
    }

    const sendWhatsAppMessage = async (company: Company, userPhone: string, selectedMessageTemplate: number) => {
        setState({ ...state, isSendingWhatsApp: true, error: undefined })
        let onlyNumbersPhone = userPhone.replace(/\D/g, "")
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let message = state.whatsAppTemplates[selectedMessageTemplate].replace('{{1}}', company.name).replace('{{2}}', `https://www.gamific.app/app/enter/${company.id}`) //WhatsAppMessageTemplates[selectedMessageTemplate].replace('{{1}}', company.name).replace('{{2}}', `https://www.gamific.app/app/enter/${company.id}`)
            let adminSmsService = new AdminSmsService()
            await adminSmsService.sendWhatsAppMessage(token, config.endpoint, [onlyNumbersPhone], message)
            setState({ ...state, isSendingWhatsApp: false, showWhatsAppPopUp: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                sendWhatsAppMessage(company, userPhone, selectedMessageTemplate)
            } else if (error.response && error.response.data && error.response.data.error) {
                let errorCode = error.response.data.error.code
                if (errorCode === 'Invalid_Phone_Number') {
                    setState({ ...state, isSendingWhatsApp: false, showWhatsAppPopUp: false, error: 'Número de telefone inválido' })
                } else {
                    setState({ ...state, isSendingWhatsApp: false, showWhatsAppPopUp: false, error: error.toString() })
                }
            } else {
                setState({ ...state, isSendingWhatsApp: false, showWhatsAppPopUp: false, error: error.toString() })
            }
        }

    }

    const sendSMSLink = async (userPhone: string) => {
        setState({ ...state, isSendingSms: true, error: undefined })
        let onlyNumbersPhone = userPhone.replace(/\D/g, "")
        let tokenHelper = new TokenHelper()
        try {
            var token = tokenHelper.getToken()
            if (!token) {
                token = await tokenHelper.getNewToken()
            }
            let adminSmsService = new AdminSmsService()
            await adminSmsService.sendLinkSMS(token, config.endpoint, onlyNumbersPhone)
            setState({ ...state, isSendingSms: false, showSmsPopUp: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                sendSMSLink(userPhone)
            } else if (error.response && error.response.data && error.response.data.error) {
                let errorCode = error.response.data.error.code
                if (errorCode === 'Invalid_Phone_Number') {
                    setState({ ...state, isSendingSms: false, showSmsPopUp: false, error: 'Número de telefone inválido' })
                } else {
                    setState({ ...state, isSendingSms: false, showSmsPopUp: false, error: error.toString() })
                }
            } else {
                setState({ ...state, isSendingSms: false, showSmsPopUp: false, error: error.toString() })
            }
        }
    }

    const goToUploadFile = () => {
        navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.UPLOAD_CREDENTIALS}`)
    }

    const goToBlockUsers = () => setState({ ...state, action: Action.BlockUsers })

    const goToSmsPanel = () => {
        navigate(`${ROUTES.APP_LANDING}${ROUTES.ADMIN}${ROUTES.SMS_PANEL}`)
    }

    const lowerCaseAndRemovePunctuationFromString = (data: string) => {
        return data.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ")
    }

    const filterUserAccessValidation = (userAccessValidation: Array<UserAccessValidation>, searchInput: string) => {
        return userAccessValidation.filter(data => {
            let primaryPropertyWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(data.primaryPropertyValue)
            let searchInputWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(searchInput)
            if (primaryPropertyWithoutPunctuation.includes(searchInputWithoutPunctuation)) return true
            if (data.name) {
                let nameWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(data.name)
                return nameWithoutPunctuation.includes(searchInputWithoutPunctuation)
            }
            return false
        })
    }

    const goBack = (currentAction: Action) => {
        if (currentAction === Action.ViewAccessValidation) {
            navigate(-1)
        } else {
            setState({ ...state, action: Action.ViewAccessValidation })
        }
    }

    const updatePhone = (phoneConfig: PhoneConfig | undefined, newValue: string) => {
        if (phoneConfig && phoneConfig.mask) {
            if (phoneConfig.mask === 'br') {
                let phoneWithMask = applyPhoneBrMask(newValue)
                return setState({ ...state, userPhoneInput: phoneWithMask })
            }
        }

        setState({ ...state, userPhoneInput: newValue })
    }

    const applyPhoneBrMask = (value: string) => {
        return value.replace(/\D/g, "")           //Remove tudo o que não é dígito
            .replace(/^(\d{2})(\d)/g, "($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
            .replace(/(\d)(\d{4})$/, "$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
    }

    const showSendSmsPopUp = (userIdentification: string | undefined, phone: string) => {
        if (userIdentification) {
            setState({ ...state, showSmsPopUp: true, userIdentification, userPhoneInput: phone })
        } else {
            setState({ ...state, showSmsPopUp: true })
        }
    }

    const showSendWhatsAppPopUp = (userIdentification: string | undefined, phone: string) => {
        if (userIdentification) {
            setState({ ...state, showWhatsAppPopUp: true, userIdentification, userPhoneInput: phone })
        } else {
            setState({ ...state, showWhatsAppPopUp: true })
        }
    }

    const renderHeader = (company: Company, action: Action) => {
        return (<Row className="pt-2 pb-2 mb-3" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='none' outline onClick={() => { goBack(action) }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', marginLeft: 10 }}><b>{action === Action.BlockUsers ? 'Bloquear Usuários' : 'Acesso de Usuários'}</b></div>
                    <img alt='foto da empresa' style={{ height: 60, marginBottom: 10, marginTop: 10 }} src={company.pic} />
                </div>
            </Col>
        </Row>)
    }

    const renderAlert = (alert: string) => {
        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Alert color="danger">{alert}</Alert>
            </Col>
        </Row>)
    }

    const renderSMSModal = (signUpConfig: SignUpConfig, showSmsPopUp: boolean, isSendingSms: boolean, userIdentification: string | undefined, phoneInput: string) => {
        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Modal isOpen={showSmsPopUp} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => setState({ ...state, showSmsPopUp: false, userPhoneInput: '', userIdentification: undefined })}>
                    <ModalHeader toggle={() => setState({ ...state, showSmsPopUp: false, userPhoneInput: '', userIdentification: undefined })}>Enviar SMS</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label style={{ fontFamily: 'Montserrat' }} for="phoneTitle">{userIdentification || 'O usuário'} vai receber uma mensagem no celular com o link para começar a jogar.</Label>
                            <Input disabled={userIdentification !== undefined} style={{ fontFamily: 'Montserrat' }} name="phone" id="phoneTitle" placeholder='(11)977334563' value={phoneInput} onChange={(event: any) => updatePhone(signUpConfig.phone, event.target.value)} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={isSendingSms} color="primary" onClick={() => sendSMSLink(phoneInput)}>{isSendingSms ? 'Enviando...' : 'Enviar'}</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    const renderWhatsAppModal = (company: Company, signUpConfig: SignUpConfig, showWhatsAppPopUp: boolean, isSendingWhatsApp: boolean, userIdentification: string | undefined, phoneInput: string, selectedMessageTemplate: number) => {
        return (<Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Modal isOpen={showWhatsAppPopUp} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 300 }}
                    toggle={() => setState({ ...state, showWhatsAppPopUp: false, userIdentification: undefined, userPhoneInput: '' })}>
                    <ModalHeader toggle={() => setState({ ...state, showWhatsAppPopUp: false, userIdentification: undefined, userPhoneInput: '' })}>Enviar WhatsApp</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label style={{ fontFamily: 'Montserrat' }} for="phoneTitle">{userIdentification || 'O usuário'} vai receber uma mensagem no WhatsApp com o link para começar a jogar.</Label>
                            <Input disabled={userIdentification !== undefined} style={{ fontFamily: 'Montserrat' }} name="phone" id="phoneTitle" placeholder='(11)977334563' value={phoneInput} onChange={(event: any) => updatePhone(signUpConfig.phone, event.target.value)} />
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <legend>Mensagem</legend>
                            {state.whatsAppTemplates.map((messageTemplate, index) => {
                                let correctMessage = messageTemplate.replace('{{1}}', company.name).replace('{{2}}', `https://www.gamific.app/app/enter/${company.id}`).replace('_responda esta mensagem para poder clicar no link_', '')
                                return (<FormGroup check style={{ marginBottom: 10 }}>
                                    <Label check>
                                        <Input type="radio" name={`radio${index}`} value={index} checked={selectedMessageTemplate === index} onChange={(event: any) => { event.persist(); setState({ ...state, selectedMessageTemplate: parseInt(event.target.value) }) }} />{' '}{correctMessage}
                                    </Label>
                                </FormGroup>)
                            })}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={isSendingWhatsApp} color="primary" onClick={() => sendWhatsAppMessage(company, phoneInput, selectedMessageTemplate)}><FontAwesomeIcon icon={faWhatsapp} /> {isSendingWhatsApp ? 'Enviando...' : 'Enviar'}</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>)
    }

    const renderOptions = (isSendingSms: boolean, isSendingWhatsApp: boolean, companyId: string) => {
        return (<Row style={{ marginBottom: 10 }}>
            <Col md={{ size: 8, offset: 2 }}>
                <Button style={{ margin: 5 }} color="success" onClick={() => setState({ ...state, action: Action.AddAccessValidation })}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faUserPlus} />Adicionar Usuário</Button>
                <Button style={{ margin: 5 }} color="primary" onClick={() => goToUploadFile()}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faFileUpload} />Subir Planilha</Button>
                <Button style={{ margin: 5 }} color="danger" onClick={() => goToBlockUsers()}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faBan} />Bloquear Usuários</Button>
                <Button disabled={isSendingWhatsApp} className='wpp-btn' style={{ margin: 5 }} onClick={() => { showSendWhatsAppPopUp(undefined, '') }}><FontAwesomeIcon icon={faWhatsapp} /> Enviar Link por WhatsApp</Button>
            </Col>
            <Col md={{ size: 8, offset: 2 }}>
                <div style={{ fontSize: 'small', marginTop: 10, color: 'black', padding: 10, background: 'white', borderRadius: 5 }}>Seu link de acesso é: <b>{`https://www.gamific.app/app/enter/${companyId}`}</b></div>
            </Col>
        </Row>)
    }

    const renderAuthData = (userAccessAuthData: any, signUpConfigAuthData: { [id: string]: AuthData }) => {
        return Object.keys(userAccessAuthData).sort().map((authDataKey, index) => {
            let authDataValue = userAccessAuthData[authDataKey]
            let signUpAuthDataInfo = signUpConfigAuthData[authDataKey]
            return (<div key={index} className="d-flex flex-column" style={{ marginTop: 2 }}>
                <div style={{ fontSize: 10, marginLeft: 5, marginTop: 2, color: 'black' }}>{`${signUpAuthDataInfo.title} - ${authDataValue}`}</div>
            </div>)
        })
    }

    const renderPhone = (userIdentification: string | undefined, userPhone: string) => {
        return (<div className="d-flex flex-column" style={{ fontSize: 12, marginLeft: 5, marginTop: 5, color: '#1d3256' }}>
            <div><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faPhone} />{userPhone}</div>
            <Button className='wpp-btn' style={{ marginTop: 5, maxWidth: 300 }} onClick={() => { showSendWhatsAppPopUp(userIdentification, userPhone) }}><FontAwesomeIcon icon={faWhatsapp} /> Enviar Link por WhatsApp</Button>
            <Button style={{ marginTop: 5, maxWidth: 300 }} color="info" onClick={() => showSendSmsPopUp(userIdentification, userPhone)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faSms} />Enviar Link por SMS</Button>
        </div>)
    }

    const renderUserAccessValidation = (companyId: string, userAccessValidation: Array<UserAccessValidation>, authData: { [id: string]: AuthData } | undefined = undefined) => {
        let sortedUserAccessValidation = userAccessValidation.sort((a, b) => {
            if (a.name && b.name) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
                else return 0
            } else if (a.name && !b.name) {
                return 1
            } else if (!a.name && b.name) {
                return - 1
            } else {
                if (a.primaryPropertyValue.toLowerCase() > b.primaryPropertyValue.toLowerCase()) return 1
                else if (a.primaryPropertyValue.toLowerCase() < b.primaryPropertyValue.toLowerCase()) return -1
                else return 0
            }
        })

        let renderItem = (index: number, key: number | string) => {
            let data = sortedUserAccessValidation[index]
            return (<div key={key} className="d-flex" style={{ marginTop: 15, marginBottom: 15, marginLeft: 5, marginRight: 5, paddingBottom: 5, borderRadius: 5, boxShadow: '2px 4px 4px 2px rgba(0,0,0,0.2)', background: '#FFFFFFDA' }}>
                <div className="d-flex" style={{ flex: 1, padding: 5 }}>
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <div className="d-flex flex-column" style={{ marginTop: 2 }}>
                            <div style={{ fontSize: 'small', marginLeft: 5, marginTop: 5, color: '#353f45' }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faInfoCircle} />{data.primaryPropertyValue}</div>
                            {data.name && <div style={{ fontSize: 18, color: '#353f45', fontFamily: 'Montserrat', verticalAlign: 'middle', marginTop: 5, marginLeft: 5 }}>{data.name}</div>}
                            {authData && data.authData && renderAuthData(data.authData, authData)}
                            {data.phone && renderPhone(data.name || data.primaryProperty, data.phone)}
                        </div>
                    </div>
                    <div className="p-2 d-flex flex-column justify-content-center">
                        <Button color="danger" onClick={() => deleteUserAccessValidation(data.primaryPropertyValue)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </div>
                </div>
            </div>)
        }

        return <Row style={{ overflow: 'auto' }}>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <div className="d-flex flex-column" style={{ flex: 1 }}>
                    <div style={{ fontSize: 'small', color: 'black', verticalAlign: 'middle', margin: 5 }}><FontAwesomeIcon style={{ marginRight: 5 }} color='#1d3256' icon={faUsers} />{sortedUserAccessValidation.length}</div>
                </div>
            </Col>
            <Col className="d-flex flex-column" md={{ size: 8, offset: 2 }}>
                <ReactList itemRenderer={renderItem} length={sortedUserAccessValidation.length} type='simple' />
            </Col>
        </Row>
    }

    const renderForAction = (company: Company, action: Action, state: State) => {

        let { signUpConfig, userAccessValidation, searchInput, isLoading, isSendingSms, showSmsPopUp, showWhatsAppPopUp, isSendingWhatsApp, userPhoneInput, selectedMessageTemplate, userIdentification } = state

        let filteredAccessValidation = filterUserAccessValidation(userAccessValidation || [], searchInput)

        if (action === Action.ViewAccessValidation) {
            return <Fragment>
                {signUpConfig && renderOptions(isSendingSms, isSendingWhatsApp, company.id)}
                <SearchInputView placeholder='Procurar usuário' updateSearchInput={(searchInput: string) => setState({ ...state, searchInput })} />
                {userAccessValidation && userAccessValidation.length > 0 && renderUserAccessValidation(company.id, filteredAccessValidation, signUpConfig && signUpConfig.authData)}
                {signUpConfig && renderSMSModal(signUpConfig, showSmsPopUp, isSendingSms, userIdentification, userPhoneInput)}
                {signUpConfig && renderWhatsAppModal(company, signUpConfig, showWhatsAppPopUp, isSendingWhatsApp, userIdentification, userPhoneInput, selectedMessageTemplate)}
            </Fragment>
        } else if (action === Action.AddAccessValidation && signUpConfig) {
            return <AddAccessValidationComponent signUpConfig={signUpConfig} isLoading={isLoading} addUserAccess={addUserAccess} />
        } else if (action === Action.BlockUsers && signUpConfig) {
            return <BlockUsersComponent signUpConfig={signUpConfig} />
        }
    }

    let { error, isLoading, action } = state

    if (isLoading && action === Action.ViewAccessValidation) { return <LoadingScreen image={company ? company.pic : undefined} /> }
    let backgroundImage = company && company.backgroundImages ? company.backgroundImages[0] : undefined
    let backgroundPosition = company?.backgroundPosition || 'left top'

    return (<Container className="d-flex flex-column" fluid style={{ height, background: backgroundImage ? `url(${backgroundImage}) 0% 0% / cover no-repeat` : 'white', overflow: 'none', backgroundPosition, boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.71)' }}>
        {error && renderAlert(error)}
        {company && renderHeader(company, action)}
        {company && renderForAction(company, action, state)}
    </Container>)

}

export default UserAccessMainScreen
