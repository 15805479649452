import React, { Component } from 'react';
import User from '../../../models/User';
import { Button, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamation, faGamepad, faStar, faTrophy, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { OponentUpdate } from './index'

interface State {
    oponentScore: number,
    oponentStatus: string
};

interface Props { oponent: User, user: User, userScore: number, oponentScore: number, oponentStatus: string, goBack: () => void }

export default class GameFinishedComponent extends Component<Props, State> implements OponentUpdate {

    constructor(props: Props) {
        super(props);
        this.state = { oponentScore: this.props.oponentScore, oponentStatus: this.props.oponentStatus }
    }

    updateOponentScore = (oponentScore: number) => {
        this.setState({ oponentScore })
    }

    oponentStatusChanged = (oponentStatus: string) => {
        this.setState({ oponentStatus })
    }

    renderUserProfilePic(user: User) {
        if (!user.pic) return (<div><FontAwesomeIcon color='#1d3256' icon={faUserCircle} size='4x' /></div>)
        if (user.thumbnail) return <img style={{ width: '4em', height: '4em', borderRadius: '2em', objectFit: 'cover' }} alt='prof pic' src={user.thumbnail} />
        else return <img style={{ width: '4em', height: '4em', borderRadius: '2em', objectFit: 'cover' }} alt='prof pic' src={user.pic} />
    }

    renderStatus(oponentScore: number, oponentStatus: string) {
        if (oponentStatus === 'disconnected') {
            return <div style={{ color: '#F0220B', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}>A conexão do usuário caiu  <FontAwesomeIcon icon={faExclamation} /></div>
        } else {
            return <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}><FontAwesomeIcon icon={faStar} /> <b>{oponentScore}</b></div>
        }
    }

    renderHeader(user: User, oponent: User) {
        return (<Row style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', paddingTop: 10, paddingBottom: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <Button color='none' outline onClick={() => { this.props.goBack() }}><FontAwesomeIcon color='#0f3252' icon={faArrowLeft} size='2x' /></Button>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 16, marginLeft: 10 }}><b>{user.username} VS {oponent.username}</b></div>
                    <FontAwesomeIcon style={{ marginLeft: 5 }} color='#0f3252' icon={faGamepad} size='2x' />
                </div>
            </Col>
        </Row>)
    }

    renderWinner(winner: User, winnerScore: number, loser: User, loserScore: number) {
        return (<Row style={{ marginTop: 10 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 18, marginLeft: 10, marginTop: 10 }}><FontAwesomeIcon color='#0f3252' icon={faTrophy} /> <b>Parabéns {winner.username}!</b></div>
            </Col>
            <Col style={{  marginTop: 5 }} md={{ size: 6, offset: 3 }}>
                <div className="d-flex align-items-center" style={{ margin: 5, padding: 5, boxShadow: '2px 4px 8px 2px rgba(0,99,0,0.5)' }}>
                    {this.renderUserProfilePic(winner)}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5, fontSize: 16 }}>{winner.username}</div>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}><FontAwesomeIcon icon={faStar} /> <b>{winnerScore}</b></div>
                    </div>
                </div>
            </Col>
            <Col style={{ marginTop: 15 }} md={{ size: 6, offset: 3 }}>
                <div className="d-flex align-items-center" style={{ margin: 5, padding: 5,  boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'}}>
                    {this.renderUserProfilePic(loser)}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5, fontSize: 16 }}>{loser.username}</div>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}><FontAwesomeIcon icon={faStar} /> {loserScore}</div>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    renderUserStatus(user: User, oponent: User, userScore: number, oponentScore: number, oponentStatus: string) {

        // TERMINOU!
        if (oponentStatus === 'finished' || oponentStatus === 'disconnected' || oponentScore > userScore) {
            // Teve vencedor
            if (userScore !== oponentScore) {

                let winner = userScore > oponentScore ? user : oponent
                let winnerScore = userScore > oponentScore ? userScore : oponentScore

                let loser = userScore < oponentScore ? user : oponent
                let loserScore = userScore < oponentScore ? userScore : oponentScore

                return this.renderWinner(winner, winnerScore, loser, loserScore)
            }
            // Empatou
            else {
                return (<Row style={{ marginTop: 10, marginBottom: 10 }}>
                    <Col md={{ size: 6, offset: 3 }}>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 18, marginLeft: 10, marginTop: 10, marginBottom: 10 }}><FontAwesomeIcon color='#0f3252' icon={faTrophy} size='2x' /> <b>Empate!</b></div>
                    </Col>
                    <Col style={{ marginTop: 10 }} md={{ size: 6, offset: 3 }}>
                        <div className="d-flex align-items-center" style={{ marginTop: 5, padding: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                            {this.renderUserProfilePic(user)}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5, fontSize: 16 }}>{user.username}</div>
                                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}><FontAwesomeIcon icon={faStar} /> <b>{userScore}</b></div>
                            </div>
                        </div>
                    </Col>
                    <Col style={{ marginTop: 15 }} md={{ size: 6, offset: 3 }}>
                        <div className="d-flex align-items-center" style={{ margin: 5, padding: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                            {this.renderUserProfilePic(oponent)}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5, fontSize: 16 }}>{oponent.username}</div>
                                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}><FontAwesomeIcon icon={faStar} /> <b>{oponentScore}</b></div>
                            </div>
                        </div>
                    </Col>
                </Row>)
            }
        } else {
            // Oponente ainda esta jogando
            return (<Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 18, marginLeft: 10, marginTop: 10, marginBottom: 10 }}><FontAwesomeIcon color='#0f3252' icon={faGamepad} /> <b>{oponent.username} ainda não terminou!</b></div>
                </Col>
                <Col style={{ marginTop: 10 }} md={{ size: 6, offset: 3 }}>
                    <div className="d-flex align-items-center" style={{ margin: 5, padding: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                        {this.renderUserProfilePic(user)}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5, fontSize: 16 }}>{user.username}</div>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}><FontAwesomeIcon icon={faStar} /> <b>{userScore}</b></div>
                        </div>
                    </div>
                </Col>
                <Col style={{ marginTop: 15 }} md={{ size: 6, offset: 3 }}>
                    <div className="d-flex align-items-center" style={{ margin: 5, padding: 5, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                        {this.renderUserProfilePic(oponent)}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5, fontSize: 16 }}>{oponent.username}</div>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 5, fontSize: 16, flex: 1 }}><FontAwesomeIcon icon={faStar} /> <b>{oponentScore}</b></div>
                        </div>
                    </div>
                </Col>
            </Row>)
        }
    }

    render() {
        let { user, oponent, userScore } = this.props
        let { oponentScore, oponentStatus } = this.state
        return (<Container fluid className='d-flex flex-column' style={{ background: 'white' }}>
            {this.renderHeader(user, oponent)}
            {this.renderUserStatus(user, oponent, userScore, oponentScore, oponentStatus)}
        </Container>)
    }
}