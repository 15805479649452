import _ from "lodash"
import Module from "../../../../models/Module"
import { UserCompanyScore } from "../../../../models/Scores"
import { SubModule } from "../../../../models/SubModule"
import User from "../../../../models/User"
import UserAccessValidation from "../../../../models/UserAccessValidation"
import { lowerCaseAndRemovePunctuationFromString } from "../../../../utils/punctuation"

export enum UserFilter {
    UsersCreatedAccount = 1,
    UsersDidntCreatedAccount,
    UsersPlayedModule,
    UsersDidntPlayedModule,
    AllUsers,
    BlockedUsers,
}

export class ComposedData {
    primaryProperty: string
    user?: User
    userAccess?: UserAccessValidation

    constructor(primaryProperty: string, data?: User | UserAccessValidation) {
        this.primaryProperty = primaryProperty
        if (data instanceof UserAccessValidation) this.userAccess = data as UserAccessValidation
        if (data instanceof User) this.user = data as User
    }

}

export const getUserFilterText = (userFilter: UserFilter) => {
    if (userFilter === UserFilter.UsersCreatedAccount) return 'Usuários que criaram conta'
    else if (userFilter === UserFilter.UsersDidntCreatedAccount) return 'Usuários que não criaram conta'
    else if (userFilter === UserFilter.UsersDidntPlayedModule) return 'Usuários com conta que não jogaram'
    else if (userFilter === UserFilter.UsersPlayedModule) return 'Usuários que jogaram'
    else if (userFilter === UserFilter.AllUsers) return 'Relatório geral de cadastrados'
    else if (userFilter === UserFilter.BlockedUsers) return 'Usuários bloqueados'
    else return ''
}

export const getComposedData = (primaryProperty: string, accessValidation: UserAccessValidation[], users: Array<User>) => {

    let completeArray = [...accessValidation, ...users]
    return completeArray.reduce((acc, data) => {

        if (data instanceof UserAccessValidation) {
            let userAccessValidation = data as UserAccessValidation
            let accData = _.head(acc.filter((a) => a.primaryProperty === userAccessValidation.primaryPropertyValue))
            if (!accData) acc.push(new ComposedData(userAccessValidation.primaryPropertyValue, userAccessValidation))
            else accData.userAccess = userAccessValidation
        } else if (data instanceof User) {
            let user = data as User
            let primaryPropertyValue = (primaryProperty === 'doc' ? user.doc : user.email) || ''
            let accData = _.head(acc.filter((a) => a.primaryProperty === primaryPropertyValue))
            if (!accData) acc.push(new ComposedData(primaryPropertyValue, user))
            else accData.user = user
        }

        return acc

    }, new Array<ComposedData>())
}

export const getFilteredComposedData = (composedData: Array<ComposedData>, searchInputWithoutPunctuation: string, authDataSelectedValues: { [id: string]: string; }) => {
    let authDataKeys = Object.keys(authDataSelectedValues)
    return composedData.filter(currentData => {
        if (authDataKeys.length > 0) {
            let userAuthData = currentData.user ? currentData.user.authData : (currentData.userAccess?.authData ?? undefined)
            if (!userAuthData) return false
            for (var i = 0; i < authDataKeys.length; i++) {
                let key = authDataKeys[i]
                if (userAuthData[key] !== authDataSelectedValues[key]) return false
            }
        }

        // pode ter buscado por e-mail ou cpf
        let primaryPropertyWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(currentData.primaryProperty)
        if(primaryPropertyWithoutPunctuation.includes(searchInputWithoutPunctuation)) return true

        // pode ter buscado por nome, o usuário pode ter trocado o nome no perfil e pode estar diferente do nome no userAccessValidation
        // então tem que procurar nos dois
        if(currentData.user) {
            let nameWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(currentData.user.username)
            if (nameWithoutPunctuation.includes(searchInputWithoutPunctuation)) return true
        }

        if(currentData.userAccess && currentData.userAccess.name && currentData.userAccess.name.length > 0) {
            let nameWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(currentData.userAccess.name)
            if (nameWithoutPunctuation.includes(searchInputWithoutPunctuation)) return true
        }

        return false
    })

}

export const getFilteredUsers = (users: User[], searchInputWithoutPunctuation: string, authDataSelectedValues: { [id: string]: string; }) => {
    let authDataKeys = Object.keys(authDataSelectedValues)
    return users.filter(user => {
        if (authDataKeys.length > 0) {
            if (!user.authData) return false
            for (var i = 0; i < authDataKeys.length; i++) {
                let key = authDataKeys[i]
                if (user.authData[key] !== authDataSelectedValues[key]) return false
            }
        }
        let nameWithoutPunctuation = lowerCaseAndRemovePunctuationFromString(user.username)
        return nameWithoutPunctuation.includes(searchInputWithoutPunctuation)
    })
}

export const getUsersDidntPlayedModule = (users: User[], selectedModule: Module | undefined, scores: UserCompanyScore[], subModules: SubModule[]): User[] => {
    return users.filter(user => {
        let userScore = _.first(scores.filter(score => { return score.userId === user!.id }))
        
        // se não jogou ainda já entra em qualquer um dos dois casos
        if (!userScore) return true

        if (selectedModule) {

            // verifica se o usuário não jogou o módulo escolhido
            var userHasPlayedSelectedModule = false
            // verifica se o usuario jogou algum nivel do modulo escolhido
            Object.keys(userScore!.subModuleScores).forEach(subModuleId => {
                let subModule = _.head(subModules.filter(subModule => subModule.id === subModuleId))
                if (subModule && subModule.moduleId === selectedModule.id) {
                    if(subModule.maxScore === 0) userHasPlayedSelectedModule = true
                    else if(userScore!.subModuleScores[subModuleId] > 0) userHasPlayedSelectedModule = true
                }
            })

            return !userHasPlayedSelectedModule

        }

        return userScore.totalScore === 0
    })
}

export const getUsersPlayedModule = (users: User[], selectedModule: Module | undefined, scores: UserCompanyScore[], subModules: SubModule[]): User[] => {
    return users.filter(user => {
        let userCompanyScore = _.first(scores.filter(score => score.userId === user!.id))
        // se o cara não jogou ainda então ele tá fora
        if (!userCompanyScore) return false

        if (selectedModule) {

            // verifica se o usuário jogou o módulo escolhido
            var userHasPlayedSelectedModule = false
            // verifica se o usuario jogou algum nivel do modulo escolhido
            Object.keys(userCompanyScore!.subModuleScores).forEach(subModuleId => {
                let subModule = _.head(subModules.filter(subModule => subModule.id === subModuleId))
                if (subModule && subModule.moduleId === selectedModule.id && userCompanyScore!.subModuleScores[subModule.id] > 0) userHasPlayedSelectedModule = true
            })

            return userHasPlayedSelectedModule

        }

        // Retorna se o usuário jogou alguma coisa
        return userCompanyScore.totalScore > 0
    })
}

export const getUsersWhoFinishedModule = (users: User[], scores: UserCompanyScore[], subModules: SubModule[]): User[] => {
    return users.filter(user => {
        let userCompanyScore = _.first(scores.filter(score => score.userId === user!.id))
        // se o cara não jogou ainda então ele tá fora
        if (!userCompanyScore) return false

        let subModulesFinishedCount = subModules.reduce((acc, subModule) => {
            if(userCompanyScore?.subModuleScores[subModule.id] === undefined) return acc
            else if(subModule.maxScore === 0) return acc + 1
            else if(userCompanyScore?.subModuleScores[subModule.id] >= (subModule.maxScore / 2)) return acc + 1
            return acc
        }, 0)

        return subModulesFinishedCount === subModules.length
    })
}