export default class VideosCache {
    error: string | undefined
    db: IDBDatabase | undefined = undefined

    constructor() {
        const DBOpenRequest = window.indexedDB.open("videosDb", 1);

        DBOpenRequest.onerror = (event) => {
            console.error(`Database error: ${event.toString()}`);
            this.error = event.toString()
        };

        DBOpenRequest.onsuccess = () => {
            this.db = DBOpenRequest.result;
        };

        DBOpenRequest.onupgradeneeded = (event) => {
            // Save the IDBDatabase interface
            if (event.target instanceof IDBOpenDBRequest) {
                const db = event.target.result
                // Create an objectStore for this database
                if (!db.objectStoreNames.contains('videos')) {
                    db.createObjectStore("videos", { keyPath: "id" });
                    console.log('created videos db')
                }
            }
        };
    }

    getVideoBlob = (url: string): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            fetch(url).then(response => resolve(response.blob())).catch(err => reject(err))
        })
    }

    saveVideoInDb = (id: string, blob: Blob, returnUrl: boolean = false): Promise<string | undefined> => {
        return new Promise((resolve, reject) => {
            if (!this.db) return reject('No Db found')
            const transaction = this.db.transaction(["videos"], "readwrite");
            const store = transaction.objectStore("videos");
            store.put({
                id,
                video: blob
            });
            transaction.oncomplete = () => returnUrl ? resolve(URL.createObjectURL(blob)) : resolve(undefined)
            transaction.onerror = (error) => reject(error)
        })
    }

    getVideoFromDb = (id: string) => {
        return new Promise<string | undefined>((resolve, reject) => {
            if (!this.db) return reject('No Db found')
            const transaction = this.db.transaction(["videos"]);
            const objectStore = transaction.objectStore("videos");
            const request = objectStore.get(id);
            request.onerror = (error) => reject(error)
            request.onsuccess = () => {
                if(request.result) {
                    resolve(URL.createObjectURL(request.result.video as Blob))
                } else {
                    resolve(undefined)
                }
            }
        })
    }
}