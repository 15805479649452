export const LANDING = '/'
export const PRODUCTS = '/products'
export const CONTACT = '/contact'
export const LEADERSHIP = '/leadership'
export const TIME_ADMIN = '/timeadmin'
export const TEAM_WORK = '/teamwork'
export const TEST = '/test'
export const LOADING = '/loading'
export const APP_LANDING = '/app'
export const ENTER = '/enter'
export const LOGIN = '/login'
export const SIGN_UP = '/signup'
export const MAIN = '/main'
export const SUBMODULE_SELECT = '/submodule'
export const MODULE_CONTENT_REPORT = '/mod_content_repo'
export const GAME = '/game'
export const REALTIME_GAME = '/realtime_game'
export const RESULT = '/result'
export const SELECT_MODULE_RANKING = '/select_module_ranking'
export const MODULE_RANKING = '/module_ranking'
export const SELECT_COMPANY_RANKING = '/select_company_ranking'
export const COMPANY_RANKING = '/company_ranking'
export const NEW_PWD = '/new_pwd'
export const PROFILE = '/profile'
export const PROFILE_PIC = '/profile_pic'
export const FORUM = '/forum'
export const ADMIN = '/admin'
export const ACTIVITIES = '/activities'
export const HELP = '/help'
export const UPLOAD_CREDENTIALS = '/upload_credentials'
export const USAGE_CONTROL = '/usage_control'
export const FORMS = '/forms'
export const USAGE_CHARTS = '/usage_charts'
export const SELECT_MODULE = '/select_module'
export const EDIT_MODULE = '/edit_module'
export const EDIT_SUBMODULE = '/edit_submodule'
export const CREATE_MODULE = '/create_module'
export const CREATE_SUBMODULE = '/create_submodule'
export const CARDS = '/cards'
export const VIDEO = '/video'
export const LINK = '/link'
export const USER_ACCESS_MAIN = '/user_access_main'
export const SMS_PANEL = '/sms_panel'
export const SMS_RESPONSE_PANEL = '/sms_response_panel'
export const USE_TERMS = '/use_terms'
export const PRIVACY_POLICY = '/privacy_policy'
export const INTEGRATION = '/integration'
export const MODULE_ACCESS = '/module_access'
export const CONFIG = '/config'